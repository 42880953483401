import { Router } from "../actions/";
// Router Before Transitions Reducer
type State = {
  pathname: string;
  search: string;
  hash: string;
  state: string;
  key: string;
};
type Action = {
  type: string;
  payload: State;
};

const initialState: State = {
  pathname: "/",
  search: "",
  hash: "",
  state: "",
  key: "",
};

export default function routerBeforeTransitions(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case Router.ROUTER_BEFORE_TRANSITIONS:
      return action.payload;
    // initial state
    default:
      return state;
  }
}
