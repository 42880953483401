// Features
import * as React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { onSetDateTime, setMomentDate, setUTCtime } from "../../../utils";
// Components
import { Divider, Header, Icon, Loader, Popup, Label } from "semantic-ui-react";
// Types
import type { RootState } from "../../../store/reducers";
import type { State as StateBroadcastMessages } from "../../../store/reducers/broadcastMessages";
// Broadcast Messages List:
// Styled Components
const Wrapper = styled.p`
  color: #999999;
  cursor: pointer;
  float: left;
  min-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 0;
  &:hover > span.wrap-content {
    color: #3765b3;
    text-decoration: underline;
  }
`;
const DateTime = styled.span`
  font-size: 0.9rem;
`;
//
const BroadcastMessagesList = () => {
  // STORE - Broadcast Messages
  const { broadcastMessages = [] }: StateBroadcastMessages = useSelector(
    ({ broadcastMessages }: RootState) => broadcastMessages
  );
  // STORE TENANT SETTING - Broadcast Messages
  const { isFetching }: { isFetching: boolean } = useSelector(
    ({ tenantSettings }: RootState) => ({
      isFetching: tenantSettings.isFetchingTenantSettings,
    })
  );
  // RENDERS
  return (
    <div className="broadcast-schedulers text-left noborder p10">
      <Divider className="mt30 mb10" horizontal>
        <Header as="h4" className="skejule-gray">
          <Icon name="envelope" />
          MESSAGES
        </Header>
      </Divider>
      <div className="broadcast-schedulers-list">
        {isFetching && (
          <Loader
            style={{ top: "30px", left: "-20px" }}
            active
            inline="centered"
            size="large"
            indeterminate
            className="m10 pl50 pr10"
          >
            <h3 className="ml10" style={{ textAlign: "center" }}>
              Loading Messages ...
            </h3>
          </Loader>
        )}
        {!isFetching &&
          broadcastMessages.map(
            ({ _id, createdAt, updatedAt, message, expirationDate }, index) => {
              const expirationDateWithFormat = expirationDate
                ? setUTCtime(expirationDate).format("MM/DD/YY")
                : "--";
              const broadcastMsgIsExpired = setMomentDate(
                expirationDateWithFormat
              ).isBefore(setMomentDate(setUTCtime().format("MM/DD/YY")));
              return (
                <React.Fragment key={`${_id}-${index}`}>
                  <Popup
                    basic
                    content={
                      <div
                        className="info-elements skejule-scroll names"
                        data-popup="is-popup-notification"
                      >
                        <div
                          className="info-element element"
                          data-popup="is-popup-notification"
                        >
                          <div
                            className="label mb5"
                            data-popup="is-popup-notification"
                          >
                            Message
                          </div>
                          <div
                            className="value"
                            data-popup="is-popup-notification"
                            style={{ whiteSpace: "pre-line" }}
                          >
                            {message}
                          </div>
                        </div>
                      </div>
                    }
                    data-popup="is-popup-notification"
                    id="broadcast-message-popup"
                    on="click"
                    position="bottom center"
                    trigger={
                      <Wrapper className="message mb10">
                        <span className="label skejule-teal mr5">Message:</span>
                        <span className="wrap-content">{message}</span>
                      </Wrapper>
                    }
                  />
                  <div className="expiration-date mb20">
                    <span className="label skejule-teal mr5">
                      Expiration Date:
                    </span>
                    <span className="value skejule-gray">
                      {expirationDateWithFormat}
                      {broadcastMsgIsExpired && (
                        <Label
                          className="expired ml5 p3"
                          color="red"
                          size="tiny"
                        >
                          Expired
                        </Label>
                      )}
                    </span>
                    <DateTime className="datetime skejule-gray right mr5">
                      {onSetDateTime(updatedAt || createdAt)}
                    </DateTime>
                  </div>
                  <Divider />
                </React.Fragment>
              );
            }
          )}
      </div>
    </div>
  );
};
export default BroadcastMessagesList;
