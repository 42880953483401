import { RouterManager } from "../actions/";
// Router Manager to save last manager route loaded Reducer
type StateBase = {
  pathname: string;
  search: string;
  hash: string;
  state: Object;
  key: string;
};
type State = {
  before: StateBase;
  loaded: StateBase;
};
type Action = {
  type: string;
  payload: State;
};

const initialState: State = {
  before: {
    pathname: "/manager",
    search: "",
    hash: "",
    state: { namePage: "Manager" },
    key: "",
  },
  loaded: {
    pathname: "/manager",
    search: "",
    hash: "",
    state: { namePage: "Manager" },
    key: "",
  },
};

export default function routerManager(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case RouterManager.ROUTER_MANAGER_BEFORE:
      return Object.assign({}, state, { before: action.payload.before });
    case RouterManager.ROUTER_MANAGER_LOADED:
      return Object.assign({}, state, { loaded: action.payload.loaded });
    case RouterManager.ROUTER_MANAGER_RESET:
      return initialState;
    // initial state
    default:
      return state;
  }
}
