// Import Features
import produce from "immer";
import { API } from "../api/API";
import { Api } from "../actions/";
import { onGetToken } from "../../utils";
// Types
import type { CancelTokenSource } from "axios";
import type { Action } from "deox";
export type CancelRequest = {
  key: "GET_CASE_LOGS" | "GET_ROTATIONS" | "GET_EVALUATIONS";
  source?: CancelTokenSource;
};
type ApiActionReducers = Action<
  string,
  {
    cancelRequest: CancelRequest;
    token: string;
  }
>;
export type State = {
  initAPI: boolean;
  api: {
    ready: boolean;
  };
  cancelRequests: CancelRequest[];
  token: string;
};
// Api Reducer
const initialState: State = {
  initAPI: false,
  api: {
    ready: false,
  },
  cancelRequests: [],
  token: onGetToken(),
};
const api: (state: State, action: ApiActionReducers) => State = produce(
  (draft: State, action: ApiActionReducers) => {
    switch (action.type) {
      case Api.INIT_API:
        draft.initAPI = true;
        if (action.payload.token) {
          draft.token = action.payload.token;
        }
        draft.api = new API();
        break;
      case Api.ADD_CANCEL_TOKEN_API:
        draft.cancelRequests = [
          ...draft.cancelRequests,
          action.payload.cancelRequest,
        ];
        break;
      case Api.REMOVE_CANCEL_TOKEN_API:
        draft.cancelRequests = draft.cancelRequests.filter(
          (cancelRequest: CancelRequest) =>
            cancelRequest.key !== action.payload.cancelRequest.key
        );
        break;
    }
  },
  initialState
);
export default api;
