// @flow

/* PERMISSIONS */
const onCheckResource = (apiResource: Object, resources: Array<string>) => {
  for (let resource in apiResource) {
    if (
      resources.indexOf(resource) !== -1 &&
      apiResource[resource]["isEnabled"]
    ) {
      return true;
    }
  }
  return false;
};

export const onCheckAllUserPortfolioPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "Portfolios:read",
    "Portfolios:update",
    "my:Students#Portfolios:submitTestimonial",
    "my#Portfolios:read",
    "my#Portfolios:update",
    "my#Portfolios:delete",
  ];
  const { Portfolios } = permissions || {};
  if (Portfolios && Portfolios.apiValue) {
    return onCheckResource(Portfolios.apiValue, resources);
  }
  return false;
};

export const onCheckReadPortfoliosPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Portfolios:read", "my#Portfolios:read"];
  const { Portfolios } = permissions || {};
  if (Portfolios && Portfolios.apiValue) {
    return onCheckResource(Portfolios.apiValue, resources);
  }
  return false;
};

export const onCheckReadAllPortfoliosPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Portfolios:read"];
  const { Portfolios } = permissions || {};
  if (Portfolios && Portfolios.apiValue) {
    return onCheckResource(Portfolios.apiValue, resources);
  }
  return false;
};
export const onCheckEditAllPortfoliosPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Portfolios:update"];
  const { Portfolios } = permissions || {};
  if (Portfolios && Portfolios.apiValue) {
    return onCheckResource(Portfolios.apiValue, resources);
  }
  return false;
};

export const onCheckReadMyUsersPortfolioPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my#Portfolios:read"];
  const { Portfolios } = permissions || {};
  if (Portfolios && Portfolios.apiValue) {
    return onCheckResource(Portfolios.apiValue, resources);
  }
  return false;
};

export const onCheckEditMyUsersPortfolioPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my#Portfolios:update"];
  const { Portfolios } = permissions || {};
  if (Portfolios && Portfolios.apiValue) {
    return onCheckResource(Portfolios.apiValue, resources);
  }
  return false;
};

export const onCheckDeleteMyUsersPortfolioPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my#Portfolios:delete"];
  const { Portfolios } = permissions || {};
  if (Portfolios && Portfolios.apiValue) {
    return onCheckResource(Portfolios.apiValue, resources);
  }
  return false;
};

export const onCheckSubmitMyUsersPortfolioPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:Students#Portfolios:submitTestimonial"];
  const { Portfolios } = permissions || {};
  if (Portfolios && Portfolios.apiValue) {
    return onCheckResource(Portfolios.apiValue, resources);
  }
  return false;
};
