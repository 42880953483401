// @flow
import * as React from 'react';
import ErrorComponent from '../shared/ErrorComponent';
// SIMPLE ErrorBoundary
class ErrorBoundary extends React.Component<Object, { isOffline: boolean }> {
  state = {
    isOffline: false
  };

  onErrorOfflineOnline = () => {
    this.setState({ isOffline: !this.state.isOffline });
  };

  componentDidMount() {
    window.addEventListener('offline', this.onErrorOfflineOnline);
    window.addEventListener('online', this.onErrorOfflineOnline);
  }

  componentDidCatch(error: any, info: any) {
    window.logger.error('ErrorBooundary: ', error, 'Info: ', info);
  }

  render() {
    const { isOffline } = this.state;
    if (isOffline) {
      return (
        <ErrorComponent
          className="error-offline"
          textError="Sorry, you are offline ! We will come back when you get a connection."
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
