import type { Config, tAPI } from "./API";
// API Files static class

const apiBaseFiles = "/files";
export default class Files {
  // Edit a file
  static EditFile(api: tAPI, fileId: string, data: object, config?: Config) {
    return api.PATCH(`${apiBaseFiles}/${fileId}`, data, config);
  }
  // Delete a file
  static DeleteFile(api: tAPI, fileId: string, config?: Config) {
    return api.DELETE(`${apiBaseFiles}/${fileId}`, config);
  }
  // Download a file
  static DownloadFile(
    api: tAPI,
    data: object,
    extension: string,
    config?: Config
  ) {
    return api.DOWNLOAD(`${apiBaseFiles}/download`, data, extension, config);
  }
}
