import axios from "axios";
import {
  put,
  select,
  call,
  all,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import CaseLogsApi from "../api/CaseLogs";
import { Api, Auth, CaseLogs } from "../actions/";
import { setUTCtime } from "../../utils/";
// Type
import type { Action } from "deox";
import type { tAPI } from "../api/API";
import type { CancelRequest, State } from "../reducers/api";
import type { StateUsersManager } from "../../types/users.types";
//Sagas for CaseLogs
function* doCaseLogsFetchAll(
  action: Action<
    string,
    {
      populated?: boolean;
      page?: number;
      limit?: number;
      searchTxt?: string;
      status?: string[];
      userStatus?: StateUsersManager["filters"]["status"];
      students?: string[];
      programs?: string[];
      cohorts?: string[];
      preceptors?: string[];
      institutions?: string[];
      procedures?: string[];
      startDate?: string;
      endDate?: string;
      appointmentDateFrom?: string;
      appointmentDateTo?: string;
      sortBy?: {
        student: "ascending" | "descending" | "";
        preceptor: "ascending" | "descending" | "";
        institution: "ascending" | "descending" | "";
        createdAt: "ascending" | "descending" | "";
        status: "ascending" | "descending" | "";
      };
      callbackSuccess?: (data: any, metadata: any) => void;
      callbackError?: (
        error: any,
        details: any,
        isAPICancelled: boolean
      ) => void;
    }
  >
) {
  yield put(CaseLogs.caseLogsFetchAllRequest());
  window.logger.log("doCaseLogsFetchAll", action);
  const {
    callbackSuccess = null,
    callbackError = null,
    ...payload
  } = action.payload || {};
  try {
    const { frontendPersonalization } = yield select(
      ({ accountSettings }) => accountSettings
    );
    let caseLogsSortBy: {
      student: "ascending" | "descending" | "";
      preceptor: "ascending" | "descending" | "";
      institution: "ascending" | "descending" | "";
      createdAt: "ascending" | "descending" | "";
      status: "ascending" | "descending" | "";
    } = {
      student: "",
      preceptor: "",
      institution: "",
      createdAt: "",
      status: "",
    };
    if (
      frontendPersonalization &&
      frontendPersonalization["CASE_LOGS_SETTINGS"]
    ) {
      caseLogsSortBy = frontendPersonalization["CASE_LOGS_SETTINGS"].sortBy;
    }
    const {
      populated = true,
      page = 1,
      limit = 0,
      searchTxt = "",
      status = [],
      userStatus = [],
      students = [],
      programs = [],
      cohorts = [],
      preceptors = [],
      institutions = [],
      procedures = [],
      startDate = "",
      endDate = "",
      appointmentDateFrom = "",
      appointmentDateTo = "",
      sortBy = caseLogsSortBy,
    } = payload || {};
    const skip = (page - 1) * limit;
    // Api
    const {
      api,
      cancelRequests,
      token,
    }: {
      api: tAPI;
      cancelRequests: State["cancelRequests"];
      token: State["token"];
    } = yield select(({ api }) => api);
    const [cancelRequest] = cancelRequests.filter(
      (cancelRequest: CancelRequest) => cancelRequest.key === "GET_CASE_LOGS"
    );
    if (cancelRequest && cancelRequest.source) {
      cancelRequest.source.cancel("Request has been cancelled");
      yield put(
        Api.removeCancelTokenAPI({
          cancelRequest: {
            key: "GET_CASE_LOGS",
          },
        })
      );
    }
    // Call the api to get all caseLogs for this company
    const CancelToken = axios.CancelToken,
      source = CancelToken.source();
    yield put(
      Api.addCancelTokenAPI({
        cancelRequest: {
          key: "GET_CASE_LOGS",
          source,
        },
      })
    );
    const { data } = yield call(
      CaseLogsApi.GetCaseLogs,
      api,
      populated,
      skip,
      limit,
      status,
      userStatus,
      students,
      programs,
      cohorts,
      preceptors,
      institutions,
      procedures,
      searchTxt,
      startDate,
      endDate,
      appointmentDateFrom,
      appointmentDateTo,
      sortBy,
      { cancelToken: source.token, token }
    );
    yield put(
      Api.removeCancelTokenAPI({
        cancelRequest: {
          key: "GET_CASE_LOGS",
        },
      })
    );
    const { caseLogs, metadata } = data;
    yield put(
      CaseLogs.caseLogsFetchAllSuccess({
        caseLogs,
        caseLogsStatus: status,
        metadata,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(caseLogs, metadata);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doCaseLogsFetchAll", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    const { message, details, status } = errors || "";
    const isAPICancelled = status === "cancelled";
    if (isAPICancelled) {
      yield put(
        Api.removeCancelTokenAPI({
          cancelRequest: {
            key: "GET_CASE_LOGS",
          },
        })
      );
    }
    yield put(CaseLogs.caseLogsFetchAllFailure());
    if (callbackError) {
      callbackError(message, details, isAPICancelled);
    }
  }
}

function* doCaseLogsFetchKpis(action: Action<string>) {
  yield put(CaseLogs.caseLogsFetchKpisRequest());
  window.logger.log("doCaseLogsFetchKpis", action);
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    const {
      data: { kpis: CaseLogsKPIS },
    } = yield call(CaseLogsApi.GetCaseLogsKpis, api, { token });
    yield put(
      CaseLogs.caseLogsFetchKpisSuccess({
        kpis: {
          fetching: true,
          CaseLogs: CaseLogsKPIS,
        },
      })
    );
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doCaseLogsFetchKpis", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    const CaseLogsKPIS: object = yield select(({ caseLogs: { kpis } }) => kpis);
    yield put(
      CaseLogs.caseLogsFetchKpisFailure({
        kpis: CaseLogsKPIS,
      })
    );
  }
}

function* doCaseLogsCreate(
  action: Action<
    string,
    {
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any, details: any) => void;
    }
  >
) {
  yield put(CaseLogs.caseLogsCreateRequest());
  window.logger.log("doCaseLogsCreate", action);
  const { body, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to create a new case log
    const { data } = yield call(CaseLogsApi.CreateCaseLog, api, body, {
      token,
    });
    const {
      caseLog: { _id },
    } = data;
    // We need to call again because this data is not populated
    const { data: dataCaseLog } = yield call(CaseLogsApi.GetCaseLog, api, _id, {
      token,
    });
    let { caseLog } = dataCaseLog;
    caseLog = {
      ...caseLog,
      lastFetching: new Date(),
    };
    yield put(CaseLogs.caseLogsCreateSuccess({ caseLog }));
    if (callbackSuccess) {
      callbackSuccess(caseLog);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doCaseLogsCreate", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(CaseLogs.caseLogsCreateFailure());
    const { message, details } = errors || "";
    if (callbackError) {
      callbackError(message, details);
    }
  }
}

function* doCaseLogsCreateDummy(
  action: Action<
    string,
    {
      body: object;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(CaseLogs.caseLogsCreateDummyRequest());
  window.logger.log("doCaseLogsCreateDummy", action);
  const { body, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to create a new case log
    yield call(CaseLogsApi.CreateCaseLog, api, body, { token });
    yield put(CaseLogs.caseLogsCreateDummySuccess());
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doCaseLogsCreateDummy", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(CaseLogs.caseLogsCreateDummyFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

const onCheckClinicalInfoReasonForVisit = (
  reasonForVisit: string | string[]
) => {
  if (typeof reasonForVisit === "string") {
    return [reasonForVisit];
  }
  return reasonForVisit || [];
};

const onSetCaseLogCreateEdit = (
  caseLog: { [k: string]: any },
  step: string
) => {
  const {
    patient,
    appointmentDate,
    reasonForVisit,
    minutesWithPatient,
    minutesWithPreceptor,
    chiefComplaint,
    // diagnosesAndProcedures,
    medicationTaken,
    prescriptionThisVisit,
    psychosocialTopics,
    otherPsychosocialTopic,
    pmcc,
    customFields,
    notes,
    status,
    preceptorComments,
  } = caseLog;
  const {
    age,
    typeAge,
    gender,
    race,
    firstLanguage,
    institutionRecordNumber,
    insurance,
  } = patient || {};
  const caseLogCreateEdit = {
    patientDemographics: {
      age: age || null,
      typeAge: typeAge || "years",
      gender: gender || null,
      race: race || null,
      firstLanguage: firstLanguage || null,
      institutionRecordNumber: institutionRecordNumber || null,
      insurance: insurance || null,
    },
    clinicalInfo: {
      appointmentDate: appointmentDate || null,
      reasonForVisit: onCheckClinicalInfoReasonForVisit(reasonForVisit),
      minutesWithPatient: minutesWithPatient || null,
      minutesWithPreceptor: minutesWithPreceptor || null,
      chiefComplaint: chiefComplaint || null,
    },
    diagnosesAndProcedures: {
      allTeeth: [],
      upperRightTeeth: [],
      upperLeftTeeth: [],
      lowerLeftTeeth: [],
      lowerRightTeeth: [],
    },
    medication: {
      medicationTaken: medicationTaken || [],
      prescriptionThisVisit: prescriptionThisVisit || [],
    },
    psychosocialTopics: {
      psychosocialTopics: psychosocialTopics || [],
      otherPsychosocialTopic: otherPsychosocialTopic || null,
      pmcc: pmcc || null,
    },
    customFields: {
      customFields: customFields
        ? customFields.map(({ fieldType, field, value }: any) => ({
            field: field._id,
            value:
              fieldType === "number"
                ? Number(value)
                : fieldType === "boolean" && value === null
                ? false
                : value,
          }))
        : [],
    },
    notes: {
      notes: notes || null,
    },
    review: {
      status: status || null,
      preceptorComments: preceptorComments || null,
    },
    step,
  };
  return caseLogCreateEdit;
};

function* doCaseLogsCheckCaseLogsCacheData(
  action: Action<
    string,
    {
      caseLogId: string;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  window.logger.log("doCaseLogsCheckCaseLogsCacheData", action);
  const { caseLogId } = action.payload;
  try {
    const caseLogsCacheDataStore: { [k: string]: any } = yield select(
      ({ caseLogs: { caseLogsCache } }) => caseLogsCache
    );
    let caseLog = caseLogsCacheDataStore.filter(
      ({ _id }: typeof caseLogsCacheDataStore) => _id === caseLogId
    );
    if (caseLog.length) {
      caseLog = caseLog[0];
      if (setUTCtime().isAfter(setUTCtime(caseLog.lastFetching).add(5, "m"))) {
        caseLog = null;
      }
    } else {
      caseLog = null;
    }
    return caseLog;
  } catch (error: any) {
    window.logger.error("ErrorSaga - doCaseLogsCheckCaseLogsCacheData", error);
    return null;
  }
}

function* doCaseLogsFetchCaseLog(
  action: Action<
    string,
    {
      caseLogId: string;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(CaseLogs.caseLogsFetchCaseLogRequest());
  window.logger.log("doCaseLogsFetchCaseLog", action);
  const {
    caseLogId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    let caseLog: { [k: string]: any } = yield call(
      doCaseLogsCheckCaseLogsCacheData,
      action
    );
    if (!caseLog) {
      const { api, token }: { api: tAPI; token: string } = yield select(
        ({ api }) => api
      );
      // call the api to get a case log
      const { data } = yield call(CaseLogsApi.GetCaseLog, api, caseLogId, {
        token,
      });
      caseLog = data.caseLog;
    }
    caseLog = {
      ...caseLog,
      lastFetching: new Date(),
    };
    yield put(
      CaseLogs.caseLogsFetchCaseLogSuccess({
        caseLog,
        caseLogCreateEdit: onSetCaseLogCreateEdit(
          caseLog,
          "patientDemographics"
        ),
      })
    );
    if (callbackSuccess) {
      callbackSuccess(caseLog);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doCaseLogsFetchCaseLog", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(CaseLogs.caseLogsFetchCaseLogFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}

function* doCaseLogsEdit(
  action: Action<
    string,
    {
      caseLogId: string;
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any, details: any) => void;
    }
  >
) {
  yield put(CaseLogs.caseLogsEditRequest());
  window.logger.log("doCaseLogsEdit", action);
  const {
    caseLogId,
    body,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to edit a a case Log for that specific caseLogId
    yield call(CaseLogsApi.EditCaseLog, api, caseLogId, body, { token });
    // We need to call again because this data is not populated
    const { data: dataCaseLog } = yield call(
      CaseLogsApi.GetCaseLog,
      api,
      caseLogId,
      { token }
    );
    let { caseLog } = dataCaseLog;
    caseLog = {
      ...caseLog,
      lastFetching: new Date(),
    };
    const caseLogDataStore: { [k: string]: any } = yield select(
      ({ caseLogs: { caseLog } }) => caseLog
    );
    yield put(
      CaseLogs.caseLogsEditSuccess({
        caseLog,
        caseLogStatusOld: caseLogDataStore.status,
        caseLogCreateEdit: onSetCaseLogCreateEdit(
          caseLog,
          "patientDemographics"
        ),
      })
    );
    if (callbackSuccess) {
      callbackSuccess(caseLog);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doCaseLogsEdit", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(CaseLogs.caseLogsEditFailure());
    const { message, details } = errors || "";
    if (callbackError) {
      callbackError(message, details);
    }
  }
}

function* doCaseLogsDelete(
  action: Action<
    string,
    {
      caseLogId: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(CaseLogs.caseLogsDeleteRequest());
  window.logger.log("doCaseLogsDelete", action);
  const {
    caseLogId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to delete a case Log for that specific caseLogId
    yield call(CaseLogsApi.DeleteCaseLog, api, caseLogId, { token });
    yield put(CaseLogs.caseLogsDeleteSuccess({ caseLogId }));
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doCaseLogsDelete", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(CaseLogs.caseLogsDeleteFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doCaseLogsReview(
  action: Action<
    string,
    {
      caseLogId: string;
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(CaseLogs.caseLogsReviewRequest());
  window.logger.log("doCaseLogsReview", action);
  const {
    caseLogId,
    body,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to review a case log for that caseLogId and status: denied or approved
    yield call(CaseLogsApi.ReviewCaseLog, api, caseLogId, body, { token });
    // We need to call again because this data is not populated
    const { data: dataCaseLog } = yield call(
      CaseLogsApi.GetCaseLog,
      api,
      caseLogId,
      { token }
    );
    let { caseLog } = dataCaseLog;
    caseLog = {
      ...caseLog,
      lastFetching: new Date(),
    };
    const caseLogDataStore: { [k: string]: any } = yield select(
      ({ caseLogs: { caseLog } }) => caseLog
    );
    yield put(
      CaseLogs.caseLogsReviewSuccess({
        caseLog,
        caseLogStatusOld: caseLogDataStore.status,
        caseLogCreateEdit: onSetCaseLogCreateEdit(
          caseLog,
          "patientDemographics"
        ),
      })
    );
    if (callbackSuccess) {
      callbackSuccess(caseLog);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doCaseLogsReview", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(CaseLogs.caseLogsReviewFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

export default function* caseLogsSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(CaseLogs.CASELOGS_FETCH_ALL, doCaseLogsFetchAll),
    takeLatest(CaseLogs.CASELOGS_FETCH_KPIS, doCaseLogsFetchKpis),
    takeLatest(CaseLogs.CASELOGS_CREATE, doCaseLogsCreate),
    takeEvery(CaseLogs.CASELOGS_CREATE_DUMMY, doCaseLogsCreateDummy),
    takeLatest(CaseLogs.CASELOGS_FETCH_CASELOG, doCaseLogsFetchCaseLog),
    takeLatest(CaseLogs.CASELOGS_EDIT, doCaseLogsEdit),
    takeLatest(CaseLogs.CASELOGS_DELETE, doCaseLogsDelete),
    takeLatest(CaseLogs.CASELOGS_REVIEW, doCaseLogsReview),
  ]);
}
