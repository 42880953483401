// @flow
import * as React from "react";
import classNames from "classnames";
import { Dropdown, Icon, Image, Label, Menu } from "semantic-ui-react";
import MenuTutorial from "./components/MenuTutorial";
import NotificationsContainer from "../notifications/NotificationsContainer";
import SubMenu from "./components/SubMenu";
import iconUserEmpty from "../../images/iconUserEmpty.svg";
import iconCompanyEmpty from "../../images/iconCompanyEmpty.svg";
import iconInstitutionEmpty from "../../images/iconInstitutionEmpty.svg";

type Props = {
  role: Object,
  user: ?Object,
  myCompany: ?Object,
  myInstitution: ?Object,
  MyPayments: ?React.ComponentType<any>,
  namePage: string,
  activePath: string,
  activeNotifications: boolean,
  isModalOpen: boolean,
  showSidebarMenu: boolean,
  notifications: Array<Object>,
  totalNotifications: number,
  unseenNotifications: number,
  onOpenFeedbackForm: () => void,
  setImg: (_img: string, updatedAt: string) => string,
  onChangeMenu: Function,
  onOpenCloseNotifications: (isClose: ?boolean) => () => void,
  onShowMenu: Function,
  onOpenCloseSubMenu: Function,
  goTo: (location: Object) => () => void,
  goBack: Function,
  onCheckPermissions: (resource: string) => ?string,
};
class MenuPresentational extends React.Component<Props> {
  _onCheckActiveMenu = (
    path:
      | "/home"
      | "/manager"
      | "/clinics"
      | "/rotations"
      | "/case-logs"
      | "/evaluations"
      | "/reports"
      | "/reports/dashboard/queries"
  ) => {
    const { activePath } = this.props;
    if (path === "/manager" && activePath.indexOf("manager") > -1) {
      return true;
    } else if (path === activePath) {
      return true;
    }
    return false;
  };
  onErrorCompanyImage = (event: Object) => {
    event.target.src = iconCompanyEmpty;
    event.target.classList.remove("small");
  };
  onErrorInstitutionImage = (event: Object) => {
    event.target.src = iconInstitutionEmpty;
    event.target.classList.remove("small");
  };
  onErrorUserImage = (event: Object) => {
    event.target.src = iconUserEmpty;
    //event.target.classList.remove('small');
  };
  // RENDERS:
  render() {
    const {
      namePage,
      isModalOpen,
      showSidebarMenu,
      onShowMenu,
      onOpenCloseSubMenu,
      goBack,
    } = this.props;
    return (
      <div id="main" className="menu notifications">
        <div className="menubar visible-xs visible-sm hidden-md hidden-lg">
          <div className="trigger">
            {!isModalOpen && (
              <i
                className="icono icono-hamburger big white"
                onClick={() => onShowMenu()}
              />
            )}
          </div>
          <div className="title">{namePage}</div>
          {namePage && namePage === "Manager" && (
            <SubMenu
              isModalOpen={isModalOpen}
              onOpenClose={onOpenCloseSubMenu}
            />
          )}
          {namePage &&
            (namePage.indexOf("Invitations") > -1 ||
              namePage.indexOf("Profile") > -1 ||
              namePage.indexOf("My School") > -1 ||
              namePage.indexOf("My Clinic") > -1 ||
              namePage.indexOf("Documents") > -1) && (
              <div className="back">
                <i className="icono icono-back big white" onClick={goBack} />
              </div>
            )}
        </div>
        <div
          className={classNames({
            sidebarmenu: true,
            visible: showSidebarMenu,
          })}
        >
          {this.renderMenu()}
          {this.renderSubMenuProfile()}
        </div>
        <MenuTutorial />
      </div>
    );
  }
  renderMenu = () => {
    const {
      activeNotifications,
      onChangeMenu,
      onOpenCloseNotifications,
      notifications,
      totalNotifications,
      unseenNotifications,
      setImg,
      onCheckPermissions,
    } = this.props;
    const menusHead = [{ path: "/home", icon: "house" }];
    let menusTail = [];
    if (onCheckPermissions("Notifications")) {
      menusTail.push({ path: "/notifications", icon: "bell" });
    }
    if (onCheckPermissions("UserManager")) {
      menusTail.push({ path: "/manager", icon: "circles" });
    }
    if (onCheckPermissions("Institutions")) {
      menusTail.push({ path: "/clinics", icon: "building" });
    }
    if (onCheckPermissions("Rotations")) {
      menusTail.push({ path: "/rotations", icon: "calendar" });
    }
    if (onCheckPermissions("CaseLogs")) {
      menusTail.push({ path: "/case-logs", icon: "clipboard" });
    }
    if (onCheckPermissions("Evaluations")) {
      menusTail.push({ path: onCheckPermissions("Evaluations"), icon: "file" });
    }
    if (onCheckPermissions("Reports")) {
      menusTail.push({
        path: onCheckPermissions("Reports"),
        icon: "statistics",
      });
    }
    let menus = [...menusHead, ...menusTail];
    return (
      <Menu icon="labeled" vertical className="nobg noborder br0 noshadow">
        {menus.map(({ path, icon }) => (
          <Menu.Item
            as="span"
            key={path + icon}
            path={path}
            active={
              path === "/notifications"
                ? activeNotifications
                : activeNotifications === false
                ? this._onCheckActiveMenu(path)
                : false
            }
            className={classNames({
              "relative notifications": path === "/notifications",
              manager: path === "/manager",
            })}
            onClick={path === "/notifications" ? null : onChangeMenu}
          >
            <i
              className={`icono icono-${icon} huge white`}
              onClick={
                path === "/notifications" ? onOpenCloseNotifications() : null
              }
            />
            {path === "/notifications" && (
              <React.Fragment>
                {unseenNotifications > 0 && (
                  <Label
                    size="small"
                    circular
                    attached="top right"
                    className="mt5 mr5"
                    onClick={onOpenCloseNotifications()}
                    style={{ cursor: "pointer" }}
                  >
                    {unseenNotifications > 99 ? "+99" : unseenNotifications}
                  </Label>
                )}
                <NotificationsContainer
                  activeNotifications={activeNotifications}
                  totalNotifications={totalNotifications}
                  notifications={notifications}
                  unseenNotifications={unseenNotifications}
                  setImg={setImg}
                  onCloseNotifications={onOpenCloseNotifications(true)}
                />
              </React.Fragment>
            )}
          </Menu.Item>
        ))}
      </Menu>
    );
  };
  renderSubMenuProfile = () => {
    const { MyPayments, user, setImg, onCheckPermissions, goTo } = this.props;
    const hasPortfolio = onCheckPermissions("MyPortfolio") && true;
    const pathAdmin = onCheckPermissions("Admin");
    return (
      <Dropdown
        id="dropdown-user-avatar"
        trigger={this.renderImage(
          "user-logged-in",
          user && user.profileImageOriginal
            ? setImg(user.profileImageOriginal, user.updatedAt)
            : iconUserEmpty,
          user ? `${user.firstName} ${user.lastName}` : "user avatar",
          user ? `${user.firstName} ${user.lastName}` : "user avatar",
          true /*user && user.profileImageOriginal ? true : false,*/,
          this.onErrorUserImage
        )}
        className="user-avatar ml15"
        pointing="bottom left"
        icon={null}
      >
        <Dropdown.Menu className="dropdown-menu-user-avatar">
          {this.renderSubMenuMyProfile()}
          {onCheckPermissions("MySchool") &&
            user &&
            user.company &&
            this.renderSubmenuSchool()}
          {onCheckPermissions("MyClinic") &&
            user &&
            (user.institution || user.activeRotation) &&
            this.renderSubmenuClinic()}
          {hasPortfolio && this.renderSubmenuMyPortfolio()}
          {MyPayments && <MyPayments as="subMenuProfile" />}
          {this.renderSubmenuMySettings()}
          {pathAdmin && this.renderSubmenuAdminPortal(pathAdmin)}
          {this.renderSubmenuChangePassword()}
          {this.renderSubmenuFeedbackForm()}
          <Dropdown.Divider />
          <Dropdown.Item
            icon={
              <i aria-hidden="true" className="power off icon skejule-teal" />
            }
            onClick={goTo({
              pathname: `/signout`,
              state: {
                namePage: "Sign Out",
              },
            })}
            text="Sign Out"
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  };
  renderSubMenuMyProfile = () => {
    const { user, setImg, goTo } = this.props;
    return (
      <Dropdown.Item
        onClick={goTo({
          pathname: `/my-profile`,
          state: {
            namePage: "My Profile",
          },
        })}
      >
        {this.renderImage(
          "user-logged-in",
          user && user.profileImageOriginal
            ? setImg(user.profileImageOriginal, user.updatedAt)
            : iconUserEmpty,
          user ? `${user.firstName} ${user.lastName}` : "user avatar",
          user ? `${user.firstName} ${user.lastName}` : "user avatar",
          true /*user && user.profileImageOriginal ? true : false,*/,
          this.onErrorUserImage
        )}{" "}
        My Profile
      </Dropdown.Item>
    );
  };
  renderSubmenuSchool = () => {
    const { myCompany, setImg, goTo } = this.props;
    return (
      <React.Fragment>
        <Dropdown.Divider />
        <Dropdown.Item
          onClick={goTo({
            pathname: `/my-school`,
            state: {
              namePage: "My School",
            },
          })}
        >
          {this.renderImage(
            "company",
            myCompany && myCompany.logo
              ? setImg(myCompany.logo, myCompany.updatedAt)
              : iconCompanyEmpty,
            "icon school avatar",
            "sub-menu-school",
            myCompany && myCompany.logo ? true : false,
            this.onErrorCompanyImage
          )}
          My School
        </Dropdown.Item>
      </React.Fragment>
    );
  };
  renderSubmenuClinic = () => {
    const { myInstitution, setImg, goTo } = this.props;
    return (
      <React.Fragment>
        <Dropdown.Divider />
        <Dropdown.Item
          onClick={goTo({
            pathname: `/my-clinic`,
            state: {
              namePage: "My Clinic",
            },
          })}
        >
          {this.renderImage(
            "institution",
            myInstitution && myInstitution.logo
              ? setImg(myInstitution.logo, myInstitution.updatedAt)
              : iconInstitutionEmpty,
            "icon clinic avatar",
            "sub-menu-clinic",
            myInstitution && myInstitution.logo ? true : false,
            this.onErrorInstitutionImage
          )}
          My Clinic
        </Dropdown.Item>
      </React.Fragment>
    );
  };
  renderImage = (
    type: "user-logged-in" | "company" | "institution",
    src: any,
    alt: string,
    title: string,
    small: boolean,
    onError: (event: Object) => void
  ) => {
    return (
      <Image
        src={src}
        className={classNames("avatar with-border", {
          small,
          "user-logged-in": type === "user-logged-in",
        })}
        alt={alt}
        title={title}
        centered
        onError={onError}
      />
    );
  };
  renderSubmenuMyPortfolio = () => {
    const { goTo } = this.props;
    return (
      <React.Fragment>
        <Dropdown.Divider />
        <Dropdown.Item
          icon={<Icon className="skejule-teal" name="address book" />}
          onClick={goTo({
            pathname: `/my-profile/portfolio`,
            state: {
              namePage: "My Portfolio",
            },
          })}
          text="My Portfolio"
        />
      </React.Fragment>
    );
  };
  renderSubmenuMySettings = () => {
    const { goTo } = this.props;
    return (
      <React.Fragment>
        <Dropdown.Divider />
        <Dropdown.Item
          icon={<Icon className="skejule-teal" name="settings" />}
          onClick={goTo({
            pathname: `/account-settings/general`,
            state: {
              namePage: "My Settings",
            },
          })}
          text="My Settings"
        />
      </React.Fragment>
    );
  };
  renderSubmenuAdminPortal = (pathAdmin: string) => {
    const { goTo } = this.props;
    return (
      <React.Fragment>
        <Dropdown.Divider />
        <Dropdown.Item
          icon={
            <Icon.Group className="skejule-teal" size="large">
              <Icon name="shield" />
              <Icon corner name="cogs" />
            </Icon.Group>
          }
          onClick={goTo({
            pathname: pathAdmin,
            state: {
              namePage: "Admin Portal",
            },
          })}
          text="Admin"
        />
      </React.Fragment>
    );
  };
  renderSubmenuChangePassword = () => {
    const { goTo } = this.props;
    return (
      <React.Fragment>
        <Dropdown.Divider />
        <Dropdown.Item
          icon={<Icon className="skejule-teal" name="key" />}
          onClick={goTo({
            pathname: `/my-profile/change-password`,
            state: {
              namePage: "My Profile",
            },
          })}
          text="Change Password"
        />
      </React.Fragment>
    );
  };
  renderSubmenuFeedbackForm = () => {
    const { onOpenFeedbackForm } = this.props;
    return (
      <React.Fragment>
        <Dropdown.Divider />
        <Dropdown.Item
          icon={<Icon className="skejule-teal" name="exclamation" />}
          onClick={onOpenFeedbackForm}
          text="Feedback"
        />
      </React.Fragment>
    );
  };
}

export default MenuPresentational;
