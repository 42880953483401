// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ProtectedRoute from "../shared/ProtectedRoute";
const AccountSettingsRoute: React.ComponentType<any> = withRouter(
  connect(({ config, auth: { isAuthenticated, role } }) => {
    let redirectTo = "/home";
    return {
      component: null,
      restProps: null,
      isAllowedRoute:
        config.companyDomain.length > 0 && isAuthenticated && role.permissions,
      redirectTo,
    };
  })(ProtectedRoute)
);
export default AccountSettingsRoute;
