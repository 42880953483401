import { put, select, call, all, takeLatest } from "redux-saga/effects";
import sortBy from "lodash/sortBy";
import CustomFieldsApi from "../api/CustomFields";
import { Auth, CustomFields } from "../actions/";
// Type
import type { Action } from "deox";
import type { tAPI } from "../api/API";
//Sagas for Custom Fields
function* doCustomFieldsFetchCustomFields(
  action: Action<
    string,
    {
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(CustomFields.customFieldsFetchCustomFieldsRequest());
  window.logger.log("doCustomFieldsFetchCustomFields", action);
  const { callbackSuccess = null, callbackError = null } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get all custom fields for this company
    const { data } = yield call(CustomFieldsApi.GetCustomFields, api, {
      token,
    });
    const { customFields } = data;
    yield put(
      CustomFields.customFieldsFetchCustomFieldsSuccess({
        customFields: sortBy(customFields, "createdAt"),
      })
    );
    if (callbackSuccess) {
      callbackSuccess(customFields);
    }
    return customFields;
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doCustomFieldsFetchCustomFields",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(CustomFields.customFieldsFetchCustomFieldsFailure());
    if (callbackError) {
      callbackError(error);
    }
    return [];
  }
}

function* doCustomFieldsCreate(
  action: Action<
    string,
    {
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(CustomFields.customFieldsCreateRequest());
  window.logger.log("doCustomFieldsCreate", action);
  const { body, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to create a new custom field
    const { data } = yield call(CustomFieldsApi.CreateCustomField, api, body, {
      token,
    });
    const { customField } = data;
    yield put(CustomFields.customFieldsCreateSuccess({ customField }));
    if (callbackSuccess) {
      callbackSuccess(customField);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doCustomFieldsCreate", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(CustomFields.customFieldsCreateFailure());
    const { message, details } = errors || "";
    if (callbackError) {
      let errorMsg = message;
      if (details && details.length > 0) {
        errorMsg = `${errorMsg}: ${details[0].msg}`;
      }
      callbackError(errorMsg);
    }
  }
}

function* doCustomFieldsEditCustomField(
  action: Action<
    string,
    {
      customFieldId: string;
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(CustomFields.customFieldsEditFieldRequest());
  window.logger.log("doCustomFieldsEditCustomField", action);
  const {
    customFieldId,
    body,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to update a custom field for that specific customFieldId
    const { data } = yield call(
      CustomFieldsApi.EditCustomField,
      api,
      customFieldId,
      body,
      {
        token,
      }
    );
    const { customField } = data;
    yield put(CustomFields.customFieldsEditFieldSuccess({ customField }));
    if (callbackSuccess) {
      yield put(CustomFields.customFieldsFetchCustomFields());
      callbackSuccess(customField);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doCustomFieldsEditCustomField",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(CustomFields.customFieldsEditFieldFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doCustomFieldsDeleteCustomField(
  action: Action<
    string,
    {
      customFieldId: string;
      customFieldModel: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(CustomFields.customFieldsDeleteFieldRequest());
  window.logger.log("doCustomFieldsDelete", action);
  const {
    customFieldId,
    customFieldModel,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to delete a custom field for that specific customFieldId
    yield call(CustomFieldsApi.DeleteCustomField, api, customFieldId, {
      token,
    });
    yield put(
      CustomFields.customFieldsDeleteFieldSuccess({
        customFieldId,
        customFieldModel,
      })
    );
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doCustomFieldsDeleteCustomField",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(CustomFields.customFieldsDeleteFieldFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

export default function* customFieldsSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(
      CustomFields.CUSTOM_FIELDS_FETCH_CUSTOM_FIELDS,
      doCustomFieldsFetchCustomFields
    ),
    takeLatest(CustomFields.CUSTOM_FIELDS_CREATE, doCustomFieldsCreate),
    takeLatest(
      CustomFields.CUSTOM_FIELDS_EDIT_FIELD,
      doCustomFieldsEditCustomField
    ),
    takeLatest(
      CustomFields.CUSTOM_FIELDS_DELETE_FIELD,
      doCustomFieldsDeleteCustomField
    ),
  ]);
}
