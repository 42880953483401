/* PERMISSIONS */
const onCheckResource = (apiResource: Object, resources: Array<string>) => {
  for (let resource in apiResource) {
    if (
      resources.indexOf(resource) !== -1 &&
      apiResource[resource]["isEnabled"]
    ) {
      return true;
    }
  }
  return false;
};
/* ROTATIONS */
export const onCheckAllRotationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "Rotations:create",
    "Rotations:read",
    "Rotations:update",
    "Rotations:delete",
    "my:Students#Rotations:read",
    "my#Rotations:read",
  ];
  const { Rotations } = permissions || {};
  if (Rotations && Rotations.apiValue) {
    return onCheckResource(Rotations.apiValue, resources);
  }
  return false;
};

export const onCheckRotationsPermissions = (permissions: ?Object): boolean => {
  const resources = [
    "Rotations:create",
    "Rotations:read",
    "my:Students#Rotations:read",
    "my#Rotations:read",
  ];
  const { Rotations } = permissions || {};
  if (Rotations && Rotations.apiValue) {
    return onCheckResource(Rotations.apiValue, resources);
  }
  return false;
};

export const onCheckReadRotationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "Rotations:read",
    "my:Students#Rotations:read",
    "my#Rotations:read",
  ];
  const { Rotations } = permissions || {};
  if (Rotations && Rotations.apiValue) {
    return onCheckResource(Rotations.apiValue, resources);
  }
  return false;
};

export const onCheckReadAllRotationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Rotations:read"];
  const { Rotations } = permissions || {};
  if (Rotations && Rotations.apiValue) {
    return onCheckResource(Rotations.apiValue, resources);
  }
  return false;
};

export const onCheckCreateRotationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Rotations:create"];
  const { Rotations } = permissions || {};
  if (Rotations && Rotations.apiValue) {
    return onCheckResource(Rotations.apiValue, resources);
  }
  return false;
};

export const onCheckEditRotationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Rotations:update"];
  const { Rotations } = permissions || {};
  if (Rotations && Rotations.apiValue) {
    return onCheckResource(Rotations.apiValue, resources);
  }
  return false;
};

export const onCheckDeleteRotationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Rotations:delete"];
  const { Rotations } = permissions || {};
  if (Rotations && Rotations.apiValue) {
    return onCheckResource(Rotations.apiValue, resources);
  }
  return false;
};

/* TERMS */
export const onCheckTermsPermissions = (permissions: ?Object): boolean => {
  const resources = ["Terms:create", "Terms:read"];
  const { Terms } = permissions || {};
  if (Terms && Terms.apiValue) {
    return onCheckResource(Terms.apiValue, resources);
  }
  return false;
};

export const onCheckReadTermsPermissions = (permissions: ?Object): boolean => {
  const resources = ["Terms:read"];
  const { Terms } = permissions || {};
  if (Terms && Terms.apiValue) {
    return onCheckResource(Terms.apiValue, resources);
  }
  return false;
};

export const onCheckCUDTermsPermissions = (permissions: ?Object): boolean => {
  const resources = ["Terms:create", "Terms:update", "Terms:delete"];
  const { Terms } = permissions || {};
  if (Terms && Terms.apiValue) {
    return onCheckResource(Terms.apiValue, resources);
  }
  return false;
};

export const onCheckCreateTermsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Terms:create"];
  const { Terms } = permissions || {};
  if (Terms && Terms.apiValue) {
    return onCheckResource(Terms.apiValue, resources);
  }
  return false;
};
export const onCheckEditTermsPermissions = (permissions: ?Object): boolean => {
  const resources = ["Terms:update"];
  const { Terms } = permissions || {};
  if (Terms && Terms.apiValue) {
    return onCheckResource(Terms.apiValue, resources);
  }
  return false;
};
export const onCheckDeleteTermsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Terms:delete"];
  const { Terms } = permissions || {};
  if (Terms && Terms.apiValue) {
    return onCheckResource(Terms.apiValue, resources);
  }
  return false;
};

/* PERIODS */
export const onCheckPeriodsPermissions = (permissions: ?Object): boolean => {
  const resources = ["Periods:create", "Periods:read"];
  const { Periods } = permissions || {};
  if (Periods && Periods.apiValue) {
    return onCheckResource(Periods.apiValue, resources);
  }
  return false;
};

export const onCheckReadPeriodsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Periods:read"];
  const { Periods } = permissions || {};
  if (Periods && Periods.apiValue) {
    return onCheckResource(Periods.apiValue, resources);
  }
  return false;
};

export const onCheckCUDPeriodsPermissions = (permissions: ?Object): boolean => {
  const resources = ["Periods:create", "Periods:update", "Periods:delete"];
  const { Periods } = permissions || {};
  if (Periods && Periods.apiValue) {
    return onCheckResource(Periods.apiValue, resources);
  }
  return false;
};

export const onCheckCreatePeriodsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Periods:create"];
  const { Periods } = permissions || {};
  if (Periods && Periods.apiValue) {
    return onCheckResource(Periods.apiValue, resources);
  }
  return false;
};
export const onCheckEditPeriodsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Periods:update"];
  const { Periods } = permissions || {};
  if (Periods && Periods.apiValue) {
    return onCheckResource(Periods.apiValue, resources);
  }
  return false;
};
export const onCheckDeletePeriodsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Periods:delete"];
  const { Periods } = permissions || {};
  if (Periods && Periods.apiValue) {
    return onCheckResource(Periods.apiValue, resources);
  }
  return false;
};
