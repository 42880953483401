import { createAction } from "deox";
// Actions for Periods
// Fetch all periods
export const PERIODS_FETCH_ALL: string = "PERIODS/FETCH/ALL";
export const PERIODS_FETCH_ALL_REQUEST: string = "PERIODS/FETCH/ALL/REQUEST";
export const PERIODS_FETCH_ALL_SUCCESS: string = "PERIODS/FETCH/ALL/SUCCESS";
export const PERIODS_FETCH_ALL_FAILURE: string = "PERIODS/FETCH/ALL/FAILURE";
export const periodsFetchAll = createAction(
  PERIODS_FETCH_ALL,
  (resolve) => (payload?: object) => resolve(payload)
);
export const periodsFetchAllRequest = createAction(PERIODS_FETCH_ALL_REQUEST);
export const periodsFetchAllSuccess = createAction(
  PERIODS_FETCH_ALL_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const periodsFetchAllFailure = createAction(PERIODS_FETCH_ALL_FAILURE);
// Create a Period
export const PERIODS_CREATE: string = "PERIODS/CREATE";
export const PERIODS_CREATE_REQUEST: string = "PERIODS/CREATE/REQUEST";
export const PERIODS_CREATE_SUCCESS: string = "PERIODS/CREATE/SUCCESS";
export const PERIODS_CREATE_FAILURE: string = "PERIODS/CREATE/FAILURE";
export const periodsCreate = createAction(
  PERIODS_CREATE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const periodsCreateRequest = createAction(PERIODS_CREATE_REQUEST);
export const periodsCreateSuccess = createAction(
  PERIODS_CREATE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const periodsCreateFailure = createAction(PERIODS_CREATE_FAILURE);
// Edit a Period
export const PERIODS_EDIT: string = "PERIODS/EDIT";
export const PERIODS_EDIT_REQUEST: string = "PERIODS/EDIT/REQUEST";
export const PERIODS_EDIT_SUCCESS: string = "PERIODS/EDIT/SUCCESS";
export const PERIODS_EDIT_FAILURE: string = "PERIODS/EDIT/FAILURE";
export const periodsEdit = createAction(
  PERIODS_EDIT,
  (resolve) => (payload?: object) => resolve(payload)
);
export const periodsEditRequest = createAction(PERIODS_EDIT_REQUEST);
export const periodsEditSuccess = createAction(
  PERIODS_EDIT_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const periodsEditFailure = createAction(PERIODS_EDIT_FAILURE);
// Delete a Period
export const PERIODS_DELETE: string = "PERIODS/DELETE";
export const PERIODS_DELETE_REQUEST: string = "PERIODS/DELETE/REQUEST";
export const PERIODS_DELETE_SUCCESS: string = "PERIODS/DELETE/SUCCESS";
export const PERIODS_DELETE_FAILURE: string = "PERIODS/DELETE/FAILURE";
export const periodsDelete = createAction(
  PERIODS_DELETE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const periodsDeleteRequest = createAction(PERIODS_DELETE_REQUEST);
export const periodsDeleteSuccess = createAction(
  PERIODS_DELETE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const periodsDeleteFailure = createAction(PERIODS_DELETE_FAILURE);
