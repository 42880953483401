// Import Features
import * as React from "react";
// Import Components
// import { BrowserRouter as Router } from "react-router-dom";
import RouterApp from "../router/";
// APP MAIN:
const AppMain = () => (
  <>
    <RouterApp />
  </>
);
export default AppMain;
