// @flow

/* PERMISSIONS */
const onCheckResource = (apiResource: Object, resources: Array<string>) => {
  for (let resource in apiResource) {
    if (
      resources.indexOf(resource) !== -1 &&
      apiResource[resource]['isEnabled']
    ) {
      return true;
    }
  }
  return false;
};

export const onCheckAllEvaluationTemplatesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    'EvaluationTemplates:create',
    'EvaluationTemplates:read',
    'EvaluationTemplates:update'
  ];
  const { EvaluationTemplates } = permissions || {};
  if (EvaluationTemplates && EvaluationTemplates.apiValue) {
    return onCheckResource(EvaluationTemplates.apiValue, resources);
  }
  return false;
};

export const onCheckEvaluationTemplatesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ['EvaluationTemplates:create', 'EvaluationTemplates:read'];
  const { EvaluationTemplates } = permissions || {};
  if (EvaluationTemplates && EvaluationTemplates.apiValue) {
    return onCheckResource(EvaluationTemplates.apiValue, resources);
  }
  return false;
};

export const onCheckReadEvaluationTemplatesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ['EvaluationTemplates:read'];
  const { EvaluationTemplates } = permissions || {};
  if (EvaluationTemplates && EvaluationTemplates.apiValue) {
    return onCheckResource(EvaluationTemplates.apiValue, resources);
  }
  return false;
};

export const onCheckCreateEvaluationTemplatesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ['EvaluationTemplates:create'];
  const { EvaluationTemplates } = permissions || {};
  if (EvaluationTemplates && EvaluationTemplates.apiValue) {
    return onCheckResource(EvaluationTemplates.apiValue, resources);
  }
  return false;
};

export const onCheckEditEvaluationTemplatesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ['EvaluationTemplates:update'];
  const { EvaluationTemplates } = permissions || {};
  if (EvaluationTemplates && EvaluationTemplates.apiValue) {
    return onCheckResource(EvaluationTemplates.apiValue, resources);
  }
  return false;
};

export const onCheckDeleteEvaluationTemplatesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ['EvaluationTemplates:delete'];
  const { EvaluationTemplates } = permissions || {};
  if (EvaluationTemplates && EvaluationTemplates.apiValue) {
    return onCheckResource(EvaluationTemplates.apiValue, resources);
  }
  return false;
};
