import { put, select, call, all, takeLatest } from "redux-saga/effects";
import EvaluationsApi from "../api/Evaluations";
import { Auth, EvaluationsSchedulers } from "../actions/";
// Type
import type { Action } from "deox";
import type { tAPI } from "../api/API";
//Sagas for Evaluations Schedulers
function* doEvaluationsSchedulersFetchEvaluationsSchedulers(
  action: Action<
    string,
    {
      evaluationTypes?: string[];
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(
    EvaluationsSchedulers.evaluationsSchedulersFetchEvaluationsSchedulersRequest()
  );
  window.logger.log(
    "doEvaluationsSchedulersFetchEvaluationsSchedulers",
    action
  );
  const {
    evaluationTypes = [],
    callbackSuccess = null,
    callbackError = null,
  } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get all evaluations for this company
    const { data } = yield call(
      EvaluationsApi.GetEvaluationsSchedulers,
      api,
      evaluationTypes,
      { token }
    );
    const { evaluationSchedulers } = data;
    yield put(
      EvaluationsSchedulers.evaluationsSchedulersFetchEvaluationsSchedulersSuccess(
        { evaluationSchedulers }
      )
    );
    if (callbackSuccess) {
      callbackSuccess(evaluationSchedulers);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doEvaluationsSchedulersFetchEvaluationsSchedulers",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(
      EvaluationsSchedulers.evaluationsSchedulersFetchEvaluationsSchedulersFailure()
    );
    if (callbackError) {
      callbackError(error);
    }
  }
}

function* doEvaluationsSchedulersFetchEvaluationScheduler(
  action: Action<
    string,
    {
      evaluationSchedulerId: string;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(
    EvaluationsSchedulers.evaluationsSchedulersFetchEvaluationSchedulerRequest()
  );
  window.logger.log("doEvaluationsSchedulersFetchEvaluationScheduler", action);
  const {
    evaluationSchedulerId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get a evaluation schedulers
    const { data } = yield call(
      EvaluationsApi.GetEvaluationSchedulers,
      api,
      evaluationSchedulerId,
      { token }
    );
    const { evaluationSchedulers } = data;
    yield put(
      EvaluationsSchedulers.evaluationsSchedulersFetchEvaluationSchedulerSuccess(
        { evaluationSchedulers }
      )
    );
    if (callbackSuccess) {
      callbackSuccess(evaluationSchedulers);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doEvaluationsSchedulersFetchEvaluationScheduler",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(
      EvaluationsSchedulers.evaluationsSchedulersFetchEvaluationSchedulerFailure()
    );
    if (callbackError) {
      callbackError(error);
    }
  }
}

function* doEvaluationsSchedulersCreate(
  action: Action<
    string,
    {
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(EvaluationsSchedulers.evaluationsSchedulersCreateRequest());
  window.logger.log("doEvaluationsSchedulersCreate", action);
  const { body, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to create new evaluations schedulers
    const { data } = yield call(
      EvaluationsApi.CreateEvaluationSchedulers,
      api,
      body,
      { token }
    );
    const { evaluationSchedulers } = data;
    yield put(EvaluationsSchedulers.evaluationsSchedulersCreateSuccess());
    if (callbackSuccess) {
      callbackSuccess(evaluationSchedulers);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doEvaluationsSchedulersCreate",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(EvaluationsSchedulers.evaluationsSchedulersCreateFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doEvaluationsSchedulersEdit(
  action: Action<
    string,
    {
      evaluationSchedulerId: string;
      evaluationTemplate: object;
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(EvaluationsSchedulers.evaluationsSchedulersEditRequest());
  window.logger.log("doEvaluationsSchedulersEdit", action);
  const {
    evaluationSchedulerId,
    evaluationTemplate,
    body,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to update a evaluation schedulers for that evaluationSchedulerId
    const { data } = yield call(
      EvaluationsApi.EditEvaluationSchedulers,
      api,
      evaluationSchedulerId,
      body,
      { token }
    );
    const { evaluationScheduler } = data;
    yield put(
      EvaluationsSchedulers.evaluationsSchedulersEditSuccess({
        evaluationScheduler: {
          ...evaluationScheduler,
          evaluationTemplate,
        },
      })
    );
    if (callbackSuccess) {
      yield put(
        EvaluationsSchedulers.evaluationsSchedulersFetchEvaluationsSchedulers()
      );
      callbackSuccess({
        ...evaluationScheduler,
        evaluationTemplate,
      });
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doEvaluationsSchedulersEdit",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(EvaluationsSchedulers.evaluationsSchedulersEditFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doEvaluationsSchedulersDelete(
  action: Action<
    string,
    {
      evaluationSchedulerId: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(EvaluationsSchedulers.evaluationsSchedulersDeleteRequest());
  window.logger.log("doEvaluationsSchedulersDelete", action);
  const {
    evaluationSchedulerId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to delete a evaluation schedulers for that specific evaluationSchedulerId
    yield call(
      EvaluationsApi.DeleteEvaluationSchedulers,
      api,
      evaluationSchedulerId,
      { token }
    );
    yield put(
      EvaluationsSchedulers.evaluationsSchedulersDeleteSuccess({
        evaluationSchedulerId,
      })
    );
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doEvaluationsSchedulersDelete",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(EvaluationsSchedulers.evaluationsSchedulersDeleteFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

export default function* evaluationsSchedulersSagas(): Generator<
  any,
  any,
  any
> {
  yield all([
    takeLatest(
      EvaluationsSchedulers.EVALUATIONS_SCHEDULERS_FETCH_EVALUATIONS_SCHEDULERS,
      doEvaluationsSchedulersFetchEvaluationsSchedulers
    ),
    takeLatest(
      EvaluationsSchedulers.EVALUATIONS_SCHEDULERS_CREATE,
      doEvaluationsSchedulersCreate
    ),
    takeLatest(
      EvaluationsSchedulers.EVALUATIONS_SCHEDULERS_FETCH_EVALUATION_SCHEDULER,
      doEvaluationsSchedulersFetchEvaluationScheduler
    ),
    takeLatest(
      EvaluationsSchedulers.EVALUATIONS_SCHEDULERS_EDIT,
      doEvaluationsSchedulersEdit
    ),
    takeLatest(
      EvaluationsSchedulers.EVALUATIONS_SCHEDULERS_DELETE,
      doEvaluationsSchedulersDelete
    ),
  ]);
}
