import type { Config, tAPI } from "./API";
// API Company static class

const apiBaseCompany = "/companies";
export default class Company {
  // Get a company for that companyId
  static GetCompany(api: tAPI, companyId: string, config?: Config) {
    return api.GET(`${apiBaseCompany}/${companyId}`, config);
  }
  // Edit a company from companyId
  static EditCompany(
    api: tAPI,
    companyId: string,
    data: object,
    isUpload: boolean = false,
    config?: Config
  ) {
    if (isUpload) {
      return api.UPLOAD(
        `${apiBaseCompany}/${companyId}`,
        data,
        "PATCH",
        config
      );
    }
    return api.PATCH(`${apiBaseCompany}/${companyId}`, data, config);
  }
  // Get a Company files for that companyId
  static GetCompanyFiles(api: tAPI, companyId: string, config?: Config) {
    return api.GET(
      `/files?populated=true&belongsTo=Companies&owner=${companyId}`,
      config
    );
  }
  // Get all Company files expired
  static GetCompanyFilesExpired(api: tAPI, config?: Config) {
    return api.GET(
      `/files?populated=true&aboutExpire=true&belongsTo=Companies`,
      config
    );
  }
  // Upload a company files
  static UploadCompanyFiles(api: tAPI, data: object, config?: Config) {
    return api.UPLOAD(`/files`, data, "POST", config);
  }
}
