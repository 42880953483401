// Import Features
import { FileCategories } from "../actions/";
import { updateArrayWithElement } from "../../utils/";
// FileCategories Reducer
type State = {
  fileCategories: object[];
};
type Action = {
  type: string;
  payload: Object;
};

const initialState: State = {
  fileCategories: [],
};

export default function fileCategories(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    //FileCategories
    case FileCategories.FILE_CATEGORIES_FETCH_FILE_CATEGORIES_SUCCESS:
      return Object.assign({}, state, {
        fileCategories: action.payload.fileCategories,
      });
    case FileCategories.FILE_CATEGORIES_CREATE_SUCCESS:
      return Object.assign({}, state, {
        fileCategories: [...state.fileCategories, action.payload.fileCategory],
      });
    case FileCategories.FILE_CATEGORIES_EDIT_SUCCESS:
      return Object.assign({}, state, {
        fileCategories: updateArrayWithElement(
          state.fileCategories,
          action.payload.fileCategory
        ),
      });
    case FileCategories.FILE_CATEGORIES_DELETE_SUCCESS:
      return Object.assign({}, state, {
        fileCategories: state.fileCategories.filter(
          ({ _id }) => _id !== action.payload.fileCategoryId
        ),
      });
    // same state (not change)
    default:
      return state;
  }
}
