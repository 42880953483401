// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
// Features
import * as React from "react";
import classNames from "classnames";
// Components
import { Grid } from "semantic-ui-react";
import NotificationsDate from "./NotificationsDate";
const NotificationReport = ({
  _id,
  readBy,
  report,
  message,
  createdAt,
  goTo,
}: Object): React$Element<any> => (
  <div
    className={classNames("notification", {
      new: !readBy || (readBy && readBy.length === 0),
    })}
  >
    <Grid columns={2}>
      <Grid.Row className="nopadding">
        <Grid.Column mobile={3} tablet={3} computer={3} className="">
          <i className="icono icono-calendar-fill huge skejule-teal" />
        </Grid.Column>
        <Grid.Column mobile={13} tablet={13} computer={13} className="">
          <div className="message">
            {message.split(/({reportName})/gi).map((el, index) => {
              if (el === "{reportName}") {
                return (
                  <a
                    key={(report && report._id) + index}
                    style={{ cursor: "pointer" }}
                    onClick={goTo(`/reports/dashboard/custom-reports`, {
                      namePage: "Reports",
                    })}
                  >
                    {report && report.name}
                  </a>
                );
              }
              return el;
            })}
          </div>
          <NotificationsDate
            createdAt={createdAt}
            isMarkAsRead={!!readBy && readBy?.length > 0}
            notificationId={_id}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
);

export default NotificationReport;
