import {
  caseLogsRequiredFields,
  caseLogsEnabledFields,
} from "../reducers/tenantSettings";
// Types
import type { Config, tAPI } from "./API";
import type { TenantSettingName } from "../reducers/tenantSettings";
// API Tenant Settings static class
const apiBaseTenantSettings = "/tenant-settings";
//METHODS
const _onGetEndPoint = (
  method: "POST" | "PATCH" | "DELETE",
  tenantSettingName: TenantSettingName,
  tenantSettingValue: any,
  tenantSetting: any
) => {
  let apiEndPoint: any = { apiRoute: "", body: null };
  switch (tenantSettingName) {
    case "SHOW_DEACTIVATED_USERS":
      apiEndPoint = {
        apiRoute: "/show-deactivated-users",
        body: {
          show: tenantSettingValue,
        },
      };
      break;
    case "RETROACTIVE_DATE":
      apiEndPoint = {
        apiRoute: "/retroactive-date",
        body: {
          retroactiveDate: tenantSettingValue,
        },
      };
      break;
    case "CASELOGS_DESIGN_VERSION":
      apiEndPoint = {
        apiRoute: "/caselogs-design-version",
        body: {
          design: tenantSettingValue,
        },
      };
      break;
    case "EDIT_PENDING_CASELOGS":
    case "CASELOGS_AUTO_APPROVAL":
    case "ENABLE_DUPLICATED_CASE_LOG_CHECKS":
    case "ALLOW_ALPHANUMERICS_CLINIC_RECORD_NUMBER":
      const apiRoutesEndpoints = {
        EDIT_PENDING_CASELOGS: "/pending-caselogs-editon",
        CASELOGS_AUTO_APPROVAL: "/caselogs-auto-approval",
        ENABLE_DUPLICATED_CASE_LOG_CHECKS: "/enable-duplicated-caselogs-check",
        ALLOW_ALPHANUMERICS_CLINIC_RECORD_NUMBER:
          "/allow-alphanumeric-record-numbers",
      };
      apiEndPoint = {
        apiRoute: apiRoutesEndpoints[tenantSettingName],
        body: {
          enabled: tenantSettingValue,
        },
      };
      break;
    case "CASELOGS_AGE_REQUIRED_FIELD":
    case "CASELOGS_FIRST_LANGUAGE_REQUIRED_FIELD":
    case "CASELOGS_GENDER_REQUIRED_FIELD":
    case "CASELOGS_CLINIC_RECORD_NUMBER_REQUIRED_FIELD":
    case "CASELOGS_RACE_REQUIRED_FIELD":
    case "CASELOGS_PMCC_REQUIRED_FIELD":
    case "CASELOGS_INSURANCE_REQUIRED_FIELD":
    case "CASELOGS_APPOINTMENT_DATE_REQUIRED_FIELD":
    case "CASELOGS_CHIEF_COMPLAINT_REQUIRED_FIELD":
    case "CASELOGS_TIME_WITH_PATIENT_REQUIRED_FIELD":
    case "CASELOGS_REASON_FOR_VISIT_REQUIRED_FIELD":
    case "CASELOGS_CONSULT_WITH_PRECEPTOR_REQUIRED_FIELD":
    case "CASELOGS_PROCEDURE_STATUS_REQUIRED_FIELD":
    case "CASELOGS_DIAGNOSIS_AND_PROCEDURE_NOTES_REQUIRED_FIELD":
    case "CASELOGS_AGE_ENABLED_FIELD":
    case "CASELOGS_FIRST_LANGUAGE_ENABLED_FIELD":
    case "CASELOGS_GENDER_ENABLED_FIELD":
    case "CASELOGS_CLINIC_RECORD_NUMBER_ENABLED_FIELD":
    case "CASELOGS_RACE_ENABLED_FIELD":
    case "CASELOGS_PMCC_ENABLED_FIELD":
    case "CASELOGS_INSURANCE_ENABLED_FIELD":
    case "CASELOGS_APPOINTMENT_DATE_ENABLED_FIELD":
    case "CASELOGS_CHIEF_COMPLAINT_ENABLED_FIELD":
    case "CASELOGS_TIME_WITH_PATIENT_ENABLED_FIELD":
    case "CASELOGS_REASON_FOR_VISIT_ENABLED_FIELD":
    case "CASELOGS_CONSULT_WITH_PRECEPTOR_ENABLED_FIELD":
    case "CASELOGS_DISCIPLINE_FOR_DIAGNOSES_AND_DIAGNOSIS_ENABLED_FIELD":
    case "CASELOGS_DIAGNOSIS_AND_PROCEDURE_NOTES_ENABLED_FIELD":
    case "CASELOGS_PROCEDURE_STATUS_ENABLED_FIELD":
      let field =
        caseLogsRequiredFields[
          tenantSettingName as keyof typeof caseLogsRequiredFields
        ];
      if (tenantSettingName.includes("ENABLED")) {
        field =
          caseLogsEnabledFields[
            tenantSettingName as keyof typeof caseLogsEnabledFields
          ];
      }
      apiEndPoint = {
        apiRoute: "/models-fields",
        body: {
          model: "CaseLogs",
          field,
          ...tenantSettingValue,
        },
      };
      break;
    case "CASELOGS_LANGUAGE_LIST":
      switch (method) {
        case "POST":
          apiEndPoint = {
            apiRoute: `/caselogs-language`,
            body: {
              languages: [tenantSettingValue],
            },
          };
          break;
        case "PATCH":
          apiEndPoint = {
            apiRoute: `/caselogs-language/${tenantSettingValue._id}`,
            body: {
              language: tenantSettingValue.value,
            },
          };
          break;
        case "DELETE":
          apiEndPoint = {
            apiRoute: `/caselogs-language/${tenantSettingValue._id}`,
            body: null,
          };
          break;
        default:
      }
      break;
    case "CASELOGS_GENDER_LIST":
      switch (method) {
        case "POST":
          apiEndPoint = {
            apiRoute: `/caselogs-gender`,
            body: {
              genders: [tenantSettingValue],
            },
          };
          break;
        case "PATCH":
          apiEndPoint = {
            apiRoute: `/caselogs-gender/${tenantSettingValue._id}`,
            body: {
              gender: tenantSettingValue.value,
            },
          };
          break;
        case "DELETE":
          apiEndPoint = {
            apiRoute: `/caselogs-gender/${tenantSettingValue._id}`,
            body: null,
          };
          break;
        default:
      }
      break;
    case "CASELOGS_INSURANCE_LIST":
      switch (method) {
        case "POST":
          apiEndPoint = {
            apiRoute: `/caselogs-insurance`,
            body: {
              insurances: [tenantSettingValue],
            },
          };
          break;
        case "PATCH":
          apiEndPoint = {
            apiRoute: `/caselogs-insurance/${tenantSettingValue._id}`,
            body: {
              insurance: tenantSettingValue.value,
            },
          };
          break;
        case "DELETE":
          apiEndPoint = {
            apiRoute: `/caselogs-insurance/${tenantSettingValue._id}`,
            body: null,
          };
          break;
        default:
      }
      break;
    case "CASELOGS_RACES_LIST":
      switch (method) {
        case "POST":
          apiEndPoint = {
            apiRoute: `/caselogs-races`,
            body: {
              races: [tenantSettingValue],
            },
          };
          break;
        case "PATCH":
          apiEndPoint = {
            apiRoute: `/caselogs-races/${tenantSettingValue._id}`,
            body: {
              race: tenantSettingValue.value,
            },
          };
          break;
        case "DELETE":
          apiEndPoint = {
            apiRoute: `/caselogs-races/${tenantSettingValue._id}`,
            body: null,
          };
          break;
        default:
      }
      break;
    case "CASELOGS_REASONS_FOR_VISIT_LIST":
      switch (method) {
        case "POST":
          apiEndPoint = {
            apiRoute: `/caselogs-reasons-for-visit`,
            body: {
              reasonsForVisit: [tenantSettingValue],
            },
          };
          break;
        case "PATCH":
          apiEndPoint = {
            apiRoute: `/caselogs-reasons-for-visit/${tenantSettingValue._id}`,
            body: {
              reasonForVisit: tenantSettingValue.value,
            },
          };
          break;
        case "DELETE":
          apiEndPoint = {
            apiRoute: `/caselogs-reasons-for-visit/${tenantSettingValue._id}`,
            body: null,
          };
          break;
        default:
      }
      break;
    case "CASELOGS_DISCIPLINES_LIST_FOR_DIAGNOSTICS":
      switch (method) {
        case "POST":
          apiEndPoint = {
            apiRoute: `/caselogs-diagnostic-disciplines`,
            body: {
              diagnosticDisciplines: [tenantSettingValue],
            },
          };
          break;
        case "PATCH":
          apiEndPoint = {
            apiRoute: `/caselogs-diagnostic-disciplines/${tenantSettingValue._id}`,
            body: {
              diagnosticDiscipline: tenantSettingValue.value,
            },
          };
          break;
        case "DELETE":
          apiEndPoint = {
            apiRoute: `/caselogs-diagnostic-disciplines/${tenantSettingValue._id}`,
            body: null,
          };
          break;
        default:
      }
      break;
    case "CASELOGS_DIAGNOSTICS_LIST":
      switch (method) {
        case "POST":
          apiEndPoint = {
            apiRoute: `/caselogs-diagnostics`,
            body: {
              diagnostics: [tenantSettingValue],
            },
          };
          break;
        case "PATCH":
          apiEndPoint = {
            apiRoute: `/caselogs-diagnostics/${tenantSettingValue._id}`,
            body: tenantSettingValue.value,
          };
          break;
        case "DELETE":
          apiEndPoint = {
            apiRoute: `/caselogs-diagnostics/${tenantSettingValue._id}`,
            body: null,
          };
          break;
        default:
      }
      break;
    case "CASELOGS_DISCIPLINES_LIST_FOR_PROCEDURES":
      switch (method) {
        case "POST":
          apiEndPoint = {
            apiRoute: `/caselogs-procedure-disciplines`,
            body: {
              procedureDisciplines: [tenantSettingValue],
            },
          };
          break;
        case "PATCH":
          apiEndPoint = {
            apiRoute: `/caselogs-procedure-disciplines/${tenantSettingValue._id}`,
            body: {
              procedureDiscipline: tenantSettingValue.value,
            },
          };
          break;
        case "DELETE":
          apiEndPoint = {
            apiRoute: `/caselogs-procedure-disciplines/${tenantSettingValue._id}`,
            body: null,
          };
          break;
        default:
      }
      break;
    case "CASELOGS_PROCEDURES_LIST":
      switch (method) {
        case "POST":
          apiEndPoint = {
            apiRoute: `/caselogs-procedures`,
            body: {
              procedures: [tenantSettingValue],
            },
          };
          break;
        case "PATCH":
          apiEndPoint = {
            apiRoute: `/caselogs-procedures/${tenantSettingValue._id}`,
            body: tenantSettingValue.value,
          };
          break;
        case "DELETE":
          apiEndPoint = {
            apiRoute: `/caselogs-procedures/${tenantSettingValue._id}`,
            body: null,
          };
          break;
        default:
      }
      break;
    case "CASELOGS_PROCEDURES_STATUS_LIST":
      switch (method) {
        case "POST":
          apiEndPoint = {
            apiRoute: `/caselogs-procedure-status`,
            body: {
              proceduresStatus: [tenantSettingValue],
            },
          };
          break;
        case "PATCH":
          apiEndPoint = {
            apiRoute: `/caselogs-procedure-status/${tenantSettingValue._id}`,
            body: {
              procedureStatus: tenantSettingValue.value,
            },
          };
          break;
        case "DELETE":
          apiEndPoint = {
            apiRoute: `/caselogs-procedure-status/${tenantSettingValue._id}`,
            body: null,
          };
          break;
        default:
      }
      break;
    case "CASELOGS_QUESTIONS_REVIEW":
      switch (method) {
        case "POST":
          apiEndPoint = {
            apiRoute: `/caselogs-questions-review`,
            body: {
              proceduresReviews: [tenantSettingValue],
            },
          };
          break;
        case "PATCH":
          apiEndPoint = {
            apiRoute: `/caselogs-questions-review/${tenantSettingValue._id}`,
            body: tenantSettingValue.value,
          };
          break;
        case "DELETE":
          apiEndPoint = {
            apiRoute: `/caselogs-questions-review/${tenantSettingValue._id}`,
            body: null,
          };
          break;
        default:
      }
      break;
    case "CASELOGS_TOOTH_LIST":
      switch (method) {
        case "POST":
          apiEndPoint = {
            apiRoute: `/caselogs-tooth`,
            body: { teeth: [tenantSettingValue] },
          };
          break;
        case "PATCH":
          apiEndPoint = {
            apiRoute: `/caselogs-tooth/${tenantSettingValue._id}`,
            body: tenantSettingValue.value,
          };
          break;
        case "DELETE":
          apiEndPoint = {
            apiRoute: `/caselogs-tooth/${tenantSettingValue._id}`,
            body: null,
          };
          break;
        default:
      }
      break;
    case "EVALUATIONS_NOTIFICATIONS_EMAIL":
      apiEndPoint = {
        apiRoute: "/evaluations-notifications-email",
        body: {
          emails: tenantSettingValue.emails,
          enabled: tenantSettingValue.enabled,
        },
      };
      break;
    case "ROTATIONS_OVERLAPPING_VALIDATION":
      apiEndPoint = {
        apiRoute: "/rotations-overlapping-validation",
        body: {
          enabled: tenantSettingValue,
        },
      };
      break;
    case "ALLOW_AUTOMATIC_ROTATIONS_SCHEDULING_PARAMETERS":
      apiEndPoint = {
        apiRoute: "/allow-automatic-rotations-parameters",
        body: {
          enabled: tenantSettingValue,
        },
      };
      break;
    case "AUTOMATIC_ROTATIONS_SCHEDULING_PARAMETERS":
      switch (method) {
        case "POST":
          apiEndPoint = {
            apiRoute: `/automatic-rotations-parameters`,
            body: {
              automaticRotationsParameters: [tenantSettingValue],
            },
          };
          break;
        case "PATCH":
          apiEndPoint = {
            apiRoute: `/automatic-rotations-parameters/${tenantSettingValue._id}`,
            body: {
              automaticRotationsParameter: tenantSettingValue.value,
            },
          };
          break;
        case "DELETE":
          apiEndPoint = {
            apiRoute: `/automatic-rotations-parameters/${tenantSettingValue._id}`,
            body: null,
          };
          break;
        default:
      }
      break;
    case "INSTITUTIONS_TYPES_LIST":
      switch (method) {
        case "POST":
          apiEndPoint = {
            apiRoute: `/institutions-types`,
            body: {
              institutionsTypes: tenantSettingValue,
            },
          };
          break;
        case "PATCH":
          apiEndPoint = {
            apiRoute: `/institutions-types/${tenantSettingValue._id}`,
            body: {
              institutionType: tenantSettingValue.type,
            },
          };
          break;
        case "DELETE":
          apiEndPoint = {
            apiRoute: `/institutions-types/${tenantSettingValue._id}`,
            body: null,
          };
          break;
        default:
      }
      break;
    case "NOTIFICATIONS_SETTINGS":
      apiEndPoint = {
        apiRoute: `/notifications-settings/${tenantSettingValue._id}`,
        body: {
          receivers: tenantSettingValue.receivers,
          channels: tenantSettingValue.channels,
        },
      };
      break;
    default:
  }
  return apiEndPoint;
};
export default class TenantSettings {
  // Get a list of Tenant Settings
  static GetTenantSettings(
    api: tAPI,
    tenantSettingName?: TenantSettingName,
    config?: Config
  ) {
    if (tenantSettingName) {
      return api.GET(
        `${apiBaseTenantSettings}?name=${tenantSettingName}`,
        config
      );
    }
    return api.GET(`${apiBaseTenantSettings}`, config);
  }
  // Create - Update - Delete a Tenant Setting
  static CUDTenantSetting(
    api: tAPI,
    method: "POST" | "PATCH" | "DELETE",
    tenantSettingName: TenantSettingName,
    tenantSettingValue: any,
    tenantSetting: any,
    config?: Config
  ) {
    const { apiRoute, body } = _onGetEndPoint(
      method,
      tenantSettingName,
      tenantSettingValue,
      tenantSetting
    );
    if (method === "DELETE") {
      return api.DELETE(`${apiBaseTenantSettings}${apiRoute}`, config);
    }
    return api[method](`${apiBaseTenantSettings}${apiRoute}`, body, config);
  }
}
