// Inclusive sagas
import { all, fork } from "redux-saga/effects";
import { logger } from "./logger";
import accessControlSagas from "./accessControl";
import accountSettings from "./accountSettings";
import authSagas from "./auth";
import broadcastMessagesSagas from "./broadcastMessages";
import caseLogsSagas from "./caseLogs";
import cohortsSagas from "./cohorts";
import companySagas from "./company";
import customFieldsSagas from "./customFields";
import enrollmentsSagas from "./enrollments";
import evaluationsSagas from "./evaluations";
import evaluationsSchedulersSagas from "./evaluationsSchedulers";
import evaluationsTemplatesSagas from "./evaluationsTemplates";
import feedbackFormSagas from "./feedbackForm";
import files from "./files";
import fileCategoriesSagas from "./fileCategories";
import importDataSagas from "./importData";
import invitationsSagas from "./invitations";
import institutionsSagas from "./institutions";
import metricsSagas from "./metrics";
import notificationsSagas from "./notifications";
import periodsSagas from "./periods";
import programsSagas from "./programs";
import reportsSagas from "./reports";
import rotationsSagas from "./rotations";
import tenantSettingsSagas from "./tenantSettings";
import termsSagas from "./terms";
import usersSagas from "./users";
import usersPortfolioSagas from "./portfolio";

function* readySaga() {
  // init logger:
  yield logger();
  yield window.logger.log("Sagas Heto App Ready!");
}

export function* sagas(): Generator<any, any, any> {
  yield all([
    fork(readySaga),
    fork(accessControlSagas),
    fork(accountSettings),
    fork(authSagas),
    fork(broadcastMessagesSagas),
    fork(companySagas),
    fork(usersSagas),
    fork(tenantSettingsSagas),
    fork(customFieldsSagas),
    fork(notificationsSagas),
    fork(institutionsSagas),
    fork(programsSagas),
    fork(cohortsSagas),
    fork(fileCategoriesSagas),
    fork(files),
    fork(enrollmentsSagas),
    fork(rotationsSagas),
    fork(termsSagas),
    fork(periodsSagas),
    fork(caseLogsSagas),
    fork(evaluationsSagas),
    fork(evaluationsSchedulersSagas),
    fork(evaluationsTemplatesSagas),
    fork(reportsSagas),
    fork(metricsSagas),
    fork(invitationsSagas),
    fork(usersPortfolioSagas),
    fork(importDataSagas),
    fork(feedbackFormSagas),
  ]);
}
