// @flow

/* PERMISSIONS */
const onCheckResource = (apiResource: Object, resources: Array<string>) => {
  for (let resource in apiResource) {
    if (
      resources.indexOf(resource) !== -1 &&
      apiResource[resource]['isEnabled']
    ) {
      return true;
    }
  }
  return false;
};

export const onCheckAllInstitutionsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    'Institutions:create',
    'Institutions:read',
    'Institutions:update',
    'my:Students#Institutions:*',
    'my#Institutions:read',
    'my#Institutions:update'
  ];
  const { Institutions } = permissions || {};
  if (Institutions && Institutions.apiValue) {
    return onCheckResource(Institutions.apiValue, resources);
  }
  return false;
};
// INSTITUTIONS
export const onCheckInstitutionsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ['Institutions:create', 'Institutions:read'];
  const { Institutions } = permissions || {};
  if (Institutions && Institutions.apiValue) {
    return onCheckResource(Institutions.apiValue, resources);
  }
  return false;
};
export const onCheckReadInstitutionsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ['Institutions:read'];
  const { Institutions } = permissions || {};
  if (Institutions && Institutions.apiValue) {
    return onCheckResource(Institutions.apiValue, resources);
  }
  return false;
};
export const onCheckEditInstitutionsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ['Institutions:update'];
  const { Institutions } = permissions || {};
  if (Institutions && Institutions.apiValue) {
    return onCheckResource(Institutions.apiValue, resources);
  }
  return false;
};
export const onCheckCreateInstitutionsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ['Institutions:create'];
  const { Institutions } = permissions || {};
  if (Institutions && Institutions.apiValue) {
    return onCheckResource(Institutions.apiValue, resources);
  }
  return false;
};
// />
// MY INSTITUTIONS
export const onCheckReadMyInstitutionsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ['my#Institutions:read'];
  const { Institutions } = permissions || {};
  if (Institutions && Institutions.apiValue) {
    return onCheckResource(Institutions.apiValue, resources);
  }
  return false;
};
export const onCheckEditMyInstitutionsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ['my#Institutions:update'];
  const { Institutions } = permissions || {};
  if (Institutions && Institutions.apiValue) {
    return onCheckResource(Institutions.apiValue, resources);
  }
  return false;
};
// />
