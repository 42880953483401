// Features
import produce from "immer";
import { FeedbackForm } from "../actions";
// Types
import type { Action } from "deox";
type FeedbackFormActionReducers = Action<string>;
export type State = {
  openCloseFeedbackForm: boolean;
};
// Feedback Form Reducer
const initialState: State = {
  openCloseFeedbackForm: false,
};
const feedbackForm: (
  state: State,
  action: FeedbackFormActionReducers
) => State = produce((draft: State, action: FeedbackFormActionReducers) => {
  switch (action.type) {
    case FeedbackForm.FEEDBACK_FORM_OPEN_CLOSE:
      draft.openCloseFeedbackForm = !draft.openCloseFeedbackForm;
      break;
  }
}, initialState);
export default feedbackForm;
