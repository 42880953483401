// Features
import * as React from "react";
import { useSelector } from "react-redux";
// Components
import { Icon, Message } from "semantic-ui-react";
// Types
import type { RootState } from "../../store/reducers";
import type { State as StateCameraDevice } from "../../store/reducers/cameraDevice";
//
const CameraWarningContainer: React.FC = () => {
  // STORE - Camera Device
  const { showCameraWarningMsg = false }: StateCameraDevice = useSelector(
    ({ cameraDevice }: RootState) => cameraDevice
  );
  if (showCameraWarningMsg) {
    return <CameraWarningView />;
  }
  return null;
};
const CameraWarningView: React.FC = () => (
  <div
    style={{
      zIndex: 9999,
      position: "fixed",
      top: "25px",
      left: "80px",
    }}
  >
    <Message className="camera-warning-msg" warning>
      <Message.Header className="pulse-warning">
        <Icon
          className="skejule-brown relative"
          name="arrow alternate circle up"
          size="big"
        />{" "}
        Allow camera access
      </Message.Header>
    </Message>
  </div>
);
export default CameraWarningContainer;
