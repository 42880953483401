import { createAction } from "deox";
// Actions for INVITATIONS

// Fetch all Invitations
export const INVITATIONS_FETCH_INVITATIONS: string =
  "INVITATIONS/FETCH/INVITATIONS";
export const INVITATIONS_FETCH_INVITATIONS_REQUEST: string =
  "INVITATIONS/FETCH/INVITATIONS/REQUEST";
export const INVITATIONS_FETCH_INVITATIONS_SUCCESS: string =
  "INVITATIONS/FETCH/INVITATIONS/SUCCESS";
export const INVITATIONS_FETCH_INVITATIONS_FAILURE: string =
  "INVITATIONS/FETCH/INVITATIONS/FAILURE";
export const invitationsFetchInvitations = createAction(
  INVITATIONS_FETCH_INVITATIONS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const invitationsFetchInvitationsRequest = createAction(
  INVITATIONS_FETCH_INVITATIONS_REQUEST
);
export const invitationsFetchInvitationsSuccess = createAction(
  INVITATIONS_FETCH_INVITATIONS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const invitationsFetchInvitationsFailure = createAction(
  INVITATIONS_FETCH_INVITATIONS_FAILURE
);
// Create a Invitation for Users
export const INVITATIONS_CREATE: string = "INVITATIONS/CREATE";
export const INVITATIONS_CREATE_REQUEST: string = "INVITATIONS/CREATE/REQUEST";
export const INVITATIONS_CREATE_SUCCESS: string = "INVITATIONS/CREATE/SUCCESS";
export const INVITATIONS_CREATE_FAILURE: string = "INVITATIONS/CREATE/FAILURE";
export const invitationsCreate = createAction(
  INVITATIONS_CREATE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const invitationsCreateRequest = createAction(
  INVITATIONS_CREATE_REQUEST
);
export const invitationsCreateSuccess = createAction(
  INVITATIONS_CREATE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const invitationsCreateFailure = createAction(
  INVITATIONS_CREATE_FAILURE
);
// Resend invitation(s)
export const INVITATIONS_RESEND: string = "INVITATIONS/RESEND";
export const INVITATIONS_RESEND_REQUEST: string = "INVITATIONS/RESEND/REQUEST";
export const INVITATIONS_RESEND_SUCCESS: string = "INVITATIONS/RESEND/SUCCESS";
export const INVITATIONS_RESEND_FAILURE: string = "INVITATIONS/RESEND/FAILURE";
export const invitationsResend = createAction(
  INVITATIONS_RESEND,
  (resolve) => (payload?: object) => resolve(payload)
);
export const invitationsResendRequest = createAction(
  INVITATIONS_RESEND_REQUEST
);
export const invitationsResendSuccess = createAction(
  INVITATIONS_RESEND_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const invitationsResendFailure = createAction(
  INVITATIONS_RESEND_FAILURE
);
// Active a invitation
export const INVITATIONS_ACTIVE: string = "INVITATIONS/ACTIVE";
export const INVITATIONS_ACTIVE_REQUEST: string = "INVITATIONS/ACTIVE/REQUEST";
export const INVITATIONS_ACTIVE_SUCCESS: string = "INVITATIONS/ACTIVE/SUCCESS";
export const INVITATIONS_ACTIVE_FAILURE: string = "INVITATIONS/ACTIVE/FAILURE";
export const invitationsActive = createAction(
  INVITATIONS_ACTIVE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const invitationsActiveRequest = createAction(
  INVITATIONS_ACTIVE_REQUEST
);
export const invitationsActiveSuccess = createAction(
  INVITATIONS_ACTIVE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const invitationsActiveFailure = createAction(
  INVITATIONS_ACTIVE_FAILURE
);
// Delete a invitation
export const INVITATIONS_DELETE: string = "INVITATIONS/DELETE";
export const INVITATIONS_DELETE_REQUEST: string = "INVITATIONS/DELETE/REQUEST";
export const INVITATIONS_DELETE_SUCCESS: string = "INVITATIONS/DELETE/SUCCESS";
export const INVITATIONS_DELETE_FAILURE: string = "INVITATIONS/DELETE/FAILURE";
export const invitationsDelete = createAction(
  INVITATIONS_DELETE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const invitationsDeleteRequest = createAction(
  INVITATIONS_DELETE_REQUEST
);
export const invitationsDeleteSuccess = createAction(
  INVITATIONS_DELETE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const invitationsDeleteFailure = createAction(
  INVITATIONS_DELETE_FAILURE
);
// Reset Invitation Loaded
export const INVITATIONS_RESET_INVITATION_LOADED: string =
  "INVITATIONS/RESET/INVITATION_LOADED";
export const invitationsResetInvitationLoaded = createAction(
  INVITATIONS_RESET_INVITATION_LOADED
);
