import type { Config, tAPI } from "./API";
import type { StateAccountSettings } from "../../types/accountSettings.types";
import type { StateUsersManager, SortBy } from "../../types/users.types";
// API Users static class
const apiBaseUsers = "/users";
const onSetSortParam = (
  sortParam: keyof SortBy,
  sortByFieldName: StateAccountSettings["frontendPersonalization"]["USER_MANAGER_SETTINGS"]["sortByName"]
) => {
  if (sortParam === "name") {
    if (sortByFieldName === "lastName") {
      return "last_name";
    }
    return "first_name";
  }
  return sortParam;
};
export default class Users {
  // Get a list of users by roles | programs | rotations | cohorts | terms
  static GetUsers(
    api: tAPI,
    populated: boolean,
    skip: number,
    limit: number,
    roles: string[],
    institutions: string[],
    search: string,
    status: StateUsersManager["filters"]["status"],
    activeRotation: boolean,
    programs: string[],
    cohorts: string[],
    sortBy: SortBy,
    sortByName: StateAccountSettings["frontendPersonalization"]["USER_MANAGER_SETTINGS"]["sortByName"],
    config?: Config
  ) {
    let queryParams = "";
    if (skip >= 0) {
      queryParams += `&skip=${skip}`;
    }
    if (limit > 0) {
      queryParams += `&limit=${limit}`;
    }
    if (roles.length > 0) {
      queryParams += `&roles=${roles.join()}`;
    }
    if (institutions.length > 0) {
      queryParams += `&institutions=${institutions.join()}`;
    }
    if (search) {
      queryParams += `&search=${search}`;
    }
    if (status.length > 0) {
      queryParams += `&status=${status.join()}`;
    }
    if (activeRotation) {
      queryParams += `&activeRotation=true`;
    }
    if (programs.length > 0) {
      queryParams += `&programs=${programs.join()}`;
    }
    if (cohorts.length > 0) {
      queryParams += `&cohorts=${cohorts.join()}`;
    }
    if (populated) {
      let sortByParams = Object.keys(sortBy)
        .filter((sortKey) => sortBy[sortKey as keyof SortBy])
        .map(
          (sortParam) =>
            `sort_${onSetSortParam(sortParam as keyof SortBy, sortByName)}=${
              sortBy[sortParam as keyof SortBy] === "ascending" ? 1 : -1
            }`
        )
        .join("&");
      if (sortByParams) {
        sortByParams = "&" + sortByParams;
      }
      queryParams = "?populated=true" + queryParams + sortByParams;
    } else if (queryParams.length > 0) {
      queryParams = "?" + queryParams.slice(1);
    }
    return api.GET(`${apiBaseUsers}${queryParams}`, config);
  }
  // Get All User Accounts Available from this userEmail
  static GetUserAccounts(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBaseUsers}/accounts`, data, {
      ...config,
      tenant: "",
    });
  }
  // Get a user from userId
  static GetUser(api: tAPI, userId: string, config?: Config) {
    return api.GET(`${apiBaseUsers}/${userId}?populated=true`, config);
  }
  static GetUserKpis(api: tAPI, userId: string, config?: Config) {
    return api.GET(`/case-logs/kpis?user=${userId}`, config);
  }
  static GetUserAvailabilities(api: tAPI, userId: string, config?: Config) {
    return api.GET(`/availabilities?user=${userId}`, config);
  }
  static CreateUserAvailabilities(api: tAPI, data: object, config?: Config) {
    return api.POST(`/availabilities`, data, config);
  }
  static EditUserAvailabilities(
    api: tAPI,
    availabilityId: string,
    data: object,
    config?: Config
  ) {
    return api.PATCH(`/availabilities/${availabilityId}`, data, config);
  }
  // Edit in bulk a group of users based on filters
  static EditUsers(api: tAPI, data: object, config?: Config) {
    return api.PATCH(`${apiBaseUsers}`, data, config);
  }
  // Edit a user from userId (include activate, deactivate, or change role)
  static EditUser(
    api: tAPI,
    actionApi:
      | "ACTIVATE_DEACTIVATE_USER"
      | "ADD_ROLE_USER"
      | "TRANSFER_USER"
      | "EDIT_USER",
    userId: string,
    data: object,
    isUpload: boolean = false,
    config?: Config
  ) {
    switch (actionApi) {
      case "ACTIVATE_DEACTIVATE_USER":
        return api.PATCH(
          `${apiBaseUsers}/${userId}/activateDeactivate`,
          data,
          config
        );
      case "ADD_ROLE_USER":
        return api.PATCH(`${apiBaseUsers}/${userId}/roles`, data, config);
      case "TRANSFER_USER":
        return api.PATCH(
          `${apiBaseUsers}/${userId}/transferClinic`,
          data,
          config
        );
      default:
        if (isUpload) {
          return api.UPLOAD(`${apiBaseUsers}/${userId}`, data, "PATCH", config);
        }
        return api.PATCH(`${apiBaseUsers}/${userId}`, data, config);
    }
  }
  // Get a user files from userId
  static GetUserFiles(api: tAPI, userId: string, config?: Config) {
    return api.GET(
      `/files?populated=true&belongsTo=Users&owner=${userId}`,
      config
    );
  }
  // Get all user files expired
  static GetUserFilesExpired(api: tAPI, config?: Config) {
    return api.GET(
      `/files?populated=true&aboutExpire=true&belongsTo=Users`,
      config
    );
  }
  // Upload a user files
  static UploadUserFiles(api: tAPI, data: object, config?: Config) {
    return api.UPLOAD(`/files`, data, "POST", config);
  }
  // Emulate User from userId
  static EmulateUser(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBaseUsers}/emulateAccount`, data, config);
  }
}
