import { createAction } from "deox";

// Actions for Import Users or rotations

// Fetch latest Import Data: Users and Rotations
export const IMPORT_DATA_FETCH: string = "IMPORT/DATA/FETCH";
export const IMPORT_DATA_FETCH_REQUEST: string = "IMPORT/DATA/FETCH/REQUEST";
export const IMPORT_DATA_FETCH_SUCCESS: string = "IMPORT/DATA/FETCH/SUCCESS";
export const IMPORT_DATA_FETCH_FAILURE: string = "IMPORT/DATA/FETCH/FAILURE";
export const importDataFetch = createAction(
  IMPORT_DATA_FETCH,
  (resolve) => (payload?: object) => resolve(payload)
);
export const importDataFetchRequest = createAction(IMPORT_DATA_FETCH_REQUEST);
export const importDataFetchSuccess = createAction(
  IMPORT_DATA_FETCH_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const importDataFetchFailure = createAction(IMPORT_DATA_FETCH_FAILURE);
// Import Data: Users or Rotations
export const IMPORT_DATA_SEND: string = "IMPORT/DATA/SEND";
export const IMPORT_DATA_SEND_REQUEST: string = "IMPORT/DATA/SEND/REQUEST";
export const IMPORT_DATA_SEND_SUCCESS: string = "IMPORT/DATA/SEND/SUCCESS";
export const IMPORT_DATA_SEND_FAILURE: string = "IMPORT/DATA/SEND/FAILURE";
export const importDataSend = createAction(
  IMPORT_DATA_SEND,
  (resolve) => (payload?: object) => resolve(payload)
);
export const importDataSendRequest = createAction(IMPORT_DATA_SEND_REQUEST);
export const importDataSendSuccess = createAction(
  IMPORT_DATA_SEND_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const importDataSendFailure = createAction(IMPORT_DATA_SEND_FAILURE);
// Generate CSV depend on Data: Users role or Rotations
export const IMPORT_GENERATE_CSV: string = "IMPORT/GENERATE_CSV";
export const IMPORT_GENERATE_CSV_REQUEST: string =
  "IMPORT/GENERATE_CSV/REQUEST";
export const IMPORT_GENERATE_CSV_SUCCESS: string =
  "IMPORT/GENERATE_CSV/SUCCESS";
export const IMPORT_GENERATE_CSV_FAILURE: string =
  "IMPORT/GENERATE_CSV/FAILURE";
export const importGenerateCSV = createAction(
  IMPORT_GENERATE_CSV,
  (resolve) => (payload?: object) => resolve(payload)
);
export const importGenerateCSVRequest = createAction(
  IMPORT_GENERATE_CSV_REQUEST
);
export const importGenerateCSVSuccess = createAction(
  IMPORT_GENERATE_CSV_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const importGenerateCSVFailure = createAction(
  IMPORT_GENERATE_CSV_FAILURE
);
// Show or Hide report warning message to let you know there are reports
// to check it into reports views
export const IMPORT_DATA_SHOW_NOTIFICATION: string =
  "IMPORT/DATA/SHOW_NOTIFICATION";
export const importDataShowNotification = createAction(
  IMPORT_DATA_SHOW_NOTIFICATION,
  (resolve) => (payload?: object) => resolve(payload)
);
export const IMPORT_DATA_HIDE_NOTIFICATION: string =
  "IMPORT/DATA/HIDE_NOTIFICATION";
export const importDataHideNotification = createAction(
  IMPORT_DATA_HIDE_NOTIFICATION,
  (resolve) => (payload?: object) => resolve(payload)
);
