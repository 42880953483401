// Inclusive Redux Store
/* eslint-disable no-useless-escape */
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import * as Cookies from "js-cookie";
import {
  AccessControl,
  AccountSettings,
  Api,
  Auth,
  CaseLogs,
  CustomFields,
  Evaluations,
  GuideLineTutorial,
  LocalStoreActions,
  Notifications,
  Router,
  RouterManager,
} from "./actions/";
// import { reducers } from "./reducers";
import { createRootReducer } from "./reducers";
import { sagas } from "./sagas";
import { isDevelopmentReactAppEnv } from "../utils";
import { LocalStore } from "../utils/local-storage/localStore";
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    logger: {
      log: (...args: any[]) => void;
      info: (...args: any[]) => void;
      warn: (...args: any[]) => void;
      error: (...args: any[]) => void;
      test: (...args: any[]) => void;
    };
  }
}
// Create a browser history
const history: any = createBrowserHistory();
// Middleware:
const beforeTransitionsMiddleware =
  (store: any) => (next: any) => (action: any) => {
    if (action.type === "@@router/LOCATION_CHANGE") {
      const { location } = store.getState().router;
      if (location) {
        if (location.pathname.indexOf("/manager") === -1) {
          store.dispatch(Router.routerBeforeTransitions(location));
        } else {
          store.dispatch(
            RouterManager.routerManagerBefore({ before: location })
          );
        }
      } else {
        if (action.payload.pathname.indexOf("/manager") === -1) {
          store.dispatch(Router.routerBeforeTransitions(action.payload));
        } else {
          store.dispatch(
            RouterManager.routerManagerBefore({ before: action.payload })
          );
        }
      }
    }
    return next(action);
  };

const routerManagerTransitionsMiddleware =
  (store: any) => (next: any) => (action: any) => {
    const nextAction = next(action);
    if (action.type === "@@router/LOCATION_CHANGE") {
      const { location } = store.getState().router;
      if (location) {
        if (location.pathname.indexOf("/manager") === 0) {
          store.dispatch(
            RouterManager.routerManagerLoaded({ loaded: location })
          );
        }
      } else {
        if (action.payload.pathname.indexOf("/manager") === 0) {
          store.dispatch(
            RouterManager.routerManagerLoaded({ loaded: action.payload })
          );
        }
      }
      // Custom Fields Institution Required My Institution
      if (
        location.pathname !== "/signout" &&
        location.pathname !== "/signout/session-expired" &&
        store.getState().auth.myInstitution
          .hasMyInstitutionCustomFieldsRequiredNull &&
        store.getState().auth.myInstitution
          .hasMyInstitutionCustomFieldsRequiredNull.isRequired &&
        store.getState().auth.myInstitution
          .hasMyInstitutionCustomFieldsRequiredNull.institutionRequired &&
        store.getState().auth.myInstitution
          .hasMyInstitutionCustomFieldsRequiredNull.institutionRequired
          .status === "active" &&
        store.getState().auth.role &&
        store
          .getState()
          .auth.myInstitution.hasMyInstitutionCustomFieldsRequiredNull.rolesWrite.indexOf(
            store.getState().auth.role.apiName
          ) !== -1 &&
        location.pathname === "/my-clinic"
      ) {
        store.dispatch(CustomFields.customFieldsRequiredTriggerAnimation());
        return;
      }
    }
    return nextAction;
  };
const localStoreMiddleware = (store: any) => (next: any) => (action: any) => {
  const nextAction = next(action);
  if (
    action.type === Auth.AUTH_SIGNOUT_SUCCESS_APP ||
    action.type === Auth.AUTH_SIGNOUT_FAILURE_APP ||
    action.type === Auth.AUTH_SESSION_EXPIRED_APP
  ) {
    store.dispatch(LocalStoreActions.clearAllLocalStore());
  } else if (
    action.type === Auth.AUTH_SIGNIN_SUCCESS_APP ||
    action.type === Auth.AUTH_UPDATE_MY_USER_ACCOUNT_SUCCESS ||
    action.type === Auth.AUTH_FETCH_MY_INSTITUTION_SUCCESS ||
    action.type === AccessControl.ACCESS_CONTROL_FETCH_ROLES_SUCCESS ||
    action.type === AccessControl.ACCESS_CONTROL_UPDATE_ROLE_RESOURCE_SUCCESS
  ) {
    const { auth, accessControl } = store.getState();
    const { expiredToken, role, user } = auth;
    if (expiredToken && role && user) {
      store.dispatch(
        LocalStoreActions.saveLocalStore({
          key: "authState",
          value: {
            auth: {
              ...auth,
              updatingProfile: false,
              fetchingMyFiles: false,
            },
          },
        })
      );
      store.dispatch(
        LocalStoreActions.saveLocalStore({
          key: "accessControlState",
          value: { accessControl },
        })
      );
    }
  } else if (action.type === AccountSettings.ACCOUNT_SETTINGS_FETCH_SUCCESS) {
    const { auth, accountSettings } = store.getState();
    const { expiredToken, role, user } = auth;
    if (expiredToken && role && user) {
      store.dispatch(
        LocalStoreActions.saveLocalStore({
          key: "accountSettingsState",
          value: {
            accountSettings: {
              ...accountSettings,
              isFetchingAccountSettings: false,
              statusUpdateAccountSettings: "",
            },
          },
        })
      );
    }
  } else if (action.type === CaseLogs.CASELOGS_FETCH_KPIS_SUCCESS) {
    const { auth, caseLogs } = store.getState();
    const { expiredToken, role, user } = auth;
    if (expiredToken && role && user) {
      store.dispatch(
        LocalStoreActions.saveLocalStore({
          key: "caseLogsState",
          value: { caseLogs },
        })
      );
    }
  } else if (action.type === Evaluations.EVALUATIONS_FETCH_KPIS_SUCCESS) {
    const { auth, evaluations } = store.getState();
    const { expiredToken, role, user } = auth;
    if (expiredToken && role && user) {
      store.dispatch(
        LocalStoreActions.saveLocalStore({
          key: "evaluationsState",
          value: { evaluations },
        })
      );
    }
  } else if (action.type === GuideLineTutorial.GUIDE_LINE_TUTORIAL_INITIALIZE) {
    const { guideLineTutorial } = store.getState();
    store.dispatch(
      LocalStoreActions.saveLocalStore({
        key: "guideLineTutorialState",
        value: { guideLineTutorial },
      })
    );
  } else if (action.type === GuideLineTutorial.GUIDE_LINE_TUTORIAL_COMPLETED) {
    const { auth, guideLineTutorial } = store.getState();
    const { expiredToken, role, user } = auth;
    if (expiredToken && role && user) {
      store.dispatch(
        LocalStoreActions.saveLocalStore({
          key: "guideLineTutorialState",
          value: { guideLineTutorial },
        })
      );
    }
  }
  return nextAction;
};
const configureStore = () => {
  // Add the saga middleware
  const sagaMiddleware: any = createSagaMiddleware();
  // Build the middleware for intercepting and dispatching navigation actions
  let middlewares: any[] = [
    routerMiddleware(history),
    sagaMiddleware,
    beforeTransitionsMiddleware,
    routerManagerTransitionsMiddleware,
    localStoreMiddleware,
  ];
  let composeEnhancers: Function = compose;
  // Add freeze and redux dev tools, only production
  if (isDevelopmentReactAppEnv()) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));
  let preloadedState: any = {
    ...LocalStore.Read("authState"),
    ...LocalStore.Read("accessControlState"),
    ...LocalStore.Read("accountSettingsState"),
    ...LocalStore.Read("caseLogsState"),
    ...LocalStore.Read("evaluationsState"),
    ...LocalStore.Read("guideLineTutorialState"),
  };
  const authCookie = Cookies.getJSON("authCookie");
  if (preloadedState && authCookie) {
    const { token, user: userId } = authCookie;
    const { auth } = preloadedState;
    if (
      token &&
      userId &&
      auth &&
      auth.isAuthenticated &&
      ((auth.user && auth.user._id && auth.user._id !== userId) ||
        (auth.emulation && auth.emulation.isActive))
    ) {
      preloadedState = {};
    }
  }
  const store: any = createStore(
    createRootReducer(history),
    preloadedState,
    enhancer
  );
  sagaMiddleware.run(sagas);
  store.dispatch(Api.initAPI({ token: authCookie?.token || "" }));
  if (preloadedState && preloadedState.auth) {
    const { socketUri } = store.getState().config;
    store.dispatch(
      Notifications.notificationsRegisterSocket({
        uri: socketUri,
        dispatch: store.dispatch,
      })
    );
    store.dispatch(Auth.authUpdateMyUserAccount());
  }
  return store;
};
// export
export { configureStore, history };
