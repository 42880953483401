// Features
import produce from "immer";
import { EvaluationsSchedulers, EvaluationsTemplates } from "../actions/";
// Types
import type { Action } from "deox";
import type { PayloadObject, UndefinedNull } from "../../types/common.types";
import type { EvaluationsTypes } from "../../types/evaluations.types";
export type StateEvaluationsSchedulersCreate = {
  stepCreateEvaluationsSchedulers: //Init: (Shared Autogenerate & Flexible)
  | "select-evaluation-schedulers"
    //Autogenerate: Based At the start-end rotations | Based On every case log
    | "setup-autogenerate-select-type"
    | "setup-autogenerate-select-type-evaluators"
    | "setup-autogenerate-select-criterias"
    //Flexible: Based On specific date
    | "setup-flexible-select-type"
    | "setup-flexible-select-evaluators"
    | "setup-flexible-select-targets"
    //Last Steps: (Shared Autogenerate & Flexible)
    | "setup-template-reminders"
    | "setup-name-description-date-options"
    | "summary-evaluation-schedulers-create";
  "select-evaluation-schedulers": {
    triggeringCriteria:
      | "start_rotation"
      | "end_rotation"
      | "every_case_log"
      | "custom_period"
      | UndefinedNull;
  };
  //Autogenerate: Based At the start-end rotations | Based On every case log
  "setup-autogenerate-select-type": {
    multipleAnswers: boolean;
    type:
      | "UserEvaluations"
      | "InstitutionEvaluations"
      | "CustomEvaluations"
      | UndefinedNull;
    evaluatorsRole: "Students" | "Preceptors" | UndefinedNull;
    targetsRole: "Students" | "Preceptors" | UndefinedNull;
    targets: [] | ["Others"];
  };
  "setup-autogenerate-select-type-evaluators": {
    evaluatorsFrom:
      | "all"
      | "only_evaluators"
      | "only_from_case_logs"
      | UndefinedNull;
    targetsFrom:
      | "all"
      | "only_evaluators"
      | "only_from_case_logs"
      | UndefinedNull;
  };
  "setup-autogenerate-select-criterias": {
    cohorts: PayloadObject[];
    programs: PayloadObject[];
    institutions: PayloadObject[];
    rotations: PayloadObject[];
  };
  //Flexible: Based On specific date
  "setup-flexible-select-type": {
    multipleAnswers: boolean;
    type:
      | "UserEvaluations"
      | "InstitutionEvaluations"
      | "CompanyEvaluations"
      | "CustomEvaluations"
      | UndefinedNull;
  };
  "setup-flexible-select-evaluators": {
    evaluators: PayloadObject[];
    evaluatorsRole:
      | "Students"
      | "Preceptors"
      | "CompanyAdministrators"
      | "InstitutionAdministrators"
      | UndefinedNull;
  };
  "setup-flexible-select-targets": {
    targets: PayloadObject[];
    targetsRole:
      | "Students"
      | "Preceptors"
      | "CompanyAdministrators"
      | "InstitutionAdministrators"
      | UndefinedNull;
  };
  //Last Steps: (Shared Autogenerate & Flexible)
  "setup-template-reminders": {
    evaluationTemplateId: string | UndefinedNull;
    reminderMessageBeforeOpen: string | UndefinedNull;
    daysBeforeOpenToSendReminder: number | UndefinedNull;
    reminderMessageBeforeClose: string | UndefinedNull;
    daysBeforeCloseToSendReminder: number | UndefinedNull;
  };
  "setup-name-description-date-options": {
    isActive: boolean;
    name: string | UndefinedNull;
    description: string | UndefinedNull;
    options: {
      openDaysBefore: number | UndefinedNull;
      keepEvaluationsOpen: number | UndefinedNull;
      period: "date_range" | "every_week_day" | "every_month";
      from: string | UndefinedNull;
      to: string | UndefinedNull;
      every: string | UndefinedNull;
    };
  };
};
export type StateEvaluationsSchedulersTabs = {
  panes: Array<{
    menuItem: string;
    name: EvaluationsTypes;
  }>;
  selected: number;
};
type State = {
  lastFetching: Date | UndefinedNull;
  evaluationsSchedulersTabs: StateEvaluationsSchedulersTabs;
  evaluationScheduler: PayloadObject | UndefinedNull;
  evaluationSchedulerId: string | UndefinedNull;
  evaluationsSchedulers: {
    UserEvaluations: PayloadObject[];
    InstitutionEvaluations: PayloadObject[];
    CompanyEvaluations: PayloadObject[];
    CustomEvaluations: PayloadObject[];
  };
  evaluationsSchedulersCreate: StateEvaluationsSchedulersCreate;
};
type EvaluationsSchedulersActionReducers = Action<
  string,
  {
    evaluationScheduler: State["evaluationScheduler"];
    evaluationSchedulerId: State["evaluationSchedulerId"];
    evaluationSchedulers: State["evaluationsSchedulers"];
    data: StateEvaluationsSchedulersCreate;
    selected: State["evaluationsSchedulersTabs"]["selected"];
  }
>;
// Evaluations Schedulers Reducer
export const initialStateEvaluationsSchedulersCreate: StateEvaluationsSchedulersCreate =
  {
    stepCreateEvaluationsSchedulers: "select-evaluation-schedulers",
    "select-evaluation-schedulers": {
      triggeringCriteria: null,
    },
    "setup-autogenerate-select-type": {
      multipleAnswers: false,
      type: null,
      evaluatorsRole: null,
      targetsRole: null,
      targets: [],
    },
    "setup-autogenerate-select-type-evaluators": {
      evaluatorsFrom: null,
      targetsFrom: null,
    },
    "setup-autogenerate-select-criterias": {
      cohorts: [],
      programs: [],
      institutions: [],
      rotations: [],
    },
    "setup-flexible-select-type": {
      multipleAnswers: false,
      type: null,
    },
    "setup-flexible-select-evaluators": {
      evaluators: [],
      evaluatorsRole: null,
    },
    "setup-flexible-select-targets": {
      targets: [],
      targetsRole: null,
    },
    "setup-template-reminders": {
      evaluationTemplateId: null,
      reminderMessageBeforeOpen: null,
      daysBeforeOpenToSendReminder: null,
      reminderMessageBeforeClose: null,
      daysBeforeCloseToSendReminder: null,
    },
    "setup-name-description-date-options": {
      isActive: true,
      name: null,
      description: null,
      options: {
        openDaysBefore: null,
        keepEvaluationsOpen: null,
        period: "date_range",
        from: null,
        to: null,
        every: null,
      },
    },
  };
const initialStateEvaluationsSchedulersTabs: StateEvaluationsSchedulersTabs = {
  panes: [
    { menuItem: "User Evaluations", name: "UserEvaluations" },
    { menuItem: "Clinic Evaluations", name: "InstitutionEvaluations" },
    { menuItem: "School Evaluations", name: "CompanyEvaluations" },
    { menuItem: "Custom Evaluations", name: "CustomEvaluations" },
  ],
  selected: 0,
};
const initialState: State = {
  lastFetching: null,
  evaluationsSchedulersTabs: initialStateEvaluationsSchedulersTabs,
  evaluationScheduler: null,
  evaluationSchedulerId: null,
  evaluationsSchedulers: {
    UserEvaluations: [],
    InstitutionEvaluations: [],
    CompanyEvaluations: [],
    CustomEvaluations: [],
  },
  evaluationsSchedulersCreate: initialStateEvaluationsSchedulersCreate,
};
const evaluationsSchedulers: (
  state: State,
  action: EvaluationsSchedulersActionReducers
) => State = produce(
  (draft: State, action: EvaluationsSchedulersActionReducers) => {
    switch (action.type) {
      case EvaluationsSchedulers.EVALUATIONS_SCHEDULERS_SET_TAB_SELECTED:
        draft.evaluationsSchedulersTabs.selected = action.payload.selected;
        break;
      case EvaluationsSchedulers.EVALUATIONS_SCHEDULERS_FETCH_EVALUATIONS_SCHEDULERS_SUCCESS:
        draft.lastFetching = new Date();
        draft.evaluationsSchedulers = action.payload.evaluationSchedulers;
        break;
      case EvaluationsSchedulers.EVALUATIONS_SCHEDULERS_SET_EVALUATION_SCHEDULER_ID:
        draft.evaluationSchedulerId = action.payload.evaluationSchedulerId;
        break;
      case EvaluationsSchedulers.EVALUATIONS_SCHEDULERS_FETCH_EVALUATION_SCHEDULER_SUCCESS:
      case EvaluationsSchedulers.EVALUATIONS_SCHEDULERS_EDIT_SUCCESS:
        draft.evaluationScheduler = action.payload.evaluationScheduler;
        break;
      case EvaluationsSchedulers.EVALUATIONS_SCHEDULERS_DELETE_SUCCESS:
        const tabEvSchedSelected =
          draft.evaluationsSchedulersTabs.panes[
            draft.evaluationsSchedulersTabs.selected
          ]["name"];
        const newEvaluationsSchedulers = {
          ...draft.evaluationsSchedulers,
          [tabEvSchedSelected]: draft.evaluationsSchedulers[
            tabEvSchedSelected
          ].filter(({ _id }) => _id !== action.payload.evaluationSchedulerId),
        };
        draft.lastFetching = new Date();
        draft.evaluationsSchedulers = newEvaluationsSchedulers;
        break;
      case EvaluationsSchedulers.EVALUATIONS_SCHEDULERS_RESET_EVALUATION_SCHEDULERS_LOADED:
      case EvaluationsTemplates.EVALUATIONS_TEMPLATES_RESET_EVALUATION_TEMPLATE_LOADED:
        draft.evaluationSchedulerId = initialState.evaluationSchedulerId;
        draft.evaluationsSchedulersCreate =
          initialState.evaluationsSchedulersCreate;
        break;
      // Evaluation Schedulers Create or Update:
      case EvaluationsSchedulers.EVALUATIONS_SCHEDULERS_CREATE_SUCCESS:
        draft.lastFetching = null;
        break;
      case EvaluationsSchedulers.EVALUATIONS_SCHEDULERS_EVALUATION_CREATE_UPDATE_STEP:
        draft.evaluationsSchedulersCreate = {
          ...draft.evaluationsSchedulersCreate,
          ...action.payload.data,
        };
        break;
      case EvaluationsSchedulers.EVALUATIONS_SCHEDULERS_EVALUATION_CREATE_RESET:
        draft.evaluationsSchedulersCreate =
          initialStateEvaluationsSchedulersCreate;
        break;
    }
  },
  initialState
);
export default evaluationsSchedulers;
