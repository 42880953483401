import { GuideLineTutorial } from "../actions/";
// Import Types
import type { Action } from "../../types/common.types";
import type { StateGuideLineTutorial as State } from "../../types/guideLineTutorial.types";
// Guide Line Tutorial Reducer
const intialStateJoyrideProps = {
  run: false,
  steps: [],
};
const initialState: State = {
  joyrideProps: intialStateJoyrideProps,
  sectionRunning: null,
  sections: [
    GuideLineTutorial.GUIDE_LINE_TUTORIAL_MENU,
    GuideLineTutorial.GUIDE_LINE_TUTORIAL_HOME,
    GuideLineTutorial.GUIDE_LINE_TUTORIAL_USER_MANAGER,
    GuideLineTutorial.GUIDE_LINE_TUTORIAL_USER_MANAGER_INVITATIONS,
    GuideLineTutorial.GUIDE_LINE_TUTORIAL_INSTITUTIONS,
    GuideLineTutorial.GUIDE_LINE_TUTORIAL_ROTATIONS,
    GuideLineTutorial.GUIDE_LINE_TUTORIAL_ROTATIONS_CREATE,
    GuideLineTutorial.GUIDE_LINE_TUTORIAL_CASE_LOGS,
    GuideLineTutorial.GUIDE_LINE_TUTORIAL_EVALUATIONS,
    GuideLineTutorial.GUIDE_LINE_TUTORIAL_ACCOUNT_SETTINGS,
  ],
  sectionsCompleted: [],
};
export default function guideLineTutorial(
  state: State = initialState,
  action: Action
): State {
  let newState = {};
  switch (action.type) {
    //GuideLineTutorial
    case GuideLineTutorial.GUIDE_LINE_TUTORIAL_INITIALIZE:
      newState = {
        ...state,
        sectionsCompleted: action.payload.sectionsCompleted,
      };
      if (newState.sections.length === newState.sectionsCompleted.length) {
        newState = {
          ...initialState,
          sectionsCompleted: action.payload.sectionsCompleted,
        };
      }
      return newState;
    case GuideLineTutorial.GUIDE_LINE_TUTORIAL_START:
      return Object.assign({}, state, {
        joyrideProps: action.payload.joyrideProps,
        sectionRunning: action.payload.sectionRunning,
      });
    case GuideLineTutorial.GUIDE_LINE_TUTORIAL_STOP:
      if (
        state.sectionRunning &&
        state.sectionRunning !== GuideLineTutorial.GUIDE_LINE_TUTORIAL_MENU
      ) {
        return Object.assign({}, state, {
          joyrideProps: intialStateJoyrideProps,
          sectionRunning: null,
        });
      } else {
        return state;
      }
    case GuideLineTutorial.GUIDE_LINE_TUTORIAL_PAUSE:
      if (
        state.sectionRunning &&
        state.sectionRunning !== GuideLineTutorial.GUIDE_LINE_TUTORIAL_MENU
      ) {
        return Object.assign({}, state, {
          joyrideProps: {
            ...state.joyrideProps,
            run: false,
          },
        });
      } else {
        return state;
      }
    case GuideLineTutorial.GUIDE_LINE_TUTORIAL_PLAY:
      if (
        state.sectionRunning &&
        state.sectionRunning !== GuideLineTutorial.GUIDE_LINE_TUTORIAL_MENU
      ) {
        return Object.assign({}, state, {
          joyrideProps: {
            ...state.joyrideProps,
            run: true,
          },
        });
      } else {
        return state;
      }
    case GuideLineTutorial.GUIDE_LINE_TUTORIAL_NEXT:
      return Object.assign({}, state, {
        joyrideProps: {
          ...state.joyrideProps,
          stepIndex: state.joyrideProps.stepIndex + 1,
        },
      });
    case GuideLineTutorial.GUIDE_LINE_TUTORIAL_PREV:
      return Object.assign({}, state, {
        joyrideProps: {
          ...state.joyrideProps,
          stepIndex: state.joyrideProps.stepIndex - 1,
        },
      });
    case GuideLineTutorial.GUIDE_LINE_TUTORIAL_COMPLETED:
      newState = {
        joyrideProps: intialStateJoyrideProps,
        sectionRunning: null,
      };
      if (state.sectionRunning) {
        newState = {
          ...newState,
          sectionsCompleted: [state.sectionRunning, ...state.sectionsCompleted],
        };
      }
      return Object.assign({}, state, newState);
    // same state (not change)
    default:
      return state;
  }
}
