// @flow

/* PERMISSIONS */
const onCheckResource = (apiResource: Object, resources: Array<string>) => {
  for (let resource in apiResource) {
    if (
      resources.indexOf(resource) !== -1 &&
      apiResource[resource]['isEnabled']
    ) {
      return true;
    }
  }
  return false;
};

export const onCheckAllCompaniesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    'Companies:read',
    'Companies:update',
    'my#Companies:read',
    'my#Companies:update'
  ];
  const { Companies } = permissions || {};
  if (Companies && Companies.apiValue) {
    return onCheckResource(Companies.apiValue, resources);
  }
  return false;
};

export const onCheckReadMyCompaniesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ['my#Companies:read'];
  const { Companies } = permissions || {};
  if (Companies && Companies.apiValue) {
    return onCheckResource(Companies.apiValue, resources);
  }
  return false;
};

export const onCheckEditMyCompaniesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ['my#Companies:update'];
  const { Companies } = permissions || {};
  if (Companies && Companies.apiValue) {
    return onCheckResource(Companies.apiValue, resources);
  }
  return false;
};
