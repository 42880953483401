import { UsersPortfolio } from "../actions/";
// Import Types
import type { Action } from "../../types/common.types";
import type { StateUsersPortfolio as State } from "../../types/usersPortfolio.types";
// Portfolio Reducer
const initialState: State = {
  publicUrl: null,
  caseLogsKPI: null,
  institutionsWorked: [],
  workExperience: [],
  educationalBackground: [],
  publications: [],
  volunteers: [],
  skills: [],
  testimonials: [],
};

export default function usersPortfolio(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    // Portfolio
    case UsersPortfolio.PORTFOLIO_FETCH_PORTFOLIO_SUCCESS:
    case UsersPortfolio.PORTFOLIO_EDIT_SUCCESS:
    case UsersPortfolio.PORTFOLIO_TESTIMONIALS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        ...action.payload.newState,
      });
    case UsersPortfolio.PORTFOLIO_DELETE_TESTIMONIALS_SUCCESS:
      return Object.assign({}, state, {
        testimonials: state.testimonials.filter(
          ({ institution }) => institution._id !== action.payload.institutionId
        ),
      });
    // same state (not change)
    default:
      return state;
  }
}
