// @flow

/* PERMISSIONS */
const onCheckResource = (apiResource: Object, resources: Array<string>) => {
  for (let resource in apiResource) {
    if (
      resources.indexOf(resource) !== -1 &&
      apiResource[resource]['isEnabled']
    ) {
      return true;
    }
  }
  return false;
};

export const onCheckEvaluationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ['Evaluations:read', 'my#Evaluations:read'];
  const { Evaluations } = permissions || {};
  if (Evaluations && Evaluations.apiValue) {
    return onCheckResource(Evaluations.apiValue, resources);
  }
  return false;
};

export const onCheckReadEvaluationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ['Evaluations:read'];
  const { Evaluations } = permissions || {};
  if (Evaluations && Evaluations.apiValue) {
    return onCheckResource(Evaluations.apiValue, resources);
  }
  return false;
};

export const onCheckEditEvaluationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ['Evaluations:update'];
  const { Evaluations } = permissions || {};
  if (Evaluations && Evaluations.apiValue) {
    return onCheckResource(Evaluations.apiValue, resources);
  }
  return false;
};

export const onCheckDeleteEvaluationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ['Evaluations:delete'];
  const { Evaluations } = permissions || {};
  if (Evaluations && Evaluations.apiValue) {
    return onCheckResource(Evaluations.apiValue, resources);
  }
  return false;
};

export const onCheckReadMyEvaluationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ['my#Evaluations:read'];
  const { Evaluations } = permissions || {};
  if (Evaluations && Evaluations.apiValue) {
    return onCheckResource(Evaluations.apiValue, resources);
  }
  return false;
};
