// Import Features
import {
  AccountSettings,
  Users,
  UsersManager,
  Institutions,
  Programs,
  Cohorts,
} from "../actions/";
import { updateArrayWithElement } from "../../utils/";
// Import Types
import type { Action, PayloadObject } from "../../types/common.types";
import type {
  StateUsersManagerFilters,
  StateUsersManager as State,
  SortBy,
} from "../../types/users.types";
// Users Reducer
export const USER_MANAGER_USER_LIMIT = 20;
// Initial States
const initialStateRoles = {
  options: [],
  isFetching: false,
  selected: null,
};
const initialStateOptionsSelected = {
  options: [],
  selected: [],
};
const initialStatePrograms = initialStateOptionsSelected;
const initialStateCohorts = initialStateOptionsSelected;
const initialStateInstitutions = initialStateOptionsSelected;
const initialStateSortBy: SortBy = {
    name: "descending",
    enrollment: "",
    institution: "",
  },
  initialStateUserStatus: StateUsersManagerFilters["status"] = ["active"];
const initialStateFilters: StateUsersManagerFilters = {
  pages: 1,
  limit: USER_MANAGER_USER_LIMIT,
  searchTxt: "",
  roles: initialStateRoles,
  status: initialStateUserStatus,
  rotations: {
    activeRotation: false,
  },
  programs: initialStatePrograms,
  cohorts: initialStateCohorts,
  institutions: initialStateInstitutions,
  sortBy: initialStateSortBy,
};
const initialState: State = {
  initialStateFilters,
  usersManagerSelected: null,
  errorAPI: null,
  filters: initialStateFilters,
};
export default function usersManager(
  state: State = initialState,
  action: Action
): State {
  let filters: StateUsersManagerFilters;
  switch (action.type) {
    case UsersManager.USERS_MANAGER_FILTERS_SAVE_FILTERS:
      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          ...action.payload.filters,
        },
      });
    case UsersManager.USERS_MANAGER_FILTERS_SET_ROLES:
      filters = Object.assign({}, state.filters, {
        roles: {
          ...state.filters.roles,
          options: action.payload.roles,
        },
      });
      return Object.assign({}, state, {
        filters: filters,
      });
    case UsersManager.USERS_MANAGER_FILTERS_SET_PAGES:
      filters = Object.assign({}, state.filters, {
        pages: action.payload.pages,
      });
      return Object.assign({}, state, {
        filters: filters,
      });
    case UsersManager.USERS_MANAGER_FILTERS_SET_SEARCH:
      filters = Object.assign({}, state.filters, {
        searchTxt: action.payload.searchTxt,
      });
      return Object.assign({}, state, {
        filters: filters,
      });
    case UsersManager.USERS_MANAGER_FILTERS_SET_SORTBY:
      filters = Object.assign({}, state.filters, {
        sortBy: action.payload.sortBy,
      });
      return Object.assign({}, state, {
        filters: filters,
      });
    case AccountSettings.ACCOUNT_SETTINGS_UPDATE_SETTING_NAME:
      if (action.payload.accountSettingsName === "USER_MANAGER_SETTINGS") {
        return Object.assign({}, state, {
          initialStateFilters: {
            ...state.initialStateFilters,
            sortBy: action.payload.newSetting.sortBy,
            status: action.payload.newSetting.userStatus,
          },
        });
      }
      return state;
    case AccountSettings.ACCOUNT_SETTINGS_FETCH_SUCCESS:
      if (action.payload.frontendPersonalization?.USER_MANAGER_SETTINGS) {
        filters = Object.assign({}, state.filters, {
          sortBy:
            action.payload.frontendPersonalization["USER_MANAGER_SETTINGS"]
              .sortBy,
          status:
            action.payload.frontendPersonalization["USER_MANAGER_SETTINGS"]
              .userStatus || initialStateUserStatus,
        });
        return Object.assign({}, state, {
          initialStateFilters: {
            ...state.initialStateFilters,
            sortBy:
              action.payload.frontendPersonalization["USER_MANAGER_SETTINGS"]
                .sortBy,
            status:
              action.payload.frontendPersonalization["USER_MANAGER_SETTINGS"]
                .userStatus || initialStateUserStatus,
          },
          filters,
        });
      }
      return state;
    case UsersManager.USERS_MANAGER_FILTERS_CLEAR:
      return Object.assign({}, state, {
        ...initialState,
        initialStateFilters: state.initialStateFilters,
        filters: {
          ...state.initialStateFilters,
          roles: {
            ...state.filters.roles,
            selected: null,
          },
          programs: {
            ...state.filters.programs,
            selected: [],
          },
          cohorts: {
            ...state.filters.cohorts,
            selected: [],
          },
          institutions: {
            ...state.filters.institutions,
            selected: [],
          },
        },
      });
    // Fetch Users for UsersManager by Roles
    case Users.USERS_FETCH_USERS_ROLES:
      if (action.payload && action.payload.selected) {
        let resetFilters = {};
        if (action.payload.resetFilters) {
          resetFilters = {
            ...state.initialStateFilters,
            status: action.payload.status,
            programs: {
              ...state.filters.programs,
              selected: [],
            },
            cohorts: {
              ...state.filters.cohorts,
              selected: [],
            },
            institutions: {
              ...state.filters.institutions,
              selected: [],
            },
          };
          if (action.payload.newFilters) {
            const { institutionsSelected, rotationsActive } =
              action.payload.newFilters;
            if (rotationsActive) {
              resetFilters = Object.assign({}, resetFilters, {
                rotations: {
                  activeRotation: rotationsActive,
                },
              });
            }
            if (institutionsSelected && institutionsSelected.length) {
              resetFilters = Object.assign({}, resetFilters, {
                institutions: {
                  ...state.filters.institutions,
                  selected: institutionsSelected,
                },
              });
            }
          }
        }
        filters = Object.assign({}, state.filters, {
          ...resetFilters,
          roles: {
            ...state.filters.roles,
            isFetching: true,
            selected: action.payload.selected,
          },
        });
        return Object.assign({}, state, {
          errorAPI: null,
          filters: filters,
        });
      }
      return state;
    case Users.USERS_FETCH_USERS_ROLES_SUCCESS:
    case Users.USERS_FETCH_USERS_ROLES_FAILURE:
      if (action.payload && action.payload.selected) {
        filters = Object.assign({}, state.filters, {
          roles: { ...state.filters.roles, isFetching: false },
        });
        let errorAPI = null;
        if (action.payload.errorAPI) {
          errorAPI = action.payload.errorAPI;
        }
        return Object.assign({}, state, { errorAPI, filters });
      }
      return state;
    case UsersManager.USERS_MANAGER_FILTERS_SET_ROLE_SELECTED:
      if (action.payload && action.payload.selected) {
        filters = Object.assign({}, state.filters, {
          roles: {
            ...state.filters.roles,
            isFetching: false,
            selected: action.payload.selected,
          },
        });
        let errorAPI = null;
        if (action.payload.errorAPI) {
          errorAPI = action.payload.errorAPI;
        }
        return Object.assign({}, state, {
          errorAPI,
          filters,
        });
      }
      return state;
    // Fetch Users for UsersManager active rotation filter (only Students)
    case UsersManager.USERS_MANAGER_FILTERS_SEARCH:
      filters = Object.assign({}, state.filters, {
        pages: 1,
      });
      return Object.assign({}, state, {
        filters: filters,
      });
    case UsersManager.USERS_MANAGER_FILTERS_SET_USER_STATUS:
      filters = Object.assign({}, state.filters, {
        pages: 1,
        status: action.payload.status,
      });
      return Object.assign({}, state, {
        filters: filters,
      });
    case UsersManager.USERS_MANAGER_FILTERS_ROTATIONS_ACTIVE:
      filters = Object.assign({}, state.filters, {
        pages: 1,
        rotations: {
          activeRotation: !state.filters.rotations.activeRotation,
        },
      });
      return Object.assign({}, state, {
        filters: filters,
      });
    // Fetch Users for UsersManager by programs filter (only Students)
    case UsersManager.USERS_MANAGER_FILTERS_PROGRAMS:
      filters = Object.assign({}, state.filters, {
        pages: 1,
        programs: {
          ...state.filters.programs,
          selected: action.payload.selected,
        },
      });
      return Object.assign({}, state, {
        filters: filters,
      });
    // Fetch Users for UsersManager by cohorts filter (only Students)
    case UsersManager.USERS_MANAGER_FILTERS_COHORTS:
      filters = Object.assign({}, state.filters, {
        pages: 1,
        cohorts: {
          ...state.filters.cohorts,
          selected: action.payload.selected,
        },
      });
      return Object.assign({}, state, {
        filters: filters,
      });
    // Fetch Users for UsersManager by institutions filter (only InstitutionAdministrators and Preceptors)
    case UsersManager.USERS_MANAGER_FILTERS_INSTITUTIONS:
      filters = Object.assign({}, state.filters, {
        pages: 1,
        institutions: {
          ...state.filters.institutions,
          selected: action.payload.selected,
        },
      });
      return Object.assign({}, state, {
        filters: filters,
      });
    // Save Users Selected into User Manager
    case UsersManager.USERS_MANAGER_SAVE_USERS:
      return Object.assign({}, state, {
        usersManagerSelected: action.payload.usersSelected,
      });
    // PROGRAMS ACTIONS
    case Programs.PROGRAMS_FETCH_PROGRAMS_SUCCESS:
      filters = Object.assign({}, state.filters, {
        programs: {
          ...state.filters.programs,
          options: action.payload.programs,
        },
      });
      return Object.assign({}, state, {
        filters: filters,
      });
    case Programs.PROGRAMS_CREATE_SUCCESS:
      filters = Object.assign({}, state.filters, {
        programs: {
          ...state.filters.programs,
          options: [...state.filters.programs.options, action.payload.program],
        },
      });
      return Object.assign({}, state, {
        filters: filters,
      });
    case Programs.PROGRAMS_EDIT_PROGRAM_SUCCESS:
      filters = Object.assign({}, state.filters, {
        programs: {
          ...state.filters.programs,
          options: updateArrayWithElement(
            state.filters.programs.options,
            action.payload.program
          ),
        },
      });
      return Object.assign({}, state, {
        filters: filters,
      });
    case Programs.PROGRAMS_DELETE_SUCCESS:
      filters = Object.assign({}, state.filters, {
        programs: {
          ...state.filters.programs,
          options: state.filters.programs.options.filter(
            ({ _id }: PayloadObject) => _id !== action.payload.programId
          ),
        },
      });
      return Object.assign({}, state, {
        filters: filters,
      });
    // COHORTS ACTIONS
    case Cohorts.COHORTS_FETCH_COHORTS_SUCCESS:
      filters = Object.assign({}, state.filters, {
        cohorts: {
          ...state.filters.cohorts,
          options: action.payload.cohorts,
        },
      });
      return Object.assign({}, state, {
        filters: filters,
      });
    case Cohorts.COHORTS_CREATE_SUCCESS:
      filters = Object.assign({}, state.filters, {
        cohorts: {
          ...state.filters.cohorts,
          options: [...state.filters.cohorts.options, action.payload.cohort],
        },
      });
      return Object.assign({}, state, {
        filters: filters,
      });
    case Cohorts.COHORTS_EDIT_COHORT_SUCCESS:
      filters = Object.assign({}, state.filters, {
        cohorts: {
          ...state.filters.cohorts,
          options: updateArrayWithElement(
            state.filters.cohorts.options,
            action.payload.cohort
          ),
        },
      });
      return Object.assign({}, state, {
        filters: filters,
      });
    case Cohorts.COHORTS_DELETE_SUCCESS:
      filters = Object.assign({}, state.filters, {
        cohorts: {
          ...state.filters.cohorts,
          options: state.filters.cohorts.options.filter(
            ({ _id }: PayloadObject) => _id !== action.payload.cohortId
          ),
        },
      });
      return Object.assign({}, state, {
        filters: filters,
      });
    // INSTITUTIONS ACTIONS
    case Institutions.INSTITUTIONS_FETCH_INSTITUTIONS_SUCCESS:
      filters = Object.assign({}, state.filters, {
        institutions: {
          ...state.filters.institutions,
          options: action.payload.institutions,
        },
      });
      return Object.assign({}, state, {
        filters: filters,
      });
    // same state (not change)
    default:
      return state;
  }
}
