import { put, select, call, all, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";
import * as Cookies from "js-cookie";
import { doAccessControlReturnRoles } from "./accessControl";
import { doCallAccountsSettingsFetchMySettings } from "./accountSettings";
import AuthApi from "../api/Auth";
import {
  AccessControl,
  AccountSettings,
  Auth,
  Cohorts,
  Company,
  CustomFields,
  FileCategories,
  Institutions,
  Notifications,
  Programs,
  TenantSettings,
  Users,
  UsersManager,
} from "../actions/";
import {
  parseName,
  parseApiName,
  setTokenExpired,
  checkTokenExpired,
} from "../../utils/";
//PERMISSIONS
import { onCheckUsersManagerPermissions } from "../../components/manager/ManagerPermissions";
import {
  onCheckReadProgramsPermissions,
  onCheckReadCohortsPermissions,
} from "../../components/admin-portal/AdminPortalPermissions";
import { onCheckReadInstitutionsPermissions } from "../../components/institutions/InstitutionsPermissions";
import { onChecReadkMyFilesPermissions } from "../../components/files/FilesPermissions";
import { USER_MANAGER_USER_LIMIT } from "../reducers/usersManager";
// Type
import type { Action } from "deox";
import type { tAPI } from "../api/API";
//Sagas for Auth
const getMyOptionsRoles = (myRole: { [k: string]: any }) => {
  const { Users } = myRole.permissions || {};
  if (Users && Users.actions && Users.actions.length > 0) {
    const optionsRoles = Users.actions
      .filter(
        ({ alias, value }: { alias: string; value: any }) =>
          alias !== "my#Profiles:read" &&
          (alias.indexOf("#Profiles:read") !== -1 ||
            alias.indexOf("#ProfilesBasic:read") !== -1) &&
          value
      )
      .map(({ alias }: { alias: string }) => ({
        apiName: alias
          .replace("my:", "")
          .replace("#Profiles:read", "")
          .replace("#ProfilesBasic:read", ""),
        type: alias
          .replace("my:", "")
          .replace("#Profiles:read", "")
          .replace("#ProfilesBasic:read", "")
          .replace(/\s/g, "")
          .toLowerCase(),
        name: parseApiName(
          parseName(
            alias
              .replace("my:", "")
              .replace("#Profiles:read", "")
              .replace("#ProfilesBasic:read", "")
          )
        ),
      }))
      .filter(
        (
          elem: { [k: string]: any },
          index: number,
          array: { [k: string]: any }[]
        ) => array.map(({ apiName }) => apiName).indexOf(elem.apiName) === index
      );
    return optionsRoles;
  }
  return [];
};

function* doAuthGetMyAccounts(email: string): Generator<any, object[], any> {
  window.logger.log("doAuthGetMyAccounts", email);
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    const { companyDomain } = yield select(({ config }) => config);
    const { data } = yield call(
      AuthApi.GetMyAccounts,
      api,
      {
        email,
        tenant: companyDomain,
      },
      { token }
    );
    const { accountsAvailable } = data;
    return accountsAvailable;
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doAuthGetMyAccounts", error, errors);
    return [];
  }
}

function* doAuthSwitchAccount(
  action: Action<
    string,
    {
      user: object;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Auth.authSwithcAccountRequest());
  window.logger.log("doAuthSwitchAccount", action);
  const { user, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token: apiToken }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to switch account
    const { data } = yield call(
      AuthApi.SwitchMyAccount,
      api,
      {
        user,
      },
      { token: apiToken }
    );
    const { user: userId, token } = data;
    const { localhost, hostname } = yield select(({ config }) => config);
    if (localhost) {
      Cookies.set(
        "authCookie",
        { token, user: userId, removeDomain: `${hostname}` },
        { domain: `${hostname}` }
      );
    }
    yield put(Auth.authSwithcAccountSuccess());
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doAuthSwitchAccount", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Auth.authSwithcAccountFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doAuthSignInApp(
  action: Action<
    string,
    {
      userId: string;
      emulation: { isActive: boolean; onBehalfUser: object | null };
      callbackSuccess: (data: any) => void;
      callbackError: (error: any) => void;
    }
  >
) {
  yield put(Auth.authSignInRequestApp());
  window.logger.log("doAuthSignInApp", action);
  const { userId, emulation, callbackSuccess, callbackError } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to sign in into account
    // First Sign In, we are waiting to get all permission for this role.
    const roles: any[] = yield call(
      // @ts-ignore
      doAccessControlReturnRoles,
      AccessControl.accessControlFetchRoles()
    );
    const { data } = yield call(AuthApi.GetMyUserAccount, api, userId, {
      token,
    });
    const { user } = data;
    const myRole =
      roles && roles.length > 0
        ? roles.filter(
            ({
              apiName,
            }: {
              apiName:
                | "Alumnis"
                | "CompanyAdministrators"
                | "Coordinators"
                | "Faculties"
                | "InstitutionAdministrators"
                | "Preceptors"
                | "Students"
                | "SuperCompanyAdministrators";
            }) => apiName === user.role
          )[0]
        : null;
    // We need User and myRole to success Sign In
    if (user.status === "active" && myRole) {
      // From here we need minimun all these tasks
      yield put(CustomFields.customFieldsFetchCustomFields());
      yield call(
        // @ts-ignore
        doCallAccountsSettingsFetchMySettings,
        AccountSettings.accountSettingsFetchMySettings({
          userId: user._id,
          userRoleType: myRole.type,
        })
      );
      const myRoles = getMyOptionsRoles(myRole);
      if (myRoles && myRoles.length > 0) {
        if (onCheckUsersManagerPermissions(myRole.permissions)) {
          yield put(
            UsersManager.usersManagerFiltersSetRoles({ roles: myRoles })
          );
          yield put(
            Users.usersFetchUsersKpis({ limit: USER_MANAGER_USER_LIMIT })
          );
        }
        if (
          myRoles.filter(
            ({
              apiName,
            }: {
              apiName:
                | "Alumnis"
                | "CompanyAdministrators"
                | "Coordinators"
                | "Faculties"
                | "InstitutionAdministrators"
                | "Preceptors"
                | "Students"
                | "SuperCompanyAdministrators";
            }) => apiName === "Students"
          ).length > 0
        ) {
          if (onCheckReadProgramsPermissions(myRole.permissions)) {
            yield put(Programs.programsFetchPrograms());
          }
          if (onCheckReadCohortsPermissions(myRole.permissions)) {
            yield put(Cohorts.cohortsFetchCohorts());
          }
        }
        if (onCheckReadInstitutionsPermissions(myRole.permissions)) {
          yield put(Institutions.institutionsFetchInstitutions());
        }
      }
      const accountsAvailable: object[] = yield doAuthGetMyAccounts(user.email);
      const { tenantSettings }: { tenantSettings: any } = yield select(
        ({ tenantSettings }) => tenantSettings
      );
      const hasToCheckRequiredFields =
        tenantSettings["ACCOUNTS_PROFILE_REQUIRED_FIELDS"].valuesApi;
      const hasContactInfoAddressesFieldsRequiredNull = hasToCheckRequiredFields
        ? getContactInfoAddressesFieldsRequiredNull(user)
        : false;
      const hasEmergencyContactFieldsRequiredNull = hasToCheckRequiredFields
        ? getEmergencyContactFieldsRequiredNull(user)
        : false;
      const hasCustomFieldsRequiredNull = hasToCheckRequiredFields
        ? getCustomFieldsRequiredNull(user)
        : false;
      window.logger.warn(
        "doAuthSignInApp",
        user,
        emulation,
        "hasContactInfoAddressesFieldsRequiredNull",
        hasContactInfoAddressesFieldsRequiredNull,
        "hasEmergencyContactFieldsRequiredNull",
        hasEmergencyContactFieldsRequiredNull,
        "hasCustomFieldsRequiredNull: ",
        hasCustomFieldsRequiredNull
      );
      yield put(
        Auth.authSignInSuccessApp({
          token,
          expiredToken: setTokenExpired(),
          user: {
            ...user,
            accountsAvailable,
          },
          emulation,
          role: myRole,
          hasContactInfoAddressesFieldsRequiredNull,
          hasEmergencyContactFieldsRequiredNull,
          hasCustomFieldsRequiredNull,
        })
      );
      // After here we can load resto of tasks
      yield put(Notifications.notificationsFetchNotifications());
      if (user.company) {
        yield put(Company.companyFetchCompany({ companyId: user.company._id }));
      }
      // Load Institution
      if (user.institution) {
        yield call(doMergeMyInstitutionAccount, user.institution);
        yield put(
          Auth.authFetchMyInstitution({ institutionId: user.institution._id })
        );
      }
      if (
        user.activeRotation &&
        user.activeRotation.institutions &&
        user.activeRotation.institutions.length > 0
      ) {
        yield call(
          doMergeMyInstitutionAccount,
          user.activeRotation.institutions[0]
        );
      }
      // Load Files
      if (onChecReadkMyFilesPermissions(myRole.permissions)) {
        yield put(
          Auth.authFetchMyFiles({ belongsTo: "Users", ownerId: user._id })
        );
      }
      yield put(TenantSettings.tenantSettingsFetchAll());
      yield put(FileCategories.fileCategoriesFetchFileCategories());
      if (callbackSuccess) {
        callbackSuccess(hasCustomFieldsRequiredNull);
      }
      if (hasCustomFieldsRequiredNull) {
        yield put(CustomFields.customFieldsRequiredTriggerAnimation());
        yield put(
          push({
            pathname: `/my-profile`,
            state: {
              namePage: "My Profile",
            },
          })
        );
      }
    } else {
      yield put(Auth.authSignInFailureApp());
      callbackError("Not user active");
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doAuthSignInApp", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Auth.authSignInFailureApp());
    if (callbackError) {
      callbackError(errors);
    }
  }
}

const getCustomFieldsRequiredNull = ({ customFields }: any) => {
  if (customFields && customFields.length) {
    const customFieldsRequired = customFields.filter(
      ({ field }: any) => field && field.isRequired
    );
    for (let i = 0; i < customFieldsRequired.length; i++) {
      const {
        field: { model, permissions },
        value,
      } = customFieldsRequired[i];
      if (
        permissions[model] === "write" &&
        (value === null || value === undefined)
      ) {
        return true;
      }
    }
  }
  return false;
};

const getContactInfoAddressesFieldsRequiredNull = ({
  contactInfo,
  addresses,
}: any) => {
  if (!contactInfo || !addresses) {
    return true;
  } else {
    if (contactInfo && contactInfo.length > 0) {
      const indexFieldCellPhone = contactInfo
        .map(({ label }: any) => label)
        .indexOf("Cell Phone");
      if (indexFieldCellPhone > -1) {
        const { value } = contactInfo[indexFieldCellPhone];
        if (!value) {
          return true;
        }
      }
    }
    if (addresses && addresses.length > 0) {
      const { address1, city, state, zipCode } = addresses[0];
      if (!address1) {
        return true;
      } else if (!city) {
        return true;
      } else if (!state) {
        return true;
      } else if (!zipCode) {
        return true;
      }
    }
  }
  return false;
};

const getEmergencyContactFieldsRequiredNull = ({
  role,
  emergencyContact,
}: any) => {
  if (role && role === "Students") {
    if (!emergencyContact) {
      return true;
    } else if (emergencyContact && !emergencyContact.contactPerson) {
      return true;
    } else if (emergencyContact && !emergencyContact.phoneNumber1) {
      return true;
    } else if (emergencyContact && !emergencyContact.email) {
      return true;
    } else if (emergencyContact && !emergencyContact.address1) {
      return true;
    } else if (emergencyContact && !emergencyContact.city) {
      return true;
    } else if (emergencyContact && !emergencyContact.state) {
      return true;
    } else if (emergencyContact && !emergencyContact.zipCode) {
      return true;
    }
  }
  return false;
};

function* doMergeMyUserAccount(user: any) {
  const accessControlRoleUser: any[] = yield select(
    ({ accessControl: { roles } }) =>
      roles.filter(({ apiName }: any) => apiName === user.role)
  );
  const { tenantSettings }: { tenantSettings: any } = yield select(
    ({ tenantSettings }) => tenantSettings
  );
  const hasToCheckRequiredFields =
    tenantSettings["ACCOUNTS_PROFILE_REQUIRED_FIELDS"].valuesApi;
  const hasContactInfoAddressesFieldsRequiredNull = hasToCheckRequiredFields
    ? getContactInfoAddressesFieldsRequiredNull(user)
    : false;
  const hasEmergencyContactFieldsRequiredNull = hasToCheckRequiredFields
    ? getEmergencyContactFieldsRequiredNull(user)
    : false;
  const hasCustomFieldsRequiredNull = hasToCheckRequiredFields
    ? getCustomFieldsRequiredNull(user)
    : false;
  window.logger.warn(
    "doMergeMyUserAccount",
    user,
    accessControlRoleUser,
    "hasContactInfoAddressesFieldsRequiredNull",
    hasContactInfoAddressesFieldsRequiredNull,
    "hasEmergencyContactFieldsRequiredNull",
    hasEmergencyContactFieldsRequiredNull,
    "hasCustomFieldsRequiredNull: ",
    hasCustomFieldsRequiredNull
  );
  yield put(
    Auth.authUpdateMyUserAccountSuccess({
      user,
      role:
        accessControlRoleUser && accessControlRoleUser.length === 1
          ? accessControlRoleUser[0]
          : null,
      hasContactInfoAddressesFieldsRequiredNull,
      hasEmergencyContactFieldsRequiredNull,
      hasCustomFieldsRequiredNull,
    })
  );
}

function* doAuthUpdateMyUserAccount(action: Action<string>) {
  yield put(Auth.authUpdateMyUserAccountRequest());
  window.logger.log("doAuthUpdateMyUserAccount", action);
  try {
    yield call(
      // @ts-ignore
      doAccessControlReturnRoles,
      AccessControl.accessControlFetchRoles()
    );
    yield put(CustomFields.customFieldsFetchCustomFields());
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    const { userId, myRole } = yield select(({ auth: { user, role } }) => ({
      userId: user._id,
      myRole: role,
    }));
    yield put(
      AccountSettings.accountSettingsFetchMySettings({
        userId,
        userRoleType: myRole.type,
      })
    );
    const myRoles = getMyOptionsRoles(myRole);
    if (myRoles && myRoles.length > 0) {
      if (onCheckUsersManagerPermissions(myRole.permissions)) {
        yield put(UsersManager.usersManagerFiltersSetRoles({ roles: myRoles }));
        yield put(
          Users.usersFetchUsersKpis({ limit: USER_MANAGER_USER_LIMIT })
        );
      }
      if (
        myRoles.filter(
          ({ apiName }: any) => apiName === "Students" || apiName === "Alumnis"
        ).length > 0
      ) {
        if (onCheckReadProgramsPermissions(myRole.permissions)) {
          yield put(Programs.programsFetchPrograms());
        }
        if (onCheckReadCohortsPermissions(myRole.permissions)) {
          yield put(Cohorts.cohortsFetchCohorts());
        }
      }
      if (onCheckReadInstitutionsPermissions(myRole.permissions)) {
        yield put(Institutions.institutionsFetchInstitutions());
      }
    }
    // call the api to update my user account from specific userId
    const { data } = yield call(AuthApi.GetMyUserAccount, api, userId, {
      token,
    });
    const { user } = data;
    const accountsAvailable: object[] = yield doAuthGetMyAccounts(user.email);
    const {
      notifications: {
        all: { length: loadedNotifications },
      },
      totalNotifications,
    } = yield select(({ notifications }) => notifications);
    if (totalNotifications === 0 || totalNotifications > loadedNotifications) {
      yield put(Notifications.notificationsFetchNotifications());
    }
    if (user.company) {
      yield put(Company.companyFetchCompany({ companyId: user.company._id }));
    }
    if (user.institution) {
      yield call(doMergeMyInstitutionAccount, user.institution);
      yield put(
        Auth.authFetchMyInstitution({ institutionId: user.institution._id })
      );
    }
    if (
      user.activeRotation &&
      user.activeRotation.institutions &&
      user.activeRotation.institutions.length > 0
    ) {
      yield call(
        doMergeMyInstitutionAccount,
        user.activeRotation.institutions[0]
      );
    }
    yield call(doMergeMyUserAccount, { ...user, accountsAvailable });
    // Load Files
    if (onChecReadkMyFilesPermissions(myRole.permissions)) {
      yield put(
        Auth.authFetchMyFiles({ belongsTo: "Users", ownerId: user._id })
      );
    }
    yield put(TenantSettings.tenantSettingsFetchAll());
    yield put(FileCategories.fileCategoriesFetchFileCategories());
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doAuthUpdateMyUserAccount", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Auth.authUpdateMyUserAccountFailure());
  }
}

function* doAuthFetchMyFiles(
  action: Action<
    string,
    {
      belongsTo: "Users" | "Companies" | "Institutions";
      ownerId: string;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Auth.authFetchMyFilesRequest());
  window.logger.log("doAuthFetchMyFiles", action);
  const {
    belongsTo,
    ownerId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to fetch my files
    const { data } = yield call(AuthApi.GetMyFiles, api, belongsTo, ownerId, {
      token,
    });
    const { files } = data;
    yield put(Auth.authFetchMyFilesSuccess({ files }));
    if (callbackSuccess) {
      callbackSuccess(files);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doAuthFetchMyFiles", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Auth.authFetchMyFilesFailure());
    if (callbackError) {
      callbackError(errors);
    }
  }
}

const getRolesInstitutionCustomFieldsRequiredNull = ({ customFields }: any) => {
  if (customFields && customFields.length) {
    const customFieldsRequired = customFields.filter(
      ({ field }: any) => field && field.isRequired
    );
    for (let i = 0; i < customFieldsRequired.length; i++) {
      const {
        field: { permissions },
        value,
      } = customFieldsRequired[i];
      const rolesWrite = Object.keys(permissions).filter(
        (role) => permissions[role] === "write"
      );
      for (let j = 0; j < rolesWrite.length; j++) {
        const roleWrite = rolesWrite[j];
        if (
          permissions[roleWrite] === "write" &&
          (value === null || value === undefined)
        ) {
          return rolesWrite;
        }
      }
    }
  }
  return [];
};

function* doMergeMyInstitutionAccount(institution: Object) {
  const { tenantSettings }: { tenantSettings: any } = yield select(
    ({ tenantSettings }) => tenantSettings
  );
  const hasToCheckRequiredFields =
    tenantSettings["ACCOUNTS_INSTITUTION_REQUIRED_FIELDS"].valuesApi;
  const hasMyInstitutionCustomFieldsRequiredNull = hasToCheckRequiredFields
    ? getRolesInstitutionCustomFieldsRequiredNull(institution)
    : false;
  window.logger.warn(
    "doMergeMyInstitutionAccount",
    institution,
    "hasMyInstitutionCustomFieldsRequiredNull: ",
    hasMyInstitutionCustomFieldsRequiredNull
  );
  yield put(
    Auth.authFetchMyInstitutionSuccess({
      institution,
      hasMyInstitutionCustomFieldsRequiredNull:
        hasMyInstitutionCustomFieldsRequiredNull &&
        hasMyInstitutionCustomFieldsRequiredNull.length
          ? {
              institutionRequired: institution,
              rolesWrite: hasMyInstitutionCustomFieldsRequiredNull,
              isRequired: true,
            }
          : null,
    })
  );
}

function* doAuthFetchMyInstitution(
  action: Action<
    string,
    {
      institutionId: string;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Auth.authFetchMyInstitutionRequest());
  window.logger.log("doAuthFetchMyInstitution", action);
  const { institutionId } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to fetch my institution from specific
    const { data } = yield call(AuthApi.GetMyInstitution, api, institutionId, {
      token,
    });
    const { institution } = data;
    yield call(doMergeMyInstitutionAccount, institution);
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doAuthFetchMyInstitution", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Auth.authFetchMyInstitutionFailure());
  }
}

function* doAuthCheckSessionExpiredApp(
  action: Action<string, { goToSignOut: () => void; errors?: any }>
) {
  window.logger.log("doAuthCheckSessionExpiredApp", action);
  try {
    const { expiredToken } = yield select(({ auth: { expiredToken } }) => ({
      expiredToken,
    }));
    if (expiredToken && checkTokenExpired(expiredToken)) {
      window.logger.warn(
        "doAuthCheckSessionExpiredApp - expiredToken",
        expiredToken,
        true
      );
      action.payload.goToSignOut();
      yield put(Auth.authSessionExpiredApp());
    } else if (action && action.payload && action.payload.errors) {
      const { error } = action.payload.errors;
      if (error === "Unauthorized") {
        window.logger.warn(
          "doAuthCheckSessionExpiredApp - expiredToken",
          action.payload.errors
        );
        yield put(
          push({
            pathname: `/signout/session-expired`,
            state: {
              namePage: "Sign Out",
            },
          })
        );
        yield put(Auth.authSessionExpiredApp());
      }
    }
  } catch (errors) {
    window.logger.error("ErrorSaga - doAuthCheckSessionExpiredApp", errors);
    return;
  }
}

function* doAuthSignOutApp(
  action: Action<
    string,
    {
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Auth.authSignOutRequestApp());
  window.logger.log("doSignOutApp", action);
  const { callbackSuccess, callbackError } = action.payload;
  const socket: { [k: string]: any } = yield select(
    ({ notifications: { socket } }) => socket
  );
  if (socket) {
    socket.disconnect();
  }
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to sign out some user
    yield call(AuthApi.SignOut, api, { token });
    yield put(Auth.authSignOutSuccessApp());
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (errors) {
    window.logger.error("ErrorSaga - doAuthSignOutApp", errors);
    yield put(Auth.authSignOutFailureApp());
    if (callbackError) {
      callbackError(errors);
    }
  }
}

export default function* authSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(Auth.AUTH_SWITCH_ACCOUNT, doAuthSwitchAccount),
    takeLatest(Auth.AUTH_SIGNIN_APP, doAuthSignInApp),
    takeLatest(Auth.AUTH_UPDATE_MY_USER_ACCOUNT, doAuthUpdateMyUserAccount),
    takeLatest(Auth.AUTH_FETCH_MY_FILES, doAuthFetchMyFiles),
    takeLatest(Auth.AUTH_FETCH_MY_INSTITUTION, doAuthFetchMyInstitution),
    takeLatest(
      Auth.AUTH_CHECK_SESSION_EXPIRED_APP,
      doAuthCheckSessionExpiredApp
    ),
    takeLatest(Auth.AUTH_SIGNOUT_APP, doAuthSignOutApp),
  ]);
}
