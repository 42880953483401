// @flow
// Import Features
import * as React from 'react';
// Import Components
import { Message, Transition } from 'semantic-ui-react';
// Import Types
import type { PropsPresentational as Props } from './FieldsWarningContainer';

class FieldsWarningPresentational extends React.Component<Props> {
  render() {
    const styles = {
      zIndex: '9999',
      position: 'absolute',
      bottom: '10px',
      right: '5px'
    };
    const { triggerAnimation } = this.props;
    return (
      <Transition animation="shake" duration={500} visible={triggerAnimation}>
        <Message warning className="ml100 mr20" style={styles}>
          <Message.Header>Please complete all required fields.</Message.Header>
        </Message>
      </Transition>
    );
  }
}

export default FieldsWarningPresentational;
