import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import * as React from "react";
import * as ReactDOM from "react-dom";
import moment from "moment";
import momentLocalizer from "react-widgets-moment";
// @ts-ignore
import simpleNumberLocalizer from "react-widgets-simple-number";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { configureStore, history } from "./store";
import AppMain from "./components/AppMain";
import ErrorBoundary from "./components/error-boundary/ErrorBoundary";
import * as serviceWorkerRegistration from "./utils/service-worker/serviceWorkerRegistration";
import reportWebVitals from "./utils/web-vitals/reportWebVitals";
// Styles Generic
import "./styles/styles.scss";
//Config react-widgets:
moment.locale("en", {
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // Used to determine first week of the year.
  },
});
momentLocalizer();
simpleNumberLocalizer();
// Init Redux Store
export const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <AppMain />
        </ConnectedRouter>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
// TODO: Issue with Service Worker
// Deactivate Service Worker
// It is not working well after updating
// if (
//   navigator.userAgent.toLowerCase().indexOf("safari") !== -1 &&
//   navigator.userAgent.toLowerCase().indexOf("chrome") === -1
// ) {
//   // NOT safari browser
//   unRegisterServiceWorker();
// } else {
//   registerServiceWorker(store);
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
