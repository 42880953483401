import { createAction } from "deox";

// Actions for File Categories
// Fetch all file categories
export const FILE_CATEGORIES_FETCH_FILE_CATEGORIES: string =
  "FILE_CATEGORIES/FETCH/FILE_CATEGORIES";
export const FILE_CATEGORIES_FETCH_FILE_CATEGORIES_REQUEST: string =
  "FILE_CATEGORIES/FETCH/FILE_CATEGORIES/REQUEST";
export const FILE_CATEGORIES_FETCH_FILE_CATEGORIES_SUCCESS: string =
  "FILE_CATEGORIES/FETCH/FILE_CATEGORIES/SUCCESS";
export const FILE_CATEGORIES_FETCH_FILE_CATEGORIES_FAILURE: string =
  "FILE_CATEGORIES/FETCH/FILE_CATEGORIES/FAILURE";
export const fileCategoriesFetchFileCategories = createAction(
  FILE_CATEGORIES_FETCH_FILE_CATEGORIES,
  (resolve) => (payload?: object) => resolve(payload)
);
export const fileCategoriesFetchFileCategoriesRequest = createAction(
  FILE_CATEGORIES_FETCH_FILE_CATEGORIES_REQUEST
);
export const fileCategoriesFetchFileCategoriesSuccess = createAction(
  FILE_CATEGORIES_FETCH_FILE_CATEGORIES_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const fileCategoriesFetchFileCategoriesFailure = createAction(
  FILE_CATEGORIES_FETCH_FILE_CATEGORIES_FAILURE
);
// Create a file category
export const FILE_CATEGORIES_CREATE: string = "FILE_CATEGORIES/CREATE";
export const FILE_CATEGORIES_CREATE_REQUEST: string =
  "FILE_CATEGORIES/CREATE/REQUEST";
export const FILE_CATEGORIES_CREATE_SUCCESS: string =
  "FILE_CATEGORIES/CREATE/SUCCESS";
export const FILE_CATEGORIES_CREATE_FAILURE: string =
  "FILE_CATEGORIES/CREATE/FAILURE";
export const fileCategoriesCreate = createAction(
  FILE_CATEGORIES_CREATE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const fileCategoriesCreateRequest = createAction(
  FILE_CATEGORIES_CREATE_REQUEST
);
export const fileCategoriesCreateSuccess = createAction(
  FILE_CATEGORIES_CREATE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const fileCategoriesCreateFailure = createAction(
  FILE_CATEGORIES_CREATE_FAILURE
);
// Edit a file category
export const FILE_CATEGORIES_EDIT: string = "FILE_CATEGORIES/EDIT";
export const FILE_CATEGORIES_EDIT_REQUEST: string =
  "FILE_CATEGORIES/EDIT/REQUEST";
export const FILE_CATEGORIES_EDIT_SUCCESS: string =
  "FILE_CATEGORIES/EDIT/SUCCESS";
export const FILE_CATEGORIES_EDIT_FAILURE: string =
  "FILE_CATEGORIES/EDIT/FAILURE";
export const fileCategoriesEdit = createAction(
  FILE_CATEGORIES_EDIT,
  (resolve) => (payload?: object) => resolve(payload)
);
export const fileCategoriesEditRequest = createAction(
  FILE_CATEGORIES_EDIT_REQUEST
);
export const fileCategoriesEditSuccess = createAction(
  FILE_CATEGORIES_EDIT_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const fileCategoriesEditFailure = createAction(
  FILE_CATEGORIES_EDIT_FAILURE
);
// Delete a file category
export const FILE_CATEGORIES_DELETE: string = "FILE_CATEGORIES/DELETE";
export const FILE_CATEGORIES_DELETE_REQUEST: string =
  "FILE_CATEGORIES/DELETE/REQUEST";
export const FILE_CATEGORIES_DELETE_SUCCESS: string =
  "FILE_CATEGORIES/DELETE/SUCCESS";
export const FILE_CATEGORIES_DELETE_FAILURE: string =
  "FILE_CATEGORIES/DELETE/FAILURE";
export const fileCategoriesDelete = createAction(
  FILE_CATEGORIES_DELETE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const fileCategoriesDeleteRequest = createAction(
  FILE_CATEGORIES_DELETE_REQUEST
);
export const fileCategoriesDeleteSuccess = createAction(
  FILE_CATEGORIES_DELETE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const fileCategoriesDeleteFailure = createAction(
  FILE_CATEGORIES_DELETE_FAILURE
);
