import { createAction } from "deox";
// Actions for Feedback Form
// OPEN or CLOSE Feedback Form
export const FEEDBACK_FORM_OPEN_CLOSE: string = "FEEDBACK_FORM/OPEN_CLOSE";
export const feedbackFormOpenClose = createAction(FEEDBACK_FORM_OPEN_CLOSE);
// Send Feedback Form
export const FEEDBACK_FORM_SEND: string = "FEEDBACK_FORM/SEND";
export const FEEDBACK_FORM_SEND_REQUEST: string = "FEEDBACK_FORM/SEND/REQUEST";
export const FEEDBACK_FORM_SEND_SUCCESS: string = "FEEDBACK_FORM/SEND/SUCCESS";
export const FEEDBACK_FORM_SEND_FAILURE: string = "FEEDBACK_FORM/SEND/FAILURE";
export const feedbackFormSend = createAction(
  FEEDBACK_FORM_SEND,
  (resolve) => (payload?: object) => resolve(payload)
);
export const feedbackFormSendRequest = createAction(FEEDBACK_FORM_SEND_REQUEST);
export const feedbackFormSendSuccess = createAction(
  FEEDBACK_FORM_SEND_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const feedbackFormSendFailure = createAction(FEEDBACK_FORM_SEND_FAILURE);
