import { createAction } from "deox";

// Actions for Cohorts

// Fetch all Cohorts
export const COHORTS_FETCH_COHORTS: string = "COHORTS/FETCH/COHORTS";
export const COHORTS_FETCH_COHORTS_REQUEST: string =
  "COHORTS/FETCH/COHORTS/REQUEST";
export const COHORTS_FETCH_COHORTS_SUCCESS: string =
  "COHORTS/FETCH/COHORTS/SUCCESS";
export const COHORTS_FETCH_COHORTS_FAILURE: string =
  "COHORTS/FETCH/COHORTS/FAILURE";
export const cohortsFetchCohorts = createAction(
  COHORTS_FETCH_COHORTS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const cohortsFetchCohortsRequest = createAction(
  COHORTS_FETCH_COHORTS_REQUEST
);
export const cohortsFetchCohortsSuccess = createAction(
  COHORTS_FETCH_COHORTS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const cohortsFetchCohortsFailure = createAction(
  COHORTS_FETCH_COHORTS_FAILURE
);
// Create a Cohort
export const COHORTS_CREATE: string = "COHORTS/CREATE";
export const COHORTS_CREATE_REQUEST: string = "COHORTS/CREATE/REQUEST";
export const COHORTS_CREATE_SUCCESS: string = "COHORTS/CREATE/SUCCESS";
export const COHORTS_CREATE_FAILURE: string = "COHORTS/CREATE/FAILURE";
export const cohortsCreate = createAction(
  COHORTS_CREATE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const cohortsCreateRequest = createAction(COHORTS_CREATE_REQUEST);
export const cohortsCreateSuccess = createAction(
  COHORTS_CREATE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const cohortsCreateFailure = createAction(COHORTS_CREATE_FAILURE);
// Edit a Cohort
export const COHORTS_EDIT_COHORT: string = "COHORTS/EDIT/COHORT";
export const COHORTS_EDIT_COHORT_REQUEST: string =
  "COHORTS/EDIT/COHORT/REQUEST";
export const COHORTS_EDIT_COHORT_SUCCESS: string =
  "COHORTS/EDIT/COHORT/SUCCESS";
export const COHORTS_EDIT_COHORT_FAILURE: string =
  "COHORTS/EDIT/COHORT/FAILURE";
export const cohortsEditCohort = createAction(
  COHORTS_EDIT_COHORT,
  (resolve) => (payload?: object) => resolve(payload)
);
export const cohortsEditCohortRequest = createAction(
  COHORTS_EDIT_COHORT_REQUEST
);
export const cohortsEditCohortSuccess = createAction(
  COHORTS_EDIT_COHORT_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const cohortsEditCohortFailure = createAction(
  COHORTS_EDIT_COHORT_FAILURE
);
// Delete a Cohort
export const COHORTS_DELETE: string = "COHORTS/DELETE";
export const COHORTS_DELETE_REQUEST: string = "COHORTS/DELETE/REQUEST";
export const COHORTS_DELETE_SUCCESS: string = "COHORTS/DELETE/SUCCESS";
export const COHORTS_DELETE_FAILURE: string = "COHORTS/DELETE/FAILURE";
export const cohortsDelete = createAction(
  COHORTS_DELETE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const cohortsDeleteRequest = createAction(COHORTS_DELETE_REQUEST);
export const cohortsDeleteSuccess = createAction(
  COHORTS_DELETE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const cohortsDeleteFailure = createAction(COHORTS_DELETE_FAILURE);
