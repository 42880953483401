import { Invitations } from "../actions/";

// Invitations Reducer
type State = {
  lastFetching: Date | undefined | null;
  invitations:
    | {
        User: object[];
      }
    | undefined
    | null;
};
type Action = {
  type: string;
  payload: Object;
};
const initialState: State = {
  lastFetching: null,
  invitations: null,
};

export default function invitations(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case Invitations.INVITATIONS_FETCH_INVITATIONS_SUCCESS:
      return Object.assign({}, state, {
        lastFetching: new Date(),
        invitations: action.payload.invitations,
      });
    case Invitations.INVITATIONS_DELETE_SUCCESS:
      if (state.invitations) {
        const deletedInvitationRoleSelected = state.invitations[
          action.payload.roleSelected
        ].filter(({ _id }) => _id !== action.payload.invitationId);
        let newStateInvitations = {
          ...state.invitations,
          [action.payload.roleSelected]: deletedInvitationRoleSelected,
        };
        if (deletedInvitationRoleSelected.length === 0) {
          delete newStateInvitations[action.payload.roleSelected];
        }
        return Object.assign({}, state, {
          lastFetching: new Date(),
          invitations: newStateInvitations,
        });
      }
      return state;
    case Invitations.INVITATIONS_RESET_INVITATION_LOADED:
      return Object.assign({}, state, {
        lastFetching: new Date(),
        invitations: state.invitations,
      });
    // same state (not change)
    default:
      return state;
  }
}
