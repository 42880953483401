// @flow
// Features
import * as React from "react";
import classNames from "classnames";
// Components
import { Grid } from "semantic-ui-react";
import NotificationsDate from "./NotificationsDate";
const NotificationPeriod = ({
  _id,
  readBy,
  period,
  message,
  createdAt,
}: Object): React$Element<any> => (
  <div
    className={classNames("notification", {
      new: !readBy || (readBy && readBy.length === 0),
    })}
  >
    <Grid columns={2}>
      <Grid.Row className="nopadding">
        <Grid.Column mobile={3} tablet={3} computer={3} className="">
          <i className="icono icono-calendar-fill huge skejule-teal" />
        </Grid.Column>
        <Grid.Column mobile={13} tablet={13} computer={13} className="">
          <div className="message">
            {message.split(/({periodName})/gi).map((el, index) => {
              if (el === "{periodName}") {
                return (
                  <span key={period._id + index} className="skejule-blue">
                    {period.name}
                  </span>
                );
              }
              return el;
            })}
          </div>
          <NotificationsDate
            createdAt={createdAt}
            isMarkAsRead={!!readBy && readBy?.length > 0}
            notificationId={_id}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
);

export default NotificationPeriod;
