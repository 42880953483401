// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  onCheckInstitutionsPermissions,
  onCheckReadInstitutionsPermissions,
  onCheckCreateInstitutionsPermissions,
  onCheckReadMyInstitutionsPermissions,
} from "./InstitutionsPermissions";
import {
  onCheckInstitutionsFilesPermissions,
  onCheckCreateInstitutionsFilesPermissions,
  onCheckMyInstitutionsFilesPermissions,
  onCheckCreateMyInstitutionsFilesPermissions,
} from "../files/FilesPermissions";
import { store } from "../../index";
import { Institutions } from "../../store/actions/";
import ProtectedRoute from "../shared/ProtectedRoute";
import { doMergeInstitutionAccount } from "../../store/sagas/institutions";

const mapStateToProps = (
  { config: { companyDomain }, auth: { isAuthenticated, role } },
  {
    path,
    computedMatch: {
      params: { create_view, institutionId, documents, upload },
    },
  }
) => {
  let allowedInstitutionsRoute =
    isAuthenticated &&
    (onCheckInstitutionsPermissions(role.permissions) ||
      onCheckReadMyInstitutionsPermissions(role.permissions));
  if (!create_view && !onCheckInstitutionsPermissions(role.permissions)) {
    allowedInstitutionsRoute = false;
  }
  if (allowedInstitutionsRoute && create_view) {
    if (create_view === "create" || create_view === "view") {
      if (create_view === "create") {
        if (
          institutionId ||
          !onCheckCreateInstitutionsPermissions(role.permissions)
        ) {
          allowedInstitutionsRoute = false;
        }
      } else if (create_view === "view") {
        if (!institutionId) {
          allowedInstitutionsRoute = false;
        } else if (
          !onCheckReadInstitutionsPermissions(role.permissions) &&
          !onCheckReadMyInstitutionsPermissions(role.permissions)
        ) {
          allowedInstitutionsRoute = false;
        } else if (documents) {
          if (documents !== "documents") {
            allowedInstitutionsRoute = false;
          } else {
            if (
              !onCheckInstitutionsFilesPermissions(role.permissions) &&
              !onCheckMyInstitutionsFilesPermissions(role.permissions)
            ) {
              allowedInstitutionsRoute = false;
            } else if (upload) {
              if (upload !== "upload") {
                allowedInstitutionsRoute = false;
              } else if (
                !onCheckCreateInstitutionsFilesPermissions(role.permissions) &&
                !onCheckCreateMyInstitutionsFilesPermissions(role.permissions)
              ) {
                allowedInstitutionsRoute = false;
              }
            }
          }
        }
      }
    } else {
      allowedInstitutionsRoute = false;
    }
  }
  return {
    component: null,
    restProps: null,
    isAllowedRoute:
      companyDomain.length > 0 && isAuthenticated && allowedInstitutionsRoute,
    redirectTo: "/home",
  };
};
const mapDispatchToProps = (dispatch: Function, ownProps: Object) => {
  const {
    computedMatch: {
      params: { institutionId },
    },
    location: { state },
  } = ownProps;
  const { institutionId: storedinstitutionId } = store.getState().institutions;
  if (institutionId && institutionId !== storedinstitutionId) {
    dispatch(Institutions.institutionsSetInstitutionId({ institutionId }));
    if (state && state.institution) {
      doMergeInstitutionAccount(state.institution, false);
    }
  }
  return { dispatch };
};
const InstitutionsRoute: React.ComponentType<any> = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute)
);

export default InstitutionsRoute;
