import axios from "axios";
import { put, select, call, all, takeLatest } from "redux-saga/effects";
import sortBy from "lodash/sortBy";
import RotationsApi from "../api/Rotations";
import { Api, Auth, Rotations } from "../actions/";
// Types
import type { Action } from "deox";
import type { tAPI } from "../api/API";
import type { CancelRequest, State as StateApi } from "../reducers/api";
import type { StateRotationsListGridContainer } from "../../components/rotations/_Rotations.types";
import type { StateUsersManager } from "../../types/users.types";
import type { StateRotations } from "../../types/rotations.types";
//Sagas for Rotations
function* doRotationsFetchByFilters(
  action: Action<
    string,
    {
      populated?: boolean;
      page?: number;
      limit?: number;
      searchTxt?: string;
      status?: string[];
      userStatus?: StateUsersManager["filters"]["status"];
      students?: string[];
      programs?: string[];
      cohorts?: string[];
      preceptors?: string[];
      institutions?: string[];
      periods?: string[];
      startDate: string;
      endDate: string;
      sortBy?: {
        student: "ascending" | "descending" | "";
        institution: "ascending" | "descending" | "";
        start_end: "ascending" | "descending" | "";
        status: "ascending" | "descending" | "";
      };
      callbackSuccess?: (data: any, metadata: any) => void;
      callbackError?: (
        error: any,
        details: any,
        isAPICancelled: boolean
      ) => void;
    }
  >
) {
  yield put(Rotations.rotationsFetchByFiltersRequest());
  window.logger.log("doRotationsFetchByFilters", action);
  const {
    callbackSuccess = null,
    callbackError = null,
    ...payload
  } = action.payload || {};
  try {
    const { frontendPersonalization } = yield select(
      ({ accountSettings }) => accountSettings
    );
    let rotationsSortBy: {
      student: "ascending" | "descending" | "";
      institution: "ascending" | "descending" | "";
      start_end: "ascending" | "descending" | "";
      status: "ascending" | "descending" | "";
    } = {
      student: "",
      institution: "",
      start_end: "",
      status: "",
    };
    if (
      frontendPersonalization &&
      frontendPersonalization["ROTATIONS_SETTINGS"]
    ) {
      rotationsSortBy = frontendPersonalization["ROTATIONS_SETTINGS"].sortBy;
    }
    const {
      populated = true,
      page = 1,
      limit = 1000,
      searchTxt = "",
      status = [],
      userStatus = [],
      students = [],
      programs = [],
      cohorts = [],
      institutions = [],
      periods = [],
      startDate,
      endDate,
      sortBy = rotationsSortBy,
    } = payload || {};
    const skip = (page - 1) * limit;
    // Api
    const {
      api,
      cancelRequests,
      token,
    }: {
      api: tAPI;
      cancelRequests: StateApi["cancelRequests"];
      token: StateApi["token"];
    } = yield select(({ api }) => api);
    const [cancelRequest] = cancelRequests.filter(
      (cancelRequest: CancelRequest) => cancelRequest.key === "GET_ROTATIONS"
    );
    if (cancelRequest && cancelRequest.source) {
      cancelRequest.source.cancel("Request has been cancelled");
      yield put(
        Api.removeCancelTokenAPI({
          cancelRequest: {
            key: "GET_ROTATIONS",
          },
        })
      );
    }
    // call the api to get all rotations for this company
    const CancelToken = axios.CancelToken,
      source = CancelToken.source();
    yield put(
      Api.addCancelTokenAPI({
        cancelRequest: {
          key: "GET_ROTATIONS",
          source,
        },
      })
    );
    const { data } = yield call(
      RotationsApi.GetRotations,
      api,
      populated,
      skip,
      limit,
      status,
      userStatus,
      students,
      programs,
      cohorts,
      institutions,
      periods,
      searchTxt,
      startDate,
      endDate,
      sortBy,
      { cancelToken: source.token, token }
    );
    yield put(
      Api.removeCancelTokenAPI({
        cancelRequest: {
          key: "GET_CASE_LOGS",
        },
      })
    );
    const { rotations, metadata } = data;
    yield put(
      Rotations.rotationsFetchByFiltersSuccess({
        rotations: rotations,
        rotationsStatus: status,
        metadata,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(rotations, metadata);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doRotationsFetchByFilters", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    const { message, details, status } = errors || "";
    const isAPICancelled = status === "cancelled";
    if (isAPICancelled) {
      yield put(
        Api.removeCancelTokenAPI({
          cancelRequest: {
            key: "GET_CASE_LOGS",
          },
        })
      );
    }
    yield put(Rotations.rotationsFetchByFiltersFailure());
    if (callbackError) {
      callbackError(message, details, isAPICancelled);
    }
  }
}
function* doRotationsFetchKpis(action: Action<string>) {
  yield put(Rotations.rotationsFetchKpisRequest());
  window.logger.log("doRotationsFetchKpis", action);
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    const {
      data: { kpis: RotationsKPIS },
    } = yield call(RotationsApi.GetRotationsKpis, api, { token });
    yield put(
      Rotations.rotationsFetchKpisSuccess({
        kpis: {
          fetching: true,
          Rotations: RotationsKPIS,
        },
      })
    );
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doRotationsFetchKpis", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    const RotationsKPIS: object = yield select(
      ({ rotations: { kpis } }) => kpis
    );
    yield put(
      Rotations.rotationsFetchKpisFailure({
        kpis: RotationsKPIS,
      })
    );
  }
}
function* doRotationsCreateRotations(
  action: Action<
    "ROTATIONS/CREATE/ROTATIONS",
    {
      body: { rotations: object[] };
      cbs: () => void;
      cbe: (error: any) => void;
    }
  >
) {
  yield put(Rotations.rotationsCreateRotationsRequest());
  window.logger.log("doRotationsCreateRotations", action);
  const {
    body: { rotations = [] },
    cbs,
    cbe,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to create a new rotation
    yield call(RotationsApi.CreateRotations, api, { rotations }, { token });
    yield put(Rotations.rotationsCreateRotationsSuccess());
    cbs();
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doRotationsCreateRotations",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Rotations.rotationsCreateRotationsFailure());
    const { message } = errors || "";
    cbe(message);
  }
}
function* doRotationsEditRotations(
  action: Action<
    "ROTATIONS/EDIT/ROTATIONS",
    {
      allRotationsSelected: boolean;
      batch: { [k: string]: any };
      filters: StateRotationsListGridContainer["filtersRotations"];
      rotationsSelected: StateRotationsListGridContainer["rotationsSelected"];
      cbs?: (
        errors: { [k: string]: any }[],
        numRequestAccepted: number
      ) => void;
      cbe?: (error: any) => void;
    }
  >
) {
  window.logger.log("doRotationsEditRotations", action);
  yield put(Rotations.rotationsEditRotationsRequest());
  const {
    allRotationsSelected = false,
    batch,
    filters: rotationsFilters,
    rotationsSelected,
    cbs = null,
    cbe = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    let rotations: string[] = [];
    if (!allRotationsSelected) {
      rotations = rotationsSelected.map(
        (rotation: { [k: string]: any }) => rotation._id
      );
    }
    let filters: {
      rotations?: string[];
      status?: StateRotationsListGridContainer["filtersRotations"]["status"]["selected"];
      programs?: StateRotationsListGridContainer["filtersRotations"]["programs"]["selected"];
      cohorts?: StateRotationsListGridContainer["filtersRotations"]["cohorts"]["selected"];
      institutions?: StateRotationsListGridContainer["filtersRotations"]["institutions"]["selected"];
      periods?: StateRotationsListGridContainer["filtersRotations"]["periods"]["selected"];
      start?: StateRotationsListGridContainer["filtersRotations"]["startEndDate"]["start"];
      end?: StateRotationsListGridContainer["filtersRotations"]["startEndDate"]["end"];
    } = {
      rotations,
    };
    if (allRotationsSelected) {
      filters = {
        status: rotationsFilters.status.selected,
      };
      if (rotationsFilters.programs.selected.length) {
        filters = {
          ...filters,
          programs: rotationsFilters.programs.selected,
        };
      }
      if (rotationsFilters.cohorts.selected.length) {
        filters = {
          ...filters,
          cohorts: rotationsFilters.cohorts.selected,
        };
      }
      if (rotationsFilters.institutions.selected.length) {
        filters = {
          ...filters,
          institutions: rotationsFilters.institutions.selected,
        };
      }
      if (rotationsFilters.periods.selected.length) {
        filters = {
          ...filters,
          periods: rotationsFilters.periods.selected,
        };
      }
      if (rotationsFilters.startEndDate.start) {
        filters = {
          ...filters,
          start: rotationsFilters.startEndDate.start,
        };
      }
      if (rotationsFilters.startEndDate.end) {
        filters = {
          ...filters,
          end: rotationsFilters.startEndDate.end,
        };
      }
    }
    // call the api to edit users in bulk
    const {
      data: { errors = [], rotations: rotationsRequestAccepted = [] },
    } = yield call(
      RotationsApi.EditRotations,
      api,
      {
        filters,
        batch,
      },
      { token }
    );
    Rotations.rotationsEditRotationsSuccess();
    if (cbs) {
      cbs(errors, rotationsRequestAccepted.length);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doRotationsEditRotations", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Rotations.rotationsEditRotationsFailure());
    const { message } = errors || "";
    if (cbe) {
      cbe(message);
    }
  }
}
function* doRotationsSetAutomaticRotations(
  action: Action<
    "ROTATIONS/SET/AUTOMATIC_ROTATIONS_PARAMETERS",
    {
      automaticRotationsParameterSelected: object;
      body: object;
      cbs: (data: any) => void;
      cbe: (error: any) => void;
    }
  >
) {
  yield put(Rotations.rotationsSetAutomaticRotationsRequest());
  window.logger.log("doRotationsSetAutomaticRotations", action);
  const { automaticRotationsParameterSelected, body, cbs, cbe } =
    action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to create a new rotation
    const {
      data: { rotations = [] },
    } = yield call(RotationsApi.SetAutomaticRotations, api, body, { token });
    let rotationsWithErrors = rotations.map(
      (
        rotation: StateRotations["rotationsAutomatic"]["draftRotations"][number]
      ) => {
        if (
          rotation?.institutions?.length === 0 &&
          !rotation?.start &&
          !rotation?.end
        ) {
          return {
            ...rotation,
            error: "Could not find proper match. Manual adjustment needed",
          };
        }
        return rotation;
      }
    );
    yield put(
      Rotations.rotationsSetAutomaticRotationsSuccess({
        automaticRotationsParameterSelected,
        rotations: sortBy(rotationsWithErrors, [
          "student.firstName",
          "student.lastName",
        ]),
      })
    );
    cbs(rotations);
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doRotationsSetAutomaticRotations",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Rotations.rotationsSetAutomaticRotationsFailure());
    const { message } = errors || "";
    cbe(message);
  }
}
function* doRotationsFetchRotation(
  action: Action<
    string,
    {
      rotationId: string;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Rotations.rotationsFetchRotationRequest());
  window.logger.log("doRotationsFetchRotation", action);
  const {
    rotationId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get a rotation
    const { data } = yield call(RotationsApi.GetRotation, api, rotationId, {
      token,
    });
    const { rotation } = data;
    yield put(Rotations.rotationsFetchRotationSuccess({ rotation }));
    if (callbackSuccess) {
      callbackSuccess(rotation);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doRotationsFetchRotation", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Rotations.rotationsFetchRotationFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}
function* doRotationsEdit(
  action: Action<
    string,
    {
      rotationId: string;
      body: object;
      cbs?: (data: any) => void;
      cbe?: (error: any) => void;
    }
  >
) {
  yield put(Rotations.rotationsEditRequest());
  window.logger.log("doRotationsEdit", action);
  const { rotationId, body, cbs = null, cbe = null } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to update a rotation for that rotationId
    yield call(RotationsApi.EditRotation, api, rotationId, body, { token });
    const { data: dataRotation } = yield call(
      RotationsApi.GetRotation,
      api,
      rotationId,
      { token }
    );
    const { rotation } = dataRotation;
    yield put(Rotations.rotationsEditSuccess({ rotation }));
    if (cbs) {
      cbs(rotation);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doRotationsEdit", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Rotations.rotationsEditFailure());
    const { message } = errors || "";
    if (cbe) {
      cbe(message);
    }
  }
}
function* doRotationsDelete(
  action: Action<
    string,
    {
      rotationId: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Rotations.rotationsDeleteRequest());
  window.logger.log("doRotationsDelete", action);
  const {
    rotationId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to delete a rotation for that specific rotationId
    yield call(RotationsApi.DeleteRotation, api, rotationId, { token });
    yield put(Rotations.rotationsDeleteSuccess({ rotationId }));
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doRotationsDelete", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Rotations.rotationsDeleteFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}
export default function* rotationsSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(Rotations.ROTATIONS_FETCH_BY_FILTERS, doRotationsFetchByFilters),
    takeLatest(Rotations.ROTATIONS_FETCH_KPIS, doRotationsFetchKpis),
    takeLatest(
      Rotations.ROTATIONS_CREATE_ROTATIONS,
      doRotationsCreateRotations
    ),
    takeLatest(Rotations.ROTATIONS_EDIT_ROTATIONS, doRotationsEditRotations),
    takeLatest(
      Rotations.ROTATIONS_SET_AUTOMATIC_ROTATIONS,
      doRotationsSetAutomaticRotations
    ),
    takeLatest(Rotations.ROTATIONS_FETCH_ROTATION, doRotationsFetchRotation),
    takeLatest(Rotations.ROTATIONS_EDIT, doRotationsEdit),
    takeLatest(Rotations.ROTATIONS_DELETE, doRotationsDelete),
  ]);
}
