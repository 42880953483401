import { createAction } from "deox";

// Actions for Broadcast Messages

// Fetch all Broadcast Messages
export const BROADCAST_MESSAGES_FETCH_MESSAGES: string =
  "BROADCAST_MESSAGES/FETCH/BROADCAST_MESSAGES";
export const BROADCAST_MESSAGES_FETCH_MESSAGES_REQUEST: string =
  "BROADCAST_MESSAGES/FETCH/BROADCAST_MESSAGES/REQUEST";
export const BROADCAST_MESSAGES_FETCH_MESSAGES_SUCCESS: string =
  "BROADCAST_MESSAGES/FETCH/BROADCAST_MESSAGES/SUCCESS";
export const BROADCAST_MESSAGES_FETCH_MESSAGES_FAILURE: string =
  "BROADCAST_MESSAGES/FETCH/BROADCAST_MESSAGES/FAILURE";
export const broadcastMessagesFetchMessages = createAction(
  BROADCAST_MESSAGES_FETCH_MESSAGES,
  (resolve) => (payload?: object) => resolve(payload)
);
export const broadcastMessagesFetchMessagesRequest = createAction(
  BROADCAST_MESSAGES_FETCH_MESSAGES_REQUEST
);
export const broadcastMessagesFetchMessagesSuccess = createAction(
  BROADCAST_MESSAGES_FETCH_MESSAGES_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const broadcastMessagesFetchMessagesFailure = createAction(
  BROADCAST_MESSAGES_FETCH_MESSAGES_FAILURE
);
// Set/Save all Broadcast Messages
export const BROADCAST_MESSAGES_SET_MESSAGES: string =
  "BROADCAST_MESSAGES/SET/MESSAGES";
export const BROADCAST_MESSAGES_SAVE_MESSAGES: string =
  "BROADCAST_MESSAGES/SAVE/MESSAGES";
export const broadcastMessagesSetMessages = createAction(
  BROADCAST_MESSAGES_SET_MESSAGES,
  (resolve) => (payload?: object) => resolve(payload)
);
export const broadcastMessagesSaveMessages = createAction(
  BROADCAST_MESSAGES_SAVE_MESSAGES,
  (resolve) => (payload?: object) => resolve(payload)
);
// Create a Broadcast Message
export const BROADCAST_MESSAGES_CREATE: string = "BROADCAST_MESSAGES/CREATE";
export const BROADCAST_MESSAGES_CREATE_REQUEST: string =
  "BROADCAST_MESSAGES/CREATE/REQUEST";
export const BROADCAST_MESSAGES_CREATE_SUCCESS: string =
  "BROADCAST_MESSAGES/CREATE/SUCCESS";
export const BROADCAST_MESSAGES_CREATE_FAILURE: string =
  "BROADCAST_MESSAGES/CREATE/FAILURE";
export const broadcastMessagesCreate = createAction(
  BROADCAST_MESSAGES_CREATE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const broadcastMessagesCreateRequest = createAction(
  BROADCAST_MESSAGES_CREATE_REQUEST
);
export const broadcastMessagesCreateSuccess = createAction(
  BROADCAST_MESSAGES_CREATE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const broadcastMessagesCreateFailure = createAction(
  BROADCAST_MESSAGES_CREATE_FAILURE
);
// Edit a Broadcast Message
export const BROADCAST_MESSAGES_EDIT: string = "BROADCAST_MESSAGES/EDIT";
export const BROADCAST_MESSAGES_EDIT_REQUEST: string =
  "BROADCAST_MESSAGES/EDIT/REQUEST";
export const BROADCAST_MESSAGES_EDIT_SUCCESS: string =
  "BROADCAST_MESSAGES/EDIT/SUCCESS";
export const BROADCAST_MESSAGES_EDIT_FAILURE: string =
  "BROADCAST_MESSAGES/EDIT/FAILURE";
export const broadcastMessagesEdit = createAction(
  BROADCAST_MESSAGES_EDIT,
  (resolve) => (payload?: object) => resolve(payload)
);
export const broadcastMessagesEditRequest = createAction(
  BROADCAST_MESSAGES_EDIT_REQUEST
);
export const broadcastMessagesEditSuccess = createAction(
  BROADCAST_MESSAGES_EDIT_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const broadcastMessagesEditFailure = createAction(
  BROADCAST_MESSAGES_EDIT_FAILURE
);
// Delete a Broadcast Message
export const BROADCAST_MESSAGES_DELETE: string = "BROADCAST_MESSAGES/DELETE";
export const BROADCAST_MESSAGES_DELETE_REQUEST: string =
  "BROADCAST_MESSAGES/DELETE/REQUEST";
export const BROADCAST_MESSAGES_DELETE_SUCCESS: string =
  "BROADCAST_MESSAGES/DELETE/SUCCESS";
export const BROADCAST_MESSAGES_DELETE_FAILURE: string =
  "BROADCAST_MESSAGES/DELETE/FAILURE";
export const broadcastMessagesDelete = createAction(
  BROADCAST_MESSAGES_DELETE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const broadcastMessagesDeleteRequest = createAction(
  BROADCAST_MESSAGES_DELETE_REQUEST
);
export const broadcastMessagesDeleteSuccess = createAction(
  BROADCAST_MESSAGES_DELETE_SUCCESS
);
export const broadcastMessagesDeleteFailure = createAction(
  BROADCAST_MESSAGES_DELETE_FAILURE
);
