// @flow

/* PERMISSIONS */

export const onCheckImportDataPermissions = (permissions: ?Object): boolean => {
  const { ImportData } = permissions || {};
  if (
    ImportData &&
    ImportData.apiValue &&
    ImportData.apiValue['ImportData:generateCSV'] &&
    ImportData.apiValue['ImportData:generateCSV']['isEnabled'] &&
    ImportData.apiValue['ImportData:uploadCSV'] &&
    ImportData.apiValue['ImportData:uploadCSV']['isEnabled']
  ) {
    return true;
  }
  return false;
};
