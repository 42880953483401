import { TenantSettings } from "../actions/";
import {
  onGetTenant,
  onSetCategoryWord,
  parseApiName,
  parseName,
} from "../../utils/";

// Types
import type { PayloadObject, UndefinedNull } from "../../types/common.types";
// TenantSettings Reducer
export type TenantSettingsNameCaseLogsRequiredFields =
  | "CASELOGS_AGE_REQUIRED_FIELD"
  | "CASELOGS_FIRST_LANGUAGE_REQUIRED_FIELD"
  | "CASELOGS_GENDER_REQUIRED_FIELD"
  | "CASELOGS_CLINIC_RECORD_NUMBER_REQUIRED_FIELD"
  | "CASELOGS_RACE_REQUIRED_FIELD"
  | "CASELOGS_PMCC_REQUIRED_FIELD"
  | "CASELOGS_INSURANCE_REQUIRED_FIELD"
  | "CASELOGS_APPOINTMENT_DATE_REQUIRED_FIELD"
  | "CASELOGS_CHIEF_COMPLAINT_REQUIRED_FIELD"
  | "CASELOGS_TIME_WITH_PATIENT_REQUIRED_FIELD"
  | "CASELOGS_REASON_FOR_VISIT_REQUIRED_FIELD"
  | "CASELOGS_CONSULT_WITH_PRECEPTOR_REQUIRED_FIELD"
  | "CASELOGS_DIAGNOSIS_AND_PROCEDURE_NOTES_REQUIRED_FIELD"
  | "CASELOGS_PROCEDURE_STATUS_REQUIRED_FIELD";
export type TenantSettingsNameCaseLogsRequiredFieldsKeys =
  | "age"
  | "firstLanguage"
  | "gender"
  | "institutionRecordNumber"
  | "race"
  | "pmcc"
  | "insurance"
  | "appointmentDate"
  | "chiefComplaint"
  | "minutesWithPatient"
  | "reasonForVisit"
  | "minutesWithPreceptor"
  | "comments"
  | "procedureStatus";
export type TenantSettingsNameCaseLogsEnabledFields =
  | "CASELOGS_AGE_ENABLED_FIELD"
  | "CASELOGS_FIRST_LANGUAGE_ENABLED_FIELD"
  | "CASELOGS_GENDER_ENABLED_FIELD"
  | "CASELOGS_CLINIC_RECORD_NUMBER_ENABLED_FIELD"
  | "CASELOGS_RACE_ENABLED_FIELD"
  | "CASELOGS_PMCC_ENABLED_FIELD"
  | "CASELOGS_INSURANCE_ENABLED_FIELD"
  | "CASELOGS_APPOINTMENT_DATE_ENABLED_FIELD"
  | "CASELOGS_CHIEF_COMPLAINT_ENABLED_FIELD"
  | "CASELOGS_TIME_WITH_PATIENT_ENABLED_FIELD"
  | "CASELOGS_REASON_FOR_VISIT_ENABLED_FIELD"
  | "CASELOGS_CONSULT_WITH_PRECEPTOR_ENABLED_FIELD"
  | "CASELOGS_DISCIPLINE_FOR_DIAGNOSES_AND_DIAGNOSIS_ENABLED_FIELD"
  | "CASELOGS_DIAGNOSIS_AND_PROCEDURE_NOTES_ENABLED_FIELD"
  | "CASELOGS_PROCEDURE_STATUS_ENABLED_FIELD";
export type TenantSettingsNameCaseLogsEnabledFieldsKeys =
  | TenantSettingsNameCaseLogsRequiredFieldsKeys
  | "diagnosticId";
export type TenantSettingNameCaseLogs =
  | "CASELOGS_DESIGN_VERSION"
  | "EDIT_PENDING_CASELOGS"
  | "CASELOGS_AUTO_APPROVAL"
  | "ENABLE_DUPLICATED_CASE_LOG_CHECKS"
  | TenantSettingsNameCaseLogsRequiredFields
  | TenantSettingsNameCaseLogsEnabledFields
  | "CASELOGS_LANGUAGE_LIST"
  | "CASELOGS_GENDER_LIST"
  | "CASELOGS_INSURANCE_LIST"
  | "CASELOGS_RACES_LIST"
  | "ALLOW_ALPHANUMERICS_CLINIC_RECORD_NUMBER"
  | "CASELOGS_REASONS_FOR_VISIT_LIST"
  | "CASELOGS_DISCIPLINES_LIST_FOR_DIAGNOSTICS"
  | "CASELOGS_DIAGNOSTICS_LIST"
  | "CASELOGS_DISCIPLINES_LIST_FOR_PROCEDURES"
  | "CASELOGS_PROCEDURES_LIST"
  | "CASELOGS_PROCEDURES_STATUS_LIST"
  | "CASELOGS_QUESTIONS_REVIEW"
  | "CASELOGS_TOOTH_LIST";
export type TenantSettingName =
  // GENERAL
  | "SHOW_DEACTIVATED_USERS"
  | "RETROACTIVE_DATE"
  // ACCOUNTS (only FrontEnd)
  | "ACCOUNTS_COMPANY_REQUIRED_FIELDS"
  | "ACCOUNTS_INSTITUTION_REQUIRED_FIELDS"
  | "ACCOUNTS_PROFILE_REQUIRED_FIELDS"
  // CASE LOGS
  | TenantSettingNameCaseLogs
  // EVALUATIONS
  | "EVALUATIONS_NOTIFICATIONS_EMAIL"
  // ROTATIONS
  | "ROTATIONS_OVERLAPPING_VALIDATION"
  | "ALLOW_AUTOMATIC_ROTATIONS_SCHEDULING_PARAMETERS"
  | "AUTOMATIC_ROTATIONS_SCHEDULING_PARAMETERS"
  // INSTITUTIONS
  | "INSTITUTIONS_TYPES_LIST"
  // BROADCAST MESSAGES
  | "BROADCAST_MESSAGES"
  // NOTIFICATIONS
  | "NOTIFICATIONS_SETTINGS";
export type ArrayTenantSettingsNameCaseLogsRequiredFields = [
  TenantSettingsNameCaseLogsRequiredFields
];
export type ArrayTenantSettingsNameCaseLogsEnabledFields = [
  TenantSettingsNameCaseLogsEnabledFields
];
export type TenantSetting = {
  type:
    | "General"
    | "Account"
    | "CaseLogs"
    | "Evaluations"
    | "Rotations"
    | "Institutions"
    | "Notifications";
  keys: string[];
  _id: string | UndefinedNull;
  name: TenantSettingName;
  valuesApi: any;
  values: any;
  text: string;
};
export type State = {
  isFetchingTenantSettings: boolean;
  lastFetchingTenantSettings: number | UndefinedNull;
  tenantSettings: { [key in TenantSettingName]: TenantSetting };
  tenantSettingsName: TenantSettingName[];
};
type TenantSettingsRequiredFields = Pick<
  State["tenantSettings"],
  | TenantSettingsNameCaseLogsRequiredFields
  | TenantSettingsNameCaseLogsEnabledFields
>;
type CaseLogsRequiredEnabledFields<TypeNames> =
  | {
      [key in TypeNames as TenantSettingsNameCaseLogsRequiredFields]: TenantSettingsNameCaseLogsRequiredFieldsKeys;
    }
  | {
      [key in TypeNames as TenantSettingsNameCaseLogsEnabledFields]: TenantSettingsNameCaseLogsEnabledFieldsKeys;
    };
type OnSetDefaultCaseLogsRequiredEnabledFields = (
  caseLogsRequiredEnabledFields:
    | CaseLogsRequiredEnabledFields<TenantSettingsNameCaseLogsRequiredFields>
    | CaseLogsRequiredEnabledFields<TenantSettingsNameCaseLogsEnabledFields>
) => TenantSettingsRequiredFields;
type Action = {
  type: string;
  payload: PayloadObject;
};
export const caseLogsRequiredFields: CaseLogsRequiredEnabledFields<TenantSettingsNameCaseLogsRequiredFields> =
  {
    CASELOGS_AGE_REQUIRED_FIELD: "age",
    CASELOGS_FIRST_LANGUAGE_REQUIRED_FIELD: "firstLanguage",
    CASELOGS_GENDER_REQUIRED_FIELD: "gender",
    CASELOGS_CLINIC_RECORD_NUMBER_REQUIRED_FIELD: "institutionRecordNumber",
    CASELOGS_RACE_REQUIRED_FIELD: "race",
    CASELOGS_PMCC_REQUIRED_FIELD: "pmcc",
    CASELOGS_INSURANCE_REQUIRED_FIELD: "insurance",
    CASELOGS_APPOINTMENT_DATE_REQUIRED_FIELD: "appointmentDate",
    CASELOGS_CHIEF_COMPLAINT_REQUIRED_FIELD: "chiefComplaint",
    CASELOGS_TIME_WITH_PATIENT_REQUIRED_FIELD: "minutesWithPatient",
    CASELOGS_REASON_FOR_VISIT_REQUIRED_FIELD: "reasonForVisit",
    CASELOGS_CONSULT_WITH_PRECEPTOR_REQUIRED_FIELD: "minutesWithPreceptor",
    CASELOGS_DIAGNOSIS_AND_PROCEDURE_NOTES_REQUIRED_FIELD: "comments",
    CASELOGS_PROCEDURE_STATUS_REQUIRED_FIELD: "procedureStatus",
  };
export const caseLogsEnabledFields: CaseLogsRequiredEnabledFields<TenantSettingsNameCaseLogsEnabledFields> =
  {
    CASELOGS_AGE_ENABLED_FIELD: "age",
    CASELOGS_FIRST_LANGUAGE_ENABLED_FIELD: "firstLanguage",
    CASELOGS_GENDER_ENABLED_FIELD: "gender",
    CASELOGS_CLINIC_RECORD_NUMBER_ENABLED_FIELD: "institutionRecordNumber",
    CASELOGS_RACE_ENABLED_FIELD: "race",
    CASELOGS_PMCC_ENABLED_FIELD: "pmcc",
    CASELOGS_INSURANCE_ENABLED_FIELD: "insurance",
    CASELOGS_APPOINTMENT_DATE_ENABLED_FIELD: "appointmentDate",
    CASELOGS_CHIEF_COMPLAINT_ENABLED_FIELD: "chiefComplaint",
    CASELOGS_TIME_WITH_PATIENT_ENABLED_FIELD: "minutesWithPatient",
    CASELOGS_REASON_FOR_VISIT_ENABLED_FIELD: "reasonForVisit",
    CASELOGS_CONSULT_WITH_PRECEPTOR_ENABLED_FIELD: "comments",
    CASELOGS_DIAGNOSIS_AND_PROCEDURE_NOTES_ENABLED_FIELD:
      "minutesWithPreceptor",
    CASELOGS_DISCIPLINE_FOR_DIAGNOSES_AND_DIAGNOSIS_ENABLED_FIELD:
      "diagnosticId",
    CASELOGS_PROCEDURE_STATUS_ENABLED_FIELD: "procedureStatus",
  };
export const tenantSettingsNameCaseLogs: TenantSettingNameCaseLogs[] = [
  "CASELOGS_DESIGN_VERSION",
  "EDIT_PENDING_CASELOGS",
  "CASELOGS_AUTO_APPROVAL",
  "ENABLE_DUPLICATED_CASE_LOG_CHECKS",
  ...(Object.keys(
    caseLogsRequiredFields
  ) as ArrayTenantSettingsNameCaseLogsRequiredFields),
  ...(Object.keys(
    caseLogsEnabledFields
  ) as ArrayTenantSettingsNameCaseLogsEnabledFields),
  "CASELOGS_LANGUAGE_LIST",
  "CASELOGS_GENDER_LIST",
  "CASELOGS_INSURANCE_LIST",
  "CASELOGS_RACES_LIST",
  "ALLOW_ALPHANUMERICS_CLINIC_RECORD_NUMBER",
  "CASELOGS_REASONS_FOR_VISIT_LIST",
  "CASELOGS_DISCIPLINES_LIST_FOR_DIAGNOSTICS",
  "CASELOGS_DIAGNOSTICS_LIST",
  "CASELOGS_DISCIPLINES_LIST_FOR_PROCEDURES",
  "CASELOGS_PROCEDURES_LIST",
  "CASELOGS_PROCEDURES_STATUS_LIST",
  "CASELOGS_QUESTIONS_REVIEW",
  "CASELOGS_TOOTH_LIST",
];
const onSetDefaultCaseLogsRequiredEnabledFields: OnSetDefaultCaseLogsRequiredEnabledFields =
  (caseLogsRequiredEnabledFields) => {
    let caseLogsFieldsObject = {};
    Object.keys(caseLogsRequiredEnabledFields).forEach((tenantSettingsName) => {
      let text = tenantSettingsName
        .replace(/^CASELOGS_|_ENABLED|_REQUIRED_FIELD$/g, "")
        .replace(/_/g, " ")
        .split(" ")
        .map((s) => s.charAt(0) + s.toLowerCase().slice(1))
        .join(" ");
      if (tenantSettingsName.includes("PMCC")) {
        text = "People with Medically Complex Conditions";
      }
      if (tenantSettingsName.includes("ENABLED")) {
        text = `Show ${text.replace(
          /Discipline/g,
          onSetCategoryWord("Discipline")
        )}`;
      }
      caseLogsFieldsObject = {
        ...caseLogsFieldsObject,
        [tenantSettingsName as keyof typeof caseLogsRequiredEnabledFields]: {
          type: "CaseLogs",
          keys: [
            caseLogsRequiredEnabledFields[
              tenantSettingsName as keyof typeof caseLogsRequiredEnabledFields
            ],
          ],
          _id: null,
          name: tenantSettingsName,
          valuesApi: false,
          values: false,
          text,
        },
      };
    });
    return caseLogsFieldsObject as TenantSettingsRequiredFields;
  };
const initialStateTenantSettings: State["tenantSettings"] = {
  SHOW_DEACTIVATED_USERS: {
    type: "General",
    keys: [],
    _id: null,
    name: "SHOW_DEACTIVATED_USERS",
    valuesApi: false,
    values: false,
    text: "Show Deactivated Users",
  },
  RETROACTIVE_DATE: {
    type: "General",
    keys: [],
    _id: null,
    name: "RETROACTIVE_DATE",
    valuesApi: null,
    values: null,
    text: "Retroactive Date",
  },
  ACCOUNTS_COMPANY_REQUIRED_FIELDS: {
    type: "Account",
    keys: [],
    _id: "ACCOUNTS_COMPANY_REQUIRED_FIELDS",
    name: "ACCOUNTS_COMPANY_REQUIRED_FIELDS",
    valuesApi: onGetTenant().includes("udm"),
    values: onGetTenant().includes("udm"),
    text: "My School Profile with Required Fields",
  },
  ACCOUNTS_INSTITUTION_REQUIRED_FIELDS: {
    type: "Account",
    keys: [],
    _id: "ACCOUNTS_INSTITUTION_REQUIRED_FIELDS",
    name: "ACCOUNTS_INSTITUTION_REQUIRED_FIELDS",
    valuesApi: onGetTenant().includes("udm"),
    values: onGetTenant().includes("udm"),
    text: "My School Profile with Required Fields",
  },
  ACCOUNTS_PROFILE_REQUIRED_FIELDS: {
    type: "Account",
    keys: [],
    _id: "ACCOUNTS_PROFILE_REQUIRED_FIELDS",
    name: "ACCOUNTS_PROFILE_REQUIRED_FIELDS",
    valuesApi: onGetTenant().includes("udm"),
    values: onGetTenant().includes("udm"),
    text: "My School Profile with Required Fields",
  },
  CASELOGS_DESIGN_VERSION: {
    type: "CaseLogs",
    keys: [],
    _id: null,
    name: "CASELOGS_DESIGN_VERSION",
    valuesApi: "",
    values: "",
    text: "Case Logs Form Design",
  },
  EDIT_PENDING_CASELOGS: {
    type: "CaseLogs",
    keys: [],
    _id: null,
    name: "EDIT_PENDING_CASELOGS",
    valuesApi: false,
    values: false,
    text: "Edit Pending Case Logs",
  },
  CASELOGS_AUTO_APPROVAL: {
    type: "CaseLogs",
    keys: [],
    _id: null,
    name: "CASELOGS_AUTO_APPROVAL",
    valuesApi: false,
    values: false,
    text: "Auto Approve Case Logs",
  },
  ENABLE_DUPLICATED_CASE_LOG_CHECKS: {
    type: "CaseLogs",
    keys: [],
    _id: null,
    name: "ENABLE_DUPLICATED_CASE_LOG_CHECKS",
    valuesApi: false,
    values: false,
    text: "Show Duplicated Case Logs",
  },
  ...onSetDefaultCaseLogsRequiredEnabledFields(caseLogsRequiredFields),
  ...onSetDefaultCaseLogsRequiredEnabledFields(caseLogsEnabledFields),
  CASELOGS_LANGUAGE_LIST: {
    type: "CaseLogs",
    keys: ["_id", "language"],
    _id: null,
    name: "CASELOGS_LANGUAGE_LIST",
    valuesApi: [],
    values: [],
    text: "Languages",
  },
  CASELOGS_GENDER_LIST: {
    type: "CaseLogs",
    keys: ["_id", "gender"],
    _id: null,
    name: "CASELOGS_GENDER_LIST",
    valuesApi: [],
    values: [],
    text: "Gender",
  },
  CASELOGS_INSURANCE_LIST: {
    type: "CaseLogs",
    keys: ["_id", "insurance"],
    _id: null,
    name: "CASELOGS_INSURANCE_LIST",
    valuesApi: [],
    values: [],
    text: "Insurances",
  },
  CASELOGS_RACES_LIST: {
    type: "CaseLogs",
    keys: ["_id", "race"],
    _id: null,
    name: "CASELOGS_RACES_LIST",
    valuesApi: [],
    values: [],
    text: "Races",
  },
  ALLOW_ALPHANUMERICS_CLINIC_RECORD_NUMBER: {
    type: "CaseLogs",
    keys: [],
    _id: null,
    name: "ALLOW_ALPHANUMERICS_CLINIC_RECORD_NUMBER",
    valuesApi: false,
    values: false,
    text: "Clinic Record Number type",
  },
  CASELOGS_REASONS_FOR_VISIT_LIST: {
    type: "CaseLogs",
    keys: ["_id", "reasonForVisit"],
    _id: null,
    name: "CASELOGS_REASONS_FOR_VISIT_LIST",
    valuesApi: [],
    values: [],
    text: "Reasons for Visit",
  },
  CASELOGS_DISCIPLINES_LIST_FOR_DIAGNOSTICS: {
    type: "CaseLogs",
    keys: ["_id", "diagnosticDiscipline"],
    _id: null,
    name: "CASELOGS_DISCIPLINES_LIST_FOR_DIAGNOSTICS",
    valuesApi: [],
    values: [],
    text: `${onSetCategoryWord("Disciplines")} for Diagnoses`,
  },
  CASELOGS_DIAGNOSTICS_LIST: {
    type: "CaseLogs",
    keys: [
      "_id",
      "diagnosticDisciplineId",
      "diagnosticDiscipline",
      "diagnostic",
      "description",
    ],
    _id: null,
    name: "CASELOGS_DIAGNOSTICS_LIST",
    valuesApi: [],
    values: [],
    text: "Diagnosis",
  },
  CASELOGS_DISCIPLINES_LIST_FOR_PROCEDURES: {
    type: "CaseLogs",
    keys: ["_id", "procedureDiscipline"],
    _id: null,
    name: "CASELOGS_DISCIPLINES_LIST_FOR_PROCEDURES",
    valuesApi: [],
    values: [],
    text: `${onSetCategoryWord("Disciplines")} for Procedures`,
  },
  CASELOGS_PROCEDURES_LIST: {
    type: "CaseLogs",
    keys: [
      "_id",
      "procedureDisciplineId",
      "procedureDiscipline",
      "procedure",
      "cpu",
    ],
    _id: null,
    name: "CASELOGS_PROCEDURES_LIST",
    valuesApi: [],
    values: [],
    text: "Procedures",
  },
  CASELOGS_PROCEDURES_STATUS_LIST: {
    type: "CaseLogs",
    keys: ["_id", "procedureStatus"],
    _id: null,
    name: "CASELOGS_PROCEDURES_STATUS_LIST",
    valuesApi: [],
    values: [],
    text: "Procedures Status",
  },
  CASELOGS_QUESTIONS_REVIEW: {
    type: "CaseLogs",
    keys: [
      "_id",
      "isEnabled",
      "procedureCodes",
      "criteriaList",
      "evaluationProcedure",
      "filters",
    ],
    _id: null,
    name: "CASELOGS_QUESTIONS_REVIEW",
    valuesApi: [],
    values: [],
    text: "Procedures Review",
  },
  CASELOGS_TOOTH_LIST: {
    type: "CaseLogs",
    keys: ["_id", "toothName", "toothReference"],
    _id: null,
    name: "CASELOGS_TOOTH_LIST",
    valuesApi: [],
    values: [],
    text: "Teeth",
  },
  EVALUATIONS_NOTIFICATIONS_EMAIL: {
    type: "Evaluations",
    keys: ["emails", "enabled"],
    _id: null,
    name: "EVALUATIONS_NOTIFICATIONS_EMAIL",
    valuesApi: {
      emails: [],
      enabled: false,
    },
    values: {
      emails: [],
      enabled: false,
    },
    text: "Evaluations notifications by email",
  },
  ROTATIONS_OVERLAPPING_VALIDATION: {
    type: "Rotations",
    keys: [],
    _id: null,
    name: "ROTATIONS_OVERLAPPING_VALIDATION",
    valuesApi: false,
    values: false,
    text: "Allow overlapping Rotations",
  },
  ALLOW_AUTOMATIC_ROTATIONS_SCHEDULING_PARAMETERS: {
    type: "Rotations",
    keys: [],
    _id: null,
    name: "ALLOW_AUTOMATIC_ROTATIONS_SCHEDULING_PARAMETERS",
    valuesApi: false,
    values: false,
    text: "Allow Automatic Rotations",
  },
  AUTOMATIC_ROTATIONS_SCHEDULING_PARAMETERS: {
    type: "Rotations",
    keys: [],
    _id: null,
    name: "AUTOMATIC_ROTATIONS_SCHEDULING_PARAMETERS",
    valuesApi: [],
    values: [],
    text: "Automatic Rotations > parameters",
  },
  INSTITUTIONS_TYPES_LIST: {
    type: "Institutions",
    keys: ["_id", "institutionType"],
    _id: null,
    name: "INSTITUTIONS_TYPES_LIST",
    valuesApi: [],
    values: [],
    text: parseApiName(parseName("InstitutionTypes")),
  },
  BROADCAST_MESSAGES: {
    type: "Notifications",
    keys: [
      "_id",
      "roles",
      "users",
      "programs",
      "cohorts",
      "institutions",
      "message",
    ],
    _id: null,
    name: "BROADCAST_MESSAGES",
    valuesApi: [],
    values: [],
    text: "Messages",
  },
  NOTIFICATIONS_SETTINGS: {
    type: "Notifications",
    keys: [
      "CASE_LOG_SUBMITED",
      "CASE_LOG_DENIED",
      "CASE_LOG_APPROVED",
      "ROTATION_CREATED",
      "ROTATION_UPDATED",
    ],
    _id: null,
    name: "NOTIFICATIONS_SETTINGS",
    valuesApi: [],
    values: [
      // CASE LOGS
      {
        name: "New one",
        description: "New case log submitted",
        rolesAvailableToSend: [
          "SuperCompanyAdministrators",
          "CompanyAdministrators",
          "InstitutionAdministrators",
          "Preceptors",
        ],
        receivers: [],
        channels: ["App"],
        type: "CASELOGS",
        _id: "CASE_LOG_SUBMITED",
      },
      {
        name: "Rejected",
        description: "Case log has been rejected",
        rolesAvailableToSend: [
          "SuperCompanyAdministrators",
          "CompanyAdministrators",
          "InstitutionAdministrators",
          "Students",
        ],
        receivers: [],
        channels: ["App"],
        type: "CASELOGS",
        _id: "CASE_LOG_DENIED",
      },
      {
        name: "Approved",
        description: "Case log has been approved",
        rolesAvailableToSend: [
          "SuperCompanyAdministrators",
          "CompanyAdministrators",
          "InstitutionAdministrators",
          "Students",
        ],
        receivers: [],
        channels: ["App"],
        type: "CASELOGS",
        _id: "CASE_LOG_APPROVED",
      },
      // ROTATIONS
      {
        name: "New one",
        description: "New rotation created",
        rolesAvailableToSend: [
          "SuperCompanyAdministrators",
          "CompanyAdministrators",
          "InstitutionAdministrators",
          "Students",
        ],
        receivers: [],
        channels: ["App"],
        type: "ROTATIONS",
        _id: "ROTATION_CREATED",
      },
      {
        name: "Edit",
        description: "Rotation has been edited",
        rolesAvailableToSend: [
          "SuperCompanyAdministrators",
          "CompanyAdministrators",
          "InstitutionAdministrators",
          "Students",
        ],
        receivers: [],
        channels: ["App"],
        type: "ROTATIONS",
        _id: "ROTATION_UPDATED",
      },
    ],
    text: "Notifications",
  },
};
const initialState: State = {
  isFetchingTenantSettings: false,
  lastFetchingTenantSettings: null,
  tenantSettings: initialStateTenantSettings,
  tenantSettingsName: [
    "SHOW_DEACTIVATED_USERS",
    "RETROACTIVE_DATE",
    "ACCOUNTS_COMPANY_REQUIRED_FIELDS",
    "ACCOUNTS_INSTITUTION_REQUIRED_FIELDS",
    "ACCOUNTS_PROFILE_REQUIRED_FIELDS",
    ...tenantSettingsNameCaseLogs,
    "EVALUATIONS_NOTIFICATIONS_EMAIL",
    "ROTATIONS_OVERLAPPING_VALIDATION",
    "ALLOW_AUTOMATIC_ROTATIONS_SCHEDULING_PARAMETERS",
    "AUTOMATIC_ROTATIONS_SCHEDULING_PARAMETERS",
    "INSTITUTIONS_TYPES_LIST",
    "BROADCAST_MESSAGES",
    "NOTIFICATIONS_SETTINGS",
  ],
};
export default function tenantSettings(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    //TenantSettings
    case TenantSettings.TENANT_SETTINGS_FETCH_ALL:
      return Object.assign({}, state, {
        isFetchingTenantSettings: true,
      });
    case TenantSettings.TENANT_SETTINGS_FETCH_ALL_FAILURE:
      return Object.assign({}, state, {
        isFetchingTenantSettings: false,
      });
    case TenantSettings.TENANT_SETTINGS_FETCH_ALL_SUCCESS:
      let newState: State["tenantSettings"] = {
        ...state.tenantSettings,
      };
      for (let index in action.payload.tenantSettings) {
        const name = action.payload.tenantSettings[index]["name"] as
          | TenantSettingName
          | "MODELS_FIELDS";
        if (name === "MODELS_FIELDS") {
          const apiCaseLogsFields =
            action.payload.tenantSettings[index]["value"]?.CaseLogs || {};
          Object.keys(caseLogsRequiredFields).forEach((tenantSettingsName) => {
            newState[
              tenantSettingsName as TenantSettingsNameCaseLogsRequiredFields
            ] = {
              ...newState[
                tenantSettingsName as TenantSettingsNameCaseLogsRequiredFields
              ],
              _id: action.payload.tenantSettings[index]["_id"],
              valuesApi:
                apiCaseLogsFields[
                  caseLogsRequiredFields[
                    tenantSettingsName as keyof typeof caseLogsRequiredFields
                  ]
                ] || false,
              values:
                apiCaseLogsFields[
                  caseLogsRequiredFields[
                    tenantSettingsName as keyof typeof caseLogsRequiredFields
                  ]
                ]?.required || false,
            };
          });
          Object.keys(caseLogsEnabledFields).forEach((tenantSettingsName) => {
            newState[
              tenantSettingsName as TenantSettingsNameCaseLogsEnabledFields
            ] = {
              ...newState[
                tenantSettingsName as TenantSettingsNameCaseLogsEnabledFields
              ],
              _id: action.payload.tenantSettings[index]["_id"],
              valuesApi:
                apiCaseLogsFields[
                  caseLogsEnabledFields[
                    tenantSettingsName as keyof typeof caseLogsEnabledFields
                  ]
                ] || false,
              values:
                apiCaseLogsFields[
                  caseLogsEnabledFields[
                    tenantSettingsName as keyof typeof caseLogsEnabledFields
                  ]
                ]?.enabled || false,
            };
          });
          continue;
        }
        let values = [];
        switch (name) {
          case "CASELOGS_LANGUAGE_LIST":
            values = action.payload.tenantSettings[index]["value"].map(
              ({ language }: any) => language
            );
            break;
          case "CASELOGS_GENDER_LIST":
            values = action.payload.tenantSettings[index]["value"].map(
              ({ gender }: any) => gender
            );
            break;
          case "CASELOGS_INSURANCE_LIST":
            values = action.payload.tenantSettings[index]["value"].map(
              ({ insurance }: any) => insurance
            );
            break;
          case "CASELOGS_RACES_LIST":
            values = action.payload.tenantSettings[index]["value"].map(
              ({ race }: any) => race
            );
            break;
          case "CASELOGS_REASONS_FOR_VISIT_LIST":
            values = action.payload.tenantSettings[index]["value"].map(
              ({ reasonForVisit }: any) => reasonForVisit
            );
            break;
          case "CASELOGS_DISCIPLINES_LIST_FOR_DIAGNOSTICS":
            values = action.payload.tenantSettings[index]["value"].map(
              ({ diagnosticDiscipline }: any) => diagnosticDiscipline
            );
            break;
          case "CASELOGS_DIAGNOSTICS_LIST":
            values = action.payload.tenantSettings[index]["value"]
              .map(
                ({ diagnostic, diagnosticDisciplineId }: any) =>
                  `${diagnosticDisciplineId}: ${diagnostic}`
              )
              .filter(
                (elem: any, index: number, array: any[]) =>
                  array.indexOf(elem) === index
              );
            break;
          case "CASELOGS_DISCIPLINES_LIST_FOR_PROCEDURES":
            values = action.payload.tenantSettings[index]["value"].map(
              ({ procedureDiscipline }: any) => procedureDiscipline
            );
            break;
          case "CASELOGS_PROCEDURES_LIST":
            action.payload.tenantSettings[index]["value"].sort(
              (a: any, b: any) => {
                if (a.procedure < b.procedure) {
                  return -1;
                }
                if (a.procedure > b.procedure) {
                  return 1;
                }
                return 0;
              }
            );
            values = action.payload.tenantSettings[index]["value"]
              .map(
                ({ procedure, procedureDisciplineId }: any) =>
                  `${procedureDisciplineId}: ${procedure}`
              )
              .filter(
                (elem: any, index: number, array: any[]) =>
                  array.indexOf(elem) === index
              );
            break;
          case "CASELOGS_PROCEDURES_STATUS_LIST":
            values = action.payload.tenantSettings[index]["value"].map(
              ({ procedureStatus }: any) => procedureStatus
            );
            break;
          case "INSTITUTIONS_TYPES_LIST":
            values = action.payload.tenantSettings[index]["value"].map(
              ({ type }: any) => type
            );
            break;
          case "NOTIFICATIONS_SETTINGS":
            values = action.payload.tenantSettings[index]["value"]
              .map(
                ({
                  _id: notificationSettingsId,
                  receivers,
                  channels = ["App"],
                }: any) => {
                  const [notificationSetting] = state.tenantSettings[
                    "NOTIFICATIONS_SETTINGS"
                  ].values.filter(
                    ({ _id }: any) => _id === notificationSettingsId
                  );
                  if (notificationSetting) {
                    return {
                      ...notificationSetting,
                      receivers,
                      channels,
                    };
                  }
                  return null;
                }
              )
              .filter((v: any) => v);
            break;
          default:
            values = action.payload.tenantSettings[index]["value"];
        }
        newState[name as TenantSettingName] = {
          ...newState[name as TenantSettingName],
          _id: action.payload.tenantSettings[index]["_id"],
          valuesApi: action.payload.tenantSettings[index]["value"],
          values,
        };
      }
      return Object.assign({}, state, {
        isFetchingTenantSettings: false,
        lastFetchingTenantSettings: new Date().getTime(),
        tenantSettings: newState,
      });
    case TenantSettings.TENANT_SETTINGS_UPDATE_TENANT_SETTING_NAME:
      action.payload.callbackEnd();
      return Object.assign({}, state, {
        tenantSettings: action.payload.tenantSettings,
        lastFetchingTenantSettings: new Date().getTime(),
      });
    // same state (not change)
    default:
      return state;
  }
}
