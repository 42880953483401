import type { Config, tAPI } from "./API";
import type { StateUsersManager } from "../../types/users.types";
// API CaseLogs static class
const apiBaseCaseLogs = "/case-logs";
export default class CaseLogs {
  // Get a list of case logs depend on case:
  // student: UserID, preceptor: UserID, institution: InstitutionID)
  static GetCaseLogs(
    api: tAPI,
    populated: boolean,
    skip: number,
    limit: number,
    status: string[],
    userStatus: StateUsersManager["filters"]["status"],
    students: string[],
    programs: string[],
    cohorts: string[],
    preceptors: string[],
    institutions: string[],
    procedures: string[],
    search: string,
    startDate: string,
    endDate: string,
    appointmentDateFrom: string,
    appointmentDateTo: string,
    sortBy: {
      student: "ascending" | "descending" | "";
      preceptor: "ascending" | "descending" | "";
      institution: "ascending" | "descending" | "";
      createdAt: "ascending" | "descending" | "";
      status: "ascending" | "descending" | "";
    },
    config?: Config
  ) {
    let queryParams = "";
    if (skip >= 0) {
      queryParams += `&skip=${skip}`;
    }
    if (limit > 0) {
      queryParams += `&limit=${limit}`;
    }
    if (status.length > 0) {
      queryParams += `&status=${status.join()}`;
    }
    if (userStatus.length > 0) {
      queryParams += `&userStatus=${userStatus.join()}`;
    }
    if (students.length > 0) {
      queryParams += `&students=${students.join()}`;
    }
    if (programs.length > 0) {
      queryParams += `&programs=${programs.join()}`;
    }
    if (cohorts.length > 0) {
      queryParams += `&cohorts=${cohorts.join()}`;
    }
    if (preceptors.length > 0) {
      queryParams += `&preceptors=${preceptors.join()}`;
    }
    if (institutions.length > 0) {
      queryParams += `&institutions=${institutions.join()}`;
    }
    if (procedures.length > 0) {
      queryParams += `&procedures=${procedures.join()}`;
    }
    if (search) {
      queryParams += `&search=${search}`;
    }
    if (startDate) {
      queryParams += `&from=${startDate}`;
    }
    if (endDate) {
      queryParams += `&to=${endDate}`;
    }
    if (appointmentDateFrom) {
      queryParams += `&appointmentDateFrom=${appointmentDateFrom}`;
    }
    if (appointmentDateTo) {
      queryParams += `&appointmentDateTo=${appointmentDateTo}`;
    }
    if (populated) {
      let sortByParams = Object.keys(sortBy)
        // @ts-ignore
        .filter((sortKey) => sortBy[sortKey])
        .map(
          (sortName) =>
            // @ts-ignore
            `sort_${sortName}=${sortBy[sortName] === "ascending" ? 1 : -1}`
        )
        .join("&");
      if (sortByParams) {
        sortByParams = "&" + sortByParams;
      }
      queryParams = "?populated=true" + queryParams + sortByParams;
    } else if (queryParams.length > 0) {
      queryParams = "?" + queryParams.slice(1);
    }
    return api.GET(`${apiBaseCaseLogs}${queryParams}`, config);
  }
  // Get Case Logs KPIS
  static GetCaseLogsKpis(api: tAPI, config?: Config) {
    return api.GET(`${apiBaseCaseLogs}/kpis`, config);
  }
  // Create a Case Log
  static CreateCaseLog(api: tAPI, data: object, config?: Config) {
    return api.UPLOAD(`${apiBaseCaseLogs}`, data, "POST", config);
    // return api.POST(`${apiBaseCaseLogs}`, data, config);
  }
  // Get a Case Log
  static GetCaseLog(api: tAPI, caseLogId: string, config?: Config) {
    return api.GET(`${apiBaseCaseLogs}/${caseLogId}?populated=true`, config);
  }
  // Edit a Case Log
  static EditCaseLog(
    api: tAPI,
    caseLogId: string,
    data: object,
    config?: Config
  ) {
    return api.PATCH(`${apiBaseCaseLogs}/${caseLogId}`, data, config);
  }
  // Delete a Case Log
  static DeleteCaseLog(api: tAPI, caseLogId: string, config?: Config) {
    return api.DELETE(`${apiBaseCaseLogs}/${caseLogId}`, config);
  }
  // Review a Case Log
  static ReviewCaseLog(
    api: tAPI,
    caseLogId: string,
    data: object,
    config?: Config
  ) {
    return api.PATCH(`${apiBaseCaseLogs}/${caseLogId}/review`, data, config);
  }
}
