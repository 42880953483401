// Types
import { State as StateReducers } from "../../../store/reducers/evaluationsTemplates";
// Utils
export const DROPDOWN_FROM_LIST_OPTIONS = [
  {
    key: "option-1",
    text: "John Doe",
    value: "option-1",
  },
  {
    key: "option-2",
    text: "Jane Doe",
    value: "option-2",
  },
  {
    key: "option-3",
    text: "Mary Johnson",
    value: "option-3",
  },
  { key: "option-4", text: "Other", value: "other" },
];
export const onSetFormOptions = (formOptions: StateReducers["formOptions"]) =>
  formOptions.map((option) => {
    const { type } = option;
    switch (type) {
      case "multipleChoice":
      case "singleChoice":
        const { id: formOptionId } = option;
        const [id, headerDescription, description = ""] =
            formOptionId.split("::"),
          allowDescription = headerDescription === "allowDescription";
        return {
          ...option,
          id,
          allowDescription,
          description,
        };
      default:
        return option;
    }
  });
export const onSetFormOptionsApiPayload = (
  formOptions: StateReducers["formOptions"]
) =>
  formOptions.map((option) => {
    const { type } = option;
    switch (type) {
      case "multipleChoice":
      case "singleChoice":
        const { allowDescription, description, ...optionPayload } = option;
        let id = optionPayload.id;
        if (allowDescription && description) {
          id = id + "::allowDescription::" + description;
        }
        return {
          ...optionPayload,
          id,
        };
      default:
        return option;
    }
  });
export const onSetFormOptionsTags = (
  templateTag: string,
  formOptions: StateReducers["formOptions"]
) =>
  formOptions.map((option) => {
    const { type } = option;
    switch (type) {
      case "question":
      case "range":
        return {
          ...option,
          tags: [templateTag, `option_${type}`, option.id.replace(/-/g, "_")],
        };
      case "multipleChoice":
      case "singleChoice":
        return {
          ...option,
          options: option.options.map((o, index) => {
            if (type === "singleChoice" && option.style === "normal") {
              // SingleChoice && Style: normal
              if (index === 0) {
                return {
                  ...o,
                  tags: [
                    templateTag,
                    `option_${type}`,
                    `option_${type}_positive`,
                    option.id.replace(/-/g, "_"),
                    `${option.id.replace(/-/g, "_")}_positive`,
                    o.id.replace(/-/g, "_"),
                    `${o.id.replace(/-/g, "_")}_positive`,
                  ],
                };
              } else if (index + 1 === option.options.length) {
                return {
                  ...o,
                  tags: [
                    templateTag,
                    `option_${type}`,
                    `option_${type}_negative`,
                    option.id.replace(/-/g, "_"),
                    `${option.id.replace(/-/g, "_")}_negative`,
                    o.id.replace(/-/g, "_"),
                    `${o.id.replace(/-/g, "_")}_negative`,
                  ],
                };
              }
            }
            return {
              ...o,
              tags: [
                templateTag,
                `option_${type}`,
                option.id.replace(/-/g, "_"),
                o.id.replace(/-/g, "_"),
              ],
            };
          }),
        };
      default:
        return option;
    }
  });
