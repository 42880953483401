// Services to manage all socket methods into app
import io from "socket.io-client";
import { ImportData, Notifications, Reports } from "../../store/actions/";
// Types
import type { StateImportData as State } from "../../types/importData.types";
// Private properties (problem with memory leak, we'll finder another solution)
// variables:
let _: Object = new WeakMap();

const bindActions = (dispatch) => {
  return {
    registerSocket: (uri) =>
      dispatch(
        Notifications.notificationsRegisterSocket({
          uri,
          dispatch,
        })
      ),
    setConnectionIdSuccess: (payload) =>
      dispatch(
        Notifications.notificationsSetSocketConnectionIdSuccess(payload)
      ),
    setConnectionIdFailure: (payload) =>
      dispatch(Notifications.notificationsSetSocketConnectionIdFailure()),
    addNotification: (payload) =>
      dispatch(Notifications.notificationsSocketAddNotification(payload)),
    disconnectSocket: (payload) =>
      dispatch(Notifications.notificationsDisconnectSocket(payload)),
    // IMPORT DATA (USERS | ROTATIONS)
    showImportDataNotification: (
      typeImport: State["importDataNotification"]["typeImport"],
      hasErrors: State["importDataNotification"]["hasErrors"] = false
    ) =>
      dispatch(
        ImportData.importDataShowNotification({
          toShow: true,
          hasErrors,
          typeImport,
        })
      ),
    //REPORTS
    getReports: (payload) => dispatch(Reports.reportsFetchReports(payload)),
    showReportWarning: () =>
      dispatch(
        Reports.reportsQueriesShowReportWarning({
          hasToShowReportsWarning: true,
        })
      ),
  };
};

const _listeners = (
  socketService: Object,
  userId: string,
  socketConnectionId: string
) => {
  const { socket, actions, uri } = _.get(socketService);
  const {
    registerSocket,
    setConnectionIdSuccess,
    setConnectionIdFailure,
    addNotification,
  } = actions;
  //Connect
  socket.on("connect", () => {
    socket.emit("register", userId, socketConnectionId);
    _.set(socketService, { socket, actions, uri, connectedSocket: true });
    window.logger.log("SocketService - Connected", new Date().toLocaleString());
  });
  //My notifications
  socket.on("message", (notification: Object | string) => {
    if (typeof notification === "object") {
      const { type = "" } = notification || {};
      if (type === "success") {
        setConnectionIdSuccess({ socket: socketService });
      } else if (type === "error") {
        setConnectionIdFailure();
        socketService.disconnect();
      } else {
        addNotification({ notification });
        socketService.dispatchAction(notification);
      }
    }
    window.logger.log("SocketService - Notification: ", notification);
  });
  //Reconnect
  socket.on("reconnect_attempt", (attemptNumber: number) => {
    window.logger.log(
      "SocketService - reconnect_attempt: ",
      attemptNumber,
      new Date().toLocaleString()
    );
  });
  socket.on("reconnect", (attemptNumber: number) => {
    registerSocket(uri);
    window.logger.log(
      "SocketService - reconnect: ",
      attemptNumber,
      new Date().toLocaleString()
    );
  });
  //Errors
  socket.on("connect_error", (error) => {
    setConnectionIdFailure();
    window.logger.log(
      "SocketService - connect_error",
      error,
      new Date().toLocaleString()
    );
  });
  socket.on("error", (error) =>
    window.logger.log(
      "SocketService - error",
      error,
      new Date().toLocaleString()
    )
  );
  socket.on("disconnect", (socketClosed) =>
    window.logger.log(
      "SocketService - disconnect",
      socketClosed,
      new Date().toLocaleString()
    )
  );
};

export class SocketService {
  initSocket: boolean;
  constructor(uri: string, dispatch: (payload: any) => void) {
    _.set(this, {
      socket: null,
      actions: bindActions(dispatch),
      uri,
      connectedSocket: false,
    });
    this.initSocket = true;
    window.logger.log("SocketService Ready!", new Date().toLocaleString());
  }

  connect(userId: string, socketConnectionId: string) {
    const { actions, uri } = _.get(this);
    _.set(this, {
      // Socket Io put their headers owner.
      // socket: io.connect(uri, {
      //   reconnect: true,
      //   transports: ['websocket', 'polling']
      // }),
      socket: io.connect(uri, {
        reconnectionAttempts: 5,
        transports: ["websocket"],
      }),
      actions,
      uri,
    });
    _listeners(this, userId, socketConnectionId);
  }

  dispatchAction(notification: any) {
    window.logger.log(
      "SocketService - Action to dispatch:",
      notification,
      new Date().toLocaleString()
    );
    const { actions } = _.get(this);
    const { getReports, showReportWarning, showImportDataNotification } =
      actions;
    const { action } = notification;
    switch (action) {
      case "REPORT_CREATED":
        const callbacks = {
          callbackError: (error) => {
            window.logger.log(
              "error - _onGettingReports: onSocketService",
              error
            );
          },
          callbackSuccess: () => {
            window.logger.log("success - _onGettingReports: onSocketService");
          },
        };
        const payload = Object.assign({}, callbacks);
        getReports(payload);
        showReportWarning();
        break;
      case "IMPORT_CREATED":
        showImportDataNotification(notification.metadata.import.model);
        break;
      case "IMPORT_ERROR":
        showImportDataNotification(notification.metadata.import.model, true);
        break;
      default:
    }
  }

  disconnect() {
    const { socket, actions, uri } = _.get(this);
    if (socket) {
      const { disconnectSocket } = actions;
      socket.disconnect();
      _.set(this, { socket: null, actions, uri, connectedSocket: false });
      disconnectSocket({ socket: this });
    }
  }
}
