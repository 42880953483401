import type { PayloadObject } from "../../types/common.types";
/* PERMISSIONS */
const onCheckResource = (apiResource: PayloadObject, resources: string[]) => {
  for (let resource in apiResource) {
    if (
      resources.indexOf(resource) !== -1 &&
      apiResource[resource]["isEnabled"]
    ) {
      return true;
    }
  }
  return false;
};

export const onCheckAllCaseLogsPermissions = (
  permissions?: PayloadObject
): boolean => {
  const resources = [
    "CaseLogs:create",
    "CaseLogs:read",
    "CaseLogs:update",
    "CaseLogs:delete",
    "CaseLogs:review",
    "my:Students#CaseLogs:read",
    "my#CaseLogs:create",
    "my#CaseLogs:read",
    "my#CaseLogs:update",
    "my#CaseLogs:review",
  ];
  const { CaseLogs } = permissions || {};
  if (CaseLogs && CaseLogs.apiValue) {
    return onCheckResource(CaseLogs.apiValue, resources);
  }
  return false;
};

export const onCheckCaseLogsPermissions = (
  permissions?: PayloadObject
): boolean => {
  const resources = [
    "CaseLogs:create",
    "CaseLogs:read",
    "my:Students#CaseLogs:read",
    "my#CaseLogs:create",
    "my#CaseLogs:read",
  ];
  const { CaseLogs } = permissions || {};
  if (CaseLogs && CaseLogs.apiValue) {
    return onCheckResource(CaseLogs.apiValue, resources);
  }
  return false;
};

export const onCheckReadCaseLogsPermissions = (
  permissions?: PayloadObject
): boolean => {
  const resources = [
    "CaseLogs:read",
    "my:Students#CaseLogs:read",
    "my#CaseLogs:read",
  ];
  const { CaseLogs } = permissions || {};
  if (CaseLogs && CaseLogs.apiValue) {
    return onCheckResource(CaseLogs.apiValue, resources);
  }
  return false;
};

export const onCheckReadAllCaseLogsPermissions = (
  permissions?: PayloadObject
): boolean => {
  const resources = ["CaseLogs:read"];
  const { CaseLogs } = permissions || {};
  if (CaseLogs && CaseLogs.apiValue) {
    return onCheckResource(CaseLogs.apiValue, resources);
  }
  return false;
};

export const onCheckCreateCaseLogsPermissions = (
  permissions?: PayloadObject
): boolean => {
  const resources = ["CaseLogs:create"];
  const { CaseLogs } = permissions || {};
  if (CaseLogs && CaseLogs.apiValue) {
    return onCheckResource(CaseLogs.apiValue, resources);
  }
  return false;
};

export const onCheckSubmitCaseLogsPermissions = (
  permissions?: PayloadObject
): boolean => {
  const resources = ["my#CaseLogs:create"];
  const { CaseLogs } = permissions || {};
  if (CaseLogs && CaseLogs.apiValue) {
    return onCheckResource(CaseLogs.apiValue, resources);
  }
  return false;
};

export const onCheckEditCaseLogsPermissions = (
  permissions?: PayloadObject
): boolean => {
  const resources = ["CaseLogs:update", "my#CaseLogs:update"];
  const { CaseLogs } = permissions || {};
  if (CaseLogs && CaseLogs.apiValue) {
    return onCheckResource(CaseLogs.apiValue, resources);
  }
  return false;
};

export const onCheckDeleteCaseLogsPermissions = (
  permissions?: PayloadObject
): boolean => {
  const resources = ["CaseLogs:delete"];
  const { CaseLogs } = permissions || {};
  if (CaseLogs && CaseLogs.apiValue) {
    return onCheckResource(CaseLogs.apiValue, resources);
  }
  return false;
};

export const onCheckReviewCaseLogsPermissions = (
  permissions?: PayloadObject
): boolean => {
  const resources = ["CaseLogs:review", "my#CaseLogs:review"];
  const { CaseLogs } = permissions || {};
  if (CaseLogs && CaseLogs.apiValue) {
    return onCheckResource(CaseLogs.apiValue, resources);
  }
  return false;
};
