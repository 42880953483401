// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  onCheckAllUsersFilesPermissions,
  onCheckCreateAllUsersFilesPermissions,
} from "../files/FilesPermissions";
import {
  onCheckUsersManagerPermissions,
  onCheckUserInvitationsManagerPermissions,
} from "./ManagerPermissions";
import { onCheckImportDataPermissions } from "../import/ImportDataPermissions";
import { store } from "../../index";
import { Users, RouterManager } from "../../store/actions/";
import ProtectedRoute from "../shared/ProtectedRoute";

const mapDispatchToProps = (dispatch: Function, ownProps: Object) => {
  const {
    computedMatch: {
      params: { userId },
    },
    location: { state },
  } = ownProps;
  const { userId: storedUserId } = store.getState().users;
  if (userId && userId !== storedUserId) {
    dispatch(Users.usersSetUserId({ userId }));
    if (state && state.user) {
      dispatch(
        Users.usersSetUser({
          user: state.user,
        })
      );
    }
  }
  return { dispatch };
};

const ManagerRoute: React.ComponentType<any> = withRouter(
  connect(
    (
      { config: { companyDomain }, auth: { isAuthenticated, role } },
      {
        path,
        computedMatch: {
          params: { userId },
        },
      }
    ) => {
      let isAllowedRoute =
        isAuthenticated &&
        (onCheckUsersManagerPermissions(role.permissions) ||
          onCheckUserInvitationsManagerPermissions(role.permissions) ||
          onCheckImportDataPermissions(role.permissions));
      let redirectTo = "/manager";
      if (!isAllowedRoute) {
        redirectTo = "/home";
      }
      if (
        path &&
        (path === "/manager" ||
          (path.indexOf("/manager/profile/") !== -1 &&
            userId &&
            path.indexOf("/portfolio") === -1 &&
            path.indexOf("/documents") === -1)) &&
        isAuthenticated &&
        isAllowedRoute &&
        !onCheckUsersManagerPermissions(role.permissions)
      ) {
        isAllowedRoute = false;
      } else if (
        path &&
        path.indexOf("/manager/profile/") !== -1 &&
        userId &&
        path.indexOf("/portfolio") !== -1 &&
        !isAllowedRoute
      ) {
        isAllowedRoute = false;
      } else if (
        path &&
        path.indexOf("/manager/profile/") !== -1 &&
        userId &&
        path.indexOf("/documents") !== -1 &&
        path.indexOf("/documents/upload") === -1 &&
        isAuthenticated &&
        isAllowedRoute &&
        !onCheckAllUsersFilesPermissions(role.permissions)
      ) {
        isAllowedRoute = false;
      } else if (
        path &&
        path.indexOf("/manager/profile/") !== -1 &&
        userId &&
        path.indexOf("/documents") !== -1 &&
        path.indexOf("/documents/upload") !== -1 &&
        isAuthenticated &&
        isAllowedRoute &&
        !onCheckCreateAllUsersFilesPermissions(role.permissions)
      ) {
        isAllowedRoute = false;
      } else if (
        path &&
        (path === "/manager/invitations" ||
          path === "/manager/invitations/create") &&
        isAuthenticated &&
        isAllowedRoute &&
        !onCheckUserInvitationsManagerPermissions(role.permissions)
      ) {
        isAllowedRoute = false;
      } else if (
        path &&
        (path === "/manager/import-users" ||
          path === "/manager/import-users/generate-csv" ||
          path === "/manager/import-users/status") &&
        isAuthenticated &&
        isAllowedRoute &&
        !onCheckImportDataPermissions(role.permissions)
      ) {
        isAllowedRoute = false;
      }
      if (!isAllowedRoute) {
        store.dispatch(RouterManager.routerManagerReset());
      }
      return {
        component: null,
        restProps: null,
        isAllowedRoute:
          companyDomain.length > 0 && isAuthenticated && isAllowedRoute,
        redirectTo,
      };
    },
    mapDispatchToProps
  )(ProtectedRoute)
);

export default ManagerRoute;
