import type { Config, tAPI } from "./API";
// API Reports and Queries static class

const apiBaseReports = "/queries";
export default class Reports {
  // API QUERIES
  // Get a list of queries to use getting reports
  static GetReportsQueries(api: tAPI, config?: Config) {
    return api.GET(`${apiBaseReports}?populated=true`, config);
  }
  // Create a query to use on Reports
  static CreateReportsQuery(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBaseReports}`, data, config);
  }
  // Get a query
  static GetReportsQuery(api: tAPI, queryId: string, config?: Config) {
    return api.GET(`${apiBaseReports}/${queryId}`, config);
  }
  // Edit a query to use on Reports
  static EditReportsQuery(
    api: tAPI,
    queryId: string,
    data: object,
    config?: Config
  ) {
    return api.PATCH(`${apiBaseReports}/${queryId}`, data, config);
  }
  // Delete a query
  static DeleteReportsQuery(api: tAPI, queryId: string, config?: Config) {
    return api.DELETE(`${apiBaseReports}/${queryId}`, config);
  }
  // Run a query and save report result to get historics on Reports
  static RunReportsQuery(
    api: tAPI,
    queryId: string,
    data: object,
    config?: Config
  ) {
    return api.POST(`${apiBaseReports}/${queryId}/run`, data, config);
  }

  // API REPORTS
  // API to get all Reports
  static GetReports(api: tAPI, config?: Config) {
    return api.GET(`${apiBaseReports}/reports?populated=true`, config);
  }

  // API REPORTS QUERIES
  // API to get Reports depend on query set up before
  static GetReportsQueryResults(api: tAPI, queryId: string, config?: Config) {
    let queryParams = "?populated=true";
    return api.GET(
      `${apiBaseReports}/${queryId}/reports${queryParams}`,
      config
    );
  }
  // Get a saved report result
  static GetReportsQueryResult(
    api: tAPI,
    queryId: string,
    reportId: string,
    config?: Config
  ) {
    return api.GET(
      `${apiBaseReports}/${queryId}/reports/${reportId}?populated=true`,
      config
    );
  }
  // Update a saved report result
  static EditReportsQueryResult(
    api: tAPI,
    queryId: string,
    reportId: string,
    updateResults: boolean = false,
    data: object,
    config?: Config
  ) {
    let queryParams = "";
    if (updateResults) {
      queryParams = "?updateResults=true";
    }
    return api.PATCH(
      `${apiBaseReports}/${queryId}/reports/${reportId}${queryParams}`,
      data,
      config
    );
  }
  // Delete a report
  static DeleteReportsQueryReport(
    api: tAPI,
    queryId: string,
    reportId: string,
    config?: Config
  ) {
    return api.DELETE(
      `${apiBaseReports}/${queryId}/reports/${reportId}`,
      config
    );
  }
  // Transform a saved report result (Counting Feature)
  static TransformReportsQueryResult(
    api: tAPI,
    queryId: string,
    reportId: string,
    data: object,
    config?: Config
  ) {
    return api.POST(
      `${apiBaseReports}/${queryId}/reports/${reportId}/transformReport`,
      data,
      config
    );
  }
  // Generate CSV from Report
  static GenerateCSVReport(
    api: tAPI,
    queryId: string,
    reportId: string,
    config?: Config
  ) {
    return api.DOWNLOAD(
      `${apiBaseReports}/${queryId}/reports/${reportId}/generateCSV`,
      {},
      "text/csv",
      config
    );
  }
  // Generate CSV Transform a saved report result (Counting Feature)
  static GenerateCSVTransformReportsQueryResult(
    api: tAPI,
    queryId: string,
    reportId: string,
    config?: Config
  ) {
    return api.DOWNLOAD(
      `${apiBaseReports}/${queryId}/reports/${reportId}/transformReport/generateCSV`,
      {},
      "text/csv",
      config
    );
  }
}
