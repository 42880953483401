import { createAction } from "deox";

// Actions for Case Logs
// Fetch all case logs
export const CASELOGS_FETCH_ALL: string = "CASELOGS/FETCH/ALL";
export const CASELOGS_FETCH_ALL_REQUEST: string = "CASELOGS/FETCH/ALL/REQUEST";
export const CASELOGS_FETCH_ALL_SUCCESS: string = "CASELOGS/FETCH/ALL/SUCCESS";
export const CASELOGS_FETCH_ALL_FAILURE: string = "CASELOGS/FETCH/ALL/FAILURE";
export const caseLogsFetchAll = createAction(
  CASELOGS_FETCH_ALL,
  (resolve) => (payload?: object) => resolve(payload)
);
export const caseLogsFetchAllRequest = createAction(CASELOGS_FETCH_ALL_REQUEST);
export const caseLogsFetchAllSuccess = createAction(
  CASELOGS_FETCH_ALL_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const caseLogsFetchAllFailure = createAction(CASELOGS_FETCH_ALL_FAILURE);
// Fetch Case Logs KPIS
export const CASELOGS_FETCH_KPIS: string = "CASELOGS/FETCH/KPIS";
export const CASELOGS_FETCH_KPIS_REQUEST: string =
  "CASELOGS/FETCH/KPIS/REQUEST";
export const CASELOGS_FETCH_KPIS_SUCCESS: string =
  "CASELOGS/FETCH/KPIS/SUCCESS";
export const CASELOGS_FETCH_KPIS_FAILURE: string =
  "CASELOGS/FETCH/KPIS/FAILURE";
export const caseLogsFetchKpis = createAction(
  CASELOGS_FETCH_KPIS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const caseLogsFetchKpisRequest = createAction(
  CASELOGS_FETCH_KPIS_REQUEST
);
export const caseLogsFetchKpisSuccess = createAction(
  CASELOGS_FETCH_KPIS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const caseLogsFetchKpisFailure = createAction(
  CASELOGS_FETCH_KPIS_FAILURE,
  (resolve) => (payload?: object) => resolve(payload)
);
// Create a Case log
export const CASELOGS_CREATE: string = "CASELOGS/CREATE";
export const CASELOGS_CREATE_REQUEST: string = "CASELOGS/CREATE/REQUEST";
export const CASELOGS_CREATE_SUCCESS: string = "CASELOGS/CREATE/SUCCESS";
export const CASELOGS_CREATE_FAILURE: string = "CASELOGS/CREATE/FAILURE";
export const caseLogsCreate = createAction(
  CASELOGS_CREATE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const caseLogsCreateRequest = createAction(CASELOGS_CREATE_REQUEST);
export const caseLogsCreateSuccess = createAction(
  CASELOGS_CREATE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const caseLogsCreateFailure = createAction(CASELOGS_CREATE_FAILURE);
// Create a Dummy Case log
export const CASELOGS_CREATE_DUMMY: string = "CASELOGS/CREATE_DUMMY";
export const CASELOGS_CREATE_DUMMY_REQUEST: string =
  "CASELOGS/CREATE_DUMMY/REQUEST";
export const CASELOGS_CREATE_DUMMY_SUCCESS: string =
  "CASELOGS/CREATE_DUMMY/SUCCESS";
export const CASELOGS_CREATE_DUMMY_FAILURE: string =
  "CASELOGS/CREATE_DUMMY/FAILURE";
export const caseLogsCreateDummy = createAction(
  CASELOGS_CREATE_DUMMY,
  (resolve) => (payload?: object) => resolve(payload)
);
export const caseLogsCreateDummyRequest = createAction(
  CASELOGS_CREATE_DUMMY_REQUEST
);
export const caseLogsCreateDummySuccess = createAction(
  CASELOGS_CREATE_DUMMY_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const caseLogsCreateDummyFailure = createAction(
  CASELOGS_CREATE_DUMMY_FAILURE
);
// Set caseLogId to Fetch this case log
export const CASELOGS_SET_CASELOGID: string = "CASELOGS/SET/CASELOGID";
export const caseLogsSetcaseLogId = createAction(
  CASELOGS_SET_CASELOGID,
  (resolve) => (payload?: object) => resolve(payload)
);
// Unset caseLogId
export const CASELOGS_UNSET_CASELOGID: string = "CASELOGS/UNSET/CASELOGID";
export const caseLogsUnsetcaseLogId = createAction(
  CASELOGS_UNSET_CASELOGID,
  (resolve) => (payload?: object) => resolve(payload)
);
// Fetch a Case log
export const CASELOGS_FETCH_CASELOG: string = "CASELOGS/FETCH/CASELOG";
export const CASELOGS_FETCH_CASELOG_REQUEST: string =
  "CASELOGS/FETCH/CASELOG/REQUEST";
export const CASELOGS_FETCH_CASELOG_SUCCESS: string =
  "CASELOGS/FETCH/CASELOG/SUCCESS";
export const CASELOGS_FETCH_CASELOG_FAILURE: string =
  "CASELOGS/FETCH/CASELOG/FAILURE";
export const caseLogsFetchCaseLog = createAction(
  CASELOGS_FETCH_CASELOG,
  (resolve) => (payload?: object) => resolve(payload)
);
export const caseLogsFetchCaseLogRequest = createAction(
  CASELOGS_FETCH_CASELOG_REQUEST
);
export const caseLogsFetchCaseLogSuccess = createAction(
  CASELOGS_FETCH_CASELOG_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const caseLogsFetchCaseLogFailure = createAction(
  CASELOGS_FETCH_CASELOG_FAILURE
);
// Edit Case log
export const CASELOGS_EDIT: string = "CASELOGS/EDIT";
export const CASELOGS_EDIT_REQUEST: string = "CASELOGS/EDIT/REQUEST";
export const CASELOGS_EDIT_SUCCESS: string = "CASELOGS/EDIT/SUCCESS";
export const CASELOGS_EDIT_FAILURE: string = "CASELOGS/EDIT/FAILURE";
export const caseLogsEdit = createAction(
  CASELOGS_EDIT,
  (resolve) => (payload?: object) => resolve(payload)
);
export const caseLogsEditRequest = createAction(CASELOGS_EDIT_REQUEST);
export const caseLogsEditSuccess = createAction(
  CASELOGS_EDIT_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const caseLogsEditFailure = createAction(CASELOGS_EDIT_FAILURE);
// Delete Case Log
export const CASELOGS_DELETE: string = "CASELOGS/DELETE";
export const CASELOGS_DELETE_REQUEST: string = "CASELOGS/DELETE/REQUEST";
export const CASELOGS_DELETE_SUCCESS: string = "CASELOGS/DELETE/SUCCESS";
export const CASELOGS_DELETE_FAILURE: string = "CASELOGS/DELETE/FAILURE";
export const caseLogsDelete = createAction(
  CASELOGS_DELETE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const caseLogsDeleteRequest = createAction(CASELOGS_DELETE_REQUEST);
export const caseLogsDeleteSuccess = createAction(
  CASELOGS_DELETE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const caseLogsDeleteFailure = createAction(CASELOGS_DELETE_FAILURE);
// Review Case log
export const CASELOGS_REVIEW: string = "CASELOGS/REVIEW";
export const CASELOGS_REVIEW_REQUEST: string = "CASELOGS/REVIEW/REQUEST";
export const CASELOGS_REVIEW_SUCCESS: string = "CASELOGS/REVIEW/SUCCESS";
export const CASELOGS_REVIEW_FAILURE: string = "CASELOGS/REVIEW/FAILURE";
export const caseLogsReview = createAction(
  CASELOGS_REVIEW,
  (resolve) => (payload?: object) => resolve(payload)
);
export const caseLogsReviewRequest = createAction(CASELOGS_REVIEW_REQUEST);
export const caseLogsReviewSuccess = createAction(
  CASELOGS_REVIEW_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const caseLogsReviewFailure = createAction(CASELOGS_REVIEW_FAILURE);
// Reset Case log Loaded
export const CASELOGS_RESET_CASELOG_LOADED: string =
  "CASELOGS/RESET/CASELOG_LOADED";
export const caseLogsResetCaseLogsLoaded = createAction(
  CASELOGS_RESET_CASELOG_LOADED,
  (resolve) => (payload?: object) => resolve(payload)
);
// Create or Edit Update
export const CASELOGS_CREATE_EDIT_UPDATE: string =
  "CASELOGS/CREATE_EDIT/UPDATE";
export const caseLogsCreateEditUpdate = createAction(
  CASELOGS_CREATE_EDIT_UPDATE,
  (resolve) => (payload?: object) => resolve(payload)
);
// Create or Edit Next step
export const CASELOGS_CREATE_EDIT_STEP: string = "CASELOGS/CREATE_EDIT/STEP";
export const caseLogsCreateEditStep = createAction(
  CASELOGS_CREATE_EDIT_STEP,
  (resolve) => (payload?: object) => resolve(payload)
);
