// Features
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { BroadcastMessages } from "../../../store/actions/";
import { setMomentDate, setUTCtime } from "../../../utils/";
// Components
import {
  Button,
  Divider,
  Header,
  Icon,
  List,
  Loader,
  Popup,
  Label,
} from "semantic-ui-react";
// Types
import type { PropsPresentational as PropsBroadcastSentMessage } from "../broadcast-sent-message/BroadcastSentMessageContainer";
import type { PayloadObject } from "../../../types/common.types";
import type { RootState } from "../../../store/reducers/";
import type { State as StateBroadcastMessages } from "../../../store/reducers/broadcastMessages";
// Notifications Individual Action:
const { useState } = React;
// Styled Components
const Wrapper = styled.p`
  color: #999999;
  cursor: pointer;
  float: left;
  min-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 0;
  &:hover > span.wrap-content {
    color: #3765b3;
    text-decoration: underline;
  }
`;
const ListItem = styled(List.Item)`
  color: #999999 !important;
`;
//
const BroadcastSchedulers = ({
  onLoadBroadCastMessage,
  ...props
}: React.PropsWithChildren<PropsBroadcastSentMessage>) => {
  const dispatch = useDispatch();
  // STORE TENANT SETTING - Broadcast Messages
  const {
    broadcastMessages,
    isFetching,
  }: {
    broadcastMessages: StateBroadcastMessages["broadcastMessages"];
    isFetching: boolean;
  } = useSelector(({ tenantSettings }: RootState) => ({
    broadcastMessages:
      tenantSettings.tenantSettings["BROADCAST_MESSAGES"].valuesApi,
    isFetching: tenantSettings.isFetchingTenantSettings,
  }));
  const broadcastSchedulers = broadcastMessages.filter(
    ({ expirationDate }) => !!expirationDate
  );
  // State
  const [inAction, setInAction] = useState<"" | "deleteBroadcastSchedulers">(
    ""
  );
  // METHODS
  const onDeleteBroadcastSchedulers = (broadcastMessageId: string) => {
    setInAction("deleteBroadcastSchedulers");
    dispatch(
      BroadcastMessages.broadcastMessagesDelete({
        broadcastMessageId,
        callbackSuccess: () => {
          onLoadBroadCastMessage();
          setInAction("");
        },
        callbackError: () => setInAction(""),
      })
    );
  };
  const onParseArraySelected = (
    propKey: "cohorts" | "institutions" | "programs" | "roles",
    keyToSearch: "apiName" | "_id",
    arraySelected: Array<PayloadObject | string> = []
  ) => {
    const arrayToSearch: PayloadObject[] = props[propKey];
    let arrayToParse: PayloadObject[] = [];
    if (arraySelected.length && arrayToSearch.length) {
      arrayToParse = arraySelected.map((value) => {
        if (typeof value === "string") {
          return arrayToSearch.filter(
            (valueToFind) => valueToFind && valueToFind[keyToSearch] === value
          )[0];
        }
        return value;
      });
    }
    return arrayToParse;
  };
  // RENDERS
  return (
    <div className="broadcast-schedulers text-left noborder p10">
      <Divider className="mt30 mb10" horizontal>
        <Header as="h4" className="skejule-gray">
          <Icon name="clock" />
          SCHEDULERS
        </Header>
      </Divider>
      <div className="broadcast-schedulers-list">
        {isFetching && (
          <Loader
            style={{ top: "30px", left: "-20px" }}
            active
            inline="centered"
            size="large"
            indeterminate
            className="m10 pl50 pr10"
          >
            <h3 className="ml10" style={{ textAlign: "center" }}>
              Loading Schedulers ...
            </h3>
          </Loader>
        )}
        {!isFetching &&
          broadcastSchedulers.map(
            (
              {
                _id,
                message,
                roles,
                cohorts,
                expirationDate,
                institutions,
                programs,
              },
              index
            ) => {
              const rolesSelected = onParseArraySelected(
                "roles",
                "apiName",
                roles
              );
              const programsSelected = onParseArraySelected(
                "programs",
                "_id",
                programs
              );
              const cohortsSelected = onParseArraySelected(
                "cohorts",
                "_id",
                cohorts
              );
              const institutionsSelected = onParseArraySelected(
                "institutions",
                "_id",
                institutions
              );
              const expirationDateWithFormat =
                setUTCtime(expirationDate).format("MM/DD/YY");
              const broadcastMsgIsExpired = setMomentDate(
                expirationDateWithFormat
              ).isBefore(setMomentDate(setUTCtime().format("MM/DD/YY")));
              return (
                <React.Fragment key={`${_id}-${index}`}>
                  <Popup
                    basic
                    content={
                      <div
                        className="info-elements skejule-scroll names"
                        data-popup="is-popup-notification"
                      >
                        <div
                          className="info-element element"
                          data-popup="is-popup-notification"
                        >
                          <div
                            className="label mb5"
                            data-popup="is-popup-notification"
                          >
                            Message
                          </div>
                          <div
                            className="value"
                            data-popup="is-popup-notification"
                            style={{ whiteSpace: "pre-line" }}
                          >
                            {message}
                          </div>
                        </div>
                      </div>
                    }
                    data-popup="is-popup-notification"
                    id="broadcast-message-popup"
                    on="click"
                    position="bottom center"
                    trigger={
                      <Wrapper className="message mb10">
                        <span className="label skejule-teal mr5">Message:</span>
                        <span className="wrap-content">{message}</span>
                      </Wrapper>
                    }
                  />
                  <div className="send-to mb10">
                    <span className="label skejule-teal">Send to:</span>
                  </div>
                  <div className="roles mb10">
                    <span className="label skejule-teal mr5">Roles:</span>
                    <span className="value skejule-gray">
                      {rolesSelected.map(({ name }) => name).join(", ")}
                    </span>
                  </div>
                  {(programsSelected?.length > 0 ||
                    cohortsSelected?.length > 0) && (
                    <div className="programs-cohorts mb10">
                      <span className="label skejule-teal mr5">
                        Programs - Cohorts:
                      </span>
                      <span className="value skejule-gray">
                        {programsSelected.map(({ name }) => name).join(", ")}
                        {programsSelected.length > 0 && " - "}
                        {cohortsSelected.map(({ name }) => name).join(", ")}
                      </span>
                    </div>
                  )}
                  {institutionsSelected?.length > 0 && (
                    <Popup
                      basic
                      content={
                        <div
                          className="info-elements skejule-scroll"
                          data-popup="is-popup-notification"
                        >
                          <div
                            className="info-element element"
                            data-popup="is-popup-notification"
                          >
                            <div
                              className="label mb5"
                              data-popup="is-popup-notification"
                            >
                              Clinics
                            </div>
                            <List
                              bulleted
                              data-popup="is-popup-notification"
                              size="small"
                            >
                              {institutionsSelected.map(
                                ({ _id, name }, index) => (
                                  <ListItem
                                    data-popup="is-popup-notification"
                                    key={`institution-scheduler-duplicate-${_id}-${index}`}
                                  >
                                    {name}
                                  </ListItem>
                                )
                              )}
                            </List>
                          </div>
                        </div>
                      }
                      data-popup="is-popup-notification"
                      id="broadcast-message-popup"
                      on="click"
                      position="bottom center"
                      trigger={
                        <Wrapper className="institutions mb10">
                          <span className="label skejule-teal mr5">
                            Clinics:
                          </span>
                          <span className="wrap-content">
                            {institutionsSelected
                              .map(({ name }) => name)
                              .join(", ")}
                          </span>
                        </Wrapper>
                      }
                    />
                  )}
                  <div className="expiration-date mb20">
                    <span className="label skejule-teal mr5">
                      Expiration Date:
                    </span>
                    <span className="value skejule-gray">
                      {expirationDateWithFormat}
                      {broadcastMsgIsExpired && (
                        <Label
                          className="expired ml5 p3"
                          color="red"
                          size="tiny"
                        >
                          Expired
                        </Label>
                      )}
                    </span>
                    <div className="actions relative right">
                      {!broadcastMsgIsExpired && (
                        <Popup
                          content="Edit"
                          position="bottom center"
                          trigger={
                            <Icon
                              className="mr5"
                              color="teal"
                              disabled={
                                inAction === "deleteBroadcastSchedulers"
                              }
                              link={!inAction}
                              loading={inAction === "deleteBroadcastSchedulers"}
                              name={
                                inAction === "deleteBroadcastSchedulers"
                                  ? "sync alternate"
                                  : "pencil alternate"
                              }
                              onClick={() =>
                                onLoadBroadCastMessage({
                                  _id: _id,
                                  cohorts: cohortsSelected.map(
                                    ({ _id }) => _id
                                  ),
                                  institutions: institutionsSelected.map(
                                    ({ _id }) => _id
                                  ),
                                  programs: programsSelected.map(
                                    ({ _id }) => _id
                                  ),
                                  roles: rolesSelected.map(
                                    ({ apiName }) => apiName
                                  ),
                                  users: [],
                                  expirationDate: expirationDateWithFormat,
                                  message,
                                })
                              }
                              size="tiny"
                            />
                          }
                        />
                      )}
                      <Popup
                        content="Copy"
                        position="bottom center"
                        trigger={
                          <Icon
                            className="mr5"
                            color="blue"
                            disabled={inAction === "deleteBroadcastSchedulers"}
                            link={!inAction}
                            loading={inAction === "deleteBroadcastSchedulers"}
                            name="copy outline"
                            onClick={() =>
                              onLoadBroadCastMessage({
                                _id: "",
                                cohorts: cohortsSelected.map(({ _id }) => _id),
                                institutions: institutionsSelected.map(
                                  ({ _id }) => _id
                                ),
                                programs: programsSelected.map(
                                  ({ _id }) => _id
                                ),
                                roles: rolesSelected.map(
                                  ({ apiName }) => apiName
                                ),
                                users: [],
                                expirationDate: expirationDateWithFormat,
                                message,
                              })
                            }
                            size="tiny"
                          />
                        }
                      />
                      <Popup
                        content={
                          <Button
                            basic
                            className="delete-notification-btn skejule m0"
                            data-btn="is-popup-notification"
                            disabled={inAction === "deleteBroadcastSchedulers"}
                            loading={inAction === "deleteBroadcastSchedulers"}
                            onClick={() => onDeleteBroadcastSchedulers(_id)}
                          >
                            Confirm Delete
                          </Button>
                        }
                        data-popup="is-popup-notification"
                        hoverable
                        on={["hover", "click"]}
                        position="bottom center"
                        trigger={
                          <Icon
                            color="red"
                            disabled={inAction === "deleteBroadcastSchedulers"}
                            link={!inAction}
                            name="trash alternate"
                            size="tiny"
                          />
                        }
                      />
                    </div>
                  </div>
                  <Divider />
                </React.Fragment>
              );
            }
          )}
      </div>
    </div>
  );
};
export default BroadcastSchedulers;
