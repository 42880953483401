import type { Config, tAPI } from "./API";
// API Institutions static class

const apiBaseInstitutions = "/institutions";
export default class Institutions {
  // Get a list of institutions for this company and filter by name (if exist)
  static GetInstitutions(
    api: tAPI,
    status: string,
    name: string,
    config?: Config
  ) {
    let queryParams = "";
    if (status) {
      queryParams += `status=${status}`;
    }
    if (name) {
      if (queryParams.length > 0) {
        queryParams += `&`;
      }
      queryParams += `name=${name}`;
    }
    if (queryParams.length > 0) {
      queryParams = `?` + queryParams;
    }
    return api.GET(`${apiBaseInstitutions}${queryParams}`, config);
  }
  // Create Institution
  static CreateInstitution(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBaseInstitutions}`, data, config);
  }
  // Get a Institution for that institutionId
  static GetInstitution(api: tAPI, institutionId: string, config?: Config) {
    return api.GET(`${apiBaseInstitutions}/${institutionId}`, config);
  }
  static GetInstitutionAvailabilities(
    api: tAPI,
    institutionId: string,
    config?: Config
  ) {
    return api.GET(`/availabilities?institution=${institutionId}`, config);
  }
  static CreateInstitutionAvailabilities(
    api: tAPI,
    data: object,
    config?: Config
  ) {
    return api.POST(`/availabilities`, data, config);
  }
  static EditInstitutionAvailabilities(
    api: tAPI,
    availabilityId: string,
    data: object,
    config?: Config
  ) {
    return api.PATCH(`/availabilities/${availabilityId}`, data, config);
  }
  // Edit a institution from institutionId
  static EditInstitution(
    api: tAPI,
    actionApi: "ACTIVATE_DEACTIVATE_INSTITUTION" | "EDIT_INSTITUTION",
    institutionId: string,
    data: object,
    isUpload: boolean = false,
    config?: Config
  ) {
    switch (actionApi) {
      case "ACTIVATE_DEACTIVATE_INSTITUTION":
        return api.PATCH(
          `${apiBaseInstitutions}/${institutionId}/activateDeactivate`,
          data,
          config
        );
      default:
        if (isUpload) {
          return api.UPLOAD(
            `${apiBaseInstitutions}/${institutionId}`,
            data,
            "PATCH",
            config
          );
        }
        return api.PATCH(
          `${apiBaseInstitutions}/${institutionId}`,
          data,
          config
        );
    }
  }
  // Get a Institution files for that institutionId
  static GetInstitutionFiles(
    api: tAPI,
    institutionId: string,
    config?: Config
  ) {
    return api.GET(
      `/files?populated=true&belongsTo=Institutions&owner=${institutionId}`,
      config
    );
  }
  // Get all Institution files expired
  static GetInstitutionFilesExpired(api: tAPI, config?: Config) {
    return api.GET(
      `/files?populated=true&aboutExpire=true&belongsTo=Institutions`,
      config
    );
  }
  // Upload a Institution files
  static UploadInstitutionFiles(api: tAPI, data: object, config?: Config) {
    return api.UPLOAD(`/files`, data, "POST", config);
  }
  // Delete a Institution
  static DeleteInstitution(api: tAPI, institutionId: string, config?: Config) {
    return api.DELETE(`${apiBaseInstitutions}/${institutionId}`, config);
  }
}
