import { createAction } from "deox";

// Actions for router before transitions
export const ROUTER_BEFORE_TRANSITIONS: string =
  "ROUTER/ROUTER_BEFORE_TRANSITIONS";

export const routerBeforeTransitions = createAction(
  ROUTER_BEFORE_TRANSITIONS,
  (resolve) => (payload?: object) => resolve(payload)
);
