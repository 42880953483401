// Inclusive reducers
import { combineReducers } from "redux";
import accessControl from "./accessControl";
import accountSettings from "./accountSettings";
import api from "./api";
import auth from "./auth";
import broadcastMessages from "./broadcastMessages";
import cameraDevice from "./cameraDevice";
import caseLogs from "./caseLogs";
import cohorts from "./cohorts";
import company from "./company";
import config from "./config";
import customFields from "./customFields";
import enrollments from "./enrollments";
import evaluations from "./evaluations";
import evaluationsSchedulers from "./evaluationsSchedulers";
import evaluationsTemplates from "./evaluationsTemplates";
import feedbackForm from "./feedbackForm";
import fileCategories from "./fileCategories";
import guideLineTutorial from "./guideLineTutorial";
import importData from "./importData";
import institutions from "./institutions";
import invitations from "./invitations";
import localStore from "./localStore";
import metrics from "./metrics";
import notifications from "./notifications";
import periods from "./periods";
import programs from "./programs";
import { reducer as reduxFormReducer } from "redux-form";
import reports from "./reports";
import rotations from "./rotations";
import routerBeforeTransitions from "./routerBeforeTransitions";
import routerManager from "./routerManager";
import { connectRouter } from "connected-react-router";
import serviceWorker from "./serviceWorker";
import tenantSettings from "./tenantSettings";
import terms from "./terms";
import users from "./users";
import usersManager from "./usersManager";
import usersPortfolio from "./portfolio";
// main reducers
export const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    // rest of your reducers
    accessControl,
    accountSettings,
    api,
    auth,
    broadcastMessages,
    cameraDevice,
    caseLogs,
    cohorts,
    config,
    company,
    customFields,
    enrollments,
    evaluations,
    evaluationsSchedulers,
    evaluationsTemplates,
    feedbackForm,
    fileCategories,
    form: reduxFormReducer,
    guideLineTutorial,
    importData,
    institutions,
    invitations,
    localStore,
    metrics,
    notifications,
    periods,
    programs,
    reports,
    rotations,
    routerBeforeTransitions,
    routerManager,
    serviceWorker,
    tenantSettings,
    terms,
    users,
    usersManager,
    usersPortfolio,
  });
// export const reducers: any = combineReducers({
//   accessControl,
//   accountSettings,
//   api,
//   auth,
//   broadcastMessages,
//   caseLogs,
//   cohorts,
//   config,
//   company,
//   customFields,
//   enrollments,
//   evaluations,
//   evaluationsSchedulers,
//   evaluationsTemplates,
//   feedbackForm,
//   fileCategories,
//   form: reduxFormReducer,
//   guideLineTutorial,
//   institutions,
//   invitations,
//   localStore,
//   metrics,
//   notifications,
//   periods,
//   programs,
//   reports,
//   rotations,
//   routerBeforeTransitions,
//   routerManager,
//   serviceWorker,
//   tenantSettings,
//   terms,
//   users,
//   usersManager,
//   usersPortfolio,
// });
export type RootState = ReturnType<any>;
