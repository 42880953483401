import { put, select, call, all, takeLatest } from "redux-saga/effects";
import MetricsApi from "../api/Metrics";
import { Auth, Metrics } from "../actions/";
// Type
import type { Action } from "deox";
import type { tAPI } from "../api/API";
//Sagas for Metrics
// METRICS PAGES
function* doMetricsFetchMetricsPages(
  action: Action<
    string,
    {
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Metrics.metricsFetchMetricsPagesRequest());
  window.logger.log("doMetricsFetchMetricsPages", action);
  const { callbackSuccess = null, callbackError = null } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get all metric pages for this company
    const {
      data: { pages },
    } = yield call(MetricsApi.GetMetricsPages, api, { token });
    yield put(
      Metrics.metricsFetchMetricsPagesSuccess({
        pages,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(pages);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doMetricsFetchMetricsPages",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Metrics.metricsFetchMetricsPagesFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}

function* doMetricsCreateMetricPage(
  action: Action<
    string,
    {
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Metrics.metricsCreateMetricPageRequest());
  window.logger.log("doMetricsCreateMetricPage", action);
  const { body, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to create a new metric page to use getting charts
    const {
      data: { page },
    } = yield call(MetricsApi.CreateMetricPage, api, body, { token });
    yield put(
      Metrics.metricsCreateMetricPageSuccess({
        page,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(page);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doMetricsCreateMetricPage", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Metrics.metricsCreateMetricPageFailure());
    const { message, details } = errors || "";
    if (callbackError) {
      let errorMsg = message;
      if (details && details.length > 0) {
        errorMsg = `${errorMsg}: ${details[0].msg}`;
      }
      callbackError(errorMsg);
    }
  }
}

function* doMetricsFetchMetricPage(
  action: Action<
    string,
    {
      pageId: string;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Metrics.metricsFetchMetricPageRequest());
  window.logger.log("doMetricsFetchMetricPage", action);
  const {
    pageId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get a metric page for that specific pageId
    const {
      data: { page },
    } = yield call(MetricsApi.GetMetricPage, api, pageId, { token });
    yield put(
      Metrics.metricsFetchMetricPageSuccess({
        page,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(page);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doMetricsFetchMetricPage", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Metrics.metricsFetchMetricPageFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}

function* doMetricsEditMetricPage(
  action: Action<
    string,
    {
      pageId: string;
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Metrics.metricsEditMetricPageRequest());
  window.logger.log("doMetricsEditMetricPage", action);
  const {
    pageId,
    body,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to update a metric page for that specific pageId
    const {
      data: { page },
    } = yield call(MetricsApi.EditMetricPage, api, pageId, body, { token });
    yield put(
      Metrics.metricsEditMetricPageSuccess({
        page,
      })
    );
    if (callbackSuccess) {
      yield put(Metrics.metricsFetchMetricPageCharts({ pageId }));
      callbackSuccess(page);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doMetricsEditMetricPage", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Metrics.metricsEditMetricPageFailure());
    const { message, details } = errors || "";
    if (callbackError) {
      let errorMsg = message;
      if (details && details.length > 0) {
        errorMsg = `${errorMsg}: ${details[0].msg}`;
      }
      callbackError(errorMsg);
    }
  }
}

function* doMetricsDeleteMetricPage(
  action: Action<
    string,
    {
      pageId: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Metrics.metricsDeleteMetricPageRequest());
  window.logger.log("doMetricsDeleteMetricPage", action);
  const {
    pageId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to delete a page from some specific pageId
    yield call(MetricsApi.DeleteMetricPage, api, pageId, { token });
    yield put(
      Metrics.metricsDeleteMetricPageSuccess({
        pageId,
      })
    );
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doMetricsDeleteMetricPage", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Metrics.metricsDeleteMetricPageFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}
// END METRICS PAGES />

// METRICS PAGES - CHARTS
function* doMetricsFetchMetricPageCharts(
  action: Action<
    string,
    {
      pageId: string;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Metrics.metricsFetchMetricPageChartsRequest());
  window.logger.log("doMetricsFetchMetricPageCharts", action);
  const {
    pageId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get all charts from some metric page
    const {
      data: { charts },
    } = yield call(MetricsApi.GetMetricPageCharts, api, pageId, { token });
    yield put(
      Metrics.metricsFetchMetricPageChartsSuccess({
        charts,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(charts);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doMetricsFetchMetricPageCharts",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Metrics.metricsFetchMetricPageChartsFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}

function* doMetricsCreateMetricPageChart(
  action: Action<
    string,
    {
      pageId: string;
      body: object;
      query: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Metrics.metricsCreateMetricPageChartRequest());
  window.logger.log("doMetricsCreateMetricPageChart", action);
  const {
    pageId,
    body,
    query,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to create a new chart for some metric page
    const {
      data: { chart },
    } = yield call(MetricsApi.CreateMetricPageChart, api, pageId, body, {
      token,
    });
    yield put(
      Metrics.metricsCreateMetricPageChartSuccess({
        chart: {
          ...chart,
          query,
        },
      })
    );
    if (callbackSuccess) {
      yield put(Metrics.metricsFetchMetricPageCharts({ pageId }));
      callbackSuccess(chart);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doMetricsCreateMetricPageChart",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Metrics.metricsCreateMetricPageChartFailure());
    const { message, details } = errors || "";
    if (callbackError) {
      let errorMsg = message;
      if (details && details.length > 0) {
        errorMsg = `${errorMsg}: ${details[0].msg}`;
      }
      callbackError(errorMsg);
    }
  }
}

function* doMetricsFetchMetricPageChart(
  action: Action<
    string,
    {
      pageId: string;
      chartId: string;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Metrics.metricsFetchMetricPageChartRequest());
  window.logger.log("doMetricsFetchMetricPageChart", action);
  const {
    pageId,
    chartId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get a chart from some specific chartId for some metric page
    const {
      data: { chart },
    } = yield call(MetricsApi.GetMetricPageChart, api, pageId, chartId, {
      token,
    });

    yield put(
      Metrics.metricsFetchMetricPageChartSuccess({
        chart,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(chart);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doMetricsFetchMetricPageChart",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Metrics.metricsFetchMetricPageChartFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}

function* doMetricsEditMetricPageChart(
  action: Action<
    string,
    {
      pageId: string;
      chartId: string;
      body: object;
      query: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Metrics.metricsEditMetricPageChartRequest());
  window.logger.log("doMetricsEditMetricPageChart", action);
  const {
    pageId,
    chartId,
    body,
    query,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to update a chart from some specific chartId for some metric page
    const {
      data: { chart },
    } = yield call(MetricsApi.EditMetricPageChart, api, pageId, chartId, body, {
      token,
    });
    yield put(
      Metrics.metricsEditMetricPageChartSuccess({
        chart: {
          ...chart,
          query,
        },
      })
    );
    if (callbackSuccess) {
      yield put(Metrics.metricsFetchMetricPageCharts({ pageId }));
      callbackSuccess(chart);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doMetricsEditMetricPageChart",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Metrics.metricsEditMetricPageChartFailure());
    const { message, details } = errors || "";
    if (callbackError) {
      let errorMsg = message;
      if (details && details.length > 0) {
        errorMsg = `${errorMsg}: ${details[0].msg}`;
      }
      callbackError(errorMsg);
    }
  }
}

function* doMetricsDeleteMetricPageChart(
  action: Action<
    string,
    {
      pageId: string;
      chartId: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Metrics.metricsDeleteMetricPageChartRequest());
  window.logger.log("doMetricsDeleteMetricPageChart", action);
  const {
    pageId,
    chartId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to delete a chart from some specific chartId for some metric page
    yield call(MetricsApi.DeleteMetricPageChart, api, pageId, chartId, {
      token,
    });
    yield put(
      Metrics.metricsDeleteMetricPageChartSuccess({
        chartId,
      })
    );
    if (callbackSuccess) {
      yield put(Metrics.metricsFetchMetricPageCharts({ pageId }));
      callbackSuccess();
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doMetricsDeleteMetricPageChart",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Metrics.metricsDeleteMetricPageChartFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}
// END METRICS PAGES -CHARTS />

export default function* reportsSagas(): Generator<any, any, any> {
  yield all([
    // Metrics Pages
    takeLatest(Metrics.METRICS_FETCH_METRICS_PAGES, doMetricsFetchMetricsPages),
    takeLatest(Metrics.METRICS_CREATE_METRIC_PAGE, doMetricsCreateMetricPage),
    takeLatest(Metrics.METRICS_FETCH_METRIC_PAGE, doMetricsFetchMetricPage),
    takeLatest(Metrics.METRICS_EDIT_METRIC_PAGE, doMetricsEditMetricPage),
    takeLatest(Metrics.METRICS_DELETE_METRIC_PAGE, doMetricsDeleteMetricPage),
    // Metrics Pages - Charts
    takeLatest(
      Metrics.METRICS_FETCH_METRIC_PAGE_CHARTS,
      doMetricsFetchMetricPageCharts
    ),
    takeLatest(
      Metrics.METRICS_CREATE_METRIC_PAGE_CHART,
      doMetricsCreateMetricPageChart
    ),
    takeLatest(
      Metrics.METRICS_FETCH_METRIC_PAGE_CHART,
      doMetricsFetchMetricPageChart
    ),
    takeLatest(
      Metrics.METRICS_EDIT_METRIC_PAGE_CHART,
      doMetricsEditMetricPageChart
    ),
    takeLatest(
      Metrics.METRICS_DELETE_METRIC_PAGE_CHART,
      doMetricsDeleteMetricPageChart
    ),
  ]);
}
