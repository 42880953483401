// Features
import produce from "immer";
import { AccountSettings, BroadcastMessages } from "../actions/";
// Types
import type { Action } from "deox";
import type { StateAccountSettings } from "../../types/accountSettings.types";
import type { UsersRole } from "../../types/users.types";
export type PayloadBroadCastMessage = {
  _id: string;
  createdAt: string;
  updatedAt?: string;
  roles: UsersRole[];
  message: string;
  programs?: string[];
  cohorts?: string[];
  institutions?: string[];
  expirationDate?: string;
};
export type State = {
  broadcastMessages: PayloadBroadCastMessage[];
  broadcastMessagesWatched: string[];
  hasBroadcastMessages: boolean;
};
type BroadcastMessagesActionReducers = Action<
  string,
  {
    broadcastMessages: State["broadcastMessages"];
    frontendPersonalization: StateAccountSettings["frontendPersonalization"];
  }
>;
// Broadcast Messages Reducer
const initialState: State = {
  broadcastMessages: [],
  broadcastMessagesWatched: [],
  hasBroadcastMessages: false,
};
export const onSetBroadcastMessagesSettings =
  (): StateAccountSettings["frontendPersonalization"]["BROADCAST_MESSAGES_SETTINGS"] => ({
    messagesWatched: [],
  });
const broadcastMessages: (
  state: State,
  action: BroadcastMessagesActionReducers
) => State = produce(
  (draft: State, action: BroadcastMessagesActionReducers) => {
    switch (action.type) {
      case AccountSettings.ACCOUNT_SETTINGS_FETCH_SUCCESS:
        const {
          BROADCAST_MESSAGES_SETTINGS = onSetBroadcastMessagesSettings(),
        } = action.payload.frontendPersonalization;
        const messagesWatched = BROADCAST_MESSAGES_SETTINGS?.messagesWatched;
        draft.broadcastMessagesWatched = messagesWatched;
        break;
      case BroadcastMessages.BROADCAST_MESSAGES_SAVE_MESSAGES:
        draft.broadcastMessages = action.payload.broadcastMessages;
        draft.hasBroadcastMessages =
          action.payload.broadcastMessages.length > 0;
        break;
    }
  },
  initialState
);
export default broadcastMessages;
