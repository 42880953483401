import axios from "axios";
import { put, select, call, all, takeLatest } from "redux-saga/effects";
import EvaluationsApi from "../api/Evaluations";
import { Api, Auth, Evaluations } from "../actions/";
import { EVALUATIONS_LIMIT } from "../reducers/evaluations";
// Type
import type { Action } from "deox";
import type { tAPI } from "../api/API";
import type { CancelRequest, State as StateApi } from "../reducers/api";
import type { StateUsersManager } from "../../types/users.types";
//Sagas for Evaluations
//////
// EVALUATIONS
//////
const getFiltersEvaluations = (evaluations: { [k: string]: any }[]) => {
  let filtersEvaluations: { [k: string]: any } = {
    evaluators: {},
    targets: {},
  };
  // All Evaluations
  for (let i = 0; i < evaluations.length; i++) {
    const evaluation = evaluations[i];
    const {
      status: statusEvaluation,
      evaluationType,
      evaluatorsRole,
      targetsRole,
    } = evaluation;
    if (statusEvaluation) {
      if (!filtersEvaluations.evaluators[evaluatorsRole]) {
        filtersEvaluations.evaluators[evaluatorsRole] = true;
      }
      if (evaluationType === "UserEvaluations") {
        if (!filtersEvaluations.targets[targetsRole]) {
          filtersEvaluations.targets[targetsRole] = true;
        }
      }
    }
  }
  return {
    filtersEvaluations,
  };
};
function* doEvaluationsFetchAll(
  action: Action<
    string,
    {
      callbackSuccess?: (data: any, metadata: any) => void;
      callbackError?: (error: any, details: any) => void;
    }
  >
) {
  yield put(Evaluations.evaluationsFetchAllRequest());
  window.logger.log("doEvaluationsFetchAll", action);
  const { callbackSuccess = null, callbackError = null } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get all evaluations for this company
    const { data: dataEvaluations } = yield call(
      EvaluationsApi.GetEvaluations,
      api,
      true,
      0,
      1,
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      "",
      "",
      "",
      {
        target: "",
        evaluator: "",
        createdAt: "",
        closingDate: "",
        status: "",
      },
      { token }
    );
    const { evaluations: evaluationsByType, metadata } = dataEvaluations || {
      evaluations: {},
    };
    const {
      UserEvaluations = [],
      InstitutionEvaluations = [],
      CompanyEvaluations = [],
      CustomEvaluations = [],
    } = evaluationsByType;
    let allEvaluations = [
      ...UserEvaluations,
      ...InstitutionEvaluations,
      ...CompanyEvaluations,
      ...CustomEvaluations,
    ];
    const evaluations = {
      all: allEvaluations,
      UserEvaluations,
      InstitutionEvaluations,
      CompanyEvaluations,
      CustomEvaluations,
    };
    yield put(
      Evaluations.evaluationsFetchAllSuccess({ evaluations, metadata })
    );
    if (callbackSuccess) {
      callbackSuccess(evaluations, metadata);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doEvaluationsFetchAll", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Evaluations.evaluationsFetchAllFailure());
    const { message, details } = errors || "";
    if (callbackError) {
      callbackError(message, details);
    }
  }
}
function* doEvaluationsFetchEvaluationsByFilters(
  action: Action<
    string,
    {
      populated?: boolean;
      page?: number;
      limit?: number;
      searchTxt?: string;
      status?: string[];
      userStatus?: StateUsersManager["filters"]["status"];
      answerStatus?: string[];
      tags?: string[];
      evaluators?: string[];
      targets?: string[];
      programs?: string[];
      cohorts?: string[];
      institutions?: string[];
      startDate: string;
      endDate: string;
      sortBy?: {
        target: "ascending" | "descending" | "";
        evaluator: "ascending" | "descending" | "";
        createdAt: "ascending" | "descending" | "";
        closingDate: "ascending" | "descending" | "";
        status: "ascending" | "descending" | "";
      };
      callbackSuccess?: (data: any, filters: any, metadata: any) => void;
      callbackError?: (
        error: any,
        details: any,
        isAPICancelled: boolean
      ) => void;
    }
  >
) {
  yield put(Evaluations.evaluationsFetchByFiltersRequest());
  window.logger.log("doEvaluationsFetchEvaluationsByFilters", action);
  const {
    callbackSuccess = null,
    callbackError = null,
    ...payload
  } = action.payload || {};
  try {
    const { frontendPersonalization } = yield select(
      ({ accountSettings }) => accountSettings
    );
    let evaluationsSortBy: {
      target: "ascending" | "descending" | "";
      evaluator: "ascending" | "descending" | "";
      createdAt: "ascending" | "descending" | "";
      closingDate: "ascending" | "descending" | "";
      status: "ascending" | "descending" | "";
    } = {
      target: "",
      evaluator: "",
      createdAt: "",
      closingDate: "",
      status: "",
    };
    if (
      frontendPersonalization &&
      frontendPersonalization["EVALUATIONS_SETTINGS"]
    ) {
      evaluationsSortBy =
        frontendPersonalization["EVALUATIONS_SETTINGS"].sortBy;
    }
    const {
      populated = true,
      page = 1,
      limit = EVALUATIONS_LIMIT,
      searchTxt = "",
      status = [],
      userStatus = [],
      answerStatus = [],
      tags = [],
      evaluators = [],
      targets = [],
      programs = [],
      cohorts = [],
      institutions = [],
      startDate,
      endDate,
      sortBy = evaluationsSortBy,
    } = payload || {};
    const skip = (page - 1) * limit;
    const { evaluationTabs } = yield select(({ evaluations }) => evaluations);
    const evaluationTypeSelected =
      evaluationTabs.panes[evaluationTabs.selected].name;
    // Api
    const {
      api,
      cancelRequests,
      token,
    }: {
      api: tAPI;
      cancelRequests: StateApi["cancelRequests"];
      token: StateApi["token"];
    } = yield select(({ api }) => api);
    const [cancelRequest] = cancelRequests.filter(
      (cancelRequest: CancelRequest) => cancelRequest.key === "GET_EVALUATIONS"
    );
    if (cancelRequest && cancelRequest.source) {
      cancelRequest.source.cancel("Request has been cancelled");
      yield put(
        Api.removeCancelTokenAPI({
          cancelRequest: {
            key: "GET_EVALUATIONS",
          },
        })
      );
    }
    const CancelToken = axios.CancelToken,
      source = CancelToken.source();
    yield put(
      Api.addCancelTokenAPI({
        cancelRequest: {
          key: "GET_EVALUATIONS",
          source,
        },
      })
    );
    // call the api to get all evaluations and answers for this company
    // Evaluations:
    const { data: dataEvaluationsMetadata } = yield call(
      EvaluationsApi.GetEvaluations,
      api,
      false,
      0,
      1,
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      "",
      "",
      "",
      {
        target: "",
        evaluator: "",
        createdAt: "",
        closingDate: "",
        status: "",
      },
      { token }
    );
    const { metadata: allMetaData } = dataEvaluationsMetadata || {
      evaluations: {},
    };
    const { data: dataEvaluations } = yield call(
      EvaluationsApi.GetEvaluations,
      api,
      populated,
      skip,
      limit,
      [evaluationTypeSelected],
      status,
      userStatus,
      answerStatus,
      tags,
      evaluators,
      targets,
      programs,
      cohorts,
      institutions,
      searchTxt,
      startDate,
      endDate,
      sortBy,
      { cancelToken: source.token, token }
    );
    yield put(
      Api.removeCancelTokenAPI({
        cancelRequest: {
          key: "GET_EVALUATIONS",
        },
      })
    );
    const { evaluations: evaluationsByType, metadata } = dataEvaluations || {
      evaluations: {},
    };
    const {
      UserEvaluations = [],
      InstitutionEvaluations = [],
      CompanyEvaluations = [],
      CustomEvaluations = [],
    } = evaluationsByType;
    let allEvaluations = [
      ...UserEvaluations,
      ...InstitutionEvaluations,
      ...CompanyEvaluations,
      ...CustomEvaluations,
    ];
    const { filtersEvaluations } = getFiltersEvaluations(allEvaluations);
    const evaluations = {
      all: allEvaluations,
      UserEvaluations,
      InstitutionEvaluations,
      CompanyEvaluations,
      CustomEvaluations,
    };
    yield put(
      Evaluations.evaluationsFetchByFiltersSuccess({
        evaluations,
        evaluationsFilters: payload,
        metadata: {
          ...allMetaData,
          [evaluationTypeSelected]: metadata[evaluationTypeSelected],
        },
      })
    );
    if (callbackSuccess) {
      callbackSuccess(evaluations, filtersEvaluations, metadata);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doEvaluationsFetchEvaluationsByFilters",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    const { message, details, status } = errors || "";
    const isAPICancelled = status === "cancelled";
    if (isAPICancelled) {
      yield put(
        Api.removeCancelTokenAPI({
          cancelRequest: {
            key: "GET_EVALUATIONS",
          },
        })
      );
    }
    yield put(Evaluations.evaluationsFetchByFiltersFailure());
    if (callbackError) {
      callbackError(message, details, isAPICancelled);
    }
  }
}
function* doEvaluationsFetchKpis(
  action: Action<
    string,
    {
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  try {
    yield put(Evaluations.evaluationsFetchKpisRequest());
    window.logger.log("doEvaluationsFetchKpis", action);
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    const {
      data: { kpis: EvaluationsKPIS },
    } = yield call(EvaluationsApi.GetEvaluationsKpis, api, { token });
    yield put(
      Evaluations.evaluationsFetchKpisSuccess({
        kpis: {
          fetching: true,
          Evaluations: EvaluationsKPIS,
        },
      })
    );
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doEvaluationsFetchKpis", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    const EvaluationsKPIS: object = yield select(
      ({
        evaluations: {
          evaluations: { kpis },
        },
      }) => kpis
    );
    yield put(
      Evaluations.evaluationsFetchKpisFailure({
        kpis: EvaluationsKPIS,
      })
    );
  }
}
function* doEvaluationsAnswersFetchKpis(
  action: Action<string, { tags: string[]; unique: boolean }>
) {
  yield put(Evaluations.evaluationsAnswersFetchKpisRequest());
  window.logger.log("doEvaluationsAnswersFetchKpis", action);
  try {
    const { tags = [], unique = false } = action.payload;
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    const {
      data: { kpis: EvaluationsAnswersKPIs = [] },
    } = yield call(
      EvaluationsApi.GetEvaluationsAnswersKpis,
      api,
      tags,
      unique,
      {
        token,
      }
    );
    yield put(
      Evaluations.evaluationsAnswersFetchKpisSuccess({
        kpis: {
          fetching: true,
          EvaluationsAnswers: EvaluationsAnswersKPIs,
        },
      })
    );
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doEvaluationsAnswersFetchKpis",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    const EvaluationsAnswersKPIs: object = yield select(
      ({
        evaluations: {
          answers: { kpis },
        },
      }) => kpis
    );
    yield put(
      Evaluations.evaluationsAnswersFetchKpisFailure({
        kpis: EvaluationsAnswersKPIs,
      })
    );
  }
}
function* doEvaluationsFetchEvaluation(
  action: Action<
    string,
    {
      evaluationId: string;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Evaluations.evaluationsFetchEvaluationRequest());
  window.logger.log("doEvaluationsFetchEvaluation", action);
  const {
    evaluationId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get a evaluation
    const { data } = yield call(
      EvaluationsApi.GetEvaluation,
      api,
      evaluationId,
      false,
      "",
      { token }
    );
    const { evaluation } = data;
    yield put(Evaluations.evaluationsFetchEvaluationSuccess({ evaluation }));
    if (callbackSuccess) {
      callbackSuccess(evaluation);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doEvaluationsFetchEvaluation",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Evaluations.evaluationsFetchEvaluationFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}
function* doEvaluationsCreate(
  action: Action<
    string,
    {
      body: object;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Evaluations.evaluationsCreateRequest());
  window.logger.log("doEvaluationsCreate", action);
  const { body, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to create new evaluations
    yield call(EvaluationsApi.CreateEvaluation, api, body, { token });
    yield put(Evaluations.evaluationsCreateSuccess());
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doEvaluationsCreate", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Evaluations.evaluationsCreateFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}
function* doEvaluationsEdit(
  action: Action<
    string,
    {
      evaluationUpdated: { [k: string]: any };
      body: object;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Evaluations.evaluationsEditRequest());
  window.logger.log("doEvaluationsEdit", action);
  const {
    evaluationUpdated,
    body,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // Call the api to update a evaluation with specific evaluationId
    //    · Closing date
    //    · Evaluator
    yield call(
      EvaluationsApi.EditEvaluation,
      api,
      evaluationUpdated._id,
      body,
      { token }
    );
    yield put(
      Evaluations.evaluationsEditSuccess({ evaluation: evaluationUpdated })
    );
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doEvaluationsEdit", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Evaluations.evaluationsEditFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}
function* doEvaluationsDelete(
  action: Action<
    string,
    {
      evaluationId: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Evaluations.evaluationsDeleteRequest());
  window.logger.log("doEvaluationsDelete", action);
  const {
    evaluationId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to delete a evaluation for that specific evaluationId
    yield call(EvaluationsApi.DeleteEvaluation, api, evaluationId, { token });
    yield put(
      Evaluations.evaluationsDeleteSuccess({
        evaluationId,
      })
    );
    if (callbackSuccess) {
      // ToDo: LOAD ALL EVALUATIONS
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doEvaluationsDelete", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Evaluations.evaluationsDeleteFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}
//////
// EVALUATIONS > ANSWERS
//////
const parseAnswers = (
  UserEvaluationAnswers: { [k: string]: any }[],
  InstitutionEvaluationsAnswers: { [k: string]: any }[],
  CompanyEvaluationsAnswers: { [k: string]: any }[],
  CustomEvaluationsAnswers: { [k: string]: any }[]
) => {
  let answersParsed = {
    all: [
      ...UserEvaluationAnswers,
      ...InstitutionEvaluationsAnswers,
      ...CompanyEvaluationsAnswers,
      ...CustomEvaluationsAnswers,
    ],
    completed: {
      UserEvaluations: UserEvaluationAnswers.filter(
        ({ status: statusAnswer }) => statusAnswer === "completed"
      ),
      InstitutionEvaluations: InstitutionEvaluationsAnswers.filter(
        ({ status: statusAnswer }) => statusAnswer === "completed"
      ),
      CompanyEvaluations: CompanyEvaluationsAnswers.filter(
        ({ status: statusAnswer }) => statusAnswer === "completed"
      ),
      CustomEvaluations: CustomEvaluationsAnswers.filter(
        ({ status: statusAnswer }) => statusAnswer === "completed"
      ),
    },
    draft: {
      UserEvaluations: UserEvaluationAnswers.filter(
        ({ status: statusAnswer }) => statusAnswer === "draft"
      ),
      InstitutionEvaluations: InstitutionEvaluationsAnswers.filter(
        ({ status: statusAnswer }) => statusAnswer === "draft"
      ),
      CompanyEvaluations: CompanyEvaluationsAnswers.filter(
        ({ status: statusAnswer }) => statusAnswer === "draft"
      ),
      CustomEvaluations: CustomEvaluationsAnswers.filter(
        ({ status: statusAnswer }) => statusAnswer === "draft"
      ),
    },
  };
  let filtersAnswers: { [k: string]: any } = {
    evaluators: {},
    targets: {},
  };
  // Completed
  for (let i = 0; i < answersParsed.all.length; i++) {
    const {
      status: statusAnswer, // completed | draft
      evaluationAnswerType: evaluationType,
      evaluator: { role: evaluatorsRole },
      target: { role: targetsRole },
    } = answersParsed.all[i];
    if (statusAnswer === "completed") {
      if (!filtersAnswers.evaluators[evaluatorsRole]) {
        filtersAnswers.evaluators[evaluatorsRole] = true;
      }
      if (evaluationType === "UserEvaluationAnswers") {
        if (!filtersAnswers.targets[targetsRole]) {
          filtersAnswers.targets[targetsRole] = true;
        }
      }
    }
  }
  return {
    answers: answersParsed,
    filtersAnswers,
  };
};
function* doEvaluationsFetchAnswers(
  action: Action<
    string,
    {
      populated?: boolean;
      page?: number;
      limit?: number;
      searchTxt?: string;
      status?: string[];
      evaluators?: string[];
      startDate: string;
      endDate: string;
      callbackSuccess?: (data: any, filters: any, metadata: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Evaluations.evaluationsFetchAnswersRequest());
  window.logger.log("doEvaluationsFetchAnswers", action);
  const {
    populated = true,
    page = 1,
    limit = EVALUATIONS_LIMIT,
    searchTxt = "",
    status = ["completed"],
    evaluators = [],
    startDate,
    endDate,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    const { evaluationTabs } = yield select(({ evaluations }) => evaluations);
    const evaluationTypeSelected =
      evaluationTabs.panes[evaluationTabs.selected].name;
    // call the api to get answers for this company
    // Answers
    const skip = (page - 1) * limit;
    const { data: dataAnswers } = yield call(
      EvaluationsApi.GetEvaluationAnswers,
      api,
      populated,
      skip,
      limit,
      [
        `${evaluationTypeSelected.slice(
          0,
          evaluationTypeSelected.length - 1
        )}Answers`,
      ],
      status,
      evaluators,
      searchTxt,
      startDate,
      endDate,
      { token }
    );
    const {
      metadata,
      answers: {
        UserEvaluationAnswers = [],
        InstitutionEvaluationsAnswers = [],
        CompanyEvaluationsAnswers = [],
        CustomEvaluationsAnswers = [],
      },
    } = dataAnswers;
    const { answers, filtersAnswers } = parseAnswers(
      UserEvaluationAnswers,
      InstitutionEvaluationsAnswers,
      CompanyEvaluationsAnswers,
      CustomEvaluationsAnswers
    );
    yield put(
      Evaluations.evaluationsFetchAnswersSuccess({
        answers,
        metadata,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(answers, filtersAnswers, metadata);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doEvaluationsFetchAnswers", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Evaluations.evaluationsFetchAnswersFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}
function* doEvaluationsFetchEvaluationAnswers(
  action: Action<
    string,
    {
      evaluationId: string;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Evaluations.evaluationsFetchEvaluationAnswersRequest());
  window.logger.log("doEvaluationsFetchEvaluationAnswers", action);
  const {
    evaluationId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get all evaluation answers from some specific evaluationId
    const { data } = yield call(
      EvaluationsApi.GetEvaluation,
      api,
      evaluationId,
      true,
      "",
      { token }
    );
    const { answers: evaluationAnswers } = data;
    yield put(
      Evaluations.evaluationsFetchEvaluationAnswersSuccess({
        evaluationAnswers,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(evaluationAnswers);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doEvaluationsFetchEvaluationAnswers",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Evaluations.evaluationsFetchEvaluationAnswersFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}
function* doEvaluationsFetchEvaluationAnswer(
  action: Action<
    string,
    {
      evaluationId: string;
      answerId: string;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Evaluations.evaluationsFetchEvaluationAnswerRequest());
  window.logger.log("doEvaluationsFetchEvaluationAnswer", action);
  const {
    evaluationId,
    answerId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get one answer evaluation from some specific evaluationId
    const { data } = yield call(
      EvaluationsApi.GetEvaluation,
      api,
      evaluationId,
      true,
      answerId,
      { token }
    );
    const { answer } = data;
    yield put(Evaluations.evaluationsFetchEvaluationAnswerSuccess({ answer }));
    if (callbackSuccess) {
      callbackSuccess(answer);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doEvaluationsFetchEvaluationAnswer",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Evaluations.evaluationsFetchEvaluationAnswerFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}
function* doEvaluationsResponseEvaluationAnswers(
  action: Action<
    string,
    {
      evaluationId: string;
      answerId: string;
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Evaluations.evaluationsResponseEvaluationAnswersRequest());
  window.logger.log("doEvaluationsResponseEvaluation", action);
  const {
    evaluationId,
    answerId = "",
    body,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get a evaluation
    const { data } = yield call(
      EvaluationsApi.ResponseEvaluationAnswer,
      api,
      evaluationId,
      answerId,
      body,
      { token }
    );
    const { answer } = data;
    const { evaluationsFilters = {} } = yield select(
      ({ evaluations }) => evaluations
    );
    yield put(Evaluations.evaluationsFetchByFilters(evaluationsFilters));
    yield put(
      Evaluations.evaluationsResponseEvaluationAnswersSuccess({ answer })
    );
    if (callbackSuccess) {
      callbackSuccess(answer);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doEvaluationsResponseEvaluationAnswers",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Evaluations.evaluationsResponseEvaluationAnswersFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}
function* doEvaluationsDeleteEvaluationAnswer(
  action: Action<
    string,
    {
      evaluationId: string;
      answerId: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Evaluations.evaluationsDeleteEvaluationAnswerRequest());
  window.logger.log("doEvaluationsDeleteEvaluationAnswer", action);
  const {
    evaluationId,
    answerId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to delete a evaluation answer for that specific evaluationId and answerId
    yield call(
      EvaluationsApi.DeleteEvaluationAnswer,
      api,
      evaluationId,
      answerId,
      { token }
    );
    yield put(
      Evaluations.evaluationsDeleteEvaluationAnswerSuccess({
        evaluationId,
        answerId,
      })
    );
    if (callbackSuccess) {
      yield put(Evaluations.evaluationsFetchAnswers());
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doEvaluationsDeleteEvaluationAnswer",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Evaluations.evaluationsDeleteEvaluationAnswerFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}
export default function* evaluationsSagas(): Generator<any, any, any> {
  yield all([
    //////
    // EVALUATIONS
    //////
    takeLatest(Evaluations.EVALUATIONS_FETCH_KPIS, doEvaluationsFetchKpis),
    takeLatest(Evaluations.EVALUATIONS_FETCH_ALL, doEvaluationsFetchAll),
    takeLatest(
      Evaluations.EVALUATIONS_FETCH_BY_FILTERS,
      doEvaluationsFetchEvaluationsByFilters
    ),
    takeLatest(
      Evaluations.EVALUATIONS_FETCH_EVALUATION,
      doEvaluationsFetchEvaluation
    ),
    takeLatest(Evaluations.EVALUATIONS_CREATE, doEvaluationsCreate),
    takeLatest(Evaluations.EVALUATIONS_EDIT, doEvaluationsEdit),
    takeLatest(Evaluations.EVALUATIONS_DELETE, doEvaluationsDelete),
    //////
    // EVALUATIONS > ANSWERS
    //////
    takeLatest(
      Evaluations.EVALUATIONS_ANSWERS_FETCH_KPIS,
      doEvaluationsAnswersFetchKpis
    ),
    takeLatest(
      Evaluations.EVALUATIONS_FETCH_ANSWERS,
      doEvaluationsFetchAnswers
    ),
    takeLatest(
      Evaluations.EVALUATIONS_FETCH_EVALUATION_ANSWERS,
      doEvaluationsFetchEvaluationAnswers
    ),
    takeLatest(
      Evaluations.EVALUATIONS_FETCH_EVALUATION_ANSWER,
      doEvaluationsFetchEvaluationAnswer
    ),
    takeLatest(
      Evaluations.EVALUATIONS_RESPONSE_EVALUATION_ANSWERS,
      doEvaluationsResponseEvaluationAnswers
    ),
    takeLatest(
      Evaluations.EVALUATIONS_DELETE_EVALUATION_ANSWER,
      doEvaluationsDeleteEvaluationAnswer
    ),
  ]);
}
