import { put, select, call, all, takeLatest } from "redux-saga/effects";
import sortBy from "lodash/sortBy";
import CohortsApi from "../api/Cohorts";
import { Auth, Cohorts } from "../actions/";
// Type
import type { Action } from "deox";
import type { tAPI } from "../api/API";
//Sagas for Cohorts
function* doCohortsFetchCohorts(
  action: Action<
    string,
    {
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Cohorts.cohortsFetchCohortsRequest());
  window.logger.log("doCohortsFetchCohorts", action);
  const { callbackSuccess = null, callbackError = null } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get all cohorts for this company
    const { data } = yield call(CohortsApi.GetCohorts, api, { token });
    const { cohorts } = data;
    const cohortsSortByName = sortBy(cohorts, ["name"]).reverse();
    yield put(
      Cohorts.cohortsFetchCohortsSuccess({ cohorts: cohortsSortByName })
    );
    if (callbackSuccess) {
      callbackSuccess(cohortsSortByName);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doCohortsFetchCohorts", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Cohorts.cohortsFetchCohortsFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}

function* doCohortsCreate(
  action: Action<
    string,
    {
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Cohorts.cohortsCreateRequest());
  window.logger.log("doCohortsCreate", action);
  const { body, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to create a new cohort
    const { data } = yield call(CohortsApi.CreateCohort, api, body, { token });
    const { cohort } = data;
    yield put(Cohorts.cohortsCreateSuccess({ cohort }));
    if (callbackSuccess) {
      callbackSuccess(cohort);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doCohortsCreate", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Cohorts.cohortsCreateFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doCohortsEditCohort(
  action: Action<
    string,
    {
      cohortId: string;
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Cohorts.cohortsEditCohortRequest());
  window.logger.log("doCohortsEditCohort", action);
  const {
    cohortId,
    body,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to update a cohort for that specific cohortId
    const { data } = yield call(CohortsApi.EditCohort, api, cohortId, body, {
      token,
    });
    const { cohort } = data;
    yield put(Cohorts.cohortsEditCohortSuccess({ cohort }));
    if (callbackSuccess) {
      callbackSuccess(cohort);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doCohortsEditCohort", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Cohorts.cohortsEditCohortFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doCohortsDelete(
  action: Action<
    string,
    {
      cohortId: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any, details: any) => void;
    }
  >
) {
  yield put(Cohorts.cohortsDeleteRequest());
  window.logger.log("doCohortsDelete", action);
  const {
    cohortId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to delete a cohort for that specific cohortId
    yield call(CohortsApi.DeleteCohort, api, cohortId, { token });
    yield put(Cohorts.cohortsDeleteSuccess({ cohortId }));
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doCohortsDelete", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Cohorts.cohortsDeleteFailure());
    const { message, details } = errors || "";
    if (callbackError) {
      callbackError(message, details);
    }
  }
}

export default function* cohortsSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(Cohorts.COHORTS_FETCH_COHORTS, doCohortsFetchCohorts),
    takeLatest(Cohorts.COHORTS_CREATE, doCohortsCreate),
    takeLatest(Cohorts.COHORTS_EDIT_COHORT, doCohortsEditCohort),
    takeLatest(Cohorts.COHORTS_DELETE, doCohortsDelete),
  ]);
}
