import type { Config, tAPI } from "./API";
// API Programs static class

const apiBasePrograms = "/programs";
export default class Programs {
  // Get a list of programs
  static GetPrograms(api: tAPI, config?: Config) {
    return api.GET(`${apiBasePrograms}`, config);
  }
  // Create a Program
  static CreateProgram(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBasePrograms}`, data, config);
  }
  // Edit a Program
  static EditProgram(
    api: tAPI,
    programId: string,
    data: object,
    config?: Config
  ) {
    return api.PATCH(`${apiBasePrograms}/${programId}`, data, config);
  }
  // Delete a Program
  static DeleteProgram(api: tAPI, programId: string, config?: Config) {
    return api.DELETE(`${apiBasePrograms}/${programId}`, config);
  }
}
