import { createAction } from "deox";

// Actions for company

// Fetch Company
export const COMPANY_FETCH_COMPANY: string = "COMPANY/FETCH/COMPANY";
export const COMPANY_FETCH_COMPANY_REQUEST: string =
  "COMPANY/FETCH/COMPANY/REQUEST";
export const COMPANY_FETCH_COMPANY_SUCCESS: string =
  "COMPANY/FETCH/COMPANY/SUCCESS";
export const COMPANY_FETCH_COMPANY_FAILURE: string =
  "COMPANY/FETCH/COMPANY/FAILURE";
export const companyFetchCompany = createAction(
  COMPANY_FETCH_COMPANY,
  (resolve) => (payload?: object) => resolve(payload)
);
export const companyFetchCompanyRequest = createAction(
  COMPANY_FETCH_COMPANY_REQUEST
);
export const companyFetchCompanySuccess = createAction(
  COMPANY_FETCH_COMPANY_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const companyFetchCompanyFailure = createAction(
  COMPANY_FETCH_COMPANY_FAILURE
);
// Edit Company
export const COMPANY_EDIT_COMPANY: string = "COMPANY/EDIT/COMPANY";
export const COMPANY_EDIT_COMPANY_REQUEST: string =
  "COMPANY/EDIT/COMPANY/REQUEST";
export const COMPANY_EDIT_COMPANY_SUCCESS: string =
  "COMPANY/EDIT/COMPANY/SUCCESS";
export const COMPANY_EDIT_COMPANY_FAILURE: string =
  "COMPANY/EDIT/COMPANY/FAILURE";
export const companyEditCompany = createAction(
  COMPANY_EDIT_COMPANY,
  (resolve) => (payload?: object) => resolve(payload)
);
export const companyEditCompanyRequest = createAction(
  COMPANY_EDIT_COMPANY_REQUEST
);
export const companyEditCompanySuccess = createAction(
  COMPANY_EDIT_COMPANY_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const companyEditCompanyFailure = createAction(
  COMPANY_EDIT_COMPANY_FAILURE
);
// Fetch Company Files
export const COMPANY_FETCH_COMPANY_FILES: string =
  "COMPANY/FETCH/COMPANY_FILES";
export const COMPANY_FETCH_COMPANY_FILES_REQUEST: string =
  "COMPANY/FETCH/COMPANY_FILES/REQUEST";
export const COMPANY_FETCH_COMPANY_FILES_SUCCESS: string =
  "COMPANY/FETCH/COMPANY_FILES/SUCCESS";
export const COMPANY_FETCH_COMPANY_FILES_FAILURE: string =
  "COMPANY/FETCH/COMPANY_FILES/FAILURE";
export const companyFetchCompanyFiles = createAction(
  COMPANY_FETCH_COMPANY_FILES,
  (resolve) => (payload?: object) => resolve(payload)
);
export const companyFetchCompanyFilesRequest = createAction(
  COMPANY_FETCH_COMPANY_FILES_REQUEST
);
export const companyFetchCompanyFilesSuccess = createAction(
  COMPANY_FETCH_COMPANY_FILES_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const companyFetchCompanyFilesFailure = createAction(
  COMPANY_FETCH_COMPANY_FILES_FAILURE
);

// Fetch all Company Files Expired
export const COMPANY_FETCH_COMPANY_FILES_EXPIRED: string =
  "COMPANY/FETCH/COMPANY_FILES_EXPIRED";
export const COMPANY_FETCH_COMPANY_FILES_EXPIRED_REQUEST: string =
  "COMPANY/FETCH/COMPANY_FILES_EXPIRED/REQUEST";
export const COMPANY_FETCH_COMPANY_FILES_EXPIRED_SUCCESS: string =
  "COMPANY/FETCH/COMPANY_FILES_EXPIRED/SUCCESS";
export const COMPANY_FETCH_COMPANY_FILES_EXPIRED_FAILURE: string =
  "COMPANY/FETCH/COMPANY_FILES_EXPIRED/FAILURE";
export const companyFetchCompanyFilesExpired = createAction(
  COMPANY_FETCH_COMPANY_FILES_EXPIRED,
  (resolve) => (payload?: object) => resolve(payload)
);
export const companyFetchCompanyFilesExpiredRequest = createAction(
  COMPANY_FETCH_COMPANY_FILES_EXPIRED_REQUEST
);
export const companyFetchCompanyFilesExpiredSuccess = createAction(
  COMPANY_FETCH_COMPANY_FILES_EXPIRED_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const companyFetchCompanyFilesExpiredFailure = createAction(
  COMPANY_FETCH_COMPANY_FILES_EXPIRED_FAILURE
);
// Upload Company Files
export const COMPANY_UPLOAD_COMPANY_FILES: string =
  "COMPANY/UPLOAD/COMPANY_FILES";
export const COMPANY_UPLOAD_COMPANY_FILES_REQUEST: string =
  "COMPANY/UPLOAD/COMPANY_FILES/REQUEST";
export const COMPANY_UPLOAD_COMPANY_FILES_SUCCESS: string =
  "COMPANY/UPLOAD/COMPANY_FILES/SUCCESS";
export const COMPANY_UPLOAD_COMPANY_FILES_FAILURE: string =
  "COMPANY/UPLOAD/COMPANY_FILES/FAILURE";
export const companyUploadCompanyFiles = createAction(
  COMPANY_UPLOAD_COMPANY_FILES,
  (resolve) => (payload?: object) => resolve(payload)
);
export const companyUploadCompanyFilesRequest = createAction(
  COMPANY_UPLOAD_COMPANY_FILES_REQUEST
);
export const companyUploadCompanyFilesSuccess = createAction(
  COMPANY_UPLOAD_COMPANY_FILES_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const companyUploadCompanyFilesFailure = createAction(
  COMPANY_UPLOAD_COMPANY_FILES_FAILURE
);
