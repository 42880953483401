import { createAction } from "deox";

// Actions for Files
// Edit a file
export const FILES_EDIT: string = "FILES/EDIT";
export const FILES_EDIT_REQUEST: string = "FILES/EDIT/REQUEST";
export const FILES_EDIT_SUCCESS: string = "FILES/EDIT/SUCCESS";
export const FILES_EDIT_FAILURE: string = "FILES/EDIT/FAILURE";
export const filesEdit = createAction(
  FILES_EDIT,
  (resolve) => (payload?: object) => resolve(payload)
);
export const filesEditRequest = createAction(FILES_EDIT_REQUEST);
export const filesEditSuccess = createAction(
  FILES_EDIT_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const filesEditFailure = createAction(FILES_EDIT_FAILURE);
// Delete a file
export const FILES_DELETE: string = "FILES/DELETE";
export const FILES_DELETE_REQUEST: string = "FILES/DELETE/REQUEST";
export const FILES_DELETE_SUCCESS: string = "FILES/DELETE/SUCCESS";
export const FILES_DELETE_FAILURE: string = "FILES/DELETE/FAILURE";
export const filesDelete = createAction(
  FILES_DELETE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const filesDeleteRequest = createAction(FILES_DELETE_REQUEST);
export const filesDeleteSuccess = createAction(
  FILES_DELETE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const filesDeleteFailure = createAction(FILES_DELETE_FAILURE);
// Download a file
export const FILES_DOWNLOAD: string = "FILES/DOWNLOAD";
export const FILES_DOWNLOAD_REQUEST: string = "FILES/DOWNLOAD/REQUEST";
export const FILES_DOWNLOAD_SUCCESS: string = "FILES/DOWNLOAD/SUCCESS";
export const FILES_DOWNLOAD_FAILURE: string = "FILES/DOWNLOAD/FAILURE";
export const filesDownload = createAction(
  FILES_DOWNLOAD,
  (resolve) => (payload?: object) => resolve(payload)
);
export const filesDownloadRequest = createAction(FILES_DOWNLOAD_REQUEST);
export const filesDownloadSuccess = createAction(
  FILES_DOWNLOAD_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const filesDownloadFailure = createAction(FILES_DOWNLOAD_FAILURE);
// Preview a file
export const FILES_PREVIEW: string = "FILES/PREVIEW";
export const FILES_PREVIEW_REQUEST: string = "FILES/PREVIEW/REQUEST";
export const FILES_PREVIEW_SUCCESS: string = "FILES/PREVIEW/SUCCESS";
export const FILES_PREVIEW_FAILURE: string = "FILES/PREVIEW/FAILURE";
export const filesPreview = createAction(
  FILES_PREVIEW,
  (resolve) => (payload?: object) => resolve(payload)
);
export const filesPreviewRequest = createAction(FILES_PREVIEW_REQUEST);
export const filesPreviewSuccess = createAction(
  FILES_PREVIEW_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const filesPreviewFailure = createAction(FILES_PREVIEW_FAILURE);
