// Features
import * as React from "react";
import { onSetDateTime } from "../../../utils/index";
// Components
import NotificationsActions from "./NotificationsActions";
// Notifications To show a Date:
const NotificationsDate = ({
  createdAt,
  isMarkAsRead,
  notificationId,
}: React.PropsWithChildren<{
  createdAt: string;
  isMarkAsRead: boolean;
  notificationId: string;
}>) => (
  <div className="datetime">
    {onSetDateTime(createdAt)}
    <NotificationsActions
      isMarkAsRead={isMarkAsRead}
      notificationId={notificationId}
    />
  </div>
);
export default NotificationsDate;
