import type { Config, tAPI } from "./API";
// API Terms static class
const apiBaseTerms = "/terms";
export default class Terms {
  // Get a list of Terms
  static GetTerms(api: tAPI, config?: Config) {
    return api.GET(`${apiBaseTerms}`, config);
  }
  // Create a Term
  static CreateTerm(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBaseTerms}`, data, config);
  }
  // Edit a Term
  static EditTerm(api: tAPI, termId: string, data: object, config?: Config) {
    return api.PATCH(`${apiBaseTerms}/${termId}`, data, config);
  }
  // Delete a Term
  static DeleteTerm(api: tAPI, termId: string, config?: Config) {
    return api.DELETE(`${apiBaseTerms}/${termId}`, config);
  }
}
