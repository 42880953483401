import type { Config, tAPI } from "./API";
// API Portfolio static class

const apiBasePortfolio = "/portfolios";
export default class Portfolio {
  // Get Portfolio info
  static GetPorfolio(api: tAPI, userId: string, config?: Config) {
    return api.GET(`${apiBasePortfolio}/${userId}`, config);
  }
  // Edit Portfolio info
  static EditPortfolio(
    api: tAPI,
    userId: string,
    data: object,
    config?: Config
  ) {
    return api.PATCH(`${apiBasePortfolio}/${userId}`, data, config);
  }
  // Request Portfolio testimonials
  static RequestPortfolioTestimonials(
    api: tAPI,
    userId: string,
    data: object,
    config?: Config
  ) {
    return api.POST(`${apiBasePortfolio}/${userId}/testimonials`, data, config);
  }
  // Submit Portfolio testimonials
  static SubmitPortfolioTestimonials(
    api: tAPI,
    userId: string,
    institutionId: string,
    data: object,
    config?: Config
  ) {
    return api.PATCH(
      `${apiBasePortfolio}/${userId}/testimonials/${institutionId}`,
      data,
      config
    );
  }
  // Delete Portfolio testimonials
  static DeletePortfolioTestimonials(
    api: tAPI,
    userId: string,
    institutionId: string,
    config?: Config
  ) {
    return api.DELETE(
      `${apiBasePortfolio}/${userId}/testimonials/${institutionId}`,
      config
    );
  }
}
