// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { onCheckReadMyProfileUsersPermissions } from "../user-manager/UsersPermissions";
import {
  onCheckMyFilesPermissions,
  onCheckCreateMyFilesPermissions,
} from "../files/FilesPermissions";
import { Auth } from "../../store/actions/";
import ProtectedRoute from "../shared/ProtectedRoute";

const mapDispatchToProps = (dispatch: Function) => {
  dispatch(Auth.authUpdateMyUserAccount());
  return { dispatch };
};

const MyProfileRoute: React.ComponentType<any> = withRouter(
  connect(({ config, auth: { isAuthenticated, role } }, { path }) => {
    let isAllowedRole = false;
    if (
      path &&
      (path === "/my-profile" || path === "/my-profile/change-password") &&
      isAuthenticated &&
      onCheckReadMyProfileUsersPermissions(role.permissions)
    ) {
      isAllowedRole = true;
    } else if (
      path &&
      path === "/my-profile/portfolio" &&
      isAuthenticated &&
      onCheckReadMyProfileUsersPermissions(role.permissions)
    ) {
      isAllowedRole = true;
    } else if (
      path &&
      path === "/my-profile/documents" &&
      isAuthenticated &&
      onCheckReadMyProfileUsersPermissions(role.permissions) &&
      onCheckMyFilesPermissions(role.permissions)
    ) {
      isAllowedRole = true;
    } else if (
      path &&
      path === "/my-profile/documents/upload" &&
      isAuthenticated &&
      onCheckReadMyProfileUsersPermissions(role.permissions) &&
      onCheckCreateMyFilesPermissions(role.permissions)
    ) {
      isAllowedRole = true;
    }
    return {
      component: null,
      restProps: null,
      isAllowedRoute:
        config.companyDomain.length > 0 && isAuthenticated && isAllowedRole,
      redirectTo: "/home",
    };
  }, mapDispatchToProps)(ProtectedRoute)
);

export default MyProfileRoute;
