// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { onCheckReadMyInstitutionsPermissions } from "./InstitutionsPermissions";
import {
  onCheckMyInstitutionsFilesPermissions,
  onCheckCreateMyInstitutionsFilesPermissions,
} from "../files/FilesPermissions";
import { store } from "../../index";
import { Auth } from "../../store/actions/";
import ProtectedRoute from "../shared/ProtectedRoute";

const mapStateToProps = (
  { config, auth: { isAuthenticated, role, user } },
  { path }
) => {
  let isAllowedRole = false;
  if (
    path &&
    path === "/my-clinic" &&
    isAuthenticated &&
    onCheckReadMyInstitutionsPermissions(role.permissions)
  ) {
    isAllowedRole = true;
  } else if (
    path &&
    path === "/my-clinic/documents" &&
    isAuthenticated &&
    onCheckMyInstitutionsFilesPermissions(role.permissions)
  ) {
    isAllowedRole = true;
  } else if (
    path &&
    path === "/my-clinic/documents/upload" &&
    isAuthenticated &&
    onCheckCreateMyInstitutionsFilesPermissions(role.permissions)
  ) {
    isAllowedRole = true;
  }
  return {
    component: null,
    restProps: null,
    isAllowedRoute:
      config.companyDomain.length > 0 &&
      isAuthenticated &&
      isAllowedRole &&
      (user.institution || user.activeRotation) &&
      true,
    redirectTo: "/home",
  };
};
const mapDispatchToProps = (dispatch: Function) => {
  const { myInstitution } = store.getState().auth;
  if (myInstitution.loaded) {
    dispatch(
      Auth.authFetchMyInstitution({ institutionId: myInstitution.data._id })
    );
  }
  return { dispatch };
};
const MyInstitutionRoute: React.ComponentType<any> = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute)
);

export default MyInstitutionRoute;
