// Features
import * as React from "react";
// Components
import { Icon, Popup, Tab } from "semantic-ui-react";
import BroadcastMessagesList from "./broadcast-messages-list/BroadcastMessagesList";
import BroadcastSentMessage from "./broadcast-sent-message/BroadcastSentMessageContainer";
// Broadcast Messages:
const { useState } = React;
//
const BroadcastMessages = ({
  canCreateNewBroadcastMessages,
  ...props
}: React.PropsWithChildren<any>) => {
  // State
  const [showSendBroadCastMessages, setShowSendBroadCastMessages] =
    useState<boolean>(false);
  // RENDER
  const renderPaneHeader = () => (
    <div className="header text-left">
      <h3 className="title skejule-teal bold mb10">
        {showSendBroadCastMessages && "Send "}Broadcast Message
        {!showSendBroadCastMessages && "s"}
        {canCreateNewBroadcastMessages && (
          <Popup
            basic
            content={
              showSendBroadCastMessages
                ? "Go back to your broadcast messages"
                : "Send broadcast messages"
            }
            position="bottom center"
            trigger={
              <Icon
                className={showSendBroadCastMessages ? "" : "relative ml5"}
                link
                onClick={() =>
                  setShowSendBroadCastMessages(!showSendBroadCastMessages)
                }
                style={
                  showSendBroadCastMessages ? undefined : { bottom: "-1px" }
                }
                name={
                  showSendBroadCastMessages
                    ? "arrow alternate circle left"
                    : "plus circle"
                }
              />
            }
          />
        )}
      </h3>
      <div className="description skejule-gray">
        {showSendBroadCastMessages
          ? "Keep your users informed with broadcast messages"
          : "Keep informed with the broadcast messages"}
      </div>
    </div>
  );
  return (
    <Tab.Pane
      attached={false}
      className="pane broadcast-messages noborder noshadow nomargin skejule-scroll p30"
    >
      <i
        className="icono icono-close-border skejule-teal absolute mt10 mr10"
        data-icono="is-icono-close"
        style={{ cursor: "pointer", top: 0, right: 0 }}
      />
      {renderPaneHeader()}
      {!showSendBroadCastMessages && <BroadcastMessagesList />}
      {showSendBroadCastMessages && <BroadcastSentMessage {...props} />}
    </Tab.Pane>
  );
};
export default BroadcastMessages;
