// Access Control Reducer
import { AccessControl, Users } from "../actions/";
import { updateArrayWithElement } from "../../utils/";

export type ApiName =
  | "Alumnis"
  | "CompanyAdministrators"
  | "Coordinators"
  | "Faculties"
  | "InstitutionAdministrators"
  | "Preceptors"
  | "Students"
  | "SuperCompanyAdministrators";
export type DynamicRule = {
  _id: string;
  createdAt?: Date;
  updatedAt?: Date;
  user: Object;
  conditions: {
    programs: Array<string>;
    cohorts: Array<string>;
  };
};
export type ResourceAction = {
  resource: string;
  resourceName: string;
  actions: {
    alias: string;
    label: string;
    value: boolean;
    disabled: boolean;
  };
  apiValue: {
    key: boolean;
  };
};
export type Role = {
  _id: string;
  updatedAt: Date;
  apiName: ApiName;
  type: string;
  name: string;
  isActive: boolean;
  permissions: {
    //Active
    Companies: ResourceAction | undefined;
    CaseLogs: ResourceAction | undefined;
    Rotations: ResourceAction | undefined;
    Periods: ResourceAction | undefined;
    Users: ResourceAction | undefined;
    Institutions: ResourceAction | undefined;
    UserInvitations: ResourceAction | undefined;
    Queries: ResourceAction | undefined;
    Reports: ResourceAction | undefined;
    Files: ResourceAction | undefined;
    FileCategories: ResourceAction | undefined;
    Programs: ResourceAction | undefined;
    Enrollments: ResourceAction | undefined;
    Notifications: ResourceAction | undefined;
    CustomFields: ResourceAction | undefined;
    EvaluationTemplates: ResourceAction | undefined;
    EvaluationSchedulers: ResourceAction | undefined;
    Evaluations: ResourceAction | undefined;
    EvaluationAnswers: ResourceAction | undefined;
    ImportData: ResourceAction | undefined;
    Roles: ResourceAction | undefined;
  };
};
type State = {
  initAccessControl: boolean;
  isFetchingRoles: boolean;
  roles: Array<Role>;
  roleSelected: number;
  dynamicRules: Array<DynamicRule>;
};
type Action = {
  type: string;
  payload: object | any;
};

// The initial application state
const initialState = {
  initAccessControl: false,
  isFetchingRoles: false,
  roles: [],
  roleSelected: 0,
  dynamicRules: [],
};

export default function accessControl(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case AccessControl.ACCESS_CONTROL_INIT:
      return Object.assign({}, state, {
        initAccessControl: true,
      });
    // case AccessControl.ACCESS_CONTROL_FETCH_ROLES:
    case Users.USERS_FETCH_USERS_KPIS:
      return Object.assign({}, state, {
        isFetchingRoles: true,
      });
    case Users.USERS_FETCH_USERS_KPIS_SUCCESS:
    case Users.USERS_FETCH_USERS_KPIS_FAILURE:
      return Object.assign({}, state, {
        isFetchingRoles: false,
      });
    case AccessControl.ACCESS_CONTROL_FETCH_ROLES_SUCCESS:
      const rolesWithoutAlumnis = action.payload.roles.filter(
        ({ apiName }: any) => apiName !== "Alumnis"
      );
      const rolAlumnis = action.payload.roles.filter(
        ({ apiName }: any) => apiName === "Alumnis"
      );
      return Object.assign({}, state, {
        roles: [...rolesWithoutAlumnis, ...rolAlumnis],
      });
    case AccessControl.ACCESS_CONTROL_ROLE_SELECTED:
      return Object.assign({}, state, {
        roleSelected: action.payload.roleSelected,
      });
    case AccessControl.ACCESS_CONTROL_UPDATE_ROLE_RESOURCE_SUCCESS:
      const newArrayRoles = [...state.roles];
      newArrayRoles[state.roleSelected] = action.payload.role;
      return Object.assign({}, state, {
        roles: newArrayRoles,
      });
    //Dynamic Rules
    case AccessControl.ACCESS_CONTROL_FETCH_DYNAMIC_RULES_SUCCESS:
      return Object.assign({}, state, {
        dynamicRules: action.payload.dynamicRules,
      });
    case AccessControl.ACCESS_CONTROL_CREATE_DYNAMIC_RULE_SUCCESS:
      return Object.assign({}, state, {
        dynamicRules: [...state.dynamicRules, action.payload.dynamicRule],
      });
    case AccessControl.ACCESS_CONTROL_EDIT_DYNAMIC_RULE_SUCCESS:
      return Object.assign({}, state, {
        dynamicRules: updateArrayWithElement(
          state.dynamicRules,
          action.payload.dynamicRule
        ),
      });
    case AccessControl.ACCESS_CONTROL_DELETE_DYNAMIC_RULE_SUCCESS:
      return Object.assign({}, state, {
        dynamicRules: state.dynamicRules.filter(
          ({ _id }) => _id !== action.payload.dynamicRuleId
        ),
      });
    // current state
    default:
      return state;
  }
}
