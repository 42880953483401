import { put, select, call, all, takeLatest } from "redux-saga/effects";
import sortBy from "lodash/sortBy";
import PeriodsApi from "../api/Periods";
import { Auth, Periods } from "../actions/";
// Type
import type { Action } from "deox";
import type { tAPI } from "../api/API";
//Sagas for Periods
function* doPeriodsFetchPeriods(
  action: Action<
    string,
    {
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Periods.periodsFetchAllRequest());
  window.logger.log("doPeriodsFetchPeriods", action);
  const { callbackSuccess = null, callbackError = null } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get all periods for this company
    const { data } = yield call(PeriodsApi.GetPeriods, api, { token });
    const { periods } = data;
    const periodsSortByName = sortBy(periods, ["name"]).reverse();
    yield put(Periods.periodsFetchAllSuccess({ periods: periodsSortByName }));
    if (callbackSuccess) {
      callbackSuccess(periodsSortByName);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doPeriodsFetchPeriods", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Periods.periodsFetchAllFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}

function* doPeriodsCreate(
  action: Action<
    string,
    {
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Periods.periodsCreateRequest());
  window.logger.log("doPeriodsCreate", action);
  const { body, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to create a new period
    const { data } = yield call(PeriodsApi.CreatePeriod, api, body, { token });
    const { period } = data;
    yield put(Periods.periodsCreateSuccess({ period }));
    if (callbackSuccess) {
      callbackSuccess(period);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doPeriodsCreate", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Periods.periodsCreateFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doPeriodsEdit(
  action: Action<
    string,
    {
      periodId: string;
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Periods.periodsEditRequest());
  window.logger.log("doPeriodsEdit", action);
  const {
    periodId,
    body,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to edit a period for that specific periodId
    const { data } = yield call(PeriodsApi.EditPeriod, api, periodId, body, {
      token,
    });
    const { period } = data;
    yield put(Periods.periodsEditSuccess({ period }));
    if (callbackSuccess) {
      callbackSuccess(period);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doPeriodsEdit", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Periods.periodsEditFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doPeriodsDelete(
  action: Action<
    string,
    {
      periodId: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Periods.periodsDeleteRequest());
  window.logger.log("doPeriodsDelete", action);
  const {
    periodId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to delete a period for that specific periodId
    yield call(PeriodsApi.DeletePeriod, api, periodId, { token });
    yield put(Periods.periodsDeleteSuccess({ periodId }));
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doPeriodsDelete", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Periods.periodsDeleteFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

export default function* periodsSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(Periods.PERIODS_FETCH_ALL, doPeriodsFetchPeriods),
    takeLatest(Periods.PERIODS_CREATE, doPeriodsCreate),
    takeLatest(Periods.PERIODS_EDIT, doPeriodsEdit),
    takeLatest(Periods.PERIODS_DELETE, doPeriodsDelete),
  ]);
}
