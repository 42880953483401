// @flow
/* PERMISSIONS */
import {
  onCheckReadAllSuperCompanyAdministratorsUsersPermissions,
  onCheckReadAllCompanyAdministratorsUsersPermissions,
  onCheckReadAllCoordinatorsUsersPermissions,
  onCheckReadAllFacultiesUsersPermissions,
  onCheckReadBasicStudentsUsersPermissions,
  onCheckReadAllStudentsUsersPermissions,
  onCheckReadAllInstitutionAdministratorsUsersPermissions,
  onCheckReadAllPreceptorsUsersPermissions,
} from "../user-manager/UsersPermissions";
import { onCheckReadAllUserInvitationsPermissions } from "../user-invitation-manager/UserInvitationsPermissions";
import {
  onCheckCreateRotationsPermissions,
  onCheckReadPeriodsPermissions,
  onCheckEditRotationsPermissions,
} from "../rotations/RotationsPermissions";
import { onCheckReadInstitutionsPermissions } from "../institutions/InstitutionsPermissions";

export const onCheckUsersManagerPermissions = (
  permissions: ?Object
): boolean => {
  if (
    onCheckReadAllSuperCompanyAdministratorsUsersPermissions(permissions) ||
    onCheckReadAllCompanyAdministratorsUsersPermissions(permissions) ||
    onCheckReadAllCoordinatorsUsersPermissions(permissions) ||
    onCheckReadAllFacultiesUsersPermissions(permissions) ||
    onCheckReadBasicStudentsUsersPermissions(permissions) ||
    onCheckReadAllStudentsUsersPermissions(permissions) ||
    onCheckReadAllInstitutionAdministratorsUsersPermissions(permissions) ||
    onCheckReadAllPreceptorsUsersPermissions(permissions)
  ) {
    return true;
  }
  return false;
};
export const onCheckUserInvitationsManagerPermissions = (
  permissions: ?Object
): boolean => {
  return onCheckReadAllUserInvitationsPermissions(permissions);
};

export const onCheckUserManagerActionCreateEditRotationPermissions = (
  permissions: ?Object
): boolean => {
  return (
    (onCheckCreateRotationsPermissions(permissions) &&
      onCheckReadPeriodsPermissions(permissions) &&
      onCheckReadInstitutionsPermissions(permissions) &&
      onCheckReadAllStudentsUsersPermissions(permissions)) ||
    (onCheckEditRotationsPermissions(permissions) &&
      onCheckReadPeriodsPermissions(permissions))
  );
};
