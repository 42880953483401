//Heto Router
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Switch, useLocation } from "react-router-dom";
import { push } from "connected-react-router";
// Import Components
import Loader from "../components/shared/Loader";
import GuideLineTutorialContainer from "../components/guide-line-tutorial/GuideLineTutorialContainer";
import Menu from "../components/menu/MenuContainer";
import EmulateUser from "../components/emulate-user/EmulateUserContainer";
import FeedbackForm from "../components/feedback-form/FeedbackForm.container";
import RouteLoadedCompoundContainer from "../components/route-loaded/RouteLoadedCompoundContainer";
import RouteManagerCompoundContainer from "../components/manager/RouteManagerCompoundContainer";
import RedirectsRoute from "../components/redirects/RedirectsRoute";
import ManagerRoute from "../components/manager/ManagerRoute";
import HomeRoute from "../components/home/HomeRoute";
import MyProfileRoute from "../components/my-profile/MyProfileRoute";
import MyCompanyRoute from "../components/company/CompanyRoute";
import InstitutionsRoute from "../components/institutions/InstitutionsRoute";
import MyInstitutionRoute from "../components/institutions/MyInstitutionRoute";
import RotationsRoute from "../components/rotations/RotationsRoute";
import EvaluationsTemplatesRoute from "../components/evaluations-templates/EvaluationsTemplatesRoute";
import EvaluationsSchedulersRoute from "../components/evaluations-schedulers/EvaluationsSchedulersRoute";
import EvaluationsRoute from "../components/evaluations/EvaluationsRoute";
import CaseLogsRoute from "../components/case-logs/CaseLogsRoute";
import ReportsRoute from "../components/reports/ReportsRoute";
import AccountSettingsRoute from "../components/account-settings/AccountSettingsRoute";
import AdminPortalRoute from "../components/admin-portal/AdminPortalRoute";
import SignOutRoute from "../components/signout/SignOutRoute";
// Warning Messages
import BroadcastMessages from "../components/broadcast/broadcast-messages/BroadcastMessages.container";
import CameraWarning from "../components/camera/CameraWarning.container";
import FieldsWarningContainer from "../components/fields-warning/FieldsWarningContainer";
import FilesRequiredWarningContainer from "../components/files/files-required-warning/FilesRequiredWarningContainer";
import ReportsWarningContainer from "../components/reports/warning/ReportsWarningContainer";
import ServiceWorker from "../components/service-worker/ServiceWorkerContainer";
// Actions
import { Auth, CustomFields } from "../store/actions/";
// Types
import type { RootState } from "../store/reducers";
import type { StateImportData as State } from "../types/importData.types";
// ROUTER:
const { lazy, useEffect, Suspense } = React;
const ImportDataNotification = lazy(
  () =>
    import("../components/import/notification/ImportDataNotification.container")
);
// Import Routes Dynamic:
const AppRouter = ({ location }: any): any => [
  <EmulateUser key="heto-emulate-user" />,
  // @ts-ignore
  <BroadcastMessages key="heto-broadcast-messages" />,
  <Menu key="heto-menu" />,
  <FeedbackForm key="heto-feedback-form" />,
  <RouteLoadedCompoundContainer
    key="heto-route-loaded"
    path="/"
    location={location}
  />,
  <Switch key="heto-routes">
    {/* ROUTE REDIRECTS */}
    <RedirectsRoute path="/(home/)" exact />
    <RedirectsRoute
      path="/(manager/import-users/generate-csv/|manager/import-users/|manager/invitations/create/|manager/invitations/|manager/)"
      exact
    />
    <RedirectsRoute
      path="/(my-profile/portfolio/|my-profile/change-password/|my-profile/documents/upload/|my-profile/documents/|my-profile/)"
      exact
    />
    <RedirectsRoute
      path="/(my-school/documents/upload/|my-school/documents/|my-school/)"
      exact
    />
    <RedirectsRoute
      path="/(clinics/create/|clinics/|my-clinic/documents/upload/|my-clinic/documents/|my-clinic/)"
      exact
    />
    <RedirectsRoute
      path="/(rotations/schedulers/automatic/confirm/|rotations/schedulers/automatic/|rotations/schedulers/|rotations/create/|rotations/import/|rotations/import/generate-csv/|rotations/)"
      exact
    />
    <RedirectsRoute path="/(case-logs/create/|case-logs/)" exact />
    <RedirectsRoute
      path="/(evaluations/|evaluations/templates/|evaluations/templates/create/|evaluations/schedulers/create/|evaluations/schedulers/)"
      exact
    />
    <RedirectsRoute
      path="/(reports/dashboard/queries/|reports/dashboard/query/create/|reports/dashboard/custom-reports/|reports/dashboard/|reports/)"
      exact
    />
    <RedirectsRoute
      path="/(admin/programs-cohorts/|admin/documents-settings/|admin/custom-fields/|admin/access-control/|admin/case-logs-settings/|admin/evaluations-settings/|admin/rotations-settings/|admin/clinics-settings/|admin/broadcast-settings/|admin/notifications-settings/|admin/subscription/)"
      exact
    />
    {/* HOME */}
    <HomeRoute path="/home" exact />
    {/* ROUTE MyPROFILE */}
    <MyProfileRoute path="/my-profile" exact />
    <MyProfileRoute path="/my-profile/change-password" exact />
    <MyProfileRoute path="/my-profile/portfolio" exact />
    <MyProfileRoute path="/my-profile/documents" exact />
    <MyProfileRoute path="/my-profile/documents/upload" exact />
    {/* ROUTE MyCOMPANY */}
    <MyCompanyRoute path="/my-school" exact />
    <MyCompanyRoute path="/my-school/documents" exact />
    <MyCompanyRoute path="/my-school/documents/upload" exact />
    {/* ROUTE MANAGER */}
    <ManagerRoute path="/manager" exact />
    <ManagerRoute path="/manager/invitations" exact />
    <ManagerRoute path="/manager/invitations/create" exact />
    <ManagerRoute path="/manager/import-users" exact />
    <ManagerRoute path="/manager/import-users/generate-csv" exact />
    <ManagerRoute path="/manager/import-users/status" exact />
    <ManagerRoute path="/manager/profile/:userId(\w+)" exact />
    <ManagerRoute path="/manager/profile/:userId(\w+)/portfolio" exact />
    <ManagerRoute path="/manager/profile/:userId(\w+)/documents" exact />
    <ManagerRoute path="/manager/profile/:userId(\w+)/documents/upload" exact />
    {/* ROUTE INSTITUTIONS */}
    <InstitutionsRoute
      path="/clinics/:create_view?/:institutionId(\w+)?/:documents?/:upload?"
      exact
    />
    {/* ROUTE MyINSTITUTION */}
    <MyInstitutionRoute path="/my-clinic" exact />
    <MyInstitutionRoute path="/my-clinic/documents" exact />
    <MyInstitutionRoute path="/my-clinic/documents/upload" exact />
    {/* ROUTE ROTATIONS */}
    <RotationsRoute path="/rotations/import" exact />
    <RotationsRoute path="/rotations/import/generate-csv" exact />
    <RotationsRoute path="/rotations/import/status" exact />
    <RotationsRoute path="/rotations/schedulers" exact />
    <RotationsRoute path="/rotations/schedulers/automatic" exact />
    <RotationsRoute path="/rotations/schedulers/automatic/confirm" exact />
    <RotationsRoute
      path="/rotations/:create_edit_confirm?/:rotationId(\w+)?"
      exact
    />
    {/* ROUTE CASELOGS */}
    <CaseLogsRoute
      path="/case-logs/:create_edit_re_view?/:caseLogId(\w+)?"
      exact
    />
    <CaseLogsRoute path="/case-logs/view/:caseLogId(\w+)/pdf" exact />
    {/* ROUTE EVALUATIONS */}
    <EvaluationsTemplatesRoute
      path="/evaluations/templates/:create_edit_view?/:templateId(\w+)?"
      exact
    />
    <EvaluationsSchedulersRoute path="/evaluations/schedulers/create" exact />
    <EvaluationsRoute path="/evaluations/schedulers" exact />
    <EvaluationsRoute
      path="/evaluations/:evaluationId(\w+)?/:answers_review?/:answerId(\w+)?"
      exact
    />
    <EvaluationsRoute
      path="/evaluations/:evaluationId(\w+)/:answers/:answerId(\w+)/pdf-complete"
      exact
    />
    <EvaluationsRoute
      path="/evaluations/:evaluationId(\w+)/:answers/:answerId(\w+)/pdf"
      exact
    />
    {/* ROUTE REPORTS */}
    <ReportsRoute
      path="/reports/dashboard/query/:queryId(\w+)/result/:reportId(\w+)"
      exact
    />
    <ReportsRoute path="/reports/dashboard/query/:queryId(\w+)/results" exact />
    <ReportsRoute path="/reports/dashboard/query/:queryId(\w+)/edit" exact />
    <ReportsRoute path="/reports/dashboard/query/:queryId(\w+)/run" exact />
    <ReportsRoute path="/reports/dashboard/query/create" exact />
    <ReportsRoute path="/reports/dashboard/queries" exact />
    <ReportsRoute path="/reports/dashboard/custom-reports" exact />
    <ReportsRoute path="/reports/dashboard" exact />
    <ReportsRoute
      path="/reports/case/:standardId([1-9][0-9]*)/:reportId([a-zA-Z0-9]{24})"
      exact
    />
    <ReportsRoute path="/reports/case/:standardId([1-9][0-9]*)" exact />
    <ReportsRoute path="/reports" exact />
    {/* ROUTE ACCOUNT SETTINGS */}
    <AccountSettingsRoute path="/account-settings" exact />
    <AccountSettingsRoute path="/account-settings/general" exact />
    <AccountSettingsRoute path="/account-settings/home" exact />
    <AccountSettingsRoute path="/account-settings/users" exact />
    <AccountSettingsRoute path="/account-settings/rotations" exact />
    <AccountSettingsRoute path="/account-settings/case-logs" exact />
    <AccountSettingsRoute path="/account-settings/evaluations" exact />
    {/* ROUTE ADMIN PORTAL */}
    <AdminPortalRoute path="/admin" exact />
    <AdminPortalRoute path="/admin/general-settings" exact />
    <AdminPortalRoute path="/admin/programs-cohorts" exact />
    <AdminPortalRoute path="/admin/documents-settings" exact />
    <AdminPortalRoute path="/admin/custom-fields" exact />
    <AdminPortalRoute path="/admin/access-control" exact />
    <AdminPortalRoute path="/admin/case-logs-settings" exact />
    <AdminPortalRoute path="/admin/evaluations-settings" exact />
    <AdminPortalRoute path="/admin/rotations-settings" exact />
    <AdminPortalRoute path="/admin/clinics-settings" exact />
    <AdminPortalRoute path="/admin/broadcast-settings" exact />
    <AdminPortalRoute path="/admin/notifications-settings" exact />
    <AdminPortalRoute path="/admin/subscription" exact />
    {/* ROUTE SIGN OUT */}
    <SignOutRoute path="/signout" exact />
    <SignOutRoute path="/signout/session-expired" exact />
    <Redirect to="/home" />
  </Switch>,
  <RouteManagerCompoundContainer
    key="heto-user-manager"
    path="/"
    location={location}
  />,
];

const RouterApp = (): any => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { type: baseClass = "" } = useSelector(
    ({ auth }: RootState) => auth.role || { type: "" }
  );
  const {
    hasCustomFieldsRequiredNull,
    hasContactInfoAddressesFieldsRequiredNull,
    hasEmergencyContactFieldsRequiredNull,
  } = useSelector(
    ({
      auth: {
        hasCustomFieldsRequiredNull,
        hasContactInfoAddressesFieldsRequiredNull,
        hasEmergencyContactFieldsRequiredNull,
      },
    }: RootState) => ({
      hasCustomFieldsRequiredNull,
      hasContactInfoAddressesFieldsRequiredNull,
      hasEmergencyContactFieldsRequiredNull,
    })
  );
  const { toShow }: State["importDataNotification"] = useSelector(
    ({ importData }: RootState) => importData.importDataNotification
  );
  useEffect(() => {
    window.logger.log("RouterApp", location);
  });
  useEffect(() => {
    dispatch(
      Auth.authCheckSessionExpiredApp({
        goToSignOut: () =>
          dispatch(
            push({
              pathname: `/signout/session-expired`,
              state: {
                namePage: "Sign Out",
              },
            })
          ),
      })
    );
    // Contact Info Fields,
    // Addresses Fields,
    // Emergency Contac Fieldst,
    // Custom Fields Users
    // Required
    if (
      location.pathname !== "/signout" &&
      location.pathname !== "/signout/session-expired" &&
      (hasCustomFieldsRequiredNull ||
        hasContactInfoAddressesFieldsRequiredNull ||
        hasEmergencyContactFieldsRequiredNull) &&
      location.pathname !== "/my-profile"
    ) {
      dispatch(
        push({
          pathname: `/my-profile`,
          state: {
            namePage: "My Profile",
          },
        })
      );
      setTimeout(
        () => dispatch(CustomFields.customFieldsRequiredTriggerAnimation()),
        500
      );
    }
  }, [
    location.pathname,
    hasCustomFieldsRequiredNull,
    hasContactInfoAddressesFieldsRequiredNull,
    hasEmergencyContactFieldsRequiredNull,
    dispatch,
  ]);
  return (
    <main id="heto-app-website" className={baseClass}>
      <GuideLineTutorialContainer />
      <Suspense
        fallback={
          <Loader className="loading-component" textLoading="Loading ..." />
        }
      >
        <AppRouter location={location} />
        {toShow && (
          <ImportDataNotification
            key="import-data-notification"
            toShow={toShow}
          />
        )}
      </Suspense>
      {/* @ts-ignore */}
      <FieldsWarningContainer key="fields-required" />
      {/* @ts-ignore */}
      <FilesRequiredWarningContainer key="files-required" />
      <ReportsWarningContainer key="reports-warning-message" />
      <CameraWarning key="camera-warning-msg" />
      <ServiceWorker key="heto-button-service-worker" />
    </main>
  );
};
export default RouterApp;
