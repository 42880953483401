import { createAction } from "deox";

// Actions for Evaluations Schedulers

// Set evaluationSchedulersTab to Get propely evaluation schedulers list
export const EVALUATIONS_SCHEDULERS_SET_TAB_SELECTED: string =
  "EVALUATIONS_SCHEDULERS/SET/TAB_SELECTED";
export const evaluationsSchedulersSetTabSelected = createAction(
  EVALUATIONS_SCHEDULERS_SET_TAB_SELECTED,
  (resolve) => (payload?: object) => resolve(payload)
);
// Fetch all evaluations schedulers
export const EVALUATIONS_SCHEDULERS_FETCH_EVALUATIONS_SCHEDULERS: string =
  "EVALUATIONS_SCHEDULERS/FETCH/EVALUATIONS_SCHEDULERS";
export const EVALUATIONS_SCHEDULERS_FETCH_EVALUATIONS_SCHEDULERS_REQUEST: string =
  "EVALUATIONS_SCHEDULERS/FETCH/EVALUATIONS_SCHEDULERS/REQUEST";
export const EVALUATIONS_SCHEDULERS_FETCH_EVALUATIONS_SCHEDULERS_SUCCESS: string =
  "EVALUATIONS_SCHEDULERS/FETCH/EVALUATIONS_SCHEDULERS/SUCCESS";
export const EVALUATIONS_SCHEDULERS_FETCH_EVALUATIONS_SCHEDULERS_FAILURE: string =
  "EVALUATIONS_SCHEDULERS/FETCH/EVALUATIONS_SCHEDULERS/FAILURE";
export const evaluationsSchedulersFetchEvaluationsSchedulers = createAction(
  EVALUATIONS_SCHEDULERS_FETCH_EVALUATIONS_SCHEDULERS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsSchedulersFetchEvaluationsSchedulersRequest =
  createAction(EVALUATIONS_SCHEDULERS_FETCH_EVALUATIONS_SCHEDULERS_REQUEST);
export const evaluationsSchedulersFetchEvaluationsSchedulersSuccess =
  createAction(
    EVALUATIONS_SCHEDULERS_FETCH_EVALUATIONS_SCHEDULERS_SUCCESS,
    (resolve) => (payload?: object) => resolve(payload)
  );
export const evaluationsSchedulersFetchEvaluationsSchedulersFailure =
  createAction(EVALUATIONS_SCHEDULERS_FETCH_EVALUATIONS_SCHEDULERS_FAILURE);
// Create a Evaluation Schedulers
export const EVALUATIONS_SCHEDULERS_CREATE: string =
  "EVALUATIONS_SCHEDULERS/CREATE";
export const EVALUATIONS_SCHEDULERS_CREATE_REQUEST: string =
  "EVALUATIONS_SCHEDULERS/CREATE/REQUEST";
export const EVALUATIONS_SCHEDULERS_CREATE_SUCCESS: string =
  "EVALUATIONS_SCHEDULERS/CREATE/SUCCESS";
export const EVALUATIONS_SCHEDULERS_CREATE_FAILURE: string =
  "EVALUATIONS_SCHEDULERS/CREATE/FAILURE";
export const evaluationsSchedulersCreate = createAction(
  EVALUATIONS_SCHEDULERS_CREATE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsSchedulersCreateRequest = createAction(
  EVALUATIONS_SCHEDULERS_CREATE_REQUEST
);
export const evaluationsSchedulersCreateSuccess = createAction(
  EVALUATIONS_SCHEDULERS_CREATE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsSchedulersCreateFailure = createAction(
  EVALUATIONS_SCHEDULERS_CREATE_FAILURE
);
// Edit a Evaluation
export const EVALUATIONS_SCHEDULERS_EDIT: string =
  "EVALUATIONS_SCHEDULERS/EDIT";
export const EVALUATIONS_SCHEDULERS_EDIT_REQUEST: string =
  "EVALUATIONS_SCHEDULERS/EDIT/REQUEST";
export const EVALUATIONS_SCHEDULERS_EDIT_SUCCESS: string =
  "EVALUATIONS_SCHEDULERS/EDIT/SUCCESS";
export const EVALUATIONS_SCHEDULERS_EDIT_FAILURE: string =
  "EVALUATIONS_SCHEDULERS/EDIT/FAILURE";
export const evaluationsSchedulersEdit = createAction(
  EVALUATIONS_SCHEDULERS_EDIT,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsSchedulersEditRequest = createAction(
  EVALUATIONS_SCHEDULERS_EDIT_REQUEST
);
export const evaluationsSchedulersEditSuccess = createAction(
  EVALUATIONS_SCHEDULERS_EDIT_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsSchedulersEditFailure = createAction(
  EVALUATIONS_SCHEDULERS_EDIT_FAILURE
);
// Delete Evaluation
export const EVALUATIONS_SCHEDULERS_DELETE: string =
  "EVALUATIONS_SCHEDULERS/DELETE";
export const EVALUATIONS_SCHEDULERS_DELETE_REQUEST: string =
  "EVALUATIONS_SCHEDULERS/DELETE/REQUEST";
export const EVALUATIONS_SCHEDULERS_DELETE_SUCCESS: string =
  "EVALUATIONS_SCHEDULERS/DELETE/SUCCESS";
export const EVALUATIONS_SCHEDULERS_DELETE_FAILURE: string =
  "EVALUATIONS_SCHEDULERS/DELETE/FAILURE";
export const evaluationsSchedulersDelete = createAction(
  EVALUATIONS_SCHEDULERS_DELETE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsSchedulersDeleteRequest = createAction(
  EVALUATIONS_SCHEDULERS_DELETE_REQUEST
);
export const evaluationsSchedulersDeleteSuccess = createAction(
  EVALUATIONS_SCHEDULERS_DELETE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsSchedulersDeleteFailure = createAction(
  EVALUATIONS_SCHEDULERS_DELETE_FAILURE
);
// Set evaluationSchedulerId to Fetch this evaluation schedulers
export const EVALUATIONS_SCHEDULERS_SET_EVALUATION_SCHEDULER_ID: string =
  "EVALUATIONS_SCHEDULERS/SET/EVALUATION_SCHEDULER_ID";
export const evaluationsSchedulersSetEvaluationSchedulerId = createAction(
  EVALUATIONS_SCHEDULERS_SET_EVALUATION_SCHEDULER_ID,
  (resolve) => (payload?: object) => resolve(payload)
);
// Unset evaluationSchedulerId
export const EVALUATIONS_SCHEDULERS_UNSET_EVALUATION_SCHEDULER_ID: string =
  "EVALUATIONS_SCHEDULERS/UNSET/EVALUATION_SCHEDULER_ID";
export const evaluationsSchedulersUnsetEvaluationSchedulerId = createAction(
  EVALUATIONS_SCHEDULERS_UNSET_EVALUATION_SCHEDULER_ID,
  (resolve) => (payload?: object) => resolve(payload)
);
// Fetch a Evaluation
export const EVALUATIONS_SCHEDULERS_FETCH_EVALUATION_SCHEDULER: string =
  "EVALUATIONS_SCHEDULERS/FETCH/EVALUATION_SCHEDULER";
export const EVALUATIONS_SCHEDULERS_FETCH_EVALUATION_SCHEDULER_REQUEST: string =
  "EVALUATIONS_SCHEDULERS/FETCH/EVALUATION_SCHEDULER/REQUEST";
export const EVALUATIONS_SCHEDULERS_FETCH_EVALUATION_SCHEDULER_SUCCESS: string =
  "EVALUATIONS_SCHEDULERS/FETCH/EVALUATION_SCHEDULER/SUCCESS";
export const EVALUATIONS_SCHEDULERS_FETCH_EVALUATION_SCHEDULER_FAILURE: string =
  "EVALUATIONS_SCHEDULERS/FETCH/EVALUATION_SCHEDULER/FAILURE";
export const evaluationsSchedulersFetchEvaluationScheduler = createAction(
  EVALUATIONS_SCHEDULERS_FETCH_EVALUATION_SCHEDULER,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsSchedulersFetchEvaluationSchedulerRequest =
  createAction(EVALUATIONS_SCHEDULERS_FETCH_EVALUATION_SCHEDULER_REQUEST);
export const evaluationsSchedulersFetchEvaluationSchedulerSuccess =
  createAction(
    EVALUATIONS_SCHEDULERS_FETCH_EVALUATION_SCHEDULER_SUCCESS,
    (resolve) => (payload?: object) => resolve(payload)
  );
export const evaluationsSchedulersFetchEvaluationSchedulerFailure =
  createAction(EVALUATIONS_SCHEDULERS_FETCH_EVALUATION_SCHEDULER_FAILURE);
// Reset Evaluation Schedulers Loaded
export const EVALUATIONS_SCHEDULERS_RESET_EVALUATION_SCHEDULERS_LOADED: string =
  "EVALUATIONS_SCHEDULERS/RESET/EVALUATION_SCHEDULERS_LOADED";
export const evaluationsSchedulersResetEvaluationSchedulersLoaded =
  createAction(EVALUATIONS_SCHEDULERS_RESET_EVALUATION_SCHEDULERS_LOADED);

// Evaluation Schedulers Create:
export const EVALUATIONS_SCHEDULERS_EVALUATION_CREATE_UPDATE_STEP: string =
  "EVALUATIONS_SCHEDULERS/EVALUATION/CREATE/UPDATE_STEP";
export const evaluationsSchedulersEvaluationCreateUpdateStep = createAction(
  EVALUATIONS_SCHEDULERS_EVALUATION_CREATE_UPDATE_STEP,
  (resolve) => (payload?: object) => resolve(payload)
);
// Evaluation Schedulers Create Reset process
export const EVALUATIONS_SCHEDULERS_EVALUATION_CREATE_RESET: string =
  "EVALUATIONS_SCHEDULERS/EVALUATION/CREATE/RESET";
export const evaluationsSchedulersEvaluationCreateReset = createAction(
  EVALUATIONS_SCHEDULERS_EVALUATION_CREATE_RESET
);
