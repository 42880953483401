// @flow
import * as React from "react";
import { connect } from "react-redux";
import {
  onCheckReadProgramsPermissions,
  onCheckCUDProgramsPermissions,
  onCheckReadCohortsPermissions,
  onCheckCUDCohortsPermissions,
  onCheckReadFileCategoriesPermissions,
  onCheckCUDFileCategoriesPermissions,
  onCheckReadCustomFieldsPermissions,
  onCheckCUDCustomFieldsPermissions,
  onCheckReadRolesPermissions,
  onCheckCUDRolesPermissions,
  onCheckReadTenantSettingsPermissions,
  onCheckCUDTenantSettingsPermissions,
  onCheckEditTenantSettingsPermissions,
} from "./AdminPortalPermissions";
import { onCheckReadMyCompaniesPermissions } from "../company/CompaniesPermissions";
import {
  onCheckReadPeriodsPermissions,
  onCheckCUDPeriodsPermissions,
} from "../rotations/RotationsPermissions";
import { withRouter } from "react-router-dom";
import ProtectedRoute from "../shared/ProtectedRoute";

const AdminPortalRoute: React.ComponentType<any> = withRouter(
  connect(({ config, auth: { isAuthenticated, role } }, { path }) => {
    let isAllowedRoute = false;
    let redirectTo = "/home";
    if (isAuthenticated && role.permissions) {
      const authPermissions = role.permissions;
      switch (path) {
        case "/admin":
          if (
            role.type === "supercompanyadministrators" &&
            onCheckReadTenantSettingsPermissions(authPermissions)
          ) {
            redirectTo = "/admin/general-settings";
          } else if (
            (onCheckReadProgramsPermissions(authPermissions) &&
              onCheckCUDProgramsPermissions(authPermissions)) ||
            (onCheckReadCohortsPermissions(authPermissions) &&
              onCheckCUDCohortsPermissions(authPermissions))
          ) {
            redirectTo = "/admin/programs-cohorts";
          } else if (
            onCheckReadFileCategoriesPermissions(authPermissions) &&
            onCheckCUDFileCategoriesPermissions(authPermissions)
          ) {
            redirectTo = "/admin/documents-settings";
          } else if (
            onCheckReadCustomFieldsPermissions(authPermissions) &&
            onCheckCUDCustomFieldsPermissions(authPermissions)
          ) {
            redirectTo = "/admin/custom-fields";
          } else if (
            onCheckReadRolesPermissions(authPermissions) &&
            onCheckCUDRolesPermissions(authPermissions)
          ) {
            redirectTo = "/admin/access-control";
          } else if (
            onCheckReadTenantSettingsPermissions(authPermissions) &&
            onCheckCUDTenantSettingsPermissions(authPermissions)
          ) {
            redirectTo = "/admin/case-logs-settings";
          } else if (
            onCheckReadPeriodsPermissions(authPermissions) &&
            onCheckCUDPeriodsPermissions(authPermissions)
          ) {
            redirectTo = "/admin/rotations-settings";
          } else if (
            onCheckReadRolesPermissions(authPermissions) &&
            onCheckReadTenantSettingsPermissions(authPermissions) &&
            onCheckEditTenantSettingsPermissions(authPermissions)
          ) {
            redirectTo = "/admin/broadcast-settings";
          }
          break;
        case "/admin/general-settings":
          if (
            role.type === "supercompanyadministrators" &&
            onCheckReadTenantSettingsPermissions(authPermissions)
          ) {
            isAllowedRoute = true;
          }
          break;
        case "/admin/programs-cohorts":
          if (
            (onCheckReadProgramsPermissions(authPermissions) &&
              onCheckCUDProgramsPermissions(authPermissions)) ||
            (onCheckReadCohortsPermissions(authPermissions) &&
              onCheckCUDCohortsPermissions(authPermissions))
          ) {
            isAllowedRoute = true;
          }
          break;
        case "/admin/documents-settings":
          if (
            onCheckReadFileCategoriesPermissions(authPermissions) &&
            onCheckCUDFileCategoriesPermissions(authPermissions)
          ) {
            isAllowedRoute = true;
          }
          break;
        case "/admin/custom-fields":
          if (
            onCheckReadCustomFieldsPermissions(authPermissions) &&
            onCheckCUDCustomFieldsPermissions(authPermissions)
          ) {
            isAllowedRoute = true;
          }
          break;
        case "/admin/access-control":
          if (
            onCheckReadRolesPermissions(authPermissions) &&
            onCheckCUDRolesPermissions(authPermissions)
          ) {
            isAllowedRoute = true;
          }
          break;
        case "/admin/case-logs-settings":
        case "/admin/evaluations-settings":
        case "/admin/clinics-settings":
          if (
            onCheckReadTenantSettingsPermissions(authPermissions) &&
            onCheckCUDTenantSettingsPermissions(authPermissions)
          ) {
            isAllowedRoute = true;
          }
          break;
        case "/admin/rotations-settings":
          if (
            (onCheckReadTenantSettingsPermissions(authPermissions) &&
              onCheckCUDTenantSettingsPermissions(authPermissions)) ||
            (onCheckReadPeriodsPermissions(authPermissions) &&
              onCheckCUDPeriodsPermissions(authPermissions))
          ) {
            isAllowedRoute = true;
          }
          break;
        case "/admin/broadcast-settings":
        case "/admin/notifications-settings":
          if (
            onCheckReadRolesPermissions(authPermissions) &&
            onCheckReadTenantSettingsPermissions(authPermissions) &&
            onCheckEditTenantSettingsPermissions(authPermissions)
          ) {
            isAllowedRoute = true;
          }
          break;
        case "/admin/subscription":
          if (
            role.type === "supercompanyadministrators" &&
            onCheckReadTenantSettingsPermissions(authPermissions) &&
            onCheckReadMyCompaniesPermissions(authPermissions)
          ) {
            isAllowedRoute = true;
          }
          break;
        default:
      }
    }
    return {
      component: null,
      restProps: null,
      isAllowedRoute:
        config.companyDomain.length > 0 &&
        isAuthenticated &&
        role.permissions &&
        isAllowedRoute,
      redirectTo,
    };
  })(ProtectedRoute)
);

export default AdminPortalRoute;
