import { put, select, call, all, takeLatest } from "redux-saga/effects";
import NotificationsApi from "../api/Notifications";
import { Auth, Notifications } from "../actions/";
// Type
import type { Action } from "deox";
import type { tAPI } from "../api/API";
import type { State as StateNotifications } from "../../store/reducers/notifications";
//Sagas for Notifications
// SOCKET
function* doNotificationsRegisterSocket(
  action: Action<
    string,
    {
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Notifications.notificationsRegisterSocketRequest());
  window.logger.log("doNotificationsRegisterSocket", action);
  const { callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get a socket connection ID to start get notifications by socket
    const { data } = yield call(
      NotificationsApi.RegisterSocketConnectionID,
      api,
      { token }
    );
    const { socketConnectionId } = data;
    yield put(
      Notifications.notificationsRegisterSocketSuccess({ socketConnectionId })
    );
    yield put(Notifications.notificationsSetSocketConnectionId());
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doNotificationsRegisterSocket",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Notifications.notificationsRegisterSocketFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doNotificationsSetSocketConnectionId(action: Action<string>) {
  yield put(Notifications.notificationsSetSocketConnectionIdRequest());
  window.logger.log("doNotificationsSetSocketConnectionId", action);
  const userId: string = yield select(({ auth: { user } }) => user._id);
  const { socketConnectionId, socket } = yield select(
    ({ notifications: { socketConnectionId, socket } }) => ({
      socketConnectionId,
      socket,
    })
  );
  socket.connect(userId, socketConnectionId);
}

// NOTIFICATIONS
function* doNotificationsFetchNotifications(
  action: Action<
    string,
    {
      skip: number;
      limit: number;
      notificationType: "notifications" | "reminders" | "testimonial-request";
      notificationsFiltersByCategories: boolean;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  window.logger.log("doNotificationsFetchNotifications", action);
  try {
    const {
      skip = 0,
      limit = 20,
      notificationType = "notifications",
      notificationsFiltersByCategories = false,
      callbackSuccess = null,
    } = action.payload || {};
    yield put(
      Notifications.notificationsFetchNotificationsRequest({
        notificationsFiltersByCategories,
      })
    );
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    const userId: string = yield select(({ auth: { user } }) => user._id);
    const notificationsCategories: StateNotifications["notificationsCategories"] =
      yield select(
        ({ notifications: { notificationsCategories } }) =>
          notificationsCategories
      );
    // call the api to get all notifications for this company
    const { data } = yield call(
      NotificationsApi.GetNotifications,
      api,
      userId,
      notificationType,
      notificationsCategories,
      skip,
      limit,
      { token }
    );
    const { notifications, total, unseen } = data;
    yield put(
      Notifications.notificationsFetchNotificationsSuccess({
        notifications,
        notificationsFiltersByCategories,
        totalNotifications: total,
        unseenNotifications: unseen,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(notifications);
    }
  } catch (error: any) {
    const { callbackError = null } = action.payload || {};
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doNotificationsFetchNotifications",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Notifications.notificationsFetchNotificationsFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}

function* doNotificationsMarkAsReadNotifications(
  action: Action<
    string,
    {
      notificationId?: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  window.logger.log("doNotificationsMarkAsReadNotifications", action);
  try {
    yield put(Notifications.notificationsMarkAsReadNotificationsRequest());
    const { notificationId, callbackSuccess = null } = action.payload || {};
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    let body = {};
    if (notificationId) {
      body = {
        notifications: [notificationId],
      };
    } else {
      body = {
        markAll: true,
      };
      const notificationsCategories: StateNotifications["notificationsCategories"] =
        yield select(
          ({ notifications: { notificationsCategories } }) =>
            notificationsCategories
        );
      if (notificationsCategories.length > 0) {
        body = {
          notificationCategory: notificationsCategories
            .map((notificationCategory) => notificationCategory.split(","))
            .reduce((ac, v) => [...ac, ...v], []),
        };
      }
    }
    // call the api to mark as read a list of notifications
    const { data } = yield call(
      NotificationsApi.MarkAsReadNotifications,
      api,
      body,
      { token }
    );
    const { nNotificationsUpdated } = data;
    yield put(
      Notifications.notificationsMarkAsReadNotificationsSuccess({
        notificationId,
        nNotificationsUpdated,
      })
    );
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doNotificationsMarkAsReadNotifications",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Notifications.notificationsMarkAsReadNotificationsFailure());
    const { message } = errors || "";
    const { callbackError = null } = action.payload || {};
    if (callbackError) {
      callbackError(message);
    }
  }
}
function* doNotificationsLastSeenNotification(
  action: Action<
    string,
    {
      notificationId: string;
      body: object;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Notifications.notificationsLastSeenNotificationRequest());
  window.logger.log("doNotificationsLastSeenNotification", action);
  const {
    notificationId,
    body,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to update last seen notification
    const { data } = yield call(
      NotificationsApi.LastSeenNotification,
      api,
      body,
      { token }
    );
    const { nNotificationsUpdated } = data;
    yield put(
      Notifications.notificationsLastSeenNotificationSuccess({
        notificationId,
        nNotificationsUpdated,
      })
    );
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doNotificationsLastSeenNotification",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Notifications.notificationsLastSeenNotificationFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}
function* doNotificationsDelete(
  action: Action<
    string,
    {
      notificationId: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  window.logger.log("doNotificationsDelete", action);
  try {
    yield put(Notifications.notificationsDeleteRequest());
    const { notificationId, callbackSuccess = null } = action.payload || {};
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    let nNotificationsUpdated = 0;
    if (notificationId) {
      // call the api to delete a notification
      yield call(NotificationsApi.DeleteNotification, api, notificationId, {
        token,
      });
      nNotificationsUpdated = 1;
    } else {
      const notificationsCategories: StateNotifications["notificationsCategories"] =
        yield select(
          ({ notifications: { notificationsCategories } }) =>
            notificationsCategories
        );
      // call the api to delete a list of notifications
      const { data } = yield call(
        NotificationsApi.DeleteNotifications,
        api,
        notificationsCategories,
        { token }
      );
      ({ nNotificationsDeleted: nNotificationsUpdated } = data);
    }
    yield put(
      Notifications.notificationsDeleteSuccess({
        notificationId,
        nNotificationsUpdated,
      })
    );
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doNotificationsDelete", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Notifications.notificationsDeleteFailure());
    const { message } = errors || "";
    const { callbackError = null } = action.payload || {};
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doNotificationsCreateReminders(
  action: Action<
    string,
    {
      body: object;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Notifications.notificationsCreateRemindersRequest());
  window.logger.log("doNotificationsCreateReminders", action);
  const { body, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to create a new reminders notification
    yield call(NotificationsApi.CreateRemindersNotification, api, body, {
      token,
    });
    yield put(Notifications.notificationsCreateRemindersSuccess());
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doNotificationsCreateReminders",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Notifications.notificationsCreateRemindersFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

export default function* notificationsSagas(): Generator<any, any, any> {
  yield all([
    // SOCKET
    takeLatest(
      Notifications.NOTIFICATIONS_REGISTER_SOCKET,
      doNotificationsRegisterSocket
    ),
    takeLatest(
      Notifications.NOTIFICATIONS_SET_SOCKET_CONNECTION_ID,
      doNotificationsSetSocketConnectionId
    ),
    // NOTIFICATIONS
    takeLatest(
      Notifications.NOTIFICATIONS_FETCH_NOTIFICATIONS,
      doNotificationsFetchNotifications
    ),
    takeLatest(
      Notifications.NOTIFICATIONS_MARK_AS_READ_NOTIFICATIONS,
      doNotificationsMarkAsReadNotifications
    ),
    takeLatest(
      Notifications.NOTIFICATIONS_LAST_SEEN_NOTIFICATION,
      doNotificationsLastSeenNotification
    ),
    takeLatest(Notifications.NOTIFICATIONS_DELETE, doNotificationsDelete),
    takeLatest(
      Notifications.NOTIFICATIONS_CREATE_REMINDERS,
      doNotificationsCreateReminders
    ),
  ]);
}
