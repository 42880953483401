import type { Config, tAPI } from "./API";
// API Auth static class

const apiBaseAuth = "/users";
export default class Auth {
  // Get All My Accounts Available
  static GetMyAccounts(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBaseAuth}/accounts`, data, { ...config, tenant: "" });
  }
  static SwitchMyAccount(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBaseAuth}/switchAccount`, data, config);
  }
  // Sign in App or Update my user account from userId
  static GetMyUserAccount(api: tAPI, userId: string, config?: Config) {
    return api.GET(`${apiBaseAuth}/${userId}?populated=true`, config);
  }
  static GetMySettingsAccount(api: tAPI, userId: string, config?: Config) {
    return api.GET(`${apiBaseAuth}/${userId}/settings`, config);
  }
  static EditMySettingsAccount(
    api: tAPI,
    userId: string,
    data: object,
    config?: Config
  ) {
    return api.PATCH(`${apiBaseAuth}/${userId}/settings`, data, config);
  }
  // Get a my user/company/institution files from ownerId
  static GetMyFiles(
    api: tAPI,
    belongsTo: "Users" | "Companies" | "Institutions",
    ownerId: string,
    config?: Config
  ) {
    return api.GET(
      `/files?populated=true&belongsTo=${belongsTo}&owner=${ownerId}`,
      config
    );
  }
  // Get my institution for that institutionId
  static GetMyInstitution(api: tAPI, institutionId: string, config?: Config) {
    return api.GET(`/institutions/${institutionId}`, config);
  }
  // Sign out
  static SignOut(api: tAPI, config?: Config) {
    return api.GET(`${apiBaseAuth}/signout`, config);
  }
}
