import { put, select, call, all, takeLatest } from "redux-saga/effects";
import TermsApi from "../api/Terms";
import { Auth, Terms } from "../actions";
// Types
import type { Action } from "deox";
import type { tAPI } from "../api/API";
//Sagas for Terms
function* doTermsFetchAll(
  action: Action<
    string,
    {
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Terms.termsFetchAllRequest());
  window.logger.log("doTermsFetchAll", action);
  const { callbackSuccess = null, callbackError = null } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get all terms for this company
    const { data } = yield call(TermsApi.GetTerms, api, { token });
    const { terms } = data;
    yield put(Terms.termsFetchAllSuccess({ terms }));
    if (callbackSuccess) {
      callbackSuccess(terms);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doTermsFetchAll", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Terms.termsFetchAllFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}
function* doTermsCreate(
  action: Action<
    string,
    {
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Terms.termsCreateRequest());
  window.logger.log("doTermsCreate", action);
  const { body, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to create a new term
    const { data } = yield call(TermsApi.CreateTerm, api, body, { token });
    const { term } = data;
    yield put(Terms.termsCreateSuccess({ term }));
    if (callbackSuccess) {
      callbackSuccess(term);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doTermsCreate", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Terms.termsCreateFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}
function* doTermsEdit(
  action: Action<
    string,
    {
      termId: string;
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Terms.termsEditRequest());
  window.logger.log("doTermsEdit", action);
  const {
    termId,
    body,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to edit a term for that specific termId
    const { data } = yield call(TermsApi.EditTerm, api, termId, body, {
      token,
    });
    const { term } = data;
    yield put(Terms.termsEditSuccess({ term }));
    if (callbackSuccess) {
      callbackSuccess(term);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doTermsEdit", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Terms.termsEditFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}
function* doTermsDelete(
  action: Action<
    string,
    {
      termId: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Terms.termsDeleteRequest());
  window.logger.log("doTermsDelete", action);
  const {
    termId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to delete a term for that specific termId
    yield call(TermsApi.DeleteTerm, api, termId, { token });
    yield put(Terms.termsDeleteSuccess({ termId }));
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doTermsDelete", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Terms.termsDeleteFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}
export default function* termsSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(Terms.TERMS_FETCH_ALL, doTermsFetchAll),
    takeLatest(Terms.TERMS_CREATE, doTermsCreate),
    takeLatest(Terms.TERMS_EDIT, doTermsEdit),
    takeLatest(Terms.TERMS_DELETE, doTermsDelete),
  ]);
}
