// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  onCheckRotationsPermissions,
  onCheckCreateRotationsPermissions,
  onCheckEditRotationsPermissions,
  onCheckReadPeriodsPermissions,
} from "./RotationsPermissions";
import { onCheckReadInstitutionsPermissions } from "../institutions/InstitutionsPermissions";
import { onCheckReadAllStudentsUsersPermissions } from "../user-manager/UsersPermissions";
import { onCheckImportDataPermissions } from "../import/ImportDataPermissions";
import { store } from "../../index";
import { Rotations } from "../../store/actions/";
import ProtectedRoute from "../shared/ProtectedRoute";

const mapDispatchToProps = (dispatch: Function, ownProps: Object) => {
  const {
    computedMatch: {
      params: { rotationId },
    },
    location: { state },
  } = ownProps;
  const { rotationId: storedRotationId } = store.getState().rotations;
  if (rotationId && rotationId !== storedRotationId) {
    if (state && state.rotation) {
      dispatch(
        Rotations.rotationsFetchRotationSuccess({ rotation: state.rotation })
      );
    }
    dispatch(Rotations.rotationsSetRotationId({ rotationId }));
  }
  return { dispatch };
};
const RotationsRoute: React.ComponentType<any> = withRouter(
  connect(
    (
      {
        config: { companyDomain },
        auth: { isAuthenticated, role },
        rotations: {
          rotation,
          rotationCreateEdit: { canConfirmRotation },
        },
      },
      {
        path,
        computedMatch: {
          params: { create_edit_confirm, rotationId },
        },
      }
    ) => {
      let allowedRole =
        isAuthenticated &&
        (onCheckRotationsPermissions(role.permissions) ||
          onCheckImportDataPermissions(role.permissions));
      let redirectToAllowed = "/rotations";
      if (!allowedRole) {
        redirectToAllowed = "/home";
      }
      let allowedRotationsRoute = allowedRole;
      if (allowedRotationsRoute && create_edit_confirm) {
        if (
          isAuthenticated &&
          (onCheckCreateRotationsPermissions(role.permissions) ||
            onCheckEditRotationsPermissions(role.permissions))
        ) {
          if (
            create_edit_confirm === "create" ||
            create_edit_confirm === "edit" ||
            create_edit_confirm === "confirm"
          ) {
            if (create_edit_confirm === "create") {
              if (
                rotationId ||
                (!onCheckCreateRotationsPermissions(role.permissions) &&
                  !onCheckReadPeriodsPermissions(role.permissions) &&
                  !onCheckReadInstitutionsPermissions(role.permissions) &&
                  !onCheckReadAllStudentsUsersPermissions(role.permissions))
              ) {
                allowedRotationsRoute = false;
              }
            }
            if (create_edit_confirm === "edit") {
              if (
                !rotationId ||
                (!onCheckEditRotationsPermissions(role.permissions) &&
                  !onCheckReadPeriodsPermissions(role.permissions) &&
                  !onCheckReadInstitutionsPermissions(role.permissions) &&
                  !onCheckReadAllStudentsUsersPermissions(role.permissions))
              ) {
                allowedRotationsRoute = false;
              }
            }
            if (create_edit_confirm === "confirm" && !canConfirmRotation) {
              if (rotation && rotation._id) {
                redirectToAllowed = `/rotations/edit/${rotation._id}`;
              }
              allowedRotationsRoute = false;
            }
          } else {
            allowedRotationsRoute = false;
          }
        } else {
          allowedRotationsRoute = false;
        }
      }
      if (
        (path === "/rotations/import" ||
          path === "/rotations/import/generate-csv" ||
          path === "/rotations/import/status") &&
        isAuthenticated &&
        !onCheckImportDataPermissions(role.permissions)
      ) {
        allowedRole = false;
      }
      if (
        (path === "/rotations/schedulers" ||
          path === "/rotations/schedulers/automatic" ||
          path === "/rotations/schedulers/automatic/confirm") &&
        isAuthenticated &&
        !onCheckCreateRotationsPermissions(role.permissions)
      ) {
        allowedRole = false;
      }
      return {
        component: null,
        restProps: null,
        isAllowedRoute:
          companyDomain.length > 0 &&
          isAuthenticated &&
          allowedRole &&
          allowedRotationsRoute,
        redirectTo:
          companyDomain.length > 0 && isAuthenticated && allowedRole
            ? redirectToAllowed
            : "/home",
      };
    },
    mapDispatchToProps
  )(ProtectedRoute)
);

export default RotationsRoute;
