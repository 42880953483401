// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { onCheckReadMyCompaniesPermissions } from "./CompaniesPermissions";
import {
  onCheckMyCompaniesFilesPermissions,
  onCheckCreateMyCompaniesFilesPermissions,
} from "../files/FilesPermissions";
import ProtectedRoute from "../shared/ProtectedRoute";

const CompanyRoute: React.ComponentType<any> = withRouter(
  connect(({ config, auth: { isAuthenticated, role, user } }, { path }) => {
    let isAllowedRole = false;
    if (
      path &&
      path === "/my-school" &&
      isAuthenticated &&
      onCheckReadMyCompaniesPermissions(role.permissions)
    ) {
      isAllowedRole = true;
    } else if (
      path &&
      path === "/my-school/documents" &&
      isAuthenticated &&
      onCheckMyCompaniesFilesPermissions(role.permissions)
    ) {
      isAllowedRole = true;
    } else if (
      path &&
      path === "/my-school/documents/upload" &&
      isAuthenticated &&
      onCheckCreateMyCompaniesFilesPermissions(role.permissions)
    ) {
      isAllowedRole = true;
    }
    return {
      component: null,
      restProps: null,
      isAllowedRoute:
        config.companyDomain.length > 0 &&
        isAuthenticated &&
        isAllowedRole &&
        user.company &&
        true,
      redirectTo: "/home",
    };
  })(ProtectedRoute)
);

export default CompanyRoute;
