import type { Config, tAPI } from "./API";
// API Enrollments static class

const apiBaseEnrollments = "/enrollments";
export default class Enrollments {
  // STUDENTS ENROLLMENTS API
  // Get all student enrollments for userId
  static GetStudentEnrollment(
    api: tAPI,
    programs: string[],
    cohorts: string[],
    students: string[],
    config?: Config
  ) {
    let queryParams = "?populated=true";
    if (programs.length > 0) {
      queryParams += `&programs=${programs.join()}`;
    }
    if (cohorts.length > 0) {
      queryParams += `&cohorts=${cohorts.join()}`;
    }
    if (students.length > 0) {
      queryParams += `&students=${students.join()}`;
    }
    return api.GET(`${apiBaseEnrollments}${queryParams}`, config);
  }
  // Create a student enrollment for userId
  static CreateStudentEnrollment(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBaseEnrollments}`, data, config);
  }
  // Edit a student enrollment for userId from studentEnrollmentId
  static EditStudentEnrollment(
    api: tAPI,
    studentEnrollmentId: string,
    data: object,
    config?: Config
  ) {
    return api.PATCH(
      `${apiBaseEnrollments}/${studentEnrollmentId}`,
      data,
      config
    );
  }
  // Edit a student enrollment for userId from studentEnrollmentId
  static DeleteStudentEnrollment(
    api: tAPI,
    studentEnrollmentId: string,
    config?: Config
  ) {
    return api.DELETE(`${apiBaseEnrollments}/${studentEnrollmentId}`, config);
  }
}
