import { createAction } from "deox";
// Actions for Evaluations
// Set evaluationSchedulersTab to Get propely evaluation schedulers list
export const EVALUATIONS_SET_TAB_SELECTED: string =
  "EVALUATIONS_/SET/TAB_SELECTED";
export const evaluationsSetTabSelected = createAction(
  EVALUATIONS_SET_TAB_SELECTED,
  (resolve) => (payload?: object) => resolve(payload)
);
//////
// EVALUATIONS
//////
// Fetch all evaluations
export const EVALUATIONS_FETCH_ALL: string = "EVALUATIONS/FETCH/ALL";
export const EVALUATIONS_FETCH_ALL_REQUEST: string =
  "EVALUATIONS/FETCH/ALL/REQUEST";
export const EVALUATIONS_FETCH_ALL_SUCCESS: string =
  "EVALUATIONS/FETCH/ALL/SUCCESS";
export const EVALUATIONS_FETCH_ALL_FAILURE: string =
  "EVALUATIONS/FETCH/ALL/FAILURE";
export const evaluationsFetchAll = createAction(
  EVALUATIONS_FETCH_ALL,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsFetchAllRequest = createAction(
  EVALUATIONS_FETCH_ALL_REQUEST
);
export const evaluationsFetchAllSuccess = createAction(
  EVALUATIONS_FETCH_ALL_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsFetchAllFailure = createAction(
  EVALUATIONS_FETCH_ALL_FAILURE
);
// Fetch Evaluations by Filters
export const EVALUATIONS_FETCH_BY_FILTERS: string =
  "EVALUATIONS/FETCH/BY_FILTERS";
export const EVALUATIONS_FETCH_BY_FILTERS_REQUEST: string =
  "EVALUATIONS/FETCH/BY_FILTERS/REQUEST";
export const EVALUATIONS_FETCH_BY_FILTERS_SUCCESS: string =
  "EVALUATIONS/FETCH/BY_FILTERS/SUCCESS";
export const EVALUATIONS_FETCH_BY_FILTERS_FAILURE: string =
  "EVALUATIONS/FETCH/BY_FILTERS/FAILURE";
export const evaluationsFetchByFilters = createAction(
  EVALUATIONS_FETCH_BY_FILTERS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsFetchByFiltersRequest = createAction(
  EVALUATIONS_FETCH_BY_FILTERS_REQUEST
);
export const evaluationsFetchByFiltersSuccess = createAction(
  EVALUATIONS_FETCH_BY_FILTERS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsFetchByFiltersFailure = createAction(
  EVALUATIONS_FETCH_BY_FILTERS_FAILURE
);
// Fetch Evaluations KPIS
export const EVALUATIONS_FETCH_KPIS: string = "EVALUATIONS/FETCH/KPIS";
export const EVALUATIONS_FETCH_KPIS_REQUEST: string =
  "EVALUATIONS/FETCH/KPIS/REQUEST";
export const EVALUATIONS_FETCH_KPIS_SUCCESS: string =
  "EVALUATIONS/FETCH/KPIS/SUCCESS";
export const EVALUATIONS_FETCH_KPIS_FAILURE: string =
  "EVALUATIONS/FETCH/KPIS/FAILURE";
export const evaluationsFetchKpis = createAction(
  EVALUATIONS_FETCH_KPIS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsFetchKpisRequest = createAction(
  EVALUATIONS_FETCH_KPIS_REQUEST
);
export const evaluationsFetchKpisSuccess = createAction(
  EVALUATIONS_FETCH_KPIS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsFetchKpisFailure = createAction(
  EVALUATIONS_FETCH_KPIS_FAILURE,
  (resolve) => (payload?: object) => resolve(payload)
);
// Fetch Evaluations Answers KPIS
export const EVALUATIONS_ANSWERS_FETCH_KPIS: string =
  "EVALUATIONS/ANSWERS/FETCH/KPIS";
export const EVALUATIONS_ANSWERS_FETCH_KPIS_REQUEST: string =
  "EVALUATIONS/ANSWERS/FETCH/KPIS/REQUEST";
export const EVALUATIONS_ANSWERS_FETCH_KPIS_SUCCESS: string =
  "EVALUATIONS/ANSWERS/FETCH/KPIS/SUCCESS";
export const EVALUATIONS_ANSWERS_FETCH_KPIS_FAILURE: string =
  "EVALUATIONS/ANSWERS/FETCH/KPIS/FAILURE";
export const evaluationsAnswersFetchKpis = createAction(
  EVALUATIONS_ANSWERS_FETCH_KPIS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsAnswersFetchKpisRequest = createAction(
  EVALUATIONS_ANSWERS_FETCH_KPIS_REQUEST
);
export const evaluationsAnswersFetchKpisSuccess = createAction(
  EVALUATIONS_ANSWERS_FETCH_KPIS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsAnswersFetchKpisFailure = createAction(
  EVALUATIONS_ANSWERS_FETCH_KPIS_FAILURE,
  (resolve) => (payload?: object) => resolve(payload)
);
// Set evaluationId to Fetch this evaluation
export const EVALUATIONS_SET_EVALUATION_ID: string =
  "EVALUATIONS/SET/EVALUATION_ID";
export const evaluationsSetEvaluationId = createAction(
  EVALUATIONS_SET_EVALUATION_ID,
  (resolve) => (payload?: object) => resolve(payload)
);
// Unset evaluationId
export const EVALUATIONS_UNSET_EVALUATION_ID: string =
  "EVALUATIONS/UNSET/EVALUATION_ID";
export const evaluationsUnsetEvaluationId = createAction(
  EVALUATIONS_UNSET_EVALUATION_ID,
  (resolve) => (payload?: object) => resolve(payload)
);
// Fetch a Evaluation
export const EVALUATIONS_FETCH_EVALUATION: string =
  "EVALUATIONS/FETCH/EVALUATION";
export const EVALUATIONS_FETCH_EVALUATION_REQUEST: string =
  "EVALUATIONS/FETCH/EVALUATION/REQUEST";
export const EVALUATIONS_FETCH_EVALUATION_SUCCESS: string =
  "EVALUATIONS/FETCH/EVALUATION/SUCCESS";
export const EVALUATIONS_FETCH_EVALUATION_FAILURE: string =
  "EVALUATIONS/FETCH/EVALUATION/FAILURE";
export const evaluationsFetchEvaluation = createAction(
  EVALUATIONS_FETCH_EVALUATION,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsFetchEvaluationRequest = createAction(
  EVALUATIONS_FETCH_EVALUATION_REQUEST
);
export const evaluationsFetchEvaluationSuccess = createAction(
  EVALUATIONS_FETCH_EVALUATION_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsFetchEvaluationFailure = createAction(
  EVALUATIONS_FETCH_EVALUATION_FAILURE
);
// Create a Evaluation
export const EVALUATIONS_CREATE: string = "EVALUATIONS/CREATE";
export const EVALUATIONS_CREATE_REQUEST: string = "EVALUATIONS/CREATE/REQUEST";
export const EVALUATIONS_CREATE_SUCCESS: string = "EVALUATIONS/CREATE/SUCCESS";
export const EVALUATIONS_CREATE_FAILURE: string = "EVALUATIONS/CREATE/FAILURE";
export const evaluationsCreate = createAction(
  EVALUATIONS_CREATE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsCreateRequest = createAction(
  EVALUATIONS_CREATE_REQUEST
);
export const evaluationsCreateSuccess = createAction(
  EVALUATIONS_CREATE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsCreateFailure = createAction(
  EVALUATIONS_CREATE_FAILURE
);
// Edit a Evaluation
export const EVALUATIONS_EDIT: string = "EVALUATIONS/EDIT";
export const EVALUATIONS_EDIT_REQUEST: string = "EVALUATIONS/EDIT/REQUEST";
export const EVALUATIONS_EDIT_SUCCESS: string = "EVALUATIONS/EDIT/SUCCESS";
export const EVALUATIONS_EDIT_FAILURE: string = "EVALUATIONS/EDIT/FAILURE";
export const evaluationsEdit = createAction(
  EVALUATIONS_EDIT,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsEditRequest = createAction(EVALUATIONS_EDIT_REQUEST);
export const evaluationsEditSuccess = createAction(
  EVALUATIONS_EDIT_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsEditFailure = createAction(EVALUATIONS_EDIT_FAILURE);
// Delete Evaluation
export const EVALUATIONS_DELETE: string = "EVALUATIONS/DELETE";
export const EVALUATIONS_DELETE_REQUEST: string = "EVALUATIONS/DELETE/REQUEST";
export const EVALUATIONS_DELETE_SUCCESS: string = "EVALUATIONS/DELETE/SUCCESS";
export const EVALUATIONS_DELETE_FAILURE: string = "EVALUATIONS/DELETE/FAILURE";
export const evaluationsDelete = createAction(
  EVALUATIONS_DELETE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsDeleteRequest = createAction(
  EVALUATIONS_DELETE_REQUEST
);
export const evaluationsDeleteSuccess = createAction(
  EVALUATIONS_DELETE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsDeleteFailure = createAction(
  EVALUATIONS_DELETE_FAILURE
);
//////
// EVALUATIONS > ANSWERS
//////
// Fetch all Evaluation Answers
export const EVALUATIONS_FETCH_ANSWERS: string = "EVALUATIONS/FETCH/ANSWERS";
export const EVALUATIONS_FETCH_ANSWERS_REQUEST: string =
  "EVALUATIONS/FETCH/ANSWERS/REQUEST";
export const EVALUATIONS_FETCH_ANSWERS_SUCCESS: string =
  "EVALUATIONS/FETCH/ANSWERS/SUCCESS";
export const EVALUATIONS_FETCH_ANSWERS_FAILURE: string =
  "EVALUATIONS/FETCH/ANSWERS/FAILURE";
export const evaluationsFetchAnswers = createAction(
  EVALUATIONS_FETCH_ANSWERS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsFetchAnswersRequest = createAction(
  EVALUATIONS_FETCH_ANSWERS_REQUEST
);
export const evaluationsFetchAnswersSuccess = createAction(
  EVALUATIONS_FETCH_ANSWERS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsFetchAnswersFailure = createAction(
  EVALUATIONS_FETCH_ANSWERS_FAILURE
);
// Fetch all Evaluation Answers from some specific evaluationId
export const EVALUATIONS_FETCH_EVALUATION_ANSWERS: string =
  "EVALUATIONS/FETCH/EVALUATION_ANSWERS";
export const EVALUATIONS_FETCH_EVALUATION_ANSWERS_REQUEST: string =
  "EVALUATIONS/FETCH/EVALUATION_ANSWERS/REQUEST";
export const EVALUATIONS_FETCH_EVALUATION_ANSWERS_SUCCESS: string =
  "EVALUATIONS/FETCH/EVALUATION_ANSWERS/SUCCESS";
export const EVALUATIONS_FETCH_EVALUATION_ANSWERS_FAILURE: string =
  "EVALUATIONS/FETCH/EVALUATION_ANSWERS/FAILURE";
export const evaluationsFetchEvaluationAnswers = createAction(
  EVALUATIONS_FETCH_EVALUATION_ANSWERS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsFetchEvaluationAnswersRequest = createAction(
  EVALUATIONS_FETCH_EVALUATION_ANSWERS_REQUEST
);
export const evaluationsFetchEvaluationAnswersSuccess = createAction(
  EVALUATIONS_FETCH_EVALUATION_ANSWERS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsFetchEvaluationAnswersFailure = createAction(
  EVALUATIONS_FETCH_EVALUATION_ANSWERS_FAILURE
);
// Set answerId to Fetch only one answer from some evaluation
export const EVALUATIONS_SET_ANSWER_ID: string = "EVALUATIONS/SET/ANSWER_ID";
export const evaluationsSetAnswerId = createAction(
  EVALUATIONS_SET_ANSWER_ID,
  (resolve) => (payload?: object) => resolve(payload)
);
// Unset answerId
export const EVALUATIONS_UNSET_ANSWER_ID: string =
  "EVALUATIONS/UNSET/ANSWER_ID";
export const evaluationsUnsetAnswerId = createAction(
  EVALUATIONS_UNSET_ANSWER_ID,
  (resolve) => (payload?: object) => resolve(payload)
);
// Fetch only one answer from some evaluation
export const EVALUATIONS_FETCH_EVALUATION_ANSWER: string =
  "EVALUATIONS/FETCH/EVALUATION_ANSWER";
export const EVALUATIONS_FETCH_EVALUATION_ANSWER_REQUEST: string =
  "EVALUATIONS/FETCH/EVALUATION_ANSWER/REQUEST";
export const EVALUATIONS_FETCH_EVALUATION_ANSWER_SUCCESS: string =
  "EVALUATIONS/FETCH/EVALUATION_ANSWER/SUCCESS";
export const EVALUATIONS_FETCH_EVALUATION_ANSWER_FAILURE: string =
  "EVALUATIONS/FETCH/EVALUATION_ANSWER/FAILURE";
export const evaluationsFetchEvaluationAnswer = createAction(
  EVALUATIONS_FETCH_EVALUATION_ANSWER,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsFetchEvaluationAnswerRequest = createAction(
  EVALUATIONS_FETCH_EVALUATION_ANSWER_REQUEST
);
export const evaluationsFetchEvaluationAnswerSuccess = createAction(
  EVALUATIONS_FETCH_EVALUATION_ANSWER_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsFetchEvaluationAnswerFailure = createAction(
  EVALUATIONS_FETCH_EVALUATION_ANSWER_FAILURE
);
// Reset Answer Loaded
export const EVALUATIONS_RESET_ANSWER_LOADED: string =
  "EVALUATIONS/RESET/ANSWER_LOADED";
export const evaluationsResetAnswerLoaded = createAction(
  EVALUATIONS_RESET_ANSWER_LOADED
);
// Response some evaluation
export const EVALUATIONS_RESPONSE_EVALUATION_ANSWERS: string =
  "EVALUATIONS/RESPONSE/EVALUATION_ANSWERS";
export const EVALUATIONS_RESPONSE_EVALUATION_ANSWERS_REQUEST: string =
  "EVALUATIONS/RESPONSE/EVALUATION_ANSWERS/REQUEST";
export const EVALUATIONS_RESPONSE_EVALUATION_ANSWERS_SUCCESS: string =
  "EVALUATIONS/RESPONSE/EVALUATION_ANSWERS/SUCCESS";
export const EVALUATIONS_RESPONSE_EVALUATION_ANSWERS_FAILURE: string =
  "EVALUATIONS/RESPONSE/EVALUATION_ANSWERS/FAILURE";
export const evaluationsResponseEvaluationAnswers = createAction(
  EVALUATIONS_RESPONSE_EVALUATION_ANSWERS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsResponseEvaluationAnswersRequest = createAction(
  EVALUATIONS_RESPONSE_EVALUATION_ANSWERS_REQUEST
);
export const evaluationsResponseEvaluationAnswersSuccess = createAction(
  EVALUATIONS_RESPONSE_EVALUATION_ANSWERS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsResponseEvaluationAnswersFailure = createAction(
  EVALUATIONS_RESPONSE_EVALUATION_ANSWERS_FAILURE
);
// Reset Evaluation Loaded
export const EVALUATIONS_RESET_EVALUATION_LOADED: string =
  "EVALUATIONS/RESET/EVALUATION_LOADED";
export const evaluationsResetEvaluationLoaded = createAction(
  EVALUATIONS_RESET_EVALUATION_LOADED
);
// Delete Evaluation Answer
export const EVALUATIONS_DELETE_EVALUATION_ANSWER: string =
  "EVALUATIONS/DELETE/EVALUATION_ANSWER";
export const EVALUATIONS_DELETE_EVALUATION_ANSWER_REQUEST: string =
  "EVALUATIONS/DELETE/EVALUATION_ANSWER/REQUEST";
export const EVALUATIONS_DELETE_EVALUATION_ANSWER_SUCCESS: string =
  "EVALUATIONS/DELETE/EVALUATION_ANSWER/SUCCESS";
export const EVALUATIONS_DELETE_EVALUATION_ANSWER_FAILURE: string =
  "EVALUATIONS/DELETE/EVALUATION_ANSWER/FAILURE";
export const evaluationsDeleteEvaluationAnswer = createAction(
  EVALUATIONS_DELETE_EVALUATION_ANSWER,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsDeleteEvaluationAnswerRequest = createAction(
  EVALUATIONS_DELETE_EVALUATION_ANSWER_REQUEST
);
export const evaluationsDeleteEvaluationAnswerSuccess = createAction(
  EVALUATIONS_DELETE_EVALUATION_ANSWER_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsDeleteEvaluationAnswerFailure = createAction(
  EVALUATIONS_DELETE_EVALUATION_ANSWER_FAILURE
);
