import { put, select, call, all, takeLatest } from "redux-saga/effects";
import CompanyApi from "../api/Company";
import FileCategoriesApi from "../api/FileCategories";
import { Auth, Company } from "../actions/";
// Type
import type { Action } from "deox";
import type { tAPI } from "../api/API";
//Sagas for company
function* doCompanyFetchCompany(
  action: Action<
    string,
    {
      companyId: string;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Company.companyFetchCompanyRequest());
  window.logger.log("doCompanyFetchCompany", action);
  const { companyId } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get my company
    const { data } = yield call(CompanyApi.GetCompany, api, companyId, {
      token,
    });
    const { company } = data;
    yield put(Company.companyFetchCompanySuccess({ myCompany: company }));
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doCompanyFetchCompany", error, error);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Company.companyFetchCompanyFailure());
  }
}

function* doCompanyEditCompany(
  action: Action<
    string,
    {
      companyId: string;
      body: object;
      isPostFile?: boolean;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Company.companyEditCompanyRequest());
  window.logger.log("doCompanyEditCompany", action);
  const {
    companyId,
    body,
    isPostFile: isUpload = false,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to update the company profile
    const { data } = yield call(
      CompanyApi.EditCompany,
      api,
      companyId,
      body,
      isUpload,
      { token }
    );
    const { company } = data;
    yield put(Company.companyEditCompanySuccess({ myCompany: company }));
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doCompanyEditCompany", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Company.companyEditCompanyFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doCompanyFetchCompanyFiles(
  action: Action<
    string,
    {
      id: string;
      callbackSuccess?: (data: any, extradata: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Company.companyFetchCompanyFilesRequest());
  window.logger.log("doCompanyFetchCompanyFiles", action);
  const { id, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get all files from this company from specific id
    const { data: dataFileCategories } = yield call(
      FileCategoriesApi.GetFileCategories,
      api,
      { token }
    );
    const { categories: fileCategories } = dataFileCategories;
    const { data } = yield call(CompanyApi.GetCompanyFiles, api, id, { token });
    const { files } = data;
    yield put(Company.companyFetchCompanyFilesSuccess({ files }));
    if (callbackSuccess) {
      callbackSuccess(files, fileCategories);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doCompanyFetchCompanyFiles",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Company.companyFetchCompanyFilesFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doCompanyFetchCompanyFilesExpired(
  action: Action<
    string,
    {
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Company.companyFetchCompanyFilesExpiredRequest());
  window.logger.log("doCompanyFetchCompanyFilesExpired", action);
  const { callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    const { data } = yield call(CompanyApi.GetCompanyFilesExpired, api, {
      token,
    });
    const { files: filesExpired } = data;
    yield put(Company.companyFetchCompanyFilesExpiredSuccess({ filesExpired }));
    if (callbackSuccess) {
      callbackSuccess(filesExpired);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doCompanyFetchCompanyFilesExpired",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Company.companyFetchCompanyFilesExpiredFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doCompanyUploadCompanyFiles(
  action: Action<
    string,
    {
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Company.companyUploadCompanyFilesRequest());
  window.logger.log("doCompanyUploadCompanyFiles", action);
  const { body, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to upload all files for this company
    const { data } = yield call(CompanyApi.UploadCompanyFiles, api, body, {
      token,
    });
    const { files } = data;
    yield put(Company.companyUploadCompanyFilesSuccess());
    if (callbackSuccess) {
      callbackSuccess(files);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doCompanyUploadCompanyFiles",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Company.companyUploadCompanyFilesFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

export default function* companySagas(): Generator<any, any, any> {
  yield all([
    takeLatest(Company.COMPANY_FETCH_COMPANY, doCompanyFetchCompany),
    takeLatest(Company.COMPANY_EDIT_COMPANY, doCompanyEditCompany),
    takeLatest(Company.COMPANY_FETCH_COMPANY_FILES, doCompanyFetchCompanyFiles),
    takeLatest(
      Company.COMPANY_FETCH_COMPANY_FILES_EXPIRED,
      doCompanyFetchCompanyFilesExpired
    ),
    takeLatest(
      Company.COMPANY_UPLOAD_COMPANY_FILES,
      doCompanyUploadCompanyFiles
    ),
  ]);
}
