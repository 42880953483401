// @flow
// Import Features
import * as React from "react";
// Import Components
import { Message, Transition } from "semantic-ui-react";
// Import Types
import type { PropsReportsWarningPresentational as Props } from "../../../types/reports.types";
class ReportsWarningPresentational extends React.Component<Props> {
  render() {
    const styles = {
      zIndex: "9999",
      position: "absolute",
      bottom: "10px",
      right: "5px",
    };
    const { showWarning } = this.props;
    return (
      <Transition animation="shake" duration={500} visible={showWarning}>
        <Message positive className="ml100 mr20" style={styles}>
          <Message.Header>There are new reports generated.</Message.Header>
        </Message>
      </Transition>
    );
  }
}
export default ReportsWarningPresentational;
