// Features
import produce from "immer";
import { Reports } from "../actions/";
import {
  isDevelopmentReactAppEnv,
  sortBy,
  updateArrayWithElement,
} from "../../utils/";
// Types
import type { Action } from "deox";
import type { ReportStandardProps } from "../../components/reports/standard/_ReportsStandard.types";
import type { PayloadObject } from "../../types/common.types";
import type { StateReports as State } from "../../types/reports.types";
type ReportsActionReducers = Action<
  string,
  {
    data: State["queryCreateEdit"];
    hasToShowReportsWarning: State["reportsShowWarning"];
    queries: State["queries"];
    query: PayloadObject;
    queryId: State["queryId"];
    queryParams: State["queryCreateEdit"]["queryParams"];
    report: PayloadObject;
    reports: State["reports"];
    reportId: State["reportId"];
  }
>;
// Reports and Queries Reducer
export const QUERIES_STANDARD_PREFIX = "reports_standard_report_";
const reportsStandardNames: State["queriesStandard"] = [
  // ALL TENANTS
  "Student Report",
  "Axium Transfer",
  "Case Logs - Procedures",
  "Case Logs - Unique Patient",
  "Case Logs - Patient Visits",
  "Site Report - Procedure Counts",
  "Site Report - Evaluations",
  // ONLY FOR UDM TENANT
  "Site Report - Evaluations > Goals",
  "Student Report - Goals",
  // ONLY FOR IUDENTAL TENANT
  "Case Logs - Daily Patient",
  "Student Report - Pre Rotations Survey",
  "Student Report - Post Rotations Survey",
  "Student Report - Post Cultural Competency Survey",
  // ONLY FOR UW TENANT
  "UW: Patient Report",
  "UW: Patient Report",
  // ALL TENANT
  "Rotations Report",
  // ONLY FOR IUDENTAL TENANT
  "Rotations - axiUm",
  "Case Logs > Diagnoses & Procedures",
  // ONLY FOR ACESHEALTH TENANTS
  "Reports > Evaluations - Survey",
  // ONLY FOR UDM TENANT
  "Student Report - Post Rotations Survey",
].map((reportName: string, index: number) => ({
  _id: `${QUERIES_STANDARD_PREFIX}${index + 1}` as ReportStandardProps["_id"],
  standardId: (index + 1) as ReportStandardProps["standardId"],
  queryId: "",
  query: null,
  reportName,
  reportDescription: "",
  reportModel: "",
  reports: [],
}));
const reportsStandardIds = reportsStandardNames.map(({ _id }) => _id);
// Initial States
export const initialStateQueryParams: State["queryCreateEdit"]["queryParams"] =
  {
    match: {
      Users: [],
      Enrollments: [],
      Institutions: [],
      Rotations: [],
      CaseLogs: [],
      Evaluations: [],
    },
    modelSelected: null,
    projection: {
      Users: [],
      Enrollments: [],
      Institutions: [],
      Rotations: [],
      CaseLogs: [],
      Evaluations: [],
    },
  };
const initialState: State = {
  isFetchingQueries: false,
  isFetchingReports: false,
  lastFetchingQueries: null,
  lastFetchingQueryReports: null,
  lastFetchingReports: null,
  queries: [],
  queriesStandard: reportsStandardNames,
  query: null,
  queryCreateEdit: {
    queryDescription: "",
    queryIsPublic: true,
    queryName: "",
    queryParams: initialStateQueryParams,
    show: "query-info",
  },
  queryId: null,
  report: null,
  reportId: null,
  reports: [],
  reportsShowWarning: false,
  results: [],
};
const reports: (state: State, action: ReportsActionReducers) => State = produce(
  (draft: State, action: ReportsActionReducers) => {
    switch (action.type) {
      // QUERIES
      case Reports.REPORTS_FETCH_QUERIES:
        draft.isFetchingQueries = true;
        break;
      case Reports.REPORTS_FETCH_QUERIES_SUCCESS:
        draft.isFetchingQueries = false;
        draft.lastFetchingQueries = new Date();
        draft.queries = action.payload.queries.filter(
          (q) => !reportsStandardIds.includes(q.name)
        );
        if (action.payload.queries.length > 0) {
          draft.queriesStandard = sortBy(
            action.payload.queries
              .filter(
                (q) =>
                  reportsStandardIds.indexOf(q.name) >= 0 &&
                  reportsStandardIds.indexOf(q.name) < reportsStandardIds.length
              )
              .map((query) => {
                const queriesStandard = draft.queriesStandard.filter(
                  (q) => query.name === q._id
                );
                const [queryStandard] = queriesStandard;
                return {
                  ...queryStandard,
                  queryId: query._id,
                  query,
                  reportDescription: query.description,
                  reportModel: query?.query?.model || "",
                  reports: [],
                };
              }),
            "standardId",
            "desc"
          );
        }
        draft.results = [];
        // To show all queries (Standard Queries included), only for dev and test
        // if (isDevelopmentReactAppEnv()) {
        //   draft.queries = action.payload.queries;
        // }
        draft.queries = action.payload.queries;
        break;
      case Reports.REPORTS_FETCH_QUERIES_FAILURE:
        draft.isFetchingQueries = false;
        break;
      case Reports.REPORTS_CREATE_QUERY_SUCCESS:
        draft.lastFetchingQueries = draft.lastFetchingQueries
          ? new Date()
          : null;
        draft.query = action.payload.query;
        draft.queries = [action.payload.query, ...draft.queries];
        draft.report = null;
        draft.lastFetchingQueryReports = null;
        draft.lastFetchingReports = null;
        draft.reports = action.payload.query?.reports || [];
        draft.results = [];
        break;
      case Reports.REPORTS_SET_QUERY_ID:
        draft.lastFetchingQueryReports =
          draft.queryId === action.payload.queryId
            ? draft.lastFetchingQueryReports
            : null;
        draft.queryId = action.payload.queryId;
        break;
      case Reports.REPORTS_FETCH_QUERY_SUCCESS:
      case Reports.REPORTS_EDIT_QUERY_SUCCESS:
        let queryParams = initialStateQueryParams;
        queryParams = {
          ...queryParams,
          ...action.payload.queryParams,
        };
        draft.query = action.payload.query;
        draft.queryCreateEdit = {
          ...draft.queryCreateEdit,
          queryName: action.payload.query.name,
          queryDescription: action.payload.query.description,
          queryParams,
          queryIsPublic:
            action.payload.query.isPrivate !== null ||
            action.payload.query.isPrivate !== undefined
              ? !action.payload.query.isPrivate
              : true,
          show: "query-params",
        };
        draft.report = null;
        draft.results = [];
        break;
      case Reports.REPORTS_DELETE_QUERY_SUCCESS:
        draft.lastFetchingQueries = new Date();
        draft.queries = draft.queries.filter(
          ({ _id }) => _id !== action.payload.queryId
        );
        break;
      case Reports.REPORTS_RUN_QUERY_SUCCESS:
        draft.lastFetchingQueryReports = draft.lastFetchingQueryReports
          ? new Date()
          : null;
        if (
          reportsStandardIds.indexOf(action.payload.report.query?.name) === -1
        ) {
          draft.reports = [...draft.reports, action.payload.report];
          draft.report = action.payload.report;
          draft.results = action.payload.report?.results || [];
        } else {
          draft.queriesStandard = draft.queriesStandard.map(
            (queryStandard: ReportStandardProps) => {
              if (queryStandard._id === action.payload.report.query?.name) {
                return {
                  ...queryStandard,
                  reports: [action.payload.report, ...queryStandard.reports],
                };
              }
              return queryStandard;
            }
          );
        }
        break;
      case Reports.REPORTS_QUERY_CREATE_EDIT_UPDATE:
        draft.queryCreateEdit = {
          ...draft.queryCreateEdit,
          ...action.payload.data,
        };
        break;
      case Reports.REPORTS_RESET_QUERY_LOADED:
        return {
          ...initialState,
          lastFetchingQueries: draft.lastFetchingQueries,
          queries: draft.queries,
          queriesStandard: draft.queriesStandard,
        };
      // REPORTS
      // REPORTS QUERIES
      case Reports.REPORTS_FETCH_REPORTS:
        draft.isFetchingReports = true;
        break;
      case Reports.REPORTS_FETCH_REPORTS_SUCCESS:
      case Reports.REPORTS_QUERIES_FETCH_REPORTS_SUCCESS:
        draft.isFetchingReports = false;
        draft.lastFetchingReports =
          Reports.REPORTS_FETCH_REPORTS_SUCCESS === action.type
            ? new Date()
            : null;
        draft.lastFetchingQueryReports =
          Reports.REPORTS_QUERIES_FETCH_REPORTS_SUCCESS === action.type
            ? new Date()
            : draft.lastFetchingQueryReports;
        draft.reports = action.payload.reports.filter(
          (r) => reportsStandardIds.indexOf(r.query?.name) === -1
        );
        draft.queriesStandard = draft.queriesStandard.map(
          (queryStandard: ReportStandardProps) => {
            const reports = action.payload.reports
              .filter((r) => r.query?.name === queryStandard._id)
              .reverse();
            return {
              ...queryStandard,
              reports,
            };
          }
        );
        draft.results = [];
        // To show all reports (Standard Reports included),  only for dev and test
        if (isDevelopmentReactAppEnv()) {
          draft.reports = action.payload.reports;
        }
        break;
      case Reports.REPORTS_FETCH_REPORTS_FAILURE:
        draft.isFetchingReports = false;
        break;
      case Reports.REPORTS_QUERIES_SHOW_REPORT_WARNING:
      case Reports.REPORTS_QUERIES_HIDE_REPORT_WARNING:
        draft.reportsShowWarning = action.payload.hasToShowReportsWarning;
        break;
      case Reports.REPORTS_QUERIES_SET_REPORT_ID:
        draft.reportId = action.payload.reportId;
        break;
      case Reports.REPORTS_QUERIES_FETCH_REPORT_SUCCESS:
        let queryParamsResult = initialStateQueryParams;
        queryParamsResult = {
          ...queryParamsResult,
          ...action.payload.queryParams,
        };
        draft.report = action.payload.report;
        draft.query = action.payload.query;
        draft.queryCreateEdit = {
          ...draft.queryCreateEdit,
          queryName: action.payload.query.name,
          queryDescription: action.payload.query.description,
          queryParams: queryParamsResult,
          queryIsPublic:
            action.payload.query.isPrivate !== null ||
            action.payload.query.isPrivate !== undefined
              ? !action.payload.query.isPrivate
              : true,
          show: "query-params",
        };
        draft.results = action.payload.report?.results || [];
        break;
      case Reports.REPORTS_QUERIES_EDIT_REPORT_SUCCESS:
        let newReport = draft.report;
        if (action.payload.report) {
          newReport = {
            ...newReport,
            name: action.payload.report.name,
            results: action.payload.report.results,
            updatedAt: action.payload.report.updatedAt,
          };
        }
        draft.report = newReport;
        draft.reports = updateArrayWithElement(draft.reports, newReport);
        draft.results = action.payload.report?.results || [];
        break;
      case Reports.REPORTS_QUERIES_DELETE_REPORT_SUCCESS:
        draft.lastFetchingQueryReports = new Date();
        draft.reports = draft.reports.filter(
          ({ _id }) => _id !== action.payload.reportId
        );
    }
  },
  initialState
);
export default reports;
