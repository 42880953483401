// Import Features
import produce from "immer";
import { ImportData } from "../actions";
import { getCurrentTimeInMs } from "../../utils/";
// Types
import type { Action } from "deox";
import type {
  StateImportData as State,
  TypeImportData,
} from "../../types/importData.types";
type ImportDataActionReducers = Action<
  string,
  {
    importData: State["imports"][
      | "users"
      | "rotations"
      | "tenantSettings"]["data"];
    importDataType: "users" | "rotations" | "tenantSettings";
    hasErrors: State["importDataNotification"]["hasErrors"];
    typeImport: TypeImportData;
  }
>;
// Import Data: (Users or rotations)
const initialStateImport = {
  data: [],
  lastFetching: 0,
};
const initialState: State = {
  imports: {
    users: initialStateImport,
    rotations: initialStateImport,
    tenantSettings: initialStateImport,
  },
  importDataNotification: {
    toShow: false,
    hasErrors: false,
    typeImport: "",
  },
};
const importData: (state: State, action: ImportDataActionReducers) => State =
  produce((draft: State, action: ImportDataActionReducers) => {
    switch (action.type) {
      case ImportData.IMPORT_DATA_FETCH_SUCCESS:
        draft.imports[action.payload.importDataType].data =
          action.payload.importData;
        draft.imports[action.payload.importDataType].lastFetching =
          getCurrentTimeInMs();
        break;
      case ImportData.IMPORT_DATA_SHOW_NOTIFICATION:
        draft.importDataNotification.toShow = true;
        draft.importDataNotification.hasErrors = action.payload.hasErrors;
        draft.importDataNotification.typeImport = action.payload.typeImport;
        draft.imports.users.lastFetching = 0;
        draft.imports.rotations.lastFetching = 0;
        draft.imports.tenantSettings.lastFetching = 0;
        break;
      case ImportData.IMPORT_DATA_HIDE_NOTIFICATION:
        draft.importDataNotification.toShow = false;
        draft.importDataNotification.hasErrors = false;
        draft.importDataNotification.typeImport = "";
    }
  }, initialState);
export default importData;
