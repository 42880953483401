// Features
import * as React from "react";
import { setParseUTCtimeToLocale } from "../../../utils/index";
// Components
import { Icon, Modal, Button, Checkbox } from "semantic-ui-react";
import Slider from "react-slick";
// Types
// Import Types
import type { ButtonProps, CheckboxProps } from "semantic-ui-react";
import type { State as StateBroadcastMessages } from "../../../store/reducers/broadcastMessages";
// Broadcast Show Messages View:
const { createRef, useState } = React;
//
const BroadcastMessagesView = ({
  messages,
  onConfirm,
}: React.PropsWithChildren<{
  messages: StateBroadcastMessages["broadcastMessages"];
  onConfirm: (
    ev: React.MouseEvent<HTMLButtonElement>,
    data: ButtonProps
  ) => void;
}>) => {
  // Ref
  const sliderRef = createRef<Slider>();
  // State
  const [indexSliderSelected, setIndexSliderSelected] = useState<number>(0);
  const [broadcastMessagesWatched, setBroadcastMessagesWatched] = useState<
    string[]
  >([]);
  // METHODS
  const onNextMessage = () => sliderRef.current?.slickNext();
  const onPrevMessage = () => sliderRef.current?.slickPrev();
  const onSelectedSlider = (
    event: React.MouseEvent<HTMLInputElement>,
    { value }: CheckboxProps
  ) => {
    const broadcastMessageWatched = value as string;
    window.logger.log(
      "onSelectedSlider",
      broadcastMessagesWatched,
      broadcastMessageWatched
    );
    let newBroadcastMessagesWatched = [...broadcastMessagesWatched];
    if (broadcastMessagesWatched.indexOf(broadcastMessageWatched) === -1) {
      newBroadcastMessagesWatched.push(broadcastMessageWatched);
    } else {
      newBroadcastMessagesWatched = newBroadcastMessagesWatched.filter(
        (broadcastMessageWatchedToRemove) =>
          broadcastMessageWatchedToRemove !== broadcastMessageWatched
      );
    }
    setBroadcastMessagesWatched(newBroadcastMessagesWatched);
  };
  // Constants
  const totalMessages = messages.length,
    sliderToShow = messages[indexSliderSelected]._id;
  // RENDERS
  return (
    <div id="main" className="broadcast-message">
      <Modal
        className="broadcast-message-modal-view p5"
        dimmer={"blurring"}
        open={true}
      >
        <Modal.Header className="bold warning">
          <Icon className="mb10" name="warning sign" />
          Important Message
          {totalMessages > 1 &&
            ` (${indexSliderSelected + 1}/${totalMessages})`}
        </Modal.Header>
        <Modal.Content className="bold text-justify pb10">
          <Slider
            adaptiveHeight={true}
            arrows={false}
            beforeChange={(current, next) => setIndexSliderSelected(next)}
            dots={false}
            infinite={false}
            ref={sliderRef}
          >
            {messages.map(({ _id, message, createdAt, updatedAt }, index) => (
              <React.Fragment key={_id + index}>
                <div style={{ whiteSpace: "pre-line" }}>{message}</div>
                <div className="datetime skejule-teal right">
                  {setParseUTCtimeToLocale(updatedAt || createdAt).format(
                    "MMM DD [at] HH:mm"
                  )}
                </div>
              </React.Fragment>
            ))}
          </Slider>
        </Modal.Content>
        <Modal.Actions>
          {totalMessages > 1 && (
            <Button
              className="basic skejule left"
              disabled={indexSliderSelected === 0}
              onClick={onPrevMessage}
              size="mini"
            >
              <Icon name="chevron left" /> Back
            </Button>
          )}
          {indexSliderSelected < totalMessages - 1 && (
            <Button
              size="mini"
              className="basic skejule"
              onClick={onNextMessage}
            >
              Next
              <Icon name="chevron right" />
            </Button>
          )}
          {indexSliderSelected === totalMessages - 1 && (
            <Button
              className="basic skejule"
              onClick={onConfirm}
              size="mini"
              value={broadcastMessagesWatched}
            >
              Confirm
              <Icon className="right mr2" name="checkmark" />
            </Button>
          )}
          <div className="mt10">
            <Checkbox
              checked={broadcastMessagesWatched.indexOf(sliderToShow) !== -1}
              label="I hereby acknowledge that I have completely read and fully understand this message"
              onClick={onSelectedSlider}
              value={sliderToShow}
            />
          </div>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default BroadcastMessagesView;
