// @flow

/* PERMISSIONS */
const onCheckResource = (apiResource: Object, resources: Array<string>) => {
  for (let resource in apiResource) {
    if (
      resources.indexOf(resource) !== -1 &&
      apiResource[resource]["isEnabled"]
    ) {
      return true;
    }
  }
  return false;
};

export const onCheckAllUserInvitationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "SuperCompanyAdministrators#Invitations:create",
    "SuperCompanyAdministrators#Invitations:read",
    "SuperCompanyAdministrators#Invitations:resend",
    "SuperCompanyAdministrators#Invitations:delete",
    "CompanyAdministrators#Invitations:create",
    "CompanyAdministrators#Invitations:read",
    "CompanyAdministrators#Invitations:resend",
    "CompanyAdministrators#Invitations:delete",
    "Coordinators#Invitations:create",
    "Coordinators#Invitations:read",
    "Coordinators#Invitations:resend",
    "Coordinators#Invitations:delete",
    "Faculties#Invitations:create",
    "Faculties#Invitations:read",
    "Faculties#Invitations:resend",
    "Faculties#Invitations:delete",
    "Students#Invitations:create",
    "Students#Invitations:read",
    "Students#Invitations:resend",
    "Students#Invitations:delete",
    "InstitutionAdministrators#Invitations:create",
    "InstitutionAdministrators#Invitations:read",
    "InstitutionAdministrators#Invitations:resend",
    "InstitutionAdministrators#Invitations:delete",
    "Preceptors#Invitations:create",
    "Preceptors#Invitations:read",
    "Preceptors#Invitations:resend",
    "Preceptors#Invitations:delete",
    "my:InstitutionAdministrators#Invitations:create",
    "my:InstitutionAdministrators#Invitations:read",
    "my:InstitutionAdministrators#Invitations:resend",
    "my:InstitutionAdministrators#Invitations:delete",
    "my:Preceptors#Invitations:create",
    "my:Preceptors#Invitations:read",
    "my:Preceptors#Invitations:resend",
    "my:Preceptors#Invitations:delete",
  ];
  const { UserInvitations } = permissions || {};
  if (UserInvitations && UserInvitations.apiValue) {
    return onCheckResource(UserInvitations.apiValue, resources);
  }
  return false;
};

export const onCheckReadAllUserInvitationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "SuperCompanyAdministrators#Invitations:read",
    "CompanyAdministrators#Invitations:read",
    "Coordinators#Invitations:read",
    "Faculties#Invitations:read",
    "Students#Invitations:read",
    "InstitutionAdministrators#Invitations:read",
    "Preceptors#Invitations:read",
    "my:InstitutionAdministrators#Invitations:read",
    "my:Preceptors#Invitations:read",
  ];
  const { UserInvitations } = permissions || {};
  if (UserInvitations && UserInvitations.apiValue) {
    return onCheckResource(UserInvitations.apiValue, resources);
  }
  return false;
};

export const onCheckCreateAllUserInvitationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "SuperCompanyAdministrators#Invitations:create",
    "CompanyAdministrators#Invitations:create",
    "Coordinators#Invitations:create",
    "Faculties#Invitations:create",
    "Students#Invitations:create",
    "InstitutionAdministrators#Invitations:create",
    "Preceptors#Invitations:create",
    "my:InstitutionAdministrators#Invitations:create",
    "my:Preceptors#Invitations:create",
  ];
  const { UserInvitations } = permissions || {};
  if (UserInvitations && UserInvitations.apiValue) {
    return onCheckResource(UserInvitations.apiValue, resources);
  }
  return false;
};

export const onCheckCreateInstitutionAdministratorsUserInvitationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "InstitutionAdministrators#Invitations:create",
    "my:InstitutionAdministrators#Invitations:create",
  ];
  const { UserInvitations } = permissions || {};
  if (UserInvitations && UserInvitations.apiValue) {
    return onCheckResource(UserInvitations.apiValue, resources);
  }
  return false;
};

export const onCheckCreatePreceptorsUserInvitationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "Preceptors#Invitations:create",
    "my:Preceptors#Invitations:create",
  ];
  const { UserInvitations } = permissions || {};
  if (UserInvitations && UserInvitations.apiValue) {
    return onCheckResource(UserInvitations.apiValue, resources);
  }
  return false;
};

// RESEND
export const onCheckResendUserInvitationsPermissions = (
  roleApiName: string,
  permissions: ?Object
) => {
  switch (roleApiName) {
    case "SuperCompanyAdministrators":
      return onCheckResendSuperCompanyAdministratorsUserInvitationsPermissions(
        permissions
      );
    case "CompanyAdministrators":
      return onCheckResendCompanyAdministratorsUserInvitationsPermissions(
        permissions
      );
    case "Coordinators":
      return onCheckResendCoordinatorsUserInvitationsPermissions(permissions);
    case "Faculties":
      return onCheckResendFacultiesUserInvitationsPermissions(permissions);
    case "InstitutionAdministrators":
      return onCheckResendInstitutionAdministratorsUserInvitationsPermissions(
        permissions
      );
    case "Preceptors":
      return onCheckResendPreceptorsUserInvitationsPermissions(permissions);
    case "Students":
      return onCheckResendStudentsUserInvitationsPermissions(permissions);
    default:
  }
  return false;
};

export const onCheckResendSuperCompanyAdministratorsUserInvitationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["SuperCompanyAdministrators#Invitations:resend"];
  const { UserInvitations } = permissions || {};
  if (UserInvitations && UserInvitations.apiValue) {
    return onCheckResource(UserInvitations.apiValue, resources);
  }
  return false;
};
export const onCheckResendCompanyAdministratorsUserInvitationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["CompanyAdministrators#Invitations:resend"];
  const { UserInvitations } = permissions || {};
  if (UserInvitations && UserInvitations.apiValue) {
    return onCheckResource(UserInvitations.apiValue, resources);
  }
  return false;
};

export const onCheckResendCoordinatorsUserInvitationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Coordinators#Invitations:resend"];
  const { UserInvitations } = permissions || {};
  if (UserInvitations && UserInvitations.apiValue) {
    return onCheckResource(UserInvitations.apiValue, resources);
  }
  return false;
};

export const onCheckResendFacultiesUserInvitationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Faculties#Invitations:resend"];
  const { UserInvitations } = permissions || {};
  if (UserInvitations && UserInvitations.apiValue) {
    return onCheckResource(UserInvitations.apiValue, resources);
  }
  return false;
};

export const onCheckResendStudentsUserInvitationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Students#Invitations:resend"];
  const { UserInvitations } = permissions || {};
  if (UserInvitations && UserInvitations.apiValue) {
    return onCheckResource(UserInvitations.apiValue, resources);
  }
  return false;
};

export const onCheckResendInstitutionAdministratorsUserInvitationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "InstitutionAdministrators#Invitations:resend",
    "my:InstitutionAdministrators#Invitations:resend",
  ];
  const { UserInvitations } = permissions || {};
  if (UserInvitations && UserInvitations.apiValue) {
    return onCheckResource(UserInvitations.apiValue, resources);
  }
  return false;
};

export const onCheckResendPreceptorsUserInvitationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "Preceptors#Invitations:resend",
    "my:Preceptors#Invitations:resend",
  ];
  const { UserInvitations } = permissions || {};
  if (UserInvitations && UserInvitations.apiValue) {
    return onCheckResource(UserInvitations.apiValue, resources);
  }
  return false;
};

// ACTIVE
export const onCheckActiveAllUserInvitationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "SuperCompanyAdministrators#Invitations:active",
    "CompanyAdministrators#Invitations:active",
    "Faculties#Invitations:active",
    "Coordinators#Invitations:active",
    "Students#Invitations:active",
    "InstitutionAdministrators#Invitations:active",
    "Preceptors#Invitations:active",
    "my:InstitutionAdministrators#Invitations:active",
    "my:Preceptors#Invitations:active",
  ];
  const { UserInvitations } = permissions || {};
  if (UserInvitations && UserInvitations.apiValue) {
    return onCheckResource(UserInvitations.apiValue, resources);
  }
  return false;
};

// DELETE
export const onCheckDeleteUserInvitationsPermissions = (
  roleApiName: string,
  permissions: ?Object
) => {
  switch (roleApiName) {
    case "SuperCompanyAdministrators":
      return onCheckDeleteSuperCompanyAdministratorsUserInvitationsPermissions(
        permissions
      );
    case "CompanyAdministrators":
      return onCheckDeleteCompanyAdministratorsUserInvitationsPermissions(
        permissions
      );
    case "Coordinators":
      return onCheckDeleteCoordinatorsUserInvitationsPermissions(permissions);
    case "Faculties":
      return onCheckDeleteFacultiesUserInvitationsPermissions(permissions);
    case "InstitutionAdministrators":
      return onCheckDeleteInstitutionAdministratorsUserInvitationsPermissions(
        permissions
      );
    case "Preceptors":
      return onCheckDeletePreceptorsUserInvitationsPermissions(permissions);
    case "Students":
      return onCheckDeleteStudentsUserInvitationsPermissions(permissions);
    default:
  }
  return false;
};

export const onCheckDeleteSuperCompanyAdministratorsUserInvitationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["SuperCompanyAdministrators#Invitations:delete"];
  const { UserInvitations } = permissions || {};
  if (UserInvitations && UserInvitations.apiValue) {
    return onCheckResource(UserInvitations.apiValue, resources);
  }
  return false;
};
export const onCheckDeleteCompanyAdministratorsUserInvitationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["CompanyAdministrators#Invitations:delete"];
  const { UserInvitations } = permissions || {};
  if (UserInvitations && UserInvitations.apiValue) {
    return onCheckResource(UserInvitations.apiValue, resources);
  }
  return false;
};

export const onCheckDeleteCoordinatorsUserInvitationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Coordinators#Invitations:delete"];
  const { UserInvitations } = permissions || {};
  if (UserInvitations && UserInvitations.apiValue) {
    return onCheckResource(UserInvitations.apiValue, resources);
  }
  return false;
};

export const onCheckDeleteFacultiesUserInvitationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Faculties#Invitations:delete"];
  const { UserInvitations } = permissions || {};
  if (UserInvitations && UserInvitations.apiValue) {
    return onCheckResource(UserInvitations.apiValue, resources);
  }
  return false;
};

export const onCheckDeleteStudentsUserInvitationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Students#Invitations:delete"];
  const { UserInvitations } = permissions || {};
  if (UserInvitations && UserInvitations.apiValue) {
    return onCheckResource(UserInvitations.apiValue, resources);
  }
  return false;
};

export const onCheckDeleteInstitutionAdministratorsUserInvitationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "InstitutionAdministrators#Invitations:delete",
    "my:InstitutionAdministrators#Invitations:delete",
  ];
  const { UserInvitations } = permissions || {};
  if (UserInvitations && UserInvitations.apiValue) {
    return onCheckResource(UserInvitations.apiValue, resources);
  }
  return false;
};

export const onCheckDeletePreceptorsUserInvitationsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "Preceptors#Invitations:delete",
    "my:Preceptors#Invitations:delete",
  ];
  const { UserInvitations } = permissions || {};
  if (UserInvitations && UserInvitations.apiValue) {
    return onCheckResource(UserInvitations.apiValue, resources);
  }
  return false;
};
