import { put, select, call, all, takeLatest } from "redux-saga/effects";
import ImportDataApi from "../api/ImportData";
import { Auth, ImportData } from "../actions/";
import { downloadFile, setParseUTCtimeToLocale } from "../../utils/";
// Type
import type { Action } from "deox";
import type { tAPI } from "../api/API";
import type { TypeImportData } from "../../types/importData.types";
//Sagas for Import Data: users or rotations
function* doImportDataFetch(
  action: Action<
    string,
    {
      typeImport: "Users" | "Rotations" | "TenantSettings";
      cbs?: (data: any) => void;
      cbe?: (error: any) => void;
    }
  >
) {
  window.logger.log("doImportDataFetch", action);
  yield put(ImportData.importDataFetchRequest());
  const { typeImport, cbs = null, cbe = null } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get latest import data uploaded
    const models: TypeImportData[] =
      typeImport === "Rotations"
        ? ["Rotations"]
        : [
            "SuperCompanyAdministrators",
            "CompanyAdministrators",
            "Faculties",
            "InstitutionAdministrators",
            "Preceptors",
            "Students",
          ];
    const {
      data: { imports: importData = [] },
    } = yield call(ImportDataApi.GetImportData, api, models, { token });
    yield put(
      ImportData.importDataFetchSuccess({
        importData,
        importDataType: typeImport.toLowerCase(),
      })
    );
    if (cbs) {
      cbs(importData);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doImportDataFetch", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(ImportData.importDataFetchFailure());
    if (cbe) {
      cbe(error);
    }
  }
}
function* doImportDataSend(
  action: Action<
    string,
    {
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any, details: any) => void;
    }
  >
) {
  window.logger.log("doImportDataSend", action);
  yield put(ImportData.importDataSendRequest());
  const { body, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to sent import data
    const { data } = yield call(ImportDataApi.SendImportData, api, body, {
      token,
    });
    const { import: importData } = data;
    yield put(ImportData.importDataSendSuccess());
    if (callbackSuccess) {
      callbackSuccess(importData);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doImportDataSend", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(ImportData.importDataSendFailure());
    const { message, details } = errors || "";
    if (callbackError) {
      callbackError(message, details);
    }
  }
}

function* doImportGenerateCSV(
  action: Action<
    string,
    {
      body: object;
      importName: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any, details: any) => void;
    }
  >
) {
  window.logger.log("doImportGenerateCSV", action);
  yield put(ImportData.importGenerateCSVRequest());
  const {
    body,
    importName = "",
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to generate CSV to import data: users or rotations
    const {
      data: { blob },
    } = yield call(ImportDataApi.GenerateCSVImportData, api, body, { token });
    downloadFile(
      `Import ${importName} - ${setParseUTCtimeToLocale().format(
        "MMM DD of YYYY [at] HH:mm"
      )}.csv`,
      blob
    );
    yield put(ImportData.importGenerateCSVSuccess());
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doImportGenerateCSV", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(ImportData.importGenerateCSVFailure());
    const { message, details } = errors || "";
    if (callbackError) {
      callbackError(message, details);
    }
  }
}

export default function* importDataSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(ImportData.IMPORT_DATA_FETCH, doImportDataFetch),
    takeLatest(ImportData.IMPORT_DATA_SEND, doImportDataSend),
    takeLatest(ImportData.IMPORT_GENERATE_CSV, doImportGenerateCSV),
  ]);
}
