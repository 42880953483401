import type { Config, tAPI } from "./API";
// API Custom Fields static class

const apiBaseCustomFields = "/custom-fields";
export default class CustomFields {
  // Get a list of Custom Fields
  static GetCustomFields(api: tAPI, config?: Config) {
    return api.GET(`${apiBaseCustomFields}`, config);
  }
  // Create a Custom Field
  static CreateCustomField(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBaseCustomFields}`, data, config);
  }
  // Edit a Custom Field
  static EditCustomField(
    api: tAPI,
    customFieldId: string,
    data: object,
    config?: Config
  ) {
    return api.PATCH(`${apiBaseCustomFields}/${customFieldId}`, data, config);
  }
  // Delete a Custom Field
  static DeleteCustomField(api: tAPI, customFieldId: string, config?: Config) {
    return api.DELETE(`${apiBaseCustomFields}/${customFieldId}`, config);
  }
}
