import { createAction } from "deox";

// Actions for Portfolio
// Fetch portfolio
export const PORTFOLIO_FETCH_PORTFOLIO: string = "PORTFOLIO/FETCH/PORTFOLIO";
export const PORTFOLIO_FETCH_PORTFOLIO_REQUEST: string =
  "PORTFOLIO/FETCH/PORTFOLIO/REQUEST";
export const PORTFOLIO_FETCH_PORTFOLIO_SUCCESS: string =
  "PORTFOLIO/FETCH/PORTFOLIO/SUCCESS";
export const PORTFOLIO_FETCH_PORTFOLIO_FAILURE: string =
  "PORTFOLIO/FETCH/PORTFOLIO/FAILURE";
export const portfolioFetchPortfolio = createAction(
  PORTFOLIO_FETCH_PORTFOLIO,
  (resolve) => (payload?: object) => resolve(payload)
);
export const portfolioFetchPortfolioRequest = createAction(
  PORTFOLIO_FETCH_PORTFOLIO_REQUEST
);
export const portfolioFetchPortfolioSuccess = createAction(
  PORTFOLIO_FETCH_PORTFOLIO_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const portfolioFetchPortfolioFailure = createAction(
  PORTFOLIO_FETCH_PORTFOLIO_FAILURE
);
// Edit portfolio
export const PORTFOLIO_EDIT: string = "PORTFOLIO/EDIT";
export const PORTFOLIO_EDIT_REQUEST: string = "PORTFOLIO/EDIT/REQUEST";
export const PORTFOLIO_EDIT_SUCCESS: string = "PORTFOLIO/EDIT/SUCCESS";
export const PORTFOLIO_EDIT_FAILURE: string = "PORTFOLIO/EDIT/FAILURE";
export const portfolioEdit = createAction(
  PORTFOLIO_EDIT,
  (resolve) => (payload?: object) => resolve(payload)
);
export const portfolioEditRequest = createAction(PORTFOLIO_EDIT_REQUEST);
export const portfolioEditSuccess = createAction(
  PORTFOLIO_EDIT_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const portfolioEditFailure = createAction(PORTFOLIO_EDIT_FAILURE);
// Request portfolio testimonials
export const PORTFOLIO_TESTIMONIALS: string = "PORTFOLIO/TESTIMONIALS";
export const PORTFOLIO_TESTIMONIALS_REQUEST: string =
  "PORTFOLIO/TESTIMONIALS/REQUEST";
export const PORTFOLIO_TESTIMONIALS_SUCCESS: string =
  "PORTFOLIO/TESTIMONIALS/SUCCESS";
export const PORTFOLIO_TESTIMONIALS_FAILURE: string =
  "PORTFOLIO/TESTIMONIALS/FAILURE";
export const portfolioTestimonials = createAction(
  PORTFOLIO_TESTIMONIALS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const portfolioTestimonialsRequest = createAction(
  PORTFOLIO_TESTIMONIALS_REQUEST
);
export const portfolioTestimonialsSuccess = createAction(
  PORTFOLIO_TESTIMONIALS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const portfolioTestimonialsFailure = createAction(
  PORTFOLIO_TESTIMONIALS_FAILURE
);
// Request portfolio testimonials notifications
export const PORTFOLIO_TESTIMONIALS_NOTIFICATIONS: string =
  "PORTFOLIO/TESTIMONIALS_NOTIFICATIONS";
export const PORTFOLIO_TESTIMONIALS_NOTIFICATIONS_REQUEST: string =
  "PORTFOLIO/TESTIMONIALS_NOTIFICATIONS/REQUEST";
export const PORTFOLIO_TESTIMONIALS_NOTIFICATIONS_SUCCESS: string =
  "PORTFOLIO/TESTIMONIALS_NOTIFICATIONS/SUCCESS";
export const PORTFOLIO_TESTIMONIALS_NOTIFICATIONS_FAILURE: string =
  "PORTFOLIO/TESTIMONIALS_NOTIFICATIONS/FAILURE";
export const portfolioTestimonialsNotifications = createAction(
  PORTFOLIO_TESTIMONIALS_NOTIFICATIONS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const portfolioTestimonialsNotificationsRequest = createAction(
  PORTFOLIO_TESTIMONIALS_NOTIFICATIONS_REQUEST
);
export const portfolioTestimonialsNotificationsSuccess = createAction(
  PORTFOLIO_TESTIMONIALS_NOTIFICATIONS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const portfolioTestimonialsNotificationsFailure = createAction(
  PORTFOLIO_TESTIMONIALS_NOTIFICATIONS_FAILURE
);
// Submit portfolio testimonials
export const PORTFOLIO_SUBMIT_TESTIMONIALS: string =
  "PORTFOLIO/SUBMIT_TESTIMONIALS";
export const PORTFOLIO_SUBMIT_TESTIMONIALS_REQUEST: string =
  "PORTFOLIO/SUBMIT_TESTIMONIALS/REQUEST";
export const PORTFOLIO_SUBMIT_TESTIMONIALS_SUCCESS: string =
  "PORTFOLIO/SUBMIT_TESTIMONIALS/SUCCESS";
export const PORTFOLIO_SUBMIT_TESTIMONIALS_FAILURE: string =
  "PORTFOLIO/SUBMIT_TESTIMONIALS/FAILURE";
export const portfolioSubmitTestimonials = createAction(
  PORTFOLIO_SUBMIT_TESTIMONIALS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const portfolioSubmitTestimonialsRequest = createAction(
  PORTFOLIO_SUBMIT_TESTIMONIALS_REQUEST
);
export const portfolioSubmitTestimonialsSuccess = createAction(
  PORTFOLIO_SUBMIT_TESTIMONIALS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const portfolioSubmitTestimonialsFailure = createAction(
  PORTFOLIO_SUBMIT_TESTIMONIALS_FAILURE
);
// Delete portfolio testimonials
export const PORTFOLIO_DELETE_TESTIMONIALS: string =
  "PORTFOLIO/DELETE_TESTIMONIALS";
export const PORTFOLIO_DELETE_TESTIMONIALS_REQUEST: string =
  "PORTFOLIO/DELETE_TESTIMONIALS/REQUEST";
export const PORTFOLIO_DELETE_TESTIMONIALS_SUCCESS: string =
  "PORTFOLIO/DELETE_TESTIMONIALS/SUCCESS";
export const PORTFOLIO_DELETE_TESTIMONIALS_FAILURE: string =
  "PORTFOLIO/DELETE_TESTIMONIALS/FAILURE";
export const portfolioDeleteTestimonials = createAction(
  PORTFOLIO_DELETE_TESTIMONIALS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const portfolioDeleteTestimonialsRequest = createAction(
  PORTFOLIO_DELETE_TESTIMONIALS_REQUEST
);
export const portfolioDeleteTestimonialsSuccess = createAction(
  PORTFOLIO_DELETE_TESTIMONIALS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const portfolioDeleteTestimonialsFailure = createAction(
  PORTFOLIO_DELETE_TESTIMONIALS_FAILURE
);
