import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { onCheckNotificationsPermissions } from "../notifications/NotificationsPermissions";
import {
  onCheckUsersManagerPermissions,
  onCheckUserInvitationsManagerPermissions,
} from "../manager/ManagerPermissions";
import { onCheckImportDataPermissions } from "../import/ImportDataPermissions";
import {
  onCheckInstitutionsPermissions,
  onCheckReadMyInstitutionsPermissions,
} from "../institutions/InstitutionsPermissions";
import {
  onCheckRotationsPermissions,
  onCheckReadPeriodsPermissions,
  onCheckCUDPeriodsPermissions,
} from "../rotations/RotationsPermissions";
import { onCheckCaseLogsPermissions } from "../case-logs/CaseLogsPermissions";
import { onCheckEvaluationsPermissions } from "../evaluations/EvaluationsPermissions";
import { onCheckEvaluationSchedulersPermissions } from "../evaluations-schedulers/EvaluationSchedulersPermissions";
import { onCheckEvaluationTemplatesPermissions } from "../evaluations-templates/EvaluationTemplatesPermissions";
import {
  onCheckReadChartsPermissions,
  onCheckReadQueriesPermissions,
  onCheckReadAllReportsPermissions,
} from "../reports/ReportsPermissions";
import { onCheckReadMyCompaniesPermissions } from "../company/CompaniesPermissions";
import {
  onCheckReadProgramsPermissions,
  onCheckCUDProgramsPermissions,
  onCheckReadCohortsPermissions,
  onCheckCUDCohortsPermissions,
  onCheckReadFileCategoriesPermissions,
  onCheckCUDFileCategoriesPermissions,
  onCheckReadCustomFieldsPermissions,
  onCheckCUDCustomFieldsPermissions,
  onCheckReadRolesPermissions,
  onCheckCUDRolesPermissions,
  onCheckReadTenantSettingsPermissions,
  onCheckCUDTenantSettingsPermissions,
  onCheckEditTenantSettingsPermissions,
} from "../admin-portal/AdminPortalPermissions";
import { FeedbackForm } from "../../store/actions/";
import MenuPresentational from "./MenuPresentational";
import "./Menu.scss";

type Props = {
  showMenu: boolean;
  location: object;
  routerBeforeTransitions: object;
  routerManagerLoaded: object;
  user: object;
  myCompany: object | undefined | null;
  myInstitution: object | undefined | null;
  role: object;
  notifications: Array<object>;
  totalNotifications: number;
  unseenNotifications: number;
  openFeedbackForm: () => void;
  setImg: (_img: string, updatedAt: string) => string;
  history: any;
};
type State = {
  activeNotifications: boolean;
  isModalOpen: boolean;
  MyPayments: React.ComponentType<any> | undefined | null;
  showSidebarMenu: boolean;
};

class MenuContainer extends React.Component<Props, State> {
  state = {
    activeNotifications: false,
    isModalOpen: false,
    MyPayments: null,
    showSidebarMenu: true,
  };
  _loadingMyPayments = async (authRoleType: string | undefined | null) => {
    const { MyPayments } = this.state;
    window.logger.log("loading Component MyPayments", authRoleType);
    if (!MyPayments && authRoleType === "alumnis") {
      let MyPayments = await import(`../shared/Payments`);
      MyPayments = MyPayments.default;
      this.setState({
        MyPayments,
      });
    }
  };
  static menus = {
    "/home": {
      to: {
        pathname: "/home",
        state: { namePage: "Dashboard" },
      },
    },
    "/manager": {
      to: {
        pathname: "/manager",
        state: { namePage: "Manager" },
      },
    },
    "/clinics": {
      to: {
        pathname: "/clinics",
        state: { namePage: "Clinics" },
      },
    },
    "/rotations": {
      to: {
        pathname: "/rotations",
        state: { namePage: "Rotations" },
      },
    },
    "/case-logs": {
      to: {
        pathname: "/case-logs",
        state: { namePage: "Case Logs List" },
      },
    },
    "/evaluations": {
      to: {
        pathname: "/evaluations",
        state: { namePage: "Evaluations" },
      },
    },
    "/reports": {
      to: {
        pathname: "/reports",
        state: { namePage: "Dashboard" },
      },
    },
    "/my-school": {
      to: {
        pathname: "/my-school",
        state: { namePage: "My School" },
      },
    },
    "/my-clinic": {
      to: {
        pathname: "/my-clinic",
        state: { namePage: "My Clinic" },
      },
    },
  };
  onCheckManagerPermissions = (permissions: Object): boolean => {
    return (
      onCheckUsersManagerPermissions(permissions) ||
      onCheckUserInvitationsManagerPermissions(permissions) ||
      onCheckImportDataPermissions(permissions)
    );
  };
  onCheckPermissions = (resource: string): string | undefined | null => {
    const {
      user,
      role: { permissions, type },
    } = this.props;
    switch (resource) {
      case "UserManager":
        if (this.onCheckManagerPermissions(permissions)) {
          return "/manager";
        }
        break;
      case "Notifications":
        if (onCheckNotificationsPermissions(permissions)) {
          return "/notifications";
        }
        break;
      case "Institutions":
        if (onCheckInstitutionsPermissions(permissions)) {
          return "/clinics";
        }
        break;
      case "Rotations":
        if (
          onCheckRotationsPermissions(permissions) ||
          onCheckImportDataPermissions(permissions)
        ) {
          return "/rotations";
        }
        break;
      case "CaseLogs":
        if (onCheckCaseLogsPermissions(permissions)) {
          return "/case-logs";
        }
        break;
      case "Evaluations":
        if (
          onCheckEvaluationsPermissions(permissions) ||
          onCheckEvaluationSchedulersPermissions(permissions) ||
          onCheckEvaluationTemplatesPermissions(permissions)
        ) {
          return "/evaluations";
        }
        break;
      case "Reports":
        if (onCheckReadAllReportsPermissions(permissions)) {
          return "/reports";
        } else if (onCheckReadChartsPermissions(permissions)) {
          delete MenuContainer.menus["/reports"];
          MenuContainer.menus = {
            ...MenuContainer.menus,
            "/reports/dashboard": {
              to: {
                pathname: "/reports/dashboard",
                state: { namePage: "Dashboard" },
              },
            },
          };
          return "/reports/dashboard";
        } else if (onCheckReadQueriesPermissions(permissions)) {
          delete MenuContainer.menus["/reports"];
          MenuContainer.menus = {
            ...MenuContainer.menus,
            "/reports/dashboard/queries": {
              to: {
                pathname: "/reports/dashboard/queries",
                state: { namePage: "Queries" },
              },
            },
          };
          return "/reports/dashboard/queries";
        }
        break;
      case "MyPortfolio":
        if (type === "students" || type === "alumnis") {
          return "/my-profile/portfolio";
        }
        break;
      case "MySchool":
        if (onCheckReadMyCompaniesPermissions(permissions)) {
          return "/my-school";
        }
        break;
      case "MyClinic":
        if (
          onCheckReadMyInstitutionsPermissions(permissions) &&
          (user.institution || user.activeRotation)
        ) {
          return "/my-clinic";
        }
        break;
      case "Admin":
        if (
          type === "supercompanyadministrators" &&
          onCheckReadTenantSettingsPermissions(permissions)
        ) {
          return "/admin/general-settings";
        } else if (
          (onCheckReadProgramsPermissions(permissions) &&
            onCheckCUDProgramsPermissions(permissions)) ||
          (onCheckReadCohortsPermissions(permissions) &&
            onCheckCUDCohortsPermissions(permissions))
        ) {
          return "/admin/programs-cohorts";
        } else if (
          onCheckReadFileCategoriesPermissions(permissions) &&
          onCheckCUDFileCategoriesPermissions(permissions)
        ) {
          return "/admin/document-settings";
        } else if (
          onCheckReadCustomFieldsPermissions(permissions) &&
          onCheckCUDCustomFieldsPermissions(permissions)
        ) {
          return "/admin/custom-fields";
        } else if (
          onCheckReadRolesPermissions(permissions) &&
          onCheckCUDRolesPermissions(permissions)
        ) {
          return "/admin/access-control";
        } else if (
          onCheckReadTenantSettingsPermissions(permissions) &&
          onCheckCUDTenantSettingsPermissions(permissions)
        ) {
          return "/admin/case-logs-settings";
        } else if (
          onCheckReadPeriodsPermissions(permissions) &&
          onCheckCUDPeriodsPermissions(permissions)
        ) {
          return "/admin/rotations-settings";
        } else if (
          onCheckReadRolesPermissions(permissions) &&
          onCheckReadTenantSettingsPermissions(permissions) &&
          onCheckEditTenantSettingsPermissions(permissions)
        ) {
          return "/admin/broadcast-settings";
        }
        break;
      default:
    }
    return "";
  };
  onChangeMenu = (ev: Event, { path }: { path: string }) => {
    if (this.state.activeNotifications) {
      this.onOpenCloseNotifications()();
    }
    const { location, history } = this.props;
    if (
      path === "/manager" &&
      location &&
      location.pathname &&
      location.pathname.indexOf("manager") > -1
    ) {
      const { routerBeforeTransitions } = this.props;
      history.push(routerBeforeTransitions);
    } else if (
      location &&
      location.pathname !== path &&
      MenuContainer.menus[path].to
    ) {
      const { routerManagerLoaded } = this.props;
      if (path === "/manager") {
        history.push(routerManagerLoaded);
      } else {
        history.push(MenuContainer.menus[path].to);
      }
    }
  };
  onGoTo = (location: Object) => () => {
    const { history } = this.props;
    if (this.state.activeNotifications) {
      this.onOpenCloseNotifications()();
    }
    history.push(location);
  };
  toggleSidebarVisibility = () => {
    this.setState({ showSidebarMenu: !this.state.showSidebarMenu });
  };
  onOpenCloseSubMenu = () =>
    this.setState({ isModalOpen: !this.state.isModalOpen });
  onOpenCloseNotifications =
    (isClose: boolean = false) =>
    (ev?: Event) => {
      ev?.stopPropagation();
      this.setState({
        activeNotifications: isClose ? false : !this.state.activeNotifications,
      });
    };
  componentDidMount() {
    const { role } = this.props;
    this._loadingMyPayments(role && role.type);
  }
  getSnapshotBeforeUpdate(prevProps: Props, prevState: State) {
    const { showMenu: showMenuOld } = prevProps;
    const { showMenu: showMenuNew } = this.props;
    if (showMenuOld !== showMenuNew && showMenuNew) {
      return showMenuNew;
    }
    return null;
  }
  componentDidUpdate(prevProps: Props, prevState: State, showMenu: boolean) {
    if (showMenu) {
      const { role } = this.props;
      this._loadingMyPayments(role && role.type);
    }
  }
  render() {
    window.logger.log("menu", this.props, this.state);
    let namePage = "Heto";
    const {
      history,
      location,
      myCompany,
      myInstitution,
      notifications,
      openFeedbackForm,
      role,
      setImg,
      showMenu,
      totalNotifications,
      unseenNotifications,
      user,
    } = this.props;
    const { activeNotifications, isModalOpen, MyPayments, showSidebarMenu } =
      this.state;
    if (location.state && location.state.namePage) {
      namePage = location.state.namePage;
      if (namePage === "Profile" && location.state.role) {
        namePage = `${location.state.role.name} ${namePage}`;
      }
    }
    return showMenu ? (
      <MenuPresentational
        role={role}
        namePage={namePage}
        activePath={location.pathname}
        activeNotifications={activeNotifications}
        isModalOpen={isModalOpen}
        MyPayments={MyPayments}
        showSidebarMenu={showSidebarMenu}
        onChangeMenu={this.onChangeMenu}
        onOpenCloseNotifications={this.onOpenCloseNotifications}
        onShowMenu={this.toggleSidebarVisibility}
        onOpenCloseSubMenu={this.onOpenCloseSubMenu}
        goTo={this.onGoTo}
        goBack={history.goBack}
        user={user}
        myCompany={myCompany}
        myInstitution={myInstitution}
        notifications={notifications}
        totalNotifications={totalNotifications}
        unseenNotifications={unseenNotifications}
        setImg={setImg}
        onOpenFeedbackForm={openFeedbackForm}
        onCheckPermissions={this.onCheckPermissions}
      />
    ) : null;
  }
}

const bindActions = (dispatch) => {
  return {
    openFeedbackForm: (payload) =>
      dispatch(FeedbackForm.feedbackFormOpenClose()),
  };
};
const Menu: React.ComponentType<any> = withRouter(
  connect(
    ({
      routerBeforeTransitions,
      routerManager: { loaded },
      config: { companyDomain, baseImg },
      auth: {
        isAuthenticated,
        user,
        role,
        myInstitution: { data: myInstitution },
      },
      company: { myCompany },
      notifications: { totalNotifications, notifications, unseenNotifications },
    }) => {
      return {
        showMenu: companyDomain.length > 0 && isAuthenticated ? true : false,
        user,
        role,
        myCompany,
        myInstitution,
        totalNotifications,
        notifications,
        unseenNotifications,
        routerBeforeTransitions,
        routerManagerLoaded: loaded,
        setImg: (_img: string, updatedAt: string) =>
          [baseImg, _img, `&updatedAt=${updatedAt}`].join(""),
      };
    },
    bindActions
  )(MenuContainer)
);

export default Menu;
