import { isDevelopmentReactAppEnv } from "../../utils";
//Logger for different type messages.
export const logger = () => {
  let logger = {
    log: () => {},
    info: () => {},
    warn: () => {},
    error: () => {},
    test: () => {},
  };
  if (isDevelopmentReactAppEnv()) {
    logger = {
      log: (...args: any[]) => console.log(...args),
      info: (...args: any[]) => console.info(...args),
      warn: (...args: any[]) => console.warn(...args),
      error: (...args: any[]) => console.error(...args),
      test: (...args: any[]) => {
        let styles = "padding:5px;background:green;color:white";
        console.log("%cTEST", styles);
        console.log(...args);
        console.log("%cEND", styles);
      },
    };
  }
  window.logger = logger;
};
