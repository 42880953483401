// @flow

/* PERMISSIONS */
const onCheckResource = (apiResource: Object, resources: Array<string>) => {
  for (let resource in apiResource) {
    if (
      resources.indexOf(resource) !== -1 &&
      apiResource[resource]["isEnabled"]
    ) {
      return true;
    }
  }
  return false;
};

export const onCheckFilesPermissions = (permissions: ?Object): boolean => {
  const resources = [
    "SuperCompanyAdministrators#Files:create",
    "SuperCompanyAdministrators#Files:read",
    "SuperCompanyAdministrators#Files:update",
    "SuperCompanyAdministrators#Files:delete",
    "CompanyAdministrators#Files:create",
    "CompanyAdministrators#Files:read",
    "CompanyAdministrators#Files:update",
    "CompanyAdministrators#Files:delete",
    "Coordinators#Files:create",
    "Coordinators#Files:read",
    "Coordinators#Files:update",
    "Coordinators#Files:delete",
    "Faculties#Files:create",
    "Faculties#Files:read",
    "Faculties#Files:update",
    "Faculties#Files:delete",
    "Students#Files:create",
    "Students#Files:read",
    "Students#Files:update",
    "Students#Files:delete",
    "InstitutionAdministrators#Files:create",
    "InstitutionAdministrators#Files:read",
    "InstitutionAdministrators#Files:update",
    "InstitutionAdministrators#Files:delete",
    "Preceptors#Files:create",
    "Preceptors#Files:read",
    "Preceptors#Files:update",
    "Preceptors#Files:delete",
    "Companies#Files:create",
    "Companies#Files:read",
    "Companies#Files:update",
    "Companies#Files:delete",
    "Institutions#Files:create",
    "Institutions#Files:read",
    "Institutions#Files:update",
    "Institutions#Files:delete",
    "my:Students#Files:create",
    "my:Students#Files:read",
    "my:Students#Files:update",
    "my:Students#Files:delete",
    "my:InstitutionAdministrators#Files:create",
    "my:InstitutionAdministrators#Files:read",
    "my:InstitutionAdministrators#Files:update",
    "my:InstitutionAdministrators#Files:delete",
    "my:Preceptors#Files:create",
    "my:Preceptors#Files:read",
    "my:Preceptors#Files:update",
    "my:Preceptors#Files:delete",
    "my:Companies#Files:create",
    "my:Companies#Files:read",
    "my:Companies#Files:update",
    "my:Companies#Files:delete",
    "my:Institutions#Files:create",
    "my:Institutions#Files:read",
    "my:Institutions#Files:update",
    "my:Institutions#Files:delete",
    "my#Files:create",
    "my#Files:read",
    "my#Files:update",
    "my#Files:delete",
  ];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
// USERS FILES
export const onCheckUsersFilesPermissions = (permissions: ?Object): boolean => {
  const resources = [
    "SuperCompanyAdministrators#Files:create",
    "SuperCompanyAdministrators#Files:read",
    "SuperCompanyAdministrators#Files:update",
    "SuperCompanyAdministrators#Files:delete",
    "CompanyAdministrators#Files:create",
    "CompanyAdministrators#Files:read",
    "CompanyAdministrators#Files:update",
    "CompanyAdministrators#Files:delete",
    "Coordinators#Files:create",
    "Coordinators#Files:read",
    "Coordinators#Files:update",
    "Coordinators#Files:delete",
    "Faculties#Files:create",
    "Faculties#Files:read",
    "Faculties#Files:update",
    "Faculties#Files:delete",
    "Students#Files:create",
    "Students#Files:read",
    "Students#Files:update",
    "Students#Files:delete",
    "InstitutionAdministrators#Files:create",
    "InstitutionAdministrators#Files:read",
    "InstitutionAdministrators#Files:update",
    "InstitutionAdministrators#Files:delete",
    "Preceptors#Files:create",
    "Preceptors#Files:read",
    "Preceptors#Files:update",
    "Preceptors#Files:delete",
    "my:Students#Files:create",
    "my:Students#Files:read",
    "my:Students#Files:update",
    "my:Students#Files:delete",
    "my:InstitutionAdministrators#Files:create",
    "my:InstitutionAdministrators#Files:read",
    "my:InstitutionAdministrators#Files:update",
    "my:InstitutionAdministrators#Files:delete",
    "my:Preceptors#Files:create",
    "my:Preceptors#Files:read",
    "my:Preceptors#Files:update",
    "my:Preceptors#Files:delete",
  ];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckReadUsersRoleFiles = (
  { type: roleType }: { type: string },
  permissions: ?Object
) => {
  switch (roleType) {
    case "myprofile":
      return onChecReadkMyFilesPermissions(permissions);
    case "supercompanyadministrators":
      return onCheckReadSuperCompanyAdministratorsFilesPermissions(permissions);
    case "companyadministrators":
      return onCheckReadCompanyAdministratorsFilesPermissions(permissions);
    case "coordinators":
      return onCheckReadCoordinatorsFilesPermissions(permissions);
    case "faculties":
      return onCheckReadFacultiesFilesPermissions(permissions);
    case "institutionadministrators":
      return (
        onCheckReadInstitutionAdministratorsFilesPermissions(permissions) ||
        onCheckReadMyInstitutionAdministratorsFilesPermissions(permissions)
      );
    case "preceptors":
      return (
        onCheckReadPreceptorsFilesPermissions(permissions) ||
        onCheckReadMyPreceptorsFilesPermissions(permissions)
      );
    case "students":
      return (
        onCheckReadStudentsFilesPermissions(permissions) ||
        onCheckReadMyStudentsFilesPermissions(permissions)
      );

    default:
  }
  return false;
};
export const onCheckCreateUsersRoleFiles = (
  { type: roleType }: { type: string },
  permissions: ?Object
) => {
  switch (roleType) {
    case "myprofile":
      return onCheckCreateMyFilesPermissions(permissions);
    case "supercompanyadministrators":
      return onCheckCreateSuperCompanyAdministratorsFilesPermissions(
        permissions
      );
    case "companyadministrators":
      return onCheckCreateCompanyAdministratorsFilesPermissions(permissions);
    case "coordinators":
      return onCheckCreateCoordinatorsFilesPermissions(permissions);
    case "faculties":
      return onCheckCreateFacultiesFilesPermissions(permissions);
    case "institutionadministrators":
      return (
        onCheckCreateInstitutionAdministratorsFilesPermissions(permissions) ||
        onCheckCreateMyInstitutionAdministratorsFilesPermissions(permissions)
      );
    case "preceptors":
      return (
        onCheckCreatePreceptorsFilesPermissions(permissions) ||
        onCheckCreateMyPreceptorsFilesPermissions(permissions)
      );
    case "students":
      return (
        onCheckCreateStudentsFilesPermissions(permissions) ||
        onCheckCreateMyStudentsFilesPermissions(permissions)
      );
    default:
  }
  return false;
};
export const onCheckEditUsersRoleFiles = (
  { type: roleType }: { type: string },
  permissions: ?Object
) => {
  switch (roleType) {
    case "myprofile":
      return onCheckEditMyFilesPermissions(permissions);
    case "supercompanyadministrators":
      return onCheckEditSuperCompanyAdministratorsFilesPermissions(permissions);
    case "companyadministrators":
      return onCheckEditCompanyAdministratorsFilesPermissions(permissions);
    case "coordinators":
      return onCheckEditCoordinatorsFilesPermissions(permissions);
    case "faculties":
      return onCheckEditFacultiesFilesPermissions(permissions);
    case "institutionadministrators":
      return (
        onCheckEditInstitutionAdministratorsFilesPermissions(permissions) ||
        onCheckEditMyInstitutionAdministratorsFilesPermissions(permissions)
      );
    case "preceptors":
      return (
        onCheckEditPreceptorsFilesPermissions(permissions) ||
        onCheckEditMyPreceptorsFilesPermissions(permissions)
      );
    case "students":
      return (
        onCheckEditStudentsFilesPermissions(permissions) ||
        onCheckEditMyStudentsFilesPermissions(permissions)
      );
    default:
  }
  return false;
};
export const onCheckDeleteUsersRoleFiles = (
  { type: roleType }: { type: string },
  permissions: ?Object
) => {
  switch (roleType) {
    case "myprofile":
      return onCheckDeleteMyFilesPermissions(permissions);
    case "supercompanyadministrators":
      return onCheckDeleteSuperCompanyAdministratorsFilesPermissions(
        permissions
      );
    case "companyadministrators":
      return onCheckDeleteCompanyAdministratorsFilesPermissions(permissions);
    case "coordinators":
      return onCheckDeleteCoordinatorsFilesPermissions(permissions);
    case "faculties":
      return onCheckDeleteFacultiesFilesPermissions(permissions);
    case "institutionadministrators":
      return (
        onCheckDeleteInstitutionAdministratorsFilesPermissions(permissions) ||
        onCheckDeleteMyInstitutionAdministratorsFilesPermissions(permissions)
      );
    case "preceptors":
      return (
        onCheckDeletePreceptorsFilesPermissions(permissions) ||
        onCheckDeleteMyPreceptorsFilesPermissions(permissions)
      );
    case "students":
      return (
        onCheckDeleteStudentsFilesPermissions(permissions) ||
        onCheckDeleteMyStudentsFilesPermissions(permissions)
      );
    default:
  }
  return false;
};
// />
// COMPANIES FILES
export const onCheckCompaniesFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "Companies#Files:create",
    "Companies#Files:read",
    "Companies#Files:update",
    "Companies#Files:delete",
  ];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckReadCompaniesFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Companies#Files:read"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckCreateCompaniesFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Companies#Files:create"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckEditCompaniesFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Companies#Files:update"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckDeleteCompaniesFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Companies#Files:delete"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
// />
// MY COMPANIES FILES
export const onCheckMyCompaniesFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "my:Companies#Files:create",
    "my:Companies#Files:read",
    "my:Companies#Files:update",
    "my:Companies#Files:delete",
  ];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckReadMyCompaniesFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:Companies#Files:read"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckCreateMyCompaniesFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:Companies#Files:create"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckEditMyCompaniesFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:Companies#Files:update"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckDeleteMyCompaniesFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:Companies#Files:delete"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
// />
// INSTITUTIONS FILES
export const onCheckInstitutionsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "Institutions#Files:create",
    "Institutions#Files:read",
    "Institutions#Files:update",
    "Institutions#Files:delete",
  ];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckReadInstitutionsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Institutions#Files:read"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckCreateInstitutionsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Institutions#Files:create"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckEditInstitutionsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Institutions#Files:update"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckDeleteInstitutionsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Institutions#Files:delete"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
// />
// MY INSTITUTIONS FILES
export const onCheckMyInstitutionsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "my:Institutions#Files:create",
    "my:Institutions#Files:read",
    "my:Institutions#Files:update",
    "my:Institutions#Files:delete",
  ];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckReadMyInstitutionsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:Institutions#Files:read"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckCreateMyInstitutionsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:Institutions#Files:create"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckEditMyInstitutionsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:Institutions#Files:update"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckDeleteMyInstitutionsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:Institutions#Files:delete"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
// />
// USERS FILES
export const onCheckAllUsersFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "SuperCompanyAdministrators#Files:create",
    "SuperCompanyAdministrators#Files:read",
    "SuperCompanyAdministrators#Files:update",
    "SuperCompanyAdministrators#Files:delete",
    "CompanyAdministrators#Files:create",
    "CompanyAdministrators#Files:read",
    "CompanyAdministrators#Files:update",
    "CompanyAdministrators#Files:delete",
    "Coordinators#Files:create",
    "Coordinators#Files:read",
    "Coordinators#Files:update",
    "Coordinators#Files:delete",
    "Faculties#Files:create",
    "Faculties#Files:read",
    "Faculties#Files:update",
    "Faculties#Files:delete",
    "Students#Files:create",
    "Students#Files:read",
    "Students#Files:update",
    "Students#Files:delete",
    "InstitutionAdministrators#Files:create",
    "InstitutionAdministrators#Files:read",
    "InstitutionAdministrators#Files:update",
    "InstitutionAdministrators#Files:delete",
    "Preceptors#Files:create",
    "Preceptors#Files:read",
    "Preceptors#Files:update",
    "Preceptors#Files:delete",
    "my:Students#Files:create",
    "my:Students#Files:read",
    "my:Students#Files:update",
    "my:Students#Files:delete",
    "my:InstitutionAdministrators#Files:create",
    "my:InstitutionAdministrators#Files:read",
    "my:InstitutionAdministrators#Files:update",
    "my:InstitutionAdministrators#Files:delete",
    "my:Preceptors#Files:create",
    "my:Preceptors#Files:read",
    "my:Preceptors#Files:update",
    "my:Preceptors#Files:delete",
  ];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckReadAllUsersFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "SuperCompanyAdministrators#Files:read",
    "CompanyAdministrators#Files:read",
    "Coordinators#Files:read",
    "Faculties#Files:read",
    "Students#Files:read",
    "InstitutionAdministrators#Files:read",
    "Preceptors#Files:read",
    "my:Students#Files:read",
    "my:InstitutionAdministrators#Files:read",
    "my:Preceptors#Files:read",
  ];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckCreateAllUsersFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "SuperCompanyAdministrators#Files:create",
    "CompanyAdministrators#Files:create",
    "Coordinators#Files:create",
    "Faculties#Files:create",
    "Students#Files:create",
    "InstitutionAdministrators#Files:create",
    "Preceptors#Files:create",
    "my:Students#Files:create",
    "my:InstitutionAdministrators#Files:create",
    "my:Preceptors#Files:create",
  ];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckEditAllUsersFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "SuperCompanyAdministrators#Files:update",
    "CompanyAdministrators#Files:update",
    "Coordinators#Files:update",
    "Faculties#Files:update",
    "Students#Files:update",
    "InstitutionAdministrators#Files:update",
    "Preceptors#Files:update",
    "my:Students#Files:update",
    "my:InstitutionAdministrators#Files:update",
    "my:Preceptors#Files:update",
  ];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckDeleteAllUsersFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "SuperCompanyAdministrators#Files:delete",
    "CompanyAdministrators#Files:delete",
    "Coordinators#Files:delete",
    "Faculties#Files:delete",
    "Students#Files:delete",
    "InstitutionAdministrators#Files:delete",
    "Preceptors#Files:delete",
    "my:Students#Files:delete",
    "my:InstitutionAdministrators#Files:delete",
    "my:Preceptors#Files:delete",
  ];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
// SuperCompanyAdministrators
export const onCheckReadSuperCompanyAdministratorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["SuperCompanyAdministrators#Files:read"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckCreateSuperCompanyAdministratorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["SuperCompanyAdministrators#Files:create"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckEditSuperCompanyAdministratorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["SuperCompanyAdministrators#Files:update"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckDeleteSuperCompanyAdministratorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["SuperCompanyAdministrators#Files:delete"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
// CompanyAdministrators
export const onCheckReadCompanyAdministratorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["CompanyAdministrators#Files:read"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckCreateCompanyAdministratorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["CompanyAdministrators#Files:create"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckEditCompanyAdministratorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["CompanyAdministrators#Files:update"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckDeleteCompanyAdministratorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["CompanyAdministrators#Files:delete"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
// Coordinators
export const onCheckReadCoordinatorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Coordinators#Files:read"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckCreateCoordinatorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Coordinators#Files:create"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckEditCoordinatorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Coordinators#Files:update"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckDeleteCoordinatorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Coordinators#Files:delete"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
// Faculties
export const onCheckReadFacultiesFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Faculties#Files:read"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckCreateFacultiesFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Faculties#Files:create"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckEditFacultiesFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Faculties#Files:update"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckDeleteFacultiesFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Faculties#Files:delete"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
// Students
export const onCheckReadStudentsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Students#Files:read"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckReadMyStudentsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:Students#Files:read"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckCreateStudentsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Students#Files:create"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckCreateMyStudentsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:Students#Files:create"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckEditStudentsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Students#Files:update"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckEditMyStudentsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:Students#Files:update"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckDeleteStudentsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Students#Files:delete"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckDeleteMyStudentsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:Students#Files:delete"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
// InstitutionAdministrators
export const onCheckReadInstitutionAdministratorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["InstitutionAdministrators#Files:read"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckReadMyInstitutionAdministratorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:InstitutionAdministrators#Files:read"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckCreateInstitutionAdministratorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["InstitutionAdministrators#Files:create"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckCreateMyInstitutionAdministratorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:InstitutionAdministrators#Files:create"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckEditInstitutionAdministratorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["InstitutionAdministrators#Files:update"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckEditMyInstitutionAdministratorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:InstitutionAdministrators#Files:update"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckDeleteInstitutionAdministratorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["InstitutionAdministrators#Files:delete"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckDeleteMyInstitutionAdministratorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:InstitutionAdministrators#Files:delete"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
// Preceptors
export const onCheckReadPreceptorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Preceptors#Files:read"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckReadMyPreceptorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:Preceptors#Files:read"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckCreatePreceptorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Preceptors#Files:create"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckCreateMyPreceptorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:Preceptors#Files:create"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckEditPreceptorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Preceptors#Files:update"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckEditMyPreceptorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:Preceptors#Files:update"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckDeletePreceptorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Preceptors#Files:delete"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckDeleteMyPreceptorsFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:Preceptors#Files:delete"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
// />
// MY FILES
export const onCheckMyFilesPermissions = (permissions: ?Object): boolean => {
  const resources = [
    "my#Files:create",
    "my#Files:read",
    "my#Files:update",
    "my#Files:delete",
  ];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onChecReadkMyFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my#Files:read"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckCreateMyFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my#Files:create"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckEditMyFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my#Files:update"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
export const onCheckDeleteMyFilesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my#Files:delete"];
  const { Files } = permissions || {};
  if (Files && Files.apiValue) {
    return onCheckResource(Files.apiValue, resources);
  }
  return false;
};
// />
