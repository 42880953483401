import { createAction } from "deox";
// Actions for API
// Init Api
export const INIT_API: string = "API/INIT_API";
export const initAPI = createAction(
  INIT_API,
  (resolve) => (payload?: object) => resolve(payload)
);
export const ADD_CANCEL_TOKEN_API: string = "API/ADD/CANCEL_TOKEN_API";
export const addCancelTokenAPI = createAction(
  ADD_CANCEL_TOKEN_API,
  (resolve) => (payload?: object) => resolve(payload)
);
export const REMOVE_CANCEL_TOKEN_API: string = "API/REMOVE/CANCEL_TOKEN_API";
export const removeCancelTokenAPI = createAction(
  REMOVE_CANCEL_TOKEN_API,
  (resolve) => (payload?: object) => resolve(payload)
);
