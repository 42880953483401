// Import Features
import produce from "immer";
import { app as appJson } from "../../app.json";
import {
  isMobileBrowser,
  isTouchableDevice,
  onGetUrlDomain,
  onGetTenant,
} from "../../utils";
// Types
import type { Action } from "deox";
type State = {};
// CONFIG Reducer
const initialState: State = {
  initAPP: true,
  ...appJson,
  origin: window.location.origin
    ? window.location.origin
    : `${window.location.protocol}//${window.location.host}`,
  protocol: `${window.location.protocol}//`,
  hostname: window.location.hostname,
  port: window.location.port.length > 0 ? `:${window.location.port}` : "",
  websitename: appJson.name,
  baseDomain: onGetUrlDomain(),
  suffix: ".com",
  companyDomain: onGetTenant(),
  baseImg: `https://${onGetTenant()}.${appJson.appURL}`,
  api: {
    protocol: "https://",
    tenant: onGetTenant(),
    base: `${appJson.appURL}`,
    version: "v1",
  },
  socketUri: `wss://ws.${appJson.appURL}`,
  isMobileBrowser: isMobileBrowser(),
  isTouchableDevice: isTouchableDevice(),
  version: appJson.version,
  buildDate: appJson.appBuildDate,
};
const config: (state: State, action: Action<string>) => State = produce(
  (draft: State, action: Action<string>) => draft,
  initialState
);
export default config;
