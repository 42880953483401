// Features
import { Rotations } from "../actions/";
// Types
import type { Action, PayloadObject } from "../../types/common.types";
import type { StateRotations as State } from "../../types/rotations.types";
// Rotations Reducer:
export const ROTATIONS_LIMIT = 20;
const initialStateRotationCreateEdit: State["rotationCreateEdit"] = {
  isEditRotation: false,
  institutions: {
    options: [],
    selected: [],
  },
  students: null,
  student: null,
  period: null,
  start: "",
  end: "",
  canConfirmRotation: false,
};
const initialState: State = {
  rotation: null,
  rotationId: "",
  rotations: [],
  rotationsStatus: [],
  rotationsMetaData: {
    numTotalRotations: 0,
    numTotalRotationsInCourse: 0,
    numTotalRotationsUpcoming: 0,
    numTotalRotationsFinished: 0,
    totalPages: 1,
  },
  fetchingRotations: false,
  rotationCreateEdit: initialStateRotationCreateEdit,
  kpis: {
    fetching: false,
    Rotations: null,
  },
  rotationsAutomatic: {
    automaticRotationsParameterSelected: null,
    draftRotations: [],
    createdAt: null,
  },
};
export default function rotations(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case Rotations.ROTATIONS_FETCH_BY_FILTERS:
      return Object.assign({}, state, {
        fetchingRotations: true,
      });
    case Rotations.ROTATIONS_FETCH_BY_FILTERS_FAILURE:
      return Object.assign({}, state, {
        fetchingRotations: false,
      });
    case Rotations.ROTATIONS_FETCH_BY_FILTERS_SUCCESS:
      const {
        incourse = 0,
        upcoming = 0,
        finished = 0,
      } = action.payload.metadata || {
        incourse: action.payload.rotations.filter(
          ({ status }: PayloadObject) => status === "incourse"
        ).length,
        upcoming: action.payload.rotations.filter(
          ({ status }: PayloadObject) => status === "upcoming"
        ).length,
        finished: action.payload.rotations.filter(
          ({ status }: PayloadObject) => status === "finished"
        ).length,
      };
      const numTotalRotations = incourse + upcoming + finished;
      return Object.assign({}, state, {
        rotations: action.payload.rotations,
        rotationsStatus: action.payload.rotationsStatus,
        rotationsMetaData: {
          numTotalRotations,
          numTotalRotationsInCourse: incourse,
          numTotalRotationsUpcoming: upcoming,
          numTotalRotationsFinished: finished,
          totalPages:
            1 +
            parseInt(
              ((numTotalRotations - 1) / ROTATIONS_LIMIT).toString(),
              10
            ),
        },
        fetchingRotations: false,
      });
    case Rotations.ROTATIONS_FETCH_KPIS_REQUEST:
      return Object.assign({}, state, {
        kpis: {
          ...state.kpis,
          fetching: true,
        },
      });
    case Rotations.ROTATIONS_FETCH_KPIS_SUCCESS:
    case Rotations.ROTATIONS_FETCH_KPIS_FAILURE:
      return Object.assign({}, state, {
        kpis: {
          ...state.kpis,
          ...action.payload.kpis,
          fetching: false,
        },
      });
    case Rotations.ROTATIONS_SET_AUTOMATIC_ROTATIONS_SUCCESS:
    case Rotations.ROTATIONS_UPDATE_AUTOMATIC_ROTATIONS:
      return Object.assign({}, state, {
        rotationsAutomatic: {
          automaticRotationsParameterSelected:
            action.payload.automaticRotationsParameterSelected,
          draftRotations: action.payload.rotations,
          createdAt: new Date(),
        },
      });
    case Rotations.ROTATIONS_UNSET_AUTOMATIC_ROTATIONS:
      return Object.assign({}, state, {
        rotationsAutomatic: {
          automaticRotationsParameterSelected:
            initialState.rotationsAutomatic.automaticRotationsParameterSelected,
          draftRotations: initialState.rotationsAutomatic.draftRotations,
          createdAt: initialState.rotationsAutomatic.createdAt,
        },
      });
    case Rotations.ROTATIONS_CREATE_EDIT_UPDATE:
      const newRotationCreateEdit = {
        ...state.rotationCreateEdit,
        ...action.payload.data,
      };
      let hasStudentsSelected = false;
      if (
        state.rotation &&
        state.rotationId &&
        newRotationCreateEdit.isEditRotation
      ) {
        if (newRotationCreateEdit.student) {
          hasStudentsSelected = true;
        }
      } else {
        hasStudentsSelected =
          Object.keys(newRotationCreateEdit.students || {}).length > 0;
      }
      newRotationCreateEdit.canConfirmRotation =
        hasStudentsSelected &&
        newRotationCreateEdit.institutions.selected.length > 0 &&
        newRotationCreateEdit.start &&
        newRotationCreateEdit.end
          ? true
          : false;
      return Object.assign({}, state, {
        rotationCreateEdit: newRotationCreateEdit,
      });
    case Rotations.ROTATIONS_CREATE_EDIT_RESET:
      return Object.assign({}, state, {
        rotation: null,
        rotationId: null,
        rotationCreateEdit: initialStateRotationCreateEdit,
      });
    case Rotations.ROTATIONS_SET_ROTATION_ID:
      return Object.assign({}, state, {
        rotationId: action.payload.rotationId,
      });
    case Rotations.ROTATIONS_FETCH_ROTATION_SUCCESS:
      return Object.assign({}, state, {
        rotation: action.payload.rotation,
      });
    case Rotations.ROTATIONS_EDIT_SUCCESS:
      const rotationUpdateIndex = state.rotations
        .map(({ _id }) => _id)
        .indexOf(action.payload.rotation._id);
      let newListUpdatedRotations = [...state.rotations];
      if (rotationUpdateIndex >= 0) {
        if (
          state.rotationsStatus.indexOf(action.payload.rotation.status) !== -1
        ) {
          newListUpdatedRotations = [
            ...state.rotations.slice(0, rotationUpdateIndex),
            action.payload.rotation,
            ...state.rotations.slice(rotationUpdateIndex + 1),
          ];
        } else {
          newListUpdatedRotations = [
            ...state.rotations.slice(0, rotationUpdateIndex),
            ...state.rotations.slice(rotationUpdateIndex + 1),
          ];
        }
      }
      return Object.assign({}, state, {
        rotation: action.payload.rotation,
        rotations: newListUpdatedRotations,
        rotationsMetaData: {
          ...state.rotationsMetaData,
          numTotalRotationsInCourse:
            state.rotation &&
            action.payload.rotation &&
            state.rotation.status === action.payload.rotation.status
              ? state.rotationsMetaData.numTotalRotationsInCourse
              : action.payload.rotation.status === "incourse"
              ? state.rotationsMetaData.numTotalRotationsInCourse + 1
              : state.rotationsMetaData.numTotalRotationsInCourse - 1,
          numTotalRotationsUpcoming:
            state.rotation &&
            action.payload.rotation &&
            state.rotation.status === action.payload.rotation.status
              ? state.rotationsMetaData.numTotalRotationsUpcoming
              : action.payload.rotation.status === "upcoming"
              ? state.rotationsMetaData.numTotalRotationsUpcoming + 1
              : state.rotationsMetaData.numTotalRotationsUpcoming - 1,
        },
      });
    case Rotations.ROTATIONS_DELETE_SUCCESS:
      return Object.assign({}, state, {
        rotations: state.rotations.filter(
          ({ _id }) => _id !== action.payload.rotationId
        ),
      });
    // same state (not change)
    default:
      return state;
  }
}
