// @flow

/* PERMISSIONS */
const onCheckResource = (apiResource: Object, resources: Array<string>) => {
  for (let resource in apiResource) {
    if (
      resources.indexOf(resource) !== -1 &&
      apiResource[resource]["isEnabled"]
    ) {
      return true;
    }
  }
  return false;
};

export const onCheckEvaluationAnswersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "EvaluationAnswers:read",
    "EvaluationAnswers:delete",
    "my#EvaluationAnswers:create",
    "my#EvaluationAnswers:read",
    "my#EvaluationAnswers:update",
  ];
  const { EvaluationAnswers } = permissions || {};
  if (EvaluationAnswers && EvaluationAnswers.apiValue) {
    return onCheckResource(EvaluationAnswers.apiValue, resources);
  }
  return false;
};
export const onCheckReadAllEvaluationAnswersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["EvaluationAnswers:read"];
  const { EvaluationAnswers } = permissions || {};
  if (EvaluationAnswers && EvaluationAnswers.apiValue) {
    return onCheckResource(EvaluationAnswers.apiValue, resources);
  }
  return false;
};
export const onCheckReadMyEvaluationAnswersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my#EvaluationAnswers:read"];
  const { EvaluationAnswers } = permissions || {};
  if (EvaluationAnswers && EvaluationAnswers.apiValue) {
    return onCheckResource(EvaluationAnswers.apiValue, resources);
  }
  return false;
};
export const onCheckDeleteEvaluationsAnswersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["EvaluationAnswers:delete"];
  const { EvaluationAnswers } = permissions || {};
  if (EvaluationAnswers && EvaluationAnswers.apiValue) {
    return onCheckResource(EvaluationAnswers.apiValue, resources);
  }
  return false;
};
