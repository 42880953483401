// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SignOutContainer from './SignOutContainer';
import ProtectedRoute from '../shared/ProtectedRoute';

const SignOutRoute: React.ComponentType<any> = withRouter(
  connect(({ config, auth: { isAuthenticated, expiredToken } }, { path }) => {
    return {
      component: SignOutContainer,
      restProps: {
        isAuthenticated,
        config,
        isTokenExpired: path === '/signout/session-expired' ? true : false
      },
      isAllowedRoute: true,
      redirectTo: '/home'
    };
  })(ProtectedRoute)
);

export default SignOutRoute;
