import type { Config, tAPI } from "./API";
import type { StateUsersManager } from "../../types/users.types";
// API Evaluations static class
const apiBaseEvaluations = "/evaluations";
const apiBaseEvaluationsSchedulers = "/evaluation-schedulers";
const apiBaseEvaluationsTemplates = "/evaluation-templates";
export default class Evaluations {
  //////
  // API EVALUATIONS
  //////
  // Get a list of evaluations depend on case:
  // student: UserID, preceptor: UserID, institution: InstitutionID)
  static GetEvaluations(
    api: tAPI,
    populated: boolean,
    skip: number,
    limit: number,
    evaluationTypes: string[],
    status: string[],
    userStatus: StateUsersManager["filters"]["status"],
    answerStatus: string[],
    tags: string[],
    evaluators: string[],
    targets: string[],
    programs: string[],
    cohorts: string[],
    institutions: string[],
    search: string,
    startDate: string,
    endDate: string,
    sortBy: {
      target: "ascending" | "descending" | "";
      evaluator: "ascending" | "descending" | "";
      createdAt: "ascending" | "descending" | "";
      closingDate: "ascending" | "descending" | "";
      status: "ascending" | "descending" | "";
    },
    config?: Config
  ) {
    let queryParams = "";
    if (skip >= 0) {
      queryParams += `&skip=${skip}`;
    }
    if (limit > 0) {
      queryParams += `&limit=${limit}`;
    }
    if (evaluationTypes.length > 0) {
      queryParams += `&evaluationTypes=${evaluationTypes.join()}`;
    }
    if (answerStatus.length > 0) {
      queryParams += `&answerStatus=${answerStatus.join()}`;
      if (evaluators.length > 0) {
        queryParams += `&evaluators=${evaluators.join()}`;
      }
      if (targets.length > 0) {
        queryParams += `&targets=${targets.join()}`;
      }
    }
    if (status.length > 0) {
      queryParams += `&status=${status.join()}`;
    }
    if (userStatus.length > 0) {
      queryParams += `&userStatus=${userStatus.join()}`;
    }
    if (tags.length > 0) {
      queryParams += `&tags=${tags.join()}`;
    }
    if (programs.length > 0) {
      queryParams += `&programs=${programs.join()}`;
    }
    if (cohorts.length > 0) {
      queryParams += `&cohorts=${cohorts.join()}`;
    }
    if (institutions.length > 0) {
      queryParams += `&institutions=${institutions.join()}`;
    }
    if (search) {
      queryParams += `&search=${search}`;
    }
    if (startDate) {
      queryParams += `&start=${startDate}`;
    }
    if (endDate) {
      queryParams += `&end=${endDate}`;
    }
    if (populated) {
      let sortByParams = Object.keys(sortBy)
        // @ts-ignore
        .filter((sortKey) => sortBy[sortKey])
        .map(
          (sortName) =>
            // @ts-ignore
            `sort_${sortName}=${sortBy[sortName] === "ascending" ? 1 : -1}`
        )
        .join("&");
      if (sortByParams) {
        sortByParams = "&" + sortByParams;
      }
      queryParams = "?populated=true" + queryParams + sortByParams;
    } else if (queryParams.length > 0) {
      queryParams = "?" + queryParams.slice(1);
    }
    return api.GET(`${apiBaseEvaluations}${queryParams}`, config);
  }
  // Get Evaluations KPIS
  static GetEvaluationsKpis(api: tAPI, config?: Config) {
    return api.GET(`${apiBaseEvaluations}/kpis`, config);
  }
  // Get Evaluations Answers KPIS
  static GetEvaluationsAnswersKpis(
    api: tAPI,
    tags: string[],
    unique: boolean,
    config?: Config
  ) {
    return api.GET(
      `${apiBaseEvaluations}/answers/kpis?unique=${unique}&tags=${tags.join()}`,
      config
    );
  }
  // Get a evaluation
  static GetEvaluation(
    api: tAPI,
    evaluationId: string,
    getAnswers: boolean = false,
    answerId?: string,
    config?: Config
  ) {
    return api.GET(
      `${apiBaseEvaluations}/${evaluationId}${
        getAnswers ? `/answers${answerId ? "/" + answerId : ""}` : ""
      }?populated=true`,
      config
    );
  }
  // Create a Evaluations depend on case:
  // Rotations (Finished) or CaseLogs (Approved)
  static CreateEvaluation(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBaseEvaluations}`, data, config);
  }
  // Edit a Evaluation (closing date expired)
  static EditEvaluation(
    api: tAPI,
    evaluationId: string,
    data: object,
    config?: Config
  ) {
    return api.PATCH(`${apiBaseEvaluations}/${evaluationId}`, data, config);
  }
  // Delete a Evaluation
  static DeleteEvaluation(api: tAPI, evaluationId: string, config?: Config) {
    return api.DELETE(`${apiBaseEvaluations}/${evaluationId}`, config);
  }
  //////
  // API EVALUATIONS > ANSWERS
  //////
  // Api for Evaluations Answers
  // Get a evaluation
  static GetEvaluationAnswers(
    api: tAPI,
    populated: boolean,
    skip: number,
    limit: number,
    evaluationTypes: string[],
    status: string[],
    evaluators: string[],
    search: string,
    startDate: string,
    endDate: string,
    config?: Config
  ) {
    let queryParams = "";
    if (skip >= 0) {
      queryParams += `&skip=${skip}`;
    }
    if (limit > 0) {
      queryParams += `&limit=${limit}`;
    }
    if (evaluationTypes.length > 0) {
      queryParams += `&evaluationAnswerTypes=${evaluationTypes.join()}`;
    }
    // if (status.length > 0) {
    //   queryParams += `&status=${status.join()}`;
    // }
    // if (evaluators.length > 0) {
    //   queryParams += `&status=${status.join()}`;
    // }
    if (search) {
      queryParams += `&search=${search}`;
    }
    if (startDate) {
      queryParams += `&start=${startDate}`;
    }
    if (endDate) {
      queryParams += `&end=${endDate}`;
    }
    if (populated) {
      queryParams = "?populated=true" + queryParams;
    } else if (queryParams.length > 0) {
      queryParams = "?" + queryParams.slice(1);
    }
    return api.GET(`${apiBaseEvaluations}/answers${queryParams}`, config);
  }
  static ResponseEvaluationAnswer(
    api: tAPI,
    evaluationId: string,
    answerId: string,
    data: object,
    config?: Config
  ) {
    if (answerId) {
      return api.PATCH(
        `${apiBaseEvaluations}/${evaluationId}/answers/${answerId}`,
        data,
        config
      );
    }
    return api.POST(
      `${apiBaseEvaluations}/${evaluationId}/answers`,
      data,
      config
    );
  }
  // Delete a Evaluation Answer
  static DeleteEvaluationAnswer(
    api: tAPI,
    evaluationId: string,
    answerId: string,
    config?: Config
  ) {
    return api.DELETE(
      `${apiBaseEvaluations}/${evaluationId}/answers/${answerId}`,
      config
    );
  }
  //////
  // API EVALUATIONS SCHEDULERS
  //////
  // API for Evaluations SCHEDULERS form
  static GetEvaluationsSchedulers(
    api: tAPI,
    evaluationTypes: string[],
    config?: Config
  ) {
    let queryParams = "?populated=true";
    if (evaluationTypes.length > 0) {
      queryParams += `&evaluationTypes=${evaluationTypes.join()}`;
    }
    return api.GET(`${apiBaseEvaluationsSchedulers}${queryParams}`, config);
  }
  // Create a evaluation schedulers form for some evaluators
  static CreateEvaluationSchedulers(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBaseEvaluationsSchedulers}`, data, config);
  }
  // Get a evaluation schedulers form
  static GetEvaluationSchedulers(
    api: tAPI,
    evaluationSchedulerId: string,
    config?: Config
  ) {
    return api.GET(
      `${apiBaseEvaluationsSchedulers}/${evaluationSchedulerId}?populated=true`,
      config
    );
  }
  // Edit a Evaluation schedulers form
  static EditEvaluationSchedulers(
    api: tAPI,
    evaluationSchedulerId: string,
    data: object,
    config?: Config
  ) {
    return api.PATCH(
      `${apiBaseEvaluationsSchedulers}/${evaluationSchedulerId}`,
      data,
      config
    );
  }
  // Delete a Evaluation schedulers form
  static DeleteEvaluationSchedulers(
    api: tAPI,
    evaluationSchedulerId: string,
    config?: Config
  ) {
    return api.DELETE(
      `${apiBaseEvaluationsSchedulers}/${evaluationSchedulerId}`,
      config
    );
  }
  //////
  // API EVALUATIONS TEMPLATES
  //////
  // API for Evaluations TEMPLATES form
  static GetEvaluationsTemplates(api: tAPI, config?: Config) {
    return api.GET(`${apiBaseEvaluationsTemplates}?populated=true`, config);
  }
  // Create a evaluation template form for some students evaluations
  static CreateEvaluationTemplates(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBaseEvaluationsTemplates}`, data, config);
  }
  // Get a evaluation template form
  static GetEvaluationTemplates(
    api: tAPI,
    evaluationTemplateId: string,
    config?: Config
  ) {
    return api.GET(
      `${apiBaseEvaluationsTemplates}/${evaluationTemplateId}?populated=true`,
      config
    );
  }
  // Edit a Evaluation template form
  static EditEvaluationTemplates(
    api: tAPI,
    evaluationTemplateId: string,
    data: object,
    config?: Config
  ) {
    return api.PATCH(
      `${apiBaseEvaluationsTemplates}/${evaluationTemplateId}`,
      data,
      config
    );
  }
  // Delete a Evaluation template form
  static DeleteEvaluationTemplates(
    api: tAPI,
    evaluationTemplateId: string,
    config?: Config
  ) {
    return api.DELETE(
      `${apiBaseEvaluationsTemplates}/${evaluationTemplateId}`,
      config
    );
  }
}
