import { put, select, call, all, takeLatest } from "redux-saga/effects";
import InvitationsApi from "../api/Invitations";
import { Auth, Invitations } from "../actions/";
// Type
import type { Action } from "deox";
import type { tAPI } from "../api/API";
//Sagas for Invitations
function* doInvitationsFetchInvitations(
  action: Action<
    string,
    {
      roles?: string[];
      emails?: string[];
      institutions?: string[];
      groupBy?: string[];
      status?: string[];
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Invitations.invitationsFetchInvitationsRequest());
  window.logger.log("doInvitationsFetchInvitations", action);
  const {
    roles = [],
    emails = [],
    institutions = [],
    groupBy = [],
    status = [],
    callbackSuccess = null,
    callbackError = null,
  } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get all invitations for this company
    const {
      data: { ok, ...remainUserInvitations },
    } = yield call(
      InvitationsApi.GetInvitations,
      api,
      roles,
      emails,
      institutions,
      groupBy,
      status,
      { token }
    );
    yield put(
      Invitations.invitationsFetchInvitationsSuccess({
        invitations:
          Object.keys(remainUserInvitations || {}).map(
            (userInvitation) => userInvitation
          ).length > 0
            ? {
                ...remainUserInvitations,
              }
            : null,
      })
    );
    if (callbackSuccess) {
      callbackSuccess({
        invitations: {
          ...remainUserInvitations,
        },
      });
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doInvitationsFetchInvitations",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Invitations.invitationsFetchInvitationsFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}
function* doInvitationsCreate(
  action: Action<
    string,
    {
      body: object;
      callbackSuccess?: (data: any, errors: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Invitations.invitationsCreateRequest());
  window.logger.log("doInvitationsCreate", action);
  const { body, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to create new invitations
    const { data } = yield call(InvitationsApi.CreateInvitations, api, body, {
      token,
    });
    const { userInvitations = [], errors = [] } = data || {};
    yield put(Invitations.invitationsCreateSuccess());
    yield put(
      Invitations.invitationsFetchInvitations({
        status: ["pending", "expired"],
      })
    );
    if (callbackSuccess) {
      callbackSuccess(userInvitations, errors);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doInvitationsCreate", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Invitations.invitationsCreateFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}
function* doInvitationsResend(
  action: Action<
    string,
    {
      body: object;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Invitations.invitationsResendRequest());
  window.logger.log("doInvitationsResend", action);
  const { body, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to resend some invitations
    yield call(InvitationsApi.ResendInvitations, api, body, { token });
    yield put(Invitations.invitationsResendSuccess());
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doInvitationsResend", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Invitations.invitationsResendFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}
function* doInvitationsActive(
  action: Action<
    string,
    {
      invitationId: string;
      body: object;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Invitations.invitationsActiveRequest());
  window.logger.log("doInvitationsActive", action);
  const {
    invitationId,
    body,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to active some invitations
    yield call(InvitationsApi.ActiveInvitation, api, invitationId, body, {
      token,
    });
    yield put(Invitations.invitationsActiveSuccess());
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doInvitationsActive", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Invitations.invitationsActiveFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}
function* doInvitationsDelete(
  action: Action<
    string,
    {
      invitationId: string;
      roleSelected: object;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Invitations.invitationsDeleteRequest());
  window.logger.log("doInvitationsDelete", action);
  const {
    invitationId,
    roleSelected,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to delete a invitation for that specific invitationId
    yield call(InvitationsApi.DeleteInvitation, api, invitationId, { token });
    yield put(
      Invitations.invitationsDeleteSuccess({ invitationId, roleSelected })
    );
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doInvitationsDelete", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Invitations.invitationsDeleteFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}
export default function* invitationsSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(
      Invitations.INVITATIONS_FETCH_INVITATIONS,
      doInvitationsFetchInvitations
    ),
    takeLatest(Invitations.INVITATIONS_CREATE, doInvitationsCreate),
    takeLatest(Invitations.INVITATIONS_RESEND, doInvitationsResend),
    takeLatest(Invitations.INVITATIONS_ACTIVE, doInvitationsActive),
    takeLatest(Invitations.INVITATIONS_DELETE, doInvitationsDelete),
  ]);
}
