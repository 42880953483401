// @flow

/* PERMISSIONS */
const onCheckResource = (apiResource: Object, resources: Array<string>) => {
  for (let resource in apiResource) {
    if (
      resources.indexOf(resource) !== -1 &&
      apiResource[resource]['isEnabled']
    ) {
      return true;
    }
  }
  return false;
};

export const onCheckNotificationsPermissions = (
  permissions: ?Object
): boolean => {
  // REGLA 'my#Notificatons:read' witten wrong
  const resources = [
    'my#Notificatons:read',
    'my#Notifications:read',
    'my#Notifications:update'
  ];
  const { Notifications } = permissions || {};
  if (Notifications && Notifications.apiValue) {
    return onCheckResource(Notifications.apiValue, resources);
  }
  return false;
};
