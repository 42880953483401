import { createAction } from "deox";

// Actions for LocalStore
export const LOCALSTORE_SAVE: string = "LOCALSTORE/SAVE";
export const saveLocalStore = createAction(
  LOCALSTORE_SAVE,
  (resolve) => (payload?: object) => resolve(payload)
);

export const LOCALSTORE_CLEAR_ALL: string = "LOCALSTORE/CLEAR_ALL";
export const clearAllLocalStore = createAction(LOCALSTORE_CLEAR_ALL);
