import { AccountSettings } from "../actions/";
// Import Types
import type { Action } from "../../types/common.types";
import type { StateAccountSettings as State } from "../../types/accountSettings.types";
// AccountSettings Reducer
export const appNewFeaturesState: State["appNewFeatures"] = {
  features: ["APP_FEATURES_MY_SETTINGS"],
  createdAt: new Date("2022-12-15").toISOString(),
  readAt: null,
  roles: [
    "SuperCompanyAdministrators",
    "CompanyAdministrators",
    "Coordinators",
    "Faculties",
    "InstitutionAdministrators",
    "Preceptors",
    "Students",
    "Alumnis",
  ],
};
const initialState: State = {
  accountSettingsNames: [
    "HOME_SETTINGS",
    "BROADCAST_MESSAGES_SETTINGS",
    "ROTATIONS_SETTINGS",
    "CASE_LOGS_SETTINGS",
    "EVALUATIONS_SETTINGS",
  ],
  appNewFeatures: appNewFeaturesState,
  frontendPersonalization: {
    HOME_SETTINGS: {
      widgetsToShow: [],
    },
    BROADCAST_MESSAGES_SETTINGS: {
      messagesWatched: [],
    },
    USER_MANAGER_SETTINGS: {
      sortBy: {
        name: "descending",
        enrollment: "",
        institution: "",
      },
      sortByName: "firstName",
      userStatus: ["active"],
    },
    ROTATIONS_SETTINGS: {
      sortBy: {
        student: "",
        institution: "",
        start_end: "",
        status: "",
      },
      userStatus: ["active"],
    },
    CASE_LOGS_SETTINGS: {
      sortBy: {
        student: "",
        preceptor: "",
        institution: "",
        createdAt: "",
        status: "",
      },
      userStatus: ["active"],
    },
    EVALUATIONS_SETTINGS: {
      sortBy: {
        target: "",
        evaluator: "",
        createdAt: "",
        closingDate: "",
        status: "",
      },
      userStatus: ["active"],
    },
  },
  isFetchingAccountSettings: false,
  statusUpdateAccountSettings: "",
};
export default function accountSettings(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    //AccountSettings
    case AccountSettings.ACCOUNT_SETTINGS_UPDATE_SETTING_NAME:
      return Object.assign({}, state, {
        frontendPersonalization: {
          ...state.frontendPersonalization,
          [action.payload.accountSettingsName]: action.payload.newSetting,
        },
      });
    case AccountSettings.ACCOUNT_SETTINGS_UPDATE_APP_NEW_FEATURES:
      return Object.assign({}, state, {
        appNewFeatures: {
          ...state.appNewFeatures,
          readAt: new Date().toISOString(),
        },
      });
    // FETCH
    case AccountSettings.ACCOUNT_SETTINGS_FETCH:
      return Object.assign({}, state, {
        isFetchingAccountSettings: true,
      });
    case AccountSettings.ACCOUNT_SETTINGS_FETCH_SUCCESS:
      let appNewFeatures = action.payload.appNewFeatures
        ? action.payload.appNewFeatures
        : state.appNewFeatures;
      if (initialState.appNewFeatures.createdAt > appNewFeatures.createdAt) {
        appNewFeatures = {
          ...initialState.appNewFeatures,
        };
      }
      return Object.assign({}, state, {
        appNewFeatures,
        frontendPersonalization: action.payload.frontendPersonalization,
        isFetchingAccountSettings: false,
      });
    case AccountSettings.ACCOUNT_SETTINGS_FETCH_FAILURE:
      return Object.assign({}, state, {
        isFetchingAccountSettings: false,
      });
    // EDIT
    case AccountSettings.ACCOUNT_SETTINGS_EDIT:
      return Object.assign({}, state, {
        statusUpdateAccountSettings: "updating",
      });
    case AccountSettings.ACCOUNT_SETTINGS_EDIT_SUCCESS:
      return Object.assign({}, state, {
        appNewFeatures: action.payload.appNewFeatures
          ? action.payload.appNewFeatures
          : state.appNewFeatures,
        frontendPersonalization: action.payload.frontendPersonalization,
        statusUpdateAccountSettings: "updated",
      });
    case AccountSettings.ACCOUNT_SETTINGS_EDIT_FAILURE:
      return Object.assign({}, state, {
        statusUpdateAccountSettings: "failed",
      });
    case AccountSettings.ACCOUNT_SETTINGS_EDIT_DONE:
      return Object.assign({}, state, {
        statusUpdateAccountSettings: "",
      });
    // same state (not change)
    default:
      return state;
  }
}
