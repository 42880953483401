import type { Config, tAPI } from "./API";
import type { State as StateNotifications } from "../../store/reducers/notifications";
// API Notifications static class
const apiBaseNotifications = "/notifications";
export default class Notifications {
  // Get a list of notifications
  static GetNotifications(
    api: tAPI,
    userId: string,
    notificationType: "notifications" | "reminders" | "testimonial-request",
    notificationsCategories: StateNotifications["notificationsCategories"],
    skip: number,
    limit: number,
    config?: Config
  ) {
    let queryParams = `?user=${userId}`;
    if (notificationType) {
      queryParams += `&notificationType=${notificationType}`;
    }
    if (notificationsCategories.length > 0) {
      queryParams += `&notificationCategory=${notificationsCategories.join()}`;
    }
    if (skip) {
      queryParams += `&skip=${skip}`;
    }
    if (limit) {
      queryParams += `&limit=${limit}`;
    }
    return api.GET(`${apiBaseNotifications}${queryParams}`, config);
  }
  // Mark as read a list of notifications
  static MarkAsReadNotifications(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBaseNotifications}/markAsRead`, data, config);
  }
  // Update last seen notification
  static LastSeenNotification(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBaseNotifications}/lastSeen`, data, config);
  }
  // Delete a list of notifications
  static DeleteNotifications(
    api: tAPI,
    notificationsCategories: StateNotifications["notificationsCategories"],
    config?: Config
  ) {
    let queryParams = "";
    if (notificationsCategories.length > 0) {
      queryParams += `?notificationCategory=${notificationsCategories.join()}`;
    }
    return api.DELETE(`${apiBaseNotifications}${queryParams}`, config);
  }
  // Delete a notification
  static DeleteNotification(
    api: tAPI,
    notificationId: string,
    config?: Config
  ) {
    return api.DELETE(`${apiBaseNotifications}/${notificationId}`, config);
  }
  // Create a Reminders Notification
  static CreateRemindersNotification(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBaseNotifications}/reminders`, data, config);
  }
  // Socket
  // Register a Socket with some connection ID
  static RegisterSocketConnectionID(api: tAPI, config?: Config) {
    return api.PUT(`${apiBaseNotifications}/register`, {}, config);
  }
}
