import { createAction } from "deox";

// Actions for Enrollment

// STUDENTS ENROLLMENTS ACTION
// Fetch all Student Enrollments
export const STUDENTS_ENROLLMENTS_FETCH: string = "STUDENT_ENROLLMENTS/FETCH";
export const STUDENTS_ENROLLMENTS_FETCH_REQUEST: string =
  "STUDENT_ENROLLMENTS/FETCH/REQUEST";
export const STUDENTS_ENROLLMENTS_FETCH_SUCCESS: string =
  "STUDENT_ENROLLMENTS/FETCH/SUCCESS";
export const STUDENTS_ENROLLMENTS_FETCH_FAILURE: string =
  "STUDENT_ENROLLMENTS/FETCH/FAILURE";
export const studentEnrollmentsFetch = createAction(
  STUDENTS_ENROLLMENTS_FETCH,
  (resolve) => (payload?: object) => resolve(payload)
);
export const studentEnrollmentsFetchRequest = createAction(
  STUDENTS_ENROLLMENTS_FETCH_REQUEST
);
export const studentEnrollmentsFetchSuccess = createAction(
  STUDENTS_ENROLLMENTS_FETCH_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const studentEnrollmentsFetchFailure = createAction(
  STUDENTS_ENROLLMENTS_FETCH_FAILURE
);
// Create a Student Enrollment
export const STUDENT_ENROLLMENT_CREATE: string = "STUDENT_ENROLLMENT/CREATE";
export const STUDENT_ENROLLMENT_CREATE_REQUEST: string =
  "STUDENT_ENROLLMENT/CREATE/REQUEST";
export const STUDENT_ENROLLMENT_CREATE_SUCCESS: string =
  "STUDENT_ENROLLMENT/CREATE/SUCCESS";
export const STUDENT_ENROLLMENT_CREATE_FAILURE: string =
  "STUDENT_ENROLLMENT/CREATE/FAILURE";
export const studentEnrollmentCreate = createAction(
  STUDENT_ENROLLMENT_CREATE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const studentEnrollmentCreateRequest = createAction(
  STUDENT_ENROLLMENT_CREATE_REQUEST
);
export const studentEnrollmentCreateSuccess = createAction(
  STUDENT_ENROLLMENT_CREATE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const studentEnrollmentCreateFailure = createAction(
  STUDENT_ENROLLMENT_CREATE_FAILURE
);
// Edit a Student Enrollment
export const STUDENT_ENROLLMENT_EDIT: string = "STUDENT_ENROLLMENT/EDIT";
export const STUDENT_ENROLLMENT_EDIT_REQUEST: string =
  "STUDENT_ENROLLMENT/EDIT/REQUEST";
export const STUDENT_ENROLLMENT_EDIT_SUCCESS: string =
  "STUDENT_ENROLLMENT/EDIT/SUCCESS";
export const STUDENT_ENROLLMENT_EDIT_FAILURE: string =
  "STUDENT_ENROLLMENT/EDIT/FAILURE";
export const studentEnrollmentEdit = createAction(
  STUDENT_ENROLLMENT_EDIT,
  (resolve) => (payload?: object) => resolve(payload)
);
export const studentEnrollmentEditRequest = createAction(
  STUDENT_ENROLLMENT_EDIT_REQUEST
);
export const studentEnrollmentEditSuccess = createAction(
  STUDENT_ENROLLMENT_EDIT_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const studentEnrollmentEditFailure = createAction(
  STUDENT_ENROLLMENT_EDIT_FAILURE
);
// Delete a Student Enrollment
export const STUDENT_ENROLLMENT_DELETE: string = "STUDENT_ENROLLMENTS/DELETE";
export const STUDENT_ENROLLMENT_DELETE_REQUEST: string =
  "STUDENT_ENROLLMENTS/DELETE/REQUEST";
export const STUDENT_ENROLLMENT_DELETE_SUCCESS: string =
  "STUDENT_ENROLLMENTS/DELETE/SUCCESS";
export const STUDENT_ENROLLMENT_DELETE_FAILURE: string =
  "STUDENT_ENROLLMENTS/DELETE/FAILURE";
export const studentEnrollmentDelete = createAction(
  STUDENT_ENROLLMENT_DELETE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const studentEnrollmentDeleteRequest = createAction(
  STUDENT_ENROLLMENT_DELETE_REQUEST
);
export const studentEnrollmentDeleteSuccess = createAction(
  STUDENT_ENROLLMENT_DELETE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const studentEnrollmentDeleteFailure = createAction(
  STUDENT_ENROLLMENT_DELETE_FAILURE
);
