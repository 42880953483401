// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
// Import Features
import * as React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import debounce from "lodash/debounce";
// Import Components
import {
  Accordion,
  Checkbox,
  Dropdown,
  Loader,
  Icon,
  Input,
} from "semantic-ui-react";
import { Users, UsersManager } from "../../store/actions/";
// Import Types
import type {
  PropsUserManagerFilters as Props,
  StateUserManagerFilters as State,
} from "../../types/users.types";
class UserManagerFilters extends React.Component<Props, State> {
  state = {
    fetchingByCohorts: false,
    fetchingByInstitutions: false,
    fetchingByPrograms: false,
    fetchingByRotations: false,
    fetchingByUserStatus: false,
  };
  render() {
    const {
      fromModal,
      roles: { selected: roleSelected },
    } = this.props;
    return (
      <div
        className={classNames("filters", {
          "skejule-scroll": fromModal,
        })}
      >
        {this.renderSearch()}
        <Accordion exclusive={false} className="skejule-scroll">
          {this.renderRoles()}
          {roleSelected && this.renderFilterUserStatus()}
          {roleSelected &&
            roleSelected.type === "students" && [
              this.renderRotations(),
              this.renderPrograms(),
              this.renderCohorts(),
            ]}
          {roleSelected &&
            (roleSelected.type === "institutionadministrators" ||
              roleSelected.type === "preceptors") &&
            this.renderInstitutions()}
        </Accordion>
      </div>
    );
  }
  // SEARCH COMPONENT
  onFiltersBySearch = debounce(() => {
    const { onFiltersBySearch } = this.props;
    onFiltersBySearch({ updateStoreUsers: true });
  }, 500);
  onSearch = (event: Object, { value: name }) => {
    window.logger.log("onSearch", name);
    const { onSearch } = this.props;
    onSearch(name);
    this.onFiltersBySearch();
  };
  renderSearch = () => {
    const {
      cohorts: { selected: cohortsSelected },
      fromModal,
      onClearFilters,
      onOpenClose,
      programs: { selected: programsSelected },
      roles: { selected: roleSelected },
      rotations: { selected: rotationSelected },
      searchTxt,
      usersFound,
    } = this.props;
    let disabledClearFilters = true;
    if (
      roleSelected ||
      rotationSelected ||
      programsSelected.length ||
      cohortsSelected.length
    ) {
      disabledClearFilters = false;
    }
    let icon = "search";
    if (searchTxt) {
      icon = (
        <Icon
          name={"delete"}
          style={{ cursor: "pointer" }}
          link
          onClick={() => this.onSearch(new Event("Empty"), { value: "" })}
        />
      );
    }
    return (
      <React.Fragment>
        <Input
          fluid
          icon={icon}
          placeholder="Search..."
          className="search"
          value={searchTxt}
          onChange={this.onSearch}
          disabled={disabledClearFilters}
        />
        <div
          className={classNames("clear", {
            disabled: disabledClearFilters,
            pb20: searchTxt.length === 0,
            pb15: searchTxt.length > 0,
          })}
        >
          <a onClick={!disabledClearFilters ? onClearFilters : null}>
            <i className="icono icono-close-border mr5" />
            Clear Filters
          </a>
        </div>
        {searchTxt.length > 0 && usersFound !== undefined && (
          <div className="results mb0">
            <div>
              <span className="number mr5">Found {usersFound}</span>
              <span className="mr5">match.</span>
              <span>
                {fromModal && (
                  <a style={{ cursor: "pointer" }} onClick={onOpenClose}>
                    View result.
                  </a>
                )}
              </span>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };
  // ROLES COMPONENT
  onGetUsersByRoles = (role) => () => {
    const { status = [], onGetUsersByRoles } = this.props;
    return new Promise((resolve) => {
      const formValues = {
        roles: [role.apiName],
        selected: role,
        updateStoreUsers: true,
        status,
        resetFilters: true,
      };
      const callbacks = {
        callbackError: (msg) => {
          window.logger.log("errors", msg);
          resolve();
        },
        callbackSuccess: () => {
          resolve();
        },
      };
      const payload = Object.assign({}, formValues, callbacks);
      onGetUsersByRoles(payload);
    });
  };
  renderRoles = () => {
    const {
      isFetchingRoles,
      roles: { isFetching, options: arrayRoles, selected: roleSelected },
    } = this.props;
    return [
      <Accordion.Title
        key="header-roles"
        active
        index={0}
        style={{ cursor: "default" }}
      >
        <Icon name="dropdown" />
        Users
        {/*<i
          className="icono icono-exclamation icon circular grey inverted ml5 tooltip"
          title="Total amount of users might be different due to Permissions Rules under your user role"
        />*/}
        <Loader
          size="tiny"
          inline
          active={
            isFetching ||
            (arrayRoles.filter(({ apiName }) => this.props[apiName]).length ===
              0 &&
              isFetchingRoles)
          }
        />
      </Accordion.Title>,
      <Accordion.Content key="content-roles" active>
        {arrayRoles
          .filter(({ apiName }) => this.props[apiName])
          .map((role, index) => (
            <div
              key={`${role.type}-${index}`}
              style={
                !roleSelected ||
                (roleSelected &&
                  roleSelected.apiName &&
                  role.apiName !== roleSelected.apiName)
                  ? { cursor: "pointer" }
                  : { cursor: "default" }
              }
              className={classNames("filter", {
                active:
                  role.apiName ===
                  (roleSelected && roleSelected.apiName
                    ? roleSelected.apiName
                    : ""),
              })}
              onClick={
                !roleSelected ||
                (roleSelected &&
                  roleSelected.apiName &&
                  role.apiName !== roleSelected.apiName)
                  ? this.onGetUsersByRoles(role)
                  : () => {}
              }
            >
              <i
                className={classNames(
                  "icono",
                  {
                    "icono-user ":
                      role.apiName !==
                      (roleSelected && roleSelected.apiName
                        ? roleSelected.apiName
                        : ""),
                  },
                  {
                    "icono-user bg-heto-teal rounded-icon":
                      role.apiName ===
                      (roleSelected && roleSelected.apiName
                        ? roleSelected.apiName
                        : ""),
                  },
                  "small mr5"
                )}
              />
              <a
                style={
                  this.props[role.apiName] && this.props[role.apiName].isFetched
                    ? { fontWeight: "bolder" }
                    : {}
                }
              >
                {role.name}{" "}
                {this.props[role.apiName] &&
                  this.props[role.apiName].isFetched &&
                  `(${this.props[role.apiName].totalUsers})`}
              </a>
            </div>
          ))}
      </Accordion.Content>,
    ];
  };
  // USERS STATUS
  onGetUsersByUserStatus = debounce(() => {
    return new Promise((resolve, error) => {
      const { onFiltersByUserStatus } = this.props;
      const formValues = { updateStoreUsers: true };
      const callbacks = {
        callbackError: (msg) => {
          window.logger.log("errors - onFiltersByUserStatus", msg);
          this.setState({ fetchingByUserStatus: false });
          error();
        },
        callbackSuccess: () => {
          window.logger.log("Success - onFiltersByUserStatus");
          this.setState({ fetchingByUserStatus: false });
        },
      };
      const payload = Object.assign({}, formValues, callbacks);
      onFiltersByUserStatus(payload);
    });
  }, 500);
  onSelectedUserStatus = (event: Object, { name: statusSelected }) => {
    window.logger.log("onSelectedUserStatus", statusSelected);
    const { status: userStatus = [], onFiltersSetUserStatus } = this.props;
    let newUserStatus = [...userStatus];
    if (newUserStatus.indexOf(statusSelected) === -1) {
      newUserStatus = [...newUserStatus, statusSelected];
    } else {
      newUserStatus = newUserStatus.filter(
        (status) => status !== statusSelected
      );
    }
    onFiltersSetUserStatus({ status: newUserStatus });
    this.setState(
      {
        fetchingByUserStatus: true,
        fetchingByCohorts: false,
        fetchingByInstitutions: false,
        fetchingByPrograms: false,
        fetchingByRotations: false,
      },
      () => {
        this.onGetUsersByUserStatus.cancel();
        this.onGetUsersByUserStatus(newUserStatus);
      }
    );
  };
  renderFilterUserStatus = () => {
    const {
      SHOW_DEACTIVATED_USERS,
      status: userStatus = SHOW_DEACTIVATED_USERS
        ? ["active", "inactive"]
        : ["active"],
    } = this.props;
    return (
      <>
        <Accordion.Title
          key="header-user-status"
          active
          index={2}
          style={{ cursor: "default" }}
        >
          <Icon name="dropdown" />
          Status
          <Loader size="tiny" inline active={this.state.fetchingByUserStatus} />
        </Accordion.Title>
        <Accordion.Content key="content-user-status" active>
          <div>
            <Checkbox
              checked={userStatus.indexOf("active") !== -1}
              className="pb10"
              disabled={
                userStatus.length === 1 && userStatus.indexOf("active") !== -1
              }
              label={
                <label
                  className={classNames("skejule-gray", {
                    bold: userStatus.indexOf("active") !== -1,
                  })}
                >
                  <Icon name="circle" size="tiny" color="teal" />
                  Active
                </label>
              }
              name="active"
              onClick={
                userStatus.length === 1 && userStatus.indexOf("active") !== -1
                  ? undefined
                  : this.onSelectedUserStatus
              }
            />
          </div>
          <div>
            <Checkbox
              checked={userStatus.indexOf("inactive") !== -1}
              disabled={
                userStatus.length === 1 && userStatus.indexOf("inactive") !== -1
              }
              label={
                <label
                  className={classNames("skejule-gray", {
                    bold: userStatus.indexOf("inactive") !== -1,
                  })}
                >
                  <Icon name="circle" size="tiny" color="orange" />
                  Not Active
                </label>
              }
              name="inactive"
              onClick={
                userStatus.length === 1 && userStatus.indexOf("inactive") !== -1
                  ? undefined
                  : this.onSelectedUserStatus
              }
            />
          </div>
        </Accordion.Content>
      </>
    );
  };
  // STUDENTS FILTERS COMPONENT
  onFiltersBy = (
    event: Event,
    { name: filter, value }: { name: string, value: string }
  ) => {
    let filterState, query, onFiltersBy;
    switch (filter) {
      case "rotations":
        filterState = "fetchingByRotations";
        query = `activeRotation=${value}`;
        const { onFiltersByRotations } = this.props;
        onFiltersBy = onFiltersByRotations;
        break;
      case "programs":
        filterState = "fetchingByPrograms";
        query = `programs=${value}`;
        const { onFiltersByPrograms } = this.props;
        onFiltersBy = onFiltersByPrograms;
        break;
      case "cohorts":
        filterState = "fetchingByCohorts";
        query = `cohorts=${value}`;
        const { onFiltersByCohorts } = this.props;
        onFiltersBy = onFiltersByCohorts;
        break;
      case "institutions":
        filterState = "fetchingByInstitutions";
        query = `institutions=${value}`;
        const { onFiltersByInstitutions } = this.props;
        onFiltersBy = onFiltersByInstitutions;
        break;
      default:
    }
    if (filterState) {
      let newState = {
        fetchingByCohorts: false,
        fetchingByInstitutions: false,
        fetchingByPrograms: false,
        fetchingByRotations: false,
        fetchingByUserStatus: false,
      };
      newState[filterState] = true;
      this.setState(newState);
      return new Promise((resolve) => {
        const formValues = {
          query: query,
          selected: value,
          updateStoreUsers: true,
        };
        const callbacks = {
          callbackError: (msg) => {
            window.logger.log("errors - onFiltersBy", msg);
            if (filterState) {
              newState[filterState] = false;
              this.setState(newState);
            }
            resolve();
          },
          callbackSuccess: () => {
            window.logger.log("Success - onFiltersBy", filter, value);
            if (filterState) {
              newState[filterState] = false;
              this.setState(newState);
            }
            resolve();
          },
        };
        const payload = Object.assign({}, formValues, callbacks);
        onFiltersBy(payload);
      });
    }
  };
  renderRotations = () => {
    const {
      rotations: { activeRotation },
    } = this.props;
    return [
      <Accordion.Title
        key="header-rotations"
        active
        index={2}
        style={{ cursor: "default" }}
      >
        <Icon name="dropdown" />
        Rotations
        <Loader size="tiny" inline active={this.state.fetchingByRotations} />
      </Accordion.Title>,
      <Accordion.Content key="content-rotations" active>
        <div
          style={{ cursor: "pointer" }}
          className={classNames("filter", { active: activeRotation })}
          onClick={() =>
            this.onFiltersBy(new Event("empty"), {
              name: "rotations",
              value: !activeRotation,
            })
          }
        >
          <Checkbox
            checked={activeRotation}
            style={{ minHeight: "14px", minWidth: "22px" }}
          />
          <a>Active Rotations</a>
        </div>
      </Accordion.Content>,
    ];
  };
  renderPrograms = () => {
    const {
      canReadAllStudentsProfile,
      programs: { options: arrayPrograms, selected: programsSelected },
    } = this.props;
    if (!canReadAllStudentsProfile) {
      return null;
    }
    return [
      <Accordion.Title
        key="header-programs"
        active
        index={1}
        style={{ cursor: "default" }}
      >
        <Icon name="dropdown" />
        Programs
        <Loader size="tiny" inline active={this.state.fetchingByPrograms} />
      </Accordion.Title>,
      <Accordion.Content key="content-programs" active>
        <Dropdown
          name="programs"
          placeholder="Select Program(s)"
          className="filter"
          selectOnBlur={false}
          upward={window.innerHeight < 830}
          search
          selection
          multiple
          disabled={arrayPrograms.length === 0}
          options={arrayPrograms.map(({ _id, name }) => ({
            key: _id,
            value: _id,
            text: name,
          }))}
          value={programsSelected}
          onChange={this.onFiltersBy}
        />
      </Accordion.Content>,
    ];
  };
  renderCohorts = () => {
    const {
      canReadAllStudentsProfile,
      cohorts: { options: arrayCohorts, selected: cohortsSelected },
    } = this.props;
    if (!canReadAllStudentsProfile) {
      return null;
    }
    return [
      <Accordion.Title
        key="header-cohorts"
        active
        index={3}
        style={{ cursor: "default" }}
      >
        <Icon name="dropdown" />
        Cohorts
        <Loader size="tiny" inline active={this.state.fetchingByCohorts} />
      </Accordion.Title>,
      <Accordion.Content key="content-cohorts" active>
        <Dropdown
          name="cohorts"
          placeholder="Select Cohort(s)"
          className="filter"
          selectOnBlur={false}
          upward={window.innerHeight < 920}
          search
          selection
          multiple
          disabled={arrayCohorts.length === 0}
          options={arrayCohorts.map(({ _id, name }) => ({
            key: _id,
            value: _id,
            text: name,
          }))}
          value={cohortsSelected}
          onChange={this.onFiltersBy}
        />
      </Accordion.Content>,
    ];
  };
  renderInstitutions = (upward: boolean = false) => {
    const {
      institutions: {
        options: arrayInstitutions,
        selected: institutionsSelected,
      },
    } = this.props;
    if (arrayInstitutions.length === 0) {
      return null;
    }
    return [
      <Accordion.Title
        key="header-institutions"
        active
        index={1}
        style={{ cursor: "default" }}
      >
        <Icon name="dropdown" />
        Clinics
        <Loader size="tiny" inline active={this.state.fetchingByInstitutions} />
      </Accordion.Title>,
      <Accordion.Content key="content-institutions" active>
        <Dropdown
          name="institutions"
          placeholder="Select Clinic(s)"
          className="filter"
          selectOnBlur={false}
          upward={upward}
          search
          selection
          multiple
          options={arrayInstitutions.map(({ _id, name }) => ({
            key: _id,
            value: _id,
            text: name,
          }))}
          value={institutionsSelected}
          onChange={this.onFiltersBy}
        />
      </Accordion.Content>,
    ];
  };
}
export const studentFounded = (
  student: Object,
  elementToFind: string,
  valueToFind: string
): boolean => {
  let { currentEnrollment } = student;
  if (currentEnrollment) {
    if (elementToFind === "program" && currentEnrollment.program) {
      return currentEnrollment.program._id === valueToFind;
    } else if (elementToFind === "cohort" && currentEnrollment.cohort) {
      return currentEnrollment.cohort._id === valueToFind;
    }
  }
  return false;
};

const bindActions = (dispatch) => {
  return {
    onClearFilters: () => dispatch(UsersManager.usersManagerFiltersClear()),
    onFiltersByCohorts: (payload) =>
      dispatch(UsersManager.usersManagerFiltersCohorts(payload)),
    onFiltersByInstitutions: (payload) =>
      dispatch(UsersManager.usersManagerFiltersInstitutions(payload)),
    onFiltersByPrograms: (payload) =>
      dispatch(UsersManager.usersManagerFiltersPrograms(payload)),
    onFiltersByRotations: (payload) =>
      dispatch(UsersManager.usersManagerFiltersRotations(payload)),
    onFiltersBySearch: (payload) =>
      dispatch(UsersManager.usersManagerFiltersSearch(payload)),
    onFiltersSetUserStatus: (payload) =>
      dispatch(UsersManager.usersManagerFiltersSetUserStatus(payload)),
    onFiltersByUserStatus: (payload) =>
      dispatch(UsersManager.usersManagerFiltersUserStatus(payload)),
    onGetUsersByRoles: (payload) =>
      dispatch(Users.usersFetchUsersRoles(payload)),
    onSearch: (name: string) =>
      dispatch(UsersManager.usersManagerFiltersSetSearch({ searchTxt: name })),
  };
};

export default connect(
  ({
    accessControl: { isFetchingRoles },
    tenantSettings: {
      tenantSettings: { SHOW_DEACTIVATED_USERS },
    },
    users,
    usersManager: { filters },
  }) => {
    const {
      SuperCompanyAdministrators,
      CompanyAdministrators,
      Coordinators,
      Faculties,
      InstitutionAdministrators,
      Preceptors,
      Students,
    } = users;
    let usersManager = [],
      usersFound = undefined;
    if (
      users &&
      filters.roles.selected &&
      !filters.roles.isFetching &&
      users[filters.roles.selected.apiName]
    ) {
      usersManager = users[filters.roles.selected.apiName].users;
      if (filters.searchTxt.length > 0) {
        usersFound = usersManager.length;
      }
    }
    return {
      ...filters,
      isFetchingRoles,
      usersFound,
      SuperCompanyAdministrators,
      CompanyAdministrators,
      Coordinators,
      Faculties,
      InstitutionAdministrators,
      Preceptors,
      Students,
      SHOW_DEACTIVATED_USERS: SHOW_DEACTIVATED_USERS?.values || false,
    };
  },
  bindActions
)(UserManagerFilters);
