import type { Config, tAPI } from "./API";
// API Broadcast Messages static class

const apiBaseBroadcastMessages = "/tenant-settings/broadcast-messages";
export default class BroadcastMessages {
  // Get a list of Broadcast Messages expired
  static GetBroadcastMessages(
    api: tAPI,
    expirationDate: string,
    config?: Config
  ) {
    let queryParams = "";
    if (expirationDate) {
      queryParams += `&expirationDate=${expirationDate}`;
    }
    return api.GET(
      `/tenant-settings?name=BROADCAST_MESSAGES${queryParams}`,
      config
    );
  }
  // Create a Broadcast Message
  static CreateBroadcastMessage(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBaseBroadcastMessages}`, data, config);
  }
  // Edit a Broadcast Message
  static EditBroadcastMessage(
    api: tAPI,
    broadcastMessageId: string,
    data: object,
    config?: Config
  ) {
    return api.PATCH(
      `${apiBaseBroadcastMessages}/${broadcastMessageId}`,
      data,
      config
    );
  }
  // Delete a Broadcast Message
  static DeleteBroadcastMessage(
    api: tAPI,
    broadcastMessageId: string,
    config?: Config
  ) {
    return api.DELETE(
      `${apiBaseBroadcastMessages}/${broadcastMessageId}`,
      config
    );
  }
}
