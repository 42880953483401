//Class to manage all api calls
import axios from "axios";
import type { AxiosResponse } from "axios";
import { app } from "../../app.json";
import { onGetTenant, onGetToken } from "../../utils";
// Types
import type { AxiosRequestConfig, CancelToken } from "axios";
export type Config = {
  https?: "https" | "http";
  tenant?: "" | string;
  envApi?: "" | "dev" | "test" | string;
  apiBase?: "getinclusive.io" | string;
  vApi?: "v1" | "v2";
  token?: string;
  cancelToken?: CancelToken;
};
export interface tAPI {
  GET: (url: string, config?: Config) => Promise<any>;
  POST: (url: string, data: any, config?: Config) => Promise<any>;
  PUT: (url: string, data: any, config?: Config) => Promise<any>;
  PATCH: (url: string, data: any, config?: Config) => Promise<any>;
  DELETE: (url: string, config?: Config, data?: any) => Promise<any>;
  UPLOAD: (
    url: string,
    data: any,
    method: "POST" | "PUT" | "PATCH",
    config?: Config
  ) => Promise<any>;
  DOWNLOAD: (
    url: string,
    data: any,
    extension: string,
    config?: Config
  ) => Promise<any>;
}
export class API {
  // Private properties:
  ready: boolean;
  constructor() {
    this.ready = true;
  }
  static __configDefault__: Config = {
    https: "https",
    tenant: onGetTenant(),
    envApi: app.apiEnv,
    apiBase: app.appURL,
    vApi: "v1",
    token: onGetToken(),
    cancelToken: undefined,
  };
  // Private Methods:
  _setHeaders = (token: string, isPostPutPatch: boolean = false): object => {
    let headers: object = {
      Accept: "application/json",
    };
    if (token && token.length) {
      headers = {
        ...headers,
        Authorization: `Bearer ${token}`,
      };
    }
    if (isPostPutPatch) {
      headers = {
        ...headers,
        "Content-type": "application/json",
      };
    }
    return headers;
  };
  _setConfigGet = (
    token: Config["token"],
    cancelToken: Config["cancelToken"]
  ): AxiosRequestConfig => {
    let config: AxiosRequestConfig = {
      cancelToken,
      headers: this._setHeaders(token as string),
      timeout: 100000,
      validateStatus: this._validateStatus,
    };
    return config;
  };
  _setConfigPostPutPatch = (
    method: "POST" | "PUT" | "PATCH",
    data: any,
    token: Config["token"]
  ): AxiosRequestConfig => {
    let config: AxiosRequestConfig = {
      data: JSON.stringify(data),
      headers: this._setHeaders(token as string, true),
      method: method,
      timeout: 100000,
      validateStatus: this._validateStatus,
    };
    return config;
  };
  _setConfigDel = (token: Config["token"], data?: any): AxiosRequestConfig => {
    let config: AxiosRequestConfig = {
      method: "DELETE",
      headers: this._setHeaders(token as string),
      timeout: 100000,
      validateStatus: this._validateStatus,
    };
    if (data) {
      config = {
        ...config,
        data: JSON.stringify(data),
      };
    }
    return config;
  };
  _setErrorAPI = (error: string, message: string = "Server error 500") => {
    return {
      name: error,
      message,
    };
  };
  _setFullURL = (url: string, config: Config): string => {
    const { https, tenant, apiBase, vApi } = config;
    let fullURL = `${https}://`;
    if (tenant) {
      fullURL += `${tenant}.`;
    }
    fullURL += `${apiBase}/api/${vApi}${url}`;
    return fullURL;
  };
  _validResponse = (
    response: AxiosResponse<any>
  ): { data?: any; ok: boolean } => {
    if (response.headers["content-type"].indexOf("application/json") > -1) {
      return response.data;
    }
    return {
      ok: false,
      ...this._setErrorAPI(
        "Internal Error",
        "content-type is not application/json"
      ),
    };
  };
  _validateStatus = (status: number) => status >= 200 && status < 500;
  _parseResponse = (response: { data?: any; ok: boolean }): Promise<any> => {
    return new Promise((resolve, reject) => {
      if (response.ok) {
        resolve({ success: true, data: response });
      } else {
        reject({ success: false, data: response });
      }
    });
  };
  // Public Methods:
  GET(url: string, config?: Config): Promise<any> {
    const apiConfig = { ...API.__configDefault__, ...config };
    return axios
      .get(
        this._setFullURL(url, apiConfig),
        this._setConfigGet(apiConfig.token || "", apiConfig.cancelToken)
      )
      .then(this._validResponse)
      .catch((error) => {
        let isAPICancelled = false;
        // Checking Cancel Request was cancelled
        if (axios.isCancel(error)) {
          isAPICancelled = true;
        }
        window.logger.error(
          "ERROR 500 from server - GET",
          error,
          isAPICancelled
        );
        return {
          ok: false,
          status: isAPICancelled ? "cancelled" : "error",
          ...this._setErrorAPI(
            "Internal Error",
            error && error.message ? error.message : error
          ),
        };
      })
      .then(this._parseResponse);
  }
  POST(url: string, data: any, config?: Config): Promise<any> {
    const apiConfig = { ...API.__configDefault__, ...config };
    return axios(
      this._setFullURL(url, apiConfig),
      this._setConfigPostPutPatch("POST", data, apiConfig.token || "")
    )
      .then(this._validResponse)
      .catch((error) => {
        window.logger.error("ERROR 500 from server - POST", error);
        return {
          ok: false,
          ...this._setErrorAPI(
            "Internal Error",
            error && error.message ? error.message : error
          ),
        };
      })
      .then(this._parseResponse);
  }
  PUT(url: string, data: any, config?: Config): Promise<any> {
    const apiConfig = { ...API.__configDefault__, ...config };
    return axios(
      this._setFullURL(url, apiConfig),
      this._setConfigPostPutPatch("PUT", data, apiConfig.token || "")
    )
      .then(this._validResponse)
      .catch((error) => {
        window.logger.error("ERROR 500 from server - PUT", error);
        return {
          ok: false,
          ...this._setErrorAPI(
            "Internal Error",
            error && error.message ? error.message : error
          ),
        };
      })
      .then(this._parseResponse);
  }
  PATCH(url: string, data: any, config?: Config): Promise<any> {
    const apiConfig = { ...API.__configDefault__, ...config };
    return axios(
      this._setFullURL(url, apiConfig),
      this._setConfigPostPutPatch("PATCH", data, apiConfig.token || "")
    )
      .then(this._validResponse)
      .catch((error) => {
        window.logger.error("ERROR 500 from server - PATCH", error);
        return {
          ok: false,
          ...this._setErrorAPI(
            "Internal Error",
            error && error.message ? error.message : error
          ),
        };
      })
      .then(this._parseResponse);
  }
  DELETE(url: string, config?: Config, data?: any): Promise<any> {
    const apiConfig = { ...API.__configDefault__, ...config };
    return axios(
      this._setFullURL(url, apiConfig),
      this._setConfigDel(apiConfig.token || "", data)
    )
      .then(this._validResponse)
      .catch((error) => {
        window.logger.error("ERROR 500 from server - DELETE", error);
        return {
          ok: false,
          ...this._setErrorAPI(
            "Internal Error",
            error && error.message ? error.message : error
          ),
        };
      })
      .then(this._parseResponse);
  }
  UPLOAD(
    url: string,
    data: any,
    method: "POST" | "PUT" | "PATCH",
    config?: Config
  ): Promise<any> {
    const apiConfig = { ...API.__configDefault__, ...config };
    return axios(this._setFullURL(url, apiConfig), {
      method,
      timeout: 100000,
      headers: this._setHeaders(apiConfig.token || ""),
      data,
      validateStatus: this._validateStatus,
    })
      .then(this._validResponse)
      .catch((error) => {
        window.logger.error("ERROR 500 from server - UPLOAD", error);
        return {
          ok: false,
          ...this._setErrorAPI(
            "Internal Error",
            error && error.message ? error.message : error
          ),
        };
      })
      .then(this._parseResponse);
  }
  DOWNLOAD(
    url: string,
    data: any,
    extension: string,
    config?: Config
  ): Promise<any> {
    const apiConfig = { ...API.__configDefault__, ...config };
    return axios(this._setFullURL(url, apiConfig), {
      method: "POST",
      timeout: 100000,
      headers: {
        ...this._setHeaders(apiConfig.token || "", true),
        Accept: extension,
      },
      data: JSON.stringify(data),
      responseType: "blob",
      validateStatus: this._validateStatus,
    })
      .then((response: { [k: string]: any }) => {
        if (response.headers["content-type"].indexOf(extension) > -1) {
          return {
            ok: true,
            blob: window.URL.createObjectURL(
              new Blob([response.data], {
                type: extension || "application/octet-stream",
              })
            ),
          };
        }
        return {
          ok: false,
          ...this._setErrorAPI(
            "Internal Error",
            `content-type is not ${extension}`
          ),
        };
      })
      .catch((error) => {
        window.logger.error("ERROR 500 from server - UPLOAD", error);
        return {
          ok: false,
          ...this._setErrorAPI(
            "Internal Error",
            error && error.message ? error.message : error
          ),
        };
      })
      .then(this._parseResponse);
  }
}
