// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
// Features
import * as React from "react";
import classNames from "classnames";
import { setUserRole } from "../../../utils";
// Components
import { Grid } from "semantic-ui-react";
import NotificationsDate from "./NotificationsDate";
const NotificationRotation = ({
  _id,
  readBy,
  rotation,
  institutions,
  student,
  message,
  createdAt,
  goTo,
}: Object): React$Element<any> => (
  <div
    className={classNames("notification", {
      new: !readBy || (readBy && readBy.length === 0),
    })}
  >
    <Grid columns={2}>
      <Grid.Row className="nopadding">
        <Grid.Column mobile={3} tablet={3} computer={3} className="">
          <i className="icono icono-calendar-fill huge skejule-teal" />
        </Grid.Column>
        <Grid.Column mobile={13} tablet={13} computer={13} className="">
          <div className="message">
            {message
              .split(/({rotationNumber}|{institutionName}|{studentName})/gi)
              .map((el, index) => {
                if (el === "{rotationNumber}") {
                  return (
                    <a
                      key={rotation._id + index}
                      style={{ cursor: "pointer" }}
                      onClick={goTo(`/rotations`, {
                        namePage: "Rotations",
                      })}
                    >
                      #{rotation.rotationNumber}
                    </a>
                  );
                } else if (el === "{studentName}") {
                  return (
                    <a
                      key={student._id + index}
                      style={{ cursor: "pointer" }}
                      onClick={(event: Object) => {
                        // DEBUG: We need to debugger this action, because when
                        // it's called one time and manager profile is open
                        // the action User.setUserId is called two times, because of
                        // ManagerRoute component is launched two times before route change
                        goTo(`/manager/profile/${student._id}`, {
                          namePage: "Profile",
                          role: setUserRole(student.role),
                          user: null,
                        })(event);
                        setTimeout(() => {
                          goTo(`/manager/profile/${student._id}`, {
                            namePage: "Profile",
                            role: setUserRole(student.role),
                            user: null,
                          })(event);
                        }, 500);
                      }}
                    >{`${student.firstName || "Not Name"} ${
                      student.lastName || "Not Last Name"
                    }`}</a>
                  );
                } else if (el === "{institutionName}") {
                  return institutions.map((institution, indexInsti) => (
                    <a
                      key={institution._id + index + indexInsti}
                      style={{ cursor: "pointer" }}
                      onClick={goTo(`/clinics/view/${institution._id}`)}
                    >
                      {institution.name}
                      {indexInsti + 1 < institutions.length && ", "}
                    </a>
                  ));
                }
                return el;
              })}
          </div>
          <NotificationsDate
            createdAt={createdAt}
            isMarkAsRead={!!readBy && readBy?.length > 0}
            notificationId={_id}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
);

export default NotificationRotation;
