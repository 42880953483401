// Features
import produce from "immer";
import { Institutions, Files } from "../actions/";
// Types
import type { Action } from "deox";
import type { PayloadObject, UndefinedNull } from "../../types/common.types";
export type State = {
  lastFetching: Date | UndefinedNull;
  isFetchingInstitutions: boolean;
  institutionId: string | UndefinedNull;
  institution: PayloadObject | UndefinedNull;
  institutionFiles: PayloadObject[];
  institutionFilesExpired: PayloadObject[];
  institutions: PayloadObject[];
  hasInstitutionCustomFieldsRequiredNull: PayloadObject | UndefinedNull;
};
type InstitutionsActionReducers = Action<
  string,
  {
    files: State["institutionFiles"];
    filesExpired: State["institutionFilesExpired"];
    institution: NonNullable<State["institution"]>;
    institutionId: NonNullable<State["institutionId"]>;
    institutions: State["institutions"];
    hasInstitutionCustomFieldsRequiredNull: NonNullable<
      State["hasInstitutionCustomFieldsRequiredNull"]
    >;
  }
>;
// Institutions Reducer
const initialState: State = {
  lastFetching: null,
  isFetchingInstitutions: false,
  institutionId: null,
  institution: null,
  institutionFiles: [],
  institutionFilesExpired: [],
  institutions: [],
  hasInstitutionCustomFieldsRequiredNull: null,
};
const institutions: (
  state: State,
  action: InstitutionsActionReducers
) => State = produce((draft: State, action: InstitutionsActionReducers) => {
  switch (action.type) {
    case Institutions.INSTITUTIONS_FETCH_INSTITUTIONS:
      draft.isFetchingInstitutions = true;
      break;
    case Institutions.INSTITUTIONS_FETCH_INSTITUTIONS_SUCCESS:
      draft.lastFetching = new Date();
      draft.isFetchingInstitutions = false;
      draft.institutions = action.payload.institutions;
      break;
    case Institutions.INSTITUTIONS_FETCH_INSTITUTIONS_FAILURE:
      draft.isFetchingInstitutions = false;
      break;
    case Institutions.INSTITUTIONS_SET_INSTITUTION_ID:
      draft.institutionId = action.payload.institutionId;
      break;
    case Institutions.INSTITUTIONS_UNSET_INSTITUTION_ID:
      draft.institutionId = initialState.institutionId;
      draft.institution = initialState.institution;
      draft.hasInstitutionCustomFieldsRequiredNull =
        initialState.hasInstitutionCustomFieldsRequiredNull;
      draft.institutionFiles = initialState.institutionFiles;
      draft.institutionFilesExpired = initialState.institutionFilesExpired;
      break;
    case Institutions.INSTITUTIONS_FETCH_INSTITUTION_SUCCESS:
    case Institutions.INSTITUTIONS_EDIT_INSTITUTION_SUCCESS:
      if (
        draft.institutionId &&
        draft.institutionId === action.payload.institution?._id
      ) {
        draft.institution = action.payload.institution;
        draft.hasInstitutionCustomFieldsRequiredNull =
          action.payload.hasInstitutionCustomFieldsRequiredNull;
      }
      break;
    case Institutions.INSTITUTIONS_CREATE_SUCCESS:
      draft.lastFetching = new Date();
      draft.institutions = [action.payload.institution, ...draft.institutions];
      break;
    case Institutions.INSTITUTIONS_FETCH_INSTITUTION_FILES_SUCCESS:
    case Files.FILES_EDIT_SUCCESS:
    case Files.FILES_DELETE_SUCCESS:
      draft.institutionFiles = action.payload.files;
      break;
    case Institutions.INSTITUTIONS_FETCH_INSTITUTION_FILES_EXPIRED_SUCCESS:
      draft.institutionFilesExpired = action.payload.filesExpired;
      break;
  }
}, initialState);
export default institutions;
