// Features
import produce from "immer";
import { Notifications } from "../actions/";
import { SocketService } from "../../utils/socket/serviceSocket";
// Types
import type { Action } from "deox";
import type { Dispatch } from "redux";
import type { PayloadObject, UndefinedNull } from "../../types/common.types";
export type NotificationType =
  | "notifications"
  | "reminders"
  | "testimonial-request";
export type NotificationsCategories =
  | "ROTATIONS"
  | "CASE_LOGS"
  | "EVALUATIONS,EVALUATION_SCHEDULES"
  | "EVALUATION_ANSWERS"
  | "REPORTS"
  | "DOCUMENTS"
  | "CUSTOM_FIELDS,PERIODS,IMPORT_DATA,PORTFOLIOS"
  | "INSTITUTIONS"
  | "USERS";
type StateNotifications = {
  all: PayloadObject[];
  notifications: PayloadObject[];
  reminders: PayloadObject[];
  isFetchingNotifications: boolean;
};
export type State = {
  notifications: StateNotifications;
  notificationsCategories: NotificationsCategories[];
  totalNotifications: number;
  unseenNotifications: number;
  lastSeenNotificationId: string | UndefinedNull;
  socketConnectionId: string | UndefinedNull;
  socket: PayloadObject | UndefinedNull;
};
type NotificationsActionReducers = Action<
  string,
  {
    dispatch: Dispatch<any>;
    nNotificationsUpdated: number;
    notification: PayloadObject;
    notificationId: string;
    notifications: PayloadObject[];
    notificationsCategory: NotificationsCategories;
    notificationsFiltersByCategories: boolean;
    notificationType: NotificationType;
    socket: PayloadObject;
    socketConnectionId: string;
    totalNotifications: State["totalNotifications"];
    unseenNotifications: State["unseenNotifications"];
    uri: string;
  }
>;
// Notifications Reducer
const initialState: State = {
  notifications: {
    all: [],
    notifications: [],
    reminders: [],
    isFetchingNotifications: false,
  },
  notificationsCategories: [],
  totalNotifications: 0,
  unseenNotifications: 0,
  lastSeenNotificationId: undefined,
  socketConnectionId: undefined,
  socket: undefined,
};
const filterNotifications = (
  stateNotifications: StateNotifications,
  newNotifications: PayloadObject[]
) => {
  let currentListNotifications = [...newNotifications];
  let all = [...stateNotifications.all];
  let notifications = [...stateNotifications.notifications];
  let reminders = [...stateNotifications.reminders];
  while (currentListNotifications.length > 0) {
    let currentNotification = currentListNotifications.shift();
    if (
      currentNotification &&
      all.filter((n) => n._id === (currentNotification as PayloadObject)._id)
        .length === 0
    ) {
      all.push(currentNotification);
      if (currentNotification.notificationType === "notifications") {
        notifications.push(currentNotification);
      } else {
        reminders.push(currentNotification);
      }
    }
  }
  return {
    all,
    notifications,
    reminders,
    isFetchingNotifications: false,
  };
};
const markAsReadNotifications = (
  stateNotifications: StateNotifications,
  notificationId?: string
) => {
  let notifications = [...stateNotifications.notifications];
  if (notificationId) {
    notifications = notifications.map((notification) => ({
      ...notification,
      readBy:
        notificationId === notification._id
          ? [{ readAt: new Date().toISOString() }]
          : notification.readBy,
    }));
  } else {
    notifications = notifications.map((notification) => ({
      ...notification,
      readBy:
        !notification.readBy || notification.readBy?.length === 0
          ? [{ readAt: new Date().toISOString() }]
          : notification.readBy,
    }));
  }
  return {
    all: [...notifications, ...stateNotifications.reminders],
    notifications,
    reminders: stateNotifications.reminders,
    isFetchingNotifications: false,
  };
};
const deleteNotifications = (
  stateNotifications: StateNotifications,
  notificationId?: string
) => {
  let notifications: StateNotifications["notifications"] = [];
  if (notificationId) {
    notifications = stateNotifications.all.filter(
      (notification) => notification._id !== notificationId
    );
  }
  return {
    all: [...notifications, ...stateNotifications.reminders],
    notifications,
    reminders: stateNotifications.reminders,
    isFetchingNotifications: false,
  };
};
const notifications: (
  state: State,
  action: NotificationsActionReducers
) => State = produce((draft: State, action: NotificationsActionReducers) => {
  switch (action.type) {
    // SOCKET
    case Notifications.NOTIFICATIONS_REGISTER_SOCKET:
      draft.socket = new SocketService(
        action.payload.uri,
        action.payload.dispatch
      );
      break;
    case Notifications.NOTIFICATIONS_REGISTER_SOCKET_SUCCESS:
      draft.socketConnectionId = action.payload.socketConnectionId;
      break;
    case Notifications.NOTIFICATIONS_SET_SOCKET_CONNECTION_ID_SUCCESS:
    case Notifications.NOTIFICATIONS_DISCONNECT_SOCKET:
      draft.socket = action.payload.socket;
      break;
    case Notifications.NOTIFICATIONS_SOCKET_ADD_NOTIFICATION:
      // draft.notifications.all = [
      //   action.payload.notification,
      //   ...draft.notifications.all,
      // ];
      // draft.notifications[
      //   action.payload.notification.notificationType as Exclude<
      //     NotificationType,
      //     "testimonial-request"
      //   >
      // ] = [
      //   action.payload.notification,
      //   ...draft.notifications[
      //     action.payload.notification.notificationType as Exclude<
      //       NotificationType,
      //       "testimonial-request"
      //     >
      //   ],
      // ];
      // draft.totalNotifications += 1;
      // draft.unseenNotifications += 1;
      break;
    // NOTIFICATIONS
    case Notifications.NOTIFICATIONS_FETCH_NOTIFICATIONS_REQUEST:
      if (action.payload.notificationsFiltersByCategories) {
        draft.notifications = {
          ...initialState.notifications,
          isFetchingNotifications: true,
        };
      } else {
        draft.notifications.isFetchingNotifications = true;
      }
      break;
    case Notifications.NOTIFICATIONS_FETCH_NOTIFICATIONS_SUCCESS:
      draft.notifications = filterNotifications(
        draft.notifications,
        action.payload.notifications
      );
      draft.totalNotifications = action.payload.totalNotifications || 0;
      draft.unseenNotifications = action.payload.unseenNotifications || 0;
      break;
    case Notifications.NOTIFICATIONS_FETCH_NOTIFICATIONS_FAILURE:
      draft.notifications.isFetchingNotifications = false;
      break;
    case Notifications.NOTIFICATIONS_SET_NOTIFICATIONS_CATEGORIES:
      if (
        draft.notificationsCategories.indexOf(
          action.payload.notificationsCategory
        ) === -1
      ) {
        draft.notificationsCategories = [
          ...draft.notificationsCategories,
          action.payload.notificationsCategory,
        ];
      } else {
        draft.notificationsCategories = draft.notificationsCategories.filter(
          (notificationsCategory) =>
            notificationsCategory !== action.payload.notificationsCategory
        );
      }
      break;
    case Notifications.NOTIFICATIONS_CLEAR_NOTIFICATIONS_CATEGORIES:
      draft.notificationsCategories = initialState.notificationsCategories;
      break;
    case Notifications.NOTIFICATIONS_MARK_AS_READ_NOTIFICATIONS_SUCCESS:
      draft.notifications = markAsReadNotifications(
        draft.notifications,
        action.payload.notificationId
      );
      draft.unseenNotifications -= action.payload.nNotificationsUpdated;
      break;
    case Notifications.NOTIFICATIONS_LAST_SEEN_NOTIFICATION_SUCCESS:
      draft.notifications = markAsReadNotifications(draft.notifications);
      draft.unseenNotifications -= action.payload.nNotificationsUpdated;
      draft.lastSeenNotificationId = action.payload.notificationId;
      break;
    case Notifications.NOTIFICATIONS_DELETE_SUCCESS:
      draft.notifications = deleteNotifications(
        draft.notifications,
        action.payload.notificationId
      );
      draft.unseenNotifications -= action.payload.nNotificationsUpdated;
      break;
  }
}, initialState);
export default notifications;
