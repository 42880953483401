// @flow
// Utils for queries and reports related with queries.

export type OptionsProps = {
  optionsProps: {
    roles: Array<{ key: string, value: string, text: string }>,
    genders: Array<{ key: string, value: string, text: string }>,
    programs: Array<{ key: string, value: string, text: string }>,
    cohorts: Array<{ key: string, value: string, text: string }>,
    terms: Array<{ key: string, value: string, text: string }>,
    institutions: Array<{ key: string, value: string, text: string }>,
    institutionsTypes: Array<{ key: string, value: string, text: string }>,
    rotationsStatus: Array<{ key: string, value: string, text: string }>,
    periods: Array<{ key: string, value: string, text: string }>,
    caseLogsStatus: Array<{ key: string, value: string, text: string }>,
    patientTypeAges: Array<{ key: string, value: string, text: string }>,
    patientGenders: Array<{ key: string, value: string, text: string }>,
    patientRaces: Array<{ key: string, value: string, text: string }>,
    caseLogsLanguagesList: Array<{ key: string, value: string, text: string }>,
    caseLogsInsurancesList: Array<{ key: string, value: string, text: string }>,
    caseLogsReasonForVisits: Array<{
      key: string,
      value: string,
      text: string,
    }>,
    caseLogsDisciplinesListForDiagnostics: Array<{
      key: string,
      value: string,
      text: string,
    }>,
    caseLogsDiagnosticsList: Array<{
      key: string,
      value: string,
      text: string,
    }>,
    caseLogsDisciplinesListForProcedures: Array<{
      key: string,
      value: string,
      text: string,
    }>,
    caseLogsProceduresList: Array<{ key: string, value: string, text: string }>,
    caseLogsTeethList: Array<{ key: string, value: string, text: string }>,
    evaluationsStatus: Array<{ key: string, value: string, text: string }>,
    evaluationType: Array<{ key: string, value: string, text: string }>,
    evaluationTemplates: Array<{ key: string, value: string, text: string }>,
    evaluationsAnswer: Array<{ key: string, value: string, text: string }>,
    users: Array<{ key: string, value: string, text: string }>,
  },
};

export const optionsPropsList = {
  Users: {
    role: "roles",
    gender: "genders",
  },
  Enrollments: {
    "program._id": "programs",
    "cohort._id": "cohorts",
    //Users:
    "student.gender": "genders",
  },
  Institutions: {
    _id: "institutions",
    type: "institutionsTypes",
  },
  Rotations: {
    status: "rotationsStatus",
    "period._id": "periods",
    //Users:
    "student.gender": "genders",
    //Institutions:
    "institution._id": "institutions",
    "institutions._id": "institutions",
    "institutions.type": "institutionsTypes",
    //Enrollments:
    "enrollment.program._id": "programs",
    "enrollment.cohort._id": "cohorts",
  },
  CaseLogs: {
    status: "caseLogsStatus",
    "patient.typeAge": "patientTypeAges",
    "patient.gender": "patientGenders",
    "patient.race": "patientRaces",
    "patient.firstLanguage": "caseLogsLanguagesList",
    "patient.insurance": "caseLogsInsurancesList",
    reasonForVisit: "caseLogsReasonForVisits",
    preceptor: "usersPreceptors",
    "diagnosesAndProcedures.toothReference": "caseLogsTeethList",
    "diagnosesAndProcedures.diagnosticDiscipline":
      "caseLogsDisciplinesListForDiagnostics",
    "diagnosesAndProcedures.diagnostic": "caseLogsDiagnosticsList",
    "diagnosesAndProcedures.procedureDiscipline":
      "caseLogsDisciplinesListForProcedures",
    "diagnosesAndProcedures.procedure": "caseLogsProceduresList",
    //Users:
    "student.gender": "genders",
    "preceptor.gender": "genders",
    //Institutions:
    "institution._id": "institutions",
    "institution.type": "institutionsTypes",
    //Rotations:
    "rotation.status": "rotationsStatus",
    "rotation.period._id": "periods",
    //Enrollments:
    "rotation.enrollment.program._id": "programs",
    "rotation.enrollment.cohort._id": "cohorts",
  },
  Evaluations: {
    status: "evaluationsStatus",
    evaluationType: "evaluationType",
    "evaluationScheduler.evaluationTemplate.name": "evaluationTemplates",
    "answer.answer": "evaluationsAnswer",
    //Institutions:
    "rotation.institution._id": "institutions",
    "rotation.institutions._id": "institutions",
    "rotation.institutions.type": "institutionsTypes",
    //Users:
    "evaluator.role": "roles",
    "evaluator.gender": "genders",
    "target.role": "roles",
    "target.gender": "genders",
    //Enrollments:
    "rotation.enrollment.program._id": "programs",
    "rotation.enrollment.cohort._id": "cohorts",
  },
};

export const onCreateOptionsProps = ({
  accessControl: { roles },
  usersManager: { filters },
  evaluationsTemplates: { templates },
  tenantSettings: {
    tenantSettings: {
      CASELOGS_LANGUAGE_LIST: { values: caseLogsLanguagesList },
      CASELOGS_INSURANCE_LIST: { values: caseLogsInsurancesList },
      CASELOGS_RACES_LIST: { values: caseLogsRacesList },
      CASELOGS_REASONS_FOR_VISIT_LIST: { values: caseLogsReasonsForVisitList },
      CASELOGS_DISCIPLINES_LIST_FOR_DIAGNOSTICS: {
        values: caseLogsDisciplinesListForDiagnostics,
      },
      CASELOGS_DIAGNOSTICS_LIST: { valuesApi: caseLogsDiagnosticsList },
      CASELOGS_DISCIPLINES_LIST_FOR_PROCEDURES: {
        values: caseLogsDisciplinesListForProcedures,
      },
      CASELOGS_PROCEDURES_LIST: { valuesApi: caseLogsProceduresList },
      CASELOGS_TOOTH_LIST: { values: caseLogsTeethList },
      INSTITUTIONS_TYPES_LIST: { valuesApi: institutionsTypes },
    },
  },
  users,
  institutions: { institutions },
  periods: { periods },
}: Object) => {
  const myRoles = roles
    .filter(({ isActive }) => isActive)
    .map(({ apiName, name }) => ({
      key: apiName,
      value: apiName,
      text: name,
    }));
  const apiRoles = myRoles.map(({ value }) => value);
  let myUsers = [],
    myUsersPreceptors = [];
  for (let i = 0; i < apiRoles.length; i++) {
    if (users && users[apiRoles[i]]) {
      const section = {
        key: apiRoles[i],
        text: `${myRoles[i].text}`,
        onClick: null,
        style: { cursor: "default" },
        disabled: true,
      };
      myUsers.push(section);
      if (apiRoles[i] === "Preceptors") {
        myUsersPreceptors = users[apiRoles[i]].users.map(
          ({ _id, firstName, lastName }) => ({
            key: _id,
            value: _id,
            text: `${firstName} ${lastName}`,
          })
        );
        myUsers = [...myUsers, ...myUsersPreceptors];
      } else {
        myUsers = [
          ...myUsers,
          ...users[apiRoles[i]].users.map(({ _id, firstName, lastName }) => ({
            key: _id,
            value: _id,
            text: `${firstName} ${lastName}`,
          })),
        ];
      }
    }
  }
  return {
    optionsProps: {
      roles: myRoles,
      genders: [
        {
          key: "male",
          value: "male",
          text: "Male",
        },
        {
          key: "female",
          value: "female",
          text: "Female",
        },
      ],
      programs:
        filters && filters.programs
          ? filters.programs.options.map(({ _id, name }) => ({
              key: _id,
              value: _id,
              text: name,
            }))
          : [],
      cohorts:
        filters && filters.cohorts
          ? filters.cohorts.options.map(({ _id, name }) => ({
              key: _id,
              value: _id,
              text: name,
            }))
          : [],
      institutions: institutions.map(({ _id, name }) => ({
        key: _id,
        value: _id,
        text: name,
      })),
      institutionsTypes: institutionsTypes.map(({ _id, type }, index) => ({
        key: `institutionType-${type}-${_id}-${index}`,
        value: _id,
        text: type,
      })),
      rotationsStatus: [
        {
          key: "upcoming",
          value: "upcoming",
          text: "Upcoming",
        },
        {
          key: "incourse",
          value: "incourse",
          text: "In Progress",
        },
        {
          key: "finished",
          value: "finished",
          text: "Finished",
        },
      ],
      periods: periods.map(({ _id, name }) => ({
        key: _id,
        value: _id,
        text: name,
      })),
      caseLogsStatus: [
        {
          key: "pending",
          value: "pending",
          text: "Pending",
        },
        {
          key: "denied",
          value: "denied",
          text: "Rejected",
        },
        {
          key: "approved",
          value: "approved",
          text: "Approved",
        },
      ],
      patientTypeAges: [
        { key: "years", value: "years", text: "Years" },
        { key: "months", value: "months", text: "Months" },
        { key: "weeks", value: "weeks", text: "Weeks" },
      ],
      patientGenders: [
        { key: "male", value: "Male", text: "Male" },
        { key: "female", value: "Female", text: "Female" },
        { key: "other", value: "Other", text: "Other" },
      ],
      patientRaces: caseLogsRacesList.map((race) => ({
        key: race,
        value: race,
        text: race,
      })),
      caseLogsLanguagesList: caseLogsLanguagesList.map((language) => ({
        key: language,
        value: language,
        text: language,
      })),
      caseLogsInsurancesList: caseLogsInsurancesList.map((insurance) => ({
        key: insurance,
        value: insurance,
        text: insurance,
      })),
      caseLogsReasonForVisits: caseLogsReasonsForVisitList.map(
        (reasonForVisit) => ({
          key: reasonForVisit,
          value: reasonForVisit,
          text: reasonForVisit,
        })
      ),
      caseLogsDisciplinesListForDiagnostics:
        caseLogsDisciplinesListForDiagnostics.map((discipline) => ({
          key: discipline,
          value: discipline,
          text: discipline,
        })),
      caseLogsDiagnosticsList: caseLogsDiagnosticsList
        .map(({ diagnostic }) => diagnostic)
        .filter((elem, index, array) => array.indexOf(elem) === index)
        .map((diagnostic) => ({
          key: diagnostic,
          value: diagnostic,
          text: diagnostic,
        })),
      caseLogsDisciplinesListForProcedures:
        caseLogsDisciplinesListForProcedures.map((discipline) => ({
          key: discipline,
          value: discipline,
          text: discipline,
        })),
      caseLogsProceduresList: caseLogsProceduresList
        .map(({ procedure }) => procedure)
        .filter((elem, index, array) => array.indexOf(elem) === index)
        .map((procedure) => ({
          key: procedure,
          value: procedure,
          text: procedure,
        })),
      caseLogsTeethList: caseLogsTeethList.map(
        ({ toothName, toothReference }) => ({
          key: toothReference,
          value: toothReference,
          text: `${toothReference}: ${toothName}`,
        })
      ),
      evaluationsStatus: [
        {
          key: "completed",
          value: "completed",
          text: "Completed",
          icon: {
            name: "circle",
            size: "tiny",
            color: "green",
          },
        },
        {
          key: "incourse",
          value: "incourse",
          text: "In Progress",
          icon: {
            name: "circle",
            size: "tiny",
            color: "orange",
          },
        },
        {
          key: "closed",
          value: "closed",
          text: "Closed",
          icon: {
            name: "circle",
            size: "tiny",
            color: "red",
          },
        },
      ],
      evaluationType: [
        {
          key: "UserEvaluations",
          value: "UserEvaluations",
          text: "User Evaluations",
        },
        {
          key: "InstitutionEvaluations",
          value: "InstitutionEvaluations",
          text: "Clinic Evaluations",
        },
        {
          key: "CompanyEvaluations",
          value: "CompanyEvaluations",
          text: "School Evaluations",
        },
        {
          key: "CustomEvaluations",
          value: "CustomEvaluations",
          text: "Custom Evaluations",
        },
      ],
      evaluationTemplates: templates.map(({ _id, name }, index) => ({
        key: `template-${_id}-${index}`,
        value: name,
        text: name,
      })),
      evaluationsAnswer: [
        {
          key: "positive_answers",
          value: "option_positive,option_singleChoice_positive",
          text: "Goal Met",
        },
        {
          key: "negative_answers",
          value: "option_negative,option_singleChoice_negative",
          text: "Standard Not Met",
        },
      ],
      users: myUsers,
      usersPreceptors: myUsersPreceptors,
    },
  };
};

export const onParseObjectValue = (obj: Object) => {
  let value = "";
  Object.keys(obj)
    .filter((key) => key !== "label")
    .map((key) => {
      value += `${key.charAt(0).toUpperCase() + key.slice(1)}: ${
        obj[key] || "Not provided"
      }; `;
      return key;
    });
  return value;
};

export const onParseArrayValue = (array: Array<any>) => {
  let value = "";
  array.map((v) => {
    if (v instanceof Object) {
      value += onParseObjectValue(v);
    } else {
      value += value;
    }
    return v;
  });
  return value;
};

export const onParseOptionsProps = (
  value: any,
  // optionsProps: $PropertyType<OptionsProps, "optionsProps">,
  optionsProps: Object,
  model:
    | "Users"
    | "Enrollments"
    | "Institutions"
    | "Rotations"
    | "CaseLogs"
    | "Evaluations",
  fieldName: string
): any => {
  if (
    value &&
    value !== "Undefined" &&
    optionsPropsList &&
    optionsPropsList[model] &&
    optionsPropsList[model][fieldName] &&
    optionsProps &&
    optionsProps[optionsPropsList[model][fieldName]]
  ) {
    const valuesArray = optionsProps[optionsPropsList[model][fieldName]].filter(
      (filter) => filter.value === value
    );
    if (valuesArray.length > 0) {
      value = valuesArray[0]["text"];
    }
  }
  return value;
};

export const onParseDynamicFilters = (dynamicFilters: {
  Users: Array<Object>,
  Enrollments: Array<Object>,
  Institutions: Array<Object>,
  Rotations: Array<Object>,
  CaseLogs: Array<Object>,
  Evaluations: Array<Object>,
}) => {
  let filtersDynamic = [];
  for (let modelDynamic in dynamicFilters) {
    if (
      dynamicFilters[modelDynamic] &&
      dynamicFilters[modelDynamic].length > 0
    ) {
      const arrayModelDynamic = dynamicFilters[modelDynamic];
      const filteredArrayModelDynamic = arrayModelDynamic.filter(
        ({ match: { type, val, conditions, from, to, isDynamic } }) => {
          if (!isDynamic) {
            return false;
          }
          switch (type) {
            case "string":
            case "id":
              if (val && val.length) {
                return true;
              }
              break;
            case "number":
              if (conditions && (conditions._gt || conditions._lt)) {
                return true;
              } else if (conditions && conditions._eq) {
                return true;
              }
              break;
            case "date":
              if (from || to) {
                return true;
              }
              break;
            case "boolean":
              if (val !== null) {
                return true;
              }
              break;
            default:
          }
          return false;
        }
      );
      if (filteredArrayModelDynamic.length > 0) {
        filtersDynamic = [
          ...filtersDynamic,
          ...filteredArrayModelDynamic.map(({ match }) => match),
        ];
      }
    }
  }
  return filtersDynamic;
};
