import type { Config, tAPI } from "./API";
// API Access Control static class
const apiBaseAccessControlRoles = "/roles",
  apiBaseAccessControlDynamicRules = "/dynamic-rules";
export default class AccessControl {
  // Get a list of roles
  static GetAccessControlRoles(
    api: tAPI,
    roles: string[] = [],
    config?: Config
  ) {
    let queryParams = "";
    if (roles.length > 0) {
      queryParams += `?roles=${roles.join()}`;
    }
    return api.GET(`${apiBaseAccessControlRoles}${queryParams}`, config);
  }
  // Create a Role
  static CreateRole(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBaseAccessControlRoles}`, data, config);
  }
  // Edit a role from some specific list of roles
  static EditRole(api: tAPI, role: string, data: object, config?: Config) {
    return api.PATCH(`${apiBaseAccessControlRoles}/${role}`, data, config);
  }
  // Delete a Role
  static DeleteRole(api: tAPI, role: string, config?: Config) {
    return api.DELETE(`${apiBaseAccessControlRoles}/${role}`, config);
  }
  // Get a list of Dynamic Rules
  static GetAccessControlDynamicRules(api: tAPI, config?: Config) {
    return api.GET(
      `${apiBaseAccessControlDynamicRules}?populated=true`,
      config
    );
  }
  // Create a Dynamic Rule
  static CreateDynamicRule(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBaseAccessControlDynamicRules}`, data, config);
  }
  // Edit a dynamic rule from some specific dynamic rule Id
  static EditDynamicRule(
    api: tAPI,
    dynamicRuleId: string,
    data: object,
    config?: Config
  ) {
    return api.PATCH(
      `${apiBaseAccessControlDynamicRules}/${dynamicRuleId}`,
      data,
      config
    );
  }
  // Delete a Dynamic Rule
  static DeleteDynamicRule(api: tAPI, dynamicRuleId: string, config?: Config) {
    return api.DELETE(
      `${apiBaseAccessControlDynamicRules}/${dynamicRuleId}`,
      config
    );
  }
}
