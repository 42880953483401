import { createAction } from "deox";

// Actions for Reports and Queries

// QUERIES
// Fetch all queries
export const REPORTS_FETCH_QUERIES: string = "REPORTS/FETCH/QUERIES";
export const REPORTS_FETCH_QUERIES_REQUEST: string =
  "REPORTS/FETCH/QUERIES/REQUEST";
export const REPORTS_FETCH_QUERIES_SUCCESS: string =
  "REPORTS/FETCH/QUERIES/SUCCESS";
export const REPORTS_FETCH_QUERIES_FAILURE: string =
  "REPORTS/FETCH/QUERIES/FAILURE";
export const reportsFetchQueries = createAction(
  REPORTS_FETCH_QUERIES,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsFetchQueriesRequest = createAction(
  REPORTS_FETCH_QUERIES_REQUEST
);
export const reportsFetchQueriesSuccess = createAction(
  REPORTS_FETCH_QUERIES_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsFetchQueriesFailure = createAction(
  REPORTS_FETCH_QUERIES_FAILURE
);
// Create a Query to run Reports for that
export const REPORTS_CREATE_QUERY: string = "REPORTS/CREATE/QUERY";
export const REPORTS_CREATE_QUERY_REQUEST: string =
  "REPORTS/CREATE/QUERY/REQUEST";
export const REPORTS_CREATE_QUERY_SUCCESS: string =
  "REPORTS/CREATE/QUERY/SUCCESS";
export const REPORTS_CREATE_QUERY_FAILURE: string =
  "REPORTS/CREATE/QUERY/FAILURE";
export const reportsCreateQuery = createAction(
  REPORTS_CREATE_QUERY,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsCreateQueryRequest = createAction(
  REPORTS_CREATE_QUERY_REQUEST
);
export const reportsCreateQuerySuccess = createAction(
  REPORTS_CREATE_QUERY_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsCreateQueryFailure = createAction(
  REPORTS_CREATE_QUERY_FAILURE
);
// Set queryId to Fetch some query
export const REPORTS_SET_QUERY_ID: string = "REPORTS/SET/QUERY_ID";
export const reportsSetQueryId = createAction(
  REPORTS_SET_QUERY_ID,
  (resolve) => (payload?: object) => resolve(payload)
);
// Unset queryId
export const REPORTS_UNSET_QUERY_ID: string = "REPORTS/UNSET/QUERY_ID";
export const reportsUnsetQueryId = createAction(
  REPORTS_UNSET_QUERY_ID,
  (resolve) => (payload?: object) => resolve(payload)
);
// Fetch a Query
export const REPORTS_FETCH_QUERY: string = "REPORTS/FETCH/QUERY";
export const REPORTS_FETCH_QUERY_REQUEST: string =
  "REPORTS/FETCH/QUERY/REQUEST";
export const REPORTS_FETCH_QUERY_SUCCESS: string =
  "REPORTS/FETCH/QUERY/SUCCESS";
export const REPORTS_FETCH_QUERY_FAILURE: string =
  "REPORTS/FETCH/QUERY/FAILURE";
export const reportsFetchQuery = createAction(
  REPORTS_FETCH_QUERY,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsFetchQueryRequest = createAction(
  REPORTS_FETCH_QUERY_REQUEST
);
export const reportsFetchQuerySuccess = createAction(
  REPORTS_FETCH_QUERY_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsFetchQueryFailure = createAction(
  REPORTS_FETCH_QUERY_FAILURE
);
// Edit a Query
export const REPORTS_EDIT_QUERY: string = "REPORTS/EDIT/QUERY";
export const REPORTS_EDIT_QUERY_REQUEST: string = "REPORTS/EDIT/QUERY/REQUEST";
export const REPORTS_EDIT_QUERY_SUCCESS: string = "REPORTS/EDIT/QUERY/SUCCESS";
export const REPORTS_EDIT_QUERY_FAILURE: string = "REPORTS/EDIT/QUERY/FAILURE";
export const reportsEditQuery = createAction(
  REPORTS_EDIT_QUERY,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsEditQueryRequest = createAction(REPORTS_EDIT_QUERY_REQUEST);
export const reportsEditQuerySuccess = createAction(
  REPORTS_EDIT_QUERY_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsEditQueryFailure = createAction(REPORTS_EDIT_QUERY_FAILURE);
// Delete Query
export const REPORTS_DELETE_QUERY: string = "REPORTS/DELETE/QUERY";
export const REPORTS_DELETE_QUERY_REQUEST: string =
  "REPORTS/DELETE/QUERY/REQUEST";
export const REPORTS_DELETE_QUERY_SUCCESS: string =
  "REPORTS/DELETE/QUERY/SUCCESS";
export const REPORTS_DELETE_QUERY_FAILURE: string =
  "REPORTS/DELETE/QUERY/FAILURE";
export const reportsDeleteQuery = createAction(
  REPORTS_DELETE_QUERY,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsDeleteQueryRequest = createAction(
  REPORTS_DELETE_QUERY_REQUEST
);
export const reportsDeleteQuerySuccess = createAction(
  REPORTS_DELETE_QUERY_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsDeleteQueryFailure = createAction(
  REPORTS_DELETE_QUERY_FAILURE
);
// Run this query to get report result
export const REPORTS_RUN_QUERY: string = "REPORTS/RUN/QUERY";
export const REPORTS_RUN_QUERY_REQUEST: string = "REPORTS/RUN/QUERY/REQUEST";
export const REPORTS_RUN_QUERY_SUCCESS: string = "REPORTS/RUN/QUERY/SUCCESS";
export const REPORTS_RUN_QUERY_FAILURE: string = "REPORTS/RUN/QUERY/FAILURE";
export const reportsRunQuery = createAction(
  REPORTS_RUN_QUERY,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsRunQueryRequest = createAction(REPORTS_RUN_QUERY_REQUEST);
export const reportsRunQuerySuccess = createAction(
  REPORTS_RUN_QUERY_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsRunQueryFailure = createAction(REPORTS_RUN_QUERY_FAILURE);
// Reset Query Loaded
export const REPORTS_RESET_QUERY_LOADED: string = "REPORTS/RESET/QUERY_LOADED";
export const reportsResetQueryLoaded = createAction(REPORTS_RESET_QUERY_LOADED);
// Query Create and Edit:
export const REPORTS_QUERY_CREATE_EDIT_UPDATE: string =
  "REPORTS/QUERY/CREATE/UPDATE";
export const reportsQueryCreateEditUpdate = createAction(
  REPORTS_QUERY_CREATE_EDIT_UPDATE,
  (resolve) => (payload?: object) => resolve(payload)
);
// Create and Edit Query Reset process
export const REPORTS_QUERY_CREATE_EDIT_RESET: string =
  "REPORTS/QUERY/CREATE/RESET";
export const reportsQueryCreateEditReset = createAction(
  REPORTS_QUERY_CREATE_EDIT_RESET
);
// END QUERIES />

// REPORTS
// Fetch all reports
export const REPORTS_FETCH_REPORTS: string = "REPORTS/FETCH/REPORTS";
export const REPORTS_FETCH_REPORTS_REQUEST: string =
  "REPORTS/FETCH/REPORTS/REQUEST";
export const REPORTS_FETCH_REPORTS_SUCCESS: string =
  "REPORTS/FETCH/REPORTS/SUCCESS";
export const REPORTS_FETCH_REPORTS_FAILURE: string =
  "REPORTS/FETCH/REPORTS/FAILURE";
export const reportsFetchReports = createAction(
  REPORTS_FETCH_REPORTS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsFetchReportsRequest = createAction(
  REPORTS_FETCH_REPORTS_REQUEST
);
export const reportsFetchReportsSuccess = createAction(
  REPORTS_FETCH_REPORTS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsFetchReportsFailure = createAction(
  REPORTS_FETCH_REPORTS_FAILURE
);
// END REPORTS />

// REPORTS QUERIES
// Fetch all reports from some query set up before
export const REPORTS_QUERIES_FETCH_REPORTS: string =
  "REPORTS/QUERIES/FETCH/REPORTS";
export const REPORTS_QUERIES_FETCH_REPORTS_REQUEST: string =
  "REPORTS/QUERIES/FETCH/REPORTS/REQUEST";
export const REPORTS_QUERIES_FETCH_REPORTS_SUCCESS: string =
  "REPORTS/QUERIES/FETCH/REPORTS/SUCCESS";
export const REPORTS_QUERIES_FETCH_REPORTS_FAILURE: string =
  "REPORTS/QUERIES/FETCH/REPORTS/FAILURE";
export const reportsQueriesFetchReports = createAction(
  REPORTS_QUERIES_FETCH_REPORTS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsQueriesFetchReportsRequest = createAction(
  REPORTS_QUERIES_FETCH_REPORTS_REQUEST
);
export const reportsQueriesFetchReportsSuccess = createAction(
  REPORTS_QUERIES_FETCH_REPORTS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsQueriesFetchReportsFailure = createAction(
  REPORTS_QUERIES_FETCH_REPORTS_FAILURE
);
// Show or Hide report warning message to let you know there are reports
// to check it into reports views
export const REPORTS_QUERIES_SHOW_REPORT_WARNING: string =
  "REPORTS/QUERIES/SHOW/REPORT_WARNING";
export const reportsQueriesShowReportWarning = createAction(
  REPORTS_QUERIES_SHOW_REPORT_WARNING,
  (resolve) => (payload?: object) => resolve(payload)
);
export const REPORTS_QUERIES_HIDE_REPORT_WARNING: string =
  "REPORTS/QUERIES/HIDE/REPORT_WARNING";
export const reportsQueriesHideReportWarning = createAction(
  REPORTS_QUERIES_HIDE_REPORT_WARNING,
  (resolve) => (payload?: object) => resolve(payload)
);
// Set reportId to Fetch results from some historic reports
export const REPORTS_QUERIES_SET_REPORT_ID: string =
  "REPORTS/QUERIES/SET/REPORT_ID";
export const reportsQueriesSetReportId = createAction(
  REPORTS_QUERIES_SET_REPORT_ID,
  (resolve) => (payload?: object) => resolve(payload)
);
// Unset reportId
export const REPORTS_QUERIES_UNSET_REPORT_ID: string =
  "REPORTS/QUERIES/UNSET/REPORT_ID";
export const reportsQueriesUnsetReportId = createAction(
  REPORTS_QUERIES_UNSET_REPORT_ID,
  (resolve) => (payload?: object) => resolve(payload)
);
// Fetch results from some historic reports
export const REPORTS_QUERIES_FETCH_REPORT: string =
  "REPORTS/QUERIES/FETCH/REPORT";
export const REPORTS_QUERIES_FETCH_REPORT_REQUEST: string =
  "REPORTS/QUERIES/FETCH/REPORT/REQUEST";
export const REPORTS_QUERIES_FETCH_REPORT_SUCCESS: string =
  "REPORTS/QUERIES/FETCH/REPORT/SUCCESS";
export const REPORTS_QUERIES_FETCH_REPORT_FAILURE: string =
  "REPORTS/QUERIES/FETCH/REPORT/FAILURE";
export const reportsQueriesFetchReport = createAction(
  REPORTS_QUERIES_FETCH_REPORT,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsQueriesFetchReportRequest = createAction(
  REPORTS_QUERIES_FETCH_REPORT_REQUEST
);
export const reportsQueriesFetchReportSuccess = createAction(
  REPORTS_QUERIES_FETCH_REPORT_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsQueriesFetchReportFailure = createAction(
  REPORTS_QUERIES_FETCH_REPORT_FAILURE
);
// Update some report from some historics reports
// Edit a Report
export const REPORTS_QUERIES_EDIT_REPORT: string =
  "REPORTS/QUERIES/EDIT/REPORT";
export const REPORTS_QUERIES_EDIT_REPORT_REQUEST: string =
  "REPORTS/QUERIES/EDIT/REPORT/REQUEST";
export const REPORTS_QUERIES_EDIT_REPORT_SUCCESS: string =
  "REPORTS/QUERIES/EDIT/REPORT/SUCCESS";
export const REPORTS_QUERIES_EDIT_REPORT_FAILURE: string =
  "REPORTS/QUERIES/EDIT/REPORT/FAILURE";
export const reportsQueriesEditReport = createAction(
  REPORTS_QUERIES_EDIT_REPORT,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsQueriesEditReportRequest = createAction(
  REPORTS_QUERIES_EDIT_REPORT_REQUEST
);
export const reportsQueriesEditReportSuccess = createAction(
  REPORTS_QUERIES_EDIT_REPORT_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsQueriesEditReportFailure = createAction(
  REPORTS_QUERIES_EDIT_REPORT_FAILURE
);
// Delete Report
export const REPORTS_QUERIES_DELETE_REPORT: string =
  "REPORTS/QUERIES/DELETE/REPORT";
export const REPORTS_QUERIES_DELETE_REPORT_REQUEST: string =
  "REPORTS/QUERIES/DELETE/REPORT/REQUEST";
export const REPORTS_QUERIES_DELETE_REPORT_SUCCESS: string =
  "REPORTS/QUERIES/DELETE/REPORT/SUCCESS";
export const REPORTS_QUERIES_DELETE_REPORT_FAILURE: string =
  "REPORTS/QUERIES/DELETE/REPORT/FAILURE";
export const reportsQueriesDeleteReport = createAction(
  REPORTS_QUERIES_DELETE_REPORT,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsQueriesDeleteReportRequest = createAction(
  REPORTS_QUERIES_DELETE_REPORT_REQUEST
);
export const reportsQueriesDeleteReportSuccess = createAction(
  REPORTS_QUERIES_DELETE_REPORT_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsQueriesDeleteReportFailure = createAction(
  REPORTS_QUERIES_DELETE_REPORT_FAILURE
);
// Transform a Report (Counting Feature)
export const REPORTS_QUERIES_TRANSFORM_REPORT: string =
  "REPORTS/QUERIES/CREATE/REPORT";
export const REPORTS_QUERIES_TRANSFORM_REPORT_REQUEST: string =
  "REPORTS/QUERIES/CREATE/REPORT/REQUEST";
export const REPORTS_QUERIES_TRANSFORM_REPORT_SUCCESS: string =
  "REPORTS/QUERIES/CREATE/REPORT/SUCCESS";
export const REPORTS_QUERIES_TRANSFORM_REPORT_FAILURE: string =
  "REPORTS/QUERIES/CREATE/REPORT/FAILURE";
export const reportsQueriesTransformReport = createAction(
  REPORTS_QUERIES_TRANSFORM_REPORT,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsQueriesTransformReportRequest = createAction(
  REPORTS_QUERIES_TRANSFORM_REPORT_REQUEST
);
export const reportsQueriesTransformReportSuccess = createAction(
  REPORTS_QUERIES_TRANSFORM_REPORT_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsQueriesTransformReportFailure = createAction(
  REPORTS_QUERIES_TRANSFORM_REPORT_FAILURE
);
// Generate CSV reports depend on query and report
export const REPORTS_QUERIES_GENERATE_CSV: string =
  "REPORTS/QUERIES/GENERATE_CSV";
export const REPORTS_QUERIES_GENERATE_CSV_REQUEST: string =
  "REPORTS/QUERIES/GENERATE_CSV/REQUEST";
export const REPORTS_QUERIES_GENERATE_CSV_SUCCESS: string =
  "REPORTS/QUERIES/GENERATE_CSV/SUCCESS";
export const REPORTS_QUERIES_GENERATE_CSV_FAILURE: string =
  "REPORTS/QUERIES/GENERATE_CSV/FAILURE";
export const reportsQueriesGenerateCSV = createAction(
  REPORTS_QUERIES_GENERATE_CSV,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsQueriesGenerateCSVRequest = createAction(
  REPORTS_QUERIES_GENERATE_CSV_REQUEST
);
export const reportsQueriesGenerateCSVSuccess = createAction(
  REPORTS_QUERIES_GENERATE_CSV_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsQueriesGenerateCSVFailure = createAction(
  REPORTS_QUERIES_GENERATE_CSV_FAILURE
);
// Generate CSV from Transform a Report (Counting Feature)
export const REPORTS_QUERIES_TRANSFORM_REPORT_GENERATE_CSV: string =
  "REPORTS/QUERIES/TRANSFORM_REPORT/GENERATE_CSV";
export const REPORTS_QUERIES_TRANSFORM_REPORT_GENERATE_CSV_REQUEST: string =
  "REPORTS/QUERIES/TRANSFORM_REPORT/GENERATE_CSV/REQUEST";
export const REPORTS_QUERIES_TRANSFORM_REPORT_GENERATE_CSV_SUCCESS: string =
  "REPORTS/QUERIES/TRANSFORM_REPORT/GENERATE_CSV/SUCCESS";
export const REPORTS_QUERIES_TRANSFORM_REPORT_GENERATE_CSV_FAILURE: string =
  "REPORTS/QUERIES/TRANSFORM_REPORT/GENERATE_CSV/FAILURE";
export const reportsQueriesTransformReportGenerateCSV = createAction(
  REPORTS_QUERIES_TRANSFORM_REPORT_GENERATE_CSV,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsQueriesTransformReportGenerateCSVRequest = createAction(
  REPORTS_QUERIES_TRANSFORM_REPORT_GENERATE_CSV_REQUEST
);
export const reportsQueriesTransformReportGenerateCSVSuccess = createAction(
  REPORTS_QUERIES_TRANSFORM_REPORT_GENERATE_CSV_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const reportsQueriesTransformReportGenerateCSVFailure = createAction(
  REPORTS_QUERIES_TRANSFORM_REPORT_GENERATE_CSV_FAILURE
);
// END REPORTS />
