// @flow
import * as React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Notifications } from "../../store/actions/";
import NotificationsPresentational from "./NotificationsPresentational";
// Styles
import "./Notifications.scss";

type Props = {
  authPermissions: Object,
  activeNotifications: boolean,
  notifications: {
    all: Array<Object>,
    notifications: Array<Object>,
    reminders: Array<Object>,
    isFetchingNotifications: boolean,
  },
  totalNotifications: number,
  unseenNotifications: number,
  setImg: (_img: string, updatedAt: string) => string,
  getNotifications: (payload: Object) => void,
  updateLastSeenNotification: (payload: Object) => void,
  onCloseNotifications: () => void,
  goTo: (pathname: string, state: Object) => void,
};

class NotificationsContainer extends React.Component<Props> {
  notificationsRef = null;
  onSeeAllNotifications = (lastNotificationId: ?string) => {
    const {
      notifications: { all },
      unseenNotifications,
      updateLastSeenNotification,
    } = this.props;
    if (all.length && unseenNotifications > 0) {
      let notificationId = lastNotificationId;
      if (!notificationId) {
        notificationId = all[all.length - 1]._id;
      }
      updateLastSeenNotification({
        notificationId,
        body: {
          notification: notificationId,
        },
      });
    }
  };
  onGettingMoreNotifications = (skip: number) => {
    const { getNotifications } = this.props;
    return new Promise((resolve, reject) => {
      const formValues = {
        skip,
      };
      const callbacks = {
        callbackError: (error) => {
          window.logger.log("error - onGettingMoreNotifications", error);
          reject();
        },
        callbackSuccess: () => {
          resolve();
        },
      };
      const payload = Object.assign({}, formValues, callbacks);
      getNotifications(payload);
    });
  };
  onGetMoreNotifications = (lastNotificationId: string) => () => {
    const {
      notifications: {
        all: { length: loadedNotifications },
      },
      totalNotifications,
    } = this.props;
    if (totalNotifications > loadedNotifications) {
      this.onSeeAllNotifications(lastNotificationId);
      this.onGettingMoreNotifications(loadedNotifications).catch((error) => {
        window.logger.log("onGetMoreNotifications", error);
      });
    } else {
      this.onSeeAllNotifications(lastNotificationId);
    }
  };
  handleOutsideClick = (event: Object) => {
    // ignore clicks on the component itself
    if (
      (event?.target?.dataset?.icono !== "is-icono-close" &&
        this.notificationsRef &&
        this.notificationsRef.contains(event.target)) ||
      event?.target?.firstElementChild?.dataset?.popup ===
        "is-popup-notification" ||
      event?.target?.dataset?.popup === "is-popup-notification" ||
      event?.target?.dataset?.btn === "is-popup-notification" ||
      event?.target?.dataset?.clear === "is-clear-expiration-date"
    ) {
      return;
    }
    const { onCloseNotifications } = this.props;
    onCloseNotifications();
    document.removeEventListener("click", this.handleOutsideClick, false);
  };
  onInitNotifications = (notificationsRef: Object) => {
    this.notificationsRef = notificationsRef;
  };
  getSnapshotBeforeUpdate(prevProps: Props, prevState: any) {
    const { activeNotifications: activeNotificationsOld } = prevProps;
    const { activeNotifications: activeNotificationsNew } = this.props;
    if (
      activeNotificationsOld !== activeNotificationsNew &&
      activeNotificationsNew
    ) {
      return activeNotificationsNew;
    }
    return null;
  }
  componentDidUpdate(
    prevProps: Props,
    prevState: any,
    activeNotificationsNew: any
  ) {
    if (activeNotificationsNew) {
      this.onSeeAllNotifications();
      document.addEventListener("click", this.handleOutsideClick, false);
    }
  }
  render() {
    const { activeNotifications } = this.props;
    if (activeNotifications) {
      return (
        <NotificationsPresentational
          {...this.props}
          initializeNotifications={this.onInitNotifications}
          getMoreNotifications={this.onGetMoreNotifications}
        />
      );
    }
    return null;
  }
}

const bindActions = (dispatch, ownProps) => {
  return {
    getNotifications: (payload) =>
      dispatch(Notifications.notificationsFetchNotifications(payload)),
    updateLastSeenNotification: (payload) =>
      dispatch(Notifications.notificationsLastSeenNotification(payload)),
    goTo: (pathname: string, state: Object) => () => {
      ownProps.onCloseNotifications();
      dispatch(
        push({
          pathname,
          state,
        })
      );
    },
  };
};

export default connect(
  ({ auth: { role } }) => ({
    authPermissions: role.permissions,
  }),
  bindActions
)(NotificationsContainer);
