// Features
import produce from "immer";
import { Periods } from "../actions";
import { updateArrayWithElement } from "../../utils";
// Types
import type { Action } from "deox";
import type { UndefinedNull } from "../../types/common.types";
type PayloadPeriod = {
  _id?: string;
  name: string;
  createdAt?: Date;
  updatedAt?: Date;
};
export type StatePeriods = {
  lastFetching: Date | UndefinedNull;
  periods: PayloadPeriod[];
};
export type PeriodsActionReducers = Action<
  string,
  {
    period: PayloadPeriod;
    periodId: string;
    periods: StatePeriods["periods"];
  }
>;
// Periods Reducer
const initialState: StatePeriods = {
  lastFetching: null,
  periods: [],
};
const periods: (
  state: StatePeriods,
  action: PeriodsActionReducers
) => StatePeriods = produce(
  (draft: StatePeriods, action: PeriodsActionReducers) => {
    switch (action.type) {
      case Periods.PERIODS_FETCH_ALL_SUCCESS:
        draft.lastFetching = new Date();
        draft.periods = action.payload.periods;
        break;
      case Periods.PERIODS_CREATE_SUCCESS:
        draft.lastFetching = new Date();
        draft.periods = [...draft.periods, action.payload.period];
        break;
      case Periods.PERIODS_EDIT_SUCCESS:
        draft.periods = updateArrayWithElement(
          draft.periods,
          action.payload.period
        );
        break;
      case Periods.PERIODS_DELETE_SUCCESS:
        draft.periods = draft.periods.filter(
          ({ _id }) => _id !== action.payload.periodId
        );
        break;
    }
  },
  initialState
);
export default periods;
