// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { store } from "../../index";
import {
  onCheckReadChartsPermissions,
  onCheckReadQueriesPermissions,
  onCheckReadAllReportsPermissions,
  onCheckCreateQueriesPermissions,
  onCheckEditQueriesPermissions,
} from "./ReportsPermissions";
import { Reports } from "../../store/actions/";
import { onCreateQueryParams } from "../../store/sagas/reports";
import ProtectedRoute from "../shared/ProtectedRoute";

const mapDispatchToProps = (dispatch: Function, ownProps: Object) => {
  const {
    computedMatch: {
      params: { queryId, reportId },
    },
    location: { state },
  } = ownProps;
  const { queryId: storedQueryId, reportId: storedReportId } =
    store.getState().reports;
  if (queryId && queryId !== storedQueryId) {
    if (state && state.query) {
      dispatch(
        Reports.reportsFetchQuerySuccess({
          query: state.query,
          queryParams: onCreateQueryParams(state.query),
        })
      );
    }
    dispatch(
      Reports.reportsSetQueryId({
        queryId,
      })
    );
  }
  if (reportId && reportId !== storedReportId) {
    if (state && state.report) {
      dispatch(
        Reports.reportsQueriesFetchReportSuccess({
          report: state.report,
          query: state.report.query,
          queryParams: onCreateQueryParams(state.report.query),
        })
      );
    }
    dispatch(
      Reports.reportsQueriesSetReportId({
        reportId,
      })
    );
  }
  return { dispatch };
};
const ReportsRoute: React.ComponentType<any> = withRouter(
  connect(
    (
      { config, auth: { isAuthenticated, role } },
      {
        path,
        computedMatch: {
          params: { queryId, reportId },
        },
      }
    ) => {
      let isAllowedRoute = isAuthenticated && role.permissions ? true : false;
      let redirectTo = "/home";
      if (isAllowedRoute) {
        if (onCheckReadAllReportsPermissions(role.permissions)) {
          redirectTo = "/reports";
        } else if (onCheckReadChartsPermissions(role.permissions)) {
          redirectTo = "/reports/dashboard";
        } else if (onCheckReadQueriesPermissions(role.permissions)) {
          redirectTo = "/reports/dashboard/queries";
        } else {
          redirectTo = "/home";
          isAllowedRoute = false;
        }
      }
      if (
        isAllowedRoute &&
        path &&
        path ===
          "/reports/dashboard/query/:queryId(\\w+)/result/:reportId(\\w+)" &&
        queryId &&
        reportId &&
        !onCheckReadAllReportsPermissions(role.permissions)
      ) {
        // CHECK PERMISSIONS
        isAllowedRoute = false;
      } else if (
        isAllowedRoute &&
        path &&
        path === "/reports/dashboard/query/:queryId(\\w+)/results" &&
        queryId &&
        !onCheckReadAllReportsPermissions(role.permissions)
      ) {
        // CHECK PERMISSIONS
        isAllowedRoute = false;
      } else if (
        isAllowedRoute &&
        path &&
        path === "/reports/dashboard/query/:queryId(\\w+)/edit" &&
        queryId &&
        !onCheckEditQueriesPermissions(role.permissions)
      ) {
        // CHECK PERMISSIONS
        isAllowedRoute = false;
      } else if (
        isAllowedRoute &&
        path &&
        path === "/reports/dashboard/query/create" &&
        !onCheckCreateQueriesPermissions(role.permissions)
      ) {
        // CHECK PERMISSIONS
        isAllowedRoute = false;
      } else if (
        isAllowedRoute &&
        path &&
        path === "/reports/dashboard/queries" &&
        !onCheckReadQueriesPermissions(role.permissions)
      ) {
        // CHECK PERMISSIONS
        isAllowedRoute = false;
      } else if (
        isAllowedRoute &&
        path &&
        path === "/reports/dashboard/custom-reports" &&
        !onCheckReadAllReportsPermissions(role.permissions)
      ) {
        // CHECK PERMISSIONS
        isAllowedRoute = false;
      } else if (
        isAllowedRoute &&
        path &&
        path === "/reports/dashboard" &&
        !onCheckReadChartsPermissions(role.permissions)
      ) {
        // CHECK PERMISSIONS
        isAllowedRoute = false;
      } else if (
        isAllowedRoute &&
        path &&
        (path === "/reports" ||
          path === "/reports/case/:standardId([1-9][0-9]*)" ||
          path ===
            "/reports/case/:standardId([1-9][0-9]*)/:reportId([a-zA-Z0-9]{24})") &&
        !onCheckReadAllReportsPermissions(role.permissions)
      ) {
        // CHECK PERMISSIONS
        isAllowedRoute = false;
      }
      return {
        component: null,
        restProps: null,
        isAllowedRoute:
          config.companyDomain.length > 0 && isAuthenticated && isAllowedRoute,
        redirectTo,
      };
    },
    mapDispatchToProps
  )(ProtectedRoute)
);

export default ReportsRoute;
