// @flow
// Import Features
import * as React from "react";
// Import Components
import { Message, Transition } from "semantic-ui-react";
// Import Types
type Props = {
  onDismissMessage: () => void
};
class FilesRequiredWarningPresentational extends React.Component<Props> {
  render() {
    const styles = {
      zIndex: "9999",
      position: "absolute",
      bottom: "10px",
      right: "5px"
    };
    const { onDismissMessage } = this.props;
    return (
      <Transition animation="shake" duration={500}>
        <Message
          id="files-required"
          warning
          className="ml100 mr20"
          style={styles}
          onDismiss={onDismissMessage}
        >
          <Message.Header>
            You have not uploaded all the required documents. Please check.
          </Message.Header>
        </Message>
      </Transition>
    );
  }
}

export default FilesRequiredWarningPresentational;
