import { put, select, call, all, takeLatest } from "redux-saga/effects";
import AccessControlApi from "../api/AccessControl";
import { Auth, AccessControl } from "../actions/";
import { parseName, parseApiName } from "../../utils/";
// Type
import type { Action } from "deox";
import type { tAPI } from "../api/API";
//Sagas for AccessControl
// ROLES
const parseAlias = (alias: string) =>
  alias
    .replace("my:", "their ")
    .split(":")
    .reverse()
    .map((actionResource, index) => {
      switch (index) {
        case 0:
          if (actionResource.indexOf("CSV") !== -1) {
            return (
              actionResource.charAt(0).toUpperCase() +
              actionResource.slice(1).toLowerCase().replace("csv", " CSV")
            );
          }
          if (actionResource.indexOf("submitTestimonial") !== -1) {
            return actionResource.replace(
              "submitTestimonial",
              "SUBMIT Recommendation"
            );
          }
          if (actionResource.indexOf("submitTestimonial") !== -1) {
            return actionResource.replace(
              "submitTestimonial",
              "SUBMIT Recommendation"
            );
          }
          if (actionResource.indexOf("activateDeactivate") !== -1) {
            return actionResource.replace(
              "activateDeactivate",
              "ACTIVATE/DISABLE"
            );
          }
          return actionResource
            .split(/(?=[A-Z])/)
            .join(" ")
            .toUpperCase();
        default:
          let newActionResource = actionResource;
          if (newActionResource.indexOf("my#") !== -1) {
            newActionResource = actionResource.replace("my#", "his/her");
          }
          if (newActionResource.indexOf("my") !== -1) {
            newActionResource = actionResource.replace("my", "");
          }
          if (newActionResource.indexOf("#") !== -1) {
            newActionResource = newActionResource.replace("#", " ");
          }
          return parseApiName(parseName(newActionResource));
      }
    })
    .join(" ");
const checkDisabled = (
  alias: string,
  apiName:
    | "Alumnis"
    | "CompanyAdministrators"
    | "Coordinators"
    | "Faculties"
    | "InstitutionAdministrators"
    | "Preceptors"
    | "Students"
    | "SuperCompanyAdministrators"
) => {
  switch (alias) {
    case "Terms:read":
    case "Periods:read":
    case "my:Students#ProfilesBasic:read":
    case "my:Preceptors#ProfilesBasic:read":
    case "my#Profiles:read":
    case "my#FileCategories:read":
    case "Programs:read":
    case "Cohorts:read":
    case "my#Enrollments:read":
    case "my#CustomFields:read":
    case "Roles:read":
    case "TenantSettings:read":
    case "my#Portfolios:read":
    case "my#Portfolios:update":
    case "my#UserSettings:read":
    case "my#UserSettings:update":
      return true;
    case "my:Preceptors#Profiles:read":
    case "my:Students#Portfolios:submitTestimonial":
      if (apiName === "InstitutionAdministrators") {
        return true;
      }
      return false;
    case "my#CaseLogs:review":
    case "my:Students#Profiles:read":
      if (apiName === "Preceptors") {
        return true;
      }
      return false;
    default:
      return false;
  }
};
const parsePermissions = (
  permissions: { [k: string]: any },
  apiName:
    | "Alumnis"
    | "CompanyAdministrators"
    | "Coordinators"
    | "Faculties"
    | "InstitutionAdministrators"
    | "Preceptors"
    | "Students"
    | "SuperCompanyAdministrators"
) => {
  const newPermissions: { [k: string]: any } = {};
  for (let resource in permissions) {
    newPermissions[resource] = {
      resource,
      resourceName: parseApiName(parseName(resource)),
      actions: Object.keys(permissions[resource]).map((alias) => ({
        alias,
        label: parseAlias(alias),
        value: permissions[resource][alias]["isEnabled"],
        disabled: checkDisabled(alias, apiName),
      })),
      apiValue: {
        ...permissions[resource],
      },
    };
  }
  return newPermissions;
};

export function* doAccessControlReturnRoles(
  action: Action<
    string,
    {
      roles: any[];
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
): Generator<any, any, any> {
  const roles = yield call(doAccessControlFetchRoles, action);
  yield put(AccessControl.initAccesControl());
  return roles;
}
function* doAccessControlFetchRoles(
  action: Action<
    string,
    {
      roles: any[];
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(AccessControl.accessControlFetchRolesRequest());
  window.logger.log("doAccessControlFetchRoles", action);
  const {
    roles = [],
    callbackSuccess = null,
    callbackError = null,
  } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get all roles for this company
    const { data } = yield call(
      AccessControlApi.GetAccessControlRoles,
      api,
      roles,
      { token }
    );
    const { roles: rolesApi } = data;
    window.logger.warn("Saga Roles Api:", rolesApi);
    const rolesState = Object.keys(rolesApi).map((roleApi) => ({
      ...rolesApi[roleApi],
      apiName: rolesApi[roleApi].name,
      type: rolesApi[roleApi].name.replace(/\s/g, "").toLowerCase(),
      name: parseApiName(
        rolesApi[roleApi].label || parseName(rolesApi[roleApi].name)
      ),
      isActive: true,
      permissions: parsePermissions(
        rolesApi[roleApi].permissions,
        rolesApi[roleApi].name
      ),
    }));
    yield put(
      AccessControl.accessControlFetchRolesSuccess({
        roles: rolesState,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(rolesState);
    }
    return rolesState;
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doAccessControlFetchRoles", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(AccessControl.accessControlFetchRolesFailure());
    if (callbackError) {
      callbackError(error);
    }
    return [];
  }
}

function* doAccessControlCreateRole(
  action: Action<
    string,
    {
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(AccessControl.accessControlCreateRoleRequest());
  window.logger.log("doAccessControlCreateRole", action);
  const { body, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to create a new role
    const { data } = yield call(AccessControlApi.CreateRole, api, body, {
      token,
    });
    const { role } = data;
    yield put(AccessControl.accessControlCreateRoleSuccess({ role }));
    if (callbackSuccess) {
      callbackSuccess(role);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doAccessControlCreateRoles",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(AccessControl.accessControlCreateRoleFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doAccessControlUpdateRoleResource(
  action: Action<
    string,
    {
      role: { [k: string]: any };
      body: { [k: string]: any };
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(AccessControl.accessControlUpdateRoleResourceRequest());
  window.logger.log("doAccessControlUpdateRoleResource", action);
  const {
    role,
    body,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to update a resource from some role
    const { data } = yield call(
      AccessControlApi.EditRole,
      api,
      role.apiName,
      body,
      { token }
    );
    const { role: roleApi } = data;
    let newResourceUpdated: { [k: string]: any } = {
      apiValue: {
        ...role.permissions[body.resource].apiValue,
        [body.action]: {
          ...roleApi.permissions[body.resource][body.action],
        },
      },
    };
    newResourceUpdated = {
      ...newResourceUpdated,
      actions: Object.keys(newResourceUpdated.apiValue).map((alias) => ({
        alias,
        label: parseAlias(alias),
        value: newResourceUpdated.apiValue[alias]["isEnabled"],
        disabled: checkDisabled(alias, role.apiName),
      })),
    };
    yield put(
      AccessControl.accessControlUpdateRoleResourceSuccess({
        role: {
          ...role,
          permissions: {
            ...role.permissions,
            [body.resource]: {
              ...role.permissions[body.resource],
              ...newResourceUpdated,
            },
          },
        },
      })
    );
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doAccessControlUpdateRoleResource",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(AccessControl.accessControlUpdateRoleResourceFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doAccessControlDeleteRole(
  action: Action<
    string,
    {
      role: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(AccessControl.accessControlDeleteRoleRequest());
  window.logger.log("doAccessControlDeleteRole", action);
  const { role, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to delete a role
    yield call(AccessControlApi.DeleteRole, api, role, { token });
    yield put(AccessControl.accessControlDeleteRoleSuccess({ role }));
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doAccessControlDeleteRole", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(AccessControl.accessControlDeleteRoleFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

// DYNAMIC RULES
function* doAccessControlFetchDynamicRules(
  action: Action<
    string,
    {
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(AccessControl.accessControlFetchDynamicRulesRequest());
  window.logger.log("doAccessControlFetchDynamicRules", action);
  const { callbackSuccess = null, callbackError = null } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get all roles for this company
    const {
      data: { dynamicRules },
    } = yield call(AccessControlApi.GetAccessControlDynamicRules, api, {
      token,
    });
    yield put(
      AccessControl.accessControlFetchDynamicRulesSuccess({ dynamicRules })
    );
    if (callbackSuccess) {
      callbackSuccess(dynamicRules);
    }
    return;
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doAccessControlFetchDynamicRules",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(AccessControl.accessControlFetchDynamicRulesFailure());
    if (callbackError) {
      callbackError(error);
    }
    return;
  }
}

function* doAccessControlCreateDynamicRule(
  action: Action<
    string,
    {
      user: object;
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(AccessControl.accessControlCreateDynamicRuleRequest());
  window.logger.log("doAccessControlCreateDynamicRule", action);
  const {
    user,
    body,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to create a new dynamic rule
    const { data } = yield call(AccessControlApi.CreateDynamicRule, api, body, {
      token,
    });
    const { dynamicRule } = data;
    yield put(
      AccessControl.accessControlCreateDynamicRuleSuccess({
        dynamicRule: { ...dynamicRule, user },
      })
    );
    if (callbackSuccess) {
      callbackSuccess({ ...dynamicRule, user });
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doAccessControlCreateDynamicRule",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(AccessControl.accessControlCreateDynamicRuleFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doAccessControlEditDynamicRule(
  action: Action<
    string,
    {
      dynamicRuleId: string;
      user: object;
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(AccessControl.accessControlEditDynamicRuleRequest());
  window.logger.log("doAccessControlEditDynamicRule", action);
  const {
    dynamicRuleId,
    user,
    body,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to edit a dynamic rule for that specific dynamicRuleId
    const { data } = yield call(
      AccessControlApi.EditDynamicRule,
      api,
      dynamicRuleId,
      body,
      { token }
    );
    const { dynamicRule } = data;
    yield put(
      AccessControl.accessControlEditDynamicRuleSuccess({
        dynamicRule: { ...dynamicRule, user },
      })
    );
    if (callbackSuccess) {
      callbackSuccess({ ...dynamicRule, user });
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doAccessControlEditDynamicRule",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(AccessControl.accessControlEditDynamicRuleFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doAccessControlDeleteDynamicRule(
  action: Action<
    string,
    {
      dynamicRuleId: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(AccessControl.accessControlDeleteDynamicRuleRequest());
  window.logger.log("doAccessControlDeleteDynamicRule", action);
  const {
    dynamicRuleId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to delete a dynamic rule for that specific dynamicRuleId
    yield call(AccessControlApi.DeleteDynamicRule, api, dynamicRuleId, {
      token,
    });
    yield put(
      AccessControl.accessControlDeleteDynamicRuleSuccess({ dynamicRuleId })
    );
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doAccessControlDeleteDynamicRule",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(AccessControl.accessControlDeleteDynamicRuleFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

export default function* accessControlSagas(): Generator<any, any, any> {
  yield all([
    // ROLES
    takeLatest(
      AccessControl.ACCESS_CONTROL_FETCH_ROLES,
      doAccessControlFetchRoles
    ),
    takeLatest(
      AccessControl.ACCESS_CONTROL_CREATE_ROLE,
      doAccessControlCreateRole
    ),
    takeLatest(
      AccessControl.ACCESS_CONTROL_UPDATE_ROLE_RESOURCE,
      doAccessControlUpdateRoleResource
    ),
    takeLatest(
      AccessControl.ACCESS_CONTROL_DELETE_ROLE,
      doAccessControlDeleteRole
    ),
    // DYNAMIC RULES
    takeLatest(
      AccessControl.ACCESS_CONTROL_FETCH_DYNAMIC_RULES,
      doAccessControlFetchDynamicRules
    ),
    takeLatest(
      AccessControl.ACCESS_CONTROL_CREATE_DYNAMIC_RULE,
      doAccessControlCreateDynamicRule
    ),
    takeLatest(
      AccessControl.ACCESS_CONTROL_EDIT_DYNAMIC_RULE,
      doAccessControlEditDynamicRule
    ),
    takeLatest(
      AccessControl.ACCESS_CONTROL_DELETE_DYNAMIC_RULE,
      doAccessControlDeleteDynamicRule
    ),
  ]);
}
