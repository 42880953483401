import { put, select, call, all, takeLatest } from "redux-saga/effects";
import sortBy from "lodash/sortBy";
import ProgramsApi from "../api/Programs";
import { Auth, Programs } from "../actions/";
// Type
import type { Action } from "deox";
import type { tAPI } from "../api/API";
//Sagas for Programs
function* doProgramsFetchPrograms(
  action: Action<
    string,
    {
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Programs.programsFetchProgramsRequest());
  window.logger.log("doProgramsFetchPrograms", action);
  const { callbackSuccess = null, callbackError = null } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get all programs for this company
    const { data } = yield call(ProgramsApi.GetPrograms, api, { token });
    const { programs } = data;
    const programsSortByName = sortBy(programs, ["name"]).reverse();
    yield put(
      Programs.programsFetchProgramsSuccess({ programs: programsSortByName })
    );
    if (callbackSuccess) {
      callbackSuccess(programsSortByName);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doProgramsFetchPrograms", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Programs.programsFetchProgramsFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}

function* doProgramsCreate(
  action: Action<
    string,
    {
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Programs.programsCreateRequest());
  window.logger.log("doProgramsCreate", action);
  const { body, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to create a new program
    const { data } = yield call(ProgramsApi.CreateProgram, api, body, {
      token,
    });
    const { program } = data;
    yield put(Programs.programsCreateSuccess({ program }));
    if (callbackSuccess) {
      callbackSuccess(program);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doProgramsCreate", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Programs.programsCreateFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doProgramsEditProgram(
  action: Action<
    string,
    {
      programId: string;
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Programs.programsEditProgramRequest());
  window.logger.log("doProgramsEditProgram", action);
  const {
    programId,
    body,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to update a program for that specific programId
    const { data } = yield call(ProgramsApi.EditProgram, api, programId, body, {
      token,
    });
    const { program } = data;
    yield put(Programs.programsEditProgramSuccess({ program }));
    if (callbackSuccess) {
      callbackSuccess(program);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doProgramsEditProgram", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Programs.programsEditProgramFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doProgramsDelete(
  action: Action<
    string,
    {
      programId: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any, details: any) => void;
    }
  >
) {
  yield put(Programs.programsDeleteRequest());
  window.logger.log("doProgramsDelete", action);
  const {
    programId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to delete a program for that specific programId
    yield call(ProgramsApi.DeleteProgram, api, programId, { token });
    yield put(Programs.programsDeleteSuccess({ programId }));
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doProgramsDelete", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Programs.programsDeleteFailure());
    const { message, details } = errors || "";
    if (callbackError) {
      callbackError(message, details);
    }
  }
}

export default function* programsSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(Programs.PROGRAMS_FETCH_PROGRAMS, doProgramsFetchPrograms),
    takeLatest(Programs.PROGRAMS_CREATE, doProgramsCreate),
    takeLatest(Programs.PROGRAMS_EDIT_PROGRAM, doProgramsEditProgram),
    takeLatest(Programs.PROGRAMS_DELETE, doProgramsDelete),
  ]);
}
