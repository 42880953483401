// Features
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { reduxForm, SubmissionError } from "redux-form";
import { FeedbackForm as FeedbackFormActions } from "../../store/actions";
// Components
import FeedbackFormView from "./FeedbackForm.view";
// Styles
import "./FeedbackForm.scss";
// Types
import type { FormErrors, FormSubmitHandler } from "redux-form";
import type {
  FeedbackFormData,
  FeedbackFormContainerProps,
  FeedbackFormViewProps,
} from "./_FeedbackForm.types";
import type { RootState } from "../../store/reducers";
// Feedback Form Container:
const { useEffect, useState } = React;
//
const FeedbackFormContainer: React.FC<FeedbackFormContainerProps> = (props) => {
  const dispatch = useDispatch();
  // Store
  const appVersion: FeedbackFormViewProps["appVersion"] = useSelector(
    ({ config: { version } }: RootState) => version
  );
  const openCloseFeedbackForm: FeedbackFormViewProps["openCloseFeedbackForm"] =
    useSelector(
      ({ feedbackForm: { openCloseFeedbackForm } }: RootState) =>
        openCloseFeedbackForm
    );
  // State
  const [confirmSubmit, setConfirmSubmit] =
    useState<FeedbackFormViewProps["confirmSubmit"]>(false);
  // Mounted
  useEffect(() => {
    const metaTagVersion = document.querySelector("meta[version]");
    if (metaTagVersion?.setAttribute) {
      metaTagVersion.setAttribute("version", appVersion);
    }
  }, [appVersion]);
  // SEND Feedback Form
  const _onSendFeedbackFormRequest = ({
    feedbackFormPriority,
    feedbackFormDescription,
    feedbackFormFile,
  }: FeedbackFormData) => {
    return new Promise((resolve, reject) => {
      let body = undefined,
        isPostFile = false;
      if (
        feedbackFormFile &&
        feedbackFormFile !== "errorPic" &&
        typeof feedbackFormFile !== "string"
      ) {
        body = new FormData();
        body.append("attachment", feedbackFormFile, feedbackFormFile.name);
        body.append("description", feedbackFormDescription);
        if (feedbackFormPriority) {
          body.append("priority", feedbackFormPriority);
        }
        isPostFile = true;
      } else {
        body = {
          description: feedbackFormDescription,
        };
        if (feedbackFormPriority) {
          body = {
            ...body,
            priority: feedbackFormPriority,
          };
        }
      }
      const formValues = {
        body,
        isPostFile,
      };
      const callbacks = {
        callbackError: (errorFeedbackForm: string) => reject(errorFeedbackForm),
        callbackSuccess: () => resolve("SendFeedbackForm - success"),
      };
      const payload = Object.assign({}, formValues, callbacks);
      dispatch(FeedbackFormActions.feedbackFormSend(payload));
    });
  };
  const onSubmit: FormSubmitHandler<FeedbackFormData> = async (formData) => {
    const { feedbackFormFile } = formData;
    if (!confirmSubmit && feedbackFormFile === "errorPic") {
      setConfirmSubmit(true);
      throw new SubmissionError({
        feedbackFormTitle: "Form Failed",
        _error: "errorPic",
      });
    }
    if (confirmSubmit) {
      if (feedbackFormFile === "errorPic") {
        onCleanErrorImage();
      }
      setConfirmSubmit(false);
    }
    await _onSendFeedbackFormRequest(formData)
      .then((successMsg) =>
        window.logger.log("onSendFeedbackForm then ", successMsg)
      )
      .catch((errorFeedbackForm: any) => {
        window.logger.log("onSendFeedbackForm - error", errorFeedbackForm);
        throw new SubmissionError({
          feedbackFormTitle: "Form Failed",
          _error: errorFeedbackForm,
        });
      });
  };
  //
  // METHODS
  const onCleanErrorImage = () => {
    const { change, clearAsyncError, clearSubmit } = props;
    clearSubmit();
    clearAsyncError("feedbackFormFile");
    change("feedbackFormFile", undefined);
  };
  const onCancel = () => {
    if (confirmSubmit) {
      onCleanErrorImage();
      return setConfirmSubmit(false);
    }
    const { reset } = props;
    reset();
    dispatch(FeedbackFormActions.feedbackFormOpenClose());
  };
  const onChangePriority: FeedbackFormViewProps["onChangePriority"] =
    (onChange) =>
    (ev, { value }) =>
      onChange(value);
  const render = () => {
    const { error, handleSubmit, submitting, submitSucceeded, valid } = props;
    return (
      <FeedbackFormView
        appVersion={appVersion}
        confirmSubmit={confirmSubmit}
        errorApi={error}
        onCancel={onCancel}
        onChangePriority={onChangePriority}
        onSubmit={handleSubmit(onSubmit)}
        openCloseFeedbackForm={openCloseFeedbackForm}
        successApi={submitSucceeded}
        submitting={submitting}
        valid={valid}
      />
    );
  };
  return render();
};
// Decorate Form Component
const FeedbackForm = reduxForm<FeedbackFormData>({
  form: "feedback-form",
  validate: (values) => {
    const errors: FormErrors<FeedbackFormData> = {};
    if (!values.feedbackFormPriority) {
      errors.feedbackFormPriority = "Priority Required";
    }
    if (!values.feedbackFormDescription) {
      errors.feedbackFormDescription = "Description Required";
    }
    return errors;
  },
})(FeedbackFormContainer);
export default FeedbackForm;
