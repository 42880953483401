import {
  put,
  select,
  call,
  all,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import ReportsApi from "../api/Reports";
import { Auth, Reports } from "../actions/";
import {
  onCreateOptions,
  onCreateFilter,
} from "../../components/reports/queries/query-params/models/index";
import { optionsPropsList } from "../../components/reports/queries/query-params/query-utils/queryUtils";
import { downloadFile } from "../../utils/";
// Type
import type { Action } from "deox";
import type { Config, tAPI } from "../api/API";
import type { Models } from "../../types/reports.types";
//Sagas for Reports and Queries
// QUERIES
function* doReportsFetchQueries(
  action: Action<
    string,
    {
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Reports.reportsFetchQueriesRequest());
  window.logger.log("doReportsFetchQueries", action);
  const { callbackSuccess = null, callbackError = null } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get all queries for this company
    const {
      data: { queries },
    } = yield call(ReportsApi.GetReportsQueries, api, { token });
    yield put(
      Reports.reportsFetchQueriesSuccess({
        queries,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(queries);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doReportsFetchQueries", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Reports.reportsFetchQueriesFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}

function* doReportsCreateQuery(
  action: Action<
    string,
    {
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Reports.reportsCreateQueryRequest());
  window.logger.log("doReportsCreateQuery", action);
  const { body, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    const createdBy: { [k: string]: any } = yield select(
      ({ auth: { user } }) => ({
        _id: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role,
      })
    );
    // call the api to create a new query to use getting reports
    const {
      data: { query },
    } = yield call(ReportsApi.CreateReportsQuery, api, body, { token });
    yield put(
      Reports.reportsCreateQuerySuccess({
        query: { ...query, createdBy },
      })
    );
    if (callbackSuccess) {
      callbackSuccess({ ...query, createdBy });
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doReportsCreateQuery", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Reports.reportsCreateQueryFailure());
    const { message, details } = errors || "";
    if (callbackError) {
      let errorMsg = message;
      if (details && details.length > 0) {
        errorMsg = `${errorMsg}: ${details[0].msg}`;
      }
      callbackError(errorMsg);
    }
  }
}

export const onCreateQueryParams = (query: {
  [k: string]: any;
}): {
  modelSelected: Models | undefined | null;
  match: {
    [k: string /*Models*/]: { [k: string]: any }[];
  };
  projection: {
    [k: string /*Models*/]: { [k: string]: any }[];
  };
  hasDynamicFilter: boolean;
} => {
  let queryParams = {
    modelSelected: null,
    match: {
      Users: [],
      Enrollments: [],
      Institutions: [],
      Rotations: [],
      CaseLogs: [],
      Evaluations: [],
    },
    projection: {
      Users: [],
      Enrollments: [],
      Institutions: [],
      Rotations: [],
      CaseLogs: [],
      Evaluations: [],
    },
    hasDynamicFilter: false,
  };
  if (query.query) {
    let queryModelSelected = query.query.model;
    if (queryModelSelected === "EvaluationAnswers") {
      queryModelSelected = "Evaluations";
    }
    const match = query.query.match;
    let hasDynamicFilter = false;
    queryParams = {
      ...queryParams,
      modelSelected: queryModelSelected,
      match: {
        ...queryParams.match,
        // eslint-disable-next-line
        [queryModelSelected]: match.map((item: any) => {
          let itemVal,
            options = null,
            fieldNameSelected = item.fieldName;
          switch (item.type) {
            case "boolean":
              itemVal = item.val;
              break;
            case "date":
              itemVal = { from: item.from, to: item.to };
              break;
            case "number":
              options = {
                selected: null,
              };
              itemVal = item.conditions;
              const { conditions } = item;
              if (conditions && conditions._gt && conditions._lt) {
                options = {
                  selected: "_gt_lt",
                };
              } else if (conditions && conditions._gt) {
                options = {
                  selected: "_gt",
                };
              } else if (conditions && conditions._lt) {
                options = {
                  selected: "_lt",
                };
              } else if (conditions && conditions._eq) {
                options = {
                  selected: "_eq",
                };
              }
              break;
            default:
              itemVal = item.val;
              if (
                optionsPropsList &&
                // @ts-ignore
                optionsPropsList[queryModelSelected] &&
                // @ts-ignore
                optionsPropsList[queryModelSelected][fieldNameSelected]
              ) {
                options = onCreateOptions(
                  // @ts-ignore
                  optionsPropsList[queryModelSelected][fieldNameSelected],
                  false
                );
              } else {
                if (!itemVal) {
                  itemVal = [];
                }
                options = onCreateOptions(
                  null,
                  true,
                  itemVal.map((itemValue: any) => ({
                    key: itemValue,
                    value: itemValue,
                    text: itemValue,
                  }))
                );
              }
          }
          if (!hasDynamicFilter && item.isDynamic) {
            hasDynamicFilter = true;
          }
          return {
            match: onCreateFilter(
              item.type,
              fieldNameSelected,
              itemVal,
              item.isDynamic,
              item.isCustomField
            ),
            options,
          };
        }),
      },
      hasDynamicFilter,
    };
    const projection = query.query.projection;
    queryParams = {
      ...queryParams,
      projection: {
        ...queryParams.projection,
        [queryModelSelected]: projection,
      },
    };
  }
  return queryParams;
};

function* doReportsFetchQuery(
  action: Action<
    string,
    {
      queryId: string;
      callbackSuccess?: (data: any, metadata: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Reports.reportsFetchQueryRequest());
  window.logger.log("doReportsFetchQuery", action);
  const {
    queryId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get a query for that specific queryId
    const {
      data: { query },
    } = yield call(ReportsApi.GetReportsQuery, api, queryId, { token });
    const queryParams = onCreateQueryParams(query);
    yield put(
      Reports.reportsFetchQuerySuccess({
        query,
        queryParams,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(query, queryParams);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doReportsFetchQuery", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Reports.reportsFetchQueryFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}

function* doReportsEditQuery(
  action: Action<
    string,
    {
      queryId: string;
      body: object;
      callbackSuccess?: (data: any, metadata: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Reports.reportsEditQueryRequest());
  window.logger.log("doReportsEditQuery", action);
  const {
    queryId,
    body,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to update a query for that specific queryId
    const {
      data: { query },
    } = yield call(ReportsApi.EditReportsQuery, api, queryId, body, { token });
    const queryParams = onCreateQueryParams(query);
    yield put(
      Reports.reportsEditQuerySuccess({
        query,
        queryParams,
      })
    );
    if (callbackSuccess) {
      yield put(Reports.reportsFetchQueries());
      callbackSuccess(query, queryParams);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doReportsEditQuery", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Reports.reportsEditQueryFailure());
    const { message, details } = errors || "";
    if (callbackError) {
      let errorMsg = message;
      if (details && details.length > 0) {
        errorMsg = `${errorMsg}: ${details[0].msg}`;
      }
      callbackError(errorMsg);
    }
  }
}

function* doReportsDeleteQuery(
  action: Action<
    string,
    {
      queryId: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Reports.reportsDeleteQueryRequest());
  window.logger.log("doReportsDeleteQuery", action);
  const {
    queryId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to delete every query from those specific array queryId
    yield call(ReportsApi.DeleteReportsQuery, api, queryId, { token });
    yield put(
      Reports.reportsDeleteQuerySuccess({
        queryId,
      })
    );
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doReportsDeleteQuery", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Reports.reportsDeleteQueryFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doReportsRunQuery(
  action: Action<
    string,
    {
      queryId: string;
      query: object;
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Reports.reportsRunQueryRequest());
  window.logger.log("doReportsRunQuery", action);
  const {
    queryId,
    query,
    body,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    const createdBy: { [k: string]: any } = yield select(
      ({ auth: { user } }) => ({
        _id: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role,
      })
    );
    // call the api to save this report to get from some historic datas
    const {
      data: { report },
    } = yield call(ReportsApi.RunReportsQuery, api, queryId, body, { token });
    yield put(
      Reports.reportsRunQuerySuccess({
        report: { ...report, query, createdBy },
      })
    );
    if (callbackSuccess) {
      callbackSuccess({ ...report, query, createdBy });
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doReportsRunQuery", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Reports.reportsRunQueryFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}
// END QUERIES />

// REPORTS
function* doReportsFetchReports(
  action: Action<
    string,
    {
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Reports.reportsFetchReportsRequest());
  window.logger.log("doReportsFetchReports", action);
  const { callbackSuccess = null, callbackError = null } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get all reports
    const {
      data: { reports },
    } = yield call(ReportsApi.GetReports, api, { token });
    yield put(
      Reports.reportsFetchReportsSuccess({
        reports,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(reports);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doReportsFetchReports", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Reports.reportsFetchReportsFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}
// END REPORTS />

// REPORTS QUERIES
function* doReportsQueriesFetchReports(
  action: Action<
    string,
    {
      queryId: string;
      callbackSuccess?: (data: any, metadata: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Reports.reportsQueriesFetchReportsRequest());
  window.logger.log("doReportsQueriesFetchReports", action);
  const {
    queryId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get all reports from some specific query set up before
    const {
      data: { query },
    } = yield call(ReportsApi.GetReportsQuery, api, queryId, { token });
    const queryParams = onCreateQueryParams(query);
    yield put(
      Reports.reportsFetchQuerySuccess({
        query,
        queryParams,
      })
    );
    const {
      data: { reports },
    } = yield call(ReportsApi.GetReportsQueryResults, api, queryId, { token });
    yield put(
      Reports.reportsQueriesFetchReportsSuccess({
        reports,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(reports, queryParams);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doReportsQueriesFetchReports",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Reports.reportsFetchQueryFailure());
    yield put(Reports.reportsQueriesFetchReportsFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}

function* doReportsQueriesFetchReport(
  action: Action<
    string,
    {
      queryId: string;
      reportId: string;
      callbackSuccess?: (data: any, metadata: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Reports.reportsQueriesFetchReportRequest());
  window.logger.log("doReportsQueriesFetchReport", action);
  const {
    queryId,
    reportId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get a report for that specific reportId
    const {
      data: { report },
    } = yield call(ReportsApi.GetReportsQueryResult, api, queryId, reportId, {
      token,
    });
    const queryParams = onCreateQueryParams(report.query);
    yield put(
      Reports.reportsQueriesFetchReportSuccess({
        report,
        query: report.query,
        queryParams,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(report, queryParams);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doReportsQueriesFetchReport",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Reports.reportsQueriesFetchReportFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}

function* doReportsQueriesEditReport(
  action: Action<
    string,
    {
      queryId: string;
      reportId: string;
      updateResults: boolean;
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Reports.reportsQueriesEditReportRequest());
  window.logger.log("doReportsQueriesEditReport", action);
  const {
    queryId,
    reportId,
    updateResults = false,
    body,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to update a report for that specific queryId
    yield call(
      ReportsApi.EditReportsQueryResult,
      api,
      queryId,
      reportId,
      updateResults,
      body,
      { token }
    );
    const {
      data: { report },
    } = yield call(ReportsApi.GetReportsQueryResult, api, queryId, reportId, {
      token,
    });
    yield put(
      Reports.reportsQueriesEditReportSuccess({
        report,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(report);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doReportsQueriesEditReport",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Reports.reportsQueriesEditReportFailure());
    const { message, details } = errors || "";
    if (callbackError) {
      let errorMsg = message;
      if (details && details.length > 0) {
        errorMsg = `${errorMsg}: ${details[0].msg}`;
      }
      callbackError(errorMsg);
    }
  }
}

function* doReportsQueriesDeleteReport(
  action: Action<
    string,
    {
      queryId: string;
      reportId: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Reports.reportsQueriesDeleteReportRequest());
  window.logger.log("doReportsQueriesDeleteReport", action);
  const {
    queryId,
    reportId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to delete a report from specific queryId
    yield call(ReportsApi.DeleteReportsQueryReport, api, queryId, reportId, {
      token,
    });
    yield put(
      Reports.reportsQueriesDeleteReportSuccess({
        reportId,
      })
    );
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doReportsQueriesDeleteReport",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Reports.reportsQueriesDeleteReportFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doReportsQueriesTransformReport(
  action: Action<
    string,
    {
      queryId: string;
      reportId: string;
      body: object;
      vApi: Config["vApi"];
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Reports.reportsQueriesTransformReportRequest());
  window.logger.log("doReportsQueriesTransformReport", action);
  const {
    queryId,
    reportId,
    body,
    vApi = "v1",
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to update a report for that specific queryId
    const {
      data: { template, report },
    } = yield call(
      ReportsApi.TransformReportsQueryResult,
      api,
      queryId,
      reportId,
      body,
      { token, vApi }
    );
    yield put(Reports.reportsQueriesTransformReportSuccess());
    if (callbackSuccess) {
      callbackSuccess(vApi === "v1" ? report : template);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doReportsQueriesTransformReport",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Reports.reportsQueriesTransformReportFailure());
    const { message, details } = errors || "";
    if (callbackError) {
      let errorMsg = message;
      if (details && details.length > 0) {
        errorMsg = `${errorMsg}: ${details[0].msg}`;
      }
      callbackError(errorMsg);
    }
  }
}

function* doReportsQueriesGenerateCSVReport(
  action: Action<
    string,
    {
      reportName: string;
      queryId: string;
      reportId: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Reports.reportsQueriesGenerateCSVRequest());
  window.logger.log("doReportsQueriesGenerateCSVReport", action);
  const {
    reportName,
    queryId,
    reportId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to generate a csv from some report for specific queryId
    const {
      data: { blob },
    } = yield call(ReportsApi.GenerateCSVReport, api, queryId, reportId, {
      token,
    });
    downloadFile(`${reportName}.csv`, blob);
    yield put(Reports.reportsQueriesGenerateCSVSuccess());
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doReportsQueriesGenerateCSVReport",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Reports.reportsQueriesGenerateCSVFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doReportsQueriesTransformReportGenerateCSVReport(
  action: Action<
    string,
    {
      queryId: string;
      reportId: string;
      body: object;
      reportName: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Reports.reportsQueriesTransformReportGenerateCSVRequest());
  window.logger.log("doReportsQueriesTransformReportGenerateCSVReport", action);
  const {
    queryId,
    reportId,
    body,
    reportName,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // 1st call the api to update the cache report in transformReport
    yield call(
      ReportsApi.TransformReportsQueryResult,
      api,
      queryId,
      reportId,
      body,
      {
        token,
        vApi: "v2",
      }
    );
    // 2nd call the api to generate a csv from some transformReport cached
    const {
      data: { blob },
    } = yield call(
      ReportsApi.GenerateCSVTransformReportsQueryResult,
      api,
      queryId,
      reportId,
      {
        token,
        vApi: "v2",
      }
    );
    downloadFile(`${reportName}.csv`, blob);
    yield put(Reports.reportsQueriesTransformReportGenerateCSVSuccess());
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doReportsQueriesTransformReportGenerateCSVReport",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Reports.reportsQueriesTransformReportGenerateCSVFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}
// END REPORTS />

export default function* reportsSagas(): Generator<any, any, any> {
  yield all([
    // Queries
    takeLatest(Reports.REPORTS_FETCH_QUERIES, doReportsFetchQueries),
    takeLatest(Reports.REPORTS_CREATE_QUERY, doReportsCreateQuery),
    takeLatest(Reports.REPORTS_FETCH_QUERY, doReportsFetchQuery),
    takeLatest(Reports.REPORTS_EDIT_QUERY, doReportsEditQuery),
    takeEvery(Reports.REPORTS_DELETE_QUERY, doReportsDeleteQuery),
    takeEvery(Reports.REPORTS_RUN_QUERY, doReportsRunQuery),
    // Reports
    takeLatest(Reports.REPORTS_FETCH_REPORTS, doReportsFetchReports),
    // Reports Queries
    takeLatest(
      Reports.REPORTS_QUERIES_FETCH_REPORTS,
      doReportsQueriesFetchReports
    ),
    takeEvery(
      Reports.REPORTS_QUERIES_FETCH_REPORT,
      doReportsQueriesFetchReport
    ),
    takeEvery(
      Reports.REPORTS_QUERIES_TRANSFORM_REPORT,
      doReportsQueriesTransformReport
    ),
    takeLatest(Reports.REPORTS_QUERIES_EDIT_REPORT, doReportsQueriesEditReport),
    takeLatest(
      Reports.REPORTS_QUERIES_DELETE_REPORT,
      doReportsQueriesDeleteReport
    ),
    takeLatest(
      Reports.REPORTS_QUERIES_GENERATE_CSV,
      doReportsQueriesGenerateCSVReport
    ),
    takeLatest(
      Reports.REPORTS_QUERIES_TRANSFORM_REPORT_GENERATE_CSV,
      doReportsQueriesTransformReportGenerateCSVReport
    ),
  ]);
}
