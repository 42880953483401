// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { onCheckEvaluationsPermissions } from "../evaluations/EvaluationsPermissions";
import {
  onCheckEvaluationSchedulersPermissions,
  onCheckCreateEvaluationSchedulersPermissions,
} from "./EvaluationSchedulersPermissions";
import { onCheckEvaluationTemplatesPermissions } from "../evaluations-templates/EvaluationTemplatesPermissions";
import ProtectedRoute from "../shared/ProtectedRoute";

const EvaluationsSchedulersRoute: React.ComponentType<any> = withRouter(
  connect(({ config, auth: { isAuthenticated, role } }) => {
    let isAllowedRoute =
      isAuthenticated &&
      onCheckCreateEvaluationSchedulersPermissions(role.permissions);
    let isAllowedEvaluationsRoute =
      isAuthenticated &&
      (onCheckEvaluationsPermissions(role.permissions) ||
        onCheckEvaluationSchedulersPermissions(role.permissions) ||
        onCheckEvaluationTemplatesPermissions(role.permissions));
    let redirectTo = "/home";
    if (isAllowedEvaluationsRoute) {
      redirectTo = "/evaluations";
    }
    return {
      component: null,
      restProps: null,
      isAllowedRoute:
        config.companyDomain.length > 0 && isAuthenticated && isAllowedRoute,
      redirectTo,
    };
  })(ProtectedRoute)
);

export default EvaluationsSchedulersRoute;
