import { put, select, call, all, takeLatest } from "redux-saga/effects";
import * as Cookies from "js-cookie";
import UsersApi from "../api/Users";
import { Auth, LocalStoreActions, Users, UsersManager } from "../actions/";
//PERMISSIONS
import { USER_MANAGER_USER_LIMIT } from "../reducers/usersManager";
// Type
import type { Action } from "deox";
import type { tAPI } from "../api/API";
import type { EditUsersActions } from "../../components/user-manager/UserManagerPresentational";
import type { StateAccountSettings } from "../../types/accountSettings.types";
import type {
  SortBy,
  StateUsersManager,
  UsersRole,
} from "../../types/users.types";
//Sagas for users
// API call Get Users
function* doUsersGetUsers(payload: {
  populated?: boolean;
  page?: number;
  limit?: number;
  roles?: string[];
  institutions?: string[];
  searchTxt?: string;
  status?: StateUsersManager["filters"]["status"];
  activeRotation?: boolean;
  programsSelected?: string[];
  cohortsSelected?: string[];
  sortBy?: SortBy;
}): Generator<any, { remainUsers: object; metadata: any }, any> {
  window.logger.log("doUsersGetUsers", payload);
  try {
    const {
        tenantSettings: { SHOW_DEACTIVATED_USERS },
      } = yield select(({ tenantSettings }) => tenantSettings),
      { frontendPersonalization } = yield select(
        ({ accountSettings }) => accountSettings
      );
    let usersManagerSortBy: SortBy = {
        name: "descending",
        enrollment: "",
        institution: "",
      },
      usersManagerSortByName: StateAccountSettings["frontendPersonalization"]["USER_MANAGER_SETTINGS"]["sortByName"] =
        "firstName",
      usersManagerUserStatus: StateUsersManager["filters"]["status"] =
        SHOW_DEACTIVATED_USERS?.values || false
          ? ["active", "inactive"]
          : ["active"];
    if (frontendPersonalization?.USER_MANAGER_SETTINGS) {
      usersManagerSortBy =
        frontendPersonalization["USER_MANAGER_SETTINGS"].sortBy;
      usersManagerSortByName =
        frontendPersonalization["USER_MANAGER_SETTINGS"].sortByName;
      usersManagerUserStatus =
        frontendPersonalization["USER_MANAGER_SETTINGS"].userStatus ||
        usersManagerUserStatus;
    }
    const {
      populated = true,
      page = 1,
      limit = USER_MANAGER_USER_LIMIT,
      roles = [],
      institutions = [],
      searchTxt = "",
      status = usersManagerUserStatus,
      activeRotation = false,
      programsSelected = [],
      cohortsSelected = [],
      sortBy = usersManagerSortBy,
    } = payload || {};
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get users
    const skip: number = (page - 1) * limit;
    const {
      data: {
        metadata,
        users: { ...remainUsers },
      },
    } = yield call(
      UsersApi.GetUsers,
      api,
      populated,
      skip,
      limit,
      roles,
      institutions,
      searchTxt,
      status,
      activeRotation,
      programsSelected,
      cohortsSelected,
      sortBy,
      usersManagerSortByName,
      { token }
    );
    return { remainUsers, metadata };
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doUsersGetUsers", error, errors);
    throw error;
  }
}
//Actions to get all users kpis (without roles)
function* doUsersFetchUsersKpis(
  action: Action<
    string,
    {
      limit: number;
      callbackSuccess?: (data: any, metadata: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Users.usersFetchUsersKpisRequest());
  window.logger.log("doUsersFetchUsersKpis", action);
  const {
    limit = -1,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload || {};
  try {
    const { remainUsers, metadata } = yield doUsersGetUsers({
      populated: false,
      limit,
    });
    yield put(
      Users.usersFetchUsersKpisSuccess({
        users: remainUsers,
        metadata,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(remainUsers, metadata);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doUsersFetchAll", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Users.usersFetchUsersKpisFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}
//Actions to get all users (without roles)
function* doUsersFetchAll(
  action: Action<
    string,
    {
      roles: string[];
      status?: StateUsersManager["filters"]["status"];
      limit: number;
      callbackSuccess?: (data: any, metadata: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Users.usersFetchAllRequest());
  window.logger.log("doUsersFetchAll", action);
  const {
    roles = [],
    status,
    limit = -1,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload || {};
  try {
    const { remainUsers, metadata } = yield doUsersGetUsers({
      populated: false,
      limit,
      roles,
      status,
    });
    yield put(
      Users.usersFetchAllSuccess({
        users: remainUsers,
        metadata,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(remainUsers, metadata);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doUsersFetchAll", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Users.usersFetchAllFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}
//Update Users after action like Change role or Import Data
function* doUsersUpdateStoreUsers(
  action: Action<
    string,
    {
      roles: string[];
      callbackSuccess?: (data: any, metadata: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  window.logger.log("doUsersUpdateStoreUsers", action);
  const { roles } = action.payload || {};
  try {
    const usersManagerFilters: {
      pages: number;
      searchTxt?: string;
      status?: StateUsersManager["filters"]["status"];
      roles: { options: object[]; selected: any };
      rotations: { activeRotation: boolean };
      programs: { selected: string };
      cohorts: { selected: string };
      institutions: { selected: string };
      sortBy: SortBy;
    } = yield select(({ usersManager }) => usersManager.filters);
    const {
      pages: page,
      searchTxt,
      status,
      roles: { options: arrayRoles, selected: roleSelected },
      rotations: { activeRotation },
      programs: { selected: programsSelected },
      cohorts: { selected: cohortsSelected },
      institutions: { selected: institutionsSelected },
      sortBy,
    } = usersManagerFilters;
    if (roleSelected && roles.indexOf(roleSelected.apiName) !== -1) {
      // Same Role
      const formValues = {
        page,
        roles,
        selected: roleSelected,
        searchTxt,
        status,
        activeRotation,
        programsSelected,
        cohortsSelected,
        institutions: institutionsSelected,
        sortBy,
        updateStoreUsers: true,
      };
      const callbacks = {
        callbackError: null,
        callbackSuccess: null,
      };
      const payload = Object.assign({}, formValues, callbacks);
      yield put(Users.usersFetchUsersRoles(payload));
    } else if (roleSelected) {
      // Not Same Role and Role is selected
      const newRoleSelected = arrayRoles.filter(
        ({ apiName }: any) => apiName === roles[0]
      )[0];
      yield put(
        Users.usersFetchUsersRoles({
          roles,
          selected: newRoleSelected,
          updateStoreUsers: true,
          resetFilters: true,
        })
      );
    } else {
      // Not Role is selected
      yield put(Users.usersFetchAll({ limit: USER_MANAGER_USER_LIMIT }));
    }
  } catch (error: any) {
    window.logger.error("ErrorSaga - doUsersUpdateStoreUsers", error);
  }
}
//Actions to get users by filters params
function* doUsersFetchUsersByFilters(
  action: Action<
    string,
    {
      updateStoreUsers?: boolean;
      callbackSuccess?: (data: any, metadata: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  window.logger.log("doUsersFetchUsersByFilters", action);
  const {
    updateStoreUsers = false,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload || {};
  try {
    const usersManagerFilters: {
      pages: number;
      searchTxt?: string;
      status?: StateUsersManager["filters"]["status"];
      roles: { options: object[]; selected: any };
      rotations: { activeRotation: boolean };
      programs: { selected: string };
      cohorts: { selected: string };
      institutions: { selected: string };
      sortBy: SortBy;
    } = yield select(({ usersManager }) => usersManager.filters);
    const {
      pages: page,
      searchTxt,
      status,
      roles: { selected: roleSelected },
      rotations: { activeRotation },
      programs: { selected: programsSelected },
      cohorts: { selected: cohortsSelected },
      institutions: { selected: institutionsSelected },
      sortBy,
    } = usersManagerFilters;
    const formValues = {
      page,
      roles: [roleSelected.apiName],
      selected: roleSelected,
      searchTxt,
      status,
      activeRotation,
      programsSelected,
      cohortsSelected,
      institutions: institutionsSelected,
      sortBy,
      updateStoreUsers,
    };
    const callbacks = {
      callbackError,
      callbackSuccess,
    };
    const payload = Object.assign({}, formValues, callbacks);
    yield put(Users.usersFetchUsersRoles(payload));
  } catch (error: any) {
    window.logger.error("ErrorSaga - doUsersFetchUsersByFilters", error);
  }
}
//Actions to get users by roles
function* doUsersFetchUsersRoles(
  action: Action<
    string,
    {
      selected?: any;
      updateStoreUsers?: boolean;
      populated?: boolean;
      page?: number;
      limit?: number;
      roles?: string[];
      institutions?: string[];
      searchTxt?: string;
      status?: StateUsersManager["filters"]["status"];
      activeRotation?: boolean;
      programsSelected?: string[];
      cohortsSelected?: string[];
      sortBy?: SortBy;
      callbackSuccess?: (data: any, metadata: any) => void;
      callbackError?: (error: any, details: any) => void;
    }
  >
) {
  yield put(Users.usersFetchUsersRolesRequest());
  window.logger.log("doUsersFetchUsersRoles", action);
  const {
    roles = [],
    selected = null,
    updateStoreUsers = false,
    callbackSuccess = null,
    callbackError = null,
    ...payload
  } = action.payload || {};
  try {
    const {
        tenantSettings: { SHOW_DEACTIVATED_USERS },
      } = yield select(({ tenantSettings }) => tenantSettings),
      { frontendPersonalization } = yield select(
        ({ accountSettings }) => accountSettings
      );
    let usersManagerSortBy: SortBy = {
        name: "descending",
        enrollment: "",
        institution: "",
      },
      usersManagerUserStatus: StateUsersManager["filters"]["status"] =
        SHOW_DEACTIVATED_USERS?.values || false
          ? ["active", "inactive"]
          : ["active"];
    if (
      frontendPersonalization &&
      frontendPersonalization["USER_MANAGER_SETTINGS"]
    ) {
      usersManagerSortBy =
        frontendPersonalization["USER_MANAGER_SETTINGS"].sortBy;
      usersManagerUserStatus =
        frontendPersonalization["USER_MANAGER_SETTINGS"].userStatus ||
        usersManagerUserStatus;
    }
    const {
      populated = true,
      institutions = [],
      page = 1,
      limit = USER_MANAGER_USER_LIMIT,
      searchTxt,
      status = usersManagerUserStatus,
      activeRotation = false,
      programsSelected = [],
      cohortsSelected = [],
      sortBy = usersManagerSortBy,
    } = payload || {};
    const { remainUsers, metadata } = yield doUsersGetUsers({
      populated,
      page,
      limit,
      roles,
      institutions,
      searchTxt,
      status,
      activeRotation,
      programsSelected,
      cohortsSelected,
      sortBy,
    });
    let usersObject = remainUsers;
    if (
      usersObject &&
      roles.length &&
      selected &&
      remainUsers &&
      remainUsers[selected.apiName]
    ) {
      usersObject = {};
      for (let i = 0; i < roles.length; i++) {
        usersObject = {
          ...usersObject,
          [roles[i]]: remainUsers[roles[i]],
        };
      }
    }
    yield put(
      Users.usersFetchUsersRolesSuccess({
        users: usersObject,
        metadata,
        roles,
        selected,
        updateStoreUsers,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(remainUsers, metadata);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doUsersFetchUsersRoles", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    const { message, details } = errors || "";
    yield put(
      Users.usersFetchUsersRolesFailure({
        roles,
        selected,
        updateStoreUsers,
        errorAPI: {
          action: "get",
          on: "users",
          errorMsg: message,
          errorsDetails: details,
        },
      })
    );
    if (callbackError) {
      callbackError(message, details);
    }
  }
}

function* doUsersGetAccounts(email: string): Generator<any, object[], any> {
  window.logger.log("doUsersGetAccounts", email);
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    const { companyDomain } = yield select(({ config }) => config);
    const { data } = yield call(
      UsersApi.GetUserAccounts,
      api,
      {
        email,
        tenant: companyDomain,
      },
      { token }
    );
    const { accountsAvailable } = data;
    return accountsAvailable;
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doUsersGetAccounts", error, errors);
    return [];
  }
}

function* doUsersFetchUser(
  action: Action<
    string,
    {
      userId: string;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Users.usersFetchUserRequest());
  window.logger.log("doUsersFetchUser", action);
  const {
    userId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get a user from specific userId
    const { data } = yield call(UsersApi.GetUser, api, userId, { token });
    const { user } = data;
    const accountsAvailable: object[] = yield doUsersGetAccounts(user.email);
    yield put(
      Users.usersFetchUserSuccess({
        user: { ...user, accountsAvailable },
      })
    );
    if (callbackSuccess) {
      callbackSuccess({ ...user, accountsAvailable });
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doUsersFetchUser", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Users.usersFetchUserFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doUsersFetchUserKpis(
  action: Action<
    string,
    {
      userId: string;
      callbackSuccess?: (data: any) => void;
      callbackError?: (roles: object, error: any) => void;
    }
  >
) {
  window.logger.log("doUsersFetchUserKpis", action);
  yield put(Users.usersFetchUserKpisRequest());
  const {
    userId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get a user kpis from specific userId
    const {
      data: { kpis: CaseLogs },
    } = yield call(UsersApi.GetUserKpis, api, userId, { token });
    yield put(Users.usersFetchUserKpisSuccess());
    if (callbackSuccess) {
      callbackSuccess({ CaseLogs });
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doUsersFetchUserKpis", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Users.usersFetchUserKpisFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(
        {
          CaseLogs: [],
          Evaluations: null,
          Rotations: null,
        },
        message
      );
    }
  }
}

function* doUsersFetchUserAvailabilities(
  action: Action<
    string,
    {
      userId: string;
      payloadAvailabilities: any;
      cbs?: (data: any) => void;
      cbe?: (error: any) => void;
    }
  >
) {
  window.logger.log("doUsersFetchUserAvailabilities", action);
  yield put(Users.usersFetchUserAvailabilitiesRequest());
  const { userId, cbs = null, cbe = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get a user availabilities from specific userId
    // yield call(UsersApi.CreateUserAvailabilities, api, payloadAvailabilities, {
    //   token,
    // });
    const {
      data: { availabilities = [] },
    } = yield call(UsersApi.GetUserAvailabilities, api, userId, { token });
    yield put(Users.usersFetchUserAvailabilitiesSuccess());
    // yield call(
    //   UsersApi.EditUserAvailabilities,
    //   api,
    //   { availabilityDays: payloadAvailabilities.availabilityDays },
    //   {
    //     token,
    //   }
    // );
    if (cbs) {
      cbs(availabilities);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doUsersFetchUserAvailabilities",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Users.usersFetchUserAvailabilitiesFailure());
    const { message } = errors || "";
    if (cbe) {
      cbe(message);
    }
  }
}

function* doUsersEditUsers(
  action: Action<
    "USERS/EDIT/USERS",
    {
      action: EditUsersActions;
      allUsersSelected: boolean;
      batch: { [k: string]: any };
      cbs?: (
        errors: { [k: string]: any }[],
        numRequestAccepted: number,
        usersRoles?: { [k: string]: any }[]
      ) => void;
      cbe?: (error: any) => void;
    }
  >
) {
  window.logger.log("doUsersEditUsers", action);
  yield put(Users.usersEditUsersRequest());
  const {
    action: bulkAction,
    allUsersSelected = false,
    batch,
    cbs = null,
    cbe = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    const {
      filters: userManagerFilters,
      usersManagerSelected,
    }: {
      filters: StateUsersManager["initialStateFilters"];
      usersManagerSelected: StateUsersManager["usersManagerSelected"];
    } = yield select(({ usersManager }) => usersManager);
    let users: string[] = [];
    if (
      !allUsersSelected &&
      userManagerFilters.roles.selected?.apiName &&
      usersManagerSelected &&
      usersManagerSelected[userManagerFilters.roles.selected.apiName]
    ) {
      const usersSelected =
        usersManagerSelected[userManagerFilters.roles.selected.apiName];
      Object.keys(usersSelected).map((page) =>
        users.push(
          ...usersSelected[page].map((user: { [k: string]: any }) => user._id)
        )
      );
    }
    let filters: {
      role: UsersRole;
      users?: string[];
      status?: StateUsersManager["initialStateFilters"]["status"];
      // Students
      activeRotation?: StateUsersManager["initialStateFilters"]["rotations"]["activeRotation"];
      programs?: StateUsersManager["initialStateFilters"]["programs"]["selected"];
      cohorts?: StateUsersManager["initialStateFilters"]["cohorts"]["selected"];
      // InstitutionAdministrators | Preceptors
      institutions?: StateUsersManager["initialStateFilters"]["institutions"]["selected"];
    } = {
      role: userManagerFilters.roles.selected?.apiName || "Alumnis",
      status: userManagerFilters.status,
    };
    if (userManagerFilters.status.length > 0) {
      filters = {
        ...filters,
        status: userManagerFilters.status,
      };
    }
    if (!allUsersSelected) {
      filters = {
        ...filters,
        users,
      };
    } else {
      if (filters.role === "Students") {
        if (userManagerFilters.rotations.activeRotation) {
          filters = {
            ...filters,
            activeRotation: userManagerFilters.rotations.activeRotation,
          };
        }
        if (userManagerFilters.programs.selected?.length) {
          filters = {
            ...filters,
            programs: userManagerFilters.programs.selected,
          };
        }
        if (userManagerFilters.cohorts.selected?.length) {
          filters = {
            ...filters,
            cohorts: userManagerFilters.cohorts.selected,
          };
        }
      } else if (
        filters.role === "InstitutionAdministrators" ||
        filters.role === "Preceptors"
      ) {
        if (userManagerFilters.institutions.selected?.length) {
          filters = {
            ...filters,
            institutions: userManagerFilters.institutions.selected,
          };
        }
      }
    }
    // call the api to edit users in bulk
    const {
      data: { errors = [], users: usersRoles = [], enrollments = [] },
    } = yield call(
      UsersApi.EditUsers,
      api,
      {
        filters,
        batch,
      },
      { token }
    );
    Users.usersEditUsersSuccess();
    if (
      bulkAction === "change-users-status" ||
      bulkAction === "add-new-enrollment" ||
      bulkAction === "add-new-institution"
    ) {
      yield put(
        UsersManager.usersManagerUpdateStoreUsers({
          roles: [userManagerFilters.roles.selected?.apiName || "Alumnis"],
        })
      );
    } else if (bulkAction === "add-users-role") {
      yield put(Users.usersFetchUsersKpis({ limit: USER_MANAGER_USER_LIMIT }));
    }
    if (cbs) {
      cbs(
        errors,
        bulkAction === "add-new-enrollment"
          ? enrollments.length
          : usersRoles.length,
        usersRoles
      );
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doUsersEditUsers", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Users.usersEditUsersFailure());
    const { message } = errors || "";
    if (cbe) {
      cbe(message);
    }
  }
}
function* doUsersEditUser(
  action: Action<
    string,
    {
      actionApi:
        | "ACTIVATE_DEACTIVATE_USER"
        | "ADD_ROLE_USER"
        | "TRANSFER_USER"
        | "EDIT_USER";
      userId: string;
      body: object;
      isPostFile?: boolean;
      isMyProfile?: boolean;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Users.usersEditUserRequest());
  window.logger.log("doUsersEditUser", action);
  const {
    actionApi = "EDIT_USER",
    userId,
    body,
    isPostFile: isUpload = false,
    isMyProfile = false,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to create new institution
    const { data } = yield call(
      UsersApi.EditUser,
      api,
      actionApi,
      userId,
      body,
      isUpload,
      { token }
    );
    if (isMyProfile) {
      yield put(Auth.authUpdateMyUserAccount());
    }
    if (isUpload) {
      const { user } = data;
      const { updatedAt, profileImage320, profileImageOriginal } = user;
      yield put(
        Users.usersEditUserSuccess({
          body: { updatedAt, profileImage320, profileImageOriginal },
        })
      );
    } else {
      if (actionApi === "ADD_ROLE_USER") {
        const { user } = yield select(({ users }) => users);
        const accountsAvailable: object[] = yield doUsersGetAccounts(
          user.email
        );
        yield put(
          Users.usersEditUserSuccess({
            body: { updatedAt: new Date().toISOString(), accountsAvailable },
          })
        );
      } else if (actionApi === "TRANSFER_USER") {
        Users.usersEditUserSuccess();
      } else {
        const { updatedAt, customFields } = data.user;
        yield put(
          Users.usersEditUserSuccess({
            body: { updatedAt, ...body, customFields },
          })
        );
      }
    }
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doUsersEditUser", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Users.usersEditUserFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doUsersEditUserAvailabilities(
  action: Action<
    string,
    {
      availabilityId: string;
      body: any;
      cbs?: () => void;
      cbe?: (error: any) => void;
    }
  >
) {
  window.logger.log("doUsersEditUserAvailabilities", action);
  yield put(Users.usersEditUserAvailabilitiesRequest());
  const { availabilityId, body, cbs = null, cbe = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to edit a user availabilities from specific userId
    yield call(UsersApi.EditUserAvailabilities, api, availabilityId, body, {
      token,
    });
    yield put(Users.usersEditUserAvailabilitiesSuccess());
    if (cbs) {
      cbs();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doUsersEditUserAvailabilities",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Users.usersEditUserAvailabilitiesFailure());
    const { message } = errors || "";
    if (cbe) {
      cbe(message);
    }
  }
}

function* doUsersSetUser(
  action: Action<
    string,
    {
      user: { [k: string]: any };
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  window.logger.log("doUsersSetUser", action);
  const { user } = action.payload || {};
  try {
    let userToLoad = null;
    const stateUsers: { [k: string]: any } = yield select(({ users }) => users);
    if (
      user &&
      user.role &&
      stateUsers &&
      stateUsers[user.role] &&
      stateUsers[user.role].isFetched
    ) {
      let userArray = stateUsers[user.role].users.filter(
        ({ _id: userId }: any) => userId === user._id
      );
      if (userArray && userArray.length) {
        userToLoad = userArray[0];
      }
    }
    yield put(Users.usersFetchUserSuccess({ user: userToLoad }));
  } catch (error: any) {
    window.logger.error("ErrorSaga - doUsersSetUser", error);
  }
}

function* doUsersFetchUserFiles(
  action: Action<
    string,
    {
      id: string;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Users.usersFetchUserFilesRequest());
  window.logger.log("doUsersFetchUserFiles", action);
  const { id, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get all files from this user from specific userId
    const { data } = yield call(UsersApi.GetUserFiles, api, id, { token });
    const { files } = data;
    yield put(Users.usersFetchUserFilesSuccess({ files }));
    if (callbackSuccess) {
      callbackSuccess(files);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doUsersFetchUserFiles", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Users.usersFetchUserFilesFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doUsersFetchUserFilesExpired(
  action: Action<
    string,
    {
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Users.usersFetchUserFilesExpiredRequest());
  window.logger.log("doUsersFetchUserFilesExpired", action);
  const { callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    const { data } = yield call(UsersApi.GetUserFilesExpired, api, { token });
    const { files: filesExpired } = data;
    yield put(Users.usersFetchUserFilesExpiredSuccess({ filesExpired }));
    if (callbackSuccess) {
      callbackSuccess(filesExpired);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doUsersFetchUserFilesExpired",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Users.usersFetchUserFilesExpiredFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doUsersUploadUserFiles(
  action: Action<
    string,
    {
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Users.usersUploadUserFilesRequest());
  window.logger.log("doUsersUploadUserFiles", action);
  const { body, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to upload all files for this user
    const { data } = yield call(UsersApi.UploadUserFiles, api, body, { token });
    const { files } = data;
    yield put(Users.usersUploadUserFilesSuccess());
    if (callbackSuccess) {
      callbackSuccess(files);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doUsersUploadUserFiles", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Users.usersUploadUserFilesFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doUsersEmulateUser(
  action: Action<
    string,
    {
      user: object;
      stopEmulation?: boolean;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Users.usersEmulateUserRequest());
  window.logger.log("doUsersEmulateUser", action);
  const {
    user,
    stopEmulation = false,
    callbackSuccess,
    callbackError,
  } = action.payload;
  try {
    const { api, token: apiToken }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to switch User
    const { data } = yield call(
      UsersApi.EmulateUser,
      api,
      {
        user,
        stopEmulation,
      },
      { token: apiToken }
    );
    const { user: userId, token, emulation } = data;
    const { localhost, hostname, origin, protocol, port } = yield select(
      ({ config }) => config
    );
    if (localhost) {
      Cookies.set(
        "authCookie",
        { token, user: userId, emulation, removeDomain: `${hostname}` },
        { domain: `${hostname}` }
      );
    }
    let pathToAppReload = origin;
    if (!pathToAppReload) {
      pathToAppReload = `${protocol}${hostname}${port ? ":3001" : ""}`;
    }
    yield put(LocalStoreActions.clearAllLocalStore());
    yield put(Users.usersEmulateUserSuccess());
    if (callbackSuccess) {
      callbackSuccess();
    }
    setTimeout(() => window.location.replace(pathToAppReload), 500);
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doUsersEmulateUser", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Users.usersEmulateUserFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

export default function* usersSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(Users.USERS_FETCH_USERS_KPIS, doUsersFetchUsersKpis),
    takeLatest(Users.USERS_FETCH_ALL, doUsersFetchAll),
    takeLatest(
      UsersManager.USERS_MANAGER_UPDATE_STORE_USERS,
      doUsersUpdateStoreUsers
    ),
    takeLatest(
      [
        UsersManager.USERS_MANAGER_FILTERS_SET_PAGES,
        UsersManager.USERS_MANAGER_FILTERS_SEARCH,
        UsersManager.USERS_MANAGER_FILTERS_USER_STATUS,
        UsersManager.USERS_MANAGER_FILTERS_ROTATIONS_ACTIVE,
        UsersManager.USERS_MANAGER_FILTERS_PROGRAMS,
        UsersManager.USERS_MANAGER_FILTERS_COHORTS,
        UsersManager.USERS_MANAGER_FILTERS_INSTITUTIONS,
        UsersManager.USERS_MANAGER_FILTERS_SET_SORTBY,
      ],
      doUsersFetchUsersByFilters
    ),
    takeLatest(Users.USERS_FETCH_USERS_ROLES, doUsersFetchUsersRoles),
    takeLatest(Users.USERS_FETCH_USER, doUsersFetchUser),
    takeLatest(Users.USERS_FETCH_USER_KPIS, doUsersFetchUserKpis),
    takeLatest(
      Users.USERS_FETCH_USER_AVAILABILITIES,
      doUsersFetchUserAvailabilities
    ),
    takeLatest(Users.USERS_EDIT_USERS, doUsersEditUsers),
    takeLatest(Users.USERS_EDIT_USER, doUsersEditUser),
    takeLatest(
      Users.USERS_EDIT_USER_AVAILABILITIES,
      doUsersEditUserAvailabilities
    ),
    takeLatest(Users.USERS_SET_USER, doUsersSetUser),
    takeLatest(Users.USERS_FETCH_USER_FILES, doUsersFetchUserFiles),
    takeLatest(
      Users.USERS_FETCH_USER_FILES_EXPIRED,
      doUsersFetchUserFilesExpired
    ),
    takeLatest(Users.USERS_UPLOAD_USER_FILES, doUsersUploadUserFiles),
    takeLatest(Users.USERS_EMULATE_USER, doUsersEmulateUser),
  ]);
}
