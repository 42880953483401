import { createAction } from "deox";
// Import Types
import type {
  TYPE_GUIDE_LINE_TUTORIAL_MENU,
  TYPE_GUIDE_LINE_TUTORIAL_HOME,
  TYPE_GUIDE_LINE_TUTORIAL_USER_MANAGER,
  TYPE_GUIDE_LINE_TUTORIAL_USER_MANAGER_INVITATIONS,
  TYPE_GUIDE_LINE_TUTORIAL_INSTITUTIONS,
  TYPE_GUIDE_LINE_TUTORIAL_ROTATIONS,
  TYPE_GUIDE_LINE_TUTORIAL_ROTATIONS_CREATE,
  TYPE_GUIDE_LINE_TUTORIAL_CASE_LOGS,
  TYPE_GUIDE_LINE_TUTORIAL_EVALUATIONS,
  TYPE_GUIDE_LINE_TUTORIAL_ACCOUNT_SETTINGS,
} from "../../types/guideLineTutorial.types";

// Actions for Guide Line Tutorial
export const GUIDE_LINE_TUTORIAL_INITIALIZE: string =
  "GUIDE_LINE_TUTORIAL/INITIALIZE";
export const guideLineTutorialInitialize = createAction(
  GUIDE_LINE_TUTORIAL_INITIALIZE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const GUIDE_LINE_TUTORIAL_ON_OFF: string = "GUIDE_LINE_TUTORIAL/ON_OFF";
export const guideLineTutorialOnOff = createAction(GUIDE_LINE_TUTORIAL_ON_OFF);

// Actions React Joyride
export const GUIDE_LINE_TUTORIAL_START: string = "GUIDE_LINE_TUTORIAL/START";
export const guideLineTutorialStart = createAction(
  GUIDE_LINE_TUTORIAL_START,
  (resolve) => (payload?: object) => resolve(payload)
);

export const GUIDE_LINE_TUTORIAL_STOP: string = "GUIDE_LINE_TUTORIAL/STOP";
export const guideLineTutorialStop = createAction(
  GUIDE_LINE_TUTORIAL_STOP,
  (resolve) => (payload?: object) => resolve(payload)
);

export const GUIDE_LINE_TUTORIAL_PAUSE: string = "GUIDE_LINE_TUTORIAL/PAUSE";
export const guideLineTutorialPause = createAction(
  GUIDE_LINE_TUTORIAL_PAUSE,
  (resolve) => (payload?: object) => resolve(payload)
);

export const GUIDE_LINE_TUTORIAL_PLAY: string = "GUIDE_LINE_TUTORIAL/PLAY";
export const guideLineTutorialPlay = createAction(
  GUIDE_LINE_TUTORIAL_PLAY,
  (resolve) => (payload?: object) => resolve(payload)
);

export const GUIDE_LINE_TUTORIAL_NEXT: string = "GUIDE_LINE_TUTORIAL/NEXT";
export const guideLineTutorialNext = createAction(
  GUIDE_LINE_TUTORIAL_NEXT,
  (resolve) => (payload?: object) => resolve(payload)
);

export const GUIDE_LINE_TUTORIAL_PREV: string = "GUIDE_LINE_TUTORIAL/PREV";
export const guideLineTutorialPrev = createAction(
  GUIDE_LINE_TUTORIAL_PREV,
  (resolve) => (payload?: object) => resolve(payload)
);

export const GUIDE_LINE_TUTORIAL_COMPLETED: string =
  "GUIDE_LINE_TUTORIAL/COMPLETED";
export const guideLineTutorialCompleted = createAction(
  GUIDE_LINE_TUTORIAL_COMPLETED
);

//MENU
export const GUIDE_LINE_TUTORIAL_MENU: TYPE_GUIDE_LINE_TUTORIAL_MENU = "MENU";
//HOME
export const GUIDE_LINE_TUTORIAL_HOME: TYPE_GUIDE_LINE_TUTORIAL_HOME = "HOME";
//USER_MANAGER
export const GUIDE_LINE_TUTORIAL_USER_MANAGER: TYPE_GUIDE_LINE_TUTORIAL_USER_MANAGER =
  "USER_MANAGER";
export const GUIDE_LINE_TUTORIAL_USER_MANAGER_INVITATIONS: TYPE_GUIDE_LINE_TUTORIAL_USER_MANAGER_INVITATIONS =
  "USER_MANAGER_INVITATIONS";
//INSTITUTIONS
export const GUIDE_LINE_TUTORIAL_INSTITUTIONS: TYPE_GUIDE_LINE_TUTORIAL_INSTITUTIONS =
  "INSTITUTIONS";
//ROTATIONS
export const GUIDE_LINE_TUTORIAL_ROTATIONS: TYPE_GUIDE_LINE_TUTORIAL_ROTATIONS =
  "ROTATIONS";
export const GUIDE_LINE_TUTORIAL_ROTATIONS_CREATE: TYPE_GUIDE_LINE_TUTORIAL_ROTATIONS_CREATE =
  "ROTATIONS_CREATE";
//CASE_LOGS
export const GUIDE_LINE_TUTORIAL_CASE_LOGS: TYPE_GUIDE_LINE_TUTORIAL_CASE_LOGS =
  "CASE_LOGS";
//EVALUATIONS
export const GUIDE_LINE_TUTORIAL_EVALUATIONS: TYPE_GUIDE_LINE_TUTORIAL_EVALUATIONS =
  "EVALUATIONS";
//ACCOUNT_SETTINGS
export const GUIDE_LINE_TUTORIAL_ACCOUNT_SETTINGS: TYPE_GUIDE_LINE_TUTORIAL_ACCOUNT_SETTINGS =
  "ACCOUNT_SETTINGS";
