// Features
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { Notifications } from "../../../store/actions";
// Components
import { Button, Loader, Popup } from "semantic-ui-react";
// Types
import type { RootState } from "../../../store/reducers";
// Notifications Actions in Bulk:
const { useState } = React;
//
const NotificationsActionsInBulk = ({
  canDoInActionsInBulk: _canDoInActionsInBulk_,
}: React.PropsWithChildren<{
  canDoInActionsInBulk: boolean;
}>) => {
  const dispatch = useDispatch();
  // STORE NOTIFICATIONS
  const hasNotificationsCategories: boolean = useSelector(
    ({ notifications }: RootState) =>
      notifications.notificationsCategories.length > 0
  );
  // State
  const [inAction, setInAction] = useState<
    "" | "markAsRead-all" | "delete-all"
  >("");
  // Methods
  const onAction = (action: "markAsRead-all" | "delete-all") => () => {
    setInAction(action);
    if (action === "markAsRead-all") {
      dispatch(
        Notifications.notificationsMarkAsReadNotifications({
          callbackSuccess: () => setInAction(""),
          callbackError: () => setInAction(""),
        })
      );
    } else if (action === "delete-all") {
      dispatch(
        Notifications.notificationsDelete({
          callbackSuccess: () => setInAction(""),
          callbackError: () => setInAction(""),
        })
      );
    }
  };
  // RENDERS
  const canDoInActionsInBulk = _canDoInActionsInBulk_ && !inAction,
    canDeleteAllInBulk = canDoInActionsInBulk && hasNotificationsCategories;
  return (
    <div
      className="actions-bulk mt10 mb15 pb15"
      style={{
        opacity: !canDoInActionsInBulk || inAction !== "" ? 0.25 : 1,
      }}
    >
      <Popup
        content={
          _canDoInActionsInBulk_ && hasNotificationsCategories ? (
            <Button
              basic
              className="delete-all-notification-btn skejule tinytext m0"
              data-btn="is-popup-notification"
              disabled={inAction === "delete-all"}
              loading={inAction === "delete-all"}
              onClick={onAction("delete-all")}
            >
              Confirm Delete all notifications
            </Button>
          ) : (
            "Delete all notifications"
          )
        }
        data-popup="is-popup-notification"
        hoverable={canDeleteAllInBulk}
        on={canDeleteAllInBulk ? ["hover", "click"] : undefined}
        position="bottom center"
        trigger={
          <div
            className={classNames(
              "btn mr5",
              {
                "skejule-teal link": canDeleteAllInBulk,
              },
              "right"
            )}
            style={
              !hasNotificationsCategories
                ? {
                    opacity: 0.25,
                  }
                : {}
            }
          >
            <span>Delete all</span>
            {inAction === "delete-all" && (
              <Loader
                size="tiny"
                inline
                active={true}
                style={{
                  top: "0px",
                }}
              />
            )}
          </div>
        }
      />
      <Popup
        content="Mark all notifications as read"
        position="bottom center"
        trigger={
          <div
            className={classNames(
              "btn mr15",
              {
                "skejule-teal link": canDoInActionsInBulk,
              },
              "right"
            )}
            onClick={
              canDoInActionsInBulk ? onAction("markAsRead-all") : undefined
            }
          >
            <span>Mark all as read</span>
            {inAction === "markAsRead-all" && (
              <Loader
                size="tiny"
                inline
                active={true}
                style={{
                  top: "0px",
                }}
              />
            )}
          </div>
        }
      />
    </div>
  );
};
export default NotificationsActionsInBulk;
