// Service Worker Reducer
import { ServiceWorker } from "../actions/";

type State = {
  initServiceWorker: boolean;
  refreshButtonShow: boolean;
};
type Action = {
  type: string;
  payload: Object;
};

// The initial application state
const initialState: State = {
  initServiceWorker: false,
  refreshButtonShow: false,
};

export default function serviceWorker(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case ServiceWorker.SERVICE_WORKER_INIT:
      return Object.assign({}, state, { initServiceWorker: true });
    case ServiceWorker.SERVICE_WORKER_REFRESH_BUTTON_SHOW:
      return Object.assign({}, state, {
        refreshButtonShow: !state.refreshButtonShow,
      });
    // initial state
    default:
      return state;
  }
}
