// Import Features
import { AccessControl, CustomFields } from "../actions/";
import union from "lodash/union";
import { updateArrayWithElement } from "../../utils/";
// Types
import type { UsersRole } from "../../types/users.types";
type StateModelsAvailable = UsersRole | "Institutions" | "CaseLogs";
type PayloadCustomFields = {
  _id: string;
  name: string;
  label: string;
  isRequired: boolean;
  model: StateModelsAvailable;
  fieldType:
    | "boolean"
    | "string"
    | "number"
    | "date"
    | "dropdown"
    | "radiobutton"
    | "checkbox"
    | "file";

  conditions: {
    programs: string[];
    cohorts: string[];
  };
  permissions: {
    [key in UsersRole]: "read" | "write";
  };
  createdAt: Date;
  updatedAt: Date;
};
export type State = {
  lastFetching: Date;
  models: Array<StateModelsAvailable>;
  customFields: {
    all: PayloadCustomFields[];
    SuperCompanyAdministrators: PayloadCustomFields[];
    CompanyAdministrators: PayloadCustomFields[];
    Coordinators: PayloadCustomFields[];
    Faculties: PayloadCustomFields[];
    Students: PayloadCustomFields[];
    InstitutionAdministrators: PayloadCustomFields[];
    Preceptors: PayloadCustomFields[];
    Institutions: PayloadCustomFields[];
    CaseLogs: PayloadCustomFields[];
  };
  triggerAnimation: boolean;
};
type Action = {
  type: string;
  payload: Object;
};
// Custom Fields Reducer
const addModelsCustomfields = (newModels: object[] = []) => {
  let modelsRoles = [
    "SuperCompanyAdministrators",
    "CompanyAdministrators",
    "Coordinators",
    "Faculties",
    "Students",
    "InstitutionAdministrators",
    "Preceptors",
  ].filter((model) => newModels.indexOf(model) !== -1);
  modelsRoles = union(modelsRoles, newModels);
  let modelsOthers = ["Institutions", "CaseLogs"];
  return [...modelsRoles, ...modelsOthers];
};
const initialStateModels = [];
const initialState: State = {
  lastFetching: new Date(),
  models: initialStateModels,
  customFields: {
    all: [],
    SuperCompanyAdministrators: [],
    CompanyAdministrators: [],
    Coordinators: [],
    Faculties: [],
    Students: [],
    InstitutionAdministrators: [],
    Preceptors: [],
    Institutions: [],
    CaseLogs: [],
  },
  triggerAnimation: false,
};

export default function customFields(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    //initialState
    case AccessControl.ACCESS_CONTROL_FETCH_ROLES_SUCCESS:
      return Object.assign({}, state, {
        lastFetching: new Date(),
        models: addModelsCustomfields(
          action.payload.roles
            .filter(({ apiName }) => apiName !== "Alumnis")
            .map(({ apiName }) => apiName)
        ),
      });
    //Custom Fields
    case CustomFields.CUSTOM_FIELDS_FETCH_CUSTOM_FIELDS_SUCCESS:
      let customFields = {
        ...state.customFields,
        all: action.payload.customFields,
      };
      for (let i = 0; i < state.models.length; i++) {
        customFields[state.models[i]] = action.payload.customFields.filter(
          ({ model }) => model === state.models[i]
        );
      }
      return Object.assign({}, state, {
        lastFetching: new Date(),
        customFields,
      });
    case CustomFields.CUSTOM_FIELDS_CREATE_SUCCESS:
      return Object.assign({}, state, {
        lastFetching: new Date(),
        customFields: {
          ...state.customFields,
          all: [...state.customFields.all, action.payload.customField],
          [action.payload.customField.model]: [
            ...state.customFields[action.payload.customField.model],
            action.payload.customField,
          ],
        },
      });
    case CustomFields.CUSTOM_FIELDS_EDIT_FIELD_SUCCESS:
      return Object.assign({}, state, {
        lastFetching: new Date(),
        customFields: {
          ...state.customFields,
          all: updateArrayWithElement(
            state.customFields.all,
            action.payload.customField
          ),
          [action.payload.customField.model]: updateArrayWithElement(
            state.customFields[action.payload.customField.model],
            action.payload.customField
          ),
        },
      });
    case CustomFields.CUSTOM_FIELDS_DELETE_FIELD_SUCCESS:
      return Object.assign({}, state, {
        lastFetching: new Date(),
        customFields: {
          ...state.customFields,
          all: state.customFields.all.filter(
            ({ _id }) => _id !== action.payload.customFieldId
          ),
          [action.payload.customFieldModel]: state.customFields[
            action.payload.customFieldModel
          ].filter(({ _id }) => _id !== action.payload.customFieldId),
        },
      });
    case CustomFields.CUSTOM_FIELDS_REQUIRED_TRIGER_ANIMATION:
      return Object.assign({}, state, {
        triggerAnimation: !state.triggerAnimation,
      });
    // same state (not change)
    default:
      return state;
  }
}
