// @flow
import * as React from "react";
import { connect } from "react-redux";
import { Auth } from "../../store/actions/";
import * as serviceWorkerRegistration from "../../utils/service-worker/serviceWorkerRegistration";
import Loader from "../shared/Loader";

type Props = {
  config: Object,
  isTokenExpired: boolean,
  signOut: (payload: Object) => void,
};

class SignOutContainer extends React.Component<Props> {
  onDoingSignOut = () => {
    const { signOut } = this.props;
    return new Promise((resolve, reject) => {
      const callbacks = {
        callbackError: (error) => {
          serviceWorkerRegistration.unregister();
          window.logger.log("error", error);
          reject(error);
        },
        callbackSuccess: () => {
          serviceWorkerRegistration.unregister();
          resolve();
        },
      };
      const payload = Object.assign({}, callbacks);
      signOut(payload);
    });
  };
  onSignOut = () => {
    const { baseDomain, port } = this.props.config;
    const pathToWebsite: string = `${baseDomain}${port ? ":3000" : ""}/signin`;
    this.onDoingSignOut()
      .then(() => {
        window.location.replace(pathToWebsite);
      })
      .catch((error) => {
        window.logger.log("onSignOut", error);
        window.location.replace(pathToWebsite);
      });
  };
  componentDidMount() {
    const { isTokenExpired } = this.props;
    if (isTokenExpired) {
      setTimeout(() => {
        this.onSignOut();
      }, 3000);
    } else {
      this.onSignOut();
    }
  }
  render() {
    const { isTokenExpired } = this.props;
    return (
      <Loader
        className="sign-out"
        textLoading={
          !isTokenExpired ? "Sign Out ..." : "Session expired, sign out ..."
        }
      />
    );
  }
}

const bindActions = (dispatch) => {
  return {
    signOut: (payload) => dispatch(Auth.authSignOutApp(payload)),
  };
};

export default connect(null, bindActions)(SignOutContainer);
