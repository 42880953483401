// Import Features
import { Cohorts } from "../actions/";
import { updateArrayWithElement } from "../../utils/";
// Cohorts Reducer
type State = {
  lastFetching: Date | undefined | null;
  cohorts: object[];
};
type Action = {
  type: string;
  payload: Object;
};

const initialState: State = {
  lastFetching: null,
  cohorts: [],
};

export default function cohorts(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    //Cohorts
    case Cohorts.COHORTS_FETCH_COHORTS_SUCCESS:
      return Object.assign({}, state, {
        lastFetching: new Date(),
        cohorts: action.payload.cohorts,
      });
    case Cohorts.COHORTS_CREATE_SUCCESS:
      return Object.assign({}, state, {
        lastFetching: new Date(),
        cohorts: [...state.cohorts, action.payload.cohort],
      });
    case Cohorts.COHORTS_EDIT_COHORT_SUCCESS:
      return Object.assign({}, state, {
        cohorts: updateArrayWithElement(state.cohorts, action.payload.cohort),
      });
    case Cohorts.COHORTS_DELETE_SUCCESS:
      return Object.assign({}, state, {
        lastFetching: new Date(),
        cohorts: state.cohorts.filter(
          ({ _id }) => _id !== action.payload.cohortId
        ),
      });
    // same state (not change)
    default:
      return state;
  }
}
