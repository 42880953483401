// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
// Features
import * as React from "react";
import classNames from "classnames";
import { setUserRole } from "../../../utils";
// Assets
import iconUserEmptyFill from "../../../images/iconUserEmptyFill.svg";
// Components
import { Grid, Image } from "semantic-ui-react";
import NotificationsDate from "./NotificationsDate";
const NotificationUserAccount = ({
  _id,
  readBy,
  user,
  institution,
  message,
  createdAt,
  setImg,
  goToUserProfile,
}: Object): React$Element<any> => (
  <div
    className={classNames("notification", {
      new: !readBy || (readBy && readBy.length === 0),
    })}
  >
    <Grid columns={2}>
      <Grid.Row className="nopadding">
        <Grid.Column mobile={3} tablet={3} computer={3} className="">
          <Image
            src={
              user.profileImage320
                ? setImg(user.profileImageOriginal, createdAt)
                : iconUserEmptyFill
            }
            className="icon-user huge"
            alt="icon-user"
            centered
          />
        </Grid.Column>
        <Grid.Column mobile={13} tablet={13} computer={13} className="">
          <div className="message">
            {message
              .replace(/{role}/gi, setUserRole(user.role).name)
              .split(/({userName}|{institutionName})/gi)
              .map((el, index) => {
                if (el === "{userName}") {
                  const userName = `${user.firstName || "Not Name"}  ${
                    user.lastName || "Not Last Name"
                  }`;
                  return (
                    <a
                      key={user._id + index}
                      style={{ cursor: "pointer" }}
                      onClick={(event: Object) => {
                        // DEBUG: We need to debugger this action, because when
                        // it's called one time and manager profile is open
                        // the action User.setUserId is called two times, because of
                        // ManagerRoute component is launched two times before route change
                        goToUserProfile(`/manager/profile/${user._id}`, {
                          namePage: "Profile",
                          role: setUserRole(user.role),
                          user: null,
                        })(event);
                        setTimeout(() => {
                          goToUserProfile(`/manager/profile/${user._id}`, {
                            namePage: "Profile",
                            role: setUserRole(user.role),
                            user: null,
                          })(event);
                        }, 500);
                      }}
                    >
                      {userName}
                    </a>
                  );
                } else if (el === "{institutionName}") {
                  return (
                    <a
                      key={(institution && institution._id) + index}
                      style={{ cursor: "pointer" }}
                      onClick={goToUserProfile(
                        `/clinics/view/${institution && institution._id}`
                      )}
                    >
                      {institution && institution.name}
                    </a>
                  );
                }
                return el;
              })}
          </div>
          <NotificationsDate
            createdAt={createdAt}
            isMarkAsRead={!!readBy && readBy?.length > 0}
            notificationId={_id}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
);

export default NotificationUserAccount;
