import type { Config, tAPI } from "./API";
// API Invitations static class

const apiBaseInvitations = "/user-invitations";
export default class Invitations {
  // Get a list of invitations invtations by roles | institutions
  static GetInvitations(
    api: tAPI,
    roles: string[],
    emails: string[],
    institutions: string[],
    groupBy: string[],
    status: string[],
    config?: Config
  ) {
    let queryParams = "?populated=true";
    if (roles.length > 0) {
      queryParams += `&roles=${roles.join()}`;
    }
    if (emails.length > 0) {
      queryParams += `&email=${emails.join()}`;
    }
    if (institutions.length > 0) {
      queryParams += `&institutions=${institutions.join()}`;
    }
    if (status.length > 0) {
      queryParams += `&status=${status.join()}`;
    }
    return api.GET(`${apiBaseInvitations}${queryParams}`, config);
  }
  // Create a list of Invitations
  static CreateInvitations(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBaseInvitations}`, data, { ...config, vApi: "v2" });
  }
  // Resend invitation(s)
  static ResendInvitations(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBaseInvitations}/resend`, data, config);
  }
  // Active invitation
  static ActiveInvitation(
    api: tAPI,
    invitationId: string,
    data: object,
    config?: Config
  ) {
    return api.PUT(
      `${apiBaseInvitations}/${invitationId}/active`,
      data,
      config
    );
  }
  // Delete a Invitation
  static DeleteInvitation(api: tAPI, invitationId: string, config?: Config) {
    return api.DELETE(`${apiBaseInvitations}/${invitationId}`, config);
  }
  //DEPRECATED
  // static ResendInvitation(api: tAPI, invitationId: string, data: object) {
  //   return api.PUT(`${apiBaseInvitations}/${invitationId}`, data);
  // }
}
