// @flow
import * as React from "react";
import classNames from "classnames";
import { Dimmer, Menu, Tab } from "semantic-ui-react";
import Notifications from "./components/Notifications";
import Broadcast from "../broadcast/Broadcast";
import {
  onCheckReadRolesPermissions,
  onCheckReadTenantSettingsPermissions,
  onCheckCreateTenantSettingsPermissions,
} from "../admin-portal/AdminPortalPermissions";

type Props = {
  authPermissions: Object,
  activeNotifications: boolean,
  unseenNotifications: number,
  notifications: {
    all: Array<Object>,
    notifications: Array<Object>,
    reminders: Array<Object>,
    isFetchingNotifications: boolean,
  },
  initializeNotifications: Function,
  setImg: (_img: string, updatedAt: string) => string,
  goTo: (pathname: string, state: Object) => void,
  getMoreNotifications: (lastNotificationId: string) => () => void,
};

class NotificationsPresentational extends React.Component<Props> {
  renderPanes = () => {
    const {
      authPermissions,
      /*unseenNotifications,*/ notifications,
      setImg,
      goTo,
      getMoreNotifications,
    } = this.props;
    const notificationsPane = {
        menuItem: (
          <Menu.Item key="notifications" className="m0 mt50">
            <i className="icono huge icono-bell skejule-teal" />
          </Menu.Item>
        ),
        render: () => (
          <Notifications
            authPermissions={authPermissions}
            isFetchingNotifications={notifications.isFetchingNotifications}
            notifications={notifications.all}
            setImg={setImg}
            goTo={goTo}
            getMoreNotifications={getMoreNotifications}
          />
        ),
      },
      broadcastMessagesPane = {
        menuItem: (
          <Menu.Item key="broadcast-messages" className="m0">
            <i className="icono huge icono-broadcast skejule-teal" />
          </Menu.Item>
        ),
        render: () => (
          <Broadcast
            canCreateNewBroadcastMessages={
              onCheckReadRolesPermissions(authPermissions) &&
              onCheckReadTenantSettingsPermissions(authPermissions) &&
              onCheckCreateTenantSettingsPermissions(authPermissions)
            }
          />
        ),
      };
    return [notificationsPane, broadcastMessagesPane];
  };
  render() {
    const { activeNotifications, initializeNotifications } = this.props;
    return (
      <>
        <div
          ref={initializeNotifications}
          className={classNames("notifications-popup", {
            visible: activeNotifications,
          })}
        >
          {/* <div className="arrow-left" /> */}
          <Tab
            className="notifications-popup-container"
            grid={{
              paneWidth: 14,
              tabWidth: 2,
              className: "notifications-content",
            }}
            menu={{
              icon: true,
              pointing: true,
              secondary: true,
              vertical: true,
              tabular: "right",
            }}
            panes={this.renderPanes()}
            style={{ cursor: "default" }}
          />
        </div>
        <Dimmer
          active
          page
          style={{
            background: "#00000059",
            zIndex: 100,
          }}
        />
      </>
    );
  }
}

export default NotificationsPresentational;
