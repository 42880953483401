// @flow
// Import Features
import * as React from "react";
import { connect } from "react-redux";
import { GuideLineTutorial } from "../../../store/actions/";
import { EVENTS, STATUS } from "react-joyride";
// Import Types
import type { PropsMenuTutorial as Props } from "../../../types/guideLineTutorial.types";
class MenuTutorial extends React.Component<Props> {
  componentDidMount() {
    this._onStartTutorial();
  }
  _onStartTutorial = () => {
    const { canRenderTutorial, startTutorial, userName } = this.props;
    if (canRenderTutorial) {
      startTutorial({
        joyrideProps: {
          run: true,
          steps: [
            {
              target: "main#heto-app-website",
              content: (
                <div className="text-left">
                  Welcome {userName} to Heto.
                  <br />
                  There will be some tutorial to let you know the main points
                  for every section. <br />
                  You can skip or disable all these tutorial in 'Your Settings'.
                </div>
              ),
              placement: "center",
              title: "Welcome  to Heto",
            },
            {
              target: ".menu .sidebarmenu.visible div.menu",
              content: (
                <div className="text-left">
                  From the menu sidebar, you can navigate through the different
                  sections on Heto. Starting with Home, where we are right now,
                  passing through Notifications, User Manager, and other
                  sections.
                </div>
              ),
              placement: "right",
              placementBeacon: "right",
              title: "Navigation Menu",
            },
            {
              target: ".menu .sidebarmenu.visible div.dropdown.user-avatar img",
              content: (
                <div className="text-left">
                  This "user menu" is always available on the bottom left
                  screen.
                </div>
              ),
              styles: {
                options: {
                  zIndex: 1000,
                },
              },
              title: "User Menu",
            },
            {
              target:
                ".menu .sidebarmenu.visible div.dropdown.user-avatar div.menu.dropdown-menu-user-avatar",
              content: (
                <div className="text-left">
                  The "user menu" give you direct access to 'Your Profile', and
                  other sections.
                </div>
              ),
              placement: "right",
              placementBeacon: "right",
              title: "User Menu",
            },
          ],
          continuous: true,
          showProgress: true,
          showSkipButton: true,
          callback: this._handleJoyrideCallback,
          styles: {
            options: {
              primaryColor: "#77c0cf",
              zIndex: 1000,
            },
          },
        },
        sectionRunning: GuideLineTutorial.GUIDE_LINE_TUTORIAL_MENU,
      });
    }
  };
  // <METHODS PRIVATE
  _handleJoyrideCallback = (data) => {
    const { action, index, type, status } = data;
    if (
      EVENTS.TOUR_END === type &&
      (STATUS.FINISHED === status || STATUS.SKIPPED === status)
    ) {
      const el = window.document.getElementById("dropdown-user-avatar");
      el.classList.remove("active", "visible");
      el.lastChild.classList.remove("visible");
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      if (action === "next" && index === 2) {
        const el = window.document.getElementById("dropdown-user-avatar");
        el.classList.add("active", "visible");
        el.lastChild.classList.add("visible");
      }
    }
  };
  // END />
  render = () => null;
  getSnapshotBeforeUpdate(prevProps: Props, prevState: any) {
    if (prevProps.canRenderTutorial !== this.props.canRenderTutorial) {
      if (this.props.canRenderTutorial) {
        return true;
      }
      this.props.stopTutorial();
    }
    return false;
  }
  componentDidUpdate(prevProps: Props, prevState: any, updateState: boolean) {
    if (updateState) {
      setTimeout(() => {
        this._onStartTutorial();
      }, 100);
    }
  }
  componentWillUnmount() {
    this.props.stopTutorial();
  }
}

export default connect(
  ({ auth: { user }, guideLineTutorial: { sections, sectionsCompleted } }) => ({
    canRenderTutorial:
      sections.length > sectionsCompleted.length &&
      sectionsCompleted.indexOf(GuideLineTutorial.GUIDE_LINE_TUTORIAL_MENU) ===
        -1,
    userName: user ? `${user.firstName} ${user.lastName}` : "",
  }),
  (dispatch) => ({
    startTutorial: (payload) =>
      dispatch(GuideLineTutorial.guideLineTutorialStart(payload)),
    stopTutorial: () => dispatch(GuideLineTutorial.guideLineTutorialStop()),
  })
)(MenuTutorial);
