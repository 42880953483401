// Features
import * as React from "react";
import { useDispatch } from "react-redux";
import { Notifications } from "../../../store/actions";
// Components
import { Button, Icon, Popup } from "semantic-ui-react";
// Notifications Individual Action:
const { useState } = React;
//
const NotificationsActions = ({
  isMarkAsRead,
  notificationId,
}: React.PropsWithChildren<{
  isMarkAsRead: boolean;
  notificationId: string;
}>) => {
  const dispatch = useDispatch();
  // State
  const [inAction, setInAction] = useState<"" | "markAsRead" | "delete">("");
  // Methods
  const onAction = (action: "markAsRead" | "delete") => () => {
    setInAction(action);
    if (action === "markAsRead") {
      dispatch(
        Notifications.notificationsMarkAsReadNotifications({
          notificationId,
          callbackSuccess: () => setInAction(""),
          callbackError: () => setInAction(""),
        })
      );
    } else if (action === "delete") {
      dispatch(
        Notifications.notificationsDelete({
          notificationId,
          callbackSuccess: () => setInAction(""),
          callbackError: () => setInAction(""),
        })
      );
    }
  };
  // RENDERS
  return (
    <div className="actions relative right">
      {!isMarkAsRead && (
        <Popup
          content="Mark as read"
          position="bottom center"
          trigger={
            <Icon
              className="mr5"
              color="teal"
              disabled={inAction === "markAsRead"}
              link={!inAction}
              loading={inAction === "markAsRead"}
              name={
                inAction === "markAsRead"
                  ? "sync alternate"
                  : "envelope open outline"
              }
              onClick={onAction("markAsRead")}
              size="tiny"
            />
          }
        />
      )}
      <Popup
        content={
          <Button
            basic
            className="delete-notification-btn skejule m0"
            data-btn="is-popup-notification"
            disabled={inAction === "delete"}
            loading={inAction === "delete"}
            onClick={onAction("delete")}
          >
            Confirm Delete
          </Button>
        }
        data-popup="is-popup-notification"
        hoverable
        on={["hover", "click"]}
        position="bottom center"
        trigger={
          <Icon
            color="red"
            disabled={inAction === "delete"}
            link={!inAction}
            name="trash alternate"
            size="tiny"
          />
        }
      />
    </div>
  );
};
export default NotificationsActions;
