import { put, select, call, all, takeLatest } from "redux-saga/effects";
import FeedbackFormApi from "../api/FeedbackForm";
import { Auth, FeedbackForm } from "../actions";
// Type
import type { Action } from "deox";
import type { tAPI } from "../api/API";
// Sagas for Feedback Form
function* doFeedbackFormSend(
  action: Action<
    string,
    {
      body: object;
      isPostFile?: boolean;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  try {
    yield put(FeedbackForm.feedbackFormSendRequest());
    window.logger.log("doFeedbackFormSend", action);
    const {
      body,
      isPostFile: isUpload = false,
      callbackSuccess = undefined,
    } = action.payload;
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to sent feedback form
    yield call(FeedbackFormApi.SendFeedbackForm, api, body, isUpload, {
      token,
    });
    yield put(FeedbackForm.feedbackFormSendSuccess());
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doFeedbackFormSend", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(FeedbackForm.feedbackFormSendFailure());
    const { message } = errors || "";
    const { callbackError = undefined } = action.payload;
    if (callbackError) {
      callbackError(message);
    }
  }
}
export default function* feedbackFormSagas(): Generator<any, any, any> {
  yield all([takeLatest(FeedbackForm.FEEDBACK_FORM_SEND, doFeedbackFormSend)]);
}
