import { put, select, call, all, takeLatest } from "redux-saga/effects";
import FileCategoriesApi from "../api/FileCategories";
import { Auth, FileCategories } from "../actions/";
// Type
import type { Action } from "deox";
import type { tAPI } from "../api/API";
//Sagas for File Categories
function* doFileCategoriesFetchFileCategories(
  action: Action<
    string,
    {
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(FileCategories.fileCategoriesFetchFileCategoriesRequest());
  window.logger.log("doFileCategoriesFetchFileCategories", action);
  const { callbackSuccess = null, callbackError = null } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get all file categories for this company
    const { data } = yield call(FileCategoriesApi.GetFileCategories, api, {
      token,
    });
    const { categories: fileCategories } = data;
    yield put(
      FileCategories.fileCategoriesFetchFileCategoriesSuccess({
        fileCategories,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(fileCategories);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doFileCategoriesFetchFileCategories",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(FileCategories.fileCategoriesFetchFileCategoriesFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}

function* doFileCategoriesCreate(
  action: Action<
    string,
    {
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(FileCategories.fileCategoriesCreateRequest());
  window.logger.log("doFileCategoriesCreate", action);
  const { body, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to create a new file category
    const { data } = yield call(
      FileCategoriesApi.CreateFileCategory,
      api,
      body,
      { token }
    );
    const { category: fileCategory } = data;
    yield put(FileCategories.fileCategoriesCreateSuccess({ fileCategory }));
    if (callbackSuccess) {
      callbackSuccess(fileCategory);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doFileCategoriesCreate", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(FileCategories.fileCategoriesCreateFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doFileCategoriesEdit(
  action: Action<
    string,
    {
      fileCategoryId: string;
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(FileCategories.fileCategoriesEditRequest());
  window.logger.log("doFileCategoriesEdit", action);
  const {
    fileCategoryId,
    body,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to update a program for that specific programId
    const { data } = yield call(
      FileCategoriesApi.EditFileCategory,
      api,
      fileCategoryId,
      body,
      { token }
    );
    const { category: fileCategory } = data;
    yield put(FileCategories.fileCategoriesEditSuccess({ fileCategory }));
    if (callbackSuccess) {
      callbackSuccess(fileCategory);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doFileCategoriesEdit", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(FileCategories.fileCategoriesEditFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doFileCategoriesDelete(
  action: Action<
    string,
    {
      fileCategoryId: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(FileCategories.fileCategoriesDeleteRequest());
  window.logger.log("doFileCategoriesDelete", action);
  const {
    fileCategoryId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to delete a file category for that specific fileCategoryId
    yield call(FileCategoriesApi.DeleteFileCategory, api, fileCategoryId, {
      token,
    });
    yield put(FileCategories.fileCategoriesDeleteSuccess({ fileCategoryId }));
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doFileCategoriesDelete", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(FileCategories.fileCategoriesDeleteFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

export default function* fileCategoriesSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(
      FileCategories.FILE_CATEGORIES_FETCH_FILE_CATEGORIES,
      doFileCategoriesFetchFileCategories
    ),
    takeLatest(FileCategories.FILE_CATEGORIES_CREATE, doFileCategoriesCreate),
    takeLatest(FileCategories.FILE_CATEGORIES_EDIT, doFileCategoriesEdit),
    takeLatest(FileCategories.FILE_CATEGORIES_DELETE, doFileCategoriesDelete),
  ]);
}
