import { createAction } from "deox";
// Actions for Terms
// Fetch all Terms
export const TERMS_FETCH_ALL: string = "TERMS/FETCH/ALL";
export const TERMS_FETCH_ALL_REQUEST: string = "TERMS/FETCH/ALL/REQUEST";
export const TERMS_FETCH_ALL_SUCCESS: string = "TERMS/FETCH/ALL/SUCCESS";
export const TERMS_FETCH_ALL_FAILURE: string = "TERMS/FETCH/ALL/FAILURE";
export const termsFetchAll = createAction(
  TERMS_FETCH_ALL,
  (resolve) => (payload?: object) => resolve(payload)
);
export const termsFetchAllRequest = createAction(TERMS_FETCH_ALL_REQUEST);
export const termsFetchAllSuccess = createAction(
  TERMS_FETCH_ALL_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const termsFetchAllFailure = createAction(TERMS_FETCH_ALL_FAILURE);
// Create a Term
export const TERMS_CREATE: string = "TERMS/CREATE";
export const TERMS_CREATE_REQUEST: string = "TERMS/CREATE/REQUEST";
export const TERMS_CREATE_SUCCESS: string = "TERMS/CREATE/SUCCESS";
export const TERMS_CREATE_FAILURE: string = "TERMS/CREATE/FAILURE";
export const termsCreate = createAction(
  TERMS_CREATE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const termsCreateRequest = createAction(TERMS_CREATE_REQUEST);
export const termsCreateSuccess = createAction(
  TERMS_CREATE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const termsCreateFailure = createAction(TERMS_CREATE_FAILURE);
// Edit a Term
export const TERMS_EDIT: string = "TERMS/EDIT";
export const TERMS_EDIT_REQUEST: string = "TERMS/EDIT/REQUEST";
export const TERMS_EDIT_SUCCESS: string = "TERMS/EDIT/SUCCESS";
export const TERMS_EDIT_FAILURE: string = "TERMS/EDIT/FAILURE";
export const termsEdit = createAction(
  TERMS_EDIT,
  (resolve) => (payload?: object) => resolve(payload)
);
export const termsEditRequest = createAction(TERMS_EDIT_REQUEST);
export const termsEditSuccess = createAction(
  TERMS_EDIT_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const termsEditFailure = createAction(TERMS_EDIT_FAILURE);
// Delete a Term
export const TERMS_DELETE: string = "TERMS/DELETE";
export const TERMS_DELETE_REQUEST: string = "TERMS/DELETE/REQUEST";
export const TERMS_DELETE_SUCCESS: string = "TERMS/DELETE/SUCCESS";
export const TERMS_DELETE_FAILURE: string = "TERMS/DELETE/FAILURE";
export const termsDelete = createAction(
  TERMS_DELETE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const termsDeleteRequest = createAction(TERMS_DELETE_REQUEST);
export const termsDeleteSuccess = createAction(
  TERMS_DELETE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const termsDeleteFailure = createAction(TERMS_DELETE_FAILURE);
