import { createAction } from "deox";

// Actions for Programs

// Fetch all Programs
export const PROGRAMS_FETCH_PROGRAMS: string = "PROGRAMS/FETCH/PROGRAMS";
export const PROGRAMS_FETCH_PROGRAMS_REQUEST: string =
  "PROGRAMS/FETCH/PROGRAMS/REQUEST";
export const PROGRAMS_FETCH_PROGRAMS_SUCCESS: string =
  "PROGRAMS/FETCH/PROGRAMS/SUCCESS";
export const PROGRAMS_FETCH_PROGRAMS_FAILURE: string =
  "PROGRAMS/FETCH/PROGRAMS/FAILURE";
export const programsFetchPrograms = createAction(
  PROGRAMS_FETCH_PROGRAMS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const programsFetchProgramsRequest = createAction(
  PROGRAMS_FETCH_PROGRAMS_REQUEST
);
export const programsFetchProgramsSuccess = createAction(
  PROGRAMS_FETCH_PROGRAMS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const programsFetchProgramsFailure = createAction(
  PROGRAMS_FETCH_PROGRAMS_FAILURE
);
// Create a Program
export const PROGRAMS_CREATE: string = "PROGRAMS/CREATE";
export const PROGRAMS_CREATE_REQUEST: string = "PROGRAMS/CREATE/REQUEST";
export const PROGRAMS_CREATE_SUCCESS: string = "PROGRAMS/CREATE/SUCCESS";
export const PROGRAMS_CREATE_FAILURE: string = "PROGRAMS/CREATE/FAILURE";
export const programsCreate = createAction(
  PROGRAMS_CREATE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const programsCreateRequest = createAction(PROGRAMS_CREATE_REQUEST);
export const programsCreateSuccess = createAction(
  PROGRAMS_CREATE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const programsCreateFailure = createAction(PROGRAMS_CREATE_FAILURE);
// Edit a Program
export const PROGRAMS_EDIT_PROGRAM: string = "PROGRAMS/EDIT/PROGRAM";
export const PROGRAMS_EDIT_PROGRAM_REQUEST: string =
  "PROGRAMS/EDIT/PROGRAM/REQUEST";
export const PROGRAMS_EDIT_PROGRAM_SUCCESS: string =
  "PROGRAMS/EDIT/PROGRAM/SUCCESS";
export const PROGRAMS_EDIT_PROGRAM_FAILURE: string =
  "PROGRAMS/EDIT/PROGRAM/FAILURE";
export const programsEditProgram = createAction(
  PROGRAMS_EDIT_PROGRAM,
  (resolve) => (payload?: object) => resolve(payload)
);
export const programsEditProgramRequest = createAction(
  PROGRAMS_EDIT_PROGRAM_REQUEST
);
export const programsEditProgramSuccess = createAction(
  PROGRAMS_EDIT_PROGRAM_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const programsEditProgramFailure = createAction(
  PROGRAMS_EDIT_PROGRAM_FAILURE
);
// Delete a Program
export const PROGRAMS_DELETE: string = "PROGRAMS/DELETE";
export const PROGRAMS_DELETE_REQUEST: string = "PROGRAMS/DELETE/REQUEST";
export const PROGRAMS_DELETE_SUCCESS: string = "PROGRAMS/DELETE/SUCCESS";
export const PROGRAMS_DELETE_FAILURE: string = "PROGRAMS/DELETE/FAILURE";
export const programsDelete = createAction(
  PROGRAMS_DELETE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const programsDeleteRequest = createAction(PROGRAMS_DELETE_REQUEST);
export const programsDeleteSuccess = createAction(
  PROGRAMS_DELETE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const programsDeleteFailure = createAction(PROGRAMS_DELETE_FAILURE);
