// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
// Features
import * as React from "react";
import classNames from "classnames";
// Components
import { Grid } from "semantic-ui-react";
import NotificationsDate from "./NotificationsDate";
const NotificationDocuments = ({
  _id,
  readBy,
  user,
  files,
  message,
  createdAt,
  goToProfile,
}: Object): React$Element<any> => (
  <div
    className={classNames("notification", {
      new: !readBy || (readBy && readBy.length === 0),
    })}
  >
    <Grid columns={2}>
      <Grid.Row className="nopadding">
        <Grid.Column mobile={3} tablet={3} computer={3} className="">
          <i className="icono icono-document2-fill huge skejule-teal" />
        </Grid.Column>
        <Grid.Column mobile={13} tablet={13} computer={13} className="">
          <div className="message">
            {message.split(/({fileName}|{userName})/gi).map((el, index) => {
              if (el === "{userName}") {
                return (
                  <a
                    key={user._id + index}
                    style={{ cursor: "pointer" }}
                    onClick={(event: Object) => {
                      // DEBUG: We need to debugger this action, because when
                      // it's called one time and manager profile is open
                      // the action User.setUserId is called two times, because of
                      // ManagerRoute component is launched two times before route change
                      goToProfile(`/manager/profile/${user._id}`)(event);
                      setTimeout(() => {
                        goToProfile(`/manager/profile/${user._id}`)(event);
                      }, 500);
                    }}
                  >{`${user.firstName || ""} ${user.lastName || ""}`}</a>
                );
              } else if (el === "{fileName}") {
                return (
                  <span
                    key={files[0].name + index}
                    title={files[0].name}
                    className="skejule-blue"
                  >
                    {files[0].name.length > 25
                      ? files[0].name.slice(0, 25) + " ..."
                      : files[0].name}
                  </span>
                );
              }
              return el;
            })}
          </div>
          <NotificationsDate
            createdAt={createdAt}
            isMarkAsRead={!!readBy && readBy?.length > 0}
            notificationId={_id}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
);

export default NotificationDocuments;
