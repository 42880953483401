import { put, select, call, all, takeLatest } from "redux-saga/effects";
import EnrollmentsApi from "../api/Enrollments";
import { Auth, Enrollments } from "../actions/";
// Type
import type { Action } from "deox";
import type { tAPI } from "../api/API";
//Sagas for Enrollments
// STUDENTS ENROLLMENTS SAGAS
function* doStudentEnrollmentsFetchEnrollments(
  action: Action<
    string,
    {
      programs: string[];
      cohorts: string[];
      students: string[];
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Enrollments.studentEnrollmentsFetchRequest());
  window.logger.log("doStudentEnrollmentsFetchEnrollments", action);
  const {
    programs = [],
    cohorts = [],
    students = [],
    callbackSuccess = null,
    callbackError = null,
  } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get all student enrollments for this student
    const { data } = yield call(
      EnrollmentsApi.GetStudentEnrollment,
      api,
      programs,
      cohorts,
      students,
      { token }
    );
    const { enrollments: studentEnrollments } = data;
    yield put(
      Enrollments.studentEnrollmentsFetchSuccess({ studentEnrollments })
    );
    if (callbackSuccess) {
      callbackSuccess(studentEnrollments);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doStudentEnrollmentsFetchEnrollments",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Enrollments.studentEnrollmentsFetchFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}

function* doStudentEnrollmentCreateEnrollment(
  action: Action<
    string,
    {
      student: object;
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Enrollments.studentEnrollmentCreateRequest());
  window.logger.log("doStudentEnrollmentCreateEnrollment", action);
  const {
    student,
    body,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to create a new student enrollment
    const { data } = yield call(
      EnrollmentsApi.CreateStudentEnrollment,
      api,
      body,
      { token }
    );
    const { enrollment: studentEnrollment } = data;
    const program: object = yield select(
      ({ programs: { programs } }) =>
        // @ts-ignore
        programs.filter(({ _id: programId }) => programId === body.program)[0]
    );
    const cohort: object = yield select(
      ({ cohorts: { cohorts } }) =>
        // @ts-ignore
        cohorts.filter(({ _id: cohortId }) => cohortId === body.cohort)[0]
    );
    yield put(
      Enrollments.studentEnrollmentCreateSuccess({
        studentEnrollment: {
          ...studentEnrollment,
          program,
          cohort,
          student,
        },
      })
    );
    if (callbackSuccess) {
      callbackSuccess(studentEnrollment);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doStudentEnrollmentCreateEnrollment",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Enrollments.studentEnrollmentCreateFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doStudentEnrollmentEditEnrollment(
  action: Action<
    string,
    {
      studentEnrollmentId: string;
      student: object;
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Enrollments.studentEnrollmentEditRequest());
  window.logger.log("doStudentEnrollmentEditEnrollment", action);
  const {
    studentEnrollmentId,
    student,
    body,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to update a student enrollment for that specific studentEnrollmentId
    const { data } = yield call(
      EnrollmentsApi.EditStudentEnrollment,
      api,
      studentEnrollmentId,
      body,
      { token }
    );
    let { enrollment: studentEnrollment } = data;
    const program: object = yield select(
      ({ programs: { programs } }) =>
        // @ts-ignore
        programs.filter(({ _id: programId }) => programId === body.program)[0]
    );
    const cohort: object = yield select(
      ({ cohorts: { cohorts } }) =>
        // @ts-ignore
        cohorts.filter(({ _id: cohortId }) => cohortId === body.cohort)[0]
    );
    yield put(
      Enrollments.studentEnrollmentEditSuccess({
        studentEnrollment: {
          ...studentEnrollment,
          program,
          cohort,
          student,
        },
      })
    );
    if (callbackSuccess) {
      callbackSuccess(studentEnrollment);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doStudentEnrollmentEditEnrollment",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Enrollments.studentEnrollmentEditFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}
function* doStudentEnrollmentDelete(
  action: Action<
    string,
    {
      studentEnrollmentId: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any, details: any) => void;
    }
  >
) {
  yield put(Enrollments.studentEnrollmentDeleteRequest());
  window.logger.log("doStudentEnrollmentDelete", action);
  const {
    studentEnrollmentId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to delete a student enrollment for that specific studentEnrollmentId
    yield call(
      EnrollmentsApi.DeleteStudentEnrollment,
      api,
      studentEnrollmentId,
      { token }
    );
    yield put(
      Enrollments.studentEnrollmentDeleteSuccess({ studentEnrollmentId })
    );
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doStudentEnrollmentDelete", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Enrollments.studentEnrollmentDeleteFailure());
    const { message, details } = errors || "";
    if (callbackError) {
      callbackError(message, details);
    }
  }
}

export default function* enrollmentsSagas(): Generator<any, any, any> {
  yield all([
    // Students Enrollments Sagas
    takeLatest(
      Enrollments.STUDENTS_ENROLLMENTS_FETCH,
      doStudentEnrollmentsFetchEnrollments
    ),
    takeLatest(
      Enrollments.STUDENT_ENROLLMENT_CREATE,
      doStudentEnrollmentCreateEnrollment
    ),
    takeLatest(
      Enrollments.STUDENT_ENROLLMENT_EDIT,
      doStudentEnrollmentEditEnrollment
    ),
    takeLatest(
      Enrollments.STUDENT_ENROLLMENT_DELETE,
      doStudentEnrollmentDelete
    ),
  ]);
}
