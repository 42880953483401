import type { Config, tAPI } from "./API";
// API Cohorts static class

const apiBaseCohorts = "/cohorts";
export default class Cohorts {
  // Get a list of cohorts
  static GetCohorts(api: tAPI, config?: Config) {
    return api.GET(`${apiBaseCohorts}`, config);
  }
  // Create a Cohort
  static CreateCohort(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBaseCohorts}`, data, config);
  }
  // Edit a Cohort
  static EditCohort(
    api: tAPI,
    cohortId: string,
    data: object,
    config?: Config
  ) {
    return api.PATCH(`${apiBaseCohorts}/${cohortId}`, data, config);
  }
  // Delete a Cohort
  static DeleteCohort(api: tAPI, cohortId: string, config?: Config) {
    return api.DELETE(`${apiBaseCohorts}/${cohortId}`, config);
  }
}
