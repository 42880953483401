// Import Features
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import loadable from "@loadable/component";
import * as Cookies from "js-cookie";
// Import Components
import Loader from "../shared/Loader";
import LoadedRoute from "../shared/LoadedRoute";

// PERMISSIONS
import { onCheckReadMyCompaniesPermissions } from "../company/CompaniesPermissions";
import { onCheckReadMyProfileUsersPermissions } from "../user-manager/UsersPermissions";
import {
  onCheckReadMyUsersPortfolioPermissions,
  onCheckSubmitMyUsersPortfolioPermissions,
} from "../user-portfolio/UserPortfolioPermissions";
import {
  onCheckInstitutionsPermissions,
  onCheckReadInstitutionsPermissions,
  onCheckReadMyInstitutionsPermissions,
} from "../institutions/InstitutionsPermissions";
import {
  onCheckReadAllUsersFilesPermissions,
  onCheckReadUsersRoleFiles,
  onCheckMyCompaniesFilesPermissions,
  onCheckCreateMyCompaniesFilesPermissions,
  onCheckReadInstitutionsFilesPermissions,
  onCheckMyInstitutionsFilesPermissions,
  onCheckCreateMyInstitutionsFilesPermissions,
  onCheckInstitutionsFilesPermissions,
  onCheckReadMyInstitutionsFilesPermissions,
} from "../files/FilesPermissions";
import { onCheckImportDataPermissions } from "../import/ImportDataPermissions";
import {
  onCheckRotationsPermissions,
  onCheckReadRotationsPermissions,
} from "../rotations/RotationsPermissions";
import {
  onCheckCaseLogsPermissions,
  onCheckReadCaseLogsPermissions,
  onCheckSubmitCaseLogsPermissions,
} from "../case-logs/CaseLogsPermissions";
import { onCheckEvaluationsPermissions } from "../evaluations/EvaluationsPermissions";
import {
  onCheckEvaluationSchedulersPermissions,
  onCheckReadEvaluationSchedulersPermissions,
  onCheckCreateEvaluationSchedulersPermissions,
} from "../evaluations-schedulers/EvaluationSchedulersPermissions";
import { onCheckEvaluationTemplatesPermissions } from "../evaluations-templates/EvaluationTemplatesPermissions";
import { onCheckAllReportsPermissions } from "../reports/ReportsPermissions";
// />
// Import basic Styles - it is need for App working fine.
// TODO: Split basic styles into different css files.
import "../home/Home.scss";
import "../files/shared/file-categories/FileCategories.scss";
// Dynamic Import (Code Splitting):
const fallback = (
  <Loader className="loading-component" textLoading="Loading ..." />
);
const HomeContainer = loadable(() => import("../home/HomeContainer"), {
  fallback,
});
const MyProfileContainer = loadable(
  () => import("../my-profile/MyProfileContainer"),
  {
    fallback,
  }
);
const UserProfileContainer = loadable(
  () => import("../user-profile/UserProfileContainer"),
  {
    fallback,
  }
);
const UserFilesContainer = loadable(
  () => import("../user-files/UserFilesContainer"),
  {
    fallback,
  }
);
const UserPortfolioContainer = loadable(
  () => import("../user-portfolio/UserPortfolioContainer"),
  {
    fallback,
  }
);
const CompanyContainer = loadable(() => import("../company/CompanyContainer"), {
  fallback,
});
const InstitutionsContainer = loadable(
  () => import("../institutions/InstitutionsContainer"),
  {
    fallback,
  }
);
const RotationsContainer = loadable(
  () => import("../rotations/RotationsContainer"),
  {
    fallback,
  }
);
const EvaluationsContainer = loadable(
  () => import("../evaluations/EvaluationsContainer"),
  {
    fallback,
  }
);
const EvaluationsTemplatesContainer = loadable(
  () => import("../evaluations-templates/EvaluationsTemplatesContainer"),
  {
    fallback,
  }
);
const CaseLogsContainer = loadable(
  () => import("../case-logs/CaseLogsContainer"),
  {
    fallback,
  }
);
const ReportsContainer = loadable(() => import("../reports/ReportsContainer"), {
  fallback,
});
const AccountSettingsContainer = loadable(
  () => import("../account-settings/AccountSettingsContainer"),
  {
    fallback,
  }
);
const AdminPortalContainer = loadable(
  () => import("../admin-portal/AdminPortalContainer"),
  {
    fallback,
  }
);
///////////////////////////////////
let lastComponentLoaded: Object = {};
const saveComponent = (stateProps: Object) => {
  lastComponentLoaded = stateProps;
  return stateProps;
};
const mapStateToProps = (state: Object, ownProps: Object) => {
  const { location } = ownProps;
  const {
    routerBeforeTransitions,
    config,
    auth: {
      updatingProfile,
      fetchingMyFiles,
      isAuthenticated,
      user,
      files,
      role,
      myInstitution,
    },
  } = state;
  const authCookie = Cookies.getJSON("authCookie");
  const setImg = (_imgOrUrl_: string, updatedAt: string): string => {
    if (_imgOrUrl_.indexOf("http") !== -1) {
      return _imgOrUrl_;
    }
    return [config.baseImg, _imgOrUrl_, `&updatedAt=${updatedAt}`].join("");
  };
  const defaultHomeContainer = () => {
    return saveComponent({
      component: HomeContainer,
      restProps: {
        updatingProfile,
        fetchingMyFiles,
        config,
        user,
        files,
        myInstitution,
        authRoleType: isAuthenticated ? role.type : null,
        authCookie: authCookie && !isAuthenticated ? authCookie : null,
        setImg,
        canReadMyPortfolio:
          isAuthenticated &&
          (role.type === "students" || role.type === "alumnis") &&
          onCheckReadMyUsersPortfolioPermissions(role.permissions),
        canSubmitPortfolioTestimonials:
          isAuthenticated &&
          role.type !== "students" &&
          onCheckSubmitMyUsersPortfolioPermissions(role.permissions),
        canReadMyCompany:
          isAuthenticated &&
          onCheckReadMyCompaniesPermissions(role.permissions) &&
          user.company,
        canReadMyFiles:
          isAuthenticated &&
          onCheckReadUsersRoleFiles({ type: "myprofile" }, role.permissions),
        canReadMyCompaniesFiles:
          isAuthenticated &&
          onCheckMyCompaniesFilesPermissions(role.permissions),
        canReadMyInstitutionsFiles:
          isAuthenticated &&
          onCheckReadMyInstitutionsFilesPermissions(role.permissions),
        canReadInstitutionsFiles:
          isAuthenticated &&
          onCheckReadInstitutionsFilesPermissions(role.permissions),
        canReadStudentsFiles:
          isAuthenticated &&
          onCheckReadUsersRoleFiles({ type: "students" }, role.permissions),
        canReadUsersFiles:
          isAuthenticated &&
          onCheckReadAllUsersFilesPermissions(role.permissions),
        canReadRotations:
          isAuthenticated && onCheckReadRotationsPermissions(role.permissions),
        canReadMyInstitution:
          isAuthenticated &&
          onCheckReadMyInstitutionsPermissions(role.permissions),
        canReadCaseLogs:
          isAuthenticated && onCheckReadCaseLogsPermissions(role.permissions),
        canSubmitCaseLogs:
          isAuthenticated && onCheckSubmitCaseLogsPermissions(role.permissions),
        canReadEvaluations:
          isAuthenticated && onCheckEvaluationsPermissions(role.permissions),
      },
      isAllowedRoute:
        config.companyDomain.length > 0 && (isAuthenticated || authCookie),
    });
  };
  if (
    isAuthenticated &&
    location.pathname &&
    location.pathname.indexOf("/manager") === -1
  ) {
    let pathname = location.pathname;
    //Check routes clinics
    if (
      pathname !== "/clinics/view/" &&
      pathname.indexOf("/clinics/view/") !== -1 &&
      pathname.indexOf("/documents") === -1
    ) {
      pathname = "/clinics/view";
    } else if (
      pathname !== "/clinics/view/" &&
      pathname.indexOf("/clinics/view/") !== -1 &&
      pathname.indexOf("/documents") !== -1
    ) {
      pathname = "/clinics/view/documents";
    } else if (
      pathname !== "/admin/clinics-settings" &&
      pathname !== "/admin/clinics-settings/" &&
      pathname.indexOf("/clinics") !== -1
    ) {
      pathname = "/clinics";
    }
    //Check routes rotations
    if (
      pathname !== "/account-settings/rotations" &&
      pathname !== "/account-settings/rotations/" &&
      pathname !== "/admin/rotations-settings" &&
      pathname !== "/admin/rotations-settings/" &&
      pathname.indexOf("/rotations") !== -1
    ) {
      pathname = "/rotations";
    }
    //Check routes evaluations and evaluations templates
    if (
      pathname !== "/account-settings/evaluations" &&
      pathname !== "/account-settings/evaluations/" &&
      pathname !== "/admin/evaluations-settings" &&
      pathname !== "/admin/evaluations-settings/"
    ) {
      if (pathname.indexOf("/evaluations/templates") !== -1) {
        pathname = "/evaluations/templates";
      } else if (pathname === "/evaluations/schedulers") {
        pathname = "/evaluations";
      } else if (pathname.indexOf("/evaluations/schedulers/") !== -1) {
        pathname = "/evaluations/schedulers";
      } else if (pathname.indexOf("/evaluations") !== -1) {
        pathname = "/evaluations";
      }
    }
    //Check routes case-logs
    if (
      pathname !== "/account-settings/case-logs" &&
      pathname !== "/account-settings/case-logs/" &&
      pathname !== "/admin/case-logs-settings" &&
      pathname !== "/admin/case-logs-settings/" &&
      pathname.indexOf("/case-logs") !== -1
    ) {
      pathname = "/case-logs";
    }
    //Check routes reports
    if (pathname.indexOf("/reports") !== -1) {
      pathname = "/reports";
    }
    //Check routes account-settings
    if (pathname.indexOf("/account-settings") !== -1) {
      pathname = "/account-settings";
    }
    //Check routes admin
    if (pathname.indexOf("/admin") !== -1) {
      pathname = "/admin";
    }
    switch (pathname) {
      case "/my-profile":
      case "/my-profile/change-password":
        return saveComponent({
          component: MyProfileContainer,
          restProps: {
            UserProfileContainer,
            isMyProfile: true,
            routerBeforeTransitions,
            myProfile: user,
            authPermissions: isAuthenticated ? role.permissions : null,
            authRoleType: isAuthenticated ? role.type : null,
            authRoleApiName: isAuthenticated ? role.apiName : null,
            hasMyInstitution: myInstitution && myInstitution.loaded && true,
            setImg,
          },
          isAllowedRoute: config.companyDomain.length > 0 && isAuthenticated,
        });
      case "/my-profile/documents":
      case "/my-profile/documents/upload":
        if (
          isAuthenticated &&
          onCheckReadMyProfileUsersPermissions(role.permissions)
        ) {
          return saveComponent({
            component: MyProfileContainer,
            restProps: {
              UserFilesContainer,
              isMyProfile: true,
              routerBeforeTransitions,
              myProfile: user,
              authUserId: isAuthenticated ? user._id : null,
              authPermissions: role.permissions,
              authRoleType: role.type,
              setImg,
            },
            isAllowedRoute:
              config.companyDomain.length > 0 &&
              isAuthenticated &&
              onCheckReadMyProfileUsersPermissions(role.permissions),
          });
        }
        return defaultHomeContainer();
      case "/my-profile/portfolio":
        if (isAuthenticated) {
          return saveComponent({
            component: MyProfileContainer,
            restProps: {
              UserPortfolioContainer,
              isMyProfile: true,
              routerBeforeTransitions,
              myProfile: user,
              authPermissions: role.permissions,
              authRoleType: role.type,
              setImg,
            },
            isAllowedRoute:
              config.companyDomain.length > 0 &&
              isAuthenticated &&
              onCheckReadMyProfileUsersPermissions(role.permissions),
          });
        }
        return defaultHomeContainer();
      case "/my-school":
        if (
          isAuthenticated &&
          onCheckReadMyCompaniesPermissions(role.permissions) &&
          user.company
        ) {
          return saveComponent({
            component: CompanyContainer,
            restProps: {
              authPermissions: role.permissions,
              authRoleType: isAuthenticated ? role.type : null,
              routerBeforeTransitions,
              setImg,
            },
            isAllowedRoute:
              config.companyDomain.length > 0 &&
              isAuthenticated &&
              onCheckReadMyCompaniesPermissions(role.permissions) &&
              user.company &&
              true,
          });
        }
        return defaultHomeContainer();
      case "/my-school/documents":
        if (
          isAuthenticated &&
          onCheckMyCompaniesFilesPermissions(role.permissions) &&
          user.company
        ) {
          return saveComponent({
            component: CompanyContainer,
            restProps: {
              authPermissions: role.permissions,
              authRoleType: isAuthenticated ? role.type : null,
              routerBeforeTransitions,
              setImg,
            },
            isAllowedRoute:
              config.companyDomain.length > 0 &&
              isAuthenticated &&
              onCheckMyCompaniesFilesPermissions(role.permissions) &&
              user.company &&
              true,
          });
        }
        return defaultHomeContainer();
      case "/my-school/documents/upload":
        if (
          isAuthenticated &&
          onCheckCreateMyCompaniesFilesPermissions(role.permissions) &&
          user.company
        ) {
          return saveComponent({
            component: CompanyContainer,
            restProps: {
              authPermissions: role.permissions,
              authRoleType: isAuthenticated ? role.type : null,
              routerBeforeTransitions,
              setImg,
            },
            isAllowedRoute:
              config.companyDomain.length > 0 &&
              isAuthenticated &&
              onCheckCreateMyCompaniesFilesPermissions(role.permissions) &&
              user.company &&
              true,
          });
        }
        return defaultHomeContainer();
      case "/clinics":
        if (
          isAuthenticated &&
          onCheckInstitutionsPermissions(role.permissions)
        ) {
          return saveComponent({
            component: InstitutionsContainer,
            restProps: {
              authPermissions: role.permissions,
              authRoleType: isAuthenticated ? role.type : null,
              authRoleApiName: isAuthenticated ? role.apiName : null,
              routerBeforeTransitions,
              setImg,
            },
            isAllowedRoute:
              config.companyDomain.length > 0 &&
              isAuthenticated &&
              onCheckInstitutionsPermissions(role.permissions),
          });
        }
        return defaultHomeContainer();
      case "/clinics/view":
        if (
          isAuthenticated &&
          (onCheckReadInstitutionsPermissions(role.permissions) ||
            onCheckReadMyInstitutionsPermissions(role.permissions))
        ) {
          return saveComponent({
            component: InstitutionsContainer,
            restProps: {
              authPermissions: role.permissions,
              authRoleType: isAuthenticated ? role.type : null,
              authRoleApiName: isAuthenticated ? role.apiName : null,
              routerBeforeTransitions,
              setImg,
            },
            isAllowedRoute:
              config.companyDomain.length > 0 &&
              isAuthenticated &&
              (onCheckReadInstitutionsPermissions(role.permissions) ||
                onCheckReadMyInstitutionsPermissions(role.permissions)),
          });
        }
        return defaultHomeContainer();
      case "/clinics/view/documents":
        if (
          isAuthenticated &&
          (onCheckInstitutionsFilesPermissions(role.permissions) ||
            onCheckMyInstitutionsFilesPermissions(role.permissions))
        ) {
          return saveComponent({
            component: InstitutionsContainer,
            restProps: {
              authPermissions: role.permissions,
              authRoleType: isAuthenticated ? role.type : null,
              authRoleApiName: isAuthenticated ? role.apiName : null,
              routerBeforeTransitions,
              setImg,
            },
            isAllowedRoute:
              config.companyDomain.length > 0 &&
              isAuthenticated &&
              (onCheckInstitutionsFilesPermissions(role.permissions) ||
                onCheckMyInstitutionsFilesPermissions(role.permissions)),
          });
        }
        return defaultHomeContainer();
      case "/my-clinic":
        if (
          isAuthenticated &&
          onCheckReadMyInstitutionsPermissions(role.permissions) &&
          (user.institution || user.activeRotation)
        ) {
          return saveComponent({
            component: InstitutionsContainer,
            restProps: {
              authPermissions: role.permissions,
              authRoleType: isAuthenticated ? role.type : null,
              authRoleApiName: isAuthenticated ? role.apiName : null,
              routerBeforeTransitions,
              setImg,
            },
            isAllowedRoute:
              config.companyDomain.length > 0 &&
              isAuthenticated &&
              onCheckReadMyInstitutionsPermissions(role.permissions) &&
              (user.institution || user.activeRotation) &&
              true,
          });
        }
        return defaultHomeContainer();
      case "/my-clinic/documents":
        if (
          isAuthenticated &&
          onCheckMyInstitutionsFilesPermissions(role.permissions) &&
          (user.institution || user.activeRotation)
        ) {
          return saveComponent({
            component: InstitutionsContainer,
            restProps: {
              authPermissions: role.permissions,
              authRoleType: isAuthenticated ? role.type : null,
              authRoleApiName: isAuthenticated ? role.apiName : null,
              routerBeforeTransitions,
              setImg,
            },
            isAllowedRoute:
              config.companyDomain.length > 0 &&
              isAuthenticated &&
              onCheckMyInstitutionsFilesPermissions(role.permissions) &&
              (user.institution || user.activeRotation) &&
              true,
          });
        }
        return defaultHomeContainer();
      case "/my-clinic/documents/upload":
        if (
          isAuthenticated &&
          onCheckCreateMyInstitutionsFilesPermissions(role.permissions) &&
          (user.institution || user.activeRotation)
        ) {
          return saveComponent({
            component: InstitutionsContainer,
            restProps: {
              authPermissions: role.permissions,
              authRoleType: isAuthenticated ? role.type : null,
              routerBeforeTransitions,
              setImg,
            },
            isAllowedRoute:
              config.companyDomain.length > 0 &&
              isAuthenticated &&
              onCheckCreateMyInstitutionsFilesPermissions(role.permissions) &&
              (user.institution || user.activeRotation) &&
              true,
          });
        }
        return defaultHomeContainer();
      case "/rotations":
        if (
          isAuthenticated &&
          (onCheckRotationsPermissions(role.permissions) ||
            onCheckImportDataPermissions(role.permissions))
        ) {
          return saveComponent({
            component: RotationsContainer,
            restProps: {
              authPermissions: role.permissions,
              authRoleType: isAuthenticated ? role.type : null,
              userId: isAuthenticated ? user._id : null,
              institutionId:
                isAuthenticated && user.institution
                  ? user.institution._id
                  : null,
              setImg,
            },
            isAllowedRoute:
              config.companyDomain.length > 0 &&
              isAuthenticated &&
              (onCheckRotationsPermissions(role.permissions) ||
                onCheckImportDataPermissions(role.permissions)),
          });
        }
        return defaultHomeContainer();
      case "/case-logs":
        if (isAuthenticated && onCheckCaseLogsPermissions(role.permissions)) {
          return saveComponent({
            component: CaseLogsContainer,
            restProps: {
              authPermissions: role.permissions,
              authRoleType: isAuthenticated ? role.type : null,
              hasActiveRotations:
                isAuthenticated && user.activeRotation ? true : false,
              institutionId:
                isAuthenticated && user.institution
                  ? user.institution._id
                  : null,
              myInstitution,
              studentsInstitutions:
                isAuthenticated && user.rotations && user.rotations.length > 0
                  ? user.rotations
                      .filter(({ status }) => status === "incourse")
                      .map(({ institutions }) => institutions)
                      .reduce(
                        (ac, institutions) => [...ac, ...institutions],
                        []
                      )
                  : [],
              setImg,
              userId: isAuthenticated ? user._id : null,
            },
            isAllowedRoute:
              config.companyDomain.length > 0 &&
              isAuthenticated &&
              onCheckCaseLogsPermissions(role.permissions),
          });
        }
        return defaultHomeContainer();
      case "/evaluations":
        let isAllowedEvaluationsRoute = false;
        if (
          isAuthenticated &&
          pathname === "/evaluations/schedulers" &&
          onCheckReadEvaluationSchedulersPermissions(role.permissions)
        ) {
          isAllowedEvaluationsRoute = true;
        } else if (
          isAuthenticated &&
          (onCheckEvaluationsPermissions(role.permissions) ||
            onCheckEvaluationSchedulersPermissions(role.permissions) ||
            onCheckEvaluationTemplatesPermissions(role.permissions))
        ) {
          isAllowedEvaluationsRoute = true;
        }
        if (isAllowedEvaluationsRoute) {
          return saveComponent({
            component: EvaluationsContainer,
            restProps: {
              isAllowedEvaluationsRoute,
              authPermissions: role.permissions,
              authRoleType: isAuthenticated ? role.type : null,
              userId: isAuthenticated ? user._id : null,
              userProfileImageOriginalSrc:
                isAuthenticated && user.profileImageOriginal
                  ? [
                      config.baseImg,
                      user.profileImageOriginal,
                      `&updatedAt=${user.updatedAt}`,
                    ].join("")
                  : null,
              institutionId:
                isAuthenticated && user.institution
                  ? user.institution._id
                  : null,
              setImg,
            },
            isAllowedRoute:
              config.companyDomain.length > 0 &&
              isAuthenticated &&
              isAllowedEvaluationsRoute,
          });
        }
        return defaultHomeContainer();
      case "/evaluations/schedulers":
        if (
          isAuthenticated &&
          onCheckCreateEvaluationSchedulersPermissions(role.permissions)
        ) {
          return saveComponent({
            component: EvaluationsContainer,
            restProps: {
              isAllowedEvaluationsRoute: true,
              authPermissions: role.permissions,
              setImg,
            },
            isAllowedRoute:
              config.companyDomain.length > 0 &&
              isAuthenticated &&
              onCheckCreateEvaluationSchedulersPermissions(role.permissions),
          });
        }
        return defaultHomeContainer();
      case "/evaluations/templates":
        if (
          isAuthenticated &&
          onCheckEvaluationTemplatesPermissions(role.permissions)
        ) {
          return saveComponent({
            component: EvaluationsTemplatesContainer,
            restProps: {
              authPermissions: role.permissions,
              setImg,
            },
            isAllowedRoute:
              config.companyDomain.length > 0 &&
              isAuthenticated &&
              onCheckEvaluationTemplatesPermissions(role.permissions),
          });
        }
        return defaultHomeContainer();
      case "/reports":
        if (isAuthenticated && onCheckAllReportsPermissions(role.permissions)) {
          return saveComponent({
            component: ReportsContainer,
            restProps: {
              authPermissions: role.permissions,
              setPublicUrl: (publicToken: string) =>
                `${config.baseDomain}${config.port ? ":3000" : ""}/public/${
                  config.companyDomain
                }/metric-pages/${publicToken}`,
              setImg,
            },
            isAllowedRoute:
              config.companyDomain.length > 0 &&
              isAuthenticated &&
              onCheckAllReportsPermissions(role.permissions),
          });
        }
        return defaultHomeContainer();
      case "/account-settings":
        if (isAuthenticated) {
          return saveComponent({
            component: AccountSettingsContainer,
            restProps: {
              authPermissions: role.permissions,
              authRoleType: isAuthenticated ? role.type : null,
              setImg,
            },
            isAllowedRoute: config.companyDomain.length > 0 && isAuthenticated,
          });
        }
        return defaultHomeContainer();
      case "/admin":
        if (isAuthenticated) {
          return saveComponent({
            component: AdminPortalContainer,
            restProps: {
              authPermissions: role.permissions,
              authRoleType: isAuthenticated ? role.type : null,
              setImg,
            },
            isAllowedRoute: config.companyDomain.length > 0 && isAuthenticated,
          });
        }
        return defaultHomeContainer();
      default:
        // case '/home' ||  case '/'
        return defaultHomeContainer();
    }
  }
  if (!lastComponentLoaded.component) {
    return defaultHomeContainer();
  }
  return lastComponentLoaded;
};

const RouteLoadedCompoundContainer: React.ComponentType<any> = withRouter(
  connect(mapStateToProps)(LoadedRoute)
);

export default RouteLoadedCompoundContainer;
