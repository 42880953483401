// Import Features
import * as React from "react";
import { Modal, Loader as LoaderSemantic } from "semantic-ui-react";
// Component generic while loading some info.
type Props = {
  className: string;
  textLoading: string;
};
const Loader = ({ className, textLoading }: React.PropsWithChildren<Props>) => (
  <div id="main" className={className}>
    <Modal open={true} basic dimmer="blurring" size="small">
      <Modal.Content>
        <LoaderSemantic
          style={{ left: "-30px" }}
          size="massive"
          active
          inline="centered"
        />
        <h3 style={{ textAlign: "center" }}>{textLoading}</h3>
      </Modal.Content>
    </Modal>
  </div>
);
export default Loader;
