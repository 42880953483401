// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
// Features
import * as React from "react";
import classNames from "classnames";
import { parseName, parseApiName } from "../../../utils/index";
// Components
import { Grid } from "semantic-ui-react";
import NotificationsDate from "./NotificationsDate";
const NotificationCustomFields = ({
  _id,
  readBy,
  customField,
  user,
  message,
  createdAt,
  goToProfile,
}: Object): React$Element<any> => (
  <div
    className={classNames("notification", {
      new: !readBy || (readBy && readBy.length === 0),
    })}
  >
    <Grid columns={2}>
      <Grid.Row className="nopadding">
        <Grid.Column mobile={3} tablet={3} computer={3} className="">
          <i className="icono icono-clipboard-fill huge skejule-teal" />
        </Grid.Column>
        <Grid.Column mobile={13} tablet={13} computer={13} className="">
          <div className="message">
            {message
              .split(/({userName}|{dataModel}|{customFieldName})/gi)
              .map((el, index) => {
                if (el === "{userName}") {
                  return (
                    <a
                      key={user._id + index}
                      style={{ cursor: "pointer" }}
                      onClick={(event: Object) => {
                        // DEBUG: We need to debugger this action, because when
                        // it's called one time and manager profile is open
                        // the action User.setUserId is called two times, because of
                        // ManagerRoute component is launched two times before route change
                        goToProfile(`/manager/profile/${user._id}`)(event);
                        setTimeout(() => {
                          goToProfile(`/manager/profile/${user._id}`)(event);
                        }, 500);
                      }}
                    >{`${user.firstName || ""} ${user.lastName || ""}`}</a>
                  );
                } else if (el === "{dataModel}") {
                  return (
                    <a
                      key={customField._id + index}
                      style={{ cursor: "pointer" }}
                      onClick={goToProfile(`/admin/custom-fields`, {
                        namePage: "Admin Portal",
                      })}
                    >
                      {parseApiName(parseName(customField.model))}
                    </a>
                  );
                } else if (el === "{customFieldName}") {
                  return (
                    <span
                      key={customField._id + index}
                      title={customField.name}
                      className="skejule-blue"
                    >
                      {customField.name.length > 25
                        ? customField.name.slice(0, 25) + " ..."
                        : customField.name}
                    </span>
                  );
                }
                return el;
              })}
          </div>
          <NotificationsDate
            createdAt={createdAt}
            isMarkAsRead={!!readBy && readBy?.length > 0}
            notificationId={_id}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
);

export default NotificationCustomFields;
