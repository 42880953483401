// Features
import * as React from "react";
import { Field } from "redux-form";
// Components
import {
  Button,
  Confirm,
  Container,
  Dropdown,
  Form,
  Grid,
  Message,
  Modal,
} from "semantic-ui-react";
//@ts-ignore
import Dropzone from "react-dropzone";
// Types
import type {
  FeedbackFormViewProps,
  RenderDropDownFieldProps,
  RenderInputFieldProps,
} from "./_FeedbackForm.types";
// Feedback Form View:
const renderDropDownField: React.ComponentType<RenderDropDownFieldProps> = ({
  input,
  onChangePriority,
}) => (
  <Form.Field>
    <Dropdown
      selectOnBlur={false}
      value={input.value}
      onChange={onChangePriority(input.onChange)}
      placeholder="Priority ..."
      selection
      options={[
        {
          key: "high",
          value: "high",
          text: "High",
        },
        {
          key: "medium",
          value: "medium",
          text: "Medium",
        },
        {
          key: "low",
          value: "low",
          text: "Low",
        },
      ]}
    />
  </Form.Field>
);
const renderInputField: React.ComponentType<RenderInputFieldProps> = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <Form.Field>
    {type === "textArea" ? (
      <Form.TextArea
        {...input}
        className="fullwidth"
        error={touched && input.value.length > 0 && error && true}
        maxLength="500"
        placeholder={placeholder}
        rows={8}
      />
    ) : input?.value?.name ? (
      <Form.Input
        value={input.value && input.value.name}
        icon={
          input?.value?.name && (
            <i
              style={{ cursor: "pointer" }}
              className="icono icono-trash-fill skejule-teal"
              onClick={() => input.onChange(null)}
            />
          )
        }
        fluid
        placeholder={placeholder}
        type={"text"}
      />
    ) : (
      <Dropzone
        disabled={input?.value?.name && true}
        accept="image/*"
        maxSize={2 * 1024 * 1024}
        multiple={false}
        className="dropzone"
        onDrop={(
          filesToUpload: File[],
          filesToRejected: File[],
          error: any
        ) => {
          if (filesToRejected.length) {
            window.logger.log("Rejected", filesToRejected, error.target);
            return input.onChange("errorPic");
          }
          if (filesToUpload.length) {
            window.logger.log("Success", filesToUpload);
            const [profilePic] = filesToUpload;
            return input.onChange(profilePic);
          }
        }}
      >
        <Form.Input
          fluid
          placeholder={placeholder}
          type={"text"}
          error={input.value && input.value === "errorPic"}
        />
      </Dropzone>
    )}
    {input.value && input.value === "errorPic" && (
      <Message className="pulse-negative" negative={true}>
        <span className="header">Image not allowed or too big.</span>
      </Message>
    )}
    {type === "textArea" && (
      <label className="skejule-teal right">{input.value.length} / 500</label>
    )}
  </Form.Field>
);

const FeedbackFormView: React.FC<FeedbackFormViewProps> = (props) => {
  const renderPriority = () => {
    const { onChangePriority } = props;
    return (
      <Container className="mt10 mb20">
        <div className="subtitle mb10">
          <h5>Priority</h5>
        </div>
        <Grid>
          <Grid.Column mobile={16} tablet={8} computer={6}>
            <Field
              name="feedbackFormPriority"
              onChangePriority={onChangePriority}
              component={renderDropDownField}
            />
          </Grid.Column>
        </Grid>
      </Container>
    );
  };
  const renderDescription = () => {
    return (
      <Container className="mt10">
        <div className="subtitle mb10">
          <h5>Description</h5>
        </div>
        <label className="fullwidth mb10">
          Describe the bug/issue or the feedback you want to share. Please
          provide as much details as possible.
        </label>
        <div>
          <Field
            name="feedbackFormDescription"
            type="textArea"
            placeholder="Description ..."
            component={renderInputField}
          />
        </div>
      </Container>
    );
  };
  const renderInputFile = () => {
    const { appVersion } = props;
    return (
      <Container className="mt20">
        <div className="subtitle mb10">
          <h5>Upload Image</h5>
        </div>
        <label className="fullwidth mb10">
          Please share any screenshot or image that are relevant to the bug you
          are reporting or the feedback you are sharing. A bug reported with
          screenshot helps us analyze the error faster. Image size limited to 5
          MB each. Only .gif .png .jpg .jpeg images are allowed.
        </label>
        <div>
          <Field
            name="feedbackFormFile"
            type="file"
            placeholder="Image ..."
            component={renderInputField}
          />
        </div>
        <div className="subtitle text-right mt5 mr5">
          <h5>Heto Version: {appVersion}</h5>
        </div>
      </Container>
    );
  };
  const render = () => {
    const {
      confirmSubmit,
      errorApi,
      openCloseFeedbackForm,
      onCancel,
      onSubmit,
      submitting,
      successApi,
      valid,
    } = props;
    return (
      <div id="main" className="feedback-report-bugs">
        <Modal
          className="modal-feedback-report-bugs seriously-fullscreen"
          dimmer={false}
          open={openCloseFeedbackForm}
          size={"fullscreen"}
        >
          <Modal.Header className="">
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={16}
                  className="text-center"
                >
                  <h1 style={{ color: "transparent" }}>Feedback Report</h1>
                  <i
                    className="icono icono-close-border right ml5"
                    style={{ cursor: "pointer" }}
                    onClick={onCancel}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Header>
          <Modal.Content className="create-evaluation">
            <Form loading={submitting} onSubmit={onSubmit}>
              <Grid
                stackable
                stretched
                centered
                verticalAlign="middle"
                className=""
              >
                <Grid.Row>
                  <Grid.Column
                    mobile={16}
                    tablet={16}
                    computer={16}
                    className="text-center"
                  >
                    <div className="subtitle mb20 capitalize">
                      <h4>Submit Feedback</h4>
                    </div>
                    <div className="message">
                      <span className="">
                        Please help us improve Heto by sharing your product
                        related feedback and/or by reporting any bugs/issues you
                        may encounter.
                      </span>
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column
                    mobile={14}
                    tablet={12}
                    computer={11}
                    className=""
                  >
                    {renderPriority()}
                    {renderDescription()}
                    {renderInputFile()}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column
                    mobile={14}
                    tablet={12}
                    computer={6}
                    className="text-center actions mt20"
                  >
                    <Grid columns={2} className="pt0 pb0">
                      <Grid.Row>
                        <Grid.Column
                          mobile={8}
                          tablet={8}
                          computer={8}
                          className="pt0 pb0"
                        >
                          <Button
                            className="skejule large filled right"
                            fluid
                            onClick={onCancel}
                          >
                            <span className="label">Cancel</span>
                          </Button>
                        </Grid.Column>
                        <Grid.Column
                          mobile={8}
                          tablet={8}
                          computer={8}
                          className="pt0 pb0"
                        >
                          <Button
                            className="skejule large filled right"
                            disabled={!valid || submitting}
                            fluid
                            loading={submitting}
                            type="submit"
                          >
                            <span className="label">Submit</span>
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Grid columns={1} className="pt0 pb0">
                      <Grid.Row style={{ display: "unset" }}>
                        {successApi && (
                          <Message positive={true}>
                            <span className="header">
                              Submit Feedback sent successfully.
                            </span>
                          </Message>
                        )}
                        {!confirmSubmit && errorApi && errorApi !== "errorPic" && (
                          <Message className="text-left" negative={true}>
                            <span className="header mb5">
                              Submit Feedback Error.{" "}
                              <span className="content">
                                Something went wrong when submitting your
                                feedback.
                              </span>
                            </span>
                            {errorApi && (
                              <span className="content-extra">{errorApi}</span>
                            )}{" "}
                            Try again, please.
                          </Message>
                        )}
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Modal.Content>
        </Modal>
        <Confirm
          cancelButton={
            <Button
              className="cancel-btn skejule p10 mb0"
              onClick={onCancel}
              size="medium"
            >
              <span className="label">Cancel</span>
            </Button>
          }
          className="confirm-msg"
          confirmButton={
            <Button
              className="confirm-btn skejule p10 mb0"
              onClick={onSubmit}
              size="medium"
            >
              <span className="label">Submit</span>
            </Button>
          }
          content={
            <div
              className="bold skejule-gray p20 pt10 pb10"
              style={{ lineHeight: "initial" }}
            >
              There is still some error when you have uploaded your image. Do
              you want to submit the feedback form anyway ?
            </div>
          }
          open={confirmSubmit && errorApi === "errorPic"}
          size="tiny"
        />
      </div>
    );
  };
  return render();
};
export default FeedbackFormView;
