import { put, select, call, all, takeLatest } from "redux-saga/effects";
import TenantSettingsApi from "../api/TenantSettings";
import { Auth, BroadcastMessages, TenantSettings } from "../actions/";
// Types
import type { Action } from "deox";
import type { tAPI } from "../api/API";
import type { TenantSettingName } from "../reducers/tenantSettings";
//Sagas for Tenant Settings
function* doTenantSettingsFetchAll(
  action: Action<
    string,
    {
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(TenantSettings.tenantSettingsFetchAllRequest());
  window.logger.log("doTenantSettingsFetchAll", action);
  const { callbackSuccess = null, callbackError = null } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get all tenant Settings for this company
    const { data } = yield call(
      TenantSettingsApi.GetTenantSettings,
      api,
      undefined,
      {
        token,
      }
    );
    const { tenantSettings } = data;
    yield put(
      TenantSettings.tenantSettingsFetchAllSuccess({
        tenantSettings,
      })
    );
    yield put(
      BroadcastMessages.broadcastMessagesSetMessages({
        broadcastMessagesSettings: tenantSettings.filter(
          ({ name }: any) => name === "BROADCAST_MESSAGES"
        ),
      })
    );
    if (callbackSuccess) {
      callbackSuccess(tenantSettings);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doTenantSettingsFetchAll", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(TenantSettings.tenantSettingsFetchAllFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}
function* doTenantSettingsCUDTenantSettings(
  action: Action<
    string,
    {
      method: "POST" | "PATCH" | "DELETE";
      tenantSettingName: TenantSettingName;
      tenantSettingValue: any;
      tenantSetting: any;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(TenantSettings.tenantSettingsCUDTenantSettingRequest());
  window.logger.log("doTenantSettingsCUDTenantSettings", action);
  const {
    method,
    tenantSettingName,
    tenantSettingValue,
    tenantSetting,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to update a tenant Setting for that specific tenantSettingName
    const { data: tenantSettingNew } = yield call(
      TenantSettingsApi.CUDTenantSetting,
      api,
      method,
      tenantSettingName,
      tenantSettingValue,
      tenantSetting,
      { token }
    );
    yield put(
      TenantSettings.tenantSettingsCUDTenantSettingSuccess({
        tenantSetting: tenantSettingNew,
      })
    );
    if (callbackSuccess) {
      yield put(TenantSettings.tenantSettingsFetchAll());
      callbackSuccess(tenantSettingNew);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doTenantSettingsCUDTenantSettings",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(TenantSettings.tenantSettingsCUDTenantSettingFailure());
    const { message, details } = errors || "";
    if (callbackError) {
      let errorMsg = message;
      if (details && details.length > 0) {
        errorMsg = `${errorMsg}: ${details[0].msg}`;
      }
      callbackError(errorMsg);
    }
  }
}
export default function* tenantSettingsSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(
      TenantSettings.TENANT_SETTINGS_FETCH_ALL,
      doTenantSettingsFetchAll
    ),
    takeLatest(
      TenantSettings.TENANT_SETTINGS_CUD_TENANT_SETTING,
      doTenantSettingsCUDTenantSettings
    ),
  ]);
}
