import { createAction } from "deox";
// Actions for Institutions
// Fetch all institutions
export const INSTITUTIONS_FETCH_INSTITUTIONS: string =
  "INSTITUTIONS/FETCH/INSTITUTIONS";
export const INSTITUTIONS_FETCH_INSTITUTIONS_REQUEST: string =
  "INSTITUTIONS/FETCH/INSTITUTIONS/REQUEST";
export const INSTITUTIONS_FETCH_INSTITUTIONS_SUCCESS: string =
  "INSTITUTIONS/FETCH/INSTITUTIONS/SUCCESS";
export const INSTITUTIONS_FETCH_INSTITUTIONS_FAILURE: string =
  "INSTITUTIONS/FETCH/INSTITUTIONS/FAILURE";
export const institutionsFetchInstitutions = createAction(
  INSTITUTIONS_FETCH_INSTITUTIONS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const institutionsFetchInstitutionsRequest = createAction(
  INSTITUTIONS_FETCH_INSTITUTIONS_REQUEST
);
export const institutionsFetchInstitutionsSuccess = createAction(
  INSTITUTIONS_FETCH_INSTITUTIONS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const institutionsFetchInstitutionsFailure = createAction(
  INSTITUTIONS_FETCH_INSTITUTIONS_FAILURE
);
// Create Institution
export const INSTITUTIONS_CREATE: string = "INSTITUTIONS/CREATE";
export const INSTITUTIONS_CREATE_REQUEST: string =
  "INSTITUTIONS/CREATE/REQUEST";
export const INSTITUTIONS_CREATE_SUCCESS: string =
  "INSTITUTIONS/CREATE/SUCCESS";
export const INSTITUTIONS_CREATE_FAILURE: string =
  "INSTITUTIONS/CREATE/FAILURE";
export const institutionsCreate = createAction(
  INSTITUTIONS_CREATE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const institutionsCreateRequest = createAction(
  INSTITUTIONS_CREATE_REQUEST
);
export const institutionsCreateSuccess = createAction(
  INSTITUTIONS_CREATE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const institutionsCreateFailure = createAction(
  INSTITUTIONS_CREATE_FAILURE
);
// Set institutionId to Fetch this institution
export const INSTITUTIONS_SET_INSTITUTION_ID: string =
  "INSTITUTIONS/SET/INSTITUTION_ID";
export const institutionsSetInstitutionId = createAction(
  INSTITUTIONS_SET_INSTITUTION_ID,
  (resolve) => (payload?: object) => resolve(payload)
);
// Unset institutionId to clean institution reducer
export const INSTITUTIONS_UNSET_INSTITUTION_ID: string =
  "INSTITUTIONS/UNSET/INSTITUTION_ID";
export const institutionsUnsetInstitutionId = createAction(
  INSTITUTIONS_UNSET_INSTITUTION_ID,
  (resolve) => (payload?: object) => resolve(payload)
);
// Fetch Institution
export const INSTITUTIONS_FETCH_INSTITUTION: string =
  "INSTITUTIONS/FETCH/INSTITUTION";
export const INSTITUTIONS_FETCH_INSTITUTION_REQUEST: string =
  "INSTITUTIONS/FETCH/INSTITUTION/REQUEST";
export const INSTITUTIONS_FETCH_INSTITUTION_SUCCESS: string =
  "INSTITUTIONS/FETCH/INSTITUTION/SUCCESS";
export const INSTITUTIONS_FETCH_INSTITUTION_FAILURE: string =
  "INSTITUTIONS/FETCH/INSTITUTION/FAILURE";
export const institutionsFetchInstitution = createAction(
  INSTITUTIONS_FETCH_INSTITUTION,
  (resolve) => (payload?: object) => resolve(payload)
);
export const institutionsFetchInstitutionRequest = createAction(
  INSTITUTIONS_FETCH_INSTITUTION_REQUEST
);
export const institutionsFetchInstitutionSuccess = createAction(
  INSTITUTIONS_FETCH_INSTITUTION_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const institutionsFetchInstitutionFailure = createAction(
  INSTITUTIONS_FETCH_INSTITUTION_FAILURE
);
// Fetch institution AVAILABILITIES
export const INSTITUTIONS_FETCH_INSTITUTION_AVAILABILITIES: string =
  "INSTITUTIONS/FETCH/INSTITUTION_AVAILABILITIES";
export const INSTITUTIONS_FETCH_INSTITUTION_AVAILABILITIES_REQUEST: string =
  "INSTITUTIONS/FETCH/INSTITUTION_AVAILABILITIES/REQUEST";
export const INSTITUTIONS_FETCH_INSTITUTION_AVAILABILITIES_SUCCESS: string =
  "INSTITUTIONS/FETCH/INSTITUTION_AVAILABILITIES/SUCCESS";
export const INSTITUTIONS_FETCH_INSTITUTION_AVAILABILITIES_FAILURE: string =
  "INSTITUTIONS/FETCH/INSTITUTION_AVAILABILITIES/FAILURE";
export const institutionsFetchInstitutionAvailabilities = createAction(
  INSTITUTIONS_FETCH_INSTITUTION_AVAILABILITIES,
  (resolve) => (payload?: object) => resolve(payload)
);
export const institutionsFetchInstitutionAvailabilitiesRequest = createAction(
  INSTITUTIONS_FETCH_INSTITUTION_AVAILABILITIES_REQUEST
);
export const institutionsFetchInstitutionAvailabilitiesSuccess = createAction(
  INSTITUTIONS_FETCH_INSTITUTION_AVAILABILITIES_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const institutionsFetchInstitutionAvailabilitiesFailure = createAction(
  INSTITUTIONS_FETCH_INSTITUTION_AVAILABILITIES_FAILURE
);
// Edit Institution
export const INSTITUTIONS_EDIT_INSTITUTION: string =
  "INSTITUTIONS/EDIT/INSTITUTION";
export const INSTITUTIONS_EDIT_INSTITUTION_REQUEST: string =
  "INSTITUTIONS/EDIT/INSTITUTION/REQUEST";
export const INSTITUTIONS_EDIT_INSTITUTION_SUCCESS: string =
  "INSTITUTIONS/EDIT/INSTITUTION/SUCCESS";
export const INSTITUTIONS_EDIT_INSTITUTION_FAILURE: string =
  "INSTITUTIONS/EDIT/INSTITUTION/FAILURE";
export const institutionsEditInstitution = createAction(
  INSTITUTIONS_EDIT_INSTITUTION,
  (resolve) => (payload?: object) => resolve(payload)
);
export const institutionsEditInstitutionRequest = createAction(
  INSTITUTIONS_EDIT_INSTITUTION_REQUEST
);
export const institutionsEditInstitutionSuccess = createAction(
  INSTITUTIONS_EDIT_INSTITUTION_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const institutionsEditInstitutionFailure = createAction(
  INSTITUTIONS_EDIT_INSTITUTION_FAILURE
);
// Edit institution AVAILABILITIES
export const INSTITUTIONS_EDIT_INSTITUTION_AVAILABILITIES: string =
  "INSTITUTIONS/EDIT/INSTITUTION_AVAILABILITIES";
export const INSTITUTIONS_EDIT_INSTITUTION_AVAILABILITIES_REQUEST: string =
  "INSTITUTIONS/EDIT/INSTITUTION_AVAILABILITIES/REQUEST";
export const INSTITUTIONS_EDIT_INSTITUTION_AVAILABILITIES_SUCCESS: string =
  "INSTITUTIONS/EDIT/INSTITUTION_AVAILABILITIES/SUCCESS";
export const INSTITUTIONS_EDIT_INSTITUTION_AVAILABILITIES_FAILURE: string =
  "INSTITUTIONS/EDIT/INSTITUTION_AVAILABILITIES/FAILURE";
export const institutionsEditInstitutionAvailabilities = createAction(
  INSTITUTIONS_EDIT_INSTITUTION_AVAILABILITIES,
  (resolve) => (payload?: object) => resolve(payload)
);
export const institutionsEditInstitutionAvailabilitiesRequest = createAction(
  INSTITUTIONS_EDIT_INSTITUTION_AVAILABILITIES_REQUEST
);
export const institutionsEditInstitutionAvailabilitiesSuccess = createAction(
  INSTITUTIONS_EDIT_INSTITUTION_AVAILABILITIES_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const institutionsEditInstitutionAvailabilitiesFailure = createAction(
  INSTITUTIONS_EDIT_INSTITUTION_AVAILABILITIES_FAILURE
);
// Fetch Institution Files
export const INSTITUTIONS_FETCH_INSTITUTION_FILES: string =
  "INSTITUTIONS/FETCH/INSTITUTION_FILES";
export const INSTITUTIONS_FETCH_INSTITUTION_FILES_REQUEST: string =
  "INSTITUTIONS/FETCH/INSTITUTION_FILES/REQUEST";
export const INSTITUTIONS_FETCH_INSTITUTION_FILES_SUCCESS: string =
  "INSTITUTIONS/FETCH/INSTITUTION_FILES/SUCCESS";
export const INSTITUTIONS_FETCH_INSTITUTION_FILES_FAILURE: string =
  "INSTITUTIONS/FETCH/INSTITUTION_FILES/FAILURE";
export const institutionsFetchInstitutionFiles = createAction(
  INSTITUTIONS_FETCH_INSTITUTION_FILES,
  (resolve) => (payload?: object) => resolve(payload)
);
export const institutionsFetchInstitutionFilesRequest = createAction(
  INSTITUTIONS_FETCH_INSTITUTION_FILES_REQUEST
);
export const institutionsFetchInstitutionFilesSuccess = createAction(
  INSTITUTIONS_FETCH_INSTITUTION_FILES_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const institutionsFetchInstitutionFilesFailure = createAction(
  INSTITUTIONS_FETCH_INSTITUTION_FILES_FAILURE
);
// Fetch all Institution Files Expired
export const INSTITUTIONS_FETCH_INSTITUTION_FILES_EXPIRED: string =
  "INSTITUTIONS/FETCH/INSTITUTION_FILES_EXPIRED";
export const INSTITUTIONS_FETCH_INSTITUTION_FILES_EXPIRED_REQUEST: string =
  "INSTITUTIONS/FETCH/INSTITUTION_FILES_EXPIRED/REQUEST";
export const INSTITUTIONS_FETCH_INSTITUTION_FILES_EXPIRED_SUCCESS: string =
  "INSTITUTIONS/FETCH/INSTITUTION_FILES_EXPIRED/SUCCESS";
export const INSTITUTIONS_FETCH_INSTITUTION_FILES_EXPIRED_FAILURE: string =
  "INSTITUTIONS/FETCH/INSTITUTION_FILES_EXPIRED/FAILURE";
export const institutionsFetchInstitutionFilesExpired = createAction(
  INSTITUTIONS_FETCH_INSTITUTION_FILES_EXPIRED,
  (resolve) => (payload?: object) => resolve(payload)
);
export const institutionsFetchInstitutionFilesExpiredRequest = createAction(
  INSTITUTIONS_FETCH_INSTITUTION_FILES_EXPIRED_REQUEST
);
export const institutionsFetchInstitutionFilesExpiredSuccess = createAction(
  INSTITUTIONS_FETCH_INSTITUTION_FILES_EXPIRED_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const institutionsFetchInstitutionFilesExpiredFailure = createAction(
  INSTITUTIONS_FETCH_INSTITUTION_FILES_EXPIRED_FAILURE
);
// Upload Institution Files
export const INSTITUTIONS_UPLOAD_INSTITUTION_FILES: string =
  "INSTITUTIONS/UPLOAD/INSTITUTION_FILES";
export const INSTITUTIONS_UPLOAD_INSTITUTION_FILES_REQUEST: string =
  "INSTITUTIONS/UPLOAD/INSTITUTION_FILES/REQUEST";
export const INSTITUTIONS_UPLOAD_INSTITUTION_FILES_SUCCESS: string =
  "INSTITUTIONS/UPLOAD/INSTITUTION_FILES/SUCCESS";
export const INSTITUTIONS_UPLOAD_INSTITUTION_FILES_FAILURE: string =
  "INSTITUTIONS/UPLOAD/INSTITUTION_FILES/FAILURE";
export const institutionsUploadInstitutionFiles = createAction(
  INSTITUTIONS_UPLOAD_INSTITUTION_FILES,
  (resolve) => (payload?: object) => resolve(payload)
);
export const institutionsUploadInstitutionFilesRequest = createAction(
  INSTITUTIONS_UPLOAD_INSTITUTION_FILES_REQUEST
);
export const institutionsUploadInstitutionFilesSuccess = createAction(
  INSTITUTIONS_UPLOAD_INSTITUTION_FILES_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const institutionsUploadInstitutionFilesFailure = createAction(
  INSTITUTIONS_UPLOAD_INSTITUTION_FILES_FAILURE
);
// Delete Institution
export const INSTITUTIONS_DELETE: string = "INSTITUTIONS/DELETE";
export const INSTITUTIONS_DELETE_REQUEST: string =
  "INSTITUTIONS/DELETE/REQUEST";
export const INSTITUTIONS_DELETE_SUCCESS: string =
  "INSTITUTIONS/DELETE/SUCCESS";
export const INSTITUTIONS_DELETE_FAILURE: string =
  "INSTITUTIONS/DELETE/FAILURE";
export const institutionsDelete = createAction(
  INSTITUTIONS_DELETE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const institutionsDeleteRequest = createAction(
  INSTITUTIONS_DELETE_REQUEST
);
export const institutionsDeleteSuccess = createAction(
  INSTITUTIONS_DELETE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const institutionsDeleteFailure = createAction(
  INSTITUTIONS_DELETE_FAILURE
);
