// @flow
// Import Features
import * as React from "react";
import { connect } from "react-redux";
import { Reports } from "../../../store/actions/";
// Import Components
import ReportsWarningPresentational from "./ReportsWarningPresentational";
// Import Styles
import "./ReportsWarning.scss";
// Import Types
import type { PropsReportsWarningContainer as Props } from "../../../types/reports.types";
class ReportsWarningContainer extends React.Component<Props> {
  getSnapshotBeforeUpdate(prevProps: Props, prevState: any) {
    if (
      prevProps.showWarning !== this.props.showWarning &&
      this.props.showWarning
    ) {
      return true;
    }
    return null;
  }
  render() {
    const { showWarning } = this.props;
    if (!showWarning) {
      return null;
    }
    return <ReportsWarningPresentational showWarning={showWarning} />;
  }
  componentDidUpdate(prevProps: Props, prevState: any, showWarning: boolean) {
    if (showWarning) {
      setTimeout(() => {
        this.props.hideReportWarning();
      }, 10000);
    }
  }
}
export default connect(
  ({ reports: { reportsShowWarning }, router: { location } }) => ({
    showWarning:
      reportsShowWarning &&
      location &&
      location.pathname &&
      location.pathname !== "/reports/dashboard/custom-reports" &&
      !location.pathname.match(
        /\/reports\/dashboard\/query\/[a-zA-Z0-9]+\/results/gi
      ),
  }),
  (dispatch) => ({
    hideReportWarning: () =>
      dispatch(
        Reports.reportsQueriesShowReportWarning({
          hasToShowReportsWarning: false,
        })
      ),
  })
)(ReportsWarningContainer);
