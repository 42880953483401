import type { Config, tAPI } from "./API";
// API Metrics and Charts static class

const apiBaseMetrics = "/metric-pages";
export default class Metrics {
  // API METRICS PAGES
  // Get a list of metrics pages to use getting charts
  static GetMetricsPages(api: tAPI, config?: Config) {
    let queryParams = "?populated=true";
    return api.GET(`${apiBaseMetrics}${queryParams}`, config);
  }
  // Create a metric page to use on Reports Stats
  static CreateMetricPage(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBaseMetrics}`, data, config);
  }
  // Get a metric page from specific pageId
  static GetMetricPage(api: tAPI, pageId: string, config?: Config) {
    return api.GET(`${apiBaseMetrics}/${pageId}`, config);
  }
  // Edit a metric page to use on Reports Stats
  static EditMetricPage(
    api: tAPI,
    pageId: string,
    data: object,
    config?: Config
  ) {
    return api.PATCH(`${apiBaseMetrics}/${pageId}`, data, config);
  }
  // Delete a metric page
  static DeleteMetricPage(api: tAPI, pageId: string, config?: Config) {
    return api.DELETE(`${apiBaseMetrics}/${pageId}`, config);
  }
  // API METRICS PAGES - CHARTS
  // API to get all Charts from some metric page
  static GetMetricPageCharts(api: tAPI, pageId: string, config?: Config) {
    return api.GET(`${apiBaseMetrics}/${pageId}/charts?populated=true`, config);
  }
  // Create a chart for some metric page to use on Reports Stats
  static CreateMetricPageChart(
    api: tAPI,
    pageId: string,
    data: object,
    config?: Config
  ) {
    return api.POST(`${apiBaseMetrics}/${pageId}/charts`, data, config);
  }
  // Get a saved report result
  static GetMetricPageChart(
    api: tAPI,
    pageId: string,
    chartId: string,
    config?: Config
  ) {
    return api.GET(
      `${apiBaseMetrics}/${pageId}/charts/${chartId}?populated=true`,
      config
    );
  }
  // Edit a chart from some metric page to use on Reports Stats
  static EditMetricPageChart(
    api: tAPI,
    pageId: string,
    chartId: string,
    data: object,
    config?: Config
  ) {
    return api.PATCH(
      `${apiBaseMetrics}/${pageId}/charts/${chartId}`,
      data,
      config
    );
  }
  // Delete a metric page
  static DeleteMetricPageChart(
    api: tAPI,
    pageId: string,
    chartId: string,
    config?: Config
  ) {
    return api.DELETE(`${apiBaseMetrics}/${pageId}/charts/${chartId}`, config);
  }
}
