// Import Features
import { Enrollments, Users } from "../actions/";
import { updateArrayWithElement } from "../../utils/";
// Enrollments Reducer
type State = {
  enrollments: object[];
  studentEnrollments: object[];
};
type Action = {
  type: string;
  payload: Object;
};

const initialState: State = {
  enrollments: [],
  studentEnrollments: [],
};

export default function enrollments(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    //Student Enrollments
    case Enrollments.STUDENTS_ENROLLMENTS_FETCH_SUCCESS:
      return Object.assign({}, state, {
        studentEnrollments: action.payload.studentEnrollments,
      });
    case Users.USERS_UNSET_USERID:
      return Object.assign({}, state, {
        studentEnrollments: [],
      });
    case Enrollments.STUDENT_ENROLLMENT_CREATE_SUCCESS:
      return Object.assign({}, state, {
        studentEnrollments: [
          ...state.studentEnrollments,
          action.payload.studentEnrollment,
        ],
      });
    case Enrollments.STUDENT_ENROLLMENT_EDIT_SUCCESS:
      return Object.assign({}, state, {
        studentEnrollments: updateArrayWithElement(
          state.studentEnrollments,
          action.payload.studentEnrollment
        ),
      });
    case Enrollments.STUDENT_ENROLLMENT_DELETE_SUCCESS:
      return Object.assign({}, state, {
        studentEnrollments: state.studentEnrollments.filter(
          ({ _id }) => _id !== action.payload.studentEnrollmentId
        ),
      });
    // same state (not change)
    default:
      return state;
  }
}
