import { createAction } from "deox";
// Actions for USERS_MANAGER

// User Manager Filters:
export const USERS_MANAGER_FILTERS_SAVE_FILTERS: string =
  "USERS/MANAGER/FILTERS/SAVE/FILTERS";
export const usersManagerFiltersSaveFilters = createAction(
  USERS_MANAGER_FILTERS_SAVE_FILTERS,
  (resolve) => (payload?: object) => resolve(payload)
);
// Set Pages Filters
export const USERS_MANAGER_FILTERS_SET_PAGES: string =
  "USERS/MANAGER/FILTERS/SET/PAGES";
export const usersManagerFiltersSetPages = createAction(
  USERS_MANAGER_FILTERS_SET_PAGES,
  (resolve) => (payload?: object) => resolve(payload)
);
// Search
export const USERS_MANAGER_FILTERS_SET_SEARCH: string =
  "USERS/MANAGER/FILTERS/SET/SEARCH";
export const usersManagerFiltersSetSearch = createAction(
  USERS_MANAGER_FILTERS_SET_SEARCH,
  (resolve) => (payload?: object) => resolve(payload)
);
// Set SortBy Filters
export const USERS_MANAGER_FILTERS_SET_SORTBY: string =
  "USERS/MANAGER/FILTERS/SET/SORTBY";
export const usersManagerFiltersSetSortBy = createAction(
  USERS_MANAGER_FILTERS_SET_SORTBY,
  (resolve) => (payload?: object) => resolve(payload)
);
// Clear Filters
export const USERS_MANAGER_FILTERS_CLEAR: string =
  "USERS/MANAGER/FILTERS/CLEAR";
export const usersManagerFiltersClear = createAction(
  USERS_MANAGER_FILTERS_CLEAR
);
// Set Roles Filters
export const USERS_MANAGER_FILTERS_SET_ROLES: string =
  "USERS/MANAGER/FILTERS/SET/ROLES";
export const usersManagerFiltersSetRoles = createAction(
  USERS_MANAGER_FILTERS_SET_ROLES,
  (resolve) => (payload?: object) => resolve(payload)
);
// Set Role Selected for Rotations
export const USERS_MANAGER_FILTERS_SET_ROLE_SELECTED: string =
  "USERS/MANAGER/FILTERS/SET/ROLE_SELECTED";
export const usersManagerFiltersSetRoleSelected = createAction(
  USERS_MANAGER_FILTERS_SET_ROLE_SELECTED,
  (resolve) => (payload?: object) => resolve(payload)
);
// Search Filters
export const USERS_MANAGER_FILTERS_SEARCH: string =
  "USERS/MANAGER/FILTERS/SEARCH";
export const usersManagerFiltersSearch = createAction(
  USERS_MANAGER_FILTERS_SEARCH,
  (resolve) => (payload?: object) => resolve(payload)
);
// User Status Filters
export const USERS_MANAGER_FILTERS_SET_USER_STATUS: string =
  "USERS/MANAGER/FILTERS/SET/USER_STATUS";
export const usersManagerFiltersSetUserStatus = createAction(
  USERS_MANAGER_FILTERS_SET_USER_STATUS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const USERS_MANAGER_FILTERS_USER_STATUS: string =
  "USERS/MANAGER/FILTERS/USER_STATUS";
export const usersManagerFiltersUserStatus = createAction(
  USERS_MANAGER_FILTERS_USER_STATUS,
  (resolve) => (payload?: object) => resolve(payload)
);
// Rotations Filters
export const USERS_MANAGER_FILTERS_ROTATIONS_ACTIVE: string =
  "USERS/MANAGER/FILTERS/ROTATIONS_ACTIVE";
export const usersManagerFiltersRotations = createAction(
  USERS_MANAGER_FILTERS_ROTATIONS_ACTIVE,
  (resolve) => (payload?: object) => resolve(payload)
);
// Programs Filters
export const USERS_MANAGER_FILTERS_PROGRAMS: string =
  "USERS/MANAGER/FILTERS/PROGRAMS";
export const usersManagerFiltersPrograms = createAction(
  USERS_MANAGER_FILTERS_PROGRAMS,
  (resolve) => (payload?: object) => resolve(payload)
);
// Cohorts Filters
export const USERS_MANAGER_FILTERS_COHORTS: string =
  "USERS/MANAGER/FILTERS/COHORTS";
export const usersManagerFiltersCohorts = createAction(
  USERS_MANAGER_FILTERS_COHORTS,
  (resolve) => (payload?: object) => resolve(payload)
);
// Institutions Filters
export const USERS_MANAGER_FILTERS_INSTITUTIONS: string =
  "USERS/MANAGER/FILTERS/INSTITUTIONS";
export const usersManagerFiltersInstitutions = createAction(
  USERS_MANAGER_FILTERS_INSTITUTIONS,
  (resolve) => (payload?: object) => resolve(payload)
);
// User Manager Filters END />
// User Manage Update Users after some action like change role or import data
export const USERS_MANAGER_UPDATE_STORE_USERS: string =
  "USERS/MANAGER/UPDATE/STORE/USERS";
export const usersManagerUpdateStoreUsers = createAction(
  USERS_MANAGER_UPDATE_STORE_USERS,
  (resolve) => (payload?: object) => resolve(payload)
);
// Save usersManager and userSelected to remain same state before leave user manager
export const USERS_MANAGER_SAVE_USERS: string = "USERS/MANAGER/SAVE/USERS";
export const usersManagerSaveUsers = createAction(
  USERS_MANAGER_SAVE_USERS,
  (resolve) => (payload?: object) => resolve(payload)
);
