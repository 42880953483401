// Features
import * as React from "react";
import { Waypoint } from "react-waypoint";
// Components
import { Divider, Header, Icon, Loader, Tab } from "semantic-ui-react";
import NotificationsFilters from "./NotificationsFilters";
import NotificationsActionsInBulk from "./NotificationsActionsInBulk";
import NotificationUserAccount from "./NotificationUserAccount";
import NotificationCustomFields from "./NotificationCustomFields";
import NotificationInstitutionAccount from "./NotificationInstitutionAccount";
import NotificationRotation from "./NotificationRotation";
import NotificationCaseLog from "./NotificationCaseLog";
import NotificationPeriod from "./NotificationPeriod";
import NotificationEvaluation from "./NotificationEvaluation";
import NotificationDocuments from "./NotificationDocuments";
import NotificationDocumentCategory from "./NotificationDocumentCategory";
import NotificationImportData from "./NotificationImportData";
import NotificationReport from "./NotificationReport";
// Types
import type { PayloadObject } from "../../../types/common.types";
type Props = {
  authPermissions: PayloadObject,
  isFetchingNotifications: boolean,
  notifications: Array<Object>,
  setImg: (_img: string, updatedAt: string) => string,
  goTo: (pathname: string, state: Object) => void,
  getMoreNotifications: (lastNotificationId: string) => () => void,
};
// Notifications:
const InfiniteScroll = ({
  onLoadMoreNotifications,
}: {
  onLoadMoreNotifications: () => void,
}): React$Element<any> => <Waypoint onEnter={onLoadMoreNotifications} />;

const Notifications = ({
  authPermissions,
  isFetchingNotifications,
  notifications,
  setImg,
  goTo,
  getMoreNotifications,
}: Props): React$Element<any> => (
  <Tab.Pane
    attached={false}
    className="pane notifications noborder noshadow nomargin p30"
  >
    <i
      className="icono icono-close-border skejule-teal absolute mt10 mr10"
      data-icono="is-icono-close"
      style={{ cursor: "pointer", top: 0, right: 0 }}
    />
    <div className="header text-left">
      <h3 className="title skejule-teal bold mb10">Notifications </h3>
      <div className="description skejule-gray">
        Filters notifications by categories
      </div>
      <NotificationsFilters authPermissions={authPermissions} />
      <Divider className="mt30 mb0" horizontal>
        <Header as="h4" className="skejule-gray">
          <Icon name="bell" />
          {notifications.filter((n) => !n.readBy).length > 0
            ? `NEW (${notifications.filter((n) => !n.readBy).length}/${
                notifications.length
              })`
            : `PREVIOUS (${notifications.length})`}
        </Header>
      </Divider>
      <NotificationsActionsInBulk
        canDoInActionsInBulk={notifications.length > 0}
      />
    </div>
    <div className="notifications-list skejule-scroll noborder p10">
      {notifications.map((notification, index) => {
        switch (notification.action) {
          case "USER_ACCOUNT_CREATED":
          case "USER_ACCOUNT_DELETED":
            return [
              index === notifications.length - 1 ? (
                <InfiniteScroll
                  key={"infinite-scroll-" + notification._id}
                  onLoadMoreNotifications={getMoreNotifications(
                    notification._id
                  )}
                />
              ) : null,
              <NotificationUserAccount
                key={notification._id}
                _id={notification._id}
                readBy={notification.readBy || undefined}
                user={notification.metadata.user}
                message={notification.message}
                createdAt={notification.createdAt}
                setImg={setImg}
                goToUserProfile={goTo}
              />,
            ];
          case "CUSTOM_FIELD_CREATED":
          case "CUSTOM_FIELD_DELETED":
            return [
              index === notifications.length - 1 ? (
                <InfiniteScroll
                  key={"infinite-scroll-" + notification._id}
                  onLoadMoreNotifications={getMoreNotifications(
                    notification._id
                  )}
                />
              ) : null,
              <NotificationCustomFields
                key={notification._id}
                _id={notification._id}
                readBy={notification.readBy || undefined}
                customField={notification.metadata.customField}
                user={notification.metadata.user}
                message={notification.message}
                createdAt={notification.createdAt}
                goToProfile={goTo}
              />,
            ];
          case "INSTITUTION_CREATED":
          case "INSTITUTION_DELETED":
            return [
              index === notifications.length - 1 ? (
                <InfiniteScroll
                  key={"infinite-scroll-" + notification._id}
                  onLoadMoreNotifications={getMoreNotifications(
                    notification._id
                  )}
                />
              ) : null,
              <NotificationInstitutionAccount
                key={notification._id}
                _id={notification._id}
                readBy={notification.readBy || undefined}
                institution={notification.metadata.institution}
                message={notification.message}
                createdAt={notification.createdAt}
                setImg={setImg}
                goToInstitutionProfile={goTo}
              />,
            ];
          case "ROTATION_CREATED":
          case "ROTATION_UPDATED":
          case "ROTATION_ABOUT_START":
          case "ROTATION_STARTED":
          case "ROTATION_ABOUT_FINISH":
          case "ROTATION_FINISHED":
            return [
              index === notifications.length - 1 ? (
                <InfiniteScroll
                  key={"infinite-scroll-" + notification._id}
                  onLoadMoreNotifications={getMoreNotifications(
                    notification._id
                  )}
                />
              ) : null,
              <NotificationRotation
                key={notification._id}
                _id={notification._id}
                readBy={notification.readBy || undefined}
                rotation={notification.metadata.rotation}
                institutions={notification.metadata.institution}
                student={notification.metadata.student}
                message={notification.message}
                createdAt={notification.createdAt}
                goTo={goTo}
              />,
            ];
          case "CASE_LOG_SUBMITTED":
          case "CASE_LOG_DENIED":
          case "CASE_LOG_APPROVED":
            return [
              index === notifications.length - 1 ? (
                <InfiniteScroll
                  key={"infinite-scroll-" + notification._id}
                  onLoadMoreNotifications={getMoreNotifications(
                    notification._id
                  )}
                />
              ) : null,
              <NotificationCaseLog
                key={notification._id}
                _id={notification._id}
                readBy={notification.readBy || undefined}
                caseLog={notification.metadata.caseLog}
                institution={notification.metadata.institution}
                student={notification.metadata.student}
                preceptor={notification.metadata.preceptor}
                message={notification.message}
                createdAt={notification.createdAt}
                setImg={setImg}
                goToProfile={goTo}
              />,
            ];
          case "PERIOD_CREATED":
          case "PERIOD_DELETED":
            return [
              index === notifications.length - 1 ? (
                <InfiniteScroll
                  key={"infinite-scroll-" + notification._id}
                  onLoadMoreNotifications={getMoreNotifications(
                    notification._id
                  )}
                />
              ) : null,
              <NotificationPeriod
                key={notification._id}
                _id={notification._id}
                readBy={notification.readBy || undefined}
                period={notification.metadata.period}
                message={notification.message}
                createdAt={notification.createdAt}
              />,
            ];
          case "EVALUATION_CREATED":
          case "EVALUATION_FINISHED":
          case "EVALUATION_ABOUT_FINISH":
          case "EVALUATION_ANSWER_SUBMITTED":
            return [
              index === notifications.length - 1 ? (
                <InfiniteScroll
                  key={"infinite-scroll-" + notification._id}
                  onLoadMoreNotifications={getMoreNotifications(
                    notification._id
                  )}
                />
              ) : null,
              <NotificationEvaluation
                key={notification._id}
                _id={notification._id}
                readBy={notification.readBy || undefined}
                evaluation={notification.metadata.evaluation}
                evaluationAnswer={notification.metadata.evaluationAnswer}
                evaluator={notification.metadata.evaluator}
                options={notification.metadata.options}
                targetName={notification.metadata.targetName}
                message={notification.message}
                createdAt={notification.createdAt}
                setImg={setImg}
                goTo={goTo}
              />,
            ];
          case "FILE_UPLOADED":
          case "FILE_EXPIRED":
            return [
              index === notifications.length - 1 ? (
                <InfiniteScroll
                  key={"infinite-scroll-" + notification._id}
                  onLoadMoreNotifications={getMoreNotifications(
                    notification._id
                  )}
                />
              ) : null,
              <NotificationDocuments
                key={notification._id}
                _id={notification._id}
                readBy={notification.readBy || undefined}
                user={notification.metadata.user}
                files={notification.metadata.files}
                message={notification.message}
                createdAt={notification.createdAt}
                goToProfile={goTo}
              />,
            ];
          case "FILE_CATEGORY_CREATED":
          case "FILE_CATEGORY_DELETED":
            return [
              index === notifications.length - 1 ? (
                <InfiniteScroll
                  key={"infinite-scroll-" + notification._id}
                  onLoadMoreNotifications={getMoreNotifications(
                    notification._id
                  )}
                />
              ) : null,
              <NotificationDocumentCategory
                key={notification._id}
                _id={notification._id}
                readBy={notification.readBy || undefined}
                fileCategory={notification.metadata.fileCategory}
                message={notification.message}
                createdAt={notification.createdAt}
                goToDocumentsCategory={goTo}
              />,
            ];
          case "IMPORT_CREATED":
          case "IMPORT_ERROR":
            return [
              index === notifications.length - 1 ? (
                <InfiniteScroll
                  key={"infinite-scroll-" + notification._id}
                  onLoadMoreNotifications={getMoreNotifications(
                    notification._id
                  )}
                />
              ) : null,
              <NotificationImportData
                key={notification._id}
                _id={notification._id}
                readBy={notification.readBy || undefined}
                fileName={notification.metadata.import.fileName}
                model={notification.metadata.import.model}
                message={notification.message}
                createdAt={notification.createdAt}
                goToImportStatus={goTo}
              />,
            ];
          case "REPORT_CREATED":
          case "REPORT_ERROR":
            return [
              index === notifications.length - 1 ? (
                <InfiniteScroll
                  key={"infinite-scroll-" + notification._id}
                  onLoadMoreNotifications={getMoreNotifications(
                    notification._id
                  )}
                />
              ) : null,
              <NotificationReport
                key={notification._id}
                _id={notification._id}
                readBy={notification.readBy || undefined}
                report={notification.metadata.report}
                message={notification.message}
                createdAt={notification.createdAt}
                goTo={goTo}
              />,
            ];
          default:
            return null;
        }
      })}
      {isFetchingNotifications && (
        <Loader
          style={{ top: notifications.length > 0 ? 0 : "125px", left: "-30px" }}
          active
          inline="centered"
          size="large"
          indeterminate
          className="m10 pl50 pr10"
        >
          <h3 className="ml10" style={{ textAlign: "center" }}>
            Loading Notifications ...
          </h3>
        </Loader>
      )}
    </div>
    {/*notifications-list*/}
  </Tab.Pane>
);

export default Notifications;
