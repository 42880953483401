import { Metrics } from "../actions/";

// Metrics and Queries Reducer
type State = {
  pageId: string | undefined | null;
  metricPage: object | undefined | null;
  metricsPages: object[];
  chartId: string | undefined | null;
  chart: object | undefined | null;
  charts: object[];
};
type Action = {
  type: string;
  payload: Object;
};
const initialState: State = {
  pageId: null,
  metricPage: null,
  metricsPages: [],
  chartId: null,
  chart: null,
  charts: [],
};

export default function charts(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    // METRICS PAGES
    case Metrics.METRICS_FETCH_METRICS_PAGES_SUCCESS:
      return Object.assign({}, state, {
        metricsPages: action.payload.pages,
        chartId: null,
        chart: null,
        charts: [],
      });
    case Metrics.METRICS_CREATE_METRIC_PAGE_SUCCESS:
      return Object.assign({}, state, {
        pageId: action.payload.page._id,
        metricPage: action.payload.page,
        metricsPages: [...state.metricsPages, action.payload.page],
      });
    case Metrics.METRICS_SET_PAGE_ID:
      return Object.assign({}, state, {
        pageId: action.payload.pageId,
        chartId: null,
        chart: null,
        charts: [],
      });
    case Metrics.METRICS_FETCH_METRIC_PAGE_SUCCESS:
      return Object.assign({}, state, {
        metricPage: action.payload.page,
        chartId: null,
        chart: null,
        charts: [],
      });
    case Metrics.METRICS_EDIT_METRIC_PAGE_SUCCESS:
      return Object.assign({}, state, {
        metricPage: action.payload.page,
        metricsPages: state.metricsPages.map((page) =>
          page._id !== action.payload.page._id ? page : action.payload.page
        ),
      });
    case Metrics.METRICS_DELETE_METRIC_PAGE_SUCCESS:
      return Object.assign({}, state, {
        metricsPages: state.metricsPages.filter(
          ({ _id }) => _id !== action.payload.pageId
        ),
        pageId:
          state.metricsPages.length > 0 ? state.metricsPages[0]._id : null,
        charts: [],
      });
    // METRICS PAGES - CHARTS
    case Metrics.METRICS_FETCH_METRIC_PAGE_CHARTS_SUCCESS:
      return Object.assign({}, state, {
        charts: action.payload.charts,
      });
    case Metrics.METRICS_CREATE_METRIC_PAGE_CHART_SUCCESS:
      return Object.assign({}, state, {
        chart: action.payload.chart,
        charts: [...state.charts, action.payload.chart],
      });
    case Metrics.METRICS_SET_CHART_ID:
      return Object.assign({}, state, {
        chartId: action.payload.chartId,
      });
    case Metrics.METRICS_FETCH_METRIC_PAGE_CHART_SUCCESS:
    case Metrics.METRICS_EDIT_METRIC_PAGE_CHART_SUCCESS:
      return Object.assign({}, state, {
        chart: action.payload.chart,
      });
    case Metrics.METRICS_DELETE_METRIC_PAGE_CHART_SUCCESS:
      return Object.assign({}, state, {
        charts: state.charts.filter(
          ({ _id }) => _id !== action.payload.chartId
        ),
      });
    // same state (not change)
    default:
      return state;
  }
}
