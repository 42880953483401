/* eslint-disable jsx-a11y/anchor-is-valid */
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
// Features
import * as React from "react";
import classNames from "classnames";
import { setUserRole } from "../../../utils";
// Components
import { Divider, Grid, Popup } from "semantic-ui-react";
import NotificationsDate from "./NotificationsDate";
// Type
import type { UndefinedNull, PayloadObject } from "../../../types/common.types";
type PropsNotificationsEvaluations = {
  _id: string;
  readBy: PayloadObject[] | UndefinedNull;
  evaluation: PayloadObject;
  evaluationAnswer: PayloadObject;
  evaluator: PayloadObject;
  options: PayloadObject[];
  targetName: string;
  message: string;
  createdAt: string;
  goTo: (pathname: string, state?: PayloadObject) => any;
};
// Notifications for EVALUATIONS
// evaluationName
// reminderMessage
// evaluatorName
// targetName

const NotificationEvaluation = ({
  _id,
  readBy,
  evaluation,
  evaluationAnswer,
  evaluator,
  options = [],
  targetName,
  message,
  createdAt,
  goTo,
}: React.PropsWithChildren<PropsNotificationsEvaluations>) => (
  <div
    className={classNames("notification", {
      new: !readBy || readBy?.length === 0,
    })}
  >
    <Grid columns={2}>
      <Grid.Row className="nopadding">
        <Grid.Column mobile={3} tablet={3} computer={3} className="">
          <i className="icono icono-file-fill huge skejule-teal" />
        </Grid.Column>
        <Grid.Column mobile={13} tablet={13} computer={13} className="">
          <div className="message">
            {message
              .split(
                /({evaluationName}|{evaluatorName}|{targetName}|{reminderMessage}| to be notified)/gi
              )
              .map((el, index) => {
                if (el === "{evaluationName}") {
                  return (
                    <a
                      key={`${index}-${evaluation._id}-evaluationName`}
                      style={{ cursor: "pointer" }}
                      onClick={
                        evaluation?._id && evaluationAnswer?._id
                          ? goTo(
                              `/evaluations/${evaluation._id}/answers/${evaluationAnswer._id}/pdf`
                            )
                          : goTo(`/evaluations`, {
                              namePage: "Evaluations",
                            })
                      }
                    >
                      #{evaluation.evaluationNumber}
                    </a>
                  );
                } else if (el === "{evaluatorName}") {
                  return (
                    <a
                      key={`${index}-${evaluator._id}-evaluatorName`}
                      style={{ cursor: "pointer" }}
                      onClick={(event: Object) => {
                        // DEBUG: We need to debugger this action, because when
                        // it's called one time and manager profile is open
                        // the action User.setUserId is called two times, because of
                        // ManagerRoute component is launched two times before route change
                        goTo(`/manager/profile/${evaluator._id}`, {
                          namePage: "Profile",
                          role: setUserRole(evaluator.role),
                          user: null,
                        })(event);
                        setTimeout(() => {
                          goTo(`/manager/profile/${evaluator._id}`, {
                            namePage: "Profile",
                            role: setUserRole(evaluator.role),
                            user: null,
                          })(event);
                        }, 500);
                      }}
                    >{`${evaluator.firstName || ""} ${
                      evaluator.lastName || ""
                    }`}</a>
                  );
                } else if (el === "{targetName}") {
                  return (
                    <span
                      key={`${index}-${evaluation._id}-${targetName}-targetName`}
                      className="skejule-teal"
                    >
                      {targetName}
                    </span>
                  );
                } else if (el === "{reminderMessage}") {
                  return (
                    <span
                      key={`${index}-${evaluation._id}-reminderMessage`}
                      className="skejule-teal"
                    >
                      {evaluation.reminderMessage}
                    </span>
                  );
                } else if (el === " to be notified") {
                  if (options?.length > 0) {
                    return (
                      <Popup
                        key={`${index}-${evaluation._id}-popup-to-be-notified`}
                        content={
                          <div
                            className={classNames("info-elements", {
                              "skejule-scroll": options.length > 4,
                            })}
                            data-popup="is-popup-notification"
                          >
                            {options.map((option, index) => (
                              <div
                                key={option.header + index}
                                className="info-element element"
                                data-popup="is-popup-notification"
                              >
                                <span
                                  className="label mb5"
                                  data-popup="is-popup-notification"
                                >
                                  {option.header}
                                </span>
                                <span
                                  className="value"
                                  data-popup="is-popup-notification"
                                >
                                  {option.option}
                                </span>
                                {index + 1 < options.length && (
                                  <Divider data-popup="is-popup-notification" />
                                )}
                              </div>
                            ))}
                          </div>
                        }
                        data-popup="is-popup-notification"
                        id="broadcast-message-popup"
                        on="click"
                        position="bottom center"
                        trigger={
                          <span className="btn mr15 skejule-blue link">
                            {el}
                          </span>
                        }
                      />
                    );
                  }
                }
                return el;
              })}
          </div>
          <NotificationsDate
            createdAt={createdAt}
            isMarkAsRead={!!readBy && readBy?.length > 0}
            notificationId={_id}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
);

export default NotificationEvaluation;
