import { put, select, call, all, takeLatest } from "redux-saga/effects";
import AuthApi from "../api/Auth";
import { Auth, AccountSettings, GuideLineTutorial } from "../actions/";
import { appNewFeaturesState } from "../reducers/accountSettings";
import { onSetBroadcastMessagesSettings } from "../reducers/broadcastMessages";
// Type
import type { Action } from "deox";
import type { tAPI } from "../api/API";
import type { UsersRoleType } from "../../types/users.types";
//Sagas for Account (My) Settings
const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const onSetHomeSettings = (userRoleType: UsersRoleType) => {
  switch (userRoleType) {
    case "supercompanyadministrators":
    case "companyadministrators":
      return {
        widgetsToShow: [
          "widgetCaseLogs",
          "widgetEvaluations",
          "widgetEvaluationsAnswers",
          "widgetAdminFiles",
        ],
        widgetCaseLogs: {
          showBy: "cohorts",
          selectedBy: [],
          statusToShow: ["pending", "approved", "denied"],
        },
        widgetEvaluations: {
          statusToShow: ["incourse", "completed", "closed"],
        },
        widgetEvaluationsAnswers: {
          showBy: "responses",
          selectedBy: [],
        },
      };
    case "coordinators":
    case "faculties":
      return {
        widgetsToShow: ["widgetEvaluations", "widgetFiles"],
        widgetEvaluations: {
          statusToShow: ["incourse", "completed", "closed"],
        },
      };
    case "institutionadministrators":
    case "preceptors":
      return {
        widgetsToShow: [
          "widgetCaseLogs",
          "widgetEvaluations",
          "widgetFiles",
          "widgetInstitutionRotations",
        ],
        widgetCaseLogs: {
          showBy: "cohorts",
          statusToShow: ["pending", "approved", "denied"],
        },
        widgetEvaluations: {
          statusToShow: ["incourse", "completed", "closed"],
        },
      };
    case "students":
    case "alumnis":
      return {
        widgetsToShow: [
          "widgetCaseLogs",
          "widgetEvaluations",
          "widgetFiles",
          "widgetStudentRotations",
        ],
        widgetCaseLogs: {
          showBy: "cohorts",
          statusToShow: ["pending", "approved", "denied"],
        },
        widgetEvaluations: {
          statusToShow: ["incourse", "completed", "closed"],
        },
      };
    default:
      return {
        widgetsToShow: [],
      };
  }
};

const onSetAccountSettings = (userRoleType: UsersRoleType) => {
  return {
    HOME_SETTINGS: onSetHomeSettings(userRoleType),
    BROADCAST_MESSAGES_SETTINGS: onSetBroadcastMessagesSettings(),
    USER_MANAGER_SETTINGS: {
      sortBy: {
        name: "descending",
        enrollment: "",
        institution: "",
      },
      sortByName: "firstName",
      userStatus: ["active"],
    },
    ROTATIONS_SETTINGS: {
      sortBy: {
        student: "",
        institution: "",
        start_end: "",
        status: "",
      },
      userStatus: ["active"],
    },
    CASE_LOGS_SETTINGS: {
      sortBy: {
        student: "",
        preceptor: "",
        institution: "",
        createdAt: "",
        status: "",
      },
      userStatus: ["active"],
    },
    EVALUATIONS_SETTINGS: {
      sortBy: {
        target: "",
        evaluator: "",
        createdAt: "",
        closingDate: "",
        status: "",
      },
      userStatus: ["active"],
    },
  };
};

export function* doCallAccountsSettingsFetchMySettings(
  action: Action<
    string,
    {
      userId: string;
      userRoleType: UsersRoleType;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
): Generator<any, any, any> {
  yield call(doAccountsSettingsFetchMySettings, action);
  return;
}

function* doAccountsSettingsFetchMySettings(
  action: Action<
    string,
    {
      userId: string;
      userRoleType: UsersRoleType;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(AccountSettings.accountSettingsFetchMySettingsRequest());
  window.logger.log("doAccountsSettingsFetchMySettings", action);
  const {
    userId,
    userRoleType,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get my settings
    const {
      data: { userSettings: settings = null },
    } = yield call(AuthApi.GetMySettingsAccount, api, userId, { token });
    const { frontendPersonalization, onboardingGuidelinesStatus } =
      settings || {};
    const { appNewFeatures, sectionsCompleted = [] } =
      onboardingGuidelinesStatus || {};
    if (sectionsCompleted.length > 0) {
      yield put(
        GuideLineTutorial.guideLineTutorialInitialize({
          sectionsCompleted: onboardingGuidelinesStatus.sectionsCompleted,
        })
      );
    }
    yield put(
      AccountSettings.accountSettingsFetchMySettingsSuccess({
        appNewFeatures,
        frontendPersonalization: frontendPersonalization
          ? frontendPersonalization
          : onSetAccountSettings(userRoleType),
      })
    );
    if (callbackSuccess) {
      callbackSuccess(frontendPersonalization);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doAccountsSettingsFetchMySettings",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(AccountSettings.accountSettingsFetchMySettingsFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}

function* doAccountsSettingsEditMySettings(
  action: Action<
    string,
    {
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(AccountSettings.accountSettingsEditMySettingsRequest());
  window.logger.log("doAccountsSettingsEditMySettings", action);
  const { body, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    const { userId } = yield select(({ auth: { user } }) => ({
      userId: user._id,
    }));
    // call the api to update my settings
    const {
      data: { userSettings: settings = null },
    } = yield call(AuthApi.EditMySettingsAccount, api, userId, body, { token });
    const { frontendPersonalization, onboardingGuidelinesStatus } =
      settings || {};
    const { appNewFeatures } = onboardingGuidelinesStatus || {};
    yield put(
      AccountSettings.accountSettingsEditMySettingsSuccess({
        appNewFeatures,
        frontendPersonalization,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(frontendPersonalization);
    }
    yield delay(3000);
    yield put(AccountSettings.accountSettingsEditMySettingsDone());
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doAccountsSettingsEditMySettings",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(AccountSettings.accountSettingsEditMySettingsFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doAccountsSettingsResetMySettings(action: Action<string>) {
  window.logger.log("doAccountsSettingsResetMySettings", action);
  try {
    const { userRoleType } = yield select(({ auth: { role } }) => ({
      userRoleType: role.type,
    }));
    const { appNewFeatures } = yield select(
      ({ accountSettings: { appNewFeatures } }) => ({
        appNewFeatures,
      })
    );
    const frontendPersonalization = onSetAccountSettings(userRoleType);
    const { sectionsCompleted } = yield select(
      ({ guideLineTutorial: { sectionsCompleted } }) => ({
        sectionsCompleted,
      })
    );
    yield put(
      AccountSettings.accountSettingsEditMySettings({
        body: {
          frontendPersonalization,
          onboardingGuidelinesStatus: {
            appNewFeatures:
              appNewFeatures && appNewFeatures.readAt
                ? appNewFeatures
                : appNewFeaturesState,
            sectionsCompleted,
          },
        },
      })
    );
  } catch (error) {
    window.logger.error("ErrorSaga - doAccountsSettingsResetMySettings", error);
  }
}

function* doAccountsSettingsSynchronizeMySettings(action: Action<string>) {
  window.logger.log("doAccountsSettingsSynchronizeMySettings", action);
  try {
    const { appNewFeatures, frontendPersonalization } = yield select(
      ({ accountSettings: { appNewFeatures, frontendPersonalization } }) => ({
        appNewFeatures,
        frontendPersonalization,
      })
    );
    const { sectionsCompleted } = yield select(
      ({ guideLineTutorial: { sectionsCompleted } }) => ({
        sectionsCompleted,
      })
    );
    yield put(
      AccountSettings.accountSettingsEditMySettings({
        body: {
          frontendPersonalization,
          onboardingGuidelinesStatus: {
            appNewFeatures,
            sectionsCompleted,
          },
        },
      })
    );
  } catch (error) {
    window.logger.error(
      "ErrorSaga - doAccountsSettingsSynchronizeMySettings",
      error
    );
  }
}

export default function* accountSettingsSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(
      AccountSettings.ACCOUNT_SETTINGS_FETCH,
      doAccountsSettingsFetchMySettings
    ),
    takeLatest(
      AccountSettings.ACCOUNT_SETTINGS_EDIT,
      doAccountsSettingsEditMySettings
    ),
    takeLatest(
      AccountSettings.ACCOUNT_SETTINGS_RESET_FRONTEND_PERSONALIZATION,
      doAccountsSettingsResetMySettings
    ),
    takeLatest(
      [
        AccountSettings.ACCOUNT_SETTINGS_UPDATE_APP_NEW_FEATURES,
        AccountSettings.ACCOUNT_SETTINGS_UPDATE_FRONTEND_PERSONALIZATION,
        GuideLineTutorial.GUIDE_LINE_TUTORIAL_COMPLETED,
        GuideLineTutorial.GUIDE_LINE_TUTORIAL_ON_OFF,
      ],
      doAccountsSettingsSynchronizeMySettings
    ),
  ]);
}
