import { createAction } from "deox";

// Actions for AUTH (authorized user after sign in successfully)

// Sign in into the App
export const AUTH_SIGNIN_APP: string = "AUTH/SIGNIN/APP";
export const AUTH_SIGNIN_REQUEST_APP: string = "AUTH/SIGNIN/REQUEST/APP";
export const AUTH_SIGNIN_SUCCESS_APP: string = "AUTH/SIGNIN/SUCCESS/APP";
export const AUTH_SIGNIN_FAILURE_APP: string = "AUTH/SIGNIN/FAILURE/APP";
export const authSignInApp = createAction(
  AUTH_SIGNIN_APP,
  (resolve) => (payload?: object) => resolve(payload)
);
export const authSignInRequestApp = createAction(AUTH_SIGNIN_REQUEST_APP);
export const authSignInSuccessApp = createAction(
  AUTH_SIGNIN_SUCCESS_APP,
  (resolve) => (payload?: object) => resolve(payload)
);
export const authSignInFailureApp = createAction(AUTH_SIGNIN_FAILURE_APP);

// Switch Account into the app
export const AUTH_SWITCH_ACCOUNT: string = "AUTH/SWITCH/ACCOUNT";
export const AUTH_SWITCH_ACCOUNT_REQUEST_APP: string =
  "AUTH/SWITCH/ACCOUNT/REQUEST/APP";
export const AUTH_SWITCH_ACCOUNT_SUCCESS_APP: string =
  "AUTH/SWITCH/ACCOUNT/SUCCESS/APP";
export const AUTH_SWITCH_ACCOUNT_FAILURE_APP: string =
  "AUTH/SWITCH/ACCOUNT/FAILURE/APP";
export const authSwithcAccount = createAction(
  AUTH_SWITCH_ACCOUNT,
  (resolve) => (payload?: object) => resolve(payload)
);
export const authSwithcAccountRequest = createAction(
  AUTH_SWITCH_ACCOUNT_REQUEST_APP
);
export const authSwithcAccountSuccess = createAction(
  AUTH_SWITCH_ACCOUNT_SUCCESS_APP
);
export const authSwithcAccountFailure = createAction(
  AUTH_SWITCH_ACCOUNT_FAILURE_APP
);

// Update My account
export const AUTH_UPDATE_MY_USER_ACCOUNT: string =
  "AUTH/UPDATE_MY_USER_ACCOUNT";
export const AUTH_UPDATE_MY_USER_ACCOUNT_REQUEST: string =
  "AUTH/UPDATE_MY_USER_ACCOUNT/REQUEST";
export const AUTH_UPDATE_MY_USER_ACCOUNT_SUCCESS: string =
  "AUTH/UPDATE_MY_USER_ACCOUNT/SUCCESS";
export const AUTH_UPDATE_MY_USER_ACCOUNT_FAILURE: string =
  "AUTH/UPDATE_MY_USER_ACCOUNT/FAILURE";
export const authUpdateMyUserAccount = createAction(
  AUTH_UPDATE_MY_USER_ACCOUNT,
  (resolve) => (payload?: object) => resolve(payload)
);
export const authUpdateMyUserAccountRequest = createAction(
  AUTH_UPDATE_MY_USER_ACCOUNT_REQUEST
);
export const authUpdateMyUserAccountSuccess = createAction(
  AUTH_UPDATE_MY_USER_ACCOUNT_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const authUpdateMyUserAccountFailure = createAction(
  AUTH_UPDATE_MY_USER_ACCOUNT_FAILURE
);

// Edit My Settings
export const AUTH_EDIT_MY_SETTINGS: string = "AUTH/EDIT/MY_SETTINGS";
export const AUTH_EDIT_MY_SETTINGS_REQUEST: string =
  "AUTH/EDIT/MY_SETTINGS/REQUEST";
export const AUTH_EDIT_MY_SETTINGS_SUCCESS: string =
  "AUTH/EDIT/MY_SETTINGS/SUCCESS";
export const AUTH_EDIT_MY_SETTINGS_FAILURE: string =
  "AUTH/EDIT/MY_SETTINGS/FAILURE";
export const authEditMySettings = createAction(
  AUTH_EDIT_MY_SETTINGS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const authEditMySettingsRequest = createAction(
  AUTH_EDIT_MY_SETTINGS_REQUEST
);
export const authEditMySettingsSuccess = createAction(
  AUTH_EDIT_MY_SETTINGS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const authEditMySettingsFailure = createAction(
  AUTH_EDIT_MY_SETTINGS_FAILURE
);

// Fetch my Files
export const AUTH_FETCH_MY_FILES: string = "AUTH/FETCH/MY_FILES";
export const AUTH_FETCH_MY_FILES_REQUEST: string =
  "AUTH/FETCH/MY_FILES/REQUEST";
export const AUTH_FETCH_MY_FILES_SUCCESS: string =
  "AUTH/FETCH/MY_FILES/SUCCESS";
export const AUTH_FETCH_MY_FILES_FAILURE: string =
  "AUTH/FETCH/MY_FILES/FAILURE";
export const authFetchMyFiles = createAction(
  AUTH_FETCH_MY_FILES,
  (resolve) => (payload?: object) => resolve(payload)
);
export const authFetchMyFilesRequest = createAction(
  AUTH_FETCH_MY_FILES_REQUEST
);
export const authFetchMyFilesSuccess = createAction(
  AUTH_FETCH_MY_FILES_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const authFetchMyFilesFailure = createAction(
  AUTH_FETCH_MY_FILES_FAILURE
);

// Fetch my Institution
export const AUTH_FETCH_MY_INSTITUTION: string = "AUTH/FETCH/MY_INSTITUTION";
export const AUTH_FETCH_MY_INSTITUTION_REQUEST: string =
  "AUTH/FETCH/MY_INSTITUTION/REQUEST";
export const AUTH_FETCH_MY_INSTITUTION_SUCCESS: string =
  "AUTH/FETCH/MY_INSTITUTION/SUCCESS";
export const AUTH_FETCH_MY_INSTITUTION_FAILURE: string =
  "AUTH/FETCH/MY_INSTITUTION/FAILURE";
export const authFetchMyInstitution = createAction(
  AUTH_FETCH_MY_INSTITUTION,
  (resolve) => (payload?: object) => resolve(payload)
);
export const authFetchMyInstitutionRequest = createAction(
  AUTH_FETCH_MY_INSTITUTION_REQUEST
);
export const authFetchMyInstitutionSuccess = createAction(
  AUTH_FETCH_MY_INSTITUTION_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const authFetchMyInstitutionFailure = createAction(
  AUTH_FETCH_MY_INSTITUTION_FAILURE
);

// Session Expired
// CHECK
export const AUTH_CHECK_SESSION_EXPIRED_APP: string =
  "AUTH/CHECK/SESSION_EXPIRED/APP";
export const authCheckSessionExpiredApp = createAction(
  AUTH_CHECK_SESSION_EXPIRED_APP,
  (resolve) => (payload?: object) => resolve(payload)
);
// The session was espired
export const AUTH_SESSION_EXPIRED_APP: string = "AUTH/SESSION_EXPIRED/APP";
export const authSessionExpiredApp = createAction(AUTH_SESSION_EXPIRED_APP);

// Sign out
export const AUTH_SIGNOUT_APP: string = "AUTH/SIGNOUT/APP";
export const AUTH_SIGNOUT_REQUEST_APP: string = "AUTH/SIGNOUT/REQUEST/APP";
export const AUTH_SIGNOUT_SUCCESS_APP: string = "AUTH/SIGNOUT/SUCCESS/APP";
export const AUTH_SIGNOUT_FAILURE_APP: string = "AUTH/SIGNOUT/FAILURE/APP";
export const authSignOutApp = createAction(
  AUTH_SIGNOUT_APP,
  (resolve) => (payload?: object) => resolve(payload)
);
export const authSignOutRequestApp = createAction(AUTH_SIGNOUT_REQUEST_APP);
export const authSignOutSuccessApp = createAction(AUTH_SIGNOUT_SUCCESS_APP);
export const authSignOutFailureApp = createAction(AUTH_SIGNOUT_FAILURE_APP);
