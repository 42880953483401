// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
// Features
import * as React from "react";
import classNames from "classnames";
// Assets
import iconInstitutionEmptyFill from "../../../images/iconInstitutionEmptyFill.svg";
// Components
import { Grid, Image } from "semantic-ui-react";
import NotificationsDate from "./NotificationsDate";
const NotificationInstitutionAccount = ({
  _id,
  readBy,
  institution,
  message,
  createdAt,
  setImg,
  goToInstitutionProfile,
}: Object): React$Element<any> => (
  <div
    className={classNames("notification", {
      new: !readBy || (readBy && readBy.length === 0),
    })}
  >
    <Grid columns={2}>
      <Grid.Row className="nopadding">
        <Grid.Column mobile={3} tablet={3} computer={3} className="">
          <Image
            src={
              institution.logo
                ? setImg(institution.logo, createdAt)
                : iconInstitutionEmptyFill
            }
            className="icon-institution huge"
            alt="icon-institution"
            centered
          />
        </Grid.Column>
        <Grid.Column mobile={13} tablet={13} computer={13} className="">
          <div className="message">
            {message.split(/({institutionName})/gi).map((el, index) => {
              if (el === "{institutionName}") {
                return (
                  <a
                    key={institution._id + index}
                    style={{ cursor: "pointer" }}
                    onClick={goToInstitutionProfile(
                      `/clinics/view/${institution._id}`,
                      {
                        namePage: "Clinic",
                        institution: null,
                      }
                    )}
                  >
                    {institution.name}
                  </a>
                );
              }
              return el;
            })}
          </div>
          <NotificationsDate
            createdAt={createdAt}
            isMarkAsRead={!!readBy && readBy?.length > 0}
            notificationId={_id}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
);

export default NotificationInstitutionAccount;
