import { createAction } from "deox";

// Actions for Metrics

// METRICS
// METRICS PAGES
// Fetch all metrics pages
export const METRICS_FETCH_METRICS_PAGES: string =
  "METRICS/FETCH/METRICS_PAGES";
export const METRICS_FETCH_METRICS_PAGES_REQUEST: string =
  "METRICS/FETCH/METRICS_PAGES/REQUEST";
export const METRICS_FETCH_METRICS_PAGES_SUCCESS: string =
  "METRICS/FETCH/METRICS_PAGES/SUCCESS";
export const METRICS_FETCH_METRICS_PAGES_FAILURE: string =
  "METRICS/FETCH/METRICS_PAGES/FAILURE";
export const metricsFetchMetricsPages = createAction(
  METRICS_FETCH_METRICS_PAGES,
  (resolve) => (payload?: object) => resolve(payload)
);
export const metricsFetchMetricsPagesRequest = createAction(
  METRICS_FETCH_METRICS_PAGES_REQUEST
);
export const metricsFetchMetricsPagesSuccess = createAction(
  METRICS_FETCH_METRICS_PAGES_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const metricsFetchMetricsPagesFailure = createAction(
  METRICS_FETCH_METRICS_PAGES_FAILURE
);
// Create a Metric Page to show Charts for that
export const METRICS_CREATE_METRIC_PAGE: string = "METRICS/CREATE/METRIC_PAGE";
export const METRICS_CREATE_METRIC_PAGE_REQUEST: string =
  "METRICS/CREATE/METRIC_PAGE/REQUEST";
export const METRICS_CREATE_METRIC_PAGE_SUCCESS: string =
  "METRICS/CREATE/METRIC_PAGE/SUCCESS";
export const METRICS_CREATE_METRIC_PAGE_FAILURE: string =
  "METRICS/CREATE/METRIC_PAGE/FAILURE";
export const metricsCreateMetricPage = createAction(
  METRICS_CREATE_METRIC_PAGE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const metricsCreateMetricPageRequest = createAction(
  METRICS_CREATE_METRIC_PAGE_REQUEST
);
export const metricsCreateMetricPageSuccess = createAction(
  METRICS_CREATE_METRIC_PAGE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const metricsCreateMetricPageFailure = createAction(
  METRICS_CREATE_METRIC_PAGE_FAILURE
);
// Set pageId to Fetch some metric page
export const METRICS_SET_PAGE_ID: string = "METRICS/SET/PAGE_ID";
export const metricsSetPageId = createAction(
  METRICS_SET_PAGE_ID,
  (resolve) => (payload?: object) => resolve(payload)
);
// Unset pageId
export const METRICS_UNSET_PAGE_ID: string = "METRICS/UNSET/PAGE_ID";
export const metricsUnsetPageId = createAction(
  METRICS_UNSET_PAGE_ID,
  (resolve) => (payload?: object) => resolve(payload)
);
// Fetch a Metric Page
export const METRICS_FETCH_METRIC_PAGE: string = "METRICS/FETCH/METRIC_PAGE";
export const METRICS_FETCH_METRIC_PAGE_REQUEST: string =
  "METRICS/FETCH/METRIC_PAGE/REQUEST";
export const METRICS_FETCH_METRIC_PAGE_SUCCESS: string =
  "METRICS/FETCH/METRIC_PAGE/SUCCESS";
export const METRICS_FETCH_METRIC_PAGE_FAILURE: string =
  "METRICS/FETCH/METRIC_PAGE/FAILURE";
export const metricsFetchMetricPage = createAction(
  METRICS_FETCH_METRIC_PAGE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const metricsFetchMetricPageRequest = createAction(
  METRICS_FETCH_METRIC_PAGE_REQUEST
);
export const metricsFetchMetricPageSuccess = createAction(
  METRICS_FETCH_METRIC_PAGE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const metricsFetchMetricPageFailure = createAction(
  METRICS_FETCH_METRIC_PAGE_FAILURE
);
// Edit a Metric Page
export const METRICS_EDIT_METRIC_PAGE: string = "METRICS/EDIT/METRIC_PAGE";
export const METRICS_EDIT_METRIC_PAGE_REQUEST: string =
  "METRICS/EDIT/METRIC_PAGE/REQUEST";
export const METRICS_EDIT_METRIC_PAGE_SUCCESS: string =
  "METRICS/EDIT/METRIC_PAGE/SUCCESS";
export const METRICS_EDIT_METRIC_PAGE_FAILURE: string =
  "METRICS/EDIT/METRIC_PAGE/FAILURE";
export const metricsEditMetricPage = createAction(
  METRICS_EDIT_METRIC_PAGE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const metricsEditMetricPageRequest = createAction(
  METRICS_EDIT_METRIC_PAGE_REQUEST
);
export const metricsEditMetricPageSuccess = createAction(
  METRICS_EDIT_METRIC_PAGE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const metricsEditMetricPageFailure = createAction(
  METRICS_EDIT_METRIC_PAGE_FAILURE
);
// Delete a Metric Page
export const METRICS_DELETE_METRIC_PAGE: string = "METRICS/DELETE/METRIC_PAGE";
export const METRICS_DELETE_METRIC_PAGE_REQUEST: string =
  "METRICS/DELETE/METRIC_PAGE/REQUEST";
export const METRICS_DELETE_METRIC_PAGE_SUCCESS: string =
  "METRICS/DELETE/METRIC_PAGE/SUCCESS";
export const METRICS_DELETE_METRIC_PAGE_FAILURE: string =
  "METRICS/DELETE/METRIC_PAGE/FAILURE";
export const metricsDeleteMetricPage = createAction(
  METRICS_DELETE_METRIC_PAGE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const metricsDeleteMetricPageRequest = createAction(
  METRICS_DELETE_METRIC_PAGE_REQUEST
);
export const metricsDeleteMetricPageSuccess = createAction(
  METRICS_DELETE_METRIC_PAGE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const metricsDeleteMetricPageFailure = createAction(
  METRICS_DELETE_METRIC_PAGE_FAILURE
);
// END METRICS PAGES />

// METRICS PAGES - CHARTS
// Fetch all charts from some Metric Page with that specific pageId
export const METRICS_FETCH_METRIC_PAGE_CHARTS: string =
  "METRICS/FETCH/METRIC_PAGE/CHARTS";
export const METRICS_FETCH_METRIC_PAGE_CHARTS_REQUEST: string =
  "METRICS/FETCH/METRIC_PAGE/CHARTS/REQUEST";
export const METRICS_FETCH_METRIC_PAGE_CHARTS_SUCCESS: string =
  "METRICS/FETCH/METRIC_PAGE/CHARTS/SUCCESS";
export const METRICS_FETCH_METRIC_PAGE_CHARTS_FAILURE: string =
  "METRICS/FETCH/METRIC_PAGE/CHARTS/FAILURE";
export const metricsFetchMetricPageCharts = createAction(
  METRICS_FETCH_METRIC_PAGE_CHARTS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const metricsFetchMetricPageChartsRequest = createAction(
  METRICS_FETCH_METRIC_PAGE_CHARTS_REQUEST
);
export const metricsFetchMetricPageChartsSuccess = createAction(
  METRICS_FETCH_METRIC_PAGE_CHARTS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const metricsFetchMetricPageChartsFailure = createAction(
  METRICS_FETCH_METRIC_PAGE_CHARTS_FAILURE
);
// Create a Chart from some specific Metric Page to show it
export const METRICS_CREATE_METRIC_PAGE_CHART: string =
  "METRICS/CREATE/METRIC_PAGE/CHART";
export const METRICS_CREATE_METRIC_PAGE_CHART_REQUEST: string =
  "METRICS/CREATE/METRIC_PAGE/CHART/REQUEST";
export const METRICS_CREATE_METRIC_PAGE_CHART_SUCCESS: string =
  "METRICS/CREATE/METRIC_PAGE/CHART/SUCCESS";
export const METRICS_CREATE_METRIC_PAGE_CHART_FAILURE: string =
  "METRICS/CREATE/METRIC_PAGE/CHART/FAILURE";
export const metricsCreateMetricPageChart = createAction(
  METRICS_CREATE_METRIC_PAGE_CHART,
  (resolve) => (payload?: object) => resolve(payload)
);
export const metricsCreateMetricPageChartRequest = createAction(
  METRICS_CREATE_METRIC_PAGE_CHART_REQUEST
);
export const metricsCreateMetricPageChartSuccess = createAction(
  METRICS_CREATE_METRIC_PAGE_CHART_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const metricsCreateMetricPageChartFailure = createAction(
  METRICS_CREATE_METRIC_PAGE_CHART_FAILURE
);
// Set chartId to Fetch some metric page with some chart
export const METRICS_SET_CHART_ID: string = "METRICS/SET/CHART_ID";
export const metricsSetChartId = createAction(
  METRICS_SET_CHART_ID,
  (resolve) => (payload?: object) => resolve(payload)
);
// Unset chartId
export const METRICS_UNSET_CHART_ID: string = "METRICS/UNSET/CHART_ID";
export const metricsUnsetChartId = createAction(
  METRICS_UNSET_CHART_ID,
  (resolve) => (payload?: object) => resolve(payload)
);
// Fetch a Chart from specific Metric Page
export const METRICS_FETCH_METRIC_PAGE_CHART: string =
  "METRICS/FETCH/METRIC_PAGE/CHART";
export const METRICS_FETCH_METRIC_PAGE_CHART_REQUEST: string =
  "METRICS/FETCH/METRIC_PAGE/CHART/REQUEST";
export const METRICS_FETCH_METRIC_PAGE_CHART_SUCCESS: string =
  "METRICS/FETCH/METRIC_PAGE/CHART/SUCCESS";
export const METRICS_FETCH_METRIC_PAGE_CHART_FAILURE: string =
  "METRICS/FETCH/METRIC_PAGE/CHART/FAILURE";
export const metricsFetchMetricPageChart = createAction(
  METRICS_FETCH_METRIC_PAGE_CHART,
  (resolve) => (payload?: object) => resolve(payload)
);
export const metricsFetchMetricPageChartRequest = createAction(
  METRICS_FETCH_METRIC_PAGE_CHART_REQUEST
);
export const metricsFetchMetricPageChartSuccess = createAction(
  METRICS_FETCH_METRIC_PAGE_CHART_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const metricsFetchMetricPageChartFailure = createAction(
  METRICS_FETCH_METRIC_PAGE_CHART_FAILURE
);
// Edit a Chart from some Metric Page
export const METRICS_EDIT_METRIC_PAGE_CHART: string =
  "METRICS/EDIT/METRIC_PAGE/CHART";
export const METRICS_EDIT_METRIC_PAGE_CHART_REQUEST: string =
  "METRICS/EDIT/METRIC_PAGE/CHART/REQUEST";
export const METRICS_EDIT_METRIC_PAGE_CHART_SUCCESS: string =
  "METRICS/EDIT/METRIC_PAGE/CHART/SUCCESS";
export const METRICS_EDIT_METRIC_PAGE_CHART_FAILURE: string =
  "METRICS/EDIT/METRIC_PAGE/CHART/FAILURE";
export const metricsEditMetricPageChart = createAction(
  METRICS_EDIT_METRIC_PAGE_CHART,
  (resolve) => (payload?: object) => resolve(payload)
);
export const metricsEditMetricPageChartRequest = createAction(
  METRICS_EDIT_METRIC_PAGE_CHART_REQUEST
);
export const metricsEditMetricPageChartSuccess = createAction(
  METRICS_EDIT_METRIC_PAGE_CHART_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const metricsEditMetricPageChartFailure = createAction(
  METRICS_EDIT_METRIC_PAGE_CHART_FAILURE
);
// Delete a Chart from some Metric Page
export const METRICS_DELETE_METRIC_PAGE_CHART: string =
  "METRICS/DELETE/METRIC_PAGE/CHART";
export const METRICS_DELETE_METRIC_PAGE_CHART_REQUEST: string =
  "METRICS/DELETE/METRIC_PAGE/CHART/REQUEST";
export const METRICS_DELETE_METRIC_PAGE_CHART_SUCCESS: string =
  "METRICS/DELETE/METRIC_PAGE/CHART/SUCCESS";
export const METRICS_DELETE_METRIC_PAGE_CHART_FAILURE: string =
  "METRICS/DELETE/METRIC_PAGE/CHART/FAILURE";
export const metricsDeleteMetricPageChart = createAction(
  METRICS_DELETE_METRIC_PAGE_CHART,
  (resolve) => (payload?: object) => resolve(payload)
);
export const metricsDeleteMetricPageChartRequest = createAction(
  METRICS_DELETE_METRIC_PAGE_CHART_REQUEST
);
export const metricsDeleteMetricPageChartSuccess = createAction(
  METRICS_DELETE_METRIC_PAGE_CHART_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const metricsDeleteMetricPageChartFailure = createAction(
  METRICS_DELETE_METRIC_PAGE_CHART_FAILURE
);
// END METRICS PAGES - CHARTS />
