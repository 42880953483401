import { put, select, call, all, takeLatest } from "redux-saga/effects";
import sortBy from "lodash/sortBy";
import InstitutionsApi from "../api/Institutions";
import { Auth, Institutions } from "../actions/";
// Type
import type { Action } from "deox";
import type { tAPI } from "../api/API";
//Sagas for institutions
function* doInstitutionsFetchInstitutions(
  action: Action<
    string,
    {
      status?: string;
      name?: string;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Institutions.institutionsFetchInstitutionsRequest());
  window.logger.log("doInstitutionsFetchInstitutions", action);
  const {
    status = "",
    name = "",
    callbackSuccess = null,
    callbackError = null,
  } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get all institutions for this company
    const { data } = yield call(
      InstitutionsApi.GetInstitutions,
      api,
      status,
      name,
      { token }
    );
    const { institutions } = data;
    const institutionsSortByName = sortBy(institutions, ["name"]);
    yield put(
      Institutions.institutionsFetchInstitutionsSuccess({
        institutions: institutionsSortByName,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(institutionsSortByName);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doInstitutionsFetchInstitutions",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Institutions.institutionsFetchInstitutionsFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}

function* doInstitutionsCreate(
  action: Action<
    string,
    {
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Institutions.institutionsCreateRequest());
  window.logger.log("doInstitutionsCreate", action);
  const { body, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to create new institution
    const { data } = yield call(InstitutionsApi.CreateInstitution, api, body, {
      token,
    });
    const { institution } = data;
    yield put(Institutions.institutionsCreateSuccess({ institution }));
    if (callbackSuccess) {
      callbackSuccess(institution);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doInstitutionsCreate", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Institutions.institutionsCreateFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

const getRolesInstitutionCustomFieldsRequiredNull = ({ customFields }: any) => {
  if (customFields.length) {
    const customFieldsRequired = customFields.filter(
      ({ isRequired }: any) => isRequired
    );
    for (let i = 0; i < customFieldsRequired.length; i++) {
      const { permissions, value } = customFieldsRequired[i];
      const rolesWrite = Object.keys(permissions).filter(
        (role) => permissions[role] === "write"
      );
      for (let j = 0; j < rolesWrite.length; j++) {
        const roleWrite = rolesWrite[j];
        if (
          permissions[roleWrite] === "write" &&
          (value === null || value === undefined)
        ) {
          return rolesWrite;
        }
      }
    }
  }
  return [];
};

export function* doMergeInstitutionAccount(
  institution: Object,
  isEdit: boolean
): Generator<any, void, any> {
  const { tenantSettings }: { tenantSettings: any } = yield select(
    ({ tenantSettings }) => tenantSettings
  );
  const hasToCheckRequiredFields =
    tenantSettings["ACCOUNTS_INSTITUTION_REQUIRED_FIELDS"].valuesApi;
  const hasInstitutionCustomFieldsRequiredNull = hasToCheckRequiredFields
    ? getRolesInstitutionCustomFieldsRequiredNull(institution)
    : false;
  window.logger.log(
    "doMergeInstitutionAccount",
    institution,
    isEdit,
    "hasInstitutionCustomFieldsRequiredNull: ",
    hasInstitutionCustomFieldsRequiredNull
  );
  if (!isEdit) {
    yield put(
      Institutions.institutionsFetchInstitutionSuccess({
        institution,
        hasInstitutionCustomFieldsRequiredNull:
          hasInstitutionCustomFieldsRequiredNull.length
            ? {
                institutionRequired: institution,
                rolesWrite: hasInstitutionCustomFieldsRequiredNull,
                isRequired: true,
              }
            : null,
      })
    );
  } else {
    yield put(
      Institutions.institutionsEditInstitutionSuccess({
        institution,
        hasInstitutionCustomFieldsRequiredNull:
          hasInstitutionCustomFieldsRequiredNull
            ? {
                institutionRequired: institution,
                rolesWrite: hasInstitutionCustomFieldsRequiredNull,
                isRequired: true,
              }
            : null,
      })
    );
  }
}

function* doInstitutionsFetchInstitution(
  action: Action<
    string,
    {
      institutionId: string;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Institutions.institutionsFetchInstitutionRequest());
  window.logger.log("doInstitutionsFetchInstitution", action);
  const {
    institutionId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get a institution for that institutionId
    const { data } = yield call(
      InstitutionsApi.GetInstitution,
      api,
      institutionId,
      { token }
    );
    const { institution } = data;
    yield call(doMergeInstitutionAccount, institution, false);
    if (callbackSuccess) {
      callbackSuccess(institution);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doInstitutionsFetchInstitution",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Institutions.institutionsFetchInstitutionFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}

function* doInstitutionsFetchInstitutionAvailabilities(
  action: Action<
    string,
    {
      institutionId: string;
      cbs?: (data: any) => void;
      cbe?: (error: any) => void;
    }
  >
) {
  window.logger.log("doInstitutionsFetchInstitutionAvailabilities", action);
  yield put(Institutions.institutionsFetchInstitutionAvailabilitiesRequest());
  const { institutionId, cbs = null, cbe = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get a institution availabilities from specific institutionId
    const {
      data: { availabilities = [] },
    } = yield call(
      InstitutionsApi.GetInstitutionAvailabilities,
      api,
      institutionId,
      { token }
    );
    yield put(Institutions.institutionsFetchInstitutionAvailabilitiesSuccess());
    if (cbs) {
      cbs(availabilities);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doInstitutionsFetchInstitutionAvailabilities",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Institutions.institutionsFetchInstitutionAvailabilitiesFailure());
    const { message } = errors || "";
    if (cbe) {
      cbe(message);
    }
  }
}

function* doInstitutionsEditInstitution(
  action: Action<
    string,
    {
      actionApi: "ACTIVATE_DEACTIVATE_INSTITUTION" | "EDIT_INSTITUTION";
      institutionId: string;
      body: object;
      isPostFile?: boolean;
      isMyInstitution?: boolean;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Institutions.institutionsEditInstitutionRequest());
  window.logger.log("doInstitutionsEditInstitution", action);
  const {
    actionApi = "EDIT_INSTITUTION",
    institutionId,
    body,
    isPostFile: isUpload = false,
    isMyInstitution = false,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to update the institution profile
    const { data } = yield call(
      InstitutionsApi.EditInstitution,
      api,
      actionApi,
      institutionId,
      body,
      isUpload,
      { token }
    );
    const { institution } = data;
    if (isMyInstitution) {
      yield put(
        Auth.authFetchMyInstitution({ institutionId: institution._id })
      );
    } else {
      yield call(doMergeInstitutionAccount, institution, true);
    }
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doInstitutionsEditInstitution",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Institutions.institutionsEditInstitutionFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doInstitutionsEditInstitutionAvailabilities(
  action: Action<
    string,
    {
      availabilityId: string;
      body: any;
      cbs?: () => void;
      cbe?: (error: any) => void;
    }
  >
) {
  window.logger.log("doInstitutionsEditInstitutionAvailabilities", action);
  yield put(Institutions.institutionsEditInstitutionAvailabilitiesRequest());
  const { availabilityId, body, cbs = null, cbe = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to edit a institution availabilities from specific institutionId
    yield call(
      InstitutionsApi.EditInstitutionAvailabilities,
      api,
      availabilityId,
      body,
      {
        token,
      }
    );
    yield put(Institutions.institutionsEditInstitutionAvailabilitiesSuccess());
    if (cbs) {
      cbs();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doInstitutionsEditInstitutionAvailabilities",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Institutions.institutionsEditInstitutionAvailabilitiesFailure());
    const { message } = errors || "";
    if (cbe) {
      cbe(message);
    }
  }
}

function* doInstitutionsFetchInstitutionFiles(
  action: Action<
    string,
    {
      id: string;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Institutions.institutionsFetchInstitutionFilesRequest());
  window.logger.log("doInstitutionsFetchInstitutionFiles", action);
  const { id, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get a institution for that institutionId
    const { data } = yield call(InstitutionsApi.GetInstitutionFiles, api, id, {
      token,
    });
    const { files } = data;
    yield put(Institutions.institutionsFetchInstitutionFilesSuccess({ files }));
    if (callbackSuccess) {
      callbackSuccess(files);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doInstitutionsFetchInstitutionFiles",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Institutions.institutionsFetchInstitutionFilesFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}

function* doInstitutionsFetchInstitutionFilesExpired(
  action: Action<
    string,
    {
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Institutions.institutionsFetchInstitutionFilesExpiredRequest());
  window.logger.log("doInstitutionsFetchInstitutionFilesExpired", action);
  const { callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    const { data } = yield call(
      InstitutionsApi.GetInstitutionFilesExpired,
      api,
      { token }
    );
    const { files: filesExpired } = data;
    yield put(
      Institutions.institutionsFetchInstitutionFilesExpiredSuccess({
        filesExpired,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(filesExpired);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doInstitutionsFetchInstitutionFilesExpired",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Institutions.institutionsFetchInstitutionFilesExpiredFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doInstitutionsUploadInstitutionFiles(
  action: Action<
    string,
    {
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Institutions.institutionsUploadInstitutionFilesRequest());
  window.logger.log("doInstitutionsUploadInstitutionFiles", action);
  const { body, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to upload all files for this institution
    const { data } = yield call(
      InstitutionsApi.UploadInstitutionFiles,
      api,
      body,
      { token }
    );
    const { files } = data;
    yield put(Institutions.institutionsUploadInstitutionFilesSuccess());
    if (callbackSuccess) {
      callbackSuccess(files);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doInstitutionsUploadInstitutionFiles",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Institutions.institutionsUploadInstitutionFilesFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doInstitutionsDelete(
  action: Action<
    string,
    {
      institutionId: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Institutions.institutionsDeleteRequest());
  window.logger.log("doInstitutionsDelete", action);
  const {
    institutionId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to delete a institution for that specific institutionId
    yield call(InstitutionsApi.DeleteInstitution, api, institutionId, {
      token,
    });
    yield put(Institutions.institutionsDeleteSuccess({ institutionId }));
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.log("ErrorSaga - doInstitutionsDelete", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Institutions.institutionsDeleteFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

export default function* institutionsSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(
      Institutions.INSTITUTIONS_FETCH_INSTITUTIONS,
      doInstitutionsFetchInstitutions
    ),
    takeLatest(Institutions.INSTITUTIONS_CREATE, doInstitutionsCreate),
    takeLatest(
      Institutions.INSTITUTIONS_FETCH_INSTITUTION,
      doInstitutionsFetchInstitution
    ),
    takeLatest(
      Institutions.INSTITUTIONS_FETCH_INSTITUTION_AVAILABILITIES,
      doInstitutionsFetchInstitutionAvailabilities
    ),
    takeLatest(
      Institutions.INSTITUTIONS_EDIT_INSTITUTION,
      doInstitutionsEditInstitution
    ),
    takeLatest(
      Institutions.INSTITUTIONS_EDIT_INSTITUTION_AVAILABILITIES,
      doInstitutionsEditInstitutionAvailabilities
    ),
    takeLatest(
      Institutions.INSTITUTIONS_FETCH_INSTITUTION_FILES,
      doInstitutionsFetchInstitutionFiles
    ),
    takeLatest(
      Institutions.INSTITUTIONS_FETCH_INSTITUTION_FILES_EXPIRED,
      doInstitutionsFetchInstitutionFilesExpired
    ),
    takeLatest(
      Institutions.INSTITUTIONS_UPLOAD_INSTITUTION_FILES,
      doInstitutionsUploadInstitutionFiles
    ),
    takeLatest(Institutions.INSTITUTIONS_DELETE, doInstitutionsDelete),
  ]);
}
