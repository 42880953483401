// Features
import produce from "immer";
import { Terms } from "../actions";
import { updateArrayWithElement } from "../../utils";
// Types
import type { Action } from "deox";
import type { UndefinedNull } from "../../types/common.types";
type PayloadTerm = {
  _id?: string;
  name: string;
  start: string | Date;
  end: string | Date;
  createdAt?: Date;
  updatedAt?: Date;
};
export type StateTerms = {
  lastFetching: Date | UndefinedNull;
  terms: PayloadTerm[];
};
export type TermsActionReducers = Action<
  string,
  {
    term: PayloadTerm;
    termId: string;
    terms: StateTerms["terms"];
  }
>;
// Terms Reducer
const initialState: StateTerms = {
  lastFetching: null,
  terms: [],
};
const terms: (state: StateTerms, action: TermsActionReducers) => StateTerms =
  produce((draft: StateTerms, action: TermsActionReducers) => {
    switch (action.type) {
      case Terms.TERMS_FETCH_ALL_SUCCESS:
        draft.lastFetching = new Date();
        draft.terms = action.payload.terms;
        break;
      case Terms.TERMS_CREATE_SUCCESS:
        draft.lastFetching = new Date();
        draft.terms = [...draft.terms, action.payload.term];
        break;
      case Terms.TERMS_EDIT_SUCCESS:
        draft.terms = updateArrayWithElement(draft.terms, action.payload.term);
        break;
      case Terms.TERMS_DELETE_SUCCESS:
        draft.terms = draft.terms.filter(
          ({ _id }) => _id !== action.payload.termId
        );
        break;
    }
  }, initialState);
export default terms;
