import type { Config, tAPI } from "./API";
import type { TypeImportData } from "../../types/importData.types";
// API Import Data: Users or Rotations static class
const apiBaseImportData = "/import-data";
export default class ImportData {
  // Get a list of latest import data uploaded
  static GetImportData(
    api: tAPI,
    typesImport: TypeImportData[],
    config?: Config
  ) {
    return api.GET(`${apiBaseImportData}?models=${typesImport.join()}`, config);
  }
  // Send import data: users or rotations
  static SendImportData(api: tAPI, data: object, config?: Config) {
    return api.UPLOAD(`${apiBaseImportData}`, data, "POST", config);
  }
  // Generate CSV
  static GenerateCSVImportData(api: tAPI, data: object, config?: Config) {
    return api.DOWNLOAD(
      `${apiBaseImportData}/generateCSV`,
      data,
      "text/csv",
      config
    );
  }
}
