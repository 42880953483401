import { LocalStoreActions } from "../actions/";
import { LocalStore } from "../../utils/local-storage/localStore";
// LocalStore reducer

// The initial application state
type State = {
  numKeys: number;
  keys: Array<string>;
  localstore:
    | {
        [key: string]: string;
      }
    | undefined
    | null;
};
type Action = {
  type: string;
  payload: Object;
};

let initialState = {
  numKeys: 0,
  keys: [],
  localstore: null,
};

export default function localStore(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case LocalStoreActions.LOCALSTORE_SAVE:
      const { key, value } = action.payload;
      const keyStringify = LocalStore.Save(key, value);
      return Object.assign({}, state, {
        numKeys:
          state.keys.indexOf(key) === -1
            ? state.keys.length + 1
            : state.numKeys,
        keys:
          state.keys.indexOf(key) === -1 ? [...state.keys, key] : state.keys,
        localstore: {
          ...state.localstore,
          [key]: keyStringify,
        },
      });
    case LocalStoreActions.LOCALSTORE_CLEAR_ALL:
      LocalStore.ClearAll();
      return initialState;
    // same state (not change)
    default:
      return state;
  }
}
