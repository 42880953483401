import { createAction } from "deox";
// Actions for Camera Device
// SHOW WARNING MESSAGE
export const CAMERA_DEVICE_SHOW_WARNING_MSG: string =
  "CAMERA_DEVICE/SHOW_WARNING_MSG";
export const cameraDeviceShowWarningMsg = createAction(
  CAMERA_DEVICE_SHOW_WARNING_MSG
);
// HIDE WARNING MESSAGE
export const CAMERA_DEVICE_HIDE_WARNING_MSG: string =
  "CAMERA_DEVICE/HIDE_WARNING_MSG";
export const cameraDeviceHideWarningMsg = createAction(
  CAMERA_DEVICE_HIDE_WARNING_MSG
);
