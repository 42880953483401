// @flow
import * as React from "react";
import { Route } from "react-router-dom";
// Component generic to manage routes loaded.
// * component - React Component
// * restProps - inject our Props
// * isAllowedRoute -- boolean
const LoadedRoute = ({
  component: Component,
  restProps,
  isAllowedRoute,
  ...rest
}: Object): React$Element<any> => (
  <Route
    {...rest}
    render={(props) =>
      isAllowedRoute ? <Component {...props} {...restProps} /> : null
    }
  />
);

export default LoadedRoute;
