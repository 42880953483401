// @flow

/* PERMISSIONS */
const onCheckResource = (apiResource: Object, resources: Array<string>) => {
  for (let resource in apiResource) {
    if (
      resources.indexOf(resource) !== -1 &&
      apiResource[resource]["isEnabled"]
    ) {
      return true;
    }
  }
  return false;
};

// PROGRAMS
export const onCheckAllProgramsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "Programs:create",
    "Programs:read",
    "Programs:update",
    "Programs:delete",
  ];
  const { Programs } = permissions || {};
  if (Programs && Programs.apiValue) {
    return onCheckResource(Programs.apiValue, resources);
  }
  return false;
};
export const onCheckReadProgramsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Programs:read"];
  const { Programs } = permissions || {};
  if (Programs && Programs.apiValue) {
    return onCheckResource(Programs.apiValue, resources);
  }
  return false;
};
export const onCheckCUDProgramsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Programs:create", "Programs:update", "Programs:delete"];
  const { Programs } = permissions || {};
  if (Programs && Programs.apiValue) {
    return onCheckResource(Programs.apiValue, resources);
  }
  return false;
};
export const onCheckCreateProgramsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Programs:create"];
  const { Programs } = permissions || {};
  if (Programs && Programs.apiValue) {
    return onCheckResource(Programs.apiValue, resources);
  }
  return false;
};
export const onCheckEditProgramsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Programs:update"];
  const { Programs } = permissions || {};
  if (Programs && Programs.apiValue) {
    return onCheckResource(Programs.apiValue, resources);
  }
  return false;
};
export const onCheckDeleteProgramsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Programs:delete"];
  const { Programs } = permissions || {};
  if (Programs && Programs.apiValue) {
    return onCheckResource(Programs.apiValue, resources);
  }
  return false;
};
// />
// COHORTS
export const onCheckAllCohortsPermissions = (permissions: ?Object): boolean => {
  const resources = [
    "Cohorts:create",
    "Cohorts:read",
    "Cohorts:update",
    "Cohorts:delete",
  ];
  const { Cohorts } = permissions || {};
  if (Cohorts && Cohorts.apiValue) {
    return onCheckResource(Cohorts.apiValue, resources);
  }
  return false;
};
export const onCheckReadCohortsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Cohorts:read"];
  const { Cohorts } = permissions || {};
  if (Cohorts && Cohorts.apiValue) {
    return onCheckResource(Cohorts.apiValue, resources);
  }
  return false;
};
export const onCheckCUDCohortsPermissions = (permissions: ?Object): boolean => {
  const resources = ["Cohorts:create", "Cohorts:update", "Cohorts:delete"];
  const { Cohorts } = permissions || {};
  if (Cohorts && Cohorts.apiValue) {
    return onCheckResource(Cohorts.apiValue, resources);
  }
  return false;
};
export const onCheckCreateCohortsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Cohorts:create"];
  const { Cohorts } = permissions || {};
  if (Cohorts && Cohorts.apiValue) {
    return onCheckResource(Cohorts.apiValue, resources);
  }
  return false;
};
export const onCheckEditCohortsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Cohorts:update"];
  const { Cohorts } = permissions || {};
  if (Cohorts && Cohorts.apiValue) {
    return onCheckResource(Cohorts.apiValue, resources);
  }
  return false;
};
export const onCheckDeleteCohortsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Cohorts:delete"];
  const { Cohorts } = permissions || {};
  if (Cohorts && Cohorts.apiValue) {
    return onCheckResource(Cohorts.apiValue, resources);
  }
  return false;
};
// />
// FILES CATEGORIES
export const onCheckAllFileCategoriesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "FileCategories:create",
    "FileCategories:read",
    "FileCategories:update",
    "FileCategories:delete",
    "my#FileCategories:read",
  ];
  const { FileCategories } = permissions || {};
  if (FileCategories && FileCategories.apiValue) {
    return onCheckResource(FileCategories.apiValue, resources);
  }
  return false;
};
export const onCheckReadFileCategoriesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["FileCategories:read"];
  const { FileCategories } = permissions || {};
  if (FileCategories && FileCategories.apiValue) {
    return onCheckResource(FileCategories.apiValue, resources);
  }
  return false;
};
export const onCheckCUDFileCategoriesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "FileCategories:create",
    "FileCategories:update",
    "FileCategories:delete",
  ];
  const { FileCategories } = permissions || {};
  if (FileCategories && FileCategories.apiValue) {
    return onCheckResource(FileCategories.apiValue, resources);
  }
  return false;
};
export const onCheckCreateFileCategoriesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["FileCategories:create"];
  const { FileCategories } = permissions || {};
  if (FileCategories && FileCategories.apiValue) {
    return onCheckResource(FileCategories.apiValue, resources);
  }
  return false;
};
export const onCheckEditFileCategoriesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["FileCategories:update"];
  const { FileCategories } = permissions || {};
  if (FileCategories && FileCategories.apiValue) {
    return onCheckResource(FileCategories.apiValue, resources);
  }
  return false;
};
export const onCheckDeleteFileCategoriesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["FileCategories:delete"];
  const { FileCategories } = permissions || {};
  if (FileCategories && FileCategories.apiValue) {
    return onCheckResource(FileCategories.apiValue, resources);
  }
  return false;
};
// />
// CUSTOM FIELDS
export const onCheckAllCustomFieldsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "CustomFields:create",
    "CustomFields:read",
    "CustomFields:update",
    "CustomFields:delete",
    "my#CustomFields:read",
  ];
  const { CustomFields } = permissions || {};
  if (CustomFields && CustomFields.apiValue) {
    return onCheckResource(CustomFields.apiValue, resources);
  }
  return false;
};
export const onCheckReadCustomFieldsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["CustomFields:read"];
  const { CustomFields } = permissions || {};
  if (CustomFields && CustomFields.apiValue) {
    return onCheckResource(CustomFields.apiValue, resources);
  }
  return false;
};
export const onCheckCUDCustomFieldsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "CustomFields:create",
    "CustomFields:update",
    "CustomFields:delete",
  ];
  const { CustomFields } = permissions || {};
  if (CustomFields && CustomFields.apiValue) {
    return onCheckResource(CustomFields.apiValue, resources);
  }
  return false;
};
export const onCheckReadMyCustomFieldsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my#CustomFields:read"];
  const { CustomFields } = permissions || {};
  if (CustomFields && CustomFields.apiValue) {
    return onCheckResource(CustomFields.apiValue, resources);
  }
  return false;
};
export const onCheckCreateCustomFieldsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["CustomFields:create"];
  const { CustomFields } = permissions || {};
  if (CustomFields && CustomFields.apiValue) {
    return onCheckResource(CustomFields.apiValue, resources);
  }
  return false;
};
export const onCheckEditCustomFieldsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["CustomFields:update"];
  const { CustomFields } = permissions || {};
  if (CustomFields && CustomFields.apiValue) {
    return onCheckResource(CustomFields.apiValue, resources);
  }
  return false;
};
export const onCheckDeleteCustomFieldsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["CustomFields:delete"];
  const { CustomFields } = permissions || {};
  if (CustomFields && CustomFields.apiValue) {
    return onCheckResource(CustomFields.apiValue, resources);
  }
  return false;
};
// />
// ROLES - Access Control
export const onCheckAllRolesPermissions = (permissions: ?Object): boolean => {
  const resources = [
    "Roles:read",
    "Roles:update",
    "Roles:change",
    "my:Users#Roles:change",
  ];
  const { Roles } = permissions || {};
  if (Roles && Roles.apiValue) {
    return onCheckResource(Roles.apiValue, resources);
  }
  return false;
};
export const onCheckReadRolesPermissions = (permissions: ?Object): boolean => {
  const resources = ["Roles:read"];
  const { Roles } = permissions || {};
  if (Roles && Roles.apiValue) {
    return onCheckResource(Roles.apiValue, resources);
  }
  return false;
};
export const onCheckCUDRolesPermissions = (permissions: ?Object): boolean => {
  const resources = ["Roles:update", "Roles:change", "my:Users#Roles:change"];
  const { Roles } = permissions || {};
  if (Roles && Roles.apiValue) {
    return onCheckResource(Roles.apiValue, resources);
  }
  return false;
};
export const onCheckEditRolesPermissions = (permissions: ?Object): boolean => {
  const resources = ["Roles:update"];
  const { Roles } = permissions || {};
  if (Roles && Roles.apiValue) {
    return onCheckResource(Roles.apiValue, resources);
  }
  return false;
};
export const onCheckChangeRolesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Roles:change", "my:Users#Roles:change"];
  const { Roles } = permissions || {};
  if (Roles && Roles.apiValue) {
    return onCheckResource(Roles.apiValue, resources);
  }
  return false;
};
// />
// DYNAMIC RULES - Access Control
export const onCheckAllDynamicRulesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "DynamicRules:create",
    "DynamicRules:read",
    "DynamicRules:update",
    "DynamicRules:delete",
  ];
  const { DynamicRules } = permissions || {};
  if (DynamicRules && DynamicRules.apiValue) {
    return onCheckResource(DynamicRules.apiValue, resources);
  }
  return false;
};
export const onCheckReadDynamicRulesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["DynamicRules:read"];
  const { DynamicRules } = permissions || {};
  if (DynamicRules && DynamicRules.apiValue) {
    return onCheckResource(DynamicRules.apiValue, resources);
  }
  return false;
};
export const onCheckCUDDynamicRulesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "DynamicRules:create",
    "DynamicRules:update",
    "DynamicRules:delete",
  ];
  const { DynamicRules } = permissions || {};
  if (DynamicRules && DynamicRules.apiValue) {
    return onCheckResource(DynamicRules.apiValue, resources);
  }
  return false;
};
export const onCheckCreateDynamicRulesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["DynamicRules:create"];
  const { DynamicRules } = permissions || {};
  if (DynamicRules && DynamicRules.apiValue) {
    return onCheckResource(DynamicRules.apiValue, resources);
  }
  return false;
};
export const onCheckEditDynamicRulesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["DynamicRules:update"];
  const { DynamicRules } = permissions || {};
  if (DynamicRules && DynamicRules.apiValue) {
    return onCheckResource(DynamicRules.apiValue, resources);
  }
  return false;
};
export const onCheckDeleteDynamicRulesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["DynamicRules:delete"];
  const { DynamicRules } = permissions || {};
  if (DynamicRules && DynamicRules.apiValue) {
    return onCheckResource(DynamicRules.apiValue, resources);
  }
  return false;
};
// />
// TENANT SETTINGS
export const onCheckAllTenantSettingsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "TenantSettings:read",
    "TenantSettings:create",
    "TenantSettings:update",
    "TenantSettings:delete",
  ];
  const { TenantSettings } = permissions || {};
  if (TenantSettings && TenantSettings.apiValue) {
    return onCheckResource(TenantSettings.apiValue, resources);
  }
  return false;
};
export const onCheckReadTenantSettingsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["TenantSettings:read"];
  const { TenantSettings } = permissions || {};
  if (TenantSettings && TenantSettings.apiValue) {
    return onCheckResource(TenantSettings.apiValue, resources);
  }
  return false;
};
export const onCheckCUDTenantSettingsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "TenantSettings:create",
    "TenantSettings:update",
    "TenantSettings:delete",
  ];
  const { TenantSettings } = permissions || {};
  if (TenantSettings && TenantSettings.apiValue) {
    return onCheckResource(TenantSettings.apiValue, resources);
  }
  return false;
};
export const onCheckCreateTenantSettingsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["TenantSettings:create"];
  const { TenantSettings } = permissions || {};
  if (TenantSettings && TenantSettings.apiValue) {
    return onCheckResource(TenantSettings.apiValue, resources);
  }
  return false;
};
export const onCheckEditTenantSettingsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["TenantSettings:update"];
  const { TenantSettings } = permissions || {};
  if (TenantSettings && TenantSettings.apiValue) {
    return onCheckResource(TenantSettings.apiValue, resources);
  }
  return false;
};
export const onCheckDeleteTenantSettingsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["TenantSettings:delete"];
  const { TenantSettings } = permissions || {};
  if (TenantSettings && TenantSettings.apiValue) {
    return onCheckResource(TenantSettings.apiValue, resources);
  }
  return false;
};
// />
