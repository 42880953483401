// @flow

/* PERMISSIONS */
const onCheckResource = (apiResource: Object, resources: Array<string>) => {
  for (let resource in apiResource) {
    if (
      resources.indexOf(resource) !== -1 &&
      apiResource[resource]["isEnabled"]
    ) {
      return true;
    }
  }
  return false;
};

export const onCheckAllReportsPermissions = (permissions: ?Object): boolean => {
  return (
    onCheckReportsPermissions(permissions) ||
    onCheckQueriesPermissions(permissions) ||
    onCheckChartsPermissions(permissions)
  );
};

// REPORTS
export const onCheckReportsPermissions = (permissions: ?Object): boolean => {
  const resources = [
    "Reports:read",
    "Reports:update",
    "Reports:delete",
    "my:Reports:read",
    "Reports:download"
  ];
  const { Reports } = permissions || {};
  if (Reports && Reports.apiValue) {
    return onCheckResource(Reports.apiValue, resources);
  }
  return false;
};

export const onCheckReadAllReportsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Reports:read", "my:Reports:read"];
  const { Reports } = permissions || {};
  if (Reports && Reports.apiValue) {
    return onCheckResource(Reports.apiValue, resources);
  }
  return false;
};

export const onCheckReadReportsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Reports:read"];
  const { Reports } = permissions || {};
  if (Reports && Reports.apiValue) {
    return onCheckResource(Reports.apiValue, resources);
  }
  return false;
};

export const onCheckEditReportsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Reports:update"];
  const { Reports } = permissions || {};
  if (Reports && Reports.apiValue) {
    return onCheckResource(Reports.apiValue, resources);
  }
  return false;
};

export const onCheckDeleteReportsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Reports:delete"];
  const { Reports } = permissions || {};
  if (Reports && Reports.apiValue) {
    return onCheckResource(Reports.apiValue, resources);
  }
  return false;
};

export const onCheckDownloadReportsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Reports:download"];
  const { Reports } = permissions || {};
  if (Reports && Reports.apiValue) {
    return onCheckResource(Reports.apiValue, resources);
  }
  return false;
};

// QUERIES
export const onCheckQueriesPermissions = (permissions: ?Object): boolean => {
  const resources = [
    "Queries:create",
    "Queries:read",
    "Queries:update",
    "Queries:delete",
    "Queries:run"
  ];
  const { Queries } = permissions || {};
  if (Queries && Queries.apiValue) {
    return onCheckResource(Queries.apiValue, resources);
  }
  return false;
};

export const onCheckReadQueriesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Queries:read"];
  const { Queries } = permissions || {};
  if (Queries && Queries.apiValue) {
    return onCheckResource(Queries.apiValue, resources);
  }
  return false;
};

export const onCheckCreateQueriesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Queries:create"];
  const { Queries } = permissions || {};
  if (Queries && Queries.apiValue) {
    return onCheckResource(Queries.apiValue, resources);
  }
  return false;
};

export const onCheckEditQueriesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Queries:update"];
  const { Queries } = permissions || {};
  if (Queries && Queries.apiValue) {
    return onCheckResource(Queries.apiValue, resources);
  }
  return false;
};

export const onCheckDeleteQueriesPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Queries:delete"];
  const { Queries } = permissions || {};
  if (Queries && Queries.apiValue) {
    return onCheckResource(Queries.apiValue, resources);
  }
  return false;
};

export const onCheckRunQueriesPermissions = (permissions: ?Object): boolean => {
  const resources = ["Queries:run"];
  const { Queries } = permissions || {};
  if (Queries && Queries.apiValue) {
    return onCheckResource(Queries.apiValue, resources);
  }
  return false;
};

// CHARTS
export const onCheckChartsPermissions = (permissions: ?Object): boolean => {
  const resources = [
    "Charts:create",
    "Charts:read",
    "Charts:update",
    "Charts:delete"
  ];
  const { Charts } = permissions || {};
  if (Charts && Charts.apiValue) {
    return onCheckResource(Charts.apiValue, resources);
  }
  return false;
};

export const onCheckReadChartsPermissions = (permissions: ?Object): boolean => {
  const resources = ["Charts:read"];
  const { Charts } = permissions || {};
  if (Charts && Charts.apiValue) {
    return onCheckResource(Charts.apiValue, resources);
  }
  return false;
};

export const onCheckCreateChartsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Charts:create"];
  const { Charts } = permissions || {};
  if (Charts && Charts.apiValue) {
    return onCheckResource(Charts.apiValue, resources);
  }
  return false;
};

export const onCheckEditChartsPermissions = (permissions: ?Object): boolean => {
  const resources = ["Charts:update"];
  const { Charts } = permissions || {};
  if (Charts && Charts.apiValue) {
    return onCheckResource(Charts.apiValue, resources);
  }
  return false;
};

export const onCheckDeleteChartsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Charts:delete"];
  const { Charts } = permissions || {};
  if (Charts && Charts.apiValue) {
    return onCheckResource(Charts.apiValue, resources);
  }
  return false;
};
