// Features
import * as React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { BroadcastMessages, Users } from "../../../store/actions/";
import { setUTCtime, setUTCtime235959 } from "../../../utils/";
// Components
import BroadcastSentMessagePresentational from "./BroadcastSentMessagePresentational";
// Styles
import "./BroadcastSentMessage.scss";
// Types
export type State = {
  broadcastMessage: {
    _id: string,
    cohorts: Array<string>,
    institutions: Array<string>,
    programs: Array<string>,
    roles: Array<string>,
    users: Array<string>,
    expirationDate: string,
    message: string,
  },
  errorSuccessBroadcastMessage: ?Object,
  isCreatingBroadcastMessage: boolean,
};
type PropsContainer = {
  canFetchAllUsers: boolean,
  createBroadcastMessage: (payload: Object) => void,
  editBroadcastMessage: (payload: Object) => void,
  getUsers: () => void,
};
export type PropsPresentational = {
  cohorts: Array<Object>,
  goToBroadcastSettings: () => void,
  institutions: Array<Object>,
  programs: Array<Object>,
  roles: Array<Object>,
  usersRoles: ?{ [string]: Array<Object> },
  onLoadBroadCastMessage: (
    broadcastMessage?: State["broadcastMessage"]
  ) => void,
};
type Props = PropsContainer & PropsPresentational;
// Sent Broadcast Messages
class BroadcastSentMessageContainer extends React.Component<Props, State> {
  state = {
    broadcastMessage:
      BroadcastSentMessageContainer._onInitialStateBroadcastMessage(),
    errorSuccessBroadcastMessage: null,
    isCreatingBroadcastMessage: false,
  };
  static _onInitialStateBroadcastMessage = () => ({
    _id: "",
    cohorts: [],
    institutions: [],
    programs: [],
    roles: [],
    users: [],
    expirationDate: "",
    message: "",
  });
  componentDidMount() {
    const { canFetchAllUsers, getUsers } = this.props;
    if (canFetchAllUsers) {
      getUsers();
    }
  }
  // <CREATE_EDIT BROADCAST MESSAGES
  onSaveBroadcastMessage = () => {
    this.setState({
      isCreatingBroadcastMessage: true,
      errorSuccessBroadcastMessage: null,
    });
    const onEndCallback = () => {
      this.setState({
        isCreatingBroadcastMessage: false,
      });
    };
    this._onSavingBroadcastMessage().then(onEndCallback).catch(onEndCallback);
  };
  _onSavingBroadcastMessage = () => {
    const {
      _id: broadcastMessageId,
      cohorts,
      institutions,
      programs,
      roles,
      users,
      expirationDate,
      message,
    } = this.state.broadcastMessage;
    const { createBroadcastMessage, editBroadcastMessage } = this.props;
    return new Promise((resolve, reject) => {
      let body = {
        message,
        roles,
      };
      if (users.length > 0) {
        body = {
          ...body,
          users,
        };
      }
      if (programs.length > 0 && roles.indexOf("Students") !== -1) {
        body = {
          ...body,
          programs,
        };
      }
      if (cohorts.length > 0 && roles.indexOf("Students") !== -1) {
        body = {
          ...body,
          cohorts,
        };
      }
      if (
        institutions.length > 0 &&
        roles.filter(
          (role) =>
            ["Students", "InstitutionAdministrators", "Preceptors"].indexOf(
              role
            ) !== -1
        )
      ) {
        body = {
          ...body,
          institutions,
        };
      }
      if (expirationDate) {
        body = {
          ...body,
          expirationDate: setUTCtime235959(expirationDate).format(),
        };
      }
      const formValues = {
        broadcastMessageId,
        body,
      };
      const callbacks = {
        callbackError: (errorSuccessBroadcastMessage) => {
          window.logger.log(
            "error - _onSavingBroadcastMessage",
            errorSuccessBroadcastMessage
          );
          let parseError = errorSuccessBroadcastMessage;
          if (parseError === "Request failed with status code 500") {
            parseError = `Looks like something went wrong when ${
              broadcastMessageId ? "saving" : "sending"
            } a new broadcast message. Try again, please.`;
          }
          this.setState({
            errorSuccessBroadcastMessage: {
              successApi: null,
              errorApi: parseError,
            },
          });
          reject();
        },
        callbackSuccess: () => {
          this.setState({
            broadcastMessage:
              BroadcastSentMessageContainer._onInitialStateBroadcastMessage(),
            errorSuccessBroadcastMessage: {
              successApi: "Submit Message sent successfully.",
              errorApi: null,
            },
          });
          setTimeout(() => {
            this.setState({
              errorSuccessBroadcastMessage: null,
            });
          }, 5000);
          resolve();
        },
      };
      const payload = Object.assign({}, formValues, callbacks);
      if (broadcastMessageId) {
        editBroadcastMessage(payload);
      } else {
        createBroadcastMessage(payload);
      }
    });
  };
  onLoadBroadCastMessage = (broadcastMessage?: State["broadcastMessage"]) =>
    this.setState({
      broadcastMessage:
        broadcastMessage ||
        BroadcastSentMessageContainer._onInitialStateBroadcastMessage(),
      errorSuccessBroadcastMessage: null,
    });
  onChangeDropdown = (
    event: Event,
    {
      name,
      value,
    }: {
      name: "cohorts" | "institutions" | "programs" | "roles" | "users",
      value: Array<string>,
    }
  ) => {
    this.setState({
      broadcastMessage: { ...this.state.broadcastMessage, [name]: value },
    });
  };
  onChangeInputField = (
    event: Object,
    { name, value }: { name: string, value: string }
  ) => {
    this.setState({
      broadcastMessage: { ...this.state.broadcastMessage, [name]: value },
    });
  };
  // END />
  render() {
    const {
      goToBroadcastSettings,
      cohorts,
      institutions,
      programs,
      roles,
      usersRoles,
    } = this.props;
    return (
      <BroadcastSentMessagePresentational
        cohorts={cohorts}
        goToBroadcastSettings={goToBroadcastSettings}
        institutions={institutions}
        onChangeDropdown={this.onChangeDropdown}
        onChangeInputField={this.onChangeInputField}
        onLoadBroadCastMessage={this.onLoadBroadCastMessage}
        onSaveBroadcastMessage={this.onSaveBroadcastMessage}
        programs={programs}
        roles={roles}
        usersRoles={usersRoles}
        {...this.state}
      />
    );
  }
}

export default connect(
  ({
    accessControl: { roles },
    cohorts: { cohorts },
    institutions: { institutions },
    programs: { programs },
    users: { allUsersSystem, lastFetchingAllUsersSystem },
  }) => ({
    canFetchAllUsers: lastFetchingAllUsersSystem
      ? setUTCtime().isAfter(
          setUTCtime(lastFetchingAllUsersSystem).add(30, "m")
        )
      : true,
    cohorts,
    institutions,
    programs,
    roles: roles.filter(
      ({ isActive, apiName }) => isActive && apiName !== "Alumnis"
    ),
    usersRoles: allUsersSystem,
  }),
  (dispatch) => ({
    createBroadcastMessage: (payload) =>
      dispatch(BroadcastMessages.broadcastMessagesCreate(payload)),
    editBroadcastMessage: (payload) =>
      dispatch(BroadcastMessages.broadcastMessagesEdit(payload)),
    getUsers: () => dispatch(Users.usersFetchAll({ status: ["active"] })),
    goToBroadcastSettings: () =>
      dispatch(
        push({
          pathname: "/admin/broadcast-settings",
          state: {
            namePage: "Admin Portal",
          },
        })
      ),
  })
)(BroadcastSentMessageContainer);
