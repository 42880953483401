import { createAction } from "deox";

// Actions to save last router manager loaded
export const ROUTER_MANAGER_BEFORE: string = "ROUTER/MANAGER/BEFORE";
export const routerManagerBefore = createAction(
  ROUTER_MANAGER_BEFORE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const ROUTER_MANAGER_LOADED: string = "ROUTER/MANAGER/LOADED";
export const routerManagerLoaded = createAction(
  ROUTER_MANAGER_LOADED,
  (resolve) => (payload?: object) => resolve(payload)
);
export const ROUTER_MANAGER_RESET: string = "ROUTER/MANAGER/RESET";
export const routerManagerReset = createAction(ROUTER_MANAGER_RESET);
