import { createAction } from "deox";

// Actions for Evaulations Templates Forms

// Fetch all evaluations TEMPLATES form
export const EVALUATIONS_TEMPLATES_FETCH_FORMS: string =
  "EVALUATIONS/TEMPLATES/FETCH/FORMS";
export const EVALUATIONS_TEMPLATES_FETCH_FORMS_REQUEST: string =
  "EVALUATIONS/TEMPLATES/FETCH/FORMS/REQUEST";
export const EVALUATIONS_TEMPLATES_FETCH_FORMS_SUCCESS: string =
  "EVALUATIONS/TEMPLATES/FETCH/FORMS/SUCCESS";
export const EVALUATIONS_TEMPLATES_FETCH_FORMS_FAILURE: string =
  "EVALUATIONS/TEMPLATES/FETCH/FORMS/FAILURE";
export const evaluationsTemplatesFetchForms = createAction(
  EVALUATIONS_TEMPLATES_FETCH_FORMS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsTemplatesFetchFormsRequest = createAction(
  EVALUATIONS_TEMPLATES_FETCH_FORMS_REQUEST
);
export const evaluationsTemplatesFetchFormsSuccess = createAction(
  EVALUATIONS_TEMPLATES_FETCH_FORMS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsTemplatesFetchFormsFailure = createAction(
  EVALUATIONS_TEMPLATES_FETCH_FORMS_FAILURE
);
// Create a Evaluation TEMPLATE form
export const EVALUATIONS_TEMPLATES_CREATE: string =
  "EVALUATIONS/TEMPLATES/CREATE";
export const EVALUATIONS_TEMPLATES_CREATE_REQUEST: string =
  "EVALUATIONS/TEMPLATES/CREATE/REQUEST";
export const EVALUATIONS_TEMPLATES_CREATE_SUCCESS: string =
  "EVALUATIONS/TEMPLATES/CREATE/SUCCESS";
export const EVALUATIONS_TEMPLATES_CREATE_FAILURE: string =
  "EVALUATIONS/TEMPLATES/CREATE/FAILURE";
export const evaluationsTemplatesCreate = createAction(
  EVALUATIONS_TEMPLATES_CREATE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsTemplatesCreateRequest = createAction(
  EVALUATIONS_TEMPLATES_CREATE_REQUEST
);
export const evaluationsTemplatesCreateSuccess = createAction(
  EVALUATIONS_TEMPLATES_CREATE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsTemplatesCreateFailure = createAction(
  EVALUATIONS_TEMPLATES_CREATE_FAILURE
);
// Set evaluationTemplateId to Fetch this evaluation TEMPLATE form
export const EVALUATIONS_TEMPLATES_SET_EVALUATION_TEMPLATE_ID: string =
  "EVALUATIONS/TEMPLATES/SET/EVALUATION_TEMPLATE_ID";
export const evaluationsTemplatesSetEvaluationTemplateId = createAction(
  EVALUATIONS_TEMPLATES_SET_EVALUATION_TEMPLATE_ID,
  (resolve) => (payload?: object) => resolve(payload)
);
// Unset evaluationTemplateId
export const EVALUATIONS_TEMPLATES_UNSET_EVALUATION_TEMPLATE_ID: string =
  "EVALUATIONS/TEMPLATES/UNSET/EVALUATION_TEMPLATE_ID";
export const evaluationsTemplatesUnsetEvaluationTemplateId = createAction(
  EVALUATIONS_TEMPLATES_UNSET_EVALUATION_TEMPLATE_ID,
  (resolve) => (payload?: object) => resolve(payload)
);
// Fetch a Evaluation TEMPLATE form
export const EVALUATIONS_TEMPLATES_FETCH_FORM: string =
  "EVALUATIONS/TEMPLATES/FETCH/EVALUATION";
export const EVALUATIONS_TEMPLATES_FETCH_FORM_REQUEST: string =
  "EVALUATIONS/TEMPLATES/FETCH/EVALUATION/REQUEST";
export const EVALUATIONS_TEMPLATES_FETCH_FORM_SUCCESS: string =
  "EVALUATIONS/TEMPLATES/FETCH/EVALUATION/SUCCESS";
export const EVALUATIONS_TEMPLATES_FETCH_FORM_FAILURE: string =
  "EVALUATIONS/TEMPLATES/FETCH/EVALUATION/FAILURE";
export const evaluationsTemplatesFetchForm = createAction(
  EVALUATIONS_TEMPLATES_FETCH_FORM,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsTemplatesFetchFormRequest = createAction(
  EVALUATIONS_TEMPLATES_FETCH_FORM_REQUEST
);
export const evaluationsTemplatesFetchFormSuccess = createAction(
  EVALUATIONS_TEMPLATES_FETCH_FORM_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsTemplatesFetchFormFailure = createAction(
  EVALUATIONS_TEMPLATES_FETCH_FORM_FAILURE
);
// Edit a Evaluation TEMPLATE form
export const EVALUATIONS_TEMPLATES_EDIT: string = "EVALUATIONS/TEMPLATES/EDIT";
export const EVALUATIONS_TEMPLATES_EDIT_REQUEST: string =
  "EVALUATIONS/TEMPLATES/EDIT/REQUEST";
export const EVALUATIONS_TEMPLATES_EDIT_SUCCESS: string =
  "EVALUATIONS/TEMPLATES/EDIT/SUCCESS";
export const EVALUATIONS_TEMPLATES_EDIT_FAILURE: string =
  "EVALUATIONS/TEMPLATES/EDIT/FAILURE";
export const evaluationsTemplatesEdit = createAction(
  EVALUATIONS_TEMPLATES_EDIT,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsTemplatesEditRequest = createAction(
  EVALUATIONS_TEMPLATES_EDIT_REQUEST
);
export const evaluationsTemplatesEditSuccess = createAction(
  EVALUATIONS_TEMPLATES_EDIT_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsTemplatesEditFailure = createAction(
  EVALUATIONS_TEMPLATES_EDIT_FAILURE
);
// Delete Evaluation TEMPLATE form
export const EVALUATIONS_TEMPLATES_DELETE: string =
  "EVALUATIONS/TEMPLATES/DELETE";
export const EVALUATIONS_TEMPLATES_DELETE_REQUEST: string =
  "EVALUATIONS/TEMPLATES/DELETE/REQUEST";
export const EVALUATIONS_TEMPLATES_DELETE_SUCCESS: string =
  "EVALUATIONS/TEMPLATES/DELETE/SUCCESS";
export const EVALUATIONS_TEMPLATES_DELETE_FAILURE: string =
  "EVALUATIONS/TEMPLATES/DELETE/FAILURE";
export const evaluationsTemplatesDelete = createAction(
  EVALUATIONS_TEMPLATES_DELETE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsTemplatesDeleteRequest = createAction(
  EVALUATIONS_TEMPLATES_DELETE_REQUEST
);
export const evaluationsTemplatesDeleteSuccess = createAction(
  EVALUATIONS_TEMPLATES_DELETE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const evaluationsTemplatesDeleteFailure = createAction(
  EVALUATIONS_TEMPLATES_DELETE_FAILURE
);
// Reset Template Loaded
export const EVALUATIONS_TEMPLATES_RESET_EVALUATION_TEMPLATE_LOADED: string =
  "EVALUATIONS/TEMPLATES/RESET/EVALUATION_TEMPLATE_LOADED";
export const evaluationsTemplatesResetTemplateLoaded = createAction(
  EVALUATIONS_TEMPLATES_RESET_EVALUATION_TEMPLATE_LOADED
);
// Create/Edit new Form template actions
// Add option to array formOptions
export const EVALUATIONS_TEMPLATES_FORM_OPTIONS_ADD: string =
  "EVALUATIONS/TEMPLATES/FORM/OPTIONS/ADD";
export const evaluationsTemplatesFormOptionAdd = createAction(
  EVALUATIONS_TEMPLATES_FORM_OPTIONS_ADD,
  (resolve) => (payload?: object) => resolve(payload)
);
// Sort array formOptions
export const EVALUATIONS_TEMPLATES_FORM_OPTIONS_UPDATE: string =
  "EVALUATIONS/TEMPLATES/FORM/OPTIONS/UPDATE";
export const evaluationsTemplatesFormOptionUpdate = createAction(
  EVALUATIONS_TEMPLATES_FORM_OPTIONS_UPDATE,
  (resolve) => (payload?: object) => resolve(payload)
);
