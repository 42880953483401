import { createAction } from "deox";
// Actions for Notification

// SOCKET
// Register Socket
export const NOTIFICATIONS_REGISTER_SOCKET: string =
  "NOTIFICATIONS/REGISTER/SOCKET";
export const NOTIFICATIONS_REGISTER_SOCKET_REQUEST: string =
  "NOTIFICATIONS/REGISTER/SOCKET/REQUEST";
export const NOTIFICATIONS_REGISTER_SOCKET_SUCCESS: string =
  "NOTIFICATIONS/REGISTER/SOCKET/SUCCESS";
export const NOTIFICATIONS_REGISTER_SOCKET_FAILURE: string =
  "NOTIFICATIONS/REGISTER/SOCKET/FAILURE";
export const notificationsRegisterSocket = createAction(
  NOTIFICATIONS_REGISTER_SOCKET,
  (resolve) => (payload?: object) => resolve(payload)
);
export const notificationsRegisterSocketRequest = createAction(
  NOTIFICATIONS_REGISTER_SOCKET_REQUEST
);
export const notificationsRegisterSocketSuccess = createAction(
  NOTIFICATIONS_REGISTER_SOCKET_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const notificationsRegisterSocketFailure = createAction(
  NOTIFICATIONS_REGISTER_SOCKET_FAILURE
);
// Set socket to start getting notifications with one socketConnectionId
export const NOTIFICATIONS_SET_SOCKET_CONNECTION_ID: string =
  "NOTIFICATIONS/SET/SOCKET/CONNECTION_ID";
export const NOTIFICATIONS_SET_SOCKET_CONNECTION_ID_REQUEST: string =
  "NOTIFICATIONS/SET/SOCKET/CONNECTION_ID/REQUEST";
export const NOTIFICATIONS_SET_SOCKET_CONNECTION_ID_SUCCESS: string =
  "NOTIFICATIONS/SET/SOCKET/CONNECTION_ID/SUCCESS";
export const NOTIFICATIONS_SET_SOCKET_CONNECTION_ID_FAILURE: string =
  "NOTIFICATIONS/SET/SOCKET/CONNECTION_ID/FAILURE";
export const notificationsSetSocketConnectionId = createAction(
  NOTIFICATIONS_SET_SOCKET_CONNECTION_ID,
  (resolve) => (payload?: object) => resolve(payload)
);
export const notificationsSetSocketConnectionIdRequest = createAction(
  NOTIFICATIONS_SET_SOCKET_CONNECTION_ID_REQUEST,
  (resolve) => (payload?: object) => resolve(payload)
);
export const notificationsSetSocketConnectionIdSuccess = createAction(
  NOTIFICATIONS_SET_SOCKET_CONNECTION_ID_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const notificationsSetSocketConnectionIdFailure = createAction(
  NOTIFICATIONS_SET_SOCKET_CONNECTION_ID_FAILURE
);
// Disconnect socket
export const NOTIFICATIONS_DISCONNECT_SOCKET: string =
  "NOTIFICATIONS/DISCONNECT/SOCKET";
export const notificationsDisconnectSocket = createAction(
  NOTIFICATIONS_DISCONNECT_SOCKET,
  (resolve) => (payload?: object) => resolve(payload)
);
// Add some notification from socket
export const NOTIFICATIONS_SOCKET_ADD_NOTIFICATION: string =
  "NOTIFICATIONS/SOCKET/ADD/NOTIFICATION";
export const notificationsSocketAddNotification = createAction(
  NOTIFICATIONS_SOCKET_ADD_NOTIFICATION,
  (resolve) => (payload?: object) => resolve(payload)
);
// NOTIFICATIONS
// Fetch Notifications
export const NOTIFICATIONS_FETCH_NOTIFICATIONS: string =
  "NOTIFICATIONS/FETCH/NOTIFICATIONS";
export const NOTIFICATIONS_FETCH_NOTIFICATIONS_REQUEST: string =
  "NOTIFICATIONS/FETCH/NOTIFICATIONS/REQUEST";
export const NOTIFICATIONS_FETCH_NOTIFICATIONS_SUCCESS: string =
  "NOTIFICATIONS/FETCH/NOTIFICATIONS/SUCCESS";
export const NOTIFICATIONS_FETCH_NOTIFICATIONS_FAILURE: string =
  "NOTIFICATIONS/FETCH/NOTIFICATIONS/FAILURE";
export const notificationsFetchNotifications = createAction(
  NOTIFICATIONS_FETCH_NOTIFICATIONS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const notificationsFetchNotificationsRequest = createAction(
  NOTIFICATIONS_FETCH_NOTIFICATIONS_REQUEST,
  (resolve) => (payload?: object) => resolve(payload)
);
export const notificationsFetchNotificationsSuccess = createAction(
  NOTIFICATIONS_FETCH_NOTIFICATIONS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const notificationsFetchNotificationsFailure = createAction(
  NOTIFICATIONS_FETCH_NOTIFICATIONS_FAILURE
);
// Set Notifications Categories
export const NOTIFICATIONS_SET_NOTIFICATIONS_CATEGORIES: string =
  "NOTIFICATIONS/SET/NOTIFICATIONS_CATEGORIES";
export const notificationsSetNotificationsCategories = createAction(
  NOTIFICATIONS_SET_NOTIFICATIONS_CATEGORIES,
  (resolve) => (payload?: object) => resolve(payload)
);
// Clear Notifications Categories
export const NOTIFICATIONS_CLEAR_NOTIFICATIONS_CATEGORIES: string =
  "NOTIFICATIONS/CLEAR/NOTIFICATIONS_CATEGORIES";
export const notificationsClearNotificationsCategories = createAction(
  NOTIFICATIONS_CLEAR_NOTIFICATIONS_CATEGORIES,
  (resolve) => (payload?: object) => resolve(payload)
);
// Mark as read a list of notifications
export const NOTIFICATIONS_MARK_AS_READ_NOTIFICATIONS: string =
  "NOTIFICATIONS/MARK_AS_READ/NOTIFICATIONS";
export const NOTIFICATIONS_MARK_AS_READ_NOTIFICATIONS_REQUEST: string =
  "NOTIFICATIONS/MARK_AS_READ/NOTIFICATIONS/REQUEST";
export const NOTIFICATIONS_MARK_AS_READ_NOTIFICATIONS_SUCCESS: string =
  "NOTIFICATIONS/MARK_AS_READ/NOTIFICATIONS/SUCCESS";
export const NOTIFICATIONS_MARK_AS_READ_NOTIFICATIONS_FAILURE: string =
  "NOTIFICATIONS/MARK_AS_READ/NOTIFICATIONS/FAILURE";
export const notificationsMarkAsReadNotifications = createAction(
  NOTIFICATIONS_MARK_AS_READ_NOTIFICATIONS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const notificationsMarkAsReadNotificationsRequest = createAction(
  NOTIFICATIONS_MARK_AS_READ_NOTIFICATIONS_REQUEST
);
export const notificationsMarkAsReadNotificationsSuccess = createAction(
  NOTIFICATIONS_MARK_AS_READ_NOTIFICATIONS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const notificationsMarkAsReadNotificationsFailure = createAction(
  NOTIFICATIONS_MARK_AS_READ_NOTIFICATIONS_FAILURE
);
// Update last seen notification
export const NOTIFICATIONS_LAST_SEEN_NOTIFICATION: string =
  "NOTIFICATIONS/LAST_SEEN/NOTIFICATION";
export const NOTIFICATIONS_LAST_SEEN_NOTIFICATION_REQUEST: string =
  "NOTIFICATIONS/LAST_SEEN/NOTIFICATION/REQUEST";
export const NOTIFICATIONS_LAST_SEEN_NOTIFICATION_SUCCESS: string =
  "NOTIFICATIONS/LAST_SEEN/NOTIFICATION/SUCCESS";
export const NOTIFICATIONS_LAST_SEEN_NOTIFICATION_FAILURE: string =
  "NOTIFICATIONS/LAST_SEEN/NOTIFICATION/FAILURE";
export const notificationsLastSeenNotification = createAction(
  NOTIFICATIONS_LAST_SEEN_NOTIFICATION,
  (resolve) => (payload?: object) => resolve(payload)
);
export const notificationsLastSeenNotificationRequest = createAction(
  NOTIFICATIONS_LAST_SEEN_NOTIFICATION_REQUEST
);
export const notificationsLastSeenNotificationSuccess = createAction(
  NOTIFICATIONS_LAST_SEEN_NOTIFICATION_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const notificationsLastSeenNotificationFailure = createAction(
  NOTIFICATIONS_LAST_SEEN_NOTIFICATION_FAILURE
);
// Delete a list of notifications or a notification
export const NOTIFICATIONS_DELETE: string = "NOTIFICATIONS/DELETE";
export const NOTIFICATIONS_DELETE_REQUEST: string =
  "NOTIFICATIONS/DELETE/REQUEST";
export const NOTIFICATIONS_DELETE_SUCCESS: string =
  "NOTIFICATIONS/DELETE/SUCCESS";
export const NOTIFICATIONS_DELETE_FAILURE: string =
  "NOTIFICATIONS/DELETE/FAILURE";
export const notificationsDelete = createAction(
  NOTIFICATIONS_DELETE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const notificationsDeleteRequest = createAction(
  NOTIFICATIONS_DELETE_REQUEST
);
export const notificationsDeleteSuccess = createAction(
  NOTIFICATIONS_DELETE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const notificationsDeleteFailure = createAction(
  NOTIFICATIONS_DELETE_FAILURE
);
// TODO:
// Delete Reminders, it is not being used
// Create a Reminders Notification
export const NOTIFICATIONS_CREATE_REMINDERS: string =
  "NOTIFICATIONS/CREATE/REMINDERS";
export const NOTIFICATIONS_CREATE_REMINDERS_REQUEST: string =
  "NOTIFICATIONS/CREATE/REMINDERS/REQUEST";
export const NOTIFICATIONS_CREATE_REMINDERS_SUCCESS: string =
  "NOTIFICATIONS/CREATE/REMINDERS/SUCCESS";
export const NOTIFICATIONS_CREATE_REMINDERS_FAILURE: string =
  "NOTIFICATIONS/CREATE/REMINDERS/FAILURE";
export const notificationsCreateReminders = createAction(
  NOTIFICATIONS_CREATE_REMINDERS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const notificationsCreateRemindersRequest = createAction(
  NOTIFICATIONS_CREATE_REMINDERS_REQUEST
);
export const notificationsCreateRemindersSuccess = createAction(
  NOTIFICATIONS_CREATE_REMINDERS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const notificationsCreateRemindersFailure = createAction(
  NOTIFICATIONS_CREATE_REMINDERS_FAILURE
);
