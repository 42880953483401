import { createAction } from "deox";
// Actions for USERS

// Fetch users KPIS (Numbers of users by role)
export const USERS_FETCH_USERS_KPIS: string = "USERS/FETCH/USERS_KPIS";
export const USERS_FETCH_USERS_KPIS_REQUEST: string =
  "USERS/FETCH/USERS_KPIS/REQUEST";
export const USERS_FETCH_USERS_KPIS_SUCCESS: string =
  "USERS/FETCH/USERS_KPIS/SUCCESS";
export const USERS_FETCH_USERS_KPIS_FAILURE: string =
  "USERS/FETCH/USERS_KPIS/FAILURE";
export const usersFetchUsersKpis = createAction(
  USERS_FETCH_USERS_KPIS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const usersFetchUsersKpisRequest = createAction(
  USERS_FETCH_USERS_KPIS_REQUEST
);
export const usersFetchUsersKpisSuccess = createAction(
  USERS_FETCH_USERS_KPIS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const usersFetchUsersKpisFailure = createAction(
  USERS_FETCH_USERS_KPIS_FAILURE
);
// Fetch all Users (without roles)
export const USERS_FETCH_ALL: string = "USERS/FETCH/ALL";
export const USERS_FETCH_ALL_REQUEST: string = "USERS/FETCH/ALL/REQUEST";
export const USERS_FETCH_ALL_SUCCESS: string = "USERS/FETCH/ALL/SUCCESS";
export const USERS_FETCH_ALL_FAILURE: string = "USERS/FETCH/ALL/FAILURE";
export const usersFetchAll = createAction(
  USERS_FETCH_ALL,
  (resolve) => (payload?: object) => resolve(payload)
);
export const usersFetchAllRequest = createAction(USERS_FETCH_ALL_REQUEST);
export const usersFetchAllSuccess = createAction(
  USERS_FETCH_ALL_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const usersFetchAllFailure = createAction(USERS_FETCH_ALL_FAILURE);
// Fetch all Users (with Roles)
export const USERS_FETCH_USERS_ROLES: string = "USERS/FETCH/USERS_ROLES";
export const USERS_FETCH_USERS_ROLES_REQUEST: string =
  "USERS/FETCH/USERS_ROLES/REQUEST";
export const USERS_FETCH_USERS_ROLES_SUCCESS: string =
  "USERS/FETCH/USERS_ROLES/SUCCESS";
export const USERS_FETCH_USERS_ROLES_FAILURE: string =
  "USERS/FETCH/USERS_ROLES/FAILURE";
export const usersFetchUsersRoles = createAction(
  USERS_FETCH_USERS_ROLES,
  (resolve) => (payload?: object) => resolve(payload)
);
export const usersFetchUsersRolesRequest = createAction(
  USERS_FETCH_USERS_ROLES_REQUEST
);
export const usersFetchUsersRolesSuccess = createAction(
  USERS_FETCH_USERS_ROLES_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const usersFetchUsersRolesFailure = createAction(
  USERS_FETCH_USERS_ROLES_FAILURE,
  (resolve) => (payload?: object) => resolve(payload)
);
// Set UserId to Fetch this users
export const USERS_SET_USERID: string = "USERS/SET/USERID";
export const usersSetUserId = createAction(
  USERS_SET_USERID,
  (resolve) => (payload?: object) => resolve(payload)
);
// Unset UserId
export const USERS_UNSET_USERID: string = "USERS/UNSET/USERID";
export const usersUnsetUserId = createAction(
  USERS_UNSET_USERID,
  (resolve) => (payload?: object) => resolve(payload)
);
// Fetch user
export const USERS_FETCH_USER: string = "USERS/FETCH/USER";
export const USERS_FETCH_USER_REQUEST: string = "USERS/FETCH/USER/REQUEST";
export const USERS_FETCH_USER_SUCCESS: string = "USERS/FETCH/USER/SUCCESS";
export const USERS_FETCH_USER_FAILURE: string = "USERS/FETCH/USER/FAILURE";
export const usersFetchUser = createAction(
  USERS_FETCH_USER,
  (resolve) => (payload?: object) => resolve(payload)
);
export const usersFetchUserRequest = createAction(USERS_FETCH_USER_REQUEST);
export const usersFetchUserSuccess = createAction(
  USERS_FETCH_USER_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const usersFetchUserFailure = createAction(USERS_FETCH_USER_FAILURE);
// Fetch user KPIS
export const USERS_FETCH_USER_KPIS: string = "USERS/FETCH/USER_KPIS";
export const USERS_FETCH_USER_KPIS_REQUEST: string =
  "USERS/FETCH/USER_KPIS/REQUEST";
export const USERS_FETCH_USER_KPIS_SUCCESS: string =
  "USERS/FETCH/USER_KPIS/SUCCESS";
export const USERS_FETCH_USER_KPIS_FAILURE: string =
  "USERS/FETCH/USER_KPIS/FAILURE";
export const usersFetchUserKpis = createAction(
  USERS_FETCH_USER_KPIS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const usersFetchUserKpisRequest = createAction(
  USERS_FETCH_USER_KPIS_REQUEST
);
export const usersFetchUserKpisSuccess = createAction(
  USERS_FETCH_USER_KPIS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const usersFetchUserKpisFailure = createAction(
  USERS_FETCH_USER_KPIS_FAILURE
);
// Fetch user AVAILABILITIES
export const USERS_FETCH_USER_AVAILABILITIES: string =
  "USERS/FETCH/USER_AVAILABILITIES";
export const USERS_FETCH_USER_AVAILABILITIES_REQUEST: string =
  "USERS/FETCH/USER_AVAILABILITIES/REQUEST";
export const USERS_FETCH_USER_AVAILABILITIES_SUCCESS: string =
  "USERS/FETCH/USER_AVAILABILITIES/SUCCESS";
export const USERS_FETCH_USER_AVAILABILITIES_FAILURE: string =
  "USERS/FETCH/USER_AVAILABILITIES/FAILURE";
export const usersFetchUserAvailabilities = createAction(
  USERS_FETCH_USER_AVAILABILITIES,
  (resolve) => (payload?: object) => resolve(payload)
);
export const usersFetchUserAvailabilitiesRequest = createAction(
  USERS_FETCH_USER_AVAILABILITIES_REQUEST
);
export const usersFetchUserAvailabilitiesSuccess = createAction(
  USERS_FETCH_USER_AVAILABILITIES_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const usersFetchUserAvailabilitiesFailure = createAction(
  USERS_FETCH_USER_AVAILABILITIES_FAILURE
);
// Edit users (bulk actions)
export const USERS_EDIT_USERS: string = "USERS/EDIT/USERS";
export const USERS_EDIT_USERS_REQUEST: string = "USERS/EDIT/USERS/REQUEST";
export const USERS_EDIT_USERS_SUCCESS: string = "USERS/EDIT/USERS/SUCCESS";
export const USERS_EDIT_USERS_FAILURE: string = "USERS/EDIT/USERS/FAILURE";
export const usersEditUsers = createAction(
  USERS_EDIT_USERS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const usersEditUsersRequest = createAction(USERS_EDIT_USERS_REQUEST);
export const usersEditUsersSuccess = createAction(
  USERS_EDIT_USERS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const usersEditUsersFailure = createAction(USERS_EDIT_USERS_FAILURE);
// Edit user
export const USERS_EDIT_USER: string = "USERS/EDIT/USER";
export const USERS_EDIT_USER_REQUEST: string = "USERS/EDIT/USER/REQUEST";
export const USERS_EDIT_USER_SUCCESS: string = "USERS/EDIT/USER/SUCCESS";
export const USERS_EDIT_USER_FAILURE: string = "USERS/EDIT/USER/FAILURE";
export const usersEditUser = createAction(
  USERS_EDIT_USER,
  (resolve) => (payload?: object) => resolve(payload)
);
export const usersEditUserRequest = createAction(USERS_EDIT_USER_REQUEST);
export const usersEditUserSuccess = createAction(
  USERS_EDIT_USER_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const usersEditUserFailure = createAction(USERS_EDIT_USER_FAILURE);
// Edit user AVAILABILITIES
export const USERS_EDIT_USER_AVAILABILITIES: string =
  "USERS/EDIT/USER_AVAILABILITIES";
export const USERS_EDIT_USER_AVAILABILITIES_REQUEST: string =
  "USERS/EDIT/USER_AVAILABILITIES/REQUEST";
export const USERS_EDIT_USER_AVAILABILITIES_SUCCESS: string =
  "USERS/EDIT/USER_AVAILABILITIES/SUCCESS";
export const USERS_EDIT_USER_AVAILABILITIES_FAILURE: string =
  "USERS/EDIT/USER_AVAILABILITIES/FAILURE";
export const usersEditUserAvailabilities = createAction(
  USERS_EDIT_USER_AVAILABILITIES,
  (resolve) => (payload?: object) => resolve(payload)
);
export const usersEditUserAvailabilitiesRequest = createAction(
  USERS_EDIT_USER_AVAILABILITIES_REQUEST
);
export const usersEditUserAvailabilitiesSuccess = createAction(
  USERS_EDIT_USER_AVAILABILITIES_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const usersEditUserAvailabilitiesFailure = createAction(
  USERS_EDIT_USER_AVAILABILITIES_FAILURE
);
// Set User to State Users into key user
export const USERS_SET_USER: string = "USERS/SET/USER";
export const usersSetUser = createAction(
  USERS_SET_USER,
  (resolve) => (payload?: object) => resolve(payload)
);
// Fetch user Files
export const USERS_FETCH_USER_FILES: string = "USERS/FETCH/USER_FILES";
export const USERS_FETCH_USER_FILES_REQUEST: string =
  "USERS/FETCH/USER_FILES/REQUEST";
export const USERS_FETCH_USER_FILES_SUCCESS: string =
  "USERS/FETCH/USER_FILES/SUCCESS";
export const USERS_FETCH_USER_FILES_FAILURE: string =
  "USERS/FETCH/USER_FILES/FAILURE";
export const usersFetchUserFiles = createAction(
  USERS_FETCH_USER_FILES,
  (resolve) => (payload?: object) => resolve(payload)
);
export const usersFetchUserFilesRequest = createAction(
  USERS_FETCH_USER_FILES_REQUEST
);
export const usersFetchUserFilesSuccess = createAction(
  USERS_FETCH_USER_FILES_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const usersFetchUserFilesFailure = createAction(
  USERS_FETCH_USER_FILES_FAILURE
);
// Fetch all user Files Expired
export const USERS_FETCH_USER_FILES_EXPIRED: string =
  "USERS/FETCH/USER_FILES_EXPIRED";
export const USERS_FETCH_USER_FILES_EXPIRED_REQUEST: string =
  "USERS/FETCH/USER_FILES_EXPIRED/REQUEST";
export const USERS_FETCH_USER_FILES_EXPIRED_SUCCESS: string =
  "USERS/FETCH/USER_FILES_EXPIRED/SUCCESS";
export const USERS_FETCH_USER_FILES_EXPIRED_FAILURE: string =
  "USERS/FETCH/USER_FILES_EXPIRED/FAILURE";
export const usersFetchUserFilesExpired = createAction(
  USERS_FETCH_USER_FILES_EXPIRED,
  (resolve) => (payload?: object) => resolve(payload)
);
export const usersFetchUserFilesExpiredRequest = createAction(
  USERS_FETCH_USER_FILES_EXPIRED_REQUEST
);
export const usersFetchUserFilesExpiredSuccess = createAction(
  USERS_FETCH_USER_FILES_EXPIRED_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const usersFetchUserFilesExpiredFailure = createAction(
  USERS_FETCH_USER_FILES_EXPIRED_FAILURE
);
// Upload user Files
export const USERS_UPLOAD_USER_FILES: string = "USERS/UPLOAD/USER_FILES";
export const USERS_UPLOAD_USER_FILES_REQUEST: string =
  "USERS/UPLOAD/USER_FILES/REQUEST";
export const USERS_UPLOAD_USER_FILES_SUCCESS: string =
  "USERS/UPLOAD/USER_FILES/SUCCESS";
export const USERS_UPLOAD_USER_FILES_FAILURE: string =
  "USERS/UPLOAD/USER_FILES/FAILURE";
export const usersUploadUserFiles = createAction(
  USERS_UPLOAD_USER_FILES,
  (resolve) => (payload?: object) => resolve(payload)
);
export const usersUploadUserFilesRequest = createAction(
  USERS_UPLOAD_USER_FILES_REQUEST
);
export const usersUploadUserFilesSuccess = createAction(
  USERS_UPLOAD_USER_FILES_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const usersUploadUserFilesFailure = createAction(
  USERS_UPLOAD_USER_FILES_FAILURE
);
// Emulate user
export const USERS_EMULATE_USER: string = "USERS/EMULATE/USER";
export const USERS_EMULATE_USER_REQUEST: string = "USERS/EMULATE/USER/REQUEST";
export const USERS_EMULATE_USER_SUCCESS: string = "USERS/EMULATE/USER/SUCCESS";
export const USERS_EMULATE_USER_FAILURE: string = "USERS/EMULATE/USER/FAILURE";
export const usersEmulateUser = createAction(
  USERS_EMULATE_USER,
  (resolve) => (payload?: object) => resolve(payload)
);
export const usersEmulateUserRequest = createAction(USERS_EMULATE_USER_REQUEST);
export const usersEmulateUserSuccess = createAction(
  USERS_EMULATE_USER_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const usersEmulateUserFailure = createAction(USERS_EMULATE_USER_FAILURE);
