// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import loadable from "@loadable/component";
// Import Components
import Loader from "../shared/Loader";
import LoadedRoute from "../shared/LoadedRoute";
// PERMISSIONS
import {
  onCheckUsersManagerPermissions,
  onCheckUserInvitationsManagerPermissions,
  onCheckUserManagerActionCreateEditRotationPermissions,
} from "./ManagerPermissions";
import { onCheckImportDataPermissions } from "../import/ImportDataPermissions";
import { onCheckCreateEvaluationSchedulersPermissions } from "../evaluations-schedulers/EvaluationSchedulersPermissions";
import {
  onCheckMyCompaniesFilesPermissions,
  onCheckReadInstitutionsFilesPermissions,
  onCheckReadMyInstitutionsFilesPermissions,
  onCheckAllUsersFilesPermissions,
} from "../files/FilesPermissions";
import {
  onCheckReadInstitutionsPermissions,
  onCheckReadMyInstitutionsPermissions,
} from "../institutions/InstitutionsPermissions";
// />
// Dynamic Import (Code Splitting):
const fallback = (
  <Loader className="loading-component" textLoading="Loading ..." />
);
const ManagerContainer = loadable(() => import("./ManagerContainer"), {
  fallback,
});
const UserManagerContainer = loadable(
  () => import("../user-manager/UserManagerContainer"),
  {
    fallback,
  }
);
const UserProfileContainer = loadable(
  () => import("../user-profile/UserProfileContainer"),
  {
    fallback,
  }
);
const UserFilesContainer = loadable(
  () => import("../user-files/UserFilesContainer"),
  {
    fallback,
  }
);
const UserPortfolioContainer = loadable(
  () => import("../user-portfolio/UserPortfolioContainer"),
  {
    fallback,
  }
);
const UserInvitationManagerContainer = loadable(
  () => import("../user-invitation-manager/UserInvitationManagerContainer"),
  {
    fallback,
  }
);
const UsersImportDataContainer = loadable(
  () => import("../users-import-data/UsersImportDataContainer"),
  {
    fallback,
  }
);
///////////////////////////////////
let lastComponentLoaded: Object = {};
const saveComponent = (stateProps: Object) => {
  lastComponentLoaded = stateProps;
  return stateProps;
};
const mapStateToProps = (state: Object, ownProps: Object) => {
  const { location } = ownProps;
  const {
    routerBeforeTransitions,
    config,
    auth: { isAuthenticated, user, role, myInstitution },
  } = state;
  const setImg = (_imgOrUrl_: string, updatedAt: string): string => {
    if (_imgOrUrl_.indexOf("http") !== -1) {
      return _imgOrUrl_;
    }
    return [config.baseImg, _imgOrUrl_, `&updatedAt=${updatedAt}`].join("");
  };
  const defaultManagerContainer = () => {
    return saveComponent({
      component: ManagerContainer,
      restProps: {
        components: lastComponentLoaded.restProps
          ? [lastComponentLoaded.restProps.components[0]]
          : [UserManagerContainer],
        baseClassName: lastComponentLoaded.restProps
          ? lastComponentLoaded.restProps.baseClassName
          : "usermanager",
        openUserManager: false,
        setImg,
      },
      isAllowedRoute:
        config.companyDomain.length > 0 &&
        isAuthenticated &&
        onCheckUsersManagerPermissions(role.permissions),
    });
  };
  if (location.pathname && location.pathname.indexOf("/manager") > -1) {
    let pathname = location.pathname;
    //Check routes clinics
    if (location.pathname.indexOf("/manager/profile/") !== -1) {
      pathname = "/manager/profile/";
    }
    switch (pathname) {
      case "/manager":
        return saveComponent({
          component: ManagerContainer,
          restProps: {
            components: [UserManagerContainer],
            baseClassName: "usermanager",
            openUserManager: true,
            routerBeforeTransitions,
            authUserId: isAuthenticated ? user._id : null,
            authPermissions: isAuthenticated ? role.permissions : null,
            authRoleType: isAuthenticated ? role.type : null,
            setImg,
            canInviteUsers:
              isAuthenticated &&
              onCheckUserInvitationsManagerPermissions(role.permissions),
            canImportUsers:
              isAuthenticated && onCheckImportDataPermissions(role.permissions),
            canCreateEditRotations:
              isAuthenticated &&
              onCheckUserManagerActionCreateEditRotationPermissions(
                role.permissions
              ),
            canCreateEvaluationSchedulers:
              isAuthenticated &&
              onCheckCreateEvaluationSchedulersPermissions(role.permissions),
          },
          isAllowedRoute:
            config.companyDomain.length > 0 &&
            isAuthenticated &&
            onCheckUsersManagerPermissions(role.permissions),
        });
      case "/manager/profile/":
        if (location.pathname.indexOf("/documents") !== -1) {
          const { before } = state.routerManager;
          return saveComponent({
            component: ManagerContainer,
            restProps: {
              components: [UserFilesContainer],
              baseClassName: "userfiles",
              openUserManager: true,
              routerBeforeTransitions,
              openUploadDocuments:
                location.pathname.indexOf("/documents/upload") !== -1,
              routerManagerBefore: before,
              authUserId: isAuthenticated ? user._id : null,
              authPermissions: isAuthenticated ? role.permissions : null,
              authRoleType: isAuthenticated ? role.type : null,
              authRoleApiName: isAuthenticated ? role.apiName : null,
              setImg,
            },
            isAllowedRoute:
              config.companyDomain.length > 0 &&
              isAuthenticated &&
              onCheckAllUsersFilesPermissions(role.permissions),
          });
        } else if (location.pathname.indexOf("/portfolio") !== -1) {
          const { before } = state.routerManager;
          return saveComponent({
            component: ManagerContainer,
            restProps: {
              components: [UserPortfolioContainer],
              baseClassName: "userportfolio",
              openUserManager: true,
              routerBeforeTransitions,
              routerManagerBefore: before,
              authPermissions: isAuthenticated ? role.permissions : null,
              authRoleType: isAuthenticated ? role.type : null,
              authRoleApiName: isAuthenticated ? role.apiName : null,
              setImg,
            },
            isAllowedRoute: config.companyDomain.length > 0 && isAuthenticated,
          });
        }
        return saveComponent({
          component: ManagerContainer,
          restProps: {
            components: [UserProfileContainer],
            baseClassName: "userprofile",
            openUserManager: true,
            routerBeforeTransitions,
            authUserId: isAuthenticated ? user._id : null,
            authPermissions: isAuthenticated ? role.permissions : null,
            authRoleType: isAuthenticated ? role.type : null,
            authRoleApiName: isAuthenticated ? role.apiName : null,
            hasMyInstitution: myInstitution && myInstitution.loaded && true,
            setImg,
            canReadAllInstitutions:
              isAuthenticated &&
              onCheckReadInstitutionsPermissions(role.permissions),
            canReadMyInstitutions:
              isAuthenticated &&
              onCheckReadMyInstitutionsPermissions(role.permissions) &&
              (user.institution || user.activeRotation),
            canReadMyCompaniesFiles:
              isAuthenticated &&
              onCheckMyCompaniesFilesPermissions(role.permissions),
            canReadInstitutionsFiles:
              isAuthenticated &&
              (onCheckReadInstitutionsFilesPermissions(role.permissions) ||
                onCheckReadMyInstitutionsFilesPermissions(role.permissions)),
            canReadMyInstitutionsFiles:
              isAuthenticated &&
              onCheckReadMyInstitutionsFilesPermissions(role.permissions),
            canReadMyFiles: false,
          },
          isAllowedRoute:
            config.companyDomain.length > 0 &&
            isAuthenticated &&
            onCheckUsersManagerPermissions(role.permissions),
        });
      case "/manager/invitations":
      case "/manager/invitations/create":
        return saveComponent({
          component: ManagerContainer,
          restProps: {
            components: [UserInvitationManagerContainer],
            baseClassName: "userinvitation",
            openUserManager: true,
            routerBeforeTransitions,
            authPermissions: isAuthenticated ? role.permissions : null,
            authRoleType: isAuthenticated ? role.type : null,
            institutionId:
              isAuthenticated && user.institution ? user.institution._id : null,
            setImg,
          },
          isAllowedRoute:
            config.companyDomain.length > 0 &&
            isAuthenticated &&
            onCheckUserInvitationsManagerPermissions(role.permissions),
        });
      case "/manager/import-users":
      case "/manager/import-users/generate-csv":
      case "/manager/import-users/status":
        return saveComponent({
          component: ManagerContainer,
          restProps: {
            components: [UserManagerContainer, UsersImportDataContainer],
            baseClassName: "usermanager",
            openUserManager: true,
            routerBeforeTransitions,
            authPermissions: isAuthenticated ? role.permissions : null,
            authRoleType: isAuthenticated ? role.type : null,
            setImg,
          },
          isAllowedRoute:
            config.companyDomain.length > 0 &&
            isAuthenticated &&
            onCheckImportDataPermissions(role.permissions),
        });
      default:
        return defaultManagerContainer();
    }
  }
  return defaultManagerContainer();
};

const RouteManagerCompoundContainer: React.ComponentType<any> = withRouter(
  connect(mapStateToProps)(LoadedRoute)
);

export default RouteManagerCompoundContainer;
