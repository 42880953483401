import { put, select, call, all, takeLatest } from "redux-saga/effects";
import FilesApi from "../api/Files";
import { Auth, Files } from "../actions/";
import { downloadFile } from "../../utils/";
// Type
import type { Action } from "deox";
import type { tAPI } from "../api/API";
//Sagas for Files
const onParseEditFiles = (
  files: { [k: string]: any }[],
  fileId: string,
  body: object
): object[] | undefined | null => {
  let file = null,
    fileIndex = -1;
  for (let i = 0; i < files.length; i++) {
    file = files[i];
    if (fileId === file._id) {
      fileIndex = i;
      break;
    }
  }
  if (fileIndex > -1) {
    const newFiles = [
      ...files.slice(0, fileIndex),
      { ...file, ...body },
      ...files.slice(fileIndex + 1),
    ];
    return newFiles;
  }
  return files;
};

function* doFilesEdit(
  action: Action<
    string,
    {
      files: { [k: string]: any }[];
      fileId: string;
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Files.filesEditRequest());
  window.logger.log("doFilesEdit", action);
  const {
    files,
    fileId,
    body,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to update a file for that specific fileId
    const { data } = yield call(FilesApi.EditFile, api, fileId, body, {
      token,
    });
    const { file } = data;
    const fileCategoryArray: any[] = yield select(
      ({ fileCategories: { fileCategories } }) =>
        fileCategories.filter(({ _id }: any) => _id === file.category)
    );
    const newFiles = onParseEditFiles(files, fileId, {
      ...body,
      category: fileCategoryArray[0],
    });
    yield put(
      Files.filesEditSuccess({
        files: newFiles,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(newFiles);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doFilesEdit", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Files.filesEditFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doFilesDelete(
  action: Action<
    string,
    {
      files: { [k: string]: any }[];
      fileId: string;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Files.filesDeleteRequest());
  window.logger.log("doFilesDelete", action);
  const {
    files,
    fileId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to delete a file for that specific fileId
    yield call(FilesApi.DeleteFile, api, fileId, { token });
    const newFiles = files.filter(({ _id }) => _id !== fileId);
    yield put(Files.filesDeleteSuccess({ files: newFiles }));
    if (callbackSuccess) {
      callbackSuccess(newFiles);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doFilesDelete", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Files.filesDeleteFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doFilesDownload(
  action: Action<
    string,
    {
      filesIDs: string[];
      fileName: string;
      extension: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Files.filesDownloadRequest());
  window.logger.log("doFilesDownload", action);
  const {
    filesIDs,
    fileName,
    extension,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to download a file for that specific fileId
    const {
      data: { blob: blobURL },
    } = yield call(FilesApi.DownloadFile, api, { files: filesIDs }, extension, {
      token,
    });
    downloadFile(fileName, blobURL);
    yield put(Files.filesDownloadSuccess());
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doFilesDownload", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Files.filesDownloadFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doFilesPreview(
  action: Action<
    string,
    {
      filesIDs: string[];
      extension: string;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(Files.filesPreviewRequest());
  window.logger.log("doFilesPreview", action);
  const {
    filesIDs,
    extension,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to download a file for that specific fileId and show in a viewer
    const {
      data: { blob: blobURL },
    } = yield call(FilesApi.DownloadFile, api, { files: filesIDs }, extension, {
      token,
    });
    yield put(Files.filesPreviewSuccess());
    if (callbackSuccess) {
      callbackSuccess(blobURL);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doFilesPreview", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(Files.filesPreviewFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

export default function* filesSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(Files.FILES_EDIT, doFilesEdit),
    takeLatest(Files.FILES_DELETE, doFilesDelete),
    takeLatest(Files.FILES_DOWNLOAD, doFilesDownload),
    takeLatest(Files.FILES_PREVIEW, doFilesPreview),
  ]);
}
