// @flow
// Import Features
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as Cookies from "js-cookie";
// Import Components
import ProtectedRoute from "../shared/ProtectedRoute";
const HomeRoute: React.ComponentType<any> = withRouter(
  connect(({ config, auth: { isAuthenticated } }) => {
    const authCookie = Cookies.getJSON("authCookie");
    return {
      component: null,
      restProps: null,
      isAllowedRoute:
        config.companyDomain.length > 0 && (isAuthenticated || authCookie),
      redirectTo:
        config.companyDomain.length > 0 && (isAuthenticated || authCookie)
          ? "/"
          : "/signout",
    };
  })(ProtectedRoute)
);
export default HomeRoute;
