import { createAction } from "deox";
// Actions for Tenant Settings
// Fetch all Tenant Settings
export const TENANT_SETTINGS_FETCH_ALL: string = "TENANT_SETTINGS/FETCH/ALL";
export const TENANT_SETTINGS_FETCH_ALL_REQUEST: string =
  "TENANT_SETTINGS/FETCH/ALL/REQUEST";
export const TENANT_SETTINGS_FETCH_ALL_SUCCESS: string =
  "TENANT_SETTINGS/FETCH/ALL/SUCCESS";
export const TENANT_SETTINGS_FETCH_ALL_FAILURE: string =
  "TENANT_SETTINGS/FETCH/ALL/FAILURE";
export const tenantSettingsFetchAll = createAction(
  TENANT_SETTINGS_FETCH_ALL,
  (resolve) => (payload?: object) => resolve(payload)
);
export const tenantSettingsFetchAllRequest = createAction(
  TENANT_SETTINGS_FETCH_ALL_REQUEST
);
export const tenantSettingsFetchAllSuccess = createAction(
  TENANT_SETTINGS_FETCH_ALL_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const tenantSettingsFetchAllFailure = createAction(
  TENANT_SETTINGS_FETCH_ALL_FAILURE
);
// CUD a Tenant Setting
export const TENANT_SETTINGS_CUD_TENANT_SETTING: string =
  "TENANT_SETTINGS/CUD/TENANT_SETTING";
export const TENANT_SETTINGS_CUD_TENANT_SETTING_REQUEST: string =
  "TENANT_SETTINGS/CUD/TENANT_SETTING/REQUEST";
export const TENANT_SETTINGS_CUD_TENANT_SETTING_SUCCESS: string =
  "TENANT_SETTINGS/CUD/TENANT_SETTING/SUCCESS";
export const TENANT_SETTINGS_CUD_TENANT_SETTING_FAILURE: string =
  "TENANT_SETTINGS/CUD/TENANT_SETTING/FAILURE";
export const tenantSettingsCUDTenantSetting = createAction(
  TENANT_SETTINGS_CUD_TENANT_SETTING,
  (resolve) => (payload?: object) => resolve(payload)
);
export const tenantSettingsCUDTenantSettingRequest = createAction(
  TENANT_SETTINGS_CUD_TENANT_SETTING_REQUEST
);
export const tenantSettingsCUDTenantSettingSuccess = createAction(
  TENANT_SETTINGS_CUD_TENANT_SETTING_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const tenantSettingsCUDTenantSettingFailure = createAction(
  TENANT_SETTINGS_CUD_TENANT_SETTING_FAILURE
);
//CaseLogsSettings
export const TENANT_SETTINGS_UPDATE_TENANT_SETTING_NAME: string =
  "TENANT_SETTINGS/UPDATE/TENANT_SETTING_NAME";
export const tenantSettingsUpdateTenantSettingName = createAction(
  TENANT_SETTINGS_UPDATE_TENANT_SETTING_NAME,
  (resolve) => (payload?: object) => resolve(payload)
);
