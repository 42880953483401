// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  onCheckCaseLogsPermissions,
  onCheckSubmitCaseLogsPermissions,
  onCheckEditCaseLogsPermissions,
  onCheckReviewCaseLogsPermissions,
} from "./CaseLogsPermissions";
import { store } from "../../index";
import { CaseLogs } from "../../store/actions/";
import ProtectedRoute from "../shared/ProtectedRoute";

const mapDispatchToProps = (dispatch: Function, ownProps: Object) => {
  const {
    computedMatch: {
      params: { caseLogId },
    },
  } = ownProps;
  const { caseLogId: storedCaseLogId } = store.getState().caseLogs;
  if (caseLogId && caseLogId !== storedCaseLogId) {
    dispatch(CaseLogs.caseLogsSetcaseLogId({ caseLogId }));
  }
  return { dispatch };
};
const CaseLogsRoute: React.ComponentType<any> = withRouter(
  connect(
    (
      { config: { companyDomain }, auth: { isAuthenticated, role, user } },
      {
        computedMatch: {
          params: { create_edit_re_view, caseLogId },
          url,
        },
      }
    ) => {
      let allowedCaseLogsRoute =
        isAuthenticated && onCheckCaseLogsPermissions(role.permissions);
      let redirectTo = allowedCaseLogsRoute ? "/case-logs" : "/home";
      if (allowedCaseLogsRoute && create_edit_re_view) {
        if (
          create_edit_re_view === "create" ||
          create_edit_re_view === "edit" ||
          create_edit_re_view === "review" ||
          create_edit_re_view === "view"
        ) {
          if (create_edit_re_view === "create") {
            if (caseLogId) {
              allowedCaseLogsRoute = false;
            } else if (
              isAuthenticated &&
              !onCheckSubmitCaseLogsPermissions(role.permissions)
            ) {
              allowedCaseLogsRoute = false;
            }
          }
          if (
            create_edit_re_view === "edit" ||
            create_edit_re_view === "review" ||
            create_edit_re_view === "view"
          ) {
            if (!caseLogId) {
              allowedCaseLogsRoute = false;
            } else if (
              create_edit_re_view === "edit" &&
              !onCheckEditCaseLogsPermissions(role.permissions)
            ) {
              allowedCaseLogsRoute = false;
            } else if (
              create_edit_re_view === "review" &&
              !onCheckReviewCaseLogsPermissions(role.permissions)
            ) {
              allowedCaseLogsRoute = false;
            }
          }
        } else {
          allowedCaseLogsRoute = false;
        }
      }
      return {
        component: null,
        restProps: null,
        isAllowedRoute:
          companyDomain.length > 0 && isAuthenticated && allowedCaseLogsRoute,
        redirectTo,
      };
    },
    mapDispatchToProps
  )(ProtectedRoute)
);

export default CaseLogsRoute;
