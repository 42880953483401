import { put, select, call, all, takeLatest } from "redux-saga/effects";
import EvaluationsApi from "../api/Evaluations";
import { Auth, EvaluationsTemplates } from "../actions/";
import { onSetFormOptions } from "../../components/evaluations-templates/edit/_EvaluationsTemplates.utils";
// Type
import type { Action } from "deox";
import type { tAPI } from "../api/API";
//Sagas for Evaluations TEMPLATES form
function* doEvaluationsTemplatesFetchForms(
  action: Action<
    string,
    {
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(EvaluationsTemplates.evaluationsTemplatesFetchFormsRequest());
  window.logger.log("doEvaluationsTemplatesFetchForms", action);
  const { callbackSuccess = null, callbackError = null } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get all evaluations TEMPLATES forms for this company
    const { data } = yield call(EvaluationsApi.GetEvaluationsTemplates, api, {
      token,
    });
    const { evaluationTemplates } = data;
    yield put(
      EvaluationsTemplates.evaluationsTemplatesFetchFormsSuccess({
        evaluationTemplates,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(evaluationTemplates);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doEvaluationsTemplatesFetchForms",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(EvaluationsTemplates.evaluationsTemplatesFetchFormsFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}

function* doEvaluationsTemplatesCreate(
  action: Action<
    string,
    {
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(EvaluationsTemplates.evaluationsTemplatesCreateRequest());
  window.logger.log("doEvaluationsTemplatesCreate", action);
  const { body, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    const { userId, userFirstName, userLastName } = yield select(
      ({ auth: { user } }) => ({
        userId: user._id,
        userFirstName: user.firstName,
        userLastName: user.lastName,
      })
    );
    // call the api to create a new evaluation template form
    const { data } = yield call(
      EvaluationsApi.CreateEvaluationTemplates,
      api,
      body,
      { token }
    );
    const { evaluationTemplate } = data;
    let parseTemplate = {
      ...evaluationTemplate,
    };
    if (
      parseTemplate &&
      parseTemplate.lastUpdatedBy &&
      !parseTemplate.lastUpdatedBy.firstName &&
      !parseTemplate.lastUpdatedBy.lastName &&
      parseTemplate.lastUpdatedBy === userId
    ) {
      parseTemplate = {
        ...parseTemplate,
        lastUpdatedBy: {
          firstName: userFirstName,
          lastName: userLastName,
        },
      };
    }
    yield put(
      EvaluationsTemplates.evaluationsTemplatesCreateSuccess({
        evaluationTemplate: parseTemplate,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(parseTemplate);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doEvaluationsTemplatesCreate",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(EvaluationsTemplates.evaluationsTemplatesCreateFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doEvaluationsTemplatesFetchForm(
  action: Action<
    string,
    {
      evaluationTemplateId: string;
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(EvaluationsTemplates.evaluationsTemplatesFetchFormRequest());
  window.logger.log("doEvaluationsTemplatesFetchForm", action);
  const {
    evaluationTemplateId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get a evaluation template form for that specific evaluationTemplateId
    const { data } = yield call(
      EvaluationsApi.GetEvaluationTemplates,
      api,
      evaluationTemplateId,
      { token }
    );
    const { evaluationTemplate: evaluationTemplateData } = data;
    const evaluationTemplate = {
      ...evaluationTemplateData,
      form: onSetFormOptions(evaluationTemplateData.form),
    };
    yield put(
      EvaluationsTemplates.evaluationsTemplatesFetchFormSuccess({
        evaluationTemplate,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(evaluationTemplate);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doEvaluationsTemplatesFetchForm",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(EvaluationsTemplates.evaluationsTemplatesFetchFormFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}

function* doEvaluationsTemplatesEdit(
  action: Action<
    string,
    {
      evaluationTemplateId: string;
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(EvaluationsTemplates.evaluationsTemplatesEditRequest());
  window.logger.log("doEvaluationsTemplatesEdit", action);
  const {
    evaluationTemplateId,
    body,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to update a evaluation template form for that specific evaluationTemplateId
    const { data } = yield call(
      EvaluationsApi.EditEvaluationTemplates,
      api,
      evaluationTemplateId,
      body,
      { token }
    );
    const { evaluationTemplate: evaluationTemplateData } = data;
    const evaluationTemplate = {
      ...evaluationTemplateData,
      form: onSetFormOptions(evaluationTemplateData.form),
    };
    yield put(
      EvaluationsTemplates.evaluationsTemplatesEditSuccess({
        evaluationTemplate,
      })
    );
    if (callbackSuccess) {
      yield put(EvaluationsTemplates.evaluationsTemplatesFetchForms());
      callbackSuccess(evaluationTemplate);
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doEvaluationsTemplatesEdit",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(EvaluationsTemplates.evaluationsTemplatesEditFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doEvaluationsTemplatesDelete(
  action: Action<
    string,
    {
      evaluationTemplateId: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(EvaluationsTemplates.evaluationsTemplatesDeleteRequest());
  window.logger.log("doEvaluationsTemplatesDelete", action);
  const {
    evaluationTemplateId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to delete a evaluation for that specific evaluationTemplateId
    yield call(
      EvaluationsApi.DeleteEvaluationTemplates,
      api,
      evaluationTemplateId,
      { token }
    );
    yield put(
      EvaluationsTemplates.evaluationsTemplatesDeleteSuccess({
        evaluationTemplateId,
      })
    );
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doEvaluationsTemplatesDelete",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(EvaluationsTemplates.evaluationsTemplatesDeleteFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

export default function* evaluationsTemplatesSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(
      EvaluationsTemplates.EVALUATIONS_TEMPLATES_FETCH_FORMS,
      doEvaluationsTemplatesFetchForms
    ),
    takeLatest(
      EvaluationsTemplates.EVALUATIONS_TEMPLATES_CREATE,
      doEvaluationsTemplatesCreate
    ),
    takeLatest(
      EvaluationsTemplates.EVALUATIONS_TEMPLATES_FETCH_FORM,
      doEvaluationsTemplatesFetchForm
    ),
    takeLatest(
      EvaluationsTemplates.EVALUATIONS_TEMPLATES_EDIT,
      doEvaluationsTemplatesEdit
    ),
    takeLatest(
      EvaluationsTemplates.EVALUATIONS_TEMPLATES_DELETE,
      doEvaluationsTemplatesDelete
    ),
  ]);
}
