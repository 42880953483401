// Import Features
import { Programs } from "../actions/";
import { updateArrayWithElement } from "../../utils/";
// Programs Reducer
type State = {
  lastFetching: Date | undefined | null;
  programs: object[];
};
type Action = {
  type: string;
  payload: Object;
};

const initialState: State = {
  lastFetching: null,
  programs: [],
};

export default function programs(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    //Programs
    case Programs.PROGRAMS_FETCH_PROGRAMS_SUCCESS:
      return Object.assign({}, state, {
        lastFetching: new Date(),
        programs: action.payload.programs,
      });
    case Programs.PROGRAMS_CREATE_SUCCESS:
      return Object.assign({}, state, {
        lastFetching: new Date(),
        programs: [...state.programs, action.payload.program],
      });
    case Programs.PROGRAMS_EDIT_PROGRAM_SUCCESS:
      return Object.assign({}, state, {
        programs: updateArrayWithElement(
          state.programs,
          action.payload.program
        ),
      });
    case Programs.PROGRAMS_DELETE_SUCCESS:
      return Object.assign({}, state, {
        lastFetching: new Date(),
        programs: state.programs.filter(
          ({ _id }) => _id !== action.payload.programId
        ),
      });
    // same state (not change)
    default:
      return state;
  }
}
