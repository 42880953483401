import type { Config, tAPI } from "./API";
import type { StateUsersManager } from "../../types/users.types";
// API Rotations static class
const apiBaseRotations = "/rotations";
export default class Rotations {
  // Get a list of rotations depend on case:
  // student: UserID, preceptor: UserID, institution: InstitutionID,
  // period: periodID, start, end
  static GetRotations(
    api: tAPI,
    populated: boolean,
    skip: number,
    limit: number,
    status: string[],
    userStatus: StateUsersManager["filters"]["status"],
    students: string[],
    programs: string[],
    cohorts: string[],
    institutions: string[],
    periods: string[],
    search: string,
    startDate: string,
    endDate: string,
    sortBy: {
      student: "ascending" | "descending" | "";
      institution: "ascending" | "descending" | "";
      start_end: "ascending" | "descending" | "";
      status: "ascending" | "descending" | "";
    },
    config?: Config
  ) {
    let queryParams = "";
    if (skip >= 0) {
      queryParams += `&skip=${skip}`;
    }
    if (limit > 0) {
      queryParams += `&limit=${limit}`;
    }
    if (status.length > 0) {
      queryParams += `&status=${status.join()}`;
    }
    if (userStatus.length > 0) {
      queryParams += `&userStatus=${userStatus.join()}`;
    }
    if (students.length > 0) {
      queryParams += `&students=${students.join()}`;
    }
    if (programs.length > 0) {
      queryParams += `&programs=${programs.join()}`;
    }
    if (cohorts.length > 0) {
      queryParams += `&cohorts=${cohorts.join()}`;
    }
    if (institutions.length > 0) {
      queryParams += `&institutions=${institutions.join()}`;
    }
    if (periods.length > 0) {
      queryParams += `&periods=${periods.join()}`;
    }
    if (search) {
      queryParams += `&search=${search}`;
    }
    if (startDate) {
      queryParams += `&start=${startDate}`;
    }
    if (endDate) {
      queryParams += `&end=${endDate}`;
    }
    if (populated) {
      let sortByParams = Object.keys(sortBy)
        // @ts-ignore
        .filter((sortKey) => sortBy[sortKey])
        .map(
          (sortName) =>
            // @ts-ignore
            `sort_${sortName}=${sortBy[sortName] === "ascending" ? 1 : -1}`
        )
        .join("&");
      if (sortByParams) {
        sortByParams = "&" + sortByParams;
      }
      queryParams = "?populated=true" + queryParams + sortByParams;
    } else if (queryParams.length > 0) {
      queryParams = "?" + queryParams.slice(1);
    }
    return api.GET(`${apiBaseRotations}${queryParams}`, config);
  }
  // Get Rotations KPIS
  static GetRotationsKpis(api: tAPI, config?: Config) {
    return api.GET(`${apiBaseRotations}/kpis`, config);
  }
  // Create Rotations
  static CreateRotations(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBaseRotations}`, data, config);
  }
  // Edit in bulk a group of rotations based on filters
  static EditRotations(api: tAPI, data: object, config?: Config) {
    return api.PATCH(`${apiBaseRotations}`, data, config);
  }
  // Set Automatic Rotations
  static SetAutomaticRotations(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBaseRotations}/automatic`, data, config);
  }
  // Get a Rotation
  static GetRotation(api: tAPI, rotationId: string, config?: Config) {
    return api.GET(`${apiBaseRotations}/${rotationId}?populated=true`, config);
  }
  // Edit a Rotation
  static EditRotation(
    api: tAPI,
    rotationId: string,
    data: object,
    config?: Config
  ) {
    return api.PATCH(`${apiBaseRotations}/${rotationId}`, data, config);
  }
  // Delete a Rotation
  static DeleteRotation(api: tAPI, rotationId: string, config?: Config) {
    return api.DELETE(`${apiBaseRotations}/${rotationId}`, config);
  }
}
