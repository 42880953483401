// @flow
// Import Features
import * as React from "react";
import { connect } from "react-redux";
// Import Components
import FieldsWarningPresentational from "./FieldsWarningPresentational";
// Import Styles
import "./FieldsWarning.scss";

type PropsContainer = {
  showWarning: boolean,
};
export type PropsPresentational = {
  triggerAnimation: boolean,
};
type Props = PropsContainer & PropsPresentational;

class FieldsWarningContainer extends React.Component<Props> {
  render() {
    const { showWarning, triggerAnimation } = this.props;
    if (!showWarning) {
      return null;
    }
    return <FieldsWarningPresentational triggerAnimation={triggerAnimation} />;
  }
}

export default connect(
  ({
    auth: {
      role,
      hasContactInfoAddressesFieldsRequiredNull,
      hasEmergencyContactFieldsRequiredNull,
      hasCustomFieldsRequiredNull,
      myInstitution: { hasMyInstitutionCustomFieldsRequiredNull },
    },
    institutions: { hasInstitutionCustomFieldsRequiredNull },
    customFields: { triggerAnimation },
    router: { location },
  }) => ({
    showWarning:
      hasContactInfoAddressesFieldsRequiredNull ||
      hasEmergencyContactFieldsRequiredNull ||
      hasCustomFieldsRequiredNull ||
      (hasInstitutionCustomFieldsRequiredNull &&
      hasInstitutionCustomFieldsRequiredNull.isRequired &&
      hasInstitutionCustomFieldsRequiredNull.institutionRequired &&
      hasInstitutionCustomFieldsRequiredNull.institutionRequired.status ===
        "active" &&
      role &&
      (role.apiName === "InstitutionAdministrators" ||
        role.apiName === "Preceptors") &&
      hasInstitutionCustomFieldsRequiredNull.rolesWrite.indexOf(
        role.apiName
      ) !== -1
        ? true
        : false) ||
      (location &&
      location.pathname &&
      location.pathname === "/my-clinic" &&
      hasMyInstitutionCustomFieldsRequiredNull &&
      hasMyInstitutionCustomFieldsRequiredNull.isRequired &&
      hasMyInstitutionCustomFieldsRequiredNull.institutionRequired &&
      hasMyInstitutionCustomFieldsRequiredNull.institutionRequired.status ===
        "active" &&
      role &&
      (role.apiName === "InstitutionAdministrators" ||
        role.apiName === "Preceptors") &&
      hasMyInstitutionCustomFieldsRequiredNull.rolesWrite.indexOf(
        role.apiName
      ) !== -1
        ? true
        : false),
    triggerAnimation,
  }),
  null
)(FieldsWarningContainer);
