import { setUTCtime00 } from "../../utils/";
import { CaseLogs } from "../actions";
// Import Types
import type { Action } from "../../types/common.types";
import type { StateCaseLogs as State } from "../../types/caseLogs.types";
// Case Logs Reducer
export const CASELOGS_LIMIT = 20;
const initialStateCaseLogCreateEdit: State["caseLogCreateEdit"] = {
  patientDemographics: {
    age: null,
    typeAge: "years",
    gender: null,
    race: null,
    firstLanguage: null,
    institutionRecordNumber: null,
    insurance: null,
  },
  clinicalInfo: {
    appointmentDate: setUTCtime00().format(),
    reasonForVisit: [],
    minutesWithPatient: null,
    minutesWithPreceptor: null,
    chiefComplaint: null,
  },
  diagnosesAndProcedures: {
    allTeeth: [],
    upperRightTeeth: [],
    upperLeftTeeth: [],
    lowerLeftTeeth: [],
    lowerRightTeeth: [],
  },
  medication: {
    medicationTaken: [],
    prescriptionThisVisit: [],
  },
  psychosocialTopics: {
    psychosocialTopics: [],
    otherPsychosocialTopic: null,
    pmcc: null,
  },
  customFields: {
    customFields: [],
  },
  notes: {
    notes: null,
  },
  review: {
    status: null,
    preceptorComments: null,
  },
  step: "patientDemographics",
};

const initialState: State = {
  caseLog: null,
  caseLogId: null,
  caseLogs: [],
  caseLogsCache: [],
  caseLogsStatus: [],
  caseLogsMetaData: {
    numTotalCaseLogs: 0,
    numTotalCaseLogsPending: 0,
    numTotalCaseLogsDenied: 0,
    numTotalCaseLogsApproved: 0,
    numTotalCaseLogsDraft: 0,
    totalPages: 1,
  },
  fetchingCaseLogs: false,
  caseLogCreateEdit: initialStateCaseLogCreateEdit,
  kpis: {
    fetching: false,
    CaseLogs: [],
  },
};
export default function caseLogs(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case CaseLogs.CASELOGS_FETCH_ALL:
      return Object.assign({}, state, {
        caseLogsCache: [],
        fetchingCaseLogs: true,
      });
    case CaseLogs.CASELOGS_FETCH_ALL_FAILURE:
      return Object.assign({}, state, {
        fetchingCaseLogs: false,
      });
    case CaseLogs.CASELOGS_FETCH_ALL_SUCCESS:
      const {
        pending = 0,
        denied = 0,
        approved = 0,
        draft = 0,
      } = action.payload.metadata || {
        pending: action.payload.caseLogs.filter(
          ({ status }: any) => status === "pending"
        ).length,
        denied: action.payload.caseLogs.filter(
          ({ status }: any) => status === "denied"
        ).length,
        approved: action.payload.caseLogs.filter(
          ({ status }: any) => status === "approved"
        ).length,
        draft: action.payload.caseLogs.filter(
          ({ status }: any) => status === "draft"
        ).length,
      };
      const numTotalCaseLogs = pending + denied + approved + draft;
      return Object.assign({}, state, {
        caseLogs: action.payload.caseLogs,
        caseLogsStatus: action.payload.caseLogsStatus,
        caseLogsMetaData: {
          numTotalCaseLogs,
          numTotalCaseLogsPending: pending,
          numTotalCaseLogsDenied: denied,
          numTotalCaseLogsApproved: approved,
          numTotalCaseLogsDraft: draft,
          totalPages:
            1 +
            parseInt(((numTotalCaseLogs - 1) / CASELOGS_LIMIT).toString(), 10),
        },
        fetchingCaseLogs: false,
      });
    case CaseLogs.CASELOGS_FETCH_KPIS_REQUEST:
      return Object.assign({}, state, {
        kpis: {
          ...state.kpis,
          fetching: true,
        },
      });
    case CaseLogs.CASELOGS_FETCH_KPIS_SUCCESS:
    case CaseLogs.CASELOGS_FETCH_KPIS_FAILURE:
      return Object.assign({}, state, {
        kpis: {
          ...state.kpis,
          ...action.payload.kpis,
          fetching: false,
        },
      });
    case CaseLogs.CASELOGS_SET_CASELOGID:
      return Object.assign({}, state, { caseLogId: action.payload.caseLogId });
    case CaseLogs.CASELOGS_CREATE_SUCCESS:
      const caseLogCreateToAdd = action.payload.caseLog;
      let newListCaseLogs = [...state.caseLogs],
        newNumTotalCaseLogs = state.caseLogsMetaData.numTotalCaseLogs,
        newNumTotalCaseLogsPending =
          state.caseLogsMetaData.numTotalCaseLogsPending;
      if (
        caseLogCreateToAdd.status === "pending" &&
        state.caseLogsStatus.indexOf("pending") !== -1
      ) {
        newListCaseLogs = [action.payload.caseLog, ...state.caseLogs];
        newNumTotalCaseLogs = state.caseLogsMetaData.numTotalCaseLogs + 1;
        newNumTotalCaseLogsPending =
          state.caseLogsMetaData.numTotalCaseLogsPending + 1;
      }
      return Object.assign({}, state, {
        caseLogs: newListCaseLogs,
        caseLogsCache: [caseLogCreateToAdd, ...state.caseLogsCache],
        caseLogsMetaData: {
          ...state.caseLogsMetaData,
          numTotalCaseLogs: newNumTotalCaseLogs,
          numTotalCaseLogsPending: newNumTotalCaseLogsPending,
        },
      });
    case CaseLogs.CASELOGS_FETCH_CASELOG_SUCCESS:
      return Object.assign({}, state, {
        caseLog: action.payload.caseLog,
        caseLogsCache: [action.payload.caseLog, ...state.caseLogsCache],
        caseLogCreateEdit: action.payload.caseLogCreateEdit,
      });
    case CaseLogs.CASELOGS_EDIT_SUCCESS:
    case CaseLogs.CASELOGS_REVIEW_SUCCESS:
      const caseLogCacheUpdateIndex = state.caseLogsCache
        .map(({ _id }) => _id)
        .indexOf(action.payload.caseLog._id);
      let newCaseLogsCache = [...state.caseLogsCache];
      if (caseLogCacheUpdateIndex >= 0) {
        newCaseLogsCache = [
          ...newCaseLogsCache.slice(0, caseLogCacheUpdateIndex),
          action.payload.caseLog,
          ...newCaseLogsCache.slice(caseLogCacheUpdateIndex + 1),
        ];
      }
      const caseLogUpdateIndex = state.caseLogs
        .map(({ _id }) => _id)
        .indexOf(action.payload.caseLog._id);
      if (caseLogUpdateIndex >= 0) {
        let newListUpdatedCaseLogs = [...state.caseLogs];
        if (
          state.caseLogsStatus.indexOf(action.payload.caseLog.status) !== -1
        ) {
          newListUpdatedCaseLogs = [
            ...state.caseLogs.slice(0, caseLogUpdateIndex),
            action.payload.caseLog,
            ...state.caseLogs.slice(caseLogUpdateIndex + 1),
          ];
        } else {
          newListUpdatedCaseLogs = [
            ...state.caseLogs.slice(0, caseLogUpdateIndex),
            ...state.caseLogs.slice(caseLogUpdateIndex + 1),
          ];
        }
        return Object.assign({}, state, {
          caseLog: action.payload.caseLog,
          caseLogCreateEdit: {
            ...action.payload.caseLogCreateEdit,
            step: state.caseLogCreateEdit.step,
          },
          caseLogs: newListUpdatedCaseLogs,
          caseLogsCache: newCaseLogsCache,
          caseLogsMetaData: {
            ...state.caseLogsMetaData,
            numTotalCaseLogsPending:
              action.payload.caseLog &&
              action.payload.caseLog.status === "pending"
                ? state.caseLogsMetaData.numTotalCaseLogsPending + 1
                : action.payload.caseLogStatusOld === "pending"
                ? state.caseLogsMetaData.numTotalCaseLogsPending - 1
                : state.caseLogsMetaData.numTotalCaseLogsPending,
            numTotalCaseLogsDenied:
              action.payload.caseLog &&
              action.payload.caseLog.status === "denied"
                ? state.caseLogsMetaData.numTotalCaseLogsDenied + 1
                : action.payload.caseLogStatusOld === "denied"
                ? state.caseLogsMetaData.numTotalCaseLogsDenied - 1
                : state.caseLogsMetaData.numTotalCaseLogsDenied,
            numTotalCaseLogsApproved:
              action.payload.caseLog &&
              action.payload.caseLog.status === "approved"
                ? state.caseLogsMetaData.numTotalCaseLogsApproved + 1
                : action.payload.caseLogStatusOld === "approved"
                ? state.caseLogsMetaData.numTotalCaseLogsApproved - 1
                : state.caseLogsMetaData.numTotalCaseLogsApproved,
            numTotalCaseLogsDraft:
              action.payload.caseLog &&
              action.payload.caseLog.status === "draft"
                ? state.caseLogsMetaData.numTotalCaseLogsDraft + 1
                : action.payload.caseLogStatusOld === "draft"
                ? state.caseLogsMetaData.numTotalCaseLogsDraft - 1
                : state.caseLogsMetaData.numTotalCaseLogsDraft,
          },
        });
      }
      return Object.assign({}, state, {
        caseLogsCache: newCaseLogsCache,
      });
    case CaseLogs.CASELOGS_DELETE_SUCCESS:
      const caseLogDeleteIndex = state.caseLogs
        .map(({ _id }) => _id)
        .indexOf(action.payload.caseLogId);
      if (caseLogDeleteIndex >= 0) {
        const caseLogDelete = state.caseLogs[caseLogDeleteIndex];
        return Object.assign({}, state, {
          caseLogs: [
            ...state.caseLogs.slice(0, caseLogDeleteIndex),
            ...state.caseLogs.slice(caseLogDeleteIndex + 1),
          ],
          caseLogsMetaData: {
            ...state.caseLogsMetaData,
            numTotalCaseLogs: state.caseLogsMetaData.numTotalCaseLogs - 1,
            numTotalCaseLogsPending:
              caseLogDelete && caseLogDelete.status === "pending"
                ? state.caseLogsMetaData.numTotalCaseLogsPending - 1
                : state.caseLogsMetaData.numTotalCaseLogsPending,
            numTotalCaseLogsDenied:
              caseLogDelete && caseLogDelete.status === "denied"
                ? state.caseLogsMetaData.numTotalCaseLogsDenied - 1
                : state.caseLogsMetaData.numTotalCaseLogsDenied,
            numTotalCaseLogsApproved:
              caseLogDelete && caseLogDelete.status === "approved"
                ? state.caseLogsMetaData.numTotalCaseLogsApproved - 1
                : state.caseLogsMetaData.numTotalCaseLogsApproved,
            numTotalCaseLogsDraft:
              action.payload.caseLog &&
              action.payload.caseLog.status === "draft"
                ? state.caseLogsMetaData.numTotalCaseLogsDraft - 1
                : state.caseLogsMetaData.numTotalCaseLogsDraft,
          },
        });
      }
      return state;
    case CaseLogs.CASELOGS_RESET_CASELOG_LOADED:
      return Object.assign({}, state, {
        ...initialState,
        caseLogs: state.caseLogs,
        caseLogsCache: state.caseLogsCache,
        caseLogsStatus: state.caseLogsStatus,
        caseLogsMetaData: state.caseLogsMetaData,
        kpis: state.kpis,
      });
    case CaseLogs.CASELOGS_CREATE_EDIT_UPDATE:
      let newStateCaseLogCreateEdit = {
        ...state.caseLogCreateEdit,
      };
      newStateCaseLogCreateEdit[
        action.payload.step as State["caseLogCreateEdit"]["step"]
      ] = action.payload.data;
      action.payload.callbackEnd();
      return Object.assign({}, state, {
        caseLogCreateEdit: newStateCaseLogCreateEdit,
      });
    case CaseLogs.CASELOGS_CREATE_EDIT_STEP:
      return Object.assign({}, state, {
        caseLogCreateEdit: {
          ...state.caseLogCreateEdit,
          step: action.payload.step,
        },
      });
    // same state (not change)
    default:
      return state;
  }
}
