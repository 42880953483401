// @flow
import * as React from "react";
import { connect } from "react-redux";
import { Users } from "../../store/actions/";
import EmulateUserPresentational from "./EmulateUserPresentational";
import "./EmulateUser.scss";

export type Props = {
  emulation: {
    isActive: boolean,
    onBehalfUser: ?string,
  },
  stopEmulation: (userId: string) => void,
  userName: ?string,
};
export type State = {
  stoppingEmulation: boolean,
};

class EmulateUserContainer extends React.Component<Props, State> {
  state = {
    stoppingEmulation: false,
  };
  _onAddBodyClass = () => {
    const { emulation } = this.props;
    if (emulation && emulation.isActive && document.body) {
      document.body.classList.add("emulate-active-heto");
    }
  };
  componentDidMount() {
    this._onAddBodyClass();
  }
  onStopEmulation = () => {
    const { emulation, stopEmulation } = this.props;
    if (emulation && emulation.isActive) {
      const userId = emulation.onBehalfUser || "";
      if (userId) {
        this.setState({ stoppingEmulation: true });
        stopEmulation(userId);
      }
    }
  };
  render() {
    return this.props.emulation && this.props.emulation.isActive ? (
      <EmulateUserPresentational
        {...this.props}
        {...this.state}
        onStopEmulation={this.onStopEmulation}
      />
    ) : null;
  }
  getSnapshotBeforeUpdate(prevProps: Props, prevState: State) {
    const {
      emulation: { isActive: emulationActiveOld },
    } = prevProps;
    const {
      emulation: { isActive: emulationActiveNew },
    } = this.props;
    if (emulationActiveOld !== emulationActiveNew && emulationActiveNew) {
      return true;
    }
    return null;
  }
  componentDidUpdate(
    prevProps: Props,
    prevState: State,
    emulationActive: boolean
  ) {
    if (emulationActive) {
      this._onAddBodyClass();
    }
  }
}

export default connect(
  ({ auth: { role, user, emulation } }) => ({
    emulation,
    userName: user && `${user.firstName || ""} ${user.lastName || ""}`,
  }),
  (dispatch) => ({
    stopEmulation: (userId: string) =>
      dispatch(Users.usersEmulateUser({ user: userId, stopEmulation: true })),
  })
)(EmulateUserContainer);
