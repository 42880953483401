// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { onCheckEvaluationsPermissions } from "../evaluations/EvaluationsPermissions";
import { onCheckEvaluationSchedulersPermissions } from "../evaluations-schedulers/EvaluationSchedulersPermissions";
import {
  onCheckEvaluationTemplatesPermissions,
  onCheckReadEvaluationTemplatesPermissions,
  onCheckCreateEvaluationTemplatesPermissions,
  onCheckEditEvaluationTemplatesPermissions,
} from "./EvaluationTemplatesPermissions";
import { store } from "../../index";
import { EvaluationsTemplates } from "../../store/actions/";
import ProtectedRoute from "../shared/ProtectedRoute";

const mapDispatchToProps = (dispatch: Function, ownProps: Object) => {
  const {
    computedMatch: {
      params: { templateId },
    },
    location: { state },
  } = ownProps;
  const { evaluationTemplateId: storedEvaluationTemplateId } =
    store.getState().evaluationsTemplates;
  if (templateId && templateId !== storedEvaluationTemplateId) {
    if (state && state.template) {
      dispatch(
        EvaluationsTemplates.evaluationsTemplatesFetchFormSuccess({
          evaluationTemplate: state.template,
        })
      );
    }
    dispatch(
      EvaluationsTemplates.evaluationsTemplatesSetEvaluationTemplateId({
        evaluationTemplateId: templateId,
      })
    );
  }
  return { dispatch };
};
const EvaluationsTemplatesRoute: React.ComponentType<any> = withRouter(
  connect(
    (
      { config, auth: { isAuthenticated, role } },
      {
        computedMatch: {
          params: { create_edit_view, templateId },
        },
      }
    ) => {
      let isAllowedRoute =
        isAuthenticated &&
        onCheckEvaluationTemplatesPermissions(role.permissions);
      let isAllowedEvaluationsRoute =
        isAuthenticated &&
        (onCheckEvaluationsPermissions(role.permissions) ||
          onCheckEvaluationSchedulersPermissions(role.permissions) ||
          onCheckEvaluationTemplatesPermissions(role.permissions));
      let redirectTo = "/home";
      if (isAllowedEvaluationsRoute) {
        redirectTo = "/evaluations";
      }
      if (isAllowedRoute && create_edit_view) {
        if (create_edit_view === "create") {
          if (templateId) {
            isAllowedRoute = false;
          } else if (
            create_edit_view === "create" &&
            !onCheckCreateEvaluationTemplatesPermissions(role.permissions)
          ) {
            isAllowedRoute = false;
          }
        } else if (create_edit_view === "edit" || create_edit_view === "view") {
          if (!templateId) {
            isAllowedRoute = false;
          } else if (
            create_edit_view === "edit" &&
            !onCheckEditEvaluationTemplatesPermissions(role.permissions)
          ) {
            isAllowedRoute = false;
          } else if (
            create_edit_view === "view" &&
            !onCheckReadEvaluationTemplatesPermissions(role.permissions)
          ) {
            isAllowedRoute = false;
          }
        } else {
          isAllowedRoute = false;
        }
      }
      return {
        component: null,
        restProps: null,
        isAllowedRoute:
          config.companyDomain.length > 0 && isAuthenticated && isAllowedRoute,
        redirectTo,
      };
    },
    mapDispatchToProps
  )(ProtectedRoute)
);

export default EvaluationsTemplatesRoute;
