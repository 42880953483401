// Features
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AccountSettings } from "../../../store/actions/";
import { setMomentDate } from "../../../utils";
// Components
import BroadcastMessagesView from "./BroadcastMessages.view";
// Styles
import "./BroadcastMessages.scss";
// Types
import type { ButtonProps } from "semantic-ui-react";
import type { RootState } from "../../../store/reducers";
import type { State as StateBroadcastMessages } from "../../../store/reducers/broadcastMessages";
import type { StateAccountSettings } from "../../../types/accountSettings.types";
// Broadcast Show Messages Container:
const { useState } = React;
//
const BroadcastMessagesContainer = () => {
  const dispatch = useDispatch();
  // STORE - Broadcast Messages
  const {
    hasBroadcastMessages,
    broadcastMessages = [],
    broadcastMessagesWatched = [],
  }: StateBroadcastMessages = useSelector(
    ({ broadcastMessages }: RootState) => broadcastMessages
  );
  let messages: StateBroadcastMessages["broadcastMessages"] = [];
  if (hasBroadcastMessages) {
    messages = broadcastMessages.filter(({ _id, expirationDate }) => {
      if (
        (!expirationDate ||
          (expirationDate && setMomentDate().isSameOrBefore(expirationDate))) &&
        broadcastMessagesWatched.indexOf(_id) === -1
      ) {
        return true;
      }
      return false;
    });
  }
  // State
  const [hideModal, setHideModal] = useState<boolean>(false);
  //
  // METHODS
  const onConfirm = (
    ev: React.MouseEvent<HTMLButtonElement>,
    { value: newMessagesWatched }: ButtonProps
  ) => {
    setHideModal(true);
    if (newMessagesWatched.length) {
      const messagesWatched = [
          ...broadcastMessagesWatched,
          ...newMessagesWatched,
        ],
        payload: {
          accountSettingsName: "BROADCAST_MESSAGES_SETTINGS";
          newSetting: StateAccountSettings["frontendPersonalization"]["BROADCAST_MESSAGES_SETTINGS"];
        } = {
          accountSettingsName: "BROADCAST_MESSAGES_SETTINGS",
          newSetting: { messagesWatched },
        };
      dispatch(AccountSettings.accountSettingsUpdateSettingName(payload));
      dispatch(AccountSettings.accountSettingsUpdateFrontendPersonalization());
    }
  };
  // RENDERS
  const showModal = !hideModal && messages.length > 0;
  if (!showModal) {
    return null;
  }
  return <BroadcastMessagesView messages={messages} onConfirm={onConfirm} />;
};
export default BroadcastMessagesContainer;
