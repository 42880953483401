// Permissions
import {
  onCheckAllRotationsPermissions,
  onCheckPeriodsPermissions,
} from "../../rotations/RotationsPermissions";
import { onCheckAllCaseLogsPermissions } from "../../case-logs/CaseLogsPermissions";
import { onCheckEvaluationsPermissions } from "../../evaluations/EvaluationsPermissions";
import { onCheckReadAllEvaluationAnswersPermissions } from "../../evaluations/answers/EvaluationAnswersPermissions";
import { onCheckAllReportsPermissions } from "../../reports/ReportsPermissions";
import {
  onCheckFilesPermissions,
  onCheckUsersFilesPermissions,
} from "../../files/FilesPermissions";
import { onCheckAllCustomFieldsPermissions } from "../../admin-portal/AdminPortalPermissions";
import { onCheckImportDataPermissions } from "../../import/ImportDataPermissions";
import { onCheckAllUserPortfolioPermissions } from "../../user-portfolio/UserPortfolioPermissions";
// Types
import type { SemanticICONS } from "semantic-ui-react";
import type { PayloadObject } from "../../../types/common.types";
import type { NotificationsCategories } from "../../../store/reducers/notifications";
type PayloadNotificationCategory = {
  _id: NotificationsCategories;
  name: string;
  icon: SemanticICONS;
  categories: string[];
};
// Notifications Filters (Categories):
const ALL_NOTIFICATIONS_CATEGORIES: PayloadNotificationCategory[] = [
  {
    _id: "ROTATIONS",
    name: "Rotations",
    icon: "calendar",
    categories: [
      "ROTATION_CREATED",
      "ROTATION_UPDATED",
      "ROTATION_ABOUT_START",
      "ROTATION_STARTED",
      "ROTATION_ABOUT_FINISH",
      "ROTATION_FINISHED",
    ],
  },
  {
    _id: "CASE_LOGS",
    name: "Case Logs",
    icon: "file alternate",
    categories: ["CASE_LOG_SUBMITTED", "CASE_LOG_DENIED", "CASE_LOG_APPROVED"],
  },
  {
    _id: "EVALUATIONS,EVALUATION_SCHEDULES",
    name: "Evaluations",
    icon: "clipboard",
    categories: [
      "EVALUATION_CREATED",
      "EVALUATION_FINISHED",
      "EVALUATION_ABOUT_FINISH",
      "EVALUATION_ANSWER_SUBMITTED",
    ],
  },
  {
    _id: "EVALUATION_ANSWERS",
    name: "Evaluations > Goals",
    icon: "clipboard",
    categories: ["EVALUATION_ANSWER_SUBMITTED"],
  },
  {
    _id: "REPORTS",
    name: "Reports",
    icon: "chart bar",
    categories: ["REPORT_CREATED", "REPORT_ERROR"],
  },
  {
    _id: "DOCUMENTS",
    name: "Documents",
    icon: "folder open",
    categories: ["FILE_UPLOADED", "FILE_EXPIRED", "FILE_CATEGORY_DELETED"],
  },
  {
    _id: "CUSTOM_FIELDS,PERIODS,IMPORT_DATA,PORTFOLIOS",
    name: "Others",
    icon: "list alternate",
    categories: [
      "CUSTOM_FIELD_DELETED",
      "PERIOD_DELETED",
      "IMPORT_CREATED",
      "IMPORT_ERROR",
    ],
  },
  // Not in use
  {
    _id: "INSTITUTIONS",
    name: "Clinics",
    icon: "hospital",
    categories: ["INSTITUTION_DELETED"],
  },
  {
    _id: "USERS",
    name: "Users",
    icon: "users",
    categories: ["USER_ACCOUNT_DELETED"],
  },
  // Deprecated
  {
    _id: "INSTITUTIONS",
    name: "Clinics",
    icon: "hospital",
    categories: ["INSTITUTION_CREATED"],
  },
  {
    _id: "USERS",
    name: "Users",
    icon: "users",
    categories: ["USER_ACCOUNT_CREATED"],
  },
  {
    _id: "DOCUMENTS",
    name: "Documents",
    icon: "folder open",
    categories: ["FILE_CATEGORY_CREATED"],
  },
  {
    _id: "CUSTOM_FIELDS,PERIODS,IMPORT_DATA,PORTFOLIOS",
    name: "Others",
    icon: "list alternate",
    categories: ["CUSTOM_FIELD_CREATED", "PERIOD_CREATED"],
  },
];
export const onCreateNotificationsCategories = (
  permissions: PayloadObject
): PayloadNotificationCategory[] => {
  let allNotificationsCategories: PayloadNotificationCategory[] = [];
  if (onCheckAllRotationsPermissions(permissions)) {
    allNotificationsCategories = [
      ...allNotificationsCategories,
      ALL_NOTIFICATIONS_CATEGORIES[0],
    ];
  }
  if (onCheckAllCaseLogsPermissions(permissions)) {
    allNotificationsCategories = [
      ...allNotificationsCategories,
      ALL_NOTIFICATIONS_CATEGORIES[1],
    ];
  }
  if (onCheckEvaluationsPermissions(permissions)) {
    allNotificationsCategories = [
      ...allNotificationsCategories,
      ALL_NOTIFICATIONS_CATEGORIES[2],
    ];
  }
  if (onCheckReadAllEvaluationAnswersPermissions(permissions)) {
    allNotificationsCategories = [
      ...allNotificationsCategories,
      ALL_NOTIFICATIONS_CATEGORIES[3],
    ];
  }
  if (onCheckAllReportsPermissions(permissions)) {
    allNotificationsCategories = [
      ...allNotificationsCategories,
      ALL_NOTIFICATIONS_CATEGORIES[4],
    ];
  }
  if (
    onCheckFilesPermissions(permissions) ||
    onCheckUsersFilesPermissions(permissions)
  ) {
    allNotificationsCategories = [
      ...allNotificationsCategories,
      ALL_NOTIFICATIONS_CATEGORIES[5],
    ];
  }
  if (
    onCheckAllCustomFieldsPermissions(permissions) ||
    onCheckPeriodsPermissions(permissions) ||
    onCheckImportDataPermissions(permissions) ||
    onCheckAllUserPortfolioPermissions(permissions)
  ) {
    allNotificationsCategories = [
      ...allNotificationsCategories,
      ALL_NOTIFICATIONS_CATEGORIES[6],
    ];
  }
  return allNotificationsCategories;
};
//
