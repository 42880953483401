// @flow

/* PERMISSIONS */
const onCheckResource = (apiResource: Object, resources: Array<string>) => {
  for (let resource in apiResource) {
    if (
      resources.indexOf(resource) !== -1 &&
      apiResource[resource]['isEnabled']
    ) {
      return true;
    }
  }
  return false;
};
export const onCheckAllEvaluationSchedulersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    'EvaluationSchedulers:create',
    'EvaluationSchedulers:read',
    'EvaluationSchedulers:update',
    'EvaluationSchedulers:delete'
  ];
  const { EvaluationSchedulers } = permissions || {};
  if (EvaluationSchedulers && EvaluationSchedulers.apiValue) {
    return onCheckResource(EvaluationSchedulers.apiValue, resources);
  }
  return false;
};
export const onCheckEvaluationSchedulersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    'EvaluationSchedulers:create',
    'EvaluationSchedulers:read'
  ];
  const { EvaluationSchedulers } = permissions || {};
  if (EvaluationSchedulers && EvaluationSchedulers.apiValue) {
    return onCheckResource(EvaluationSchedulers.apiValue, resources);
  }
  return false;
};
export const onCheckReadEvaluationSchedulersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ['EvaluationSchedulers:read'];
  const { EvaluationSchedulers } = permissions || {};
  if (EvaluationSchedulers && EvaluationSchedulers.apiValue) {
    return onCheckResource(EvaluationSchedulers.apiValue, resources);
  }
  return false;
};
export const onCheckCreateEvaluationSchedulersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ['EvaluationSchedulers:create'];
  const { EvaluationSchedulers } = permissions || {};
  if (EvaluationSchedulers && EvaluationSchedulers.apiValue) {
    return onCheckResource(EvaluationSchedulers.apiValue, resources);
  }
  return false;
};
export const onCheckEditEvaluationSchedulersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ['EvaluationSchedulers:update'];
  const { EvaluationSchedulers } = permissions || {};
  if (EvaluationSchedulers && EvaluationSchedulers.apiValue) {
    return onCheckResource(EvaluationSchedulers.apiValue, resources);
  }
  return false;
};
export const onCheckDeleteEvaluationSchedulersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ['EvaluationSchedulers:delete'];
  const { EvaluationSchedulers } = permissions || {};
  if (EvaluationSchedulers && EvaluationSchedulers.apiValue) {
    return onCheckResource(EvaluationSchedulers.apiValue, resources);
  }
  return false;
};
