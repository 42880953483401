import { createAction } from "deox";

// Actions for Account (My) Settings

export const ACCOUNT_SETTINGS_UPDATE_SETTING_NAME: string =
  "ACCOUNT_SETTINGS/UPDATE/SETTING_NAME";
export const accountSettingsUpdateSettingName = createAction(
  ACCOUNT_SETTINGS_UPDATE_SETTING_NAME,
  (resolve) => (payload?: object) => resolve(payload)
);
export const ACCOUNT_SETTINGS_UPDATE_APP_NEW_FEATURES: string =
  "ACCOUNT_SETTINGS/UPDATE/APP_NEW_FEATURES";
export const accountSettingsUpdateAppNewFeatures = createAction(
  ACCOUNT_SETTINGS_UPDATE_APP_NEW_FEATURES,
  (resolve) => (payload?: object) => resolve(payload)
);
export const ACCOUNT_SETTINGS_UPDATE_FRONTEND_PERSONALIZATION: string =
  "ACCOUNT_SETTINGS/UPDATE/FRONTEND_PERSONALIZATION";
export const accountSettingsUpdateFrontendPersonalization = createAction(
  ACCOUNT_SETTINGS_UPDATE_FRONTEND_PERSONALIZATION,
  (resolve) => (payload?: object) => resolve(payload)
);
export const ACCOUNT_SETTINGS_RESET_FRONTEND_PERSONALIZATION: string =
  "ACCOUNT_SETTINGS/RESET/FRONTEND_PERSONALIZATION";
export const accountSettingsResetFrontendPersonalization = createAction(
  ACCOUNT_SETTINGS_RESET_FRONTEND_PERSONALIZATION,
  (resolve) => (payload?: object) => resolve(payload)
);

// Fetch My Settings
export const ACCOUNT_SETTINGS_FETCH: string = "ACCOUNT_SETTINGS/FETCH";
export const ACCOUNT_SETTINGS_FETCH_REQUEST: string =
  "ACCOUNT_SETTINGS/FETCH/REQUEST";
export const ACCOUNT_SETTINGS_FETCH_SUCCESS: string =
  "ACCOUNT_SETTINGS/FETCH/SUCCESS";
export const ACCOUNT_SETTINGS_FETCH_FAILURE: string =
  "ACCOUNT_SETTINGS/FETCH/FAILURE";
export const accountSettingsFetchMySettings = createAction(
  ACCOUNT_SETTINGS_FETCH,
  (resolve) => (payload?: object) => resolve(payload)
);
export const accountSettingsFetchMySettingsRequest = createAction(
  ACCOUNT_SETTINGS_FETCH_REQUEST
);
export const accountSettingsFetchMySettingsSuccess = createAction(
  ACCOUNT_SETTINGS_FETCH_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const accountSettingsFetchMySettingsFailure = createAction(
  ACCOUNT_SETTINGS_FETCH_FAILURE
);

// Edit My Settings
export const ACCOUNT_SETTINGS_EDIT: string = "ACCOUNT_SETTINGS/EDIT";
export const ACCOUNT_SETTINGS_EDIT_REQUEST: string =
  "ACCOUNT_SETTINGS/EDIT/REQUEST";
export const ACCOUNT_SETTINGS_EDIT_SUCCESS: string =
  "ACCOUNT_SETTINGS/EDIT/SUCCESS";
export const ACCOUNT_SETTINGS_EDIT_FAILURE: string =
  "ACCOUNT_SETTINGS/EDIT/FAILURE";
export const ACCOUNT_SETTINGS_EDIT_DONE: string = "ACCOUNT_SETTINGS/EDIT/DONE";
export const accountSettingsEditMySettings = createAction(
  ACCOUNT_SETTINGS_EDIT,
  (resolve) => (payload?: object) => resolve(payload)
);
export const accountSettingsEditMySettingsRequest = createAction(
  ACCOUNT_SETTINGS_EDIT_REQUEST
);
export const accountSettingsEditMySettingsSuccess = createAction(
  ACCOUNT_SETTINGS_EDIT_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const accountSettingsEditMySettingsFailure = createAction(
  ACCOUNT_SETTINGS_EDIT_FAILURE
);
export const accountSettingsEditMySettingsDone = createAction(
  ACCOUNT_SETTINGS_EDIT_DONE
);
