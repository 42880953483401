import { createAction } from "deox";

// Actions for Custom Fields

// Fetch all Custom Fields
export const CUSTOM_FIELDS_FETCH_CUSTOM_FIELDS: string =
  "CUSTOM_FIELDS/FETCH/CUSTOM_FIELDS";
export const CUSTOM_FIELDS_FETCH_CUSTOM_FIELDS_REQUEST: string =
  "CUSTOM_FIELDS/FETCH/CUSTOM_FIELDS/REQUEST";
export const CUSTOM_FIELDS_FETCH_CUSTOM_FIELDS_SUCCESS: string =
  "CUSTOM_FIELDS/FETCH/CUSTOM_FIELDS/SUCCESS";
export const CUSTOM_FIELDS_FETCH_CUSTOM_FIELDS_FAILURE: string =
  "CUSTOM_FIELDS/FETCH/CUSTOM_FIELDS/FAILURE";
export const customFieldsFetchCustomFields = createAction(
  CUSTOM_FIELDS_FETCH_CUSTOM_FIELDS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const customFieldsFetchCustomFieldsRequest = createAction(
  CUSTOM_FIELDS_FETCH_CUSTOM_FIELDS_REQUEST
);
export const customFieldsFetchCustomFieldsSuccess = createAction(
  CUSTOM_FIELDS_FETCH_CUSTOM_FIELDS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const customFieldsFetchCustomFieldsFailure = createAction(
  CUSTOM_FIELDS_FETCH_CUSTOM_FIELDS_FAILURE
);
// Create a Custom Field
export const CUSTOM_FIELDS_CREATE: string = "CUSTOM_FIELDS/CREATE";
export const CUSTOM_FIELDS_CREATE_REQUEST: string =
  "CUSTOM_FIELDS/CREATE/REQUEST";
export const CUSTOM_FIELDS_CREATE_SUCCESS: string =
  "CUSTOM_FIELDS/CREATE/SUCCESS";
export const CUSTOM_FIELDS_CREATE_FAILURE: string =
  "CUSTOM_FIELDS/CREATE/FAILURE";
export const customFieldsCreate = createAction(
  CUSTOM_FIELDS_CREATE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const customFieldsCreateRequest = createAction(
  CUSTOM_FIELDS_CREATE_REQUEST
);
export const customFieldsCreateSuccess = createAction(
  CUSTOM_FIELDS_CREATE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const customFieldsCreateFailure = createAction(
  CUSTOM_FIELDS_CREATE_FAILURE
);
// Edit a Custom Field
export const CUSTOM_FIELDS_EDIT_FIELD: string =
  "CUSTOM_FIELDS/EDIT/CUSTOM_FIELD";
export const CUSTOM_FIELDS_EDIT_FIELD_REQUEST: string =
  "CUSTOM_FIELDS/EDIT/CUSTOM_FIELD/REQUEST";
export const CUSTOM_FIELDS_EDIT_FIELD_SUCCESS: string =
  "CUSTOM_FIELDS/EDIT/CUSTOM_FIELD/SUCCESS";
export const CUSTOM_FIELDS_EDIT_FIELD_FAILURE: string =
  "CUSTOM_FIELDS/EDIT/CUSTOM_FIELD/FAILURE";
export const customFieldsEditField = createAction(
  CUSTOM_FIELDS_EDIT_FIELD,
  (resolve) => (payload?: object) => resolve(payload)
);
export const customFieldsEditFieldRequest = createAction(
  CUSTOM_FIELDS_EDIT_FIELD_REQUEST
);
export const customFieldsEditFieldSuccess = createAction(
  CUSTOM_FIELDS_EDIT_FIELD_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const customFieldsEditFieldFailure = createAction(
  CUSTOM_FIELDS_EDIT_FIELD_FAILURE
);
// Delete a Custom Field
export const CUSTOM_FIELDS_DELETE_FIELD: string =
  "CUSTOM_FIELDS/DELETE/CUSTOM_FIELD";
export const CUSTOM_FIELDS_DELETE_FIELD_REQUEST: string =
  "CUSTOM_FIELDS/DELETE/CUSTOM_FIELD/REQUEST";
export const CUSTOM_FIELDS_DELETE_FIELD_SUCCESS: string =
  "CUSTOM_FIELDS/DELETE/CUSTOM_FIELD/SUCCESS";
export const CUSTOM_FIELDS_DELETE_FIELD_FAILURE: string =
  "CUSTOM_FIELDS/DELETE/CUSTOM_FIELD/FAILURE";
export const customFieldsDeleteField = createAction(
  CUSTOM_FIELDS_DELETE_FIELD,
  (resolve) => (payload?: object) => resolve(payload)
);
export const customFieldsDeleteFieldRequest = createAction(
  CUSTOM_FIELDS_DELETE_FIELD_REQUEST
);
export const customFieldsDeleteFieldSuccess = createAction(
  CUSTOM_FIELDS_DELETE_FIELD_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const customFieldsDeleteFieldFailure = createAction(
  CUSTOM_FIELDS_DELETE_FIELD_FAILURE
);

// Edit a Custom Field
export const CUSTOM_FIELDS_REQUIRED_TRIGER_ANIMATION: string =
  "CUSTOM_FIELDS/REQUIRED/TRIGER_ANIMATION";
export const customFieldsRequiredTriggerAnimation = createAction(
  CUSTOM_FIELDS_REQUIRED_TRIGER_ANIMATION
);
