import { createAction } from "deox";

// Actions for Access Control
export const ACCESS_CONTROL_INIT: string = "ACCESS_CONTROL/INIT";
export const initAccesControl = createAction(ACCESS_CONTROL_INIT);

// Actions for Roles
// Role Selected
export const ACCESS_CONTROL_ROLE_SELECTED: string =
  "ACCESS_CONTROL/ROLE/SELECTED";
export const accesControlRoleSelected = createAction(
  ACCESS_CONTROL_ROLE_SELECTED,
  (resolve) => (payload?: object) => resolve(payload)
);
// Fetch all roles
export const ACCESS_CONTROL_FETCH_ROLES: string = "ACCESS_CONTROL/FETCH/ROLES";
export const ACCESS_CONTROL_FETCH_ROLES_REQUEST: string =
  "ACCESS_CONTROL/FETCH/ROLES/REQUEST";
export const ACCESS_CONTROL_FETCH_ROLES_SUCCESS: string =
  "ACCESS_CONTROL/FETCH/ROLES/SUCCESS";
export const ACCESS_CONTROL_FETCH_ROLES_FAILURE: string =
  "ACCESS_CONTROL/FETCH/ROLES/FAILURE";
export const accessControlFetchRoles = createAction(
  ACCESS_CONTROL_FETCH_ROLES,
  (resolve) => (payload?: object) => resolve(payload)
);
export const accessControlFetchRolesRequest = createAction(
  ACCESS_CONTROL_FETCH_ROLES_REQUEST
);
export const accessControlFetchRolesSuccess = createAction(
  ACCESS_CONTROL_FETCH_ROLES_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const accessControlFetchRolesFailure = createAction(
  ACCESS_CONTROL_FETCH_ROLES_FAILURE,
  (resolve) => (payload?: object) => resolve(payload)
);
// Create a Role
export const ACCESS_CONTROL_CREATE_ROLE: string = "ACCESS_CONTROL/CREATE/ROLE";
export const ACCESS_CONTROL_CREATE_ROLE_REQUEST: string =
  "ACCESS_CONTROL/CREATE/ROLE/REQUEST";
export const ACCESS_CONTROL_CREATE_ROLE_SUCCESS: string =
  "ACCESS_CONTROL/CREATE/ROLE/SUCCESS";
export const ACCESS_CONTROL_CREATE_ROLE_FAILURE: string =
  "ACCESS_CONTROL/CREATE/ROLE/FAILURE";
export const accessControlCreateRole = createAction(
  ACCESS_CONTROL_CREATE_ROLE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const accessControlCreateRoleRequest = createAction(
  ACCESS_CONTROL_CREATE_ROLE_REQUEST
);
export const accessControlCreateRoleSuccess = createAction(
  ACCESS_CONTROL_CREATE_ROLE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const accessControlCreateRoleFailure = createAction(
  ACCESS_CONTROL_CREATE_ROLE_FAILURE
);
// Update a Resource from some Role
export const ACCESS_CONTROL_UPDATE_ROLE_RESOURCE: string =
  "ACCESS_CONTROL/UPDATE/ROLE/RESOURCE";
export const ACCESS_CONTROL_UPDATE_ROLE_RESOURCE_REQUEST: string =
  "ACCESS_CONTROL/UPDATE/ROLE/RESOURCE/REQUEST";
export const ACCESS_CONTROL_UPDATE_ROLE_RESOURCE_SUCCESS: string =
  "ACCESS_CONTROL/UPDATE/ROLE/RESOURCE/SUCCESS";
export const ACCESS_CONTROL_UPDATE_ROLE_RESOURCE_FAILURE: string =
  "ACCESS_CONTROL/UPDATE/ROLE/RESOURCE/FAILURE";
export const accessControlUpdateRoleResource = createAction(
  ACCESS_CONTROL_UPDATE_ROLE_RESOURCE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const accessControlUpdateRoleResourceRequest = createAction(
  ACCESS_CONTROL_UPDATE_ROLE_RESOURCE_REQUEST
);
export const accessControlUpdateRoleResourceSuccess = createAction(
  ACCESS_CONTROL_UPDATE_ROLE_RESOURCE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const accessControlUpdateRoleResourceFailure = createAction(
  ACCESS_CONTROL_UPDATE_ROLE_RESOURCE_FAILURE
);
// Delete a Role
export const ACCESS_CONTROL_DELETE_ROLE: string = "ACCESS_CONTROL/DELETE/ROLE";
export const ACCESS_CONTROL_DELETE_ROLE_REQUEST: string =
  "ACCESS_CONTROL/DELETE/ROLE/REQUEST";
export const ACCESS_CONTROL_DELETE_ROLE_SUCCESS: string =
  "ACCESS_CONTROL/DELETE/ROLE/SUCCESS";
export const ACCESS_CONTROL_DELETE_ROLE_FAILURE: string =
  "ACCESS_CONTROL/DELETE/ROLE/FAILURE";
export const accessControlDeleteRole = createAction(
  ACCESS_CONTROL_DELETE_ROLE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const accessControlDeleteRoleRequest = createAction(
  ACCESS_CONTROL_DELETE_ROLE_REQUEST
);
export const accessControlDeleteRoleSuccess = createAction(
  ACCESS_CONTROL_DELETE_ROLE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const accessControlDeleteRoleFailure = createAction(
  ACCESS_CONTROL_DELETE_ROLE_FAILURE
);

// Actions for Dynamic Rules
// Fetch all dynamic rules
export const ACCESS_CONTROL_FETCH_DYNAMIC_RULES: string =
  "ACCESS_CONTROL/FETCH/DYNAMIC_RULES";
export const ACCESS_CONTROL_FETCH_DYNAMIC_RULES_REQUEST: string =
  "ACCESS_CONTROL/FETCH/DYNAMIC_RULES/REQUEST";
export const ACCESS_CONTROL_FETCH_DYNAMIC_RULES_SUCCESS: string =
  "ACCESS_CONTROL/FETCH/DYNAMIC_RULES/SUCCESS";
export const ACCESS_CONTROL_FETCH_DYNAMIC_RULES_FAILURE: string =
  "ACCESS_CONTROL/FETCH/DYNAMIC_RULES/FAILURE";
export const accessControlFetchDynamicRules = createAction(
  ACCESS_CONTROL_FETCH_DYNAMIC_RULES,
  (resolve) => (payload?: object) => resolve(payload)
);
export const accessControlFetchDynamicRulesRequest = createAction(
  ACCESS_CONTROL_FETCH_DYNAMIC_RULES_REQUEST
);
export const accessControlFetchDynamicRulesSuccess = createAction(
  ACCESS_CONTROL_FETCH_DYNAMIC_RULES_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const accessControlFetchDynamicRulesFailure = createAction(
  ACCESS_CONTROL_FETCH_DYNAMIC_RULES_FAILURE
);
// Create a Dynamic Rule
export const ACCESS_CONTROL_CREATE_DYNAMIC_RULE: string =
  "ACCESS_CONTROL/CREATE/DYNAMIC_RULE";
export const ACCESS_CONTROL_CREATE_DYNAMIC_RULE_REQUEST: string =
  "ACCESS_CONTROL/CREATE/DYNAMIC_RULE/REQUEST";
export const ACCESS_CONTROL_CREATE_DYNAMIC_RULE_SUCCESS: string =
  "ACCESS_CONTROL/CREATE/DYNAMIC_RULE/SUCCESS";
export const ACCESS_CONTROL_CREATE_DYNAMIC_RULE_FAILURE: string =
  "ACCESS_CONTROL/CREATE/DYNAMIC_RULE/FAILURE";
export const accessControlCreateDynamicRule = createAction(
  ACCESS_CONTROL_CREATE_DYNAMIC_RULE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const accessControlCreateDynamicRuleRequest = createAction(
  ACCESS_CONTROL_CREATE_DYNAMIC_RULE_REQUEST
);
export const accessControlCreateDynamicRuleSuccess = createAction(
  ACCESS_CONTROL_CREATE_DYNAMIC_RULE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const accessControlCreateDynamicRuleFailure = createAction(
  ACCESS_CONTROL_CREATE_DYNAMIC_RULE_FAILURE
);
// Edit a Dynamic Rule
export const ACCESS_CONTROL_EDIT_DYNAMIC_RULE: string =
  "ACCESS_CONTROL/EDIT/DYNAMIC_RULE";
export const ACCESS_CONTROL_EDIT_DYNAMIC_RULE_REQUEST: string =
  "ACCESS_CONTROL/EDIT/DYNAMIC_RULE/REQUEST";
export const ACCESS_CONTROL_EDIT_DYNAMIC_RULE_SUCCESS: string =
  "ACCESS_CONTROL/EDIT/DYNAMIC_RULE/SUCCESS";
export const ACCESS_CONTROL_EDIT_DYNAMIC_RULE_FAILURE: string =
  "ACCESS_CONTROL/EDIT/DYNAMIC_RULE/FAILURE";
export const accessControlEditDynamicRule = createAction(
  ACCESS_CONTROL_EDIT_DYNAMIC_RULE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const accessControlEditDynamicRuleRequest = createAction(
  ACCESS_CONTROL_EDIT_DYNAMIC_RULE_REQUEST
);
export const accessControlEditDynamicRuleSuccess = createAction(
  ACCESS_CONTROL_EDIT_DYNAMIC_RULE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const accessControlEditDynamicRuleFailure = createAction(
  ACCESS_CONTROL_EDIT_DYNAMIC_RULE_FAILURE
);
// Delete a Dynamic Rule
export const ACCESS_CONTROL_DELETE_DYNAMIC_RULE: string =
  "ACCESS_CONTROL/DELETE/DYNAMIC_RULE";
export const ACCESS_CONTROL_DELETE_DYNAMIC_RULE_REQUEST: string =
  "ACCESS_CONTROL/DELETE/DYNAMIC_RULE/REQUEST";
export const ACCESS_CONTROL_DELETE_DYNAMIC_RULE_SUCCESS: string =
  "ACCESS_CONTROL/DELETE/DYNAMIC_RULE/SUCCESS";
export const ACCESS_CONTROL_DELETE_DYNAMIC_RULE_FAILURE: string =
  "ACCESS_CONTROL/DELETE/DYNAMIC_RULE/FAILURE";
export const accessControlDeleteDynamicRule = createAction(
  ACCESS_CONTROL_DELETE_DYNAMIC_RULE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const accessControlDeleteDynamicRuleRequest = createAction(
  ACCESS_CONTROL_DELETE_DYNAMIC_RULE_REQUEST
);
export const accessControlDeleteDynamicRuleSuccess = createAction(
  ACCESS_CONTROL_DELETE_DYNAMIC_RULE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const accessControlDeleteDynamicRuleFailure = createAction(
  ACCESS_CONTROL_DELETE_DYNAMIC_RULE_FAILURE
);
