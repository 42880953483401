// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { onCheckEvaluationsPermissions } from "./EvaluationsPermissions";
import {
  onCheckEvaluationSchedulersPermissions,
  onCheckReadEvaluationSchedulersPermissions,
} from "../evaluations-schedulers/EvaluationSchedulersPermissions";
import { onCheckEvaluationTemplatesPermissions } from "../evaluations-templates/EvaluationTemplatesPermissions";
import { store } from "../../index";
import { Evaluations } from "../../store/actions/";
import ProtectedRoute from "../shared/ProtectedRoute";

const mapDispatchToProps = (dispatch: Function, ownProps: Object) => {
  const {
    computedMatch: {
      params: { evaluationId, answerId },
    },
    location: { state },
  } = ownProps;
  const { evaluationId: storedEvaluationId, answerId: storedAnswerId } =
    store.getState().evaluations;
  if (evaluationId && evaluationId !== storedEvaluationId) {
    if (state && state.evaluation) {
      dispatch(
        Evaluations.evaluationsFetchEvaluationSuccess({
          evaluation: state.evaluation,
        })
      );
    }
    dispatch(Evaluations.evaluationsSetEvaluationId({ evaluationId }));
  }
  if (answerId && answerId !== storedAnswerId) {
    if (state && state.answer) {
      dispatch(
        Evaluations.evaluationsFetchEvaluationAnswerSuccess({
          answer: state.answer,
        })
      );
    }
    dispatch(Evaluations.evaluationsSetAnswerId({ answerId }));
  }
  return { dispatch };
};
const EvaluationsRoute: React.ComponentType<any> = withRouter(
  connect(
    (
      { config, auth: { isAuthenticated, role } },
      {
        path,
        computedMatch: {
          params: { evaluationId, answers_review, answerId },
          url,
        },
      }
    ) => {
      let allowedEvaluationsRoute =
        config.companyDomain.length > 0 &&
        isAuthenticated &&
        (onCheckEvaluationsPermissions(role.permissions) ||
          onCheckEvaluationSchedulersPermissions(role.permissions) ||
          onCheckEvaluationTemplatesPermissions(role.permissions));
      let redirectTo = allowedEvaluationsRoute ? "/evaluations" : "/home";
      if (
        allowedEvaluationsRoute &&
        path &&
        path === "/evaluations/schedulers" &&
        !onCheckReadEvaluationSchedulersPermissions(role.permissions)
      ) {
        allowedEvaluationsRoute = false;
      } else if (
        allowedEvaluationsRoute &&
        path &&
        path ===
          "/evaluations/:evaluationId(\\w+)?/:answers_review?/:answerId(\\w+)?" &&
        url
      ) {
        if (allowedEvaluationsRoute && evaluationId) {
          if (!answers_review) {
            allowedEvaluationsRoute = false;
          } else {
            if (answers_review !== "answers" && answers_review !== "review") {
              allowedEvaluationsRoute = false;
            } else if (answers_review === "answers" && !answerId) {
              allowedEvaluationsRoute = false;
            }
          }
        }
      }
      return {
        component: null,
        restProps: null,
        isAllowedRoute:
          config.companyDomain.length > 0 &&
          isAuthenticated &&
          allowedEvaluationsRoute,
        redirectTo,
      };
    },
    mapDispatchToProps
  )(ProtectedRoute)
);

export default EvaluationsRoute;
