// Features
import produce from "immer";
import { Company, Files } from "../actions/";
// Types
import type {
  CompanyActionReducers as Action,
  StateCompany as State,
} from "../../types/company.types";
// Company Reducer
const initialState: State = {
  myCompanyLoaded: false,
  myCompanyStatus: "ready",
  myCompany: undefined,
  myCompanyFiles: [],
  myCompanyFilesExpired: [],
};
const company: (state: State, action: Action) => State = produce(
  (draft: State, action: Action) => {
    switch (action.type) {
      case Company.COMPANY_FETCH_COMPANY:
      case Company.COMPANY_FETCH_COMPANY_FILES:
      case Company.COMPANY_FETCH_COMPANY_FILES_EXPIRED:
        draft.myCompanyStatus = "requesting";
        break;
      case Company.COMPANY_FETCH_COMPANY_SUCCESS:
      case Company.COMPANY_EDIT_COMPANY_SUCCESS:
        draft.myCompany = action.payload.myCompany;
        draft.myCompanyLoaded = true;
        draft.myCompanyStatus = "success";
        break;
      case Company.COMPANY_FETCH_COMPANY_FILES_SUCCESS:
      case Files.FILES_EDIT_SUCCESS:
      case Files.FILES_DELETE_SUCCESS:
        draft.myCompanyFiles = action.payload.files;
        draft.myCompanyStatus = "success";
        break;
      case Company.COMPANY_FETCH_COMPANY_FILES_EXPIRED_SUCCESS:
        draft.myCompanyFilesExpired = action.payload.filesExpired;
        draft.myCompanyStatus = "success";
        break;
      case Company.COMPANY_FETCH_COMPANY_FAILURE:
      case Company.COMPANY_FETCH_COMPANY_FILES_FAILURE:
      case Company.COMPANY_FETCH_COMPANY_FILES_EXPIRED_FAILURE:
        draft.myCompanyStatus = "error";
        break;
    }
  },
  initialState
);
export default company;
