// Features
import produce from "immer";
import { Evaluations, EvaluationsTemplates } from "../actions/";
import { updateArrayWithElement } from "../../utils/";
// Types
// import type { Action } from "../../types/common.types";
import type { Action } from "deox";
import type {
  EvaluationsTypes,
  EvaluationsMetadata,
  AnswersMetadata,
  StateEvaluations as State,
} from "../../types/evaluations.types";
type EvaluationsActionReducers = Action<
  string,
  {
    answer: State["answer"];
    answerId: State["answerId"];
    answers: State["answers"];
    evaluation: State["evaluation"];
    evaluationAnswers: State["evaluation"]["answers"];
    evaluationId: State["evaluationId"];
    evaluations: State["evaluations"];
    evaluationsFilters: State["evaluationsFilters"];
    kpis: State["evaluations"]["kpis"] | State["answers"]["kpis"];
    metadata: { [k in EvaluationsTypes]: number };
    selected: State["evaluationTabs"]["selected"];
  }
>;
// Evaluations Reducer
export const EVALUATIONS_LIMIT = 20;
const initialStateEvaluationTabs: State["evaluationTabs"] = {
  panes: [
    { menuItem: "User Evaluations", name: "UserEvaluations" },
    { menuItem: "Clinic Evaluations", name: "InstitutionEvaluations" },
    { menuItem: "School Evaluations", name: "CompanyEvaluations" },
    { menuItem: "Custom Evaluations", name: "CustomEvaluations" },
  ],
  selected: 0,
};
const initialStateEvaluationsMetadata: EvaluationsMetadata = {
  numTotalEvaluations: 0,
  numTotalEvaluationsInCourse: 0,
  numTotalEvaluationsCompleted: 0,
  numTotalEvaluationsClosed: 0,
  totalPages: 1,
};
const initialStateAnswersMetadata: AnswersMetadata = {
  numTotalAnswers: 0,
  numTotalAnswersDraft: 0,
  numTotalAnswersCompleted: 0,
  totalPagesByAnswersCompleted: 1,
  totalPages: 1,
};
const initialState: State = {
  // Evaluations
  evaluationTabs: initialStateEvaluationTabs,
  evaluation: {
    data: null,
    answers: [],
  },
  evaluationId: null,
  evaluations: {
    all: [],
    UserEvaluations: [],
    InstitutionEvaluations: [],
    CompanyEvaluations: [],
    CustomEvaluations: [],
    metadata: {
      UserEvaluations: initialStateEvaluationsMetadata,
      InstitutionEvaluations: initialStateEvaluationsMetadata,
      CompanyEvaluations: initialStateEvaluationsMetadata,
      CustomEvaluations: initialStateEvaluationsMetadata,
    },
    kpis: {
      fetching: false,
      Evaluations: null,
    },
  },
  evaluationsFilters: null,
  // Answers
  answers: {
    all: [],
    completed: {
      UserEvaluations: [],
      InstitutionEvaluations: [],
      CompanyEvaluations: [],
      CustomEvaluations: [],
    },
    draft: {
      UserEvaluations: [],
      InstitutionEvaluations: [],
      CompanyEvaluations: [],
      CustomEvaluations: [],
    },
    metadata: {
      UserEvaluations: initialStateAnswersMetadata,
      InstitutionEvaluations: initialStateAnswersMetadata,
      CompanyEvaluations: initialStateAnswersMetadata,
      CustomEvaluations: initialStateAnswersMetadata,
    },
    kpis: {
      fetching: false,
      EvaluationsAnswers: [],
    },
  },
  answer: null,
  answerId: null,
};
const evaluations: (state: State, action: EvaluationsActionReducers) => State =
  produce((draft: State, action: EvaluationsActionReducers) => {
    const evaluationsTabSelected =
      draft.evaluationTabs.panes[draft.evaluationTabs.selected].name;
    switch (action.type) {
      case Evaluations.EVALUATIONS_SET_TAB_SELECTED:
        draft.evaluationTabs.selected = action.payload.selected;
        break;
      case Evaluations.EVALUATIONS_FETCH_ALL_SUCCESS:
        draft.evaluations.metadata = getEvaluationsMetadata(action);
        break;
      case Evaluations.EVALUATIONS_FETCH_BY_FILTERS_SUCCESS:
        draft.evaluations = {
          ...action.payload.evaluations,
          kpis: draft.evaluations.kpis,
        };
        draft.evaluations.metadata = getEvaluationsMetadata(action);
        draft.evaluationsFilters = action.payload.evaluationsFilters;
        break;
      case Evaluations.EVALUATIONS_FETCH_KPIS_REQUEST:
        if (!draft.evaluations.kpis) {
          // Compatibility v2.21.0
          draft.evaluations = {
            ...draft.evaluations,
            kpis: {
              ...initialState.evaluations.kpis,
              fetching: true,
            },
          };
        } else {
          draft.evaluations.kpis.fetching = true;
        }
        break;
      case Evaluations.EVALUATIONS_FETCH_KPIS_SUCCESS:
      case Evaluations.EVALUATIONS_FETCH_KPIS_FAILURE:
        draft.evaluations.kpis = action.payload
          .kpis as State["evaluations"]["kpis"];
        draft.evaluations.kpis.fetching = false;
        break;
      case Evaluations.EVALUATIONS_ANSWERS_FETCH_KPIS_REQUEST:
        if (!draft.answers.kpis) {
          // Compatibility v2.21.0
          draft.answers.kpis = {
            ...initialState.answers.kpis,
            fetching: true,
          };
        } else {
          draft.answers.kpis.fetching = true;
        }
        break;
      case Evaluations.EVALUATIONS_ANSWERS_FETCH_KPIS_SUCCESS:
      case Evaluations.EVALUATIONS_ANSWERS_FETCH_KPIS_FAILURE:
        draft.answers.kpis = action.payload.kpis as State["answers"]["kpis"];
        draft.answers.kpis.fetching = false;
        break;
      case Evaluations.EVALUATIONS_EDIT_SUCCESS:
        draft.evaluations.all = updateArrayWithElement(
          draft.evaluations.all,
          action.payload.evaluation
        );
        draft.evaluations[evaluationsTabSelected] = updateArrayWithElement(
          draft.evaluations[evaluationsTabSelected],
          action.payload.evaluation
        );
        break;
      case Evaluations.EVALUATIONS_DELETE_SUCCESS:
        draft.evaluations.all = draft.evaluations.all.filter(
          ({ _id }) => _id !== action.payload.evaluationId
        );
        draft.evaluations[evaluationsTabSelected] = draft.evaluations[
          evaluationsTabSelected
        ].filter(({ _id }) => _id !== action.payload.evaluationId);
        break;
      case Evaluations.EVALUATIONS_FETCH_ANSWERS_SUCCESS:
        draft.answers = {
          ...action.payload.answers,
          kpis: draft.answers.kpis,
        };
        draft.answers.metadata = getAnswersMetadata(action);
        break;
      case Evaluations.EVALUATIONS_SET_EVALUATION_ID:
        draft.evaluationId = action.payload.evaluationId;
        break;
      case Evaluations.EVALUATIONS_FETCH_EVALUATION_SUCCESS:
        draft.evaluation.data = action.payload.evaluation;
        break;
      case Evaluations.EVALUATIONS_FETCH_EVALUATION_ANSWERS_SUCCESS:
        draft.evaluation.answers = action.payload.evaluationAnswers;
        break;
      case Evaluations.EVALUATIONS_SET_ANSWER_ID:
        draft.answerId = action.payload.answerId;
        break;
      case Evaluations.EVALUATIONS_FETCH_EVALUATION_ANSWER_SUCCESS:
        draft.answer = action.payload.answer;
        break;
      case Evaluations.EVALUATIONS_RESET_ANSWER_LOADED:
        draft.answer = initialState.answer;
        draft.answerId = initialState.answerId;
        break;
      case Evaluations.EVALUATIONS_RESET_EVALUATION_LOADED:
        draft.evaluation = initialState.evaluation;
        draft.evaluationId = initialState.evaluationId;
        break;
      case EvaluationsTemplates.EVALUATIONS_TEMPLATES_RESET_EVALUATION_TEMPLATE_LOADED:
        return {
          ...initialState,
          evaluationTabs: draft.evaluationTabs,
          evaluations: draft.evaluations,
          answers: draft.answers,
        };
    }
  }, initialState);
// GET EvaluationsMetadata
const getEvaluationsMetadata = (action: EvaluationsActionReducers) => {
  const { metadata, evaluations } = action.payload;
  let newMetadata = {
    UserEvaluations: initialStateEvaluationsMetadata,
    InstitutionEvaluations: initialStateEvaluationsMetadata,
    CompanyEvaluations: initialStateEvaluationsMetadata,
    CustomEvaluations: initialStateEvaluationsMetadata,
  };
  for (let evaluationType in evaluations) {
    if (evaluationType !== "all") {
      const {
        incourse = 0,
        completed = 0,
        closed = 0,
      } = {
        incourse: evaluations[evaluationType as EvaluationsTypes].filter(
          ({ status }) => status === "incourse"
        ).length,
        completed: (
          evaluations[
            evaluationType as EvaluationsTypes
          ] as State["evaluations"]["all"]
        ).filter(({ status }) => status === "completed").length,
        closed: (
          evaluations[
            evaluationType as EvaluationsTypes
          ] as State["evaluations"]["all"]
        ).filter(({ status }) => status === "closed").length,
      };
      let numTotalEvaluations = incourse + completed + closed;
      if (metadata && metadata[evaluationType as EvaluationsTypes]) {
        numTotalEvaluations = metadata[evaluationType as EvaluationsTypes];
      }
      newMetadata = {
        ...newMetadata,
        [evaluationType]: {
          numTotalEvaluations,
          numTotalEvaluationsInCourse: incourse,
          numTotalEvaluationsCompleted: completed,
          numTotalEvaluationsClosed: closed,
          totalPages:
            1 +
            parseInt(
              ((numTotalEvaluations - 1) / EVALUATIONS_LIMIT).toString(),
              10
            ),
        },
      };
    }
  }
  return newMetadata;
};
// GET AnswersMetadata
const getAnswersMetadata = (action: EvaluationsActionReducers) => {
  const { metadata, answers } = action.payload;
  let newMetadata = {
    UserEvaluations: initialStateAnswersMetadata,
    InstitutionEvaluations: initialStateAnswersMetadata,
    CompanyEvaluations: initialStateAnswersMetadata,
    CustomEvaluations: initialStateAnswersMetadata,
  };
  for (let evaluationType in answers.completed) {
    const { draft = 0 } = {
      draft: answers.draft[evaluationType as EvaluationsTypes].length,
    };
    const { completed = 0 } = {
      completed: answers.completed[evaluationType as EvaluationsTypes].length,
    };
    let numTotalAnswers = draft + completed;
    const evaluationTypeToCheck = `${evaluationType.slice(
      0,
      evaluationType.length - 1
    )}Answers`;
    if (metadata && metadata[evaluationTypeToCheck as EvaluationsTypes]) {
      numTotalAnswers = metadata[evaluationTypeToCheck as EvaluationsTypes];
    }
    newMetadata = {
      ...newMetadata,
      [evaluationType]: {
        numTotalAnswers,
        numTotalAnswersDraft: draft,
        numTotalAnswersCompleted: completed,
        totalPagesByAnswersCompleted:
          1 +
          parseInt(((numTotalAnswers - 1) / EVALUATIONS_LIMIT).toString(), 10),
        totalPages:
          1 +
          parseInt(((numTotalAnswers - 1) / EVALUATIONS_LIMIT).toString(), 10),
      },
    };
  }
  return newMetadata;
};
export default evaluations;
