// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ProtectedRoute from "../shared/ProtectedRoute";

const RedirectsRoute: React.ComponentType<any> = withRouter(
  connect(
    (
      { config, auth: { isAuthenticated } },
      { path, computedMatch: { url } }
    ) => {
      let redirectTo = "/home";
      let isAllowedRoute = config.companyDomain.length > 0 && isAuthenticated;
      if (isAllowedRoute && path && url) {
        isAllowedRoute = false;
        redirectTo = url.slice(0, url.length - 1);
      }
      return {
        component: null,
        restProps: null,
        isAllowedRoute,
        redirectTo,
      };
    }
  )(ProtectedRoute)
);

export default RedirectsRoute;
