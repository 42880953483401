import type { Config, tAPI } from "./API";

// API Feedback Report or Bugs static class
const apiBaseFeedbackForm = "/feedback-reports";
export default class FeedbackForm {
  // Send feedback form
  static SendFeedbackForm(
    api: tAPI,
    data: object,
    isUpload: boolean = false,
    config?: Config
  ) {
    if (isUpload) {
      return api.UPLOAD(`${apiBaseFeedbackForm}`, data, "POST", config);
    }
    return api.POST(`${apiBaseFeedbackForm}`, data, config);
  }
}
