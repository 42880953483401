// @flow
// Import Features
import * as React from "react";
import { connect } from "react-redux";
import ReactJoyride from "react-joyride";
import { GuideLineTutorial } from "../../store/actions/";
import { EVENTS, STATUS } from "react-joyride";
// Import Types
import type { PropsGuideLineTutorialContainer as Props } from "../../types/guideLineTutorial.types";
class GuideLineTutorialContainer extends React.Component<Props> {
  // <METHODS PRIVATE
  _handleJoyrideCallback = (data) => {
    const {
      joyrideProps: { callback = (CallbackProps: any) => CallbackProps },
      finishTutorial,
    } = this.props;
    callback(data);
    const { type, status } = data;
    if (
      EVENTS.TOUR_END === type &&
      (STATUS.FINISHED === status || STATUS.SKIPPED === status)
    ) {
      finishTutorial();
    }
  };
  // END />
  render() {
    const { canRenderTutorial, joyrideProps } = this.props;
    if (canRenderTutorial && joyrideProps.run) {
      return (
        <ReactJoyride
          continuous
          showProgress
          showSkipButton
          styles={{
            options: {
              primaryColor: "#77c0cf",
              zIndex: 10000,
            },
          }}
          {...joyrideProps}
          callback={this._handleJoyrideCallback}
        />
      );
    }
    return null;
  }
}

export default connect(
  ({
    accountSettings: { isFetchingAccountSettings },
    guideLineTutorial: { joyrideProps, sections, sectionsCompleted },
  }) => ({
    canRenderTutorial:
      !isFetchingAccountSettings && sections.length > sectionsCompleted.length,
    joyrideProps,
  }),
  (dispatch) => ({
    finishTutorial: () =>
      dispatch(GuideLineTutorial.guideLineTutorialCompleted()),
  })
)(GuideLineTutorialContainer);
