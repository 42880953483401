import type { Config, tAPI } from "./API";
// API File Categories static class

const apiBaseFileCategories = "/file-categories";
export default class FileCategories {
  // Get a list of file categories
  static GetFileCategories(api: tAPI, config?: Config) {
    return api.GET(`${apiBaseFileCategories}`, config);
  }
  // Create a file category
  static CreateFileCategory(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBaseFileCategories}`, data, config);
  }
  // Edit a file category
  static EditFileCategory(
    api: tAPI,
    fileCategoryId: string,
    data: object,
    config?: Config
  ) {
    return api.PATCH(
      `${apiBaseFileCategories}/${fileCategoryId}`,
      data,
      config
    );
  }
  // Delete a file category
  static DeleteFileCategory(
    api: tAPI,
    fileCategoryId: string,
    config?: Config
  ) {
    return api.DELETE(`${apiBaseFileCategories}/${fileCategoryId}`, config);
  }
}
