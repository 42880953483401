// Utils
import moment from "moment";
import { app as appJson } from "../app.json";
import { LocalStore } from "./local-storage/localStore";
// Types
import type { Moment } from "moment";
import type { UsersRole } from "../types/users.types";
/* eslint-disable no-useless-escape */
// Utils Environment
export const isDevelopmentEnv = () => process.env.NODE_ENV === "development";
export const isDevelopmentReactAppEnv = () => appJson.appEnv !== "production";
export const emptyVoid = () => {};
export const emptyVoidVoid = () => () => {};
export const isMobileBrowser = () => {
  let isMobile = false; //initiate as false
  // device detection
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      navigator.userAgent.substr(0, 4)
    )
  )
    isMobile = true;
  return isMobile;
};
export const isTouchableDevice = () => {
  let isTouchable = false; //initiate as false
  // device detection
  if (typeof window === "object" && "ontouchstart" in window)
    isTouchable = true;
  return isTouchable;
};
export const isURL = (text: string) =>
  /^(?:(?:(?:https?|ftp):)?\/\/)?[\w.-]+\.[a-z]{2,}(?:\/\S*)?$/i.test(text);
export const onComparePath = (regex: RegExp, pathname: string): boolean => {
  const [path] = pathname.match(regex) || [];
  if (path && path === pathname) {
    return true;
  }
  return false;
};
export const onGetUrlDomain = () => {
  const [hostname] = window.location.host.split(":");
  return `${window.location.protocol}//${hostname
    .split(".")
    .slice(1)
    .join(".")}`;
};
export const onGetTenant = () => {
  const [hostname] = window.location.host.split(":");
  let tenant = "";
  if (hostname) {
    [tenant] = hostname.split(".");
  }
  return tenant;
};
export const onGetToken = () => {
  const {
    auth: { token = "" },
  } = LocalStore.Read("authState") || {
    auth: {
      token: "",
    },
  };
  return token;
};
export const parseApiName = (apiName: string) =>
  apiName.replace(
    /(Company|Companies|Institution|Institutions|Administrator|Administrators|Faculties|File|Files)/gi,
    (str: string) => {
      switch (str) {
        case "Company":
          return "School";
        case "Companies":
          return "Schools";
        case "Institution":
          return "Clinic";
        case "Institutions":
          return "Clinics";
        case "Administrators":
          return "Admins";
        case "Administrator":
          return "Admin";
        case "Faculties":
          return "Faculty";
        case "Files":
          return "Documents";
        case "File":
          return "Document";
        default:
          return str;
      }
    }
  );
export const parseName = (name: UsersRole | string = "") =>
  name.split(/(?=[A-Z])/).join(" ");
export const onSetRoleName = (roleName: string = ""): string => {
  if (roleName?.length && roleName[roleName.length - 1] === "s") {
    return capitalizeFirstLetter(roleName).slice(0, -1);
  }
  return roleName;
};
export function parseQueryParams(query: string): object {
  let queryParams: any = {
    length: 0,
  };
  if (query) {
    const queryArray = query.split("?")[1].split("&");
    for (let i = 0; i < queryArray.length; i++) {
      const [key, val] = queryArray[i].split("=");
      queryParams[key] = val;
    }
    queryParams.length = queryArray.length;
  }
  return queryParams;
}
export function setUserRole(role: string): {
  apiName: string;
  type: string;
  name: string;
} {
  return {
    apiName: role,
    type: role.replace(/\s/g, "").toLowerCase(),
    name: parseApiName(parseName(role)),
  };
}
// Utils for Date
export const generateRandomDates = (
  startDate: Date,
  endDate: Date,
  count: number
): string[] => {
  const randomDates: string[] = [];

  const startDateTimestamp = startDate.getTime();
  const endDateTimestamp = endDate.getTime();

  for (let i = 0; i < count; i++) {
    const randomTimestamp = Math.floor(
      Math.random() * (endDateTimestamp - startDateTimestamp + 1) +
        startDateTimestamp
    );
    const randomDate = new Date(randomTimestamp);
    const dateString = randomDate.toISOString().split("T")[0];
    if (!randomDates.includes(dateString)) {
      randomDates.push(dateString);
    }
  }
  return randomDates.sort();
};
export function isValidISODate(date: string) {
  return !!date.match(
    /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$/
  );
}
export const parseDateToLocaleString = (
  timeStamp: number,
  withTime: boolean = false
): string => {
  const dateJs = new Date(timeStamp * 1000);
  const hour = dateJs.getHours(),
    minutes = dateJs.getMinutes();
  let strMonth = "";
  switch (dateJs.getMonth()) {
    case 0:
      strMonth = "Jan";
      break;
    case 1:
      strMonth = "Feb";
      break;
    case 2:
      strMonth = "Mar";
      break;
    case 3:
      strMonth = "Apr";
      break;
    case 4:
      strMonth = "May";
      break;
    case 5:
      strMonth = "Jun";
      break;
    case 6:
      strMonth = "Jul";
      break;
    case 7:
      strMonth = "Aug";
      break;
    case 8:
      strMonth = "Sep";
      break;
    case 9:
      strMonth = "Oct";
      break;
    case 10:
      strMonth = "Nov";
      break;
    case 11:
      strMonth = "Dec";
      break;
    default:
      break;
  }
  let time = "";
  if (withTime) {
    time = ` at ${hour > 9 ? hour : "0" + hour}:${
      minutes > 9 ? minutes : "0" + minutes
    }`;
  }
  const fullDateString = `${dateJs.getDate()} ${strMonth}, ${dateJs.getFullYear()}${time}`;
  return fullDateString;
};
export const parseDateToMMDDYYYY = (timeStamp: number): string => {
  const dateJs = new Date(timeStamp);
  const year = dateJs.getFullYear(),
    month = dateJs.getMonth() + 1,
    day = dateJs.getDate();
  return `${month < 10 ? "0" + month : month}/${
    day < 10 ? "0" + day : day
  }/${year}`;
};
export const getCurrentTimeInMs = () => new Date().getTime();
export const setSecondsInMs = (seconds: number = 60) => seconds * 1000;
export const setMinutesInMs = (minutes: number = 60) => minutes * 60 * 1000;
export const setHoursInMs = (hour: number = 1) => hour * 60 * 60 * 1000;
export const setDaysInMs = (day: number = 1) => day * 24 * 60 * 60 * 1000;
// Moment
export const setMoment = () => {
  return moment;
};
export const setMomentDate = (date?: string | Date | Moment) => {
  return moment(date);
};
export function setTokenExpired() {
  return moment().utcOffset(0).startOf("day").add(7, "d").format();
}
export function checkTokenExpired(date: string) {
  return moment().isSameOrAfter(date);
}
export function setParseUTCtimeToLocale(date?: string | Date | Moment) {
  // @ts-ignore
  return moment(date).utc(moment.parseZone().utcOffset());
}
export function setUTCtime(date?: string | Date | Moment) {
  return moment(date).utc();
}
export function setUTCtimeOffset(date?: string | Date | Moment) {
  let utcTimeOffset: any = moment(date)
    .add(-1 * moment.parseZone().utcOffset(), "m")
    .utc();
  // In Summer we have one hour less ==> moment.parseZone().utcOffset() not get hour 00:00:00.000.Z
  if (new Date(utcTimeOffset).getHours() === 23) {
    utcTimeOffset.add(1, "h");
  }
  return utcTimeOffset;
}
export function setUTCtime00(date?: string | Date | Moment) {
  return (
    moment(date)
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      // @ts-ignore
      .utc(moment.parseZone().utcOffset())
  );
}
export function setUTCtime235959(date?: string | Date | Moment) {
  return (
    moment(date)
      .hour(23)
      .minute(59)
      .second(59)
      .millisecond(999)
      // @ts-ignore
      .utc(moment.parseZone().utcOffset())
  );
}
export function setLocaleTime(utcDate: string | Date | Moment) {
  let offSet = moment.parseZone().utcOffset();
  if (offSet < 0) {
    return setUTCtime00(moment(utcDate).add(2, "d")).format();
  }
  return setUTCtime00(utcDate).format();
}
export function setUTC_Offset(date?: string | Date) {
  let offSet = moment.parseZone().utcOffset();
  if (offSet < 0) {
    offSet *= -1;
  }
  return moment(date).utcOffset(offSet);
}
export function set_Offset(offset: number | string, date?: string | Date) {
  return moment(date).utcOffset(offset);
}
export const onChekValueDate = (
  value: any,
  format: string = "MM/DD/YYYY"
): any => {
  if (
    value &&
    value !== "Undefined" &&
    value.toString().split(/([0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9])/gi)
      .length === 3 &&
    value
      .toString()
      .split(/([0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9])/gi)[2][0] === "T" &&
    setUTC_Offset(value.toString()).format(format) !== "Invalid date"
  ) {
    return setUTC_Offset(value.toString()).format(format);
  }
  return value;
};
export const onSetDateTime = (isoDate: string): string => {
  let diffDateTime = moment().diff(moment(isoDate), "hours");
  if (diffDateTime >= 1) {
    return diffDateTime >= 24
      ? setParseUTCtimeToLocale(isoDate).format("MMM DD [at] HH:mm")
      : `${diffDateTime} h`;
  }
  diffDateTime = moment().diff(moment(isoDate), "minutes");
  if (diffDateTime >= 1) {
    return `${diffDateTime} m`;
  }
  return `${moment().diff(moment(isoDate), "seconds")} s`;
};
// Utils for Array (like lodash)
export const groupBy = (input: any[], key: string) => {
  return [...input].reduce((acc, currentValue) => {
    let groupKey = currentValue[key];
    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }
    acc[groupKey].push(currentValue);
    return acc;
  }, {});
};
export const onParseToOrdinalSuffix = (num: any) => {
  const int = parseInt(num, 10),
    digits = [int % 10, int % 100],
    ordinals = ["st", "nd", "rd", "th"],
    oPattern = [1, 2, 3, 4],
    tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19];
  return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
    ? int + ordinals[digits[0] - 1]
    : int + ordinals[3];
};
export const onSelectedItems =
  (
    selectType: "select-all" | "select-none" | "select-one",
    itemSelected: { [key: string]: any } | undefined,
    isItemSelected: boolean | undefined
  ) =>
  (items: object[] = [], itemsSelected: object[] = []): Array<Object> => {
    let newItemsSelected: object[] = [];
    switch (selectType) {
      case "select-all":
        // Select All Items
        newItemsSelected = [...items];
        break;
      case "select-none":
        // Select none Items
        break;
      default:
        // Add or Remove item selected
        if (isItemSelected) {
          // Remove
          newItemsSelected = itemsSelected.filter(
            ({ _id }: { [key: string]: any }) =>
              itemSelected && itemSelected._id !== _id
          );
        } else if (itemSelected) {
          // Add
          newItemsSelected = [...itemsSelected, itemSelected];
        }
    }
    return newItemsSelected;
  };
export const onSortByStatus = (status: "ascending" | "descending" | "") => {
  switch (status) {
    case "ascending":
      return "descending";
    case "descending":
      return "";
    default:
      return "ascending";
  }
};
export const sortBy = (
  input: any[],
  key: string,
  sort: "asc" | "desc" | undefined = "asc"
) => {
  const compare = (a: string | number, b: string | number) =>
    a < b ? -1 : a > b ? 1 : 0;
  return [...input].sort((left, right) => {
    let a = left[key],
      b = right[key];
    if (isNumeric(a)) {
      a = parseFloat(a);
    }
    if (isNumeric(b)) {
      b = parseFloat(b);
    }
    return sort === "asc" ? compare(b, a) : compare(a, b);
  });
};
export function updateArrayWithElement(
  array: Array<any>,
  element: any
): Array<any> {
  const index: string = array
    .map(({ _id }) => _id)
    .indexOf(element._id)
    .toString();
  return [
    ...array.slice(0, parseInt(index, 10)),
    element,
    ...array.slice(parseInt(index, 10) + 1),
  ];
}
// Utils others
export const capitalizeFirstLetter = (string: string): any => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const capitalizeURLFirstLetter = (url: string): string => {
  return capitalizeFirstLetter(url.slice(1));
};
export function downloadFile(name: string, href: string): void {
  //this trick will generate a temp <a /> tag to download the file
  const link = document.createElement("a");
  link.href = href;
  link.style.display = "none";
  link.download = name;
  if (document.body && link) {
    document.body.appendChild(link);
    link.click();
  }
  if (document.body && link) {
    document.body.removeChild(link);
  }
}
export const hexToRgb = (hex: string): any => {
  const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  window.logger.log("hexToRgb", hex, "to", rgb);
  return rgb && rgb.length > 1
    ? {
        r: parseInt(rgb[1], 16),
        g: parseInt(rgb[2], 16),
        b: parseInt(rgb[3], 16),
      }
    : null;
};
export const isNumeric = (str: string): boolean => {
  if (typeof str !== "string") {
    return false;
  } else {
    return !isNaN(Number(str)) && !isNaN(parseFloat(str));
  }
};
export const lightenDarkenColor = (col: string, amt: number): string => {
  var usePound = false;
  if (col[0] === "#") {
    col = col.slice(1);
    usePound = true;
  }
  var num = parseInt(col, 16);
  var r = (num >> 16) + amt;
  if (r > 255) {
    r = 255;
  } else if (r < 0) {
    r = 0;
  }
  var b = ((num >> 8) & 0x00ff) + amt;
  if (b > 255) {
    b = 255;
  } else if (b < 0) {
    b = 0;
  }
  var g = (num & 0x0000ff) + amt;
  if (g > 255) {
    g = 255;
  } else if (g < 0) {
    g = 0;
  }
  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
};
export const onCheckTooltip = (event: Event) => {
  const eventTarget: any = event.target;
  // $FlowFixMe
  const { firstElementChild } = eventTarget;
  // $FlowFixMe
  eventTarget.title = "";
  // $FlowFixMe
  if (eventTarget.classList.contains("tooltip")) {
    // $FlowFixMe
    eventTarget.classList.remove("tooltip");
  }
  if (firstElementChild) {
    if (firstElementChild.offsetWidth < firstElementChild.scrollWidth) {
      // $FlowFixMe
      eventTarget.title = eventTarget.innerText;
      // $FlowFixMe
      eventTarget.classList.add("tooltip");
      return;
    }
  }
};
export const onGetHexRandomColor = (): string =>
  `#${Math.floor(Math.random() * (0xffffff << 0)).toString(16)}${Math.floor(
    Math.random() * (0xffffff << 0)
  ).toString(16)}`.slice(0, 7);
export const onParseCountToString = (count: number): string => {
  if (count > 999999) {
    return parseFloat((count / 1000000).toFixed(2)) + "M";
  } else if (count > 99999) {
    return parseFloat((count / 1000).toFixed(1)) + "k";
  }
  return count.toString();
};
// Dictionary
export const onSetCategoryWord = (
  disciplineWord: "Discipline" | "discipline" | "Disciplines" | "disciplines"
): string => {
  if (
    onGetTenant() === "uwslr" ||
    onGetTenant() === "uwride" ||
    onGetTenant() === "uwsandbox"
  ) {
    switch (disciplineWord) {
      case "Discipline":
        return "Category";
      case "discipline":
        return "category";
      case "Disciplines":
        return "Categories";
      case "disciplines":
        return "categories";
      default:
    }
  }
  return disciplineWord;
};
