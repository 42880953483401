import { Auth, AccessControl, Files } from "../actions/";
// Auth Reducer
type StateMyInstitution = {
  loaded: boolean;
  data: object | undefined | null;
  hasMyInstitutionCustomFieldsRequiredNull: object | undefined | null;
};
type AuthUser = {
  accountsAvailable: object[];
  [key: string]: any;
};
type State = {
  updatingProfile: boolean;
  fetchingMyFiles: boolean;
  isAuthenticated: boolean;
  token: string;
  expiredToken: object | undefined | null;
  role: object | undefined | null;
  user: AuthUser | undefined | null;
  emulation: {
    isActive: boolean;
    onBehalfUser: string | undefined | null;
  };
  files: object[];
  filesRequired: object[];
  myInstitution: StateMyInstitution | undefined | null;
  hasContactInfoAddressesFieldsRequiredNull: boolean;
  hasEmergencyContactFieldsRequiredNull: boolean;
  hasCustomFieldsRequiredNull: boolean;
};
type Action = {
  type: string;
  payload: Object;
};
const initialStateMyInstitution: StateMyInstitution = {
  loaded: false,
  data: null,
  hasMyInstitutionCustomFieldsRequiredNull: null,
};

const initialState: State = {
  updatingProfile: false,
  fetchingMyFiles: false,
  isAuthenticated: false,
  token: "",
  expiredToken: null,
  role: null,
  user: null,
  emulation: {
    isActive: false,
    onBehalfUser: null,
  },
  files: [],
  filesRequired: [],
  myInstitution: initialStateMyInstitution,
  hasContactInfoAddressesFieldsRequiredNull: false,
  hasEmergencyContactFieldsRequiredNull: false,
  hasCustomFieldsRequiredNull: false,
};

export default function auth(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case Auth.AUTH_SIGNIN_APP:
    case Auth.AUTH_UPDATE_MY_USER_ACCOUNT:
      return Object.assign({}, state, {
        updatingProfile: true,
        fetchingMyFiles: true,
      });
    case Auth.AUTH_UPDATE_MY_USER_ACCOUNT_FAILURE:
      return Object.assign({}, state, {
        updatingProfile: false,
        fetchingMyFiles: false,
      });
    case Auth.AUTH_FETCH_MY_FILES:
      return Object.assign({}, state, {
        fetchingMyFiles: true,
      });
    case Auth.AUTH_FETCH_MY_FILES_FAILURE:
      return Object.assign({}, state, {
        fetchingMyFiles: false,
      });
    case Auth.AUTH_SIGNIN_SUCCESS_APP:
      if (action.payload.role && action.payload.role.type) {
        window.document
          .getElementById("heto-app-website")
          .classList.add(action.payload.role.type);
      }
      return Object.assign({}, state, {
        updatingProfile: false,
        isAuthenticated: true,
        token: action.payload.token,
        expiredToken: action.payload.expiredToken,
        role: action.payload.role,
        user: action.payload.user,
        emulation: action.payload.emulation,
        hasContactInfoAddressesFieldsRequiredNull:
          action.payload.hasContactInfoAddressesFieldsRequiredNull,
        hasEmergencyContactFieldsRequiredNull:
          action.payload.hasEmergencyContactFieldsRequiredNull,
        hasCustomFieldsRequiredNull: action.payload.hasCustomFieldsRequiredNull,
      });
    case Auth.AUTH_UPDATE_MY_USER_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        updatingProfile: false,
        role: action.payload.role,
        user: action.payload.user,
        hasContactInfoAddressesFieldsRequiredNull:
          action.payload.hasContactInfoAddressesFieldsRequiredNull,
        hasEmergencyContactFieldsRequiredNull:
          action.payload.hasEmergencyContactFieldsRequiredNull,
        hasCustomFieldsRequiredNull: action.payload.hasCustomFieldsRequiredNull,
      });
    case Auth.AUTH_FETCH_MY_FILES_SUCCESS:
    case Files.FILES_EDIT_SUCCESS:
    case Files.FILES_DELETE_SUCCESS:
      return Object.assign({}, state, {
        fetchingMyFiles: false,
        files: action.payload.files,
        filesRequired: action.payload.files.filter(
          ({ category }) => category && category.isRequired
        ),
      });
    case Auth.AUTH_FETCH_MY_INSTITUTION_SUCCESS:
      return Object.assign({}, state, {
        myInstitution: {
          loaded: true,
          data: action.payload.institution,
          hasMyInstitutionCustomFieldsRequiredNull:
            action.payload.hasMyInstitutionCustomFieldsRequiredNull,
        },
      });
    case Auth.AUTH_SESSION_EXPIRED_APP:
      return Object.assign({}, state, {
        expiredToken: null,
      });
    case AccessControl.ACCESS_CONTROL_FETCH_ROLES_SUCCESS:
      if (state.isAuthenticated) {
        return Object.assign({}, state, {
          role: action.payload.roles.filter(
            ({ apiName }) => state.role && state.role.apiName === apiName
          )[0],
        });
      }
      return state;
    case AccessControl.ACCESS_CONTROL_UPDATE_ROLE_RESOURCE_SUCCESS:
      if (
        state.isAuthenticated &&
        state.role &&
        state.role.type === action.payload.role.type
      ) {
        return Object.assign({}, state, {
          role: action.payload.role,
        });
      }
      return state;
    case Auth.AUTH_SIGNIN_FAILURE_APP:
    case Auth.AUTH_SIGNOUT_SUCCESS_APP:
    case Auth.AUTH_SIGNOUT_FAILURE_APP:
      return initialState;
    // same state (not change)
    default:
      return state;
  }
}
