// Features
import produce from "immer";
import { CameraDevice } from "../actions";
// Types
import type { Action } from "deox";
type CameraDeviceActionReducers = Action<string>;
export type State = {
  showCameraWarningMsg: boolean;
};
// Camera Device Reducer
const initialState: State = {
  showCameraWarningMsg: false,
};
const cameraDevice: (
  state: State,
  action: CameraDeviceActionReducers
) => State = produce((draft: State, action: CameraDeviceActionReducers) => {
  switch (action.type) {
    case CameraDevice.CAMERA_DEVICE_SHOW_WARNING_MSG:
      draft.showCameraWarningMsg = true;
      break;
    case CameraDevice.CAMERA_DEVICE_HIDE_WARNING_MSG:
      draft.showCameraWarningMsg = false;
      break;
  }
}, initialState);
export default cameraDevice;
