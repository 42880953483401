// Local Storage to save local information into the browser.
export class LocalStore {
  static Save(key: string, value: string | number | object): string {
    try {
      localStorage.setItem(key, JSON.stringify(value));
      return JSON.stringify(value);
    } catch (err) {
      // Ignore error
      return "";
    }
  }
  static Delete(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (err) {
      // Ignore error
    }
  }
  static Read(key: string): any | string | number | object | undefined | null {
    try {
      const value = localStorage.getItem(key) || "null";
      return JSON.parse(value);
    } catch (err) {
      return null;
    }
  }
  static ClearAll() {
    try {
      localStorage.clear();
    } catch (err) {
      // Ignore error
    }
  }
  static numKeys(): number {
    return localStorage.length;
  }
}
