import findIndex from "lodash/findIndex";
import update from "immutability-helper";
import { Users, Files } from "../actions/";
import { USER_MANAGER_USER_LIMIT } from "./usersManager";
// Import Types
import type { Action, PayloadObject } from "../../types/common.types";
import type { StateUsers as State } from "../../types/users.types";
// Users Reducer
// Initial States
const initialState: State = {
  userId: null,
  user: null,
  userFiles: [],
  userFilesExpired: [],
  allUsersSystem: null,
  lastFetchingAllUsersSystem: null,
  totalUsersSystem: null,
};
export default function users(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case Users.USERS_FETCH_USERS_KPIS_SUCCESS:
      let usersObjectAll = {},
        totalUsersSystem = 0;
      const countAllUsers = action.payload.metadata || {};
      for (let userRole in action.payload.users) {
        usersObjectAll = {
          ...usersObjectAll,
          [userRole]: {
            isFetched: true,
            users: [],
            totalUsers:
              countAllUsers && countAllUsers[userRole]
                ? countAllUsers[userRole] || users.length
                : users.length,
            totalPages:
              countAllUsers && countAllUsers[userRole]
                ? 1 +
                  parseInt(
                    (
                      ((countAllUsers[userRole] || users.length) - 1) /
                      USER_MANAGER_USER_LIMIT
                    ).toString(),
                    10
                  )
                : 1 +
                  parseInt(
                    ((users.length - 1) / USER_MANAGER_USER_LIMIT).toString(),
                    10
                  ),
          },
        };
        totalUsersSystem +=
          countAllUsers && countAllUsers[userRole]
            ? countAllUsers[userRole] || users.length
            : users.length;
      }
      let newUsersStateAll = Object.assign({}, state, {
        ...usersObjectAll,
      });
      return {
        ...newUsersStateAll,
        totalUsersSystem: totalUsersSystem,
      };
    case Users.USERS_FETCH_ALL_SUCCESS:
      return Object.assign({}, state, {
        allUsersSystem: action.payload.users,
        lastFetchingAllUsersSystem: new Date(),
      });
    case Users.USERS_FETCH_USERS_ROLES_SUCCESS:
      if (!action.payload.updateStoreUsers) {
        return state;
      }
      let usersObject = {};
      const count = action.payload.metadata || {};
      for (let userRole in action.payload.users) {
        const users =
          action.payload.roles.indexOf(userRole) > -1 ||
          action.payload.roles.length === 0
            ? [...action.payload.users[userRole]]
            : state[userRole]
            ? state[userRole].users
            : [];
        usersObject = {
          ...usersObject,
          [userRole]: {
            isFetched: true,
            users,
            totalUsers:
              count && count[userRole]
                ? count[userRole] || users.length
                : users.length,
            totalPages:
              count && count[userRole]
                ? 1 +
                  parseInt(
                    (
                      ((count[userRole] || users.length) - 1) /
                      USER_MANAGER_USER_LIMIT
                    ).toString(),
                    10
                  )
                : 1 +
                  parseInt(
                    ((users.length - 1) / USER_MANAGER_USER_LIMIT).toString(),
                    10
                  ),
          },
        };
      }
      let newUsersState = Object.assign({}, state, {
        ...usersObject,
      });
      return {
        ...newUsersState,
      };
    case Users.USERS_SET_USERID:
      return Object.assign({}, state, { userId: action.payload.userId });
    case Users.USERS_UNSET_USERID:
      return Object.assign({}, state, {
        userId: null,
        user: null,
        userFiles: [],
      });
    case Users.USERS_FETCH_USER_SUCCESS:
      return Object.assign({}, state, { user: action.payload.user });
    case Users.USERS_EDIT_USER_SUCCESS:
      if (state.user && state.userId) {
        const stateRole = state.user.role;
        let newStateUsers = {
          ...state[stateRole],
        };
        let index = findIndex(
          newStateUsers.users,
          (user: PayloadObject) => user._id === state.userId
        );
        if (index > -1) {
          newStateUsers.users = update(newStateUsers.users, {
            [index]: {
              $set: {
                ...state.user,
                ...action.payload.body,
              },
            },
          });
        }
        return Object.assign({}, state, {
          user: { ...state.user, ...action.payload.body },
          [stateRole]: newStateUsers,
        });
      }
      return state;
    case Users.USERS_FETCH_USER_FILES_SUCCESS:
    case Files.FILES_EDIT_SUCCESS:
    case Files.FILES_DELETE_SUCCESS:
      return Object.assign({}, state, { userFiles: action.payload.files });
    case Users.USERS_FETCH_USER_FILES_EXPIRED_SUCCESS:
      return Object.assign({}, state, {
        userFilesExpired: action.payload.filesExpired,
      });
    // initial state
    // return initialState;
    // same state (not change)
    default:
      return state;
  }
}
