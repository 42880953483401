// @flow

/* PERMISSIONS */
const onCheckResource = (apiResource: Object, resources: Array<string>) => {
  for (let resource in apiResource) {
    if (
      resources.indexOf(resource) !== -1 &&
      apiResource[resource]["isEnabled"]
    ) {
      return true;
    }
  }
  return false;
};

export const onCheckUsersPermissions = (permissions: ?Object): boolean => {
  const resources = [
    "SuperCompanyAdministrators#Profiles:read",
    "SuperCompanyAdministrators#Profiles:update",
    "CompanyAdministrators#Profiles:read",
    "CompanyAdministrators#Profiles:update",
    "Coordinators#Profiles:read",
    "Coordinators#Profiles:update",
    "Faculties#Profiles:read",
    "Faculties#Profiles:update",
    "Students#Profiles:read",
    "Students#Profiles:update",
    "InstitutionAdministrators#Profiles:read",
    "InstitutionAdministrators#Profiles:update",
    "Preceptors#Profiles:read",
    "Preceptors#Profiles:update",
    "my:SuperCompanyAdministrators#Profiles:read",
    "my:SuperCompanyAdministrators#Profiles:update",
    "my:CompanyAdministrators#Profiles:read",
    "my:CompanyAdministrators#Profiles:update",
    "my:Coordinators#Profiles:read",
    "my:Coordinators#Profiles:update",
    "my:Faculties#Profiles:read",
    "my:Faculties#Profiles:update",
    "my:Students#Profiles:read",
    "my:Students#Profiles:update",
    "my:InstitutionAdministrators#Profiles:read",
    "my:InstitutionAdministrators#Profiles:update",
    "my:Preceptors#Profiles:read",
    "my:Preceptors#Profiles:update",
    "my#Profiles:read",
    "my#Profiles:update",
  ];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
export const onCheckReadUsersRoleProfile = (
  { type: roleType }: { type: string },
  permissions: ?Object
) => {
  switch (roleType) {
    case "myprofile":
      return onCheckReadMyProfileUsersPermissions(permissions);
    case "supercompanyadministrators":
      return onCheckReadAllSuperCompanyAdministratorsUsersPermissions(
        permissions
      );
    case "companyadministrators":
      return onCheckReadAllCompanyAdministratorsUsersPermissions(permissions);
    case "coordinators":
      return onCheckReadAllCoordinatorsUsersPermissions(permissions);
    case "faculties":
      return onCheckReadAllFacultiesUsersPermissions(permissions);
    case "institutionadministrators":
      return onCheckReadAllInstitutionAdministratorsUsersPermissions(
        permissions
      );
    case "preceptors":
      return onCheckReadAllPreceptorsUsersPermissions(permissions);
    case "students":
      return (
        onCheckReadAllStudentsUsersPermissions(permissions) ||
        onCheckReadBasicStudentsUsersPermissions(permissions)
      );
    default:
  }
  return false;
};
export const onCheckEditUsersRoleProfile = (
  { type: roleType }: { type: string },
  permissions: ?Object
) => {
  switch (roleType) {
    case "myprofile":
      return onCheckEditMyProfileUsersPermissions(permissions);
    case "supercompanyadministrators":
      return onCheckEditAllSuperCompanyAdministratorsUsersPermissions(
        permissions
      );
    case "companyadministrators":
      return onCheckEditAllCompanyAdministratorsUsersPermissions(permissions);
    case "coordinators":
      return onCheckEditAllCoordinatorsUsersPermissions(permissions);
    case "faculties":
      return onCheckEditAllFacultiesUsersPermissions(permissions);
    case "institutionadministrators":
      return onCheckEditAllInstitutionAdministratorsUsersPermissions(
        permissions
      );
    case "preceptors":
      return onCheckEditAllPreceptorsUsersPermissions(permissions);
    case "students":
      return onCheckEditAllStudentsUsersPermissions(permissions);
    default:
  }
  return false;
};
// USERS SUPER COMPANY ADMINS
export const onCheckReadAllSuperCompanyAdministratorsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "SuperCompanyAdministrators#Profiles:read",
    "my:SuperCompanyAdministrators#Profiles:read",
  ];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
export const onCheckReadSuperCompanyAdministratorsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["SuperCompanyAdministrators#Profiles:read"];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
export const onCheckReadMySuperCompanyAdministratorsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:SuperCompanyAdministrators#Profiles:read"];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
export const onCheckEditAllSuperCompanyAdministratorsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "SuperCompanyAdministrators#Profiles:update",
    "my:SuperCompanyAdministrators#Profiles:update",
  ];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
// />
// USERS COMPANY ADMINS
export const onCheckReadAllCompanyAdministratorsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "CompanyAdministrators#Profiles:read",
    "my:CompanyAdministrators#Profiles:read",
  ];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
export const onCheckReadCompanyAdministratorsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["CompanyAdministrators#Profiles:read"];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
export const onCheckReadMyCompanyAdministratorsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:CompanyAdministrators#Profiles:read"];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
export const onCheckEditAllCompanyAdministratorsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "CompanyAdministrators#Profiles:update",
    "my:CompanyAdministrators#Profiles:update",
  ];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
// />
// USERS COORDINATORS
export const onCheckReadAllCoordinatorsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "Coordinators#Profiles:read",
    "my:Coordinators#Profiles:read",
  ];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
export const onCheckReadCoordinatorsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Coordinators#Profiles:read"];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
export const onCheckReadMyCoordinatorsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:Coordinators#Profiles:read"];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
export const onCheckEditAllCoordinatorsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "Coordinators#Profiles:update",
    "my:Coordinators#Profiles:update",
  ];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
// />
// USERS FACULTIES
export const onCheckReadAllFacultiesUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Faculties#Profiles:read", "my:Faculties#Profiles:read"];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
export const onCheckReadFacultiesUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Faculties#Profiles:read"];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
export const onCheckReadMyFacultiesUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:Faculties#Profiles:read"];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
export const onCheckEditAllFacultiesUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "Faculties#Profiles:update",
    "my:Faculties#Profiles:update",
  ];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
// />
// USERS STUDENTS
export const onCheckReadAllStudentsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Students#Profiles:read", "my:Students#Profiles:read"];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
export const onCheckReadBasicStudentsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:Students#ProfilesBasic:read"];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
export const onCheckReadStudentsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Students#Profiles:read"];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
export const onCheckReadMyStudentsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:Students#Profiles:read"];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
export const onCheckEditAllStudentsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Students#Profiles:update", "my:Students#Profiles:update"];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
// --> STUDENTS ENROLLMENTS
export const onCheckReadEnrollmentStudentsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Enrollments:read"];
  const { Enrollments } = permissions || {};
  if (Enrollments && Enrollments.apiValue) {
    return onCheckResource(Enrollments.apiValue, resources);
  }
  return false;
};
export const onCheckCreateEnrollmentStudentsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Enrollments:create"];
  const { Enrollments } = permissions || {};
  if (Enrollments && Enrollments.apiValue) {
    return onCheckResource(Enrollments.apiValue, resources);
  }
  return false;
};
export const onCheckEditEnrollmentStudentsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Enrollments:update"];
  const { Enrollments } = permissions || {};
  if (Enrollments && Enrollments.apiValue) {
    return onCheckResource(Enrollments.apiValue, resources);
  }
  return false;
};
export const onCheckDeleteEnrollmentStudentsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Enrollments:delete"];
  const { Enrollments } = permissions || {};
  if (Enrollments && Enrollments.apiValue) {
    return onCheckResource(Enrollments.apiValue, resources);
  }
  return false;
};
// />
// USERS INSTITUTIONS ADMINS
export const onCheckReadAllInstitutionAdministratorsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "InstitutionAdministrators#Profiles:read",
    "my:InstitutionAdministrators#Profiles:read",
  ];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
export const onCheckReadInstitutionAdministratorsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["InstitutionAdministrators#Profiles:read"];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
export const onCheckReadMyInstitutionAdministratorsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:InstitutionAdministrators#Profiles:read"];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
export const onCheckEditAllInstitutionAdministratorsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "InstitutionAdministrators#Profiles:update",
    "my:InstitutionAdministrators#Profiles:update",
  ];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
// />
// USERS PRECEPTORS
export const onCheckReadAllPreceptorsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Preceptors#Profiles:read", "my:Preceptors#Profiles:read"];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
export const onCheckReadBasicPreceptorsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:Preceptors#ProfilesBasic:read"];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
export const onCheckReadPreceptorsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["Preceptors#Profiles:read"];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
export const onCheckReadMyPreceptorsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my:Preceptors#Profiles:read"];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
export const onCheckEditAllPreceptorsUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = [
    "Preceptors#Profiles:update",
    "my:Preceptors#Profiles:update",
  ];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
// />
// MY PROFILE
export const onCheckReadMyProfileUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my#Profiles:read"];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
export const onCheckEditMyProfileUsersPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my#Profiles:update"];
  const { Users } = permissions || {};
  if (Users && Users.apiValue) {
    return onCheckResource(Users.apiValue, resources);
  }
  return false;
};
// --> STUDENTS ENROLLMENTS
export const onCheckReadMyProfileUsersEnrollmentsPermissions = (
  permissions: ?Object
): boolean => {
  const resources = ["my#Enrollments:read", "my:Users#Enrollments:read"];
  const { Enrollments } = permissions || {};
  if (Enrollments && Enrollments.apiValue) {
    return onCheckResource(Enrollments.apiValue, resources);
  }
  return false;
};
// />
