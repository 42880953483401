// Features
import * as React from "react";
import classNames from "classnames";
import sortBy from "lodash/sortBy";
import { parseApiName, parseName, setUTCtimeOffset } from "../../../utils/";
// Components
import {
  Button,
  Container,
  Divider,
  Dropdown,
  Form,
  Grid,
  Icon,
  TextArea,
} from "semantic-ui-react";
//@ts-ignore
import { DateTimePicker } from "react-widgets";
import BroadcastSchedulers from "../broadcast-schedulers/BroadcastSchedulers";
// Types
import type {
  PropsPresentational,
  State,
} from "./BroadcastSentMessageContainer";
type Props = {
  onChangeDropdown: (
    event: Event,
    {
      name: "cohorts" | "institutions" | "programs" | "roles" | "users",
      value: Array<string>,
    }
  ) => void,
  onChangeInputField: (event: Object, { name: string, value: string }) => void,
  onSaveBroadcastMessage: () => void,
} & PropsPresentational &
  State;
// Broadcast Sent Messages & Scheduler
class BroadcastSentMessagePresentational extends React.Component<Props> {
  render() {
    return this.renderCreateBroadcastMessage();
  }
  renderCreateBroadcastMessage = () => {
    return (
      <>
        <Form className="send-broadcast-message">
          {this.renderButtonLink()}
          {this.renderRecipients()}
          {this.renderExpirationDate()}
          {this.renderMessage()}
          {this.renderActions()}
        </Form>
        <BroadcastSchedulers {...this.props} />
      </>
    );
  };
  renderButtonLink = () => (
    <div
      className="btn skejule-blue mt5 link"
      onClick={this.props.goToBroadcastSettings}
      style={{ cursor: "pointer" }}
    >
      <span>See all your broadcast messages</span>
    </div>
  );
  renderRecipients = () => {
    const {
      broadcastMessage,
      cohorts,
      institutions,
      onChangeDropdown,
      programs,
      roles,
    } = this.props;
    const {
      cohorts: cohortsSelected = [],
      institutions: institutionsSelected = [],
      programs: programsSelected = [],
      roles: rolesSelected = [],
    } = broadcastMessage;
    const canSelectProgramsAndCohorts =
      rolesSelected.indexOf("Students") !== -1;
    const canSelectInstitutions =
      rolesSelected.filter(
        (role) =>
          ["Students", "InstitutionAdministrators", "Preceptors"].indexOf(
            role
          ) !== -1
      ).length > 0;
    let allInstitutions = [];
    if (canSelectInstitutions) {
      allInstitutions = sortBy(
        institutions.filter(({ status }) => status === "active"),
        ["name"]
      );
    }
    return (
      <div className="recipient">
        <Container className="mt10 mb10">
          <label className="fullwidth skejule-teal mb10">Send to</label>
          <div>
            <Dropdown
              className="skejule-teal"
              name="roles"
              placeholder="Select Role(s)"
              selectOnBlur={false}
              multiple
              search
              selection
              fluid
              noResultsMessage={
                roles.length === rolesSelected.length
                  ? "Not more roles."
                  : "No results found."
              }
              options={roles.map(({ apiName, name }) => ({
                key: apiName,
                text: name,
                value: apiName,
              }))}
              value={rolesSelected}
              onChange={onChangeDropdown}
              renderLabel={(item, index, defaultLabelProps) => ({
                ...defaultLabelProps,
                content: item.text,
              })}
            />
          </div>
          {/*{this.renderFilterUsersRoles()}*/}
          {canSelectProgramsAndCohorts && (
            <Grid columns={2}>
              <Grid.Column>
                <Dropdown
                  className="skejule-teal"
                  name="programs"
                  placeholder="Select Program(s)"
                  selectOnBlur={false}
                  multiple
                  search
                  selection
                  fluid
                  noResultsMessage={
                    programs.length === programsSelected.length
                      ? "Not more programs."
                      : "No results found."
                  }
                  options={programs.map(({ _id, name }) => ({
                    key: _id,
                    text: name,
                    value: _id,
                  }))}
                  value={programsSelected}
                  onChange={onChangeDropdown}
                  renderLabel={(item, index, defaultLabelProps) => ({
                    ...defaultLabelProps,
                    content: item.text,
                  })}
                />
              </Grid.Column>
              <Grid.Column>
                <Dropdown
                  className="skejule-teal"
                  name="cohorts"
                  placeholder="Select Cohort(s)"
                  selectOnBlur={false}
                  multiple
                  search
                  selection
                  fluid
                  noResultsMessage={
                    cohorts.length === cohortsSelected.length
                      ? "Not more cohorts."
                      : "No results found."
                  }
                  options={cohorts.map(({ _id, name }) => ({
                    key: _id,
                    text: name,
                    value: _id,
                  }))}
                  value={cohortsSelected}
                  onChange={onChangeDropdown}
                  renderLabel={(item, index, defaultLabelProps) => ({
                    ...defaultLabelProps,
                    content: item.text,
                  })}
                />
              </Grid.Column>
            </Grid>
          )}
          {canSelectInstitutions && (
            <div>
              <Dropdown
                className="skejule-teal"
                name="institutions"
                placeholder="Select Clinic(s)"
                selectOnBlur={false}
                multiple
                search
                selection
                fluid
                noResultsMessage={
                  allInstitutions.length === institutionsSelected.length
                    ? "Not more clinics."
                    : "No results found."
                }
                options={allInstitutions.map(({ _id, name }) => ({
                  key: _id,
                  text: name,
                  value: _id,
                }))}
                value={institutionsSelected}
                onChange={onChangeDropdown}
                renderLabel={(item, index, defaultLabelProps) => ({
                  ...defaultLabelProps,
                  content: item.text,
                })}
              />
            </div>
          )}
        </Container>
      </div>
    );
  };
  renderFilterUsersRoles = () => {
    const {
      broadcastMessage: { users: usersSelected },
      onChangeDropdown,
      usersRoles,
    } = this.props;
    let usersRolesOptions = [];
    if (usersRoles) {
      Object.keys(usersRoles).forEach((role) => {
        if (usersRoles[role] && usersRoles[role].length > 0) {
          const section = {
            key: role,
            text: (
              <Divider horizontal className="skejule-divider skejule-teal">
                {parseApiName(parseName(role))} ({usersRoles[role].length})
              </Divider>
            ),
            disabled: true,
            style: { opacity: 1 },
            className: "lemming",
          };
          const usersOptions = usersRoles[role].map(
            ({ _id, firstName, lastName }, index) => ({
              key: _id,
              text: `${firstName} ${lastName}`,
              value: _id,
            })
          );
          usersRolesOptions = [...usersRolesOptions, section, ...usersOptions];
        }
        return role;
      });
    }
    return (
      <div>
        <Dropdown
          className="skejule-teal users-roles"
          name="users"
          placeholder={"Select Users..."}
          selectOnBlur={false}
          multiple
          search
          selection
          fluid
          options={usersRolesOptions}
          onChange={onChangeDropdown}
          renderLabel={(item, index, defaultLabelProps) => ({
            ...defaultLabelProps,
            content: item.text,
          })}
          value={usersSelected}
        />
      </div>
    );
  };
  renderExpirationDate = () => {
    const {
      broadcastMessage: { expirationDate },
      onChangeInputField,
    } = this.props;
    return (
      <div className="expiration-date">
        <Container className="mt10 mb10 gray">
          <label className="fullwidth skejule-teal mb10">Expiration Date</label>
          <Grid columns={2}>
            <Grid.Column>
              <DateTimePicker
                className=""
                min={new Date()}
                onChange={(dateSelected: Date, strDate: string) =>
                  onChangeInputField(new Event("Empty"), {
                    name: "expirationDate",
                    value: strDate,
                  })
                }
                placeholder="MM/DD/YY"
                time={false}
                value={
                  (expirationDate || null) &&
                  new Date(setUTCtimeOffset(expirationDate).format())
                }
              />
            </Grid.Column>
            <Grid.Column>
              {expirationDate && (
                <Icon
                  link
                  name="delete"
                  onClick={() =>
                    onChangeInputField(new Event("Empty"), {
                      name: "expirationDate",
                      value: "",
                    })
                  }
                  data-clear="is-clear-expiration-date"
                  style={{
                    cursor: "pointer",
                    position: "relative",
                    top: "9px",
                    right: "105px",
                  }}
                />
              )}
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  };
  renderMessage = () => {
    const {
      broadcastMessage: { message },
      onChangeInputField,
    } = this.props;
    return (
      <div className="message">
        <Container className="mt10 mb10 gray">
          <label className="fullwidth mb10 skejule-teal">Message</label>
          <div>
            <TextArea
              className="fluid skejule-teal"
              name="message"
              onChange={onChangeInputField}
              placeholder="Enter message ..."
              rows="6"
              value={message || ""}
            />
          </div>
        </Container>
      </div>
    );
  };
  renderActions = () => {
    const {
      broadcastMessage: { _id, message, roles: rolesSelected = [] },
      errorSuccessBroadcastMessage,
      isCreatingBroadcastMessage,
      onLoadBroadCastMessage,
      onSaveBroadcastMessage,
    } = this.props;
    return (
      <div className="actions">
        <div
          className={classNames("message text-left ml10 mb5", {
            info:
              errorSuccessBroadcastMessage &&
              errorSuccessBroadcastMessage.successApi,
            negative:
              errorSuccessBroadcastMessage &&
              errorSuccessBroadcastMessage.errorApi,
          })}
          style={
            errorSuccessBroadcastMessage
              ? {}
              : { cursor: "default", opacity: 0 }
          }
        >
          {!errorSuccessBroadcastMessage
            ? "Message Here"
            : errorSuccessBroadcastMessage.successApi ||
              errorSuccessBroadcastMessage.errorApi}
        </div>
        <Button
          className="skejule large mt10 mb0"
          disabled={
            !message || rolesSelected.length === 0 || isCreatingBroadcastMessage
          }
          loading={isCreatingBroadcastMessage}
          onClick={() => onLoadBroadCastMessage()}
        >
          <span className="">Cancel</span>
        </Button>
        <Button
          className="skejule large filled right mt10 mb0"
          disabled={
            !message || rolesSelected.length === 0 || isCreatingBroadcastMessage
          }
          loading={isCreatingBroadcastMessage}
          onClick={onSaveBroadcastMessage}
        >
          <span className="">{_id ? "Save" : "Send"}</span>
        </Button>
      </div>
    );
  };
}

export default BroadcastSentMessagePresentational;
