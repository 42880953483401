// @flow
// Import Features
import * as React from "react";
import { connect } from "react-redux";
// Import Components
import FilesRequiredWarningPresentational from "./FilesRequiredWarningPresentational";
// Import Styles
import "./FilesRequiredWarning.scss";
// Types
type Props = {
  showWarning: boolean,
};
type State = {
  hideMessage: boolean,
};
class FilesRequiredWarningContainer extends React.Component<Props, State> {
  state = {
    hideMessage: false,
  };
  onDismissMessage = () => this.setState({ hideMessage: true });
  render() {
    const { showWarning } = this.props;
    const { hideMessage } = this.state;
    if (!showWarning || hideMessage) {
      return null;
    }
    return (
      <FilesRequiredWarningPresentational
        onDismissMessage={this.onDismissMessage}
      />
    );
  }
}

export default connect(
  ({
    auth: { fetchingMyFiles, filesRequired = [], isAuthenticated, role },
    fileCategories: { fileCategories },
  }) => ({
    showWarning:
      isAuthenticated &&
      role &&
      role.apiName &&
      !fetchingMyFiles &&
      fileCategories.filter(
        ({ _id: fileCategoryId, isRequired, visibleForRoles }) =>
          isRequired &&
          visibleForRoles.indexOf(role.apiName) !== -1 &&
          filesRequired
            .map(({ category }) => category._id)
            .indexOf(fileCategoryId) === -1
      ).length > 0,
  }),
  null
)(FilesRequiredWarningContainer);
