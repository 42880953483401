// @flow
import * as React from "react";
import classNames from "classnames";
import { Button, Grid } from "semantic-ui-react";
import type { Props, State } from "./EmulateUserContainer";
class EmulateUserPresentational extends React.Component<
  Props & {
    onStopEmulation: () => void,
  } & State
> {
  // RENDERS:
  render() {
    const { onStopEmulation, stoppingEmulation, userName } = this.props;
    return (
      <Grid className="heto-emulate-user-widget">
        <Grid.Column className="emulate-panel" textAlign="center">
          <span className="">Currently emulating</span>
          <span className="skejule-blue bold ml15">{userName}</span>
          <Button
            basic
            className={classNames("stop-emulation mr5", {
              tooltip: !stoppingEmulation,
            })}
            disabled={stoppingEmulation}
            floated="right"
            icon="close"
            loading={stoppingEmulation}
            onClick={onStopEmulation}
            title="Stop Emulation"
          />
        </Grid.Column>
      </Grid>
    );
  }
}

export default EmulateUserPresentational;
