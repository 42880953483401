import type { Config, tAPI } from "./API";
// API Periods static class

const apiBasePeriods = "/periods";
export default class Periods {
  // Get a list of periods
  static GetPeriods(api: tAPI, config?: Config) {
    return api.GET(`${apiBasePeriods}`, config);
  }
  // Create a Period
  static CreatePeriod(api: tAPI, data: object, config?: Config) {
    return api.POST(`${apiBasePeriods}`, data, config);
  }
  // Edit a Period
  static EditPeriod(
    api: tAPI,
    periodId: string,
    data: object,
    config?: Config
  ) {
    return api.PATCH(`${apiBasePeriods}/${periodId}`, data, config);
  }
  // Delete a Period
  static DeletePeriod(api: tAPI, periodId: string, config?: Config) {
    return api.DELETE(`${apiBasePeriods}/${periodId}`, config);
  }
}
