// @flow
import * as React from "react";
import { Modal } from "semantic-ui-react";
// Component generic for some error info.
const ErrorComponent = ({
  className,
  textError,
}: React.PropsWithChildren<{
  className: string;
  textError: string;
}>) => (
  <div id="main" className={className}>
    <Modal open={true} basic dimmer="blurring" size="small">
      <Modal.Content>
        <h3 style={{ textAlign: "center" }}>{textError}</h3>
      </Modal.Content>
    </Modal>
  </div>
);
export default ErrorComponent;
