// @flow
import * as React from 'react';
import { Modal } from 'semantic-ui-react';
import UserManagerFilters from '../../user-manager/UserManagerFilters';

type Props = {
  isModalOpen: boolean,
  onOpenClose: Function
};
const SubMenu = ({ isModalOpen, onOpenClose }: Props): React$Element<any> => (
  <div className="search">
    <Modal
      open={isModalOpen}
      trigger={
        <i
          className={`icono icono-${
            isModalOpen ? 'close' : 'search'
          } big white`}
          onClick={onOpenClose}
        />
      }
      className="seriously-fullscreen filters"
      dimmer={false}
      size={'fullscreen'}
      closeIcon
    >
      <Modal.Content>
        <UserManagerFilters fromModal={true} onOpenClose={onOpenClose} />
      </Modal.Content>
    </Modal>
  </div>
);

export default SubMenu;
