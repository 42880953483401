import { put, select, call, all, takeLatest } from "redux-saga/effects";
import NotificationsApi from "../api/Notifications";
import PortfolioApi from "../api/Portfolio";
import { Auth, UsersPortfolio } from "../actions/";
// Type
import type { Action } from "deox";
import type { tAPI } from "../api/API";
//Sagas for User Portfolio
function* doUsersPortfolioFetchPortfolio(
  action: Action<
    string,
    {
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(UsersPortfolio.portfolioFetchPortfolioRequest());
  window.logger.log("doUsersPortfolioFetchPortfolio", action);
  const { callbackSuccess = null, callbackError = null } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    const { userId } = yield select(({ auth: { user } }) => ({
      userId: user._id,
    }));
    const config: { [k: string]: any } = yield select(({ config }) => config);
    // call the api to get my portfolio info
    const {
      data: { portfolio },
    } = yield call(PortfolioApi.GetPorfolio, api, userId, { token });
    const {
      _id: portfolioId,
      owner,
      workExperience = [],
      caseLogsKPI,
      institutionsWorked = [],
      educationalBackground = [],
      publications = [],
      testimonials = [],
      volunteers = [],
      skills = [],
    } = portfolio;
    const { companyDomain, baseDomain, port } = config;
    const publicUrl: string = `${baseDomain}${
      port ? ":3000" : ""
    }/public/${companyDomain}/portfolios/${portfolioId}`;
    yield put(
      UsersPortfolio.portfolioFetchPortfolioSuccess({
        newState: {
          publicUrl,
          workExperience,
          caseLogsKPI,
          institutionsWorked,
          educationalBackground,
          publications,
          testimonials,
          volunteers,
          skills,
        },
      })
    );
    if (callbackSuccess) {
      callbackSuccess(owner);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doUsersPortfolioFetchPortfolio",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(UsersPortfolio.portfolioFetchPortfolioFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}

function* doUsersPortfolioEditPortfolio(
  action: Action<
    string,
    {
      body: object;
      fieldName: string;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(UsersPortfolio.portfolioEditRequest());
  window.logger.log("doUsersPortfolioEditPortfolio", action);
  const {
    body,
    fieldName,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    const { userId } = yield select(({ auth: { user } }) => ({
      userId: user._id,
    }));
    // call the api to edit my portfolio
    const {
      data: { portfolio },
    } = yield call(PortfolioApi.EditPortfolio, api, userId, body, { token });
    yield put(
      UsersPortfolio.portfolioEditSuccess({
        newState: {
          [fieldName]: portfolio[fieldName],
        },
      })
    );
    if (callbackSuccess) {
      callbackSuccess(portfolio[fieldName]);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doUsersPortfolioEditPortfolio",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(UsersPortfolio.portfolioEditFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doUsersPortfolioRequestPortfolioTestimonials(
  action: Action<
    string,
    {
      body: object;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(UsersPortfolio.portfolioTestimonialsRequest());
  window.logger.log("doUsersPortfolioRequestPortfolioTestimonials", action);
  const { body, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    const { userId } = yield select(({ auth: { user } }) => ({
      userId: user._id,
    }));
    // call the api to edit my portfolio
    const {
      data: { portfolio },
    } = yield call(
      PortfolioApi.RequestPortfolioTestimonials,
      api,
      userId,
      body,
      { token }
    );
    yield put(
      UsersPortfolio.portfolioTestimonialsSuccess({
        newState: {
          testimonials: portfolio.testimonials,
        },
      })
    );
    if (callbackSuccess) {
      callbackSuccess(portfolio);
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doUsersPortfolioRequestPortfolioTestimonials",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(UsersPortfolio.portfolioTestimonialsFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doUsersPortfolioRequestPortfolioTestimonialsNotifications(
  action: Action<
    string,
    {
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(UsersPortfolio.portfolioTestimonialsNotificationsRequest());
  window.logger.log(
    "doUsersPortfolioRequestPortfolioTestimonialsNotifications",
    action
  );
  const { callbackSuccess = null, callbackError = null } = action.payload || {};
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    const { userId } = yield select(({ auth: { user } }) => ({
      userId: user._id,
    }));
    // call the api to get all notifications for this company
    const { data } = yield call(
      NotificationsApi.GetNotifications,
      api,
      userId,
      "testimonial-request",
      [],
      0,
      0,
      { token }
    );
    const { notifications, total, unseen } = data;
    yield put(UsersPortfolio.portfolioTestimonialsNotificationsSuccess());
    if (callbackSuccess) {
      callbackSuccess({
        notifications,
        totalNotifications: total,
        unseenNotifications: unseen,
      });
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doUsersPortfolioRequestPortfolioTestimonialsNotifications",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(UsersPortfolio.portfolioTestimonialsNotificationsFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}

function* doUsersPortfolioSubmitPortfolioTestimonials(
  action: Action<
    string,
    {
      userId: string;
      institutionId: string;
      body: object;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(UsersPortfolio.portfolioSubmitTestimonialsRequest());
  window.logger.log("doUsersPortfolioSubmitPortfolioTestimonials", action);
  const {
    userId,
    institutionId,
    body,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to edit my portfolio
    yield call(
      PortfolioApi.SubmitPortfolioTestimonials,
      api,
      userId,
      institutionId,
      body,
      { token }
    );
    yield put(UsersPortfolio.portfolioSubmitTestimonialsSuccess());
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doUsersPortfolioSubmitPortfolioTestimonials",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(UsersPortfolio.portfolioSubmitTestimonialsFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doUsersPortfolioDeletePortfolioTestimonials(
  action: Action<
    string,
    {
      institutionId: string;
      body: object;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(UsersPortfolio.portfolioDeleteTestimonialsRequest());
  window.logger.log("doUsersPortfolioDeletePortfolioTestimonials", action);
  const {
    institutionId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    const { userId } = yield select(({ auth: { user } }) => ({
      userId: user._id,
    }));
    // call the api to delete portfolio testimonial from some specific institutionId
    yield call(
      PortfolioApi.DeletePortfolioTestimonials,
      api,
      userId,
      institutionId,
      { token }
    );
    yield put(
      UsersPortfolio.portfolioDeleteTestimonialsSuccess({
        institutionId,
      })
    );
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doUsersPortfolioDeletePortfolioTestimonials",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(UsersPortfolio.portfolioDeleteTestimonialsFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

export default function* usersPortfolioSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(
      UsersPortfolio.PORTFOLIO_FETCH_PORTFOLIO,
      doUsersPortfolioFetchPortfolio
    ),
    takeLatest(UsersPortfolio.PORTFOLIO_EDIT, doUsersPortfolioEditPortfolio),
    takeLatest(
      UsersPortfolio.PORTFOLIO_TESTIMONIALS,
      doUsersPortfolioRequestPortfolioTestimonials
    ),
    takeLatest(
      UsersPortfolio.PORTFOLIO_TESTIMONIALS_NOTIFICATIONS,
      doUsersPortfolioRequestPortfolioTestimonialsNotifications
    ),
    takeLatest(
      UsersPortfolio.PORTFOLIO_SUBMIT_TESTIMONIALS,
      doUsersPortfolioSubmitPortfolioTestimonials
    ),
    takeLatest(
      UsersPortfolio.PORTFOLIO_DELETE_TESTIMONIALS,
      doUsersPortfolioDeletePortfolioTestimonials
    ),
  ]);
}
