// Features
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { Notifications } from "../../../store/actions";
import { onCreateNotificationsCategories } from "../utils/_Notifications.utils";
// Components
import { Button, Icon } from "semantic-ui-react";
// Types
import type { RootState } from "../../../store/reducers";
import type {
  NotificationsCategories,
  State as StateNotifications,
} from "../../../store/reducers/notifications";
import type {
  ErrorType,
  PayloadObject,
  UndefinedNull,
} from "../../../types/common.types";
// Notifications Filters (Categories):
const { useState } = React;
//
const NotificationsFilters = ({
  authPermissions,
}: React.PropsWithChildren<{
  authPermissions: PayloadObject;
}>) => {
  const dispatch = useDispatch();
  // STORE NOTIFICATIONS
  const notificationsCategories: StateNotifications["notificationsCategories"] =
    useSelector(
      ({ notifications }: RootState) => notifications.notificationsCategories
    );
  // Constants
  const NOTIFICATIONS_CATEGORIES =
    onCreateNotificationsCategories(authPermissions);
  // State
  const [timerId, setTimerId] = useState<NodeJS.Timeout | UndefinedNull>(
    undefined
  );
  // GET Notifications by Categories
  const _onFilterNotificationsByCategoriesRequest = () => {
    return new Promise((resolve, reject) => {
      const formValues = {
        notificationsCategories,
        notificationsFiltersByCategories: true,
      };
      const callbacks = {
        cbe: (error: ErrorType) => {
          window.logger.log(
            "_onFilterNotificationsByCategoriesRequest - failed"
          );
          reject(error);
        },
        cbs: () => {
          window.logger.log(
            "_onFilterNotificationsByCategoriesRequest - success"
          );
          resolve("_onFilterNotificationsByCategoriesRequest - success");
        },
      };
      const payload = Object.assign({}, formValues, callbacks);
      dispatch(Notifications.notificationsFetchNotifications(payload));
    });
  };
  const onFilterNotificationsByCategories = async () => {
    await _onFilterNotificationsByCategoriesRequest()
      .then((successMsg) => {
        window.logger.log(
          "onFilterNotificationsByCategories then ",
          successMsg
        );
      })
      .catch((error: any) =>
        window.logger.log("onFilterNotificationsByCategories - error", error)
      );
    setTimerId(undefined);
  };
  // Set Notifications By Categories and call to API
  const onSetNotificationsByCategories = (
    notificationsCategory?: NotificationsCategories
  ) => {
    if (notificationsCategory) {
      dispatch(
        Notifications.notificationsSetNotificationsCategories({
          notificationsCategory,
        })
      );
    } else {
      dispatch(Notifications.notificationsClearNotificationsCategories());
    }
    if (timerId) {
      clearTimeout(timerId);
    }
    setTimerId(setTimeout(() => onFilterNotificationsByCategories(), 1000));
  };
  // RENDERS
  return (
    <>
      <Button.Group className="notifications-filters pt20 pl2 pr2 pb5">
        {NOTIFICATIONS_CATEGORIES.map(({ _id, name, icon }, index) => (
          <Button
            className={classNames("skejule", {
              "active filled": notificationsCategories.indexOf(_id) !== -1,
              mr5: index < NOTIFICATIONS_CATEGORIES.length - 1,
            })}
            key={`${_id}_${index}`}
            onClick={() => onSetNotificationsByCategories(_id)}
            size="mini"
          >
            <Icon name={icon} />
            {name}
            <Icon
              circular
              className="relative right skejule-teal"
              name="delete"
              size="tiny"
            />
          </Button>
        ))}
      </Button.Group>
      <div
        className={classNames(
          "btn skejule-teal mt10 mr5",
          {
            link: notificationsCategories.length > 0,
          },
          "right"
        )}
        onClick={
          notificationsCategories.length > 0
            ? () => onSetNotificationsByCategories()
            : undefined
        }
        style={{
          opacity: notificationsCategories.length > 0 ? 1 : 0.45,
        }}
      >
        <span>Clear</span>
      </div>
    </>
  );
};
export default NotificationsFilters;
