import { EvaluationsTemplates } from "../actions/";
// Types
import type { PayloadObject, UndefinedNull } from "../../types/common.types";

// Evaluations Templates Reducer
type StateOptionHeader = {
  id: string;
  type: "header";
  header: string | UndefinedNull;
};
type StateSingleMultipleChoice = {
  id: string;
  header: string | UndefinedNull;
  content: string | UndefinedNull;
  notify: boolean;
  commentsRequired: boolean;
  selected: boolean;
  optionComments: string | UndefinedNull;
  tags: string[];
};
type StateOptionSingleChoice = {
  id: string;
  type: "singleChoice";
  isColorized: boolean;
  isRequired: boolean;
  allowComments: boolean;
  allowDescription: boolean;
  style: "dropdown" | "normal";
  header: string | UndefinedNull;
  description: string | UndefinedNull;
  options: Array<StateSingleMultipleChoice>;
  comments: string | UndefinedNull;
};
type StateOptionMultipleChoice = {
  id: string;
  type: "multipleChoice";
  isRequired: boolean;
  allowComments: boolean;
  allowDescription: boolean;
  header: string | UndefinedNull;
  description: string | UndefinedNull;
  options: Array<StateSingleMultipleChoice>;
  comments: string | UndefinedNull;
};
type StateOptionQuestion = {
  id: string;
  type: "question";
  isRequired: boolean;
  question: string | UndefinedNull;
  answer: string | UndefinedNull;
  notify: boolean;
  tags: string[];
};
type StateOptionRange = {
  id: string;
  type: "range";
  isRequired: boolean;
  allowComments: boolean;
  header: string | UndefinedNull;
  min: number | UndefinedNull;
  max: number | UndefinedNull;
  value: string | UndefinedNull;
  comments: string | UndefinedNull;
  tags: string[];
};
type StateFormOptions =
  | StateOptionHeader
  | StateOptionSingleChoice
  | StateOptionMultipleChoice
  | StateOptionQuestion
  | StateOptionRange;
export type State = {
  lastFetching: Date | UndefinedNull;
  template: PayloadObject | UndefinedNull;
  evaluationTemplateId: string | UndefinedNull;
  templates: PayloadObject[];
  formOptions: Array<StateFormOptions>;
};
type Action = {
  type: string;
  payload: PayloadObject;
};
const initialState: State = {
  lastFetching: null,
  template: null,
  evaluationTemplateId: null,
  templates: [],
  formOptions: [],
};

export default function evaluationsTemplates(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case EvaluationsTemplates.EVALUATIONS_TEMPLATES_FETCH_FORMS_SUCCESS:
      return Object.assign({}, state, {
        lastFetching: new Date(),
        templates: action.payload.evaluationTemplates,
      });
    case EvaluationsTemplates.EVALUATIONS_TEMPLATES_CREATE_SUCCESS:
      return Object.assign({}, state, {
        lastFetching: new Date(),
        templates: [action.payload.evaluationTemplate, ...state.templates],
        template: action.payload.evaluationTemplate,
        formOptions: [],
      });
    case EvaluationsTemplates.EVALUATIONS_TEMPLATES_SET_EVALUATION_TEMPLATE_ID:
      return Object.assign({}, state, {
        evaluationTemplateId: action.payload.evaluationTemplateId,
      });
    case EvaluationsTemplates.EVALUATIONS_TEMPLATES_FETCH_FORM_SUCCESS:
    case EvaluationsTemplates.EVALUATIONS_TEMPLATES_EDIT_SUCCESS:
      return Object.assign({}, state, {
        template: action.payload.evaluationTemplate,
        formOptions: action.payload.evaluationTemplate.form,
      });
    case EvaluationsTemplates.EVALUATIONS_TEMPLATES_DELETE_SUCCESS:
      return Object.assign({}, state, {
        lastFetching: new Date(),
        templates: state.templates.filter(
          ({ _id }) => _id !== action.payload.evaluationTemplateId
        ),
      });
    case EvaluationsTemplates.EVALUATIONS_TEMPLATES_RESET_EVALUATION_TEMPLATE_LOADED:
      return Object.assign({}, state, {
        ...initialState,
        lastFetching: state.lastFetching,
        templates: state.templates,
      });
    // Actions for edit form templates:
    case EvaluationsTemplates.EVALUATIONS_TEMPLATES_FORM_OPTIONS_ADD:
      return Object.assign({}, state, {
        formOptions: [...state.formOptions, action.payload.newFormOption],
      });
    case EvaluationsTemplates.EVALUATIONS_TEMPLATES_FORM_OPTIONS_UPDATE:
      return Object.assign({}, state, {
        formOptions: action.payload.newFormOptions,
      });
    // same state (not change)
    default:
      return state;
  }
}
