import { createAction } from "deox";
// Actions for Rotations
// Fetch rotations by filters
export const ROTATIONS_FETCH_BY_FILTERS: string = "ROTATIONS/FETCH/BY_FILTERS";
export const ROTATIONS_FETCH_BY_FILTERS_REQUEST: string =
  "ROTATIONS/FETCH/BY_FILTERS/REQUEST";
export const ROTATIONS_FETCH_BY_FILTERS_SUCCESS: string =
  "ROTATIONS/FETCH/BY_FILTERS/SUCCESS";
export const ROTATIONS_FETCH_BY_FILTERS_FAILURE: string =
  "ROTATIONS/FETCH/BY_FILTERS/FAILURE";
export const rotationsFetchByFilters = createAction(
  ROTATIONS_FETCH_BY_FILTERS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const rotationsFetchByFiltersRequest = createAction(
  ROTATIONS_FETCH_BY_FILTERS_REQUEST
);
export const rotationsFetchByFiltersSuccess = createAction(
  ROTATIONS_FETCH_BY_FILTERS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const rotationsFetchByFiltersFailure = createAction(
  ROTATIONS_FETCH_BY_FILTERS_FAILURE
);
// Fetch Rotations KPIS
export const ROTATIONS_FETCH_KPIS: string = "ROTATIONS/FETCH/KPIS";
export const ROTATIONS_FETCH_KPIS_REQUEST: string =
  "ROTATIONS/FETCH/KPIS/REQUEST";
export const ROTATIONS_FETCH_KPIS_SUCCESS: string =
  "ROTATIONS/FETCH/KPIS/SUCCESS";
export const ROTATIONS_FETCH_KPIS_FAILURE: string =
  "ROTATIONS/FETCH/KPIS/FAILURE";
export const rotationsFetchKpis = createAction(
  ROTATIONS_FETCH_KPIS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const rotationsFetchKpisRequest = createAction(
  ROTATIONS_FETCH_KPIS_REQUEST
);
export const rotationsFetchKpisSuccess = createAction(
  ROTATIONS_FETCH_KPIS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const rotationsFetchKpisFailure = createAction(
  ROTATIONS_FETCH_KPIS_FAILURE,
  (resolve) => (payload?: object) => resolve(payload)
);
// Create Rotations
export const ROTATIONS_CREATE_ROTATIONS: string = "ROTATIONS/CREATE/ROTATIONS";
export const ROTATIONS_CREATE_ROTATIONS_REQUEST: string =
  "ROTATIONS/CREATE/ROTATIONS/REQUEST";
export const ROTATIONS_CREATE_ROTATIONS_SUCCESS: string =
  "ROTATIONS/CREATE/ROTATIONS/SUCCESS";
export const ROTATIONS_CREATE_ROTATIONS_FAILURE: string =
  "ROTATIONS/CREATE/ROTATIONS/FAILURE";
export const rotationsCreateRotations = createAction(
  ROTATIONS_CREATE_ROTATIONS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const rotationsCreateRotationsRequest = createAction(
  ROTATIONS_CREATE_ROTATIONS_REQUEST
);
export const rotationsCreateRotationsSuccess = createAction(
  ROTATIONS_CREATE_ROTATIONS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const rotationsCreateRotationsFailure = createAction(
  ROTATIONS_CREATE_ROTATIONS_FAILURE
);
// Edit Rotations (bulk actions)
export const ROTATIONS_EDIT_ROTATIONS: string = "ROTATIONS/EDIT/ROTATIONS";
export const ROTATIONS_EDIT_ROTATIONS_REQUEST: string =
  "ROTATIONS/EDIT/ROTATIONS/REQUEST";
export const ROTATIONS_EDIT_ROTATIONS_SUCCESS: string =
  "ROTATIONS/EDIT/ROTATIONS/SUCCESS";
export const ROTATIONS_EDIT_ROTATIONS_FAILURE: string =
  "ROTATIONS/EDIT/ROTATIONS/FAILURE";
export const rotationsEditRotations = createAction(
  ROTATIONS_EDIT_ROTATIONS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const rotationsEditRotationsRequest = createAction(
  ROTATIONS_EDIT_ROTATIONS_REQUEST
);
export const rotationsEditRotationsSuccess = createAction(
  ROTATIONS_EDIT_ROTATIONS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const rotationsEditRotationsFailure = createAction(
  ROTATIONS_EDIT_ROTATIONS_FAILURE
);
// Set Automatic Rotations
export const ROTATIONS_SET_AUTOMATIC_ROTATIONS: string =
  "ROTATIONS/SET/AUTOMATIC_ROTATIONS";
export const ROTATIONS_SET_AUTOMATIC_ROTATIONS_REQUEST: string =
  "ROTATIONS/SET/AUTOMATIC_ROTATIONS/REQUEST";
export const ROTATIONS_SET_AUTOMATIC_ROTATIONS_SUCCESS: string =
  "ROTATIONS/SET/AUTOMATIC_ROTATIONS/SUCCESS";
export const ROTATIONS_SET_AUTOMATIC_ROTATIONS_FAILURE: string =
  "ROTATIONS/SET/AUTOMATIC_ROTATIONS/FAILURE";
export const rotationsSetAutomaticRotations = createAction(
  ROTATIONS_SET_AUTOMATIC_ROTATIONS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const rotationsSetAutomaticRotationsRequest = createAction(
  ROTATIONS_SET_AUTOMATIC_ROTATIONS_REQUEST
);
export const rotationsSetAutomaticRotationsSuccess = createAction(
  ROTATIONS_SET_AUTOMATIC_ROTATIONS_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const rotationsSetAutomaticRotationsFailure = createAction(
  ROTATIONS_SET_AUTOMATIC_ROTATIONS_FAILURE
);
// Update Automatic Rotations
export const ROTATIONS_UPDATE_AUTOMATIC_ROTATIONS: string =
  "ROTATIONS/UPDATE/AUTOMATIC_ROTATIONS";
export const rotationsUpdateAutomaticRotations = createAction(
  ROTATIONS_UPDATE_AUTOMATIC_ROTATIONS,
  (resolve) => (payload?: object) => resolve(payload)
);
// Unset Automatic Rotations
export const ROTATIONS_UNSET_AUTOMATIC_ROTATIONS: string =
  "ROTATIONS/UNSET/AUTOMATIC_ROTATIONS";
export const rotationsUnsetAutomaticRotations = createAction(
  ROTATIONS_UNSET_AUTOMATIC_ROTATIONS,
  (resolve) => (payload?: object) => resolve(payload)
);
// Set rotationId to Fetch this rotation
export const ROTATIONS_SET_ROTATION_ID: string = "ROTATIONS/SET/ROTATION_ID";
export const rotationsSetRotationId = createAction(
  ROTATIONS_SET_ROTATION_ID,
  (resolve) => (payload?: object) => resolve(payload)
);
// Fetch a Rotation
export const ROTATIONS_FETCH_ROTATION: string = "ROTATIONS/FETCH/ROTATION";
export const ROTATIONS_FETCH_ROTATION_REQUEST: string =
  "ROTATIONS/FETCH/ROTATION/REQUEST";
export const ROTATIONS_FETCH_ROTATION_SUCCESS: string =
  "ROTATIONS/FETCH/ROTATION/SUCCESS";
export const ROTATIONS_FETCH_ROTATION_FAILURE: string =
  "ROTATIONS/FETCH/ROTATION/FAILURE";
export const rotationsFetchRotation = createAction(
  ROTATIONS_FETCH_ROTATION,
  (resolve) => (payload?: object) => resolve(payload)
);
export const rotationsFetchRotationRequest = createAction(
  ROTATIONS_FETCH_ROTATION_REQUEST
);
export const rotationsFetchRotationSuccess = createAction(
  ROTATIONS_FETCH_ROTATION_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const rotationsFetchRotationFailure = createAction(
  ROTATIONS_FETCH_ROTATION_FAILURE
);
// Edit a Rotation
export const ROTATIONS_EDIT: string = "ROTATIONS/EDIT";
export const ROTATIONS_EDIT_REQUEST: string = "ROTATIONS/EDIT/REQUEST";
export const ROTATIONS_EDIT_SUCCESS: string = "ROTATIONS/EDIT/SUCCESS";
export const ROTATIONS_EDIT_FAILURE: string = "ROTATIONS/EDIT/FAILURE";
export const rotationsEdit = createAction(
  ROTATIONS_EDIT,
  (resolve) => (payload?: object) => resolve(payload)
);
export const rotationsEditRequest = createAction(ROTATIONS_EDIT_REQUEST);
export const rotationsEditSuccess = createAction(
  ROTATIONS_EDIT_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const rotationsEditFailure = createAction(ROTATIONS_EDIT_FAILURE);
// Delete a Rotation
export const ROTATIONS_DELETE: string = "ROTATIONS/DELETE";
export const ROTATIONS_DELETE_REQUEST: string = "ROTATIONS/DELETE/REQUEST";
export const ROTATIONS_DELETE_SUCCESS: string = "ROTATIONS/DELETE/SUCCESS";
export const ROTATIONS_DELETE_FAILURE: string = "ROTATIONS/DELETE/FAILURE";
export const rotationsDelete = createAction(
  ROTATIONS_DELETE,
  (resolve) => (payload?: object) => resolve(payload)
);
export const rotationsDeleteRequest = createAction(ROTATIONS_DELETE_REQUEST);
export const rotationsDeleteSuccess = createAction(
  ROTATIONS_DELETE_SUCCESS,
  (resolve) => (payload?: object) => resolve(payload)
);
export const rotationsDeleteFailure = createAction(ROTATIONS_DELETE_FAILURE);
// Create or Edit Update
export const ROTATIONS_CREATE_EDIT_UPDATE: string =
  "ROTATIONS/CREATE_EDIT/UPDATE";
export const rotationsCreateEditUpdate = createAction(
  ROTATIONS_CREATE_EDIT_UPDATE,
  (resolve) => (payload?: object) => resolve(payload)
);
// Create or Edit Reset process
export const ROTATIONS_CREATE_EDIT_RESET: string =
  "ROTATIONS/CREATE_EDIT/RESET";
export const rotationsCreateEditReset = createAction(
  ROTATIONS_CREATE_EDIT_RESET
);
