/* eslint-disable jsx-a11y/anchor-is-valid */
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
// Features
import * as React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { setUserRole } from "../../../utils/";
// Permissions
import { onCheckReadUsersRoleProfile } from "../../user-manager/UsersPermissions";
import { onCheckReadCaseLogsPermissions } from "../../case-logs/CaseLogsPermissions";
// Components
import { Grid } from "semantic-ui-react";
import NotificationsDate from "./NotificationsDate";
// Type
import type { RootState } from "../../../store/reducers";
import type { UndefinedNull, PayloadObject } from "../../../types/common.types";
type PropsNotificationsCaseLogs = {
  _id: string;
  readBy: PayloadObject[] | UndefinedNull;
  caseLog: PayloadObject;
  institution: PayloadObject;
  student: PayloadObject;
  preceptor: PayloadObject;
  message: string;
  createdAt: string;
  goToProfile: (pathname: string, state?: PayloadObject) => any;
};
// Notifications for CASE_LOGS
const NotificationCaseLog = ({
  _id,
  readBy,
  caseLog,
  institution,
  student,
  preceptor,
  message,
  createdAt,
  goToProfile,
}: React.PropsWithChildren<PropsNotificationsCaseLogs>) => (
  <div
    className={classNames("notification", {
      new: !readBy || readBy?.length === 0,
    })}
  >
    <Grid columns={2}>
      <Grid.Row className="nopadding">
        <Grid.Column mobile={3} tablet={3} computer={3} className="">
          <i className="icono icono-clipboard-fill huge skejule-teal" />
        </Grid.Column>
        <Grid.Column mobile={13} tablet={13} computer={13} className="">
          <div className="message">
            {message
              .split(
                /({studentName}|{institutionName}|{preceptorName}|{caseLogNumber}|a new Case Log)/gi
              )
              .map((el: string, index: number) => {
                if (el === "{studentName}") {
                  return (
                    <a
                      key={student._id + index}
                      style={{ cursor: "pointer" }}
                      onClick={(event: Object) => {
                        // DEBUG: We need to debugger this action, because when
                        // it's called one time and manager profile is open
                        // the action User.setUserId is called two times, because of
                        // ManagerRoute component is launched two times before route change
                        goToProfile(`/manager/profile/${student._id}`)(event);
                        setTimeout(() => {
                          goToProfile(`/manager/profile/${student._id}`)(event);
                        }, 500);
                      }}
                    >{`${student.firstName || ""} ${
                      student.lastName || ""
                    }`}</a>
                  );
                } else if (el === "{institutionName}") {
                  return (
                    <a
                      key={institution._id + index}
                      style={{ cursor: "pointer" }}
                      onClick={goToProfile(`/clinics/view/${institution._id}`)}
                    >
                      {institution.name}
                    </a>
                  );
                } else if (el === "{preceptorName}") {
                  return (
                    <LinkToPreceptor
                      key={preceptor._id + index}
                      style={{ cursor: "pointer" }}
                      onClick={(event: Object) => {
                        // DEBUG: We need to debugger this action, because when
                        // it's called one time and manager profile is open
                        // the action User.setUserId is called two times, because of
                        // ManagerRoute component is launched two times before route change
                        goToProfile(`/manager/profile/${preceptor._id}`)(event);
                        setTimeout(() => {
                          goToProfile(`/manager/profile/${preceptor._id}`)(
                            event
                          );
                        }, 500);
                      }}
                    >
                      {`${preceptor.firstName || ""} ${
                        preceptor.lastName || ""
                      }`}
                    </LinkToPreceptor>
                  );
                } else if (el === "{caseLogNumber}") {
                  return (
                    <LinkToCaseLog
                      key={caseLog._id + index}
                      style={{ cursor: "pointer" }}
                      onClick={goToProfile(
                        `/case-logs/${
                          message.indexOf("Resubmit now") !== -1
                            ? "edit"
                            : "view"
                        }/${caseLog._id}`
                      )}
                    >
                      #{caseLog.caseLogNumber}
                    </LinkToCaseLog>
                  );
                } else if (el === "a new Case Log") {
                  return (
                    <LinkToCaseLog
                      key={caseLog._id + index}
                      style={{ cursor: "pointer" }}
                      onClick={goToProfile(
                        `/case-logs/${
                          message.indexOf("Complete your review") !== -1
                            ? "review"
                            : "view"
                        }/${caseLog._id}`
                      )}
                    >
                      a new Case Log
                    </LinkToCaseLog>
                  );
                }
                return el;
              })}
          </div>
          <NotificationsDate
            createdAt={createdAt}
            isMarkAsRead={!!readBy && readBy?.length > 0}
            notificationId={_id}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
);
const LinkToPreceptor = ({
  children,
  ...props
}: React.PropsWithChildren<React.AnchorHTMLAttributes<HTMLAnchorElement>>) => {
  // STORE AUTH
  const canGoToPreceptorProfile: boolean = useSelector(
    ({ auth: { role } }: RootState) =>
      onCheckReadUsersRoleProfile(setUserRole("Preceptors"), role.permissions)
  );
  if (canGoToPreceptorProfile) {
    return <a {...props}>{children}</a>;
  }
  return <>{children}</>;
};
const LinkToCaseLog = ({
  children,
  ...props
}: React.PropsWithChildren<React.AnchorHTMLAttributes<HTMLAnchorElement>>) => {
  // STORE AUTH
  const canReadCaseLogs: boolean = useSelector(
    ({ auth: { role } }: RootState) =>
      onCheckReadCaseLogsPermissions(role.permissions)
  );
  if (canReadCaseLogs) {
    return <a {...props}>{children}</a>;
  }
  return <>{children}</>;
};
export default NotificationCaseLog;
