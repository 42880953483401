import { put, select, call, all, takeLatest } from "redux-saga/effects";
import BroadcastMessagesApi from "../api/BroadcastMessages";
import { Auth, BroadcastMessages, TenantSettings } from "../actions/";
// Type
import type { Action } from "deox";
import type { tAPI } from "../api/API";
//Sagas for broadcast Messages
function* doBroadcastMessagesFetchMessages(
  action: Action<
    string,
    {
      expirationDate: string;
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  try {
    window.logger.log("doBroadcastMessagesFetchMessages", action);
    const { expirationDate = "", callbackSuccess = null } =
      action.payload || {};
    yield put(BroadcastMessages.broadcastMessagesFetchMessagesRequest());
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to get all broadcast messages
    const { data } = yield call(
      BroadcastMessagesApi.GetBroadcastMessages,
      api,
      expirationDate,
      { token }
    );
    const { tenantSettings: broadcastMessages } = data;
    yield put(
      BroadcastMessages.broadcastMessagesFetchMessagesSuccess({
        broadcastMessages,
      })
    );
    if (callbackSuccess) {
      callbackSuccess(broadcastMessages);
    }
  } catch (error: any) {
    const { callbackError = null } = action.payload || {};
    const { data: errors } = error;
    window.logger.error(
      "ErrorSaga - doBroadcastMessagesFetchMessages",
      error,
      errors
    );
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(BroadcastMessages.broadcastMessagesFetchMessagesFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}
function* doBroadcastMessagesSetMessages(
  action: Action<
    string,
    {
      broadcastMessagesSettings: { [k: string]: any }[];
      callbackSuccess?: (data: any) => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  window.logger.log("doBroadcastMessagesSetMessages", action);
  const {
    broadcastMessagesSettings = [],
    callbackSuccess = null,
    callbackError = null,
  } = action.payload || {};
  let broadcastMessages = [],
    broadcastMessagesSettingsValue = [];
  try {
    const { role: userRole, ...authUser } = yield select(
      ({ auth: { user } }) => user
    );
    if (broadcastMessagesSettings.length > 0) {
      ({ value: broadcastMessagesSettingsValue } =
        broadcastMessagesSettings[0]);
      broadcastMessagesSettingsValue = broadcastMessagesSettingsValue.filter(
        ({ roles }: any) => roles.indexOf(userRole) !== -1
      );
      switch (userRole) {
        case "Students":
          const { activeRotation, currentEnrollment } = authUser;
          for (let index in broadcastMessagesSettingsValue) {
            const {
              programs = [],
              cohorts = [],
              institutions = [],
            } = broadcastMessagesSettingsValue[index];
            let messagesBelongToThisUser = true;
            if (programs.length > 0) {
              if (currentEnrollment) {
                const { program } = currentEnrollment;
                if (programs.indexOf(program._id) === -1) {
                  messagesBelongToThisUser = false;
                }
              } else {
                messagesBelongToThisUser = false;
              }
            }
            if (cohorts.length > 0) {
              if (currentEnrollment) {
                const { cohort } = currentEnrollment;
                if (cohorts.indexOf(cohort._id) === -1) {
                  messagesBelongToThisUser = false;
                } else {
                  messagesBelongToThisUser = true;
                }
              } else {
                messagesBelongToThisUser = false;
              }
            }
            if (institutions.length > 0) {
              if (activeRotation) {
                const { institutions: studentsInstitutions = [] } =
                  activeRotation;
                if (
                  studentsInstitutions.filter(
                    ({ _id: institutionId }: any) =>
                      institutions.indexOf(institutionId) !== -1
                  ).length === 0
                ) {
                  messagesBelongToThisUser = false;
                } else {
                  messagesBelongToThisUser = true;
                }
              }
            }
            if (messagesBelongToThisUser) {
              broadcastMessages.push(broadcastMessagesSettingsValue[index]);
            }
          }
          break;
        case "InstitutionAdministrators":
        case "Preceptors":
          const { institution } = authUser;
          for (let index in broadcastMessagesSettingsValue) {
            const { institutions = [] } = broadcastMessagesSettingsValue[index];
            let messagesBelongToThisUser = true;
            if (institutions.length > 0) {
              const { _id: institutionId } = institution;
              if (institutions.indexOf(institutionId) === -1) {
                messagesBelongToThisUser = false;
              }
            }
            if (messagesBelongToThisUser) {
              broadcastMessages.push(broadcastMessagesSettingsValue[index]);
            }
          }
          break;
        default:
          broadcastMessages = [...broadcastMessagesSettingsValue];
          break;
      }
    }
    yield put(
      BroadcastMessages.broadcastMessagesSaveMessages({ broadcastMessages })
    );
    if (callbackSuccess) {
      callbackSuccess(broadcastMessages);
    }
  } catch (error: any) {
    window.logger.error("ErrorSaga - doBroadcastMessagesSetMessages", error);
    if (callbackError) {
      callbackError(error);
    }
  }
}

function* doBroadcastMessagesCreate(
  action: Action<
    string,
    {
      body: object;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(BroadcastMessages.broadcastMessagesCreateRequest());
  window.logger.log("doBroadcastMessagesCreate", action);
  const { body, callbackSuccess = null, callbackError = null } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to create a new broadcast Message
    yield call(BroadcastMessagesApi.CreateBroadcastMessage, api, body, {
      token,
    });
    yield put(BroadcastMessages.broadcastMessagesCreateSuccess());
    yield put(TenantSettings.tenantSettingsFetchAll());
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doBroadcastMessagesCreate", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(BroadcastMessages.broadcastMessagesCreateFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doBroadcastMessagesEdit(
  action: Action<
    string,
    {
      broadcastMessageId: string;
      body: object;
      callbackSuccess?: () => void;
      callbackError?: (error: any) => void;
    }
  >
) {
  yield put(BroadcastMessages.broadcastMessagesEditRequest());
  window.logger.log("doBroadcastMessagesEdit", action);
  const {
    broadcastMessageId,
    body,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to update a broadcast Message for that specific broadcastMessageId
    yield call(
      BroadcastMessagesApi.EditBroadcastMessage,
      api,
      broadcastMessageId,
      body,
      { token }
    );
    yield put(BroadcastMessages.broadcastMessagesEditSuccess());
    yield put(TenantSettings.tenantSettingsFetchAll());
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doBroadcastMessagesEdit", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(BroadcastMessages.broadcastMessagesEditFailure());
    const { message } = errors || "";
    if (callbackError) {
      callbackError(message);
    }
  }
}

function* doBroadcastMessagesDelete(
  action: Action<
    string,
    {
      broadcastMessageId: string;
      callbackSuccess?: () => void;
      callbackError?: (error: any, details: any) => void;
    }
  >
) {
  yield put(BroadcastMessages.broadcastMessagesDeleteRequest());
  window.logger.log("doBroadcastMessagesDelete", action);
  const {
    broadcastMessageId,
    callbackSuccess = null,
    callbackError = null,
  } = action.payload;
  try {
    const { api, token }: { api: tAPI; token: string } = yield select(
      ({ api }) => api
    );
    // call the api to delete a broadcast Message for that specific broadcastMessageId
    yield call(
      BroadcastMessagesApi.DeleteBroadcastMessage,
      api,
      broadcastMessageId,
      { token }
    );
    yield put(BroadcastMessages.broadcastMessagesDeleteSuccess());
    yield put(TenantSettings.tenantSettingsFetchAll());
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error: any) {
    const { data: errors } = error;
    window.logger.error("ErrorSaga - doBroadcastMessagesDelete", error, errors);
    if (errors) {
      yield put(Auth.authCheckSessionExpiredApp({ errors }));
    }
    yield put(BroadcastMessages.broadcastMessagesDeleteFailure());
    const { message, details } = errors || "";
    if (callbackError) {
      callbackError(message, details);
    }
  }
}

export default function* broadcastMessagesSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(
      BroadcastMessages.BROADCAST_MESSAGES_FETCH_MESSAGES,
      doBroadcastMessagesFetchMessages
    ),
    takeLatest(
      BroadcastMessages.BROADCAST_MESSAGES_SET_MESSAGES,
      doBroadcastMessagesSetMessages
    ),
    takeLatest(
      BroadcastMessages.BROADCAST_MESSAGES_CREATE,
      doBroadcastMessagesCreate
    ),
    takeLatest(
      BroadcastMessages.BROADCAST_MESSAGES_EDIT,
      doBroadcastMessagesEdit
    ),
    takeLatest(
      BroadcastMessages.BROADCAST_MESSAGES_DELETE,
      doBroadcastMessagesDelete
    ),
  ]);
}
